var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('Payments.reconciliation'))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-cash-register")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('Payments.invoice',2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-cash-register")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.paymentNotes,"footer-props":{
                    'items-per-page-options': [5, 10, 50]
                  },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pl-0",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_c('span',[_vm._v(" "+_vm._s(_vm.ComputeType)+" ")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-expand-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"hidden-search",attrs:{"flat":"","height":"40","width":_vm.$vuetify.breakpoint.smAndDown ? '75%': _vm.$vuetify.breakpoint.lgAndUp ? '30%':'40%'}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0 py-5",attrs:{"cols":"12"}},[_c('SubscriberSearch',{ref:"subs_criber",on:{"clearing":_vm.SoftEraseFilter,"subscriber":_vm.SearchSubscriber}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',{staticClass:"py-0 mx-0",attrs:{"cols":"12","sm":"12","md":"11"}},[_c('v-row',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-1' :'ma-0 pa-0',attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0 mb-6 mb-md-0",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('SubscriberSearch',{ref:"subs_criber",on:{"clearing":_vm.SoftEraseFilter,"subscriber":_vm.SearchSubscriber}})],1),_c('v-col',{class:!_vm.$vuetify.breakpoint.mdAndUp ? 'py-0 mb-6 mb-md-0 d-flex flex-row justify-end':'py-0 mb-6 mb-md-0 d-flex flex-row justify-start',attrs:{"cols":"12","sm":"12","md":"2","lg":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"filterErase justify-content-between",attrs:{"text":"","small":""},on:{"click":_vm.EraseFilter}},on),[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndUp),expression:"!$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t('clean'))+" ")]),_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("fas fa-filter")]),_c('v-icon',{staticClass:"filterIconEraser",attrs:{"small":"","color":"grey darken-1 filterErase py-0 mx-0"}},[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('clean')))])])],1)],1)],1)],1)]},proxy:true},{key:"item.issue_date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.issue_date).format(_vm.$store.getters.getDateFormat[0]))+" ")]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('billing.status.'+item.status))+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" "+_vm._s(_vm._f("formatAmount")(item.amount))+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show_doc),expression:"show_doc"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.getClientDocument(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('PreView',{ref:"pre_view"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }