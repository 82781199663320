<template>
  <v-dialog
    v-model="dialog" 
    width="600"
    transition="dialog-transition"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline font-weight-bold primary--text">
          {{ 
            $t(pack.id != '' ? 'package.edit' : 'package.new')
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-show="pack.service_count > 0"
              color="primary"
              :label="$tc('active',0)" 
              v-model="pack.active">
            </v-switch>
          </v-col>
          <v-col cols="12" v-show="!(pack.service_count > 0)">
            <v-alert dense outlined type="info" class="body-2">
              {{ $t('package.no_service') }}
            </v-alert>
          </v-col>
          <v-col cols="12" xs="12" sm="4">
            <v-text-field
              v-model="pack.code"
              name="code"
              @keyup.enter="save"
              v-validate="'required'"
              :error-messages="errors.collect('code')"
              :data-vv-as="$tc('code',1)"
              :label="$t('code')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="8">
            <v-text-field
              v-model="pack.name"
              name="name"
              @keyup.enter="save"
              v-validate="'required'"
              :error-messages="errors.collect('name')"
              :data-vv-as="$tc('name',1)"
              :label="$t('name')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="pack.description"
              v-validate="'required'"
              rows="2"
              name="description"
              :data-vv-as="$t('description')"
              :label="$t('description')"
              :error-messages="errors.collect('description')"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="info" text @click="close">
          <span v-show="$vuetify.breakpoint.mdAndUp" 
            class="mr-5">
            {{$t('base.cancel')}}
          </span>                        
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="save">
          <span v-show="$vuetify.breakpoint.mdAndUp" 
            class="mr-5">
            {{$t('base.save')}}
          </span>                        
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:"dialpack",
  data(){
    return {
      dialog: false,
      pack:{
        id: '',
        active: false,
        code: '',
        name: '',
        description: '',
        service_count: 0
      },
      loading: false
    }
  },
  methods:{
    open(value){
      this.pack = value != undefined ? Object.assign({},value) : {
        id: '',
        active: false,
        code: '',
        name: '',
        description: '',
        service_count: 0
      }
      this.$validator.reset()
      this.loading = false
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
    save(){
      this.$validator.validateAll()
      .then(()=>{
        if(!this.errors.any()){
          this.loading = true
          if(this.pack.id == ''){
            axios.post(this.$store.getters.getPackage(),this.pack)
            .then(response => {
              this.$emit('packageSaved')
              this.$store.dispatch('pushNotification', {
                snackbar:true,
                style: 'success',
                timeout: 4000,
                notifText: 'success',
                notifPos: 1,
                extra: {
                  model: this.$tc('package.package',2),
                  action: this.$tc('created',1) 
                }
              })
              this.dialog = false
            })
            .finally(()=>{
              this.loading = false
            }) 
          }
          else{
            axios.put(
              this.$store.getters.getPackage(
                this.pack.id),
                this.pack
              )
            .then(response => {
              this.$emit('packageSaved')
              this.$store.dispatch('pushNotification', {
                snackbar:true,
                style: 'success',
                timeout: 4000,
                notifText: 'success',
                notifPos: 1,
                extra: {
                  model: this.$tc('package.package',2),
                  action: this.$tc('updated',1) 
                }
              })
              this.dialog = false
            })
            .finally(()=>{
              this.loading = false
            }) 
          }
        }
      })
    }
  }
}
</script>