import modalFeatures from './ModalFeatures.vue'

export default {
    components:{
        modalFeatures,
    },
    data: () => ({
        dialogFullScreem: false,
        viewMode: false,
        perPage: 10,
        page: 1,
        last_page:0,
        totalValues: 0,
        search: "",
        parent_data: {
            id: '',
            name: '',
            description: '',
            is_active: '',
            is_franchise: '',
            features: []
        },
        unwatch: '',
        clon: {},
        feat: [],
    }),
    computed: {
        features(){
            if (this.parent_data.features != null || this.parent_data.features != undefined) {
                return this.parent_data.features.filter( j => {
                    if(this.search == undefined || this.search == ""){
                    return j
                    }
                    else if(j.name.toUpperCase().indexOf(this.search.toUpperCase()) != -1){
                    return j
                    }
                })
            }
            else{
                return []
            }
        }
    },
    created () {
        this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
            // Watch for update table after confirmation
            if(val && this.$store.getters.getConfirmationTemplate == 'features'){
                this.parent_data = this.clon
            }
        })
    },
    methods: {
        setClose(){
            this.setFilter()
            this.parent_data = {
                id: '',
                name: '',
                description: '',
                is_active: '',
                is_franchise: '',
                features: []
            }
            this.clon = {}
            this.feat = []
            this.dialogFullScreem = false
        },
        setFilter(){
            this.search = ""
        },
        updateTypeClient(evt){
            this.$emit('updateTypeClient')
            if(evt.edit){
                this.$set(this.parent_data.features, evt.feature.index, JSON.parse(JSON.stringify(evt.feature)))
            }

        },
        //evt funtion check the view (List or Cards) in the cards of features clients
        viewModeActive(mode){
            if (mode == false) {
                this.viewMode = false
            }
            else{
                this.viewMode = true
            }
        },
        //This is a function to excecute up modal full screem, from the parent component
        openDialog(value){
            this.dialogFullScreem = true
            this.parent_data = Object.assign({}, value)
            this.parent_data.features = this.parent_data.features == null ? [] : this.parent_data.features
            this.parent_data.features = this.parent_data.features.map((j, key) => {
                return {
                    index : key,
                    name: j.name,
                    description: j.description
                }
            })
        },
        editItem (index, feature) {
            let object = Object.assign({}, feature)
            this.$refs.modalFeatures.editItem(object, this.parent_data)
        },
        remove(value, data){
            // eslint-disable-next-line no-console
            // console.log('Esto es el remove de features, value: ', value, ' y data: ', data);
            this.clon = JSON.parse(JSON.stringify(data))
            this.clon.features.splice(value,1)
            this.$store.dispatch('confirmationValue',{
                template: 'features',
                show: true,
                title: this.$t('base.delete'),
                pre: 'success',
                model:this.$t('typeClient.feature'),
                action: this.$tc('deleted',2),
                content: this.$t('base.confirm_delete',{
                action:this.$t('base.delete'),
                record:value.name
                }),
                type: 'error',
                confirm:{
                    url: this.$store.getters.getTypeClient(data.id),
                    method: 'put',
                    //El metodo acepta cualquier tipo de metodo y luego se le envia la data...
                    data:this.clon,
                },
                pos: 2
            })
        },
        // searchValue(){
        //     this.page = 1
        //     this.parent_data.features.filter( j => {
        //         if(this.search == undefined || this.search == ""){
        //         return j
        //         }
        //         else if(j.name.toUpperCase().indexOf(this.search.toUpperCase()) != -1 ||
        //             j.description.toUpperCase().indexOf(this.search.toUpperCase()) != -1){
        //         return j
        //         }
        //     })
        // }
    },
    beforeDestroy () {
        this.unwatch()
    }
}
