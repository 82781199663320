import HistoryForm  from './Form.vue'
import HistoryTable from './Table.vue'

export default {
  name: "OldBillingHistoryDialog",
  components:{
    HistoryForm,
    HistoryTable
  },
  props: {
    subscriber_id: Number,
    contract_code: {
      type:[String, Number],
      default: ''
    }
  },
  data (){
    return {
      mode: '',
      loading: false,
      dialog: false,
      permissions: {
        list: false,
        create: false,
        edit: false,
        delete: false
      },
      form_editing: false,
      editable_history: {},
      file_upload_url: this.$store.getters.oldBillingHistoryBulkLoad(this.subscriber_id, this.contract_code),
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    this.historyPolicies()
  },
  computed:{
    //
  },
  methods:{
    open () {
      this.dialog = true
      if (this.permissions.list) {
        this.mode = 'table'
      } else if (this.permissions.create) {
        this.mode = 'form'
      } else {
        this.close()
      }
    },
    close () {
      this.dialog = false
      if (this.$refs.old_billing_history_form) {
        setTimeout(() => {
          this.$refs.old_billing_history_form.$validator.reset()
        }, 400)
      }
    },
    changeMode (mode, edit = false) {
      this.mode = mode
      /*if (this.mode == 'table') {
        setTimeout(() => {
          this.$refs.old_billing_history_table.getHistory()
        }, 400)
      }*/
      if (this.mode == 'form') {
        this.form_editing = edit
      }
    },
    saveForm () {
      this.$refs.old_billing_history_form.save()
    },
    setEditableHistory (history) {
      this.editable_history = Object.assign({}, history)
    },
    loadingBtn (val) {
      this.loading = val
    },
    historyPolicies () {
      this.permissions.list = this.getPermissionGroup({
        permission:[
          'index_oldBillingHistory'
        ],
        mode:'all'
      });
      this.permissions.create = this.getPermissionGroup({
        permission:[
          'store_oldBillingHistory',
        ],
        mode:'all'
      });
      this.permissions.edit = this.getPermissionGroup({
        permission:[
          'index_taxes',
          'update_oldBillingHistory',
        ],
        mode:'all'
      });
      this.permissions.delete = this.getPermissionGroup({
        permission:[
          'index_oldBillingHistory',
          'destroy_oldBillingHistory',
        ],
        mode:'all'
      });
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
