<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input',$event)"
    :name="name"
    autocomplete="off"
    clearable
    :readonly="readonly"
    :disabled="disabled"
    @click:clear="$emit('input',$event)"
    :error-messages="errorMessages"
    :items="records"
    :loading="loading"
    :search-input.sync="search"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :prepend-icon="icon ? icon : ''"
  ></v-autocomplete>
</template>
<script>
export default {
  props:{
    value:{
      required:true
    },
    noPagination:{
      default:false,
      type:Boolean
    },
    itemValue:{
      type:[String,Array,Function],
      default:'value'
    },
    itemText:{
      type:[String,Array,Function],
      default:'value'
    },
    responseVal:{
      type:String
    },
    searchObj:{
      type:Object,
      default:() => ({
        "search":''
      })
    },
    readonly:{
      default:false,
      type:Boolean
    },
    disabled:{
      default:false,
      type:Boolean
    },
    errorMessages: {
      default:''
    },
    api:{
      required:true,
      type:Object
    },
    label:{
      required:true,
      default:"",
      type:String
    },
    name:{
      required:true,
      default:"",
      type:String
    },
    icon:{
      required:false,
      default:"",
      type:String
    },
  },
  data: () => ({
    records:[],
    cloneSearch:{},
    loading:false,
    noSearch: false,
    search:""
  }),
  methods:{
    getRecords(){
      let clone = JSON.parse(JSON.stringify(this.api))
      if(clone !== undefined){
        clone.params = {...clone.params,...this.cloneSearch}
      }
      this.loading = true
      axios(clone)
      .then(response => {
        this.noSearch = true
        this.records = this.responseVal === undefined ? response.data : response.data[this.responseVal]
        if(!this.noPagination){
          this.records = this.records.data
          this.noSearch = false
        }
      })
      .finally(() => this.loading = false)
    }
  },
  watch:{
    searchObj:{
      immediate:true,
      deep:true,
      handler(val){
        this.cloneSearch = JSON.parse(JSON.stringify(val))
      }
    },
    value:{
      immediate:true,
      handler(){}
    },
    api:{
      deep:true,
      immediate:true,
      handler(api){
        this.getRecords()
      }
    },
    search(val){
      if((typeof(val) === 'string' && val.length%2 == 0 && !this.noSearch)){
        Object.keys(this.cloneSearch).forEach((key) =>{
          this.cloneSearch[key] = val
        })
        this.getRecords()
      }
    }
  },
}
</script>
