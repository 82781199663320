var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1 subscribers-table",attrs:{"headers":_vm.headers,"items":_vm.subscribers,"mobile-breakpoint":0,"hide-default-header":_vm.nodata,"footer-props":{
              'items-per-page-options': [10, 50, 100, 500, 1000]
            },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.$tc('contracts.contracts',2)+' '+_vm.$tc('by',2)+' '+_vm.$tc('service.service',1)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(_vm._s(_vm.computedMainFilters))]),_c('sectorFilter',{attrs:{"showBtnFilter":"","showSearch":"","showContractStatuses":"","showPackages":!_vm.computedMainFilters.hasOwnProperty('srv') && _vm.policies.list_package,"showServices":((_vm.computedMainFilters.hasOwnProperty('srv') && _vm.computedMainFilters.srv != 0) ||
                _vm.computedMainFilters.hasOwnProperty('all') ||
                _vm.computedMainFilters.hasOwnProperty('corporate')) &&
                !_vm.$store.getters.isFranchise &&
                _vm.policies.list_service,"showFranchise":_vm.franchise_id == 0 &&
                !_vm.computedMainFilters.hasOwnProperty('corporate') &&
                !(_vm.computedMainFilters.hasOwnProperty('srv') && _vm.computedMainFilters.srv != 0) &&
                _vm.policies.list_franchise,"disableFields":_vm.loadingTable,"f_id":_vm.searchBy.franchise_id,"srv_id":_vm.searchBy.service_id,"pkg_id":_vm.searchBy.package_id,"classParent":'filter-details-table'},on:{"franchise_id":_vm.filterBy,"service_id":_vm.filterBy,"package_id":_vm.filterBy,"search":_vm.filterBy,"contract_status":_vm.filterBy,"eraseFilter":_vm.eraseFilter}})]},proxy:true},{key:"item.subscriber.id",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscriber.id.toString().length < 5 ? ("00000" + item.subscriber.id).slice(-5) : item.subscriber.id)+" ")]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(_vm.policies.show_subscriber)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.$i18n.locale == 'es' ? 'mr-4':'mr-4',attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.viewDetails(item.subscriber.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('subscriber-details',{ref:"subscriber_dialog",on:{"editedSubscriber":function($event){return _vm.getSubscribers(_vm.expand, _vm.prospects_only, _vm.inactive_only, _vm.permanently_retired)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }