<template>
  <div :class="parentClass">
    <v-card
      :elevation="elevation"
      class="d-flex flex-column align-start w-100"
      card-service
    >
      <!-- chip para saber si es servicio o paquete -->
      <span class="flex-1 w-100">
        <v-chip color="secondary" left outlined>
          <v-avatar :left="$vuetify.breakpoint.mdAndUp">
            <v-icon
              small
              v-text="item.type == 'package' ? 'fas fa-cubes' : 'fas fa-server'"
            >
            </v-icon>
          </v-avatar>
          <span v-show="$vuetify.breakpoint.mdAndUp">
            {{
              item.type == "package"
                ? $tc("package.package", 2)
                : $tc("service.service", 1)
            }}
          </span>
        </v-chip>
      </span>
      <div class="btn-float">
        <div class="d-flex flex-row">
          <!-- Change package or service -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="showBtnChangeServPack"
                v-on="on"
                x-small
                dark
                fab
                color="grey"
                class="ml-3 mr-n1"
                @click="openChangeServPack(item)"
              >
                <v-icon small>fas fa-jedi</v-icon>
              </v-btn>
            </template>
            <span>{{ btnChangePack }}</span>
          </v-tooltip>
          <!-- Historical -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="showBtnHistorical"
                v-on="on"
                x-small
                dark
                fab
                color="grey"
                class="ml-3 mr-n1"
                @click="openHistorical(item)"
              >
                <v-icon small>fas fa-align-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("billing.history") }}</span>
          </v-tooltip>
          <!-- Activate/inactivate -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-if="showBtnActivate"
                v-on="on"
                :color="itemStatus(item.status, 1)"
                class="ml-3 mr-n1"
                @click="changeStatus(item)"
              >
                <v-icon small>{{ itemStatus(item.status, 2) }}</v-icon>
              </v-btn>
            </template>
            <span>
              {{ itemStatus(item.status, 3) }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <!-- Titulo -->
      <v-card-title
        tile
        class="exclude-outlined-styles primary--text pt-2 py-0"
      >
        <div class="headline font-weight-bold">
          {{ item.name }}
        </div>
        <span>
          {{ item.qty > 1 ? ` (${item.qty})` : "" }}
        </span>
      </v-card-title>
      <!-- Status -->
      <p
        class="mt-n2 mb-0 px-4 font-weight-bold caption"
        :class="itemStatus(item.status, 1) + '--text'"
        style="font-size: 11px !important;"
      >
        ({{ $t("service.statuses." + item.status) }})
      </p>
      <v-card-text
        class="d-md-flex justify-space-between flex-wrap text-right pt-0 pb-2"
        style="font-size: 13px;"
      >
        <div>
          <template v-if="item.installation_date">
            {{ `${$t("service_install.installation_date")}:` }}
            {{
              $moment(item.installation_date).format(
                $store.getters.getDateFormat[0]
              )
            }}
            <v-icon x-small v-if="editInstallationDate"
              class="mb-1 ml-1"
              @click="openEditDateModal('installation', item.installation_date)"
            >
              fas fa-pencil-alt
            </v-icon>
          </template>
        </div>
        <div v-if="viewPrice">
          {{ `${$t("paymentNote.priceTotal")}:` }}
          {{ item.price | formatCurrency(0) }}
        </div>
        <div v-if="item.billing_start_date && showBillingStartDate">
          {{ `${$t("service_install.billing_starts_at")}:` }}
          {{
            $moment(item.billing_start_date).format(
              $store.getters.getDateFormat[0]
            )
          }}
          <v-icon x-small v-if="editBillingStartDate"
            class="mb-1 ml-1"
            @click="openEditDateModal('billing', item.billing_start_date)"
          >
            fas fa-pencil-alt
          </v-icon>
        </div>
      </v-card-text>
      <!-- Lista de parametros de servicio -->
      <v-list
        dense
        v-if="item.type == 'service'"
        class="pt-0"
        style="width: 100%;"
      >
        <v-list-item
          dense
          v-for="(param, i) in item.service_param"
          :key="'param-' + i"
          style="min-height: 20px !important;"
        >
          <v-list-item-content class="py-0">
            <v-list-item-title
              class="d-flex justify-space-between"
              style="font-size: 13px;font-weight: 400;min-height: 20px !important;"
            >
              <div>
                <b>&middot;</b> {{ param.qty }} {{ transformMeasure(param.qty, param.param.measure) }}
              </div>
              <!-- Open config -->
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="showBtnParameters && i == 0"
                    x-small
                    dark
                    icon
                    color="grey"
                    class="pa-3"
                    @click="openJSON(item)"
                  >
                    <v-icon size="14">fas fa-cogs</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("configs") }}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- Lista de servicios del Paquete -->
      <v-list
        dense
        v-if="item.type == 'package'"
        class="pt-0"
        style="width: 100%;"
      >
        <v-list-item
          dense
          v-for="serv in item.services"
          :key="'serv-' + serv.id"
        >
          <v-list-item-content class="pb-0">
            <v-list-item-title
              class="d-flex justify-space-between pl-4"
              style="font-size: 13px;font-weight: 700;"
            >
              <div>{{ serv.name }}</div>
              <!-- Open config -->
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="showBtnParameters"
                    x-small
                    dark
                    icon
                    color="grey"
                    class="pa-3"
                    @click="openJSON(serv)"
                  >
                    <v-icon size="14">fas fa-cogs</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("configs") }}</span>
              </v-tooltip>
            </v-list-item-title>
            <!-- Pack service params -->
            <v-list-item
              dense
              v-for="s_param in serv.service_param"
              :key="'s_param-' + s_param.id"
              style="min-height: 20px !important;"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="ma-0 pa-0 pl-3"
                  style="font-size: 12px;font-weight: 400;min-height: 20px !important;"
                >
                  <b>&middot;</b> {{ s_param.qty }} {{ transformMeasure(s_param.qty, s_param.param.measure) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- X -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- End -->
    </v-card>
    <!-- Edit Dates Modal -->
    <date-modal
      :dialog-open="editDateModal"
      :date="editingDate"
      :type="editingDateType"
      :loadingBtn="loading"
      @saveNewDate="saveDate"
      @closeDateModal="($event) => editDateModal = $event"
    ></date-modal>
  </div>
</template>

<script>
import DateModal from "@/components/Utils/DateModal.vue"

export default {
  name: "ServiceCard",
  components:{
    DateModal
  },
  props: {
    viewPrice: {
      type: Boolean,
      default: false
    },
    parentClass: {
      type: String,
      default: ""
    },
    elevation: {
      type: Number,
      default: 5
    },
    item: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    editInstallationDate: {
      type: Boolean,
      default: false
    },
    showBillingStartDate: {
      type: Boolean,
      default: false
    },
    editBillingStartDate: {
      type: Boolean,
      default: false
    },
    showBtnParameters: {
      type: Boolean,
      default: false
    },
    showBtnActivate: {
      type: Boolean,
      default: false
    },
    showBtnHistorical: {
      type: Boolean,
      default: false
    },
    showBtnChangeServPack: {
      type: Boolean,
      default: false
    }
  },
  data:() => ({
    editDateModal: false,
    editingDateType: '',
    editingDate: '',
    loading: false
  }),
  computed: {
    computedBillingStartDate: {
      get () {
        return this.item.billing_start_date
      },
      set (val) {
        //
      }
    },
    btnChangePack() {
      const pack =
        this.item.type == "package"
          ? this.$tc("package.package", 2)
          : this.$tc("service.service", 1);
      return this.$t("base.change") + " " + pack;
    }
  },
  created () {
    //
  },
  methods: {
    openJSON(data) {
      this.$emit("config", data);
    },
    changeStatus(data) {
      this.$emit("changeStatus", data);
    },
    openHistorical(data) {
      this.$emit("historical", data);
    },
    openChangeServPack(data) {
      this.$emit("changeServPack", data);
    },
    itemStatus(item_status, return_var) {
      let color = "";
      let icon = "";
      let text = "";
      switch (item_status) {
        case "draft":
        case "requested":
        case "activation_in_progress":
        case "trial_period":
        case "voluntary_suspension_in_progress":
        case "suspension_by_cut_in_progress":
          color = "warning";
          icon = "fas fa-spinner";
          text = this.$t("activate");
          break;
        case "active":
          color = "success";
          icon = "fas fa-check";
          text = this.$t("deactivate");
          break;
        case "retired":
        case "voluntarily_suspended":
        case "suspended_by_cut":
          color = "error";
          icon = "fas fa-ban";
          text = this.$t("activate");
          break;
      }
      switch (return_var) {
        case 1:
          return color;
        case 2:
          return icon;
        case 3:
          return text;
      }
    },
    transformMeasure (qty, measure) {
      let pos = 0;
      let tmp = measure.split('/')
      if (qty && qty > 1) {
        pos = 1
      }
      return pos in tmp ? tmp[pos] : measure
    },
    openEditDateModal (type, date) {
      this.editingDate = date
      this.editingDateType = type
      this.editDateModal = true
    },
    saveDate (newDate) {
      this.loading = true
      const id = this.item.service_installation_id
      const data = {}
      this.editingDateType === 'billing'
        ? data['billing_start_date'] = newDate
        : data['installation_date'] = newDate

      axios.patch(this.$store.getters.getServiceInstallation(id), data)
        .then(response => {
          this.editDateModal = false
          this.$emit('updateCard')
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'success',
            timeout: 4000,
            notifText: 'success',
            notifPos: 2,
            extra: {
              model: this.$t('service_install.billing_start'),
              action: this.$tc('updated', 2)
            }
          })
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    @import "./ServiceCard.scss";
  }
</style>
