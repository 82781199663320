import SubscriberDetails from '../../Subscribers/SubscriberDetails.vue';

export default {
  components:{
    SubscriberDetails
  },
  data(){
    return {
      initialized: false,
      nodata: true,
      subscribers:[],
      totalValues: 0,
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      subscriber_details: [],
      // Edit Subscriber Info Modal
      edit_subscriber: false,
      subscriber: [],
      unwatch: '',
      policies:{
        list_franchise: false,
        list_service: false,
        list_package: false,
        show_subscriber: false,
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0 : Number(this.$store.getters.getFranchiseID),
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0 : Number(this.$store.getters.getFranchiseID),
        status: 1,
      },
      step: 'tab-1',
      main_filters: {
        arr_filter: this.$route.params.arr_filter// Object.values(this.$route.params.arr_filter)
      }
    };
  },
  methods:{
    async getSubscribers () {
      this.subscribers = []
      this.totalValues = 0
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          filter: this.searchBy.search,
          status: this.searchBy.contract_status,
          arr_filter: this.main_filters.arr_filter
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      } else {
        this.options.sortBy[0] = 'subscriber.legal_denomination'
        ops.params.orderBy = this.options.sortBy[0]
      }
      axios.get(this.$store.getters.filterContractsPerServiceDetails, ops)
        .then(response => {
          this.nodata = false
          this.subscribers = response.data.success.data
          this.totalValues = response.data.success.total
        })
        .catch(err=>{
          this.subscribers = []
          this.totalValues = 0
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
        })
    },
    filterBy(item, type){
      this.options.page = 1;
      this.searchBy[type] = item
      switch (type) {
        case 'franchise_id':
          if (item) {
            delete this.main_filters.arr_filter.massives
            this.main_filters.arr_filter.frn = item
          } else {
            delete this.main_filters.arr_filter.frn
            this.main_filters.arr_filter.massives = 0
          }
          break
        case 'service_id':
          if (item) {
            this.main_filters.arr_filter.srv = item
          }
          break
        case 'package_id':
          if (item) {
            this.main_filters.arr_filter.pkg = item
          }
          break
      }
      this.getSubscribers()
    },
    eraseFilter(){
      this.searchBy = {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      }
      this.main_filters.arr_filter = { frn: null, srv: null, pkg: null}
      this.getSubscribers()
    },
    viewDetails (id) {
      this.$refs.subscriber_dialog.viewDetails(id)
    },
    PoliciesFilter(){
      this.policies.list_franchise = this.getPermissionGroup({
        permission:[
          'index_franchise'
        ],
        mode:'all'
      });
      this.policies.list_service = this.getPermissionGroup({
        permission:[
          'index_service'
        ],
        mode:'all'
      });
      this.policies.list_package = this.getPermissionGroup({
        permission:[
          'index_servicePackage'
        ],
        mode:'all'
      });
      this.policies.show_subscriber = this.getPermissionGroup({
        permission:[
          'show_subscriber'
        ],
        mode:'all'
      });
    },
  },
  watch:{
    options: {
      handler () {
        this.getSubscribers()
      },
      deep: true,
    }
  },
  computed:{
    headers () {
      let headers = [
        {
          text: this.$t('base.code'),
          align: 'center',
          value: 'subscriber.id',
          width: 86
        },
        {
          text: this.$tc('subscribers.code',2),
          align: 'left',
          value: 'code',
          width: 110
        },
        {
          text: this.$tc('subscribers.dni',2),
          align: 'left',
          value: 'subscriber.doc',
          width: 120
        },
        {
          text: this.$t('subscribers.name'),
          align: 'left',
          value: 'subscriber.legal_denomination',
        },
      ];
      if (!this.$store.getters.isFranchise && this.main_filters.classification != 'corporate') {
        headers.push(
          {
            text: this.$tc('franchise.company',2),
            value: 'franchise.full_name',
          }
        );
      }
      if (this.policies.show_subscriber) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false ,
            align: 'right',
            width: 100
          }
        )
      }
      return headers
    },
    computedMainFilters: {
      get () {
        if (this.main_filters.arr_filter) {
          this.searchBy.franchise_id = this.main_filters.arr_filter.frn
          this.searchBy.service_id = this.main_filters.arr_filter.srv
          this.searchBy.package_id = this.main_filters.arr_filter.pkg
          return this.main_filters.arr_filter
        }
        return this.main_filters
      },
      set (val) {
        //
      }
    }
  },
  beforeCreate () {
    if (!this.$route.params.arr_filter) {
      this.$router.push({name: 'filterContractsPerService'})
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
    this.PoliciesFilter()
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    //
  },
}
