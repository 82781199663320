<template>
  <div>
    <v-card text class="elevation-0">
      <v-card-text class="pa-0 pt-10">
        <v-data-table
          :headers="headers"
          :items="documents"
          :footer-props="{
            'items-per-page-options': [5, 10, 50],
          }"
          :server-items-length="totalValues"
          :options.sync="options"
          :loading="loadingTable"
          class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:[`item.issue_date`]="{ item }">
            {{
              $moment(item.issue_date).format($store.getters.getDateFormat[0])
            }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ $t("billing.status." + item.status) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ item.symbol }} {{ item.amount | formatAmount }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  edit
                  v-show="show_doc"
                  @click="getClientDocument(item)"
                  icon
                  v-on="on"
                >
                  <v-icon small>fas fa-eye</v-icon>
                   <!-- <v-icon small>fas fa-cash-register</v-icon> -->
                </v-btn>
              </template>
              <span>{{ $t("base.view_details") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- Vista previa -->
    <PreView ref="pre_view"/>
  </div>
</template>
<script>
import PreView from '../../components/HistoryBills/preview.vue';
export default {
  name: "SubscriberDoc",
  components: {
    PreView
  },
  props: {
    /** Tipo de documento */
    typeDoc: {
      type: String,
      default: "payment_note",
    },
    /** Estado del documento */
    statusDoc: {
      type: String,
      default: "pending",
    },
    /** ID del abonado */
    subscriber_id: {
      type: [Number, String],
      default: "",
    },
    /** ID del contrato */
    contract_id: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      show_doc: true,
      options: {
        sortBy: ["updated_at"],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      count: 0,
      perPage: 10,
      totalRates: 0,
      totalValues: 0,
      relationIndex: -1,
      documents: [],
      unwatch: "",
      symbol: "",
      totalAmount: 0,
      today: new Date()
        .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
        .substr(0, 10),
    };
  },
  computed: {
    headers(){
      let isInvoice = this.typeDoc == 'invoice'
      let headers = [
        {
          text: this.$t('billing.elaboration_date'),
          value: 'issue_date',
          align: 'center',
        },
        {
          text: isInvoice ? this.$t('billing.invoice_number') : this.$t('billing.control_number'),
          value: isInvoice ? 'document_number' : 'control_number',
          align: 'center',
        },
        {
          text: this.$t('billing.description'),
          value: 'remark',
          align: 'center',
        },
        {
          text: this.$t('billing.state'),
          value: 'status',
          align: 'center',
        },
        {
          text: this.$tc('amount',1),
          value: 'amount',
          align: 'right'
        },
      ];
      if (this.show_doc) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'center'
          }
        );
      }
      return headers;
    },
    ComputeType(){
      return this.$t('document_control.types.'+this.typeDoc);
    },
  },
  methods: {
    initSearchDocuments() {
      if (!this.isEmpty(this.typeDoc) && !this.isEmpty(this.subscriber_id)) {
        this.ListDocument();
      }
    },
    async ListDocument() {
      if (this.subscriber_id != "") {
        this.options.page = 1;
      }
      let symbol_base = this.$store.getters.getBaseCurrency;
      // eslint-disable-next-line no-console
      // console.log('hola', symbol_base);
      let symbol = "";
      if (symbol_base != null) {
        for (var key in symbol_base) {
          symbol = symbol_base[key].symbol;
        }
      } else {
        await this.getQueries();
        symbol = this.symbol;
      }
      this.loadingTable = true;
      let params = {
        page: this.options.page,
        paginate:
          typeof this.options.itemsPerPage == "number"
            ? this.options.itemsPerPage
            : 10,
        sort_by: this.options.sortBy[0] || null,
        sort_desc: this.options.sortDesc[0] || null,
        type: this.typeDoc,
        status: this.statusDoc,
        subscriber_id: this.subscriber_id,
        symbol: symbol,
      };
      this.$store
        .dispatch("DocList", params)
        .then((response) => {
          if (this.contract_id) {
            const client_documents = [...response.client_documents];
            /** Se filtran los documentos por contrato */
            const documentsByContract = client_documents.filter(doc => { return doc.contract_id == this.contract_id });
            this.documents = documentsByContract;
            /** Se calcula el total de documentos por contrato */
            const Total = documentsByContract.length;
            this.totalValues = Total;
          }
          // eslint-disable-next-line no-console
          // console.log("documents", this.documents, this.contract_id, this.totalValues);
        })
        .catch((error) => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log('Error', error.response);
          }
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async getQueries() {
      await this.$store
        .dispatch("DocQuerys")
        .then((response) => {
          this.symbol = response.symbol;
        })
        .catch((error) => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error.response);
          }
        });
    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      // console.log(item);
      /** TODO: Se comenta por ahora
       *  Esta parte del codigo permite mostrar la vista de pagos de notas de cobro
       */
      // let preview = null;
      // if (item.type =='payment_note') {
      //   preview = false;
      // } else if (item.type =='invoice') {
      //   preview = true;
      // }
      axios.get(this.$store.getters.getClientDocument(item.id))
      .then((response)=>{
        if (response.status == 200) {
          // eslint-disable-next-line no-console
          // console.log(response.data);
          // Le pasamos el simbolo al modal para evitar una consulta
          response.data.symbol = this.symbol;
          this.$refs.pre_view.open(response.data, true);
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      });
    },
    /** Permisos necesarios para mostrar el documento */
    showDocument() {
      this.show_doc = this.getPermissionGroup({
        permission: [
          "show_clientDocument",
          "makeClientDocumentPDF_clientDocument",
          "makePDF_clientDocument",
          "sendClientDocumentEmail_clientDocument",
          "index_employee",
          "store_proofPayment",
        ],
        mode: "all",
      });
    },
    isEmpty(value) {
      if (value == null || value == undefined || value == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  created() {},
  beforeDestroy() {},
  mounted() {
    this.initSearchDocuments();
    this.showDocument();
  },
};
</script>
