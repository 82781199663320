import SubscriberSearch from "../../components/Subscriber/suscriberSearch.vue";

const colorMsg = [
  "background: black",
  "color: #b6ff05",
  "font-size: 12px",
  "font-weight: bold",
  "padding: 5px"
].join(";");
const colorMsg2 = [
  "background: #222",
  "color: yellow",
  "font-size: 12px",
  "font-weight: bold",
  "padding: 5px"
].join(";");

const colorMsg3 = [
  "background: grey",
  "color: red",
  "font-size: 12px",
  "font-weight: bold",
  "padding: 5px"
].join(";");

export default {
  name: "dialogMoneyReceived",
  components: {
    SubscriberSearch
  },
  computed: {
    titleMoney() {
      if (!this.edit) {
        return this.$t("money_received.money_receive");
      }
      return `${this.$t("base.edit")} ${this.$t(
        "money_received.money_received"
      )}`;
    },
    selectedSourses() {
      return this.sources.map(source => {
        return {
          value: source.value,
          text: this.$t(source.text)
        };
      });
    },
    selectedPaymentMethods() {
      return this.payment_methods.map(method => {
        return {
          value: method.value,
          text: this.$t(method.text)
        };
      });
    },
    computedIcon() {
      return this.moneyReceived.exchange_rate
        ? "fas fa-" + JSON.parse(this.moneyReceived.exchange_rate).icon
        : null;
    }
  },
  data() {
    return {
      loading: false,
      loadingMoney: false,
      disableMoney: false,
      dialogMoney: false,
      fullscreen: false,
      edit: false,
      has_temp_file: false,
      moneyReceived: {},
      moneyReceived_edit: "",
      moneyDefault: {
        franchise_id:
          this.$store.getters.getFranchiseID == null
            ? null
            : this.$store.getters.getFranchiseID,
        user_id:
          this.$store.getters.dataUser == null
            ? 0
            : this.$store.getters.dataUser.pkUser,
        amount: 0,
        reception_date: new Date()
          .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
          .substr(0, 10),
        status: "pending",
        currency_code: "",
        exchange_rate: "",
        bank_id: null
      },
      sources: [],
      payment_methods: [],
      exchangeRates: [],
      baseCurrency: {},
      banks: [],
      listBanks: "",
      payValidation: {
        required: false
      },
      view_only: false,
      statuses: [],
      idExchangeRate: 0,
      attachmentURL: "",
      decimal_places: 2
    };
  },
  watch: {
    "moneyReceived.payment_method": {
      handler(val) {
        if (val != "" && typeof val == "string") {
          if (val != "cash") {
            this.payValidation = {
              required: true
            };
          } else {
            this.payValidation = {
              required: false
            };
          }
        }
      }
    },
    "moneyReceived.exchange_rate": {
      handler(val) {
        this.setCurrencyCode(val);
      }
    }
  },
  methods: {
    openDialog(editMode, item, viewOnly) {
      this.edit = editMode;
      if (!editMode) {
        this.dialogMoney = true;
        this.AddMoney();
      } else {
        this.EditMoney(item);
      }
      this.view_only = viewOnly
    },
    AddMoney() {
      this.moneyReceived = Object.assign({}, this.moneyDefault);
    },
    EditMoney(moneyReceived) {
      this.idExchangeRate = moneyReceived.exchange_rate.id;
      this.moneyReceived_edit = JSON.stringify(moneyReceived);
      this.moneyReceived = Object.assign({}, moneyReceived);
      this.moneyReceived.exchange_rate = JSON.stringify(
        moneyReceived.exchange_rate
      );
      this.attachmentURL = this.moneyReceived.attachment
        ? this.moneyReceived.attachment
        : null;
      delete this.moneyReceived.attachment;
      this.dialogMoney = true;
    },
    close() {
      this.$refs.form_money.reset();
      if (!this.edit) {
        this.$refs.subscriber_search.clear2();
      }
      this.$refs.exchange_rate.clearField();
      this.idExchangeRate = 0;
      this.moneyReceived = Object.assign({}, this.moneyDefault);
      this.decimal_places = 2;
      this.dialogMoney = false;
      this.attachmentURL = "";
      this.view_only = false
    },
    save() {
      this.$refs.form_money.validate().then(success => {
        if (success) {
          let moneyReceived = Object.assign({}, this.moneyReceived);
          let extra = this.moneyReceived.extra;
          moneyReceived.extra = JSON.stringify(extra);
          this.getFormData(moneyReceived);
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    create(moneyReceived) {
      this.loadingMoney = true;
      axios
        .post(this.$store.getters.getMoneyReceived(), moneyReceived, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          const text = this.$t("money_received.money_received");
          this.$emit("AddMoney");
          this.pushNoty(text, "created", 1);
          this.close();
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            console.log("%cError", colorMsg3, error.response);
          }
        })
        .finally(() => {
          this.loadingMoney = false;
        });
    },
    update(moneyReceived) {
      this.loadingMoney = true;
      axios
        .post(
          this.$store.getters.getMoneyReceived(this.moneyReceived.id),
          moneyReceived,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          if (response.status == 200) {
            this.$emit("AddMoney");
            const text = this.$t("money_received.money_received");
            this.pushNoty(text, "updated", 1);
            this.close();
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            console.log("%cError", colorMsg3, error.response);
          }
        })
        .finally(() => {
          this.loadingMoney = false;
        });
    },
    setCurrencyCode(item) {
      try {
        // Parseamos el JSON
        const exchange_rate = JSON.parse(item);
        // Obtenemos el currency_code
        const currency_code = exchange_rate.currency_code;
        this.decimal_places = exchange_rate.decimal_places;
        // Fijamos el currency_code en el objeto a enviar al backend
        this.moneyReceived.currency_code = currency_code;
        // Parseamos la lista de bancos
        const banks = JSON.parse(this.listBanks);
        // Fieltramos la lista de bancos
        this.banks = banks.filter(banks => {
          return banks.currency_code === currency_code;
        });
      } catch (error) {
        this.moneyReceived.currency_code = "";
        this.banks = [];
        this.decimal_places = 2;
      }
    },
    loadImg(e) {
      if (e) {
        if (
          !["image/png", "image/jpeg", "image/jpg", "application/pdf"].includes(
            e.type
          )
        ) {
          this.$awn.alert(this.$tc("error422.file_path_must_type", 0, []), {
            durations: { alert: 6000 }
          });
          this.clearFile();
        } else if (e.size > 4194304) {
          this.$awn.alert(this.$tc("error422.file_path_max_4096_kb", 0, []), {
            durations: { alert: 6000 }
          });
          this.clearFile();
        } else {
          this.moneyReceived.attachment = e;
          this.has_temp_file = true;
        }
      }
    },
    clearFile() {
      this.has_temp_file = false;
      this.moneyReceived.attachment = null;
      this.$refs.attachment.reset();
      this.$refs.attachment.$refs.input.value = "";
      this.$refs.attachment.blur();
    },
    getJSON(data) {
      if (!!data && typeof data === "object") {
        return JSON.stringify(data);
      } else {
        return data;
      }
    },
    getFormData(moneyReceived) {
      let form_data = new FormData();
      for (let key in moneyReceived) {
        if (
          key !== "bank" &&
          key !== "bank_account" &&
          key !== "employee" &&
          key !== "franchise" &&
          key !== "subscriber" &&
          key !== "user" &&
          key !== "client_document" &&
          moneyReceived[key] != null
        ) {
          form_data.append(key, moneyReceived[key]);
        } else if (key == "exchange_rate") {
          const exchange_rate = this.getJSON(moneyReceived.exchange_rate);
          form_data.append(key, exchange_rate);
        } else if (key == "attachment") {
          if (this.has_temp_file) {
            form_data.append("attachment", moneyReceived.attachment);
          }
        }
      }
      if (this.edit) {
        form_data.append("_method", "PATCH");
      }
      if (this.edit == false) {
        this.create(form_data);
      } else {
        this.update(form_data);
      }
    },
    /**
     * Funcion que recibe al Abonado seleccionado
     * @param {contract, subscriber_id, text} item
     */
    changeSubsCriber(item) {
      if (item) {
        this.moneyReceived.subscriber_id = item.subscriber_id;
      }
    },
    /**
     * Funcion que elimina el contacto seleccionado
     */
    cancelSubscriber() {
      this.moneyReceived.subscriber_id = null;
    },
    /**
     * Funcion para consultar los querys necesarios
     */
    getQuerys() {
      this.$store.dispatch("DocQuerys")
        .then(response => {
          this.sources = response.sourcesMoneyReceived;
          this.payment_methods = response.paymentMethods;
          this.exchangeRates = response.ExchangeRates;
          this.baseCurrency = response.baseCurrency;
          this.listBanks = JSON.stringify(response.Banks);
          this.statuses = response.statusMoneyReceived
          this.$emit('fillSources', this.sources)
          this.$emit('fillPaymentMethods', this.payment_methods)
          this.$emit('fillStatuses', this.statuses)
        })
        .catch(error => {
          if (error.response) {
            //
          }
        });
    },
    /**
     * Funcion de notificaciones
     * @param {*} model
     * @param {*} action
     * @param {Number} pos
     * @param {Number} time Tiempo de la notificaion
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
    openAttachment() {
      this.downloadFile("window", this.attachmentURL);
    }
  },
  mounted() {
    this.getQuerys();
  }
};
