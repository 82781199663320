import JsonDialog from './JsonConfig.vue'

export default {
  components:{
    JsonDialog
  },
  props:{
    contract_id: {
      type:[Number],
      required: true
    },
    subscriber_id: {
      type:[Number],
      required: true
    },
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'service_installation'){
        this.$emit('changeModel')
      }
    })
  },
  beforeDestroy () {
    this.date_installation = false
    this.$emit('hasDate', null)
    this.unwatch()
  },
  computed:{
    computedInstallationDate: {
      get () {
        return this.formatDate(this.service.installation_date)
      },
      set (val) {
        this.service.installation_date = val
      }
    },
  },
  data:() => ({
    details: [],
    date_installation:false,
    unwatch: '',
    tab: 'services',
    edit: false,
    clone:{},
    service : {
      'observations':'',
      'installation_date':'',
    },
    loading: false,
    loadingService: false,
    loadingMaterial: false,
    loadingInstallation: false,
    loadingFile: false,
    serviceConfig: [],
    materialService: [],
    loaded_file2: false,
    file2: {},
    found_file2: true,
    file_exists: false,
    has_temp_file: false,
    contract: {},
    today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
    required_configs: [],
    filled_configs: [],
    enable_completion: null,
  }),
  watch:{
    /*'service.installation_date':{
      handler(date){
        if(date !== null) {
          this.$emit('hasDate', date)
        }
      }
    }*/
    serviceConfig: {
      handler(val) {
        this.filled_configs = []
        this.required_configs = []
        val.forEach(elm => {
          if (elm.wan_config) {
            elm.wan_config.forEach(conf => {
              Object.keys(conf).forEach((key) => {
                if (conf[key]) {
                  this.filled_configs.push(key)
                }
              })
            })
          }

          if (elm.service) {
            let parent_configs = []
            if (elm.service.parent && elm.service.parent.configurations) {
              elm.service.parent.configurations.forEach(p_conf => {
                if (p_conf.required) {
                  this.required_configs.push(this.toSnakeCase(p_conf.name))
                }
              })
            }
            if (elm.service.configurations) {
              elm.service.configurations.forEach(c_conf => {
                if (c_conf.required) {
                  this.required_configs.push(this.toSnakeCase(c_conf.name))
                }
              })
            }
          }
        })

        let count = 0
        this.required_configs.forEach(x => {
          if (this.filled_configs.includes(x)) {
            count++
          }
        })
        if (count == this.required_configs.length) {
          this.enable_completion = true
        } else {
          this.enable_completion = false
        }
      },
      deep: true
    },
    enable_completion (val) {
      this.$emit('enableCompletion', val)
    }
  },
  methods:{
    toSnakeCase (val) {
      return val/*.toLowerCase()*/.replace(' ', '_')
    },
    editMode(val){
      this.edit = val
      if (!val) {
        this.$validator.reset()
      }
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    assignModel(evt){
      let modelUpdated = ""
      switch (evt.field){
        case 'wan_config':
        case 'lan_config':
          modelUpdated = 'serviceConfig'
          break;
        case 'config':
          modelUpdated = 'materialService'
          break;
      }
      let index = this[modelUpdated].findIndex(m => m.id === evt.model.id)
      if(index > -1){
        this.$set(this[modelUpdated],index,evt.model)
      }
    },
    load () {
      this.getServiceInstallation()
      this.getServiceContract(this.contract_id)
      this.getServiceMaterial(this.contract_id)
      this.loadPDF(this.contract_id, 'installation_order')
    },
    postService(){
      this.$validator.validateAll()
      .then(() => {
        if (this.errors.all() == '') {
          this.loading = true
          axios.put(this.$store.getters.getServiceInstallation(this.service.id),this.service)
          .then(response => {
            this.edit = false
            this.$emit('hasDate', this.service.installation_date)
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    loadPDF (contract_id, file_nm) {
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
              'contract_id': contract_id,
              'name': file_nm
          }
        })
        .then(response => {
          if (response.data.files) {
            if (response.data.files.data.length > 0) {
              this.file2 = response.data.files.data[0]
              this.loaded_file2 = true
              this.file_exists = true
              this.found_file2 = true
            } else {
              this.file2.file_name = 'installation_order'
              this.loaded_file2 = true
              this.file_exists = false
              this.found_file2 = false
            }
          }
        })
        .catch(err => {
          this.file2 = {}
          this.loaded_file2 = false
        })
        .finally(()=>{
          //
        })
    },
    async getServiceContract(contract){
      this.loadingService = true
      let response = await axios.get(this.$store.getters.getContractServices(),{
        params:{
          contract: contract,
          paginate:'All'
        }
      })
      this.serviceConfig = response.data.package
      this.loadingService = false
    },
    async getServiceMaterial(contract){
      this.loadingMaterial = true
      let response = await axios.get(this.$store.getters.getContractInstalledHardware(),{
        params:{
          contract_id: contract,
          paginate:'All'
        }
      })
      this.materialService = response.data.hardware
      this.loadingMaterial = false
    },
    async getServiceInstallation(){
      this.loadingInstallation = true
      let response = await axios.post(this.$store.getters.getServiceInstallation(), {
        contract_id: this.contract_id,
        status: 'migration'
      })
      this.service = response.data.service
      this.$emit('hasDate', response.data.service.installation_date)
      this.loadingInstallation = false
    },
    validateFile (e) {
      if (e) {
        if (!['image/png','image/jpeg','image/jpg','application/pdf'].includes(e.type)) {
          this.$awn.alert(this.$tc('error422.file_path_must_type', 0, []), {durations:{alert: 6000}})
          this.clearInput()
        } else if (e.size > 4194304) {
          this.$awn.alert(this.$tc('error422.file_path_max_4096_kb', 0, []), {durations:{alert: 6000}})
          this.clearInput()
        } else {
          this.file2.file = e
          this.has_temp_file = true
        }
      }
    },
    clearInput () {
      this.has_temp_file = false
      this.$refs.signed_letter.reset()
      this.$refs.signed_letter.$refs.input.value = ''
    },
    loadFile () {
      this.loadingFile = true
      let form_data = new FormData()
      let id = null
      form_data.append('subscriber_id', this.subscriber_id)
      form_data.append('contract_id', this.contract_id)
      form_data.append('file_name', 'installation_order')
      form_data.append('file_path', this.file2.file)
      if (this.file_exists) {
        id = this.file2.id
        form_data.append('_method', 'PATCH')
      }
      axios.post(this.$store.getters.getSubscriberFilesLibrary(id), form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success) {
            this.clearInput()
            this.found_file2 = true
            this.file_exists = true
            this.file2 = response.data.success
            this.$awn.success(
              this.$tc('success', 1,
                {
                  model: this.$tc('file_library.file',1),
                  action: this.$tc('loaded',1)
                }
              )
            , {durations:{alert: 6000}})
          } else {
            this.$awn.alert(this.$tc('base.something_wrong', 0, []), {durations:{alert: 6000}})
          }
        })
        .catch((err) => {
          this.$awn.alert(this.$tc('base.something_wrong', 0, []), {durations:{alert: 6000}})
        })
        .finally(() => {
          this.loadingFile = false
        })
    }
  },
  mounted () {
    this.load()
  }
}
