<template>
  <v-row>
    <v-col v-if="no_data" cols="12" class="text-center pa-0 pb-1">
      <v-progress-circular
        :size="40"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br>
      <span class="caption primary--text pl-3">
        {{$t('base.loading')}}...
      </span>
    </v-col>
    <template v-else>
      <v-col cols="12" sm="12" md="6" lg="4"
        v-for="contact in showContacts"
        :key="contact.type"
        class="pt-0 pb-3 d-flex "
      >
        <v-card>
          <v-card-title class="d-flex justify-space-between exclude-outlined-styles">
            <span class="px-1 subtitle-1 primary--text font-weight-bold">
              {{ $i18n.locale == 'es' ? contact.type == 'legal' ? $t('subscribers.info')+' '+$tc('subscribers.'+contact.type,2) : $t('subscribers.info')+' '+$tc('subscribers.'+contact.type,1) : $tc('subscribers.'+contact.type,1)+' '+$t('subscribers.info') }}
            </span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="editable && (permissions.editContact || permissions.editAddress)"
                  v-on="on"
                  edit icon
                  @click="editAddress(contact.obj)"
                >
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('base.edit') }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="body-0">
            <b>{{ $tc('subscribers.dni',0) }}:</b> {{contact.dni}} <br>
            <b>{{$t('subscribers.first_name')+'/'+$t('subscribers.last_name')}}:</b> {{contact.name}} <br>
            <b>{{ $tc('actor.position',1) }}:</b> {{contact.position}} <br>
            <span v-if="contact.local_phone">
              <b>{{ $tc('phone',1) }}:</b> {{contact.local_phone}}<br>
            </span>
            <b>{{ $tc('base.email',2) }}:</b> {{contact.email}} <br>
            <b>{{ $tc('cellphone',1) }}:</b> {{contact.cellphone}}<br>
            <b>{{ $tc('address',1) }}:</b> {{contact.address}}
            <span v-if="contact.geo_location">
              <br><b>{{ $tc('contracts.coordinates',2) }}: </b>
              <a :href="`https://www.google.com/maps/@${contact.geo_location.coordinates[1]},${contact.geo_location.coordinates[0]},17z`" target="_blank"><b>{{contact.geo_location.coordinates[1]}}</b>, <b>{{contact.geo_location.coordinates[0]}}</b>
              </a>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <DialogContactAddress
      :permissions="permissions"
      @updateContract="getAll"
      @techAddressChanged="techAddressChanged"
      ref="contact_address"
    />
  </v-row>
</template>
<script>

import DialogContactAddress from '../../components/ContactAddress/DialogContactAddress.vue'

export default {
  props:['contract', 'editable'],
  components:{
    DialogContactAddress
  },
  data:() => ({
    addresses: [],
    contacts: [],
    loading1: false,
    loading2: false,
    permissions:{
      editContact: false,
      editAddress: false,
    },
    tech_address_changed: false
  }),
  methods:{
    getAddress(){
      this.loading1 = true
      axios.get(this.$store.getters.getContractAddresses(),{
        params:{
          contract:this.contract.id
        }
      })
      .then(res => {
        this.addresses = res.data.address
        if (this.tech_address_changed) {
          let tech_address = this.addresses.find(d => d.type === 'tech')
          this.$emit('techAddressChanged', tech_address.contract_id, tech_address.full_address)
          this.tech_address_changed = false
        }
      })
      .finally(() => {
        this.loading1 = false
      })
    },
    getContacts(){
      this.loading2 = true
      axios.get(this.$store.getters.getContractContacts(),{
        params:{
          contract:this.contract.id
        }
      })
      .then(res => {
        this.contacts = res.data.contact
      })
      .finally(() => {
        this.loading2 = false
      })
    },
    editAddress(item){
      this.$refs.contact_address.open_dialog(item);
    },
    getAll(){
      this.getContacts();
      this.getAddress();
    },
    techAddressChanged(){
      this.tech_address_changed = true
    },
    PermissionsContactInfo(){
      this.permissions.editContact = this.getPermissionGroup({
        permission:[
          'update_contractContact',
        ],
        mode:'all'
      });
      this.permissions.editAddress = this.getPermissionGroup({
        permission:[
          'update_contractAddress',
          'index_employee',
        ],
        mode:'all'
      });
    }
  },
  computed:{
    showContacts(){
      if (this.contract.franchise_id) {
        let address = this.addresses.map(address => {
          let contact = this.contacts[0]
          let last_name = contact.last_name == '...' ? '' : contact.last_name
          return {
            name: `${contact.first_name} ${last_name}`,
            dni: contact.dni,
            cellphone: contact.cellphone,
            email: contact.email,
            position: contact.position,
            address: address != undefined ? address.full_address : '',
            geo_location: address.geo_location != undefined ? address.geo_location : '',
            type: address.type,
            obj: {
              contact: contact,
              address: address
            },
          }
        })
        return address
      } else {
        let contacts = this.contacts.map(m => {
          let address = this.addresses.find(d => d.type == m.type)
          return {
            name: `${m.first_name} ${m.last_name}`,
            dni: m.dni,
            cellphone:m.cellphone,
            email:m.email,
            position:m.position,
            address:address != undefined ? address.full_address : '',
            geo_location:address.geo_location != undefined ? address.geo_location : '',
            type:m.type,
            obj: {
              contact:m,
              address: address
            },
          }
        })
        return contacts
      }
    },
    no_data () {
      return this.loading1 || this.loading2 ? true : false
    }
  },
  mounted(){
    this.PermissionsContactInfo();
    this.getContacts()
    this.getAddress()
  }
}
</script>
