var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('FeasibilityDialogForm',{ref:"details_dialog",on:{"created":_vm.getFeasibilitiesNetwork}}),_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","color":"grey","show-arrows":"","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.tab_val),callback:function ($$v) {_vm.tab_val=$$v},expression:"tab_val"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('service_sale.awaiting'))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-file-contract")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('feasibility.active_reports'))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-file-signature")])],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('feasibility.closed_reports'))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-file-archive")])],1),_c('v-tab-item',{attrs:{"value":_vm.tab_val}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-0 contracts-table",attrs:{"headers":_vm.headers,"items":_vm.contracts,"hide-default-header":_vm.nodata,"footer-props":{
                      'items-per-page-options': [10, 50, 100, 500, 1000]
                    },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pl-0",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[(!_vm.expand)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('requests.requests',2))+" ")]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.expand = false}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-left")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-expand-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"hidden-search",attrs:{"flat":"","height":"40","width":_vm.$vuetify.breakpoint.smAndDown ? '75%': _vm.$vuetify.breakpoint.lgAndUp ? '30%':'40%'}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0 py-5",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],ref:"search",attrs:{"dark":"","name":"filter","error-messages":_vm.errors.collect('filter'),"label":_vm.$t('base.search'),"single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1)],1),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.expand},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.expand ? _vm.search():_vm.expandIt()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-search")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('base.search')))])])],1)]},proxy:true},(_vm.tab_val == 'tab-1')?{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.toString().length < 5 ? ("00000" + item.id).slice(-5) : item.id)+" ")]}}:{key:"item.contract_id",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.contract_id.toString().length < 5 ? ("00000" + item.contract_id).slice(-5) : item.contract_id)+" ")]}},{key:"item.status",fn:function(ref){
                    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("feasibility." + value)))+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.policies.show),expression:"policies.show"}],class:_vm.$i18n.locale == 'es' ? 'mr-5':'mr-4',attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.viewDetails(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.tab_val == 'tab-1' ? 'fas fa-file-contract' : _vm.tab_val == 'tab-2' ? 'fas fa-file-signature' : 'fas fa-file-archive')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }