import ConfigService from "./ConfigService.vue";

export default {
  props: {
    sendPack: {
      type: Boolean,
      default: false
    },
    onlyConfig: {
      type: Boolean,
      default: false
    },
    servPack_id:{
      type: Number,
      default: 0
    },
    // Determines if it is a Service or a Package that is being changed.
    serv_or_pack:{
      type: String,
      default: ''
    },
    // Determines if it's forced service/package add.
    isForcedAdd:{
      type: Boolean,
      default: false
    }
  },
  components: {
    configservice: ConfigService
  },
  data() {
    return {
      dialog: false,
      list: false,
      tab: "services",
      mode: "package",
      valuesForSelect: [],
      page: 1,
      perPage: 10,
      totalValues: 0,
      search: "",
      last_page: 0,
      pk: "",
      loading: false,
      sortBy: "",
      sortDesc: false,
      specification:{},
    };
  },
  watch: {
    dialog(val) {
      val ? this.getSelected() : "";
    },
    perPage() {
      this.page = 1;
      this.getSelected();
    },
    page() {
      this.getSelected();
    },
    sortDesc() {
      this.getSelected();
    },
    sortBy() {
      this.getSelected();
    }
  },
  computed: {
    orders() {
      return [
        {
          text: this.$t("code"),
          value: "code"
        },
        {
          text: this.$t("name"),
          value: "name"
        },
        {
          text: this.$t("description"),
          value: "description"
        },
        {
          text: this.$tc("active", 0),
          value: "active"
        }
      ];
    }
  },
  methods: {
    tabChange() {
      this.getSelected();
    },
    searchValue(value) {
      this.search = value;
      this.page = 1;
      this.getSelected();
    },
    open(mode, value) {
      this.$store.getters.isFranchise ? (this.tab = "packages") : "";
      if (this.tab != "packages" && this.onlyConfig && this.serv_or_pack == 'package') {
        this.tab = "packages"
      }
      this.mode = mode;
      this.pk = value;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.specification = {};
      !this.$store.getters.isFranchise && this.tab != "services"
        ? (this.tab = "services")
        : "";
    },
    /**
     * Function to assign a service or return the service
     * @param {*} specification
     * @param {pk} pk
     * @param {mode} mode
     */
    assign_service(specification, pk, mode, onlyConfig){
      this.specification = JSON.stringify(specification);
      this.$refs.configservice.open(specification, pk, mode, onlyConfig);
    },
    /**
     * Function to assign a package or return the package
     * @param {pack} pack
     * @param {package} data
     */
    assign_package(pack, data) {
      if (!this.sendPack) {
        axios
          .post(
            this.$store.getters.getContractPackage({
              contract: this.pk,
              pack: pack
            })
          )
          .then(response => {
            this.$emit("configService");
            let notif = this.$store.getters.isFranchise
              ? this.$t("package.service_added_for_contract")
              : this.$t("package.package_added_for_contract");
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 4000,
              notifText: notif,
              notifPos: 1,
              extra: null
            });
            if (this.isForcedAdd) {
              this.$emit("forcedAddCompleted", this.pk)
            }
          });
      } else {
        this.$emit("selectedServPack", data);
        this.close();
      }
    },
    getSelected() {
      this.loading = true;
      var url =
        this.tab == "services"
          ? this.$store.getters.getService()
          : this.$store.getters.getPackage();
      let param = {
        page: this.page,
        paginate: this.perPage,
        param: true
      };
      if (this.search != "" && this.search != undefined) {
        param["search"] = this.search;
      }
      if (this.sortBy != "") {
        param["sortBy"] = this.sortBy;
        param["sortDesc"] = this.sortDesc;
      }
      if (this.mode == "contract") {
        param.contract = this.pk;
      }
      if (this.onlyConfig) {
        param.only_config = this.servPack_id;
      }

      axios
        .get(url, {
          params: param
        })
        .then(response => {
          let valuesForSelect = [];
          if (this.tab == "services") {
            this.last_page = response.data.service.last_page;
            this.totalValues = response.data.service.total;
            valuesForSelect = response.data.service.data.filter(serv => serv.id != this.servPack_id);
          } else {
            this.last_page = response.data.package.last_page;
            this.totalValues = response.data.package.total;
            valuesForSelect = response.data.package.data.filter(pack => pack.id != this.servPack_id);
          }
          this.valuesForSelect = valuesForSelect;
        })
        .catch(err => {
          this.valuesForSelect = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    configServPack(data){
      // eslint-disable-next-line no-console
      // console.log("data",data);
      const items = {...JSON.parse(this.specification)};
      items.qty = data.qty;
      items.price = data.price;
      items.contract_id = data.contract_id;
      items.service_param = data.service_param;
      // eslint-disable-next-line no-console
      // console.log(items);
      this.$emit("selectedServPack", items);
      this.close();
    },
    emitCompleted (contract_id) {
      if (this.isForcedAdd) {
        this.$emit('forcedAddCompleted', contract_id)
      } else {
        this.$emit('configService')
      }
    }
  }
};
