var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[_c('servicespecs',{ref:"specifics"}),_c('dialservice',{ref:"dialservice",on:{"serviceSaved":function($event){return _vm.getService()}}}),_c('dialparam',{ref:"dialparam"}),_c('dialconfig',{ref:"dialconfig",on:{"configSaved":function($event){return _vm.getService()}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.services,"page":_vm.page,"items-per-page":_vm.perPage,"footer-props":{
            'items-per-page-options':[5,10,15,20]
          },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalValues,"loading":_vm.loadingServiceTable},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(!_vm.expand)?_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc('service.service',2))+" ")]):_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":_vm.expandIt}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-left")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],1),_c('div',{staticClass:"flex-grow-1"}),(!_vm.expand)?_c('v-btn',{attrs:{"text":"","fab":!_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){return _vm.$refs.dialservice.open()}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('service.new')))]),_c('v-icon',[_vm._v("fas fa-plus")])],1):_vm._e(),_c('v-expand-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"hidden-search",attrs:{"flat":"","height":"40","width":_vm.$vuetify.breakpoint.smAndDown ? '75%': _vm.$vuetify.breakpoint.lgAndUp ? '30%':'40%'}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0 py-5",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],ref:"search",attrs:{"dark":"","name":"filter","error-messages":_vm.errors.collect('filter'),"label":_vm.$t('base.search'),"single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1)],1),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.expand},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.expand ? _vm.search():_vm.expandIt()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-search")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('base.search')))])])],1)]},proxy:true},{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}]},[_vm._v(" "+_vm._s(item.active ? _vm.$tc('active',0) : _vm.$tc('inactive',0))+" ")]),_c('v-icon',{attrs:{"x-small":"","right":"","color":item.active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.active ? 'fa-check' : 'fa-times')+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.specifics.open(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-clipboard")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('service.attributes',1)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.dialparam.open(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-layer-group")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('service.params',1)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"gray","icon":""},on:{"click":function($event){return _vm.$refs.dialconfig.open(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-cogs")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('configs')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"warning","icon":""},on:{"click":function($event){return _vm.$refs.dialservice.open(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('service.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.removed(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('service.delete')))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }