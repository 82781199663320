<template>
  <div style="position: sticky; top: 2rem">
    <v-card
      outlined
      class="elevation-0 step-inner card-outlined"
    >
      <v-card-title>
        <div class="title primary--text">
          <span class="px-1 white">{{ $t("preview") }}</span>
        </div>
      </v-card-title>
      <v-card-text class="py-0">
        <v-container class="py-1 px-0">
          <v-row>
            <v-col md="12">
              <v-card class="mx-auto">
                <v-app-bar dark :color="company.color1">
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>
                  <v-avatar tile v-if="company.head_logo" class="mr-2">
                    <img
                      :src="
                        av_head_logo != ''
                          ? av_head_logo
                          : company.head_logo != null
                          ? company.head_logo
                          : ''
                      "
                      :alt="company.name_company"
                    />
                    <!-- :src="company.id != undefined ? require(`@/assets/company/${ company.id }/${ company.head_logo}`) : ''" -->
                  </v-avatar>
                  <v-avatar v-else class="avatar-border mx-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon large v-on="on">fa-camera</v-icon>
                      </template>
                      <span>Logo</span>
                    </v-tooltip>
                  </v-avatar>
                  <v-toolbar-title class="text-uppercase">
                    <span v-if="company.name_company">{{
                      company.name_company
                    }}</span>
                    <v-tooltip right v-else>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ $t("company.name_company") }}</span>
                      </template>
                      <span>{{ $t("company.name_company") }}</span>
                    </v-tooltip>
                  </v-toolbar-title>
                  <div class="flex-grow-1"></div>
                  <v-toolbar-items>
                    <v-btn text v-if="company.name" class="pl-2 pr-1">
                      <v-icon small class="mr-1">fas fa-user</v-icon>
                      {{ company.name }}
                    </v-btn>
                  </v-toolbar-items>
                </v-app-bar>
                <v-row class="pr-3">
                  <!-- <v-col :cols="fullscreen ? 4 : 3" class="py-0"> -->
                  <v-col cols="3" sm="4" md="5" lg="4" class="py-0">
                    <v-list
                      rounded
                      :width="$vuetify.breakpoint.mdAndUp ? '100%' : '60'"
                      elevation="1"
                      height="100%"
                      class="pa-1 pt-4"
                    >
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(item, i) in [
                            {
                              text: 'DSB.menu.company',
                              icon: 'fas fa-building'
                            },
                            {
                              text: 'DSB.menu.dashboard',
                              icon: 'fas fa-tachometer-alt'
                            }
                          ]"
                          :key="i"
                        >
                          <v-list-item-icon class="mx-auto ml-md-n1 mr-md-1">
                            <v-icon
                              small
                              v-text="item.icon"
                              class="mx-auto mx-sm-0"
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="$t(item.text)"
                              v-show="fullscreen"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col
                    class="pt-0"
                    style="min-height: 20rem;
                    max-height: calc(80vh - 120px);
                    overflow: hidden auto"
                  >
                    <!-- <h1 class="text-center py-4">{{company.name}}</h1> -->
                    <!-- Preview logo -->
                    <!-- <v-img aspect-ratio="3" contain></v-img> -->
                    <!-- :src="company.id != undefined ? require(`@/assets/company/${ company.id }/${ company.logo}`) : ''" -->
                    <v-list class="mt-1 mb-4">
                      <!-- Firm -->
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="$t('company.firm')"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-if="!company.firm">{{
                            $t("not_assigned")
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar
                          tile
                          v-if="company.firm"
                          class="my-0"
                        >
                          <v-img
                            contain
                            :src="
                              av_firm != ''
                                ? av_firm
                                : company.firm != null
                                ? company.firm
                                : ''
                            "
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-divider></v-divider>
                      <!-- Seal -->
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="$t('company.seal')"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-if="!company.firm">{{
                            $t("not_assigned")
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar
                          tile
                          v-if="company.seal"
                          class="my-0"
                        >
                          <v-img
                            contain
                            :src="
                              av_seal != ''
                                ? av_seal
                                : company.seal != null
                                ? company.seal
                                : ''
                            "
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-divider></v-divider>
                      <!-- Printable Logo -->
                      <v-list-item two-line v-if="!company.logo">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="$t('company.printable_logo')"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $t("not_assigned") }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-else class="document-preview mt-3 mb-4 mx-auto">
                        <span class="v-label font-weight-light">
                          {{ $t("company.printable_logo") }}
                        </span>
                        <div class="head-logo pa-2 pb-0">
                          <v-img
                            contain
                            :src="
                              av_image != ''
                                ? av_image
                                : company.logo != null
                                ? company.logo
                                : ''
                            "
                            :max-width="
                              $vuetify.breakpoint.xsOnly
                                ? '47%'
                                : $vuetify.breakpoint.mdAndUp
                                ? '44%'
                                : '60%'
                            "
                            max-height="99%"
                          ></v-img>
                          <p class="blur black--text mb-0">
                            Lorem ipsum dolor sit amet..
                          </p>
                        </div>
                      </div>
                      <!-- End - Printable Logo -->
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "companyPreview",
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    av_image: {
      type: String,
      default: ""
    },
    av_head_logo: {
      type: String,
      default: ""
    },
    av_firm: {
      type: String,
      default: ""
    },
    av_seal: {
      type: String,
      default: ""
    },
    company: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    }
  },
  data() {
    return {
      modeNode: process.env.NODE_ENV
    };
  }
};
</script>
