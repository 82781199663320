import CreateActor from '@/components/Actors/CreateActor.vue';

export default{
  components: {
    CreateActor
  },
  data() {
    return {
      actors: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalValues: 0,
      unwatch: '',
      defaults: [],
      search:{
        dni:'',
        first_last_name:'',
        email:'',
        // position: '',
        role: '',
        active: ''
      },
      // positions: [],
      loading_roles: false,
      roles: [],
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
    }
  },
  methods:{
    setDefault (item) {
      if (!this.defaults[item.id]) {
        let data = null
        if (this.franchise_id) {
          data = {
            franchise_id: this.franchise_id,
          }
        }
        this.$store.dispatch('confirmationValue',{
          show: true,
          model: this.$tc('base.default_access',1),
          title: '',
          pre: 'success',
          action: this.$tc('stablished',1),
          content: this.$t('base.confirm_default', {
            company: this.$store.getters.companyInfo.name,
            user: item.user.email
          }),
          type: 'info',
          confirm:{
            url: this.$store.getters.setUserDefaultCompany(item.user_id),
            method: 'patch',
            data
          },
          pos: 1
        })
      }
    },
    editItem (item) {
      this.$refs.createActor.load_data(item)
      this.edit = true
      this.dialog = true
    },
    activateItem (item) { 
      this.activateDeactivateItem(item,this.$t('activate'),this.$tc('activated',0));
    },
    deactivateItem (item) {
      this.activateDeactivateItem(item,this.$t('deactivate'),this.$tc('deactivated',0));
    },
    activateDeactivateItem (item, main_text, text) {
      const url = this.franchise_id === 0 ? this.$store.getters.actorsSources(item.id):
      this.$store.getters.actorsSources(item.id)+'?franchise_id='+this.franchise_id
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$tc('actor.actor',1),
        title: main_text,
        action: text,
        content: this.$t('base.confirm_delete',{
          action: main_text,
          record: item.user.email
        }),
        type: 'info',
        confirm:{
          url: url,
          method: 'delete'
        }
      })
    },
    getActor () {
      this.loading = true
      axios.get(this.$store.getters.actorsSources(),{
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          dni: this.search.dni,
          first_last_name: this.search.first_last_name,
          email: this.search.email,
          // position: this.search.position,
          role: this.search.role,
          active: this.search.active,
          franchise_id: this.franchise_id,
          'tbl': 'n',// Sent to indicate is to populate table
        }
      })
      .then(response => {
        this.loading = false
        let sup_actor = []
        for(let actor of response.data.success.data){
          let company = actor.user.companies.filter(c => c.id == parseInt(localStorage.getItem('nkhokwe')))[0]
          actor['active'] = company.pivot.active ? true : false
          sup_actor.push(actor)
          // To mark default checkboxes as checked
          this.defaults[actor.id] = company.pivot.is_default
        }
        this.actors = sup_actor
        this.totalValues = response.data.success.total
      })
      .catch(err=>{
        this.loading = false
      })
    },
    actor_created () {
      this.edit = false
      this.getActor()
    },
    checkDefault (item) {
      return item.user.companies.filter(comp => comp.pivot.company_id == 1)[0].pivot.is_default
    },
    /*getCatalog(){
      axios.get(this.$store.getters.catalogByCategory('position'))
      .then(response => {
        this.positions = response.data.success
        this.positions.unshift({id:'',name:''})
      })
    },*/
    getRoles () {
      this.loading_roles = true
      axios.get(this.$store.getters.rolesSources(),{
        params:{
          select_only: true,
          orderBy: 'name'
        }
      })
        .then(response => {
          this.roles = response.data.roles
        })
        .finally(() => {
          this.loading_roles = false
        })
    },
    blurRoles () {
      this.$refs.roles_select.blur()
    }
  },
  computed:{
    headers () {
      return [
        { 
          text: this.$t('dni'), align: 'left', value: 'dni' 
        },
        { 
          text: this.$t('first_name'),
          value: 'first_name' 
        },
        { 
          text: this.$t('last_name'),
          value: 'last_name' 
        },
        { 
          text: this.$t('base.email'),
          value: 'user.email',
          sortable: false, 
        },
        { 
          text: this.$tc('roles.roles',1), value: 'roles.name',
          sortable: false, 
        },
        { 
          text: this.$tc('active',0), 
          value: 'active', 
          align: 'center', 
          width: 80,
          sortable: false,
        },
        {
          text: this.$t('actions'),
          value: 'action',
          sortable: false,
          align: 'center',
          width: 110
        },
        {
          text: this.$tc('base.access',1),
          value: 'default',
          sortable: false,
          align: 'center',
          width: 40
        }
      ]
    } 
  },
  watch: {
    options: {
      handler () {
        this.getActor()
      },
      deep: true,
    },
    search: {
      handler () {
        this.options.page = 1
      },
      deep: true,
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        // Evaluates if the store watch is triggered by a image delete or an Actor delete
        let item_to_delete = this.$refs.createActor.item_to_delete 
        if (item_to_delete == 'seal') {
          this.$refs.createActor.actor.seal_url = null
          this.$refs.createActor.item_to_delete = ''
        } else if (item_to_delete == 'sign') {
          this.$refs.createActor.actor.sign_url = null
          this.$refs.createActor.item_to_delete = ''
        }
        else {
          this.getActor()
        }
      }
    })
  },
  mounted () {
    // this.getCatalog()
    this.getRoles()
  },
  beforeDestroy () {
    this.unwatch()
  }
}
