<template>
  <v-dialog v-model="inspection_dialog" max-width="380" persistent scrollable>
    <v-card confirmation>
      <v-card-title class="info">
        <span class="headline font-weight-bold py-1">
          {{ $t('base.complete') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              class="pa-0 pt-2 text-center"
            >
              <b>{{ $t('requests.requires_inspection') }}</b>
              <v-row class="my-3">
                <v-col cols="5" class="small-label bold-label text-right">
                  <span class="v-label" :class="!requires_inspection ? 'primary--text':''">
                    No
                  </span>
                </v-col>
                <v-col cols="2">
                  <v-switch inset
                    v-model="requires_inspection"
                    color="primary"
                    class="small-label bold-label ma-0 pa-0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="5" class="small-label bold-label text-left">
                  <span class="v-label" :class="requires_inspection ? 'primary--text':''">
                    {{$tc('yes',0)}}
                  </span>
                </v-col>
              </v-row>
              <span v-html="$t('base.confirm_completed')"></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel
          :disabled="confirmLoading"
          @click="close"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{$t('base.cancel')}}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save
          :loading="confirmLoading"
          @click="completeRequest"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{$t('base.confirm')}}
          </span>
          <v-icon small>fas fa-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'current_contract'
  ],
  data(){
    return {
      inspection_dialog: false,
      requires_inspection: false,
      confirmLoading: false,
    }
  },
  methods: {
    open(){
      this.inspection_dialog = true
    },
    close(){
      this.inspection_dialog = false
      this.requires_inspection =  false
    },
    completeRequest(){
      this.confirmLoading = true
      let inspection = false
      let ops = {
        params: {
          'status': 'generate_charge_note',
          'update_serv_status': 'requested'
        }
      }
      if (this.requires_inspection) {
        ops.params.status = 'service_request'
        ops.params.requires_inspection = true
        inspection = true
      }
      axios.patch(this.$store.getters.getContracts(this.current_contract), ops.params)
        .then(response =>{
          if (response.status == 200) {
            this.close()
            this.$emit('finish', inspection)
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    }
  },
  watch:{
    //
  }
}
</script>
