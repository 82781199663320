export default {
  data (){
    return {
      modeNode:process.env.NODE_ENV,
    };
  },
  filters: {
    truncateSubheader: function (value) {
      if (this.$store.getters.getMiniTooltip && value.length > 5) {
        value = value.substring(0, 5) + '...';
      }
      return value;
    }
  },
  computed: {
    itemsChange() {
      return [
        { header: 'DSB.subheader.administrative', show_sub: this.$store.getters.isRoot, action: {} },
        // { divider: true },
        {
          title: 'DSB.menu.dashboard',
          icon: 'fas fa-tachometer-alt',
          route: { name: 'dashboard' },
          show: true,
          action: {}
        },
        {
          title: 'DSB.menu.company',
          icon: 'fas fa-building',
          route: { name: 'company' },
          show: this.$store.getters.isRoot,
          action: {}
        },
        {
          title: 'franchise.company',
          icon: 'fas fa-building',
          route: { name: 'franchise' },
          show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
          action: {},
        },
        {
          title: 'locations',
          icon: 'fa fa-map-marker-alt',
          show: !this.$store.getters.isRoot,
          permission:{
            permission: ['index_zone', 'index_franchise',],
            method:'all'
          },
          action: {},
          sub:[
            {
              title: 'zones.zones',
              level: 2,
              icon: 'fas fa-map',
              route: { name: 'zones' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isFranchiseAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission: ['index_zone', 'index_franchise',],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'zones.zones',
              level: 2,
              icon: 'fas fa-map',
              route: { name: 'zones-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission: ['index_zone', 'index_franchise',],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'sectors.sectors',
              level: 2,
              icon: 'fas fa-map-marked-alt',
              route: { name: 'sectors' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isFranchiseAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission: ['index_sector', 'index_zone', 'index_franchise',],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'sectors.sectors',
              level: 2,
              icon: 'fas fa-map-marked-alt',
              route: { name: 'sectors-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchise &&
                    !this.$store.getters.isCorporateUser,
              permission:{
                permission: ['index_sector', 'index_zone', 'index_franchise',],
                mode:'all'
              },
              action: {}
            }
          ]
        },
        {
          title: 'DSB.menu.type_client',
          icon: 'fas fa-users-cog',
          route: { name: 'typeClient' },
          permission:{
            permission:'subscriber',
            method:'r'
          },
          show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
          action: {}
        },
        {
          title: 'actor.actor',
          icon: 'fas fa-user',
          level: 2,
          route: { name: 'franchise_user' },
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && this.$store.getters.isFranchiseAdmin,
          action: {}
        },
        {// CDS - All Subscribers
          title: 'DSB.menu.all_subscribers',
          icon: 'fas fa-users',
          route: { name: 'AllSubscribersCDS' },
          permission:{
            permission: ['allCDS_subscriber'],
            mode:'only',
          },
          action: {}
        },
        {
          title: 'DSB.menu.subscribers',
          icon: 'fas fa-users',
          route: { name: 'subscribers' },
          permission:{
            permission:'subscriber',
            method:'r'
          },
          action: {}
        },
        {
          title: 'DSB.menu.migrate_subscriber',
          icon: 'fas fa-user-plus',
          // icon: 'fas fa-people-arrows',
          // icon: 'fas fa-user-friends',
          route: { name: 'migrateSubscriber' },
          permission:{
            permission: ['inject_old_subscriber'],
            mode:'only',
          },
          action: {}
        },
        {
          title: 'subscribers.groups',
          icon: 'fas fa-user-friends',
          route: { name: 'subscribersGroups' },
          permission:{
            permission: ['indexSubscribersGroups_catalog'],
            mode:'all'
          },
          show: !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin,
          action:{}
        },
        {
          title: 'DSB.menu.requests',
          icon: 'fas fa-concierge-bell',
          route: { name: 'requests' },
          permission:{
            permission:'contract',
            method:'r'
          },
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin,
          action:{}
        },
        { header: 'DSB.subheader.operative', show_sub: !this.$store.getters.isRoot, action: {} },
        // { divider: true },
        {
          title: 'usermanagement',
          icon: 'fas fa-users',
          level: 2,
          show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
          action: {},
          sub:[
            {
              title: 'actor.position',
              icon: 'fas fa-address-card',
              level: 2,
              route: { name: 'position' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
            {
              title: 'roles.groups',
              icon: 'fas fa-users',
              level: 2,
              route: { name: 'groups' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
            /*{
              title: 'roles.permissions',
              icon: 'fas fa-user-tag',
              level: 2,
              route: { name: 'permissions' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },*/
            {
              title: 'roles.roles',
              icon: 'fas fa-user-friends',
              level: 2,
              route: { name: 'roles' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
            {
              title: 'actor.actor',
              icon: 'fas fa-user',
              level: 2,
              route: { name: 'actors' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
          ]
        },
        {
          title: 'DSB.menu.feasibilityreport',
          icon: 'fas fa-users-cog',
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchise,
          permission:{
            mode:'only',
            permission:['index_feasibilityReport','index_feasibilityNetwork'],
          },
          action:{},
          sub:[
            {
              title: 'feasibilityReport.fibra',
              icon: 'fab fa-hubspot',
              level: 1,
              route: { name: 'feasibilityFibra' },
              permission:{
                permission:'feasibilityReport',
                method:'r'
              },
              action: {}
            },
            {
              title: 'feasibilityReport.network',
              icon: 'fas fa-network-wired',
              level: 1,
              route: { name: 'feasibilityNetwork' },
              permission:{
                permission:'feasibilityNetwork',
                method:'r'
              },
              action: {}
            }
          ]
        },
        {
          title: 'inspection.inspection',
          icon: 'fas fa-users-cog',
          route: { name: 'inspection' },
          show: !this.$store.getters.isRoot &&
                !this.$store.getters.isAdmin &&
                !this.$store.getters.isFranchiseAdmin &&
                this.$store.getters.isFranchise,
          permission:{
            permission:'inspection',
            method:'r'
          },
          action:{}
        },
        {
          title: 'service_sale.service_sale',
          icon: 'fas fa-hand-holding-usd',
          route: { name: 'serviceSale'},
          show: !this.$store.getters.isRoot &&
                !this.$store.getters.isAdmin &&
                !this.$store.getters.isFranchise,
          permission:{
            permission:'acceptanceLetter',
            method:'r'
          },
          action: {},
          // sub:[
          //   {
          //     title: 'service_sale.acceptance_letter',
          //     icon: 'fas fa-envelope-open-text',
          //     show: !this.$store.getters.isRoot,
          //     action: {},
          //   }
          // ]
        },
        {
          title: 'DSB.menu.billing',
          level: 2,
          icon: 'fas fa-cash-register',
          route: { name: 'documentControl' },
          show: !this.$store.getters.isRoot,
          permission:{
            permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument','index_clientDocumentControl'],
            mode:'only'
          },
          action: {},
          sub:[
            {
              title: 'DSB.menu.requests',
              icon: 'fas fa-concierge-bell',
              route: { name: 'requestBills' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchise &&
                    !this.$store.getters.isFranchiseAdmin,
              permission:{
                permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'DSB.menu.requests',
              icon: 'fas fa-concierge-bell',
              route: { name: 'RequestBillsFranchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchise &&
                    !this.$store.getters.isFranchiseAdmin,
              permission:{
                permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'document_control.document_control',
              icon: 'fas fa-money-check',
              route: { name: 'documentControl' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isFranchiseAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission:['index_clientDocumentControl','index_voidedNumber','index_employee','clientDocumentTypes_clientDocument','index_franchise', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'document_control.document_control',
              icon: 'fas fa-money-check',
              route: { name: 'documentControl-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchise &&
                    !this.$store.getters.isCorporateUser,
              permission:{
                permission:['index_clientDocumentControl','index_voidedNumber','index_employee','clientDocumentTypes_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'money_received.money_received',
              icon: 'fas fa-money-bill-wave',
              route: { name: 'moneyReceived' },
              show: !this.$store.getters.isRoot,
              permission:{
                permission:['index_moneyReceived'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'Payments.payment_recieved',
              icon: 'fas fa-cash-register',
              route: { name: 'paymentsReceived' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin,
              action: {}
            },
            {
              title: 'Payments.payment_recieved',
              icon: 'fas fa-cash-register',
              route: { name: 'paymentsReceived-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchiseAdmin,
              action: {}
            },
            {
              title: 'Payments.reconciliation',
              icon: 'fas fa-cash-register',
              route: { name: 'payments' },
              permission:{
                permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchise,
              action: {}
              // this.permissions.index_clientDocument && this.permissions.invoiceableContracts_clientDocument
              // See: && this.permissions.getClientDocument_clientDocument
            },
            {
              title: 'Payments.reconciliation',
              icon: 'fas fa-cash-register',
              route: { name: 'payments-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchiseAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
              // this.permissions.index_clientDocument && this.permissions.invoiceableContracts_clientDocument
              // See: && this.permissions.getClientDocument_clientDocument
            },
            {
              title: 'DSB.menu.management',
              icon: 'fas fa-book',
              route: { name: 'historyBills' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
              // this.permissions.invoiceQueries_clientDocument && this.permissions.index_clientDocument && this.permissions.invoiceableContracts_clientDocument
              // See: && this.permissions.getClientDocument_clientDocument
            },
            {
              title: 'DSB.menu.management',
              icon: 'fas fa-book',
              route: { name: 'historyBills-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchiseAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            // this.permissions.invoiceQueries_clientDocument && this.permissions.index_clientDocument && this.permissions.invoiceableContracts_clientDocument
            // See: && this.permissions.getClientDocument_clientDocument
            {
              title: 'document_control.documents',
              icon: 'fas fa-money-check',
              route: { name: 'billingDocuments' },
              show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchise,
              permission:{
                permission:['invoiceQueries_clientDocument', 'clientDocumentHeader_clientDocument',
                  'invoiceableContracts_clientDocument','store_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'document_control.documents',
              icon: 'fas fa-money-check',
              route: { name: 'billingDocuments-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchiseAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission:['invoiceQueries_clientDocument', 'clientDocumentHeader_clientDocument',
                  'invoiceableContracts_clientDocument','store_clientDocument', 'access_clientDocument'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'concept.concepts',
              icon: 'fas fa-newspaper',
              route: { name: 'concept' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission:'concept',
                method:'r'
              },
              action: {}
            },
            {
              title: 'concept.concepts',
              icon: 'fas fa-newspaper',
              route: { name: 'concept-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    !this.$store.getters.isFranchiseAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission:'concept',
                method:'r'
              },
              action: {}
            },
            {
              title: 'DSB.menu.adjustment_motives',
              icon: 'fas fa-newspaper',
              route: { name: 'adjustmenMotives' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
            {
              title: 'banks.bank',
              icon: 'fas fa-donate',
              route: { name: 'banks' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin && !this.$store.getters.isFranchise,
              action: {}
            },
          ]
        },
        /* {
          title: 'DSB.menu.account',
          icon: 'fas fa-user-circle',
          route: { name: 'profile' },
          show: !this.$store.getters.isRoot
        },
        {
          title: 'DSB.menu.test',
          icon: 'fas fa-check',
          route: { name: 'prueba' },
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin,
          action: {}
        }, */
        {
          title: 'service.service',
          level: 2,
          icon: 'fas fa-server',
          route: { name: 'service' },
          show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
          permission:{
            permission:['index_service','index_servicePackage'],
            mode:'only'
          },
          action: {},
          sub:[
            {
              title: 'service.service_types',
              icon: 'fas fa-layer-group',
              route: { name: 'servicesTypes' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin && !this.$store.getters.isFranchise,
              action: {}
            },
            {
              title: 'service.service',
              level: 2,
              icon: 'fas fa-server',
              route: { name: 'service' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              permission:{
                permission:'service',
                method:'r'
              },
              action: {},
            },
            {
              title: 'package.package',
              level: 1,
              icon: 'fa fa-cubes',
              route: { name: 'package' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              permission:{
                permission:'servicePackage',
                method:'r'
              },
              action: {}
            },
            {
              title: 'MaterialEquipment.names',
              level: 1,
              icon: 'fa fa-tools',
              route: { name: 'materialsAndEquipment' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin,
              action: {}
            },
          ]
        },
        {
          title: 'service_install.service_installation',
          icon: 'fas fa-cog',
          route: { name: 'serviceInstallation' },
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin,
          permission:{
            permission:'serviceInstallation',
            method:'r'
          },
          action: {}
        },
        {
          title: 'service_install.activation_pending',
          icon: 'fas fa-server',
          route: { name: 'serviceActivation' },
          show: !this.$store.getters.isRoot && !this.$store.getters.isAdmin && (this.$store.getters.isFranchiseAdmin || !this.$store.getters.isFranchiseAdmin),
          permission:{
            permission:['indexActivationPending_serviceInstallation', 'indexAllActivationPending_serviceInstallation'],
            mode:'only'
          },
          action: {}
        },
        {
          title: 'humanResources.humanResources',
          level: 2,
          icon: 'fas fa-users',
          route: { name: 'employees' },
          show: !this.$store.getters.isRoot,
          permission:{
            permission: ['index_employee',],
            method:'all'
          },
          action: {},
          sub:[
            {
              title: 'employees.employees',
              level: 1,
              icon: 'fas fa-user-friends',
              route: { name: 'employees' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isFranchiseAdmin &&
                    !this.$store.getters.isFranchise,
              permission:{
                permission:['index_employee','index_franchise','employeeTypes_employee'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'employees.employees',
              level: 1,
              icon: 'fas fa-user-friends',
              route: { name: 'employees-franchise' },
              show: !this.$store.getters.isRoot &&
                    !this.$store.getters.isAdmin &&
                    this.$store.getters.isFranchise,
              permission:{
                permission:['index_employee','index_franchise','employeeTypes_employee'],
                mode:'all'
              },
              action: {}
            },
          ]
        },
        // Reports
        {
          title: 'reports.reports',
          icon: 'fas fa-file-invoice',
          route: { name: 'reports' },
          show: !this.$store.getters.isRoot,
          action: {},
          sub:[
            {
              title: 'DSB.menu.filter_subscribers',
              icon: 'fas fa-user-tag',
              route: { name: 'filterSubscribersTotals' },
              permission:{
                permission: ['filterAll_subscriber'],
                mode:'only',
              },
              action: {}
            },
            {
              title: 'DSB.menu.contracts_per_service',
              icon: 'fas fa-user-tag',
              route: { name: 'filterContractsPerService' },
              permission:{
                permission: ['filterPerServiceTotals_contract'],
                mode:'only',
              },
              action: {}
            },
            {
              title: 'DSB.menu.amounts_per_service',
              icon: 'fas fa-file-invoice-dollar',
              route: { name: 'filterAmountsPerService' },
              permission:{
                permission: ['filterPerServiceAmounts_contract'],
                mode:'only',
              },
              action: {}
            },
            {
              title: 'DSB.menu.installs_per_date',
              icon: 'fas fa-toolbox',
              route: { name: 'filterInstallationsTotals' },
              permission:{
                permission: ['filterAllTotals_serviceInstallation'],
                mode:'only',
              },
              action: {}
            },
            {
              title: 'DSB.menu.debt_per_subscriber',
              icon: 'fas fa-book-dead',
              route: { name: 'debtPerSubscriber' },
              permission:{
                // permission: ['getOverdueCutoff_subscriber'],
                permission: ['getDebtors_subscriber'],
                mode:'only',
              },
              action: {}
            },
            {
              title: 'reports.generate',
              icon: 'fas fa-file-invoice',
              route: { name: 'reports' },
              show: !this.$store.getters.isRoot,
              permission:{
                permission:['index_employee','index_conceptMotive','index_sector','index_franchise','currencyCodes_exchangeRate'],
                mode:'all'
              },
            }
          ]
        },
        // Reports
        {
          title: 'configs',
          icon: 'fas fa-cog',
          route: { name: 'system' },
          show: !this.$store.getters.isRoot,
          action: {},
          sub:[
            {
              title: 'DSB.subheader.gral_config',
              icon: 'fas fa-cog',
              route: { name: 'system' },
              show: !this.$store.getters.isRoot && this.$store.getters.isAdmin && this.$store.getters.getBaseCurrency !== null,
              action: {}
            },
            {
              title: 'system.exchange_rate',
              level: 2,
              icon: 'fas fa-sync-alt',
              route: { name: 'exchangeRates' },
              show: !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin && this.$store.getters.getBaseCurrency !== null,
              permission:{
                permission:['index_exchangeRate'],
                mode:'only'
              },
              action: {}
            },
            {
              title: 'DSB.subheader.bank_account',
              icon: 'fas fa-piggy-bank',
              route: { name: 'bankAccounts' },
              show: !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin,
              permission:{
                permission:['index_bankAccount'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'corporate_identity',
              icon: 'fa-id-card',
              route: { name: 'identity' },
              show: !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin,
              permission:{
                permission:['indexCorporateIdentity_catalog'],
                mode:'all'
              },
              action: {}
            },
            {
              title: 'DSB.subheader.taxes',
              icon: 'fas fa-hand-holding-usd',
              route: { name: 'taxes' },
              show: !this.$store.getters.isRoot && !this.$store.getters.isFranchiseAdmin,
              permission:{
                permission:['index_taxes'],
                mode:'all'
              },
              action: {}
            },
          ]
        },
        // { header: 'DSB.subheader.gral_config', show_sub: true, action: {} },
        // { divider: true },
        {
          title: 'APP',
          icon: 'fas fa-window-restore',
          route: { name: 'appatlas' },
          show: !this.$store.getters.isRoot &&
                !this.$store.getters.isAdmin &&
                !this.$store.getters.isFranchiseAdmin,
          permission:{
            permission: ['filterPerServiceTotals_contract'],
            mode:'only',
          },
          action: {}
        },
        {
          title: 'DSB.menu.about',
          icon: 'fas fa-info-circle',
          route: { name: 'about' },
          show: true,
          action: {}
        },
        // Must be last, always shown
        // {
        //   title: 'DSB.menu.night_mode',
        //   icon: 'fas fa-moon',
        //   show: true,
        //   mode: 'switch',
        //   action: {
        //     'click': this.toggleDark
        //   }
        // }
      ].filter(menu => this.$store.getters.isRoot || this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin ?
          menu.show == true :
          menu.permission !== undefined && menu.show !== undefined ?
            this.getPermissionGroup(menu.permission) && menu.show === true :
            menu.permission !== undefined ?
              this.getPermissionGroup(menu.permission) :
              menu.show === true
      );
    }
  },
  methods:{
    /*
    * Function to print in console the children of a menu item
    * First, find the item by title
    * Second, filter the children by title
    */
    show(){
      let found = this.itemsChange.find(item=> item.title =='DSB.menu.billing');
      let found_child = found.sub.filter(item2=> {
        return item2.title == 'document_control.document_control';
      });
      // eslint-disable-next-line no-console
      console.log(`\n`);
      // console.log(found_child);
      for (var key in found_child) {
        // eslint-disable-next-line no-console
        console.log(`${found_child[key].route.name}\n`,'permission y show',this.getPermissionGroup(found_child[key].permission), found_child[key].show,);
      }
      if((this.$store.getters.isRoot) || (this.$store.getters.isAdmin) || (this.$store.getters.isFranchiseAdmin)) {
         // eslint-disable-next-line no-console
         console.log('Any admin',);
      }
    }
  }
};
