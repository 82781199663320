<template>
    <!-- v-model="employee" -->
  <v-autocomplete
    :value="value"
    @input="TakeEmployee"
    :item-text="legalDenomination"
    item-value="id"
    clearable
    clear-icon="fas fa-times"
    cache-items
    hide-no-data
    :name="name"
    :class="ClassField"
    :prepend-icon="prepend_icon_field"
    :loading="loading_employee"
    :disabled="disabled_field"
    :items="employee_list"
    :search-input.sync="search_employee"
    :label="$tc('employees.employee', 1)"
    :error-messages="errorMessages"
    @click:clear="clear()"
  >
    <template v-slot:append><!-- append-outer -->
      <v-menu top left
        offset-x
        offset-y
        :close-on-content-click="false"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on"
            size="18"
            color="info_alt"
            class="mt-05"
          >
            fas fa-info-circle
          </v-icon>
        </template>
        <v-card
          class="info_alt-border"
          max-width="280"
        >
          <v-card-text class="body-0 text-justify font_color--text py-2 px-3 cursor-default">
            {{ $t('employee_search_info') }}
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: 'EmployeeSearch',
  components:{},
  props:{
    mountedList:{
      type:Boolean,
      default:false,
    },
    onlyID:{
      type:Boolean,
      default:true,
    },
    disabled_field:{
      type:Boolean,
      default:false,
    },
    errorMessages: {
      // type:[String, Array],
      default:''
    },
    name:{
      type:String,
      default:'employee_field'
    },
    ClassField:{
      type:String,
      default:'ma-0 pa-0',
    },
    prepend_icon_field:{
      type:String,
      default:'',
    },
    SearchField:{
      type:String,
      default:'',
    },
    valueDefault:{
      type:[Object,String],
      default:'',
    },
    employee_default:{
      type:[Number,String],
      default:'',
    },
    value:{
      type:[Number,String,Object],
      default: ""
    },
    payment_collector:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return {
      loading_employee:false,
      paramEmployee:{doc:'',legal_denomination:'',},
      employee_list:[],
      search_employee:'',
      employee_id: this.employee_default,
      f_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
    };
  },
  computed:{},
  watch: {
    value:{
      immediate:true,
      handler(val){
        if(val==null || val==''){
          this.getEmployees()
        }
        else if(this.employee_list.find(m => m.id === val) === undefined) {
          this.getEmployees()
        }
      }
    },
    search_employee(val){
      if (val != undefined && (typeof(val) === 'string' && val.length >2)) {
        // Regex que detecta si es una cedula, puede incluir o no el prefijo
        const doc = /^([\s['vVgGeEjJ']?\d{3,10})/; // /^([\s\D{1}]?\d{3,10})/
        const words = /^(?:\w+\s?){2}/;
        if (doc.test(val)) {
          this.paramEmployee.doc = val;
          this.paramEmployee.legal_denomination = '';
          this.getEmployees();
        } else if (words.test(val)){
          this.paramEmployee.doc = '';
          this.paramEmployee.legal_denomination = val;
          this.getEmployees();
        }
      }
    },
  },
  methods:{
    legalDenomination(em) {
      if (em != undefined && em != null && em != '') {
        let legal_denomination = '';
        let dni = em.doc.charAt(0);
        let obj = JSON.parse(em.legal_denomination[1]);
        if (dni=='J'|| dni=='G') {
          legal_denomination = obj.denomination;
        } else if (dni=='V'|| dni=='E'){
          if (obj.firm_name != null && obj.firm_name != undefined) {
            legal_denomination = obj.firm_name;
          } else {
            legal_denomination = obj.first_name +' '+ obj.last_name;
          }
        }
        // em.legal = legal_denomination.substr(0, 25);
        em = em.doc +' '+legal_denomination.substr(0, 20);
      } else {
        em = '';
      }
      return em;
    },
    getEmployees(){
      this.loading_employee = true;
      axios.get(this.$store.getters.getEmployees(),
      {
        params:{
          page:1,
          paginate:10,
          sort_by: 'updated_at',
          sort_desc: true,
          active: true,
          doc: this.paramEmployee.doc,
          legal_denomination: this.paramEmployee.legal_denomination,
          employee_id:this.value,
          employee_type: 'collector',
          franchise_id: this.f_id == 0 ? '' : this.f_id,
          payment_collector: this.payment_collector
        }
      }).then((response)=>{
        this.employee_list = response.data.employees.data;
      }).catch((error)=>{
        if (error.response) {
          //
        }
      }).finally(()=>{
        this.loading_employee = false;
      });
    },
    TakeEmployee(evt){
      if (evt!=null && evt !=undefined) {
          this.$emit('input',evt)
          let doc = this.employee_list.find(em=> em.id==evt);
          this.$emit('change',doc)
      } else {
        this.$emit('input','')
      }
    },
    clear(){
      this.employee_list = [];
      this.loading_employee = false;
      this.paramEmployee={doc:'',legal_denomination:'',};
      this.$emit('input', '');
      this.$emit('clearing', '');
    },
    clear2(){
      this.employee_list = [];
      this.loading_employee = false;
      this.paramEmployee={doc:'',legal_denomination:'',};
      this.$emit('input', '');
    },
    search_legal(){
      this.paramEmployee.legal_denomination = this.SearchField;
      this.getEmployees();
    },
    setValue(val){
      this.employee = val
    },
    setItems(){
      this.employee_list.push(this.value);
      // eslint-disable-next-line vue/no-mutating-props
      this.value = this.value.id
    },
    changeValue(val){
      //
    }
  },
  mounted(){},
}
</script>
