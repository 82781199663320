<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from '../components/HelloWorld'
import { store } from '@/store'

export default {
  components: {
    HelloWorld
  },
  mounted () {
    if (store.getters.isAuth && this.$store.getters.dbSelected !== null && this.$route.path === '/') {
      this.$router.push({ name: 'dashboard' })
    }

    if (this.$router.currentRoute.name == 'home') {
      this.$store.dispatch('diaLogin', true)
    }
  },
  beforeDestroy () {
    this.$store.dispatch('closedialogin')
  }
}
</script>
