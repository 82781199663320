//
import PreView from '../../components/HistoryBills/preview.vue';
import EditDoc from '../../components/HistoryBills/editDoc.vue';
import SubscriberSearch from '../../components/Subscriber/suscriberSearch.vue';

const ReturnObject = (value)=>{
  if(typeof(value)=='object'){
    return value;
  } else if(typeof(value)=='string' && value != 'null'){
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "HistoryBills",
  components:{
    PreView: PreView,
    EditDoc:EditDoc,
    SubscriberSearch:SubscriberSearch,
  },
  data (){
    return {
      edit: false,
      expand: false,
      loading: false,
      loading_queries:false,
      is_filter: false,
      is_filter2:false,
      its_edit:false,
      fullscreen:false,
      loadingTable:false,
      prospects_only:false,
      loadingSend:false,
      Permissions:{
        show:false,
        edit:false,
      },
      date:{
        start:true,
        end:true
      },
      options:{
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      searches:{
        type:'invoice',
        date_start:'',
        date_end:'',
        control_number:'',
        document_number:'',
        status:'',
        client:'',
      },
      historyBills:[],
      TypeDocument:[],
      StatusDocument:[],
      BillsToSend:[],
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      totalRates:0,
      date_start:'',
      date_end: '',
      date_max:'',
      index: '',
      editedIndex:'',
      unwatch: '',
      search:'',
      symbol:'',
      pk_client:'',
      billsName:'document_control.types.invoice',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
    };
  },
  async mounted() {
    await this.getSearchDays();
    await this.getQueries();
    this.PermissionsDocument();
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      let headers = [
        {
          text: this.$t('billing.elaboration_date'),
          value:'issue_date',
          align: 'center',
        },
        {
          text: this.searches.type == 'invoice' ? this.$t('billing.invoice_number') : this.$t('billing.control_number'),
          value:  this.searches.type == 'invoice' ? 'document_number' : 'control_number',
          align: 'center',
        },
        {
          text: this.$t('billing.description'),
          value:'remark',
          align: 'center',
        },
        {
          text: this.$t('billing.state'),
          value:'status',
          align: 'center',
        },
        {
          text: this.$tc('amount',1),
          value:'amount',
          align: 'right'
        },
      ];
      if (this.Permissions.show || this.Permissions.edit) {
        headers.push(
          {
            text: this.Permissions.show && this.Permissions.edit ? this.$t('actions'):this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'right'
          }
        );
      }
      return headers;
    },
    selectedDocumentTypes(){
      return this.TypeDocument.map(types => {
        return {
          'value': types.value,
          'text': this.$t(types.text)
        };
      });
    },
    SelectStatus () {
      return this.StatusDocument.map(status => {
        return {
          'value': status.value,
          'text': this.$t(status.text)
        };
      });
    },
    computedDateFormatt () {
      return this.formatDate(this.today);
    },
  },
  methods:{
    List(){
      this.BillsToSend = [];
      this.loadingTable = true;
      this.historyBills = [];
      this.totalValues = 0;
      if ((this.searches.type != '' || this.searches.date_start != '' || this.searches.date_end != '') && this.is_filter) {
        this.options.page = 1;
      }
      let params = {
        page:this.options.page,
        paginate:typeof(this.options.itemsPerPage)=='number' ? this.options.itemsPerPage : 10,
        sort_by: this.options.sortBy[0] || null,
        sort_desc: this.options.sortDesc[0] || null,
        type: this.searches.type,
        start_date: this.searches.date_start,
        end_date: this.searches.date_end,
        control_number: this.searches.control_number,
        document_number: this.searches.document_number,
        status: this.searches.status,
        subscriber_id: this.searches.client,
        symbol: this.symbol,
      };
      this.$store.dispatch('DocList', params)
      .then((response)=>{
        response.client_documents.map(doc=>{
          if (doc.send_date == null) {
            if (doc.type != 'invoice' && doc.status == 'pending') {
              doc.ItsEdit = true;
            } else {
              doc.ItsEdit = false;
            }
          } else {
            doc.ItsEdit = false;
          }
        });
        // eslint-disable-next-line no-console
        this.historyBills = response.client_documents;
        this.totalValues = response.totalValues;
        this.is_filter = false;
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      }).finally(()=>{
        this.loadingTable = false;
      });
    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      axios.get(this.$store.getters.getClientDocument(item))
      .then((response)=>{
        // eslint-disable-next-line no-console
        // Le pasamos el simbolo al modal para evitar una consulta
        response.data.symbol = this.symbol;
        if (this.its_edit) {
          this.$refs.edit_doc.open(response.data, true);
        } else {
          this.$refs.pre_view.open(response.data, true);
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error);
        }
      });
    },
    editItem(item){
      // eslint-disable-next-line no-console
      this.its_edit = true;
      this.getClientDocument(item);
    },
    close(){
      this.its_edit = false;
    },
    searchForType(item){
      this.billsName = 'document_control.types.'+item;
      this.options = {sortBy:['updated_at'],sortDesc:[true]};
      this.is_filter = true;
    },
    SearchSubscriber(item){
      // eslint-disable-next-line no-console
      if (typeof(item) == 'object' && Object.prototype.hasOwnProperty.call(item, 'subscriber_id')) {
        this.searches.client = item.subscriber_id;
        this.SearchFilter();
      }
    },
    SearchFilter(){
      // eslint-disable-next-line no-console
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.is_filter = true;
      this.List();
    },
    getQueries(){
      this.loading_queries = true;
      this.$store.dispatch('DocQuerys')
      .then(response =>{
        // eslint-disable-next-line no-console
        this.symbol = response.symbol;
        this.TypeDocument = response.TypeDocument.filter(type=>{
          if(localStorage.getItem('anapa')!='' & localStorage.getItem('anapa')!=null){
            return type.value !='price_quotation';
          }else {
            return type;
          }
        });
        this.StatusDocument = response.StatusDocument;
      })
      .catch(error =>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loading_queries = false;
      });
    },
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    formatAmount(amount){
      if (!amount) return null;
      return Number(amount).toFixed(2);
    },
    getSearchDays(){
      this.date = {start: false, end: false};
      let date = new Date();
      var first_Day = new Date(date.getFullYear(), date.getMonth(), 1);
      // let last_Day = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      if (this.searches.date_start == '') {
        this.searches.date_start = first_Day.toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10);
      }
      if (this.searches.date_end =='') {
        this.searches.date_end = date.toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10);
      }
      setTimeout(() => {
        this.date = {start: true, end: true};
      }, 1);
    },
    SoftEraseFilter(){
      this.options={page: 1, itemsPerPage:10, sortBy:['updated_at'],sortDesc:[true]};
      this.searches.client = '';
      this.is_filter = false;
      this.List();
    },
    EraseFilter(){
      this.options = {
        page: 1, itemsPerPage: 10, sortBy: ['updated_at'], sortDesc: [true]
      };
      this.searches = {
        type: 'invoice',
        date_start: '',
        date_end: '',
        control_number: '',
        document_number: '',
        status: '',
        client: ''
      };
      this.is_filter = false;
      this.$refs.subs_criber.clear2();
      this.getSearchDays();
      this.List();
    },
    SendBills(){
      this.loadingSend = true;
      // eslint-disable-next-line no-console
      let bills = this.BillsToSend.map(bill=>{
        return bill.id;
      });
      setTimeout(()=> {
        // eslint-disable-next-line no-console
        this.loadingSend = false;
      }, 2000);
    },
    PermissionsDocument(){
      this.Permissions.show = this.getPermissionGroup({
        permission:[
          'show_clientDocument',
          'invoiceQueries_clientDocument',
          'makeClientDocumentPDF_clientDocument',
          'invoiceQueries_clientDocument',
          'update_clientDocument',
        ],
        mode:'all'
      });
      this.Permissions.edit = this.getPermissionGroup({
        permission:[
          'show_clientDocument',
          'invoiceQueries_clientDocument',
          'makeClientDocumentPDF_clientDocument',
          'invoiceQueries_clientDocument',
          'update_clientDocument',
          'index_conceptMotive',
        ],
        mode:'all'
      });
    },
  },
};
