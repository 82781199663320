import featuresTypeClient from "./FeaturesTypeClient/FeaturesTypeClient.vue";
import modalNewEdit from "./ModalNewEdit.vue";

export default {
  components: {
    featuresTypeClient,
    modalNewEdit
  },
  data() {
    return {
      type_clients: [],
      sub_type_clients: [],
      data_parent: {},
      perPage: 10,
      page: 1,
      totalValues: 0,
      load: false,
      tableLoading: false,
      changeT: false,
      unwatch: "",
      createdPolicies: false,
      updatePolicies: false,
      deletePolicies: false
    };
  },
  computed: {
    headers() {
      let act = {
        text: this.$t("actions"),
        value: "action",
        align: "center",
        sortable: false,
        width: 140
      };
      let list = [
        {
          text: this.$t("name"),
          align: "left",
          value: "name"
        },
        {
          text: this.$t("description"),
          value: "description",
          sortable: false
        },
        {
          text: this.$tc("active", 0),
          value: "is_active",
          width: 100
        },
        {
          text: this.$tc("franchise.contractor", 2),
          value: "is_franchise",
          align: "center",
          width: 110
        }
      ];
      if (this.createdPolicies || this.updatePolicies || this.deletePolicies)
        list.push(act);
      return list;
    },
    headersSubTypes() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("description"),
          value: "description"
        },
        {
          text: this.$t("actions"),
          value: "action",
          align: "right",
          sortable: false
        }
      ];
    }
  },
  watch: {
    changeT(val) {
      if (val) {
        this.sub_type_clients = [];
      }
    }
  },
  methods: {
    editItem(value) {
      let object = Object.assign({}, value);
      this.$refs.modalNewEdit.open(object);
    },
    getTypeClient() {
      this.tableLoading = true;
      axios
        .get(this.$store.getters.getTypeClient(), {
          params: {
            page: this.page,
            paginate: this.perPage
          }
        })
        .then(response => {
          this.tableLoading = false;
          this.type_clients = response.data.typeclient.data;
          this.totalValues = response.data.typeclient.total;
        });
    },
    getSubTypeClient(parent_data) {
      this.tableLoading = true;
      axios
        .get(this.$store.getters.getSubTypeClient(parent_data.id), {
          params: {
            page: this.page,
            paginate: this.perPage
          }
        })
        .then(response => {
          this.tableLoading = false;
          this.sub_type_clients = response.data.subtypeclient.data;
          this.totalValues = response.data.subtypeclient.total;
        });
    },
    remove(value) {
      let translation =
        value.parent_id == null
          ? this.$t("typeClient.typeClient")
          : this.$t("typeClient.subTypeClient");
      let action = this.$tc("deleted", 1);
      this.$store.dispatch("confirmationValue", {
        template: !this.changeT ? "typeClient" : "subTypeClient",
        show: true,
        title: this.$t("base.delete"),
        model: translation,
        action: action,
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: value.name
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.getTypeClient(value.id),
          method: "delete"
        }
      });
    },
    changeType(value) {
      this.data_parent = value;
      this.getSubTypeClient(value);
      this.changeT = !this.changeT;
    },
    permission() {
      this.createdPolicies = this.$store.getters.isAdmin
        ? true
        : this.getPermissionGroup({
            permission: "typeClient",
            method: "c"
          });
      this.updatePolicies = this.$store.getters.isAdmin
        ? true
        : this.getPermissionGroup({
            permission: "typeClient",
            method: "u"
          });
      this.deletePolicies = this.$store.getters.isAdmin
        ? true
        : this.getPermissionGroup({
            permission: "typeClient",
            method: "d"
          });
    },
    newItem() {
      this.$refs.modalNewEdit.open();
    }
  },
  mounted() {
    this.getTypeClient();
    this.permission();
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (
          (val &&
            this.$store.getters.getConfirmationTemplate == "typeClient") ||
          this.$store.getters.getConfirmationTemplate == "features"
        ) {
          this.getTypeClient();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  }
};
