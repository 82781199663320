import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VeeValidate, { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import {translations} from "@/locale/translations.js"
import money from 'v-money'

// register directive v-money and component <money>
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '$ ',
  suffix: '',
  precision: 2,
  masked: false
})

Vue.use(VueI18n)
// Global Components
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Validator.localize('es', translations.es.validation)

let lang = localStorage.getItem("restiLang") != undefined ?
  localStorage.getItem("restiLang") :
  process.env.VUE_APP_LOCALE

// Safety verification, in case of localStorage manipulation
if (!Object.prototype.hasOwnProperty.call(translations, lang)) {
  lang = process.env.VUE_APP_LOCALE
}

export const i18n = new VueI18n({
  locale: lang, // set locale
  messages: translations, // set locale messages
  silentTranslationWarn: true, // hide translation fail warnings
})

// Moment JS for date formatting
const moment = require('moment')
if (lang == 'es') {
  require('moment/locale/es')
}
Vue.use(require('vue-moment'), {
    moment
})
// this.$moment('put_date').format(this.$store.getters.getDateFormat)

// Numeral JS settings
var numeral = require('numeral')
numeral.register('locale', 'es', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'o';
    },
    currency: {
        symbol: 'Bs.'
    }
})
numeral.locale(lang);

Vue.use(VeeValidate, {
  i18n,
  locale: lang,
  dictionary: {
    es: translations.es.validation,
    en: translations.en.validation
  }
})

// definir un objeto mixin
export let globals = {
  methods: {
    getPermissionGroup(value){
      return this.$store.getters.getPoliciesGroup(value)
    },
    downloadFile(action, url, filename){
      if (action == 'download') {
        const ext = url.split('.').pop();
        const href = window.URL.createObjectURL(new Blob([url]))
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename+'.'+ext)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      else {
        var newWin = window.open(url)
        newWin.focus()
      }
    },
    /*,
    getDecimalPrecision(val = 0){
      if(Math.floor(val) === val) return 2;
      let places
      let split = val.toString().split(".")
      if (split[1]) {
        places = split[1].length
      } else {
        places = 2
      }
      if (places < 2) {
        return 2
      } else {
        return places
      }
    }
    */
  }
}

Vue.mixin(globals)
