<template>
  <v-app>
    <v-content>
      <toolbar></toolbar>
      <notific></notific>
      <confirm></confirm>
      <router-view/>
    </v-content>
  </v-app>
</template>
<script>
import ToolBar from './components/layouts/ToolBar.vue'
import Notifications from './components/layouts/Notifications.vue'
import Confirmations from './components/layouts/Confirmations.vue'
export default {
  name: 'app',
  components: {
    toolbar: ToolBar,
    notific: Notifications,
    confirm: Confirmations
  },
  data () {
    return {}
  },
  created() {
    // Send ping to check for 503 response
    this.$store.dispatch('check503')
    // Right way of checking false string as boolean
    // this.$vuetify.theme.dark = this.$store.getters.getDarkTheme == "true"
    let mode = false
    if (this.$store.getters.dataUser.stUser && this.$store.getters.dataUser.stUser.dark_mode) {
      mode = this.$store.getters.dataUser.stUser.dark_mode
    }
    this.$vuetify.theme.dark = mode
    if (this.$store.getters.primaryColor !== null) {
      this.$vuetify.theme.themes.light.primary = this.$store.getters.primaryColor
      this.$vuetify.theme.themes.dark.primary = this.$store.getters.primaryColor
      this.$vuetify.theme.themes.light.info = this.$store.getters.primaryColor
      this.$vuetify.theme.themes.dark.info = this.$store.getters.primaryColor
    }
  }
}
</script>
