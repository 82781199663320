export default {
  props: [
    'countries', 'states', 'is_states', 'scope',
    'current_address', 'current_contact', 'prev_address', 'prev_contact',
    'municips', 'parishes', 'sectors'
  ],
  data() {
    return {
      // Selects
      // items_state: [],        // List States
      // items_city: [],         // List Cities
      items_municipality: this.municips.length > 0 ? this.municips : [], // List Minicipalities
      items_parish: this.parishes.length > 0 ? this.parishes : [],       // List Parishes
      items_sector: this.sectors.length > 0 ? this.sectors : [],         // List Sectors
      natural_prefixes: [],
      // Models
      scope_address: {},
      scope_contact: {},
      // Default Models
      empty_address: {
        country_id: 237,// Venezuela preselected case only
        state_id: '',
        municipality_id: '',
        // city_id: '',
        parish_id: '',
        sector_id: '',
        tower: '',
        address: '',
        ref_point: '',
        lat: '',
        lon: '',
      },
      empty_contact: {
        first_name: '',
        last_name: '',
        prefix: '',
        dni: '',
        position: '',
        email: '',
        local_phone: '',
        cellphone: '',
      },
      // Selects Loadings
      loadingSelCountry: false,
      loadingSelState: true,
      // loadingSelCity: false,
      loadingSelMunicip: false,
      loadingSelParish: false,
      loadingSelSector: false,
      loadingPrefixes: false,
      same_as_previous_addr: false,
      same_as_previous_cont: false,
      // signal to parent
      loading_signal: false
    }
  },
  methods: {
    loadNaturalPrefixes() {
      if (!this.$store.getters.isFranchise && this.natural_prefixes.length == 0) {
        this.loadingPrefixes = true
        axios.get(this.$store.getters.getPrefixes,{
          params:{
            type: 'natural'
          }
        })
          .then(response => {
            this.natural_prefixes = response.data.prefixes
          })
          .catch(err => {
            this.natural_prefixes = []
          })
          .finally(() => {
            this.loadingPrefixes = false
          })
      }
    },
    clearValidation () {
      this.$validator.reset()
    },
    displaySectorName (item) {
      let sector = this.items_sector.filter(sector => {
        return sector.id == item
      })
      if (sector.length > 0) {
        return sector[0].name
      } else if (item.name) {
        return item.name
      } else {
        if (typeof item === 'string') {
          return item.toUpperCase()
        } else {
          return item
        }
      }
    },
    getListOf (type, id = null, with_signal = false) {
      if (with_signal && !this.loading_signal) {
        this.loading_signal = true
        this.$emit('loading_signal', true)
      }
      let keep_going = true
      let url = ''
      switch (type) {
        case 'country':
          this.loadingSelCountry = true
          url = this.$store.getters.getAllCountries
          break
        case 'state':
          this.loadingSelState = true
          url = this.$store.getters.getStates(id)
          break
        /*case 'city':
          this.loadingSelCity = true
          url = this.$store.getters.getCities(id)
          break*/
        case 'municipality':
          this.loadingSelMunicip = true
          url = this.$store.getters.getMunicipalities(id)
          break
        case 'parish':
          this.loadingSelParish = true
          url = this.$store.getters.getParishes(id)
          break
        case 'sector':
          this.loadingSelSector = true
          url = this.$store.getters.getSectorsByParish(id)
          break
        default:
          keep_going = false
          this.emitNotification('error', 6000, 'base.something_wrong')
          return false
      }
      if (keep_going) {
        axios
          .get(url)
          .then(response => {
            this.fillListData (type, true, response.data)
            if (this.same_as_previous_addr) {
              this.scope_address = Object.assign({}, this.prev_address)
              this.scope_address.sector_id = this.checkSectorID(this.prev_address)
            }
          })
          .catch(err => {
            let error = err.response
            if (err.response.data) {
              error = err.response.data.error
            }
            this.emitNotification('error', 6000, error ? error:'base.something_wrong')
            this.fillListData (type, false, null)
          })
      }
    },
    fillListData (type, is_success, list) {
      this.clearListData(type)
      switch (type) {
        case 'country':
          this.loadingSelCountry = false
          is_success ? this.items_country = list.countries : this.items_country = []
          break
        case 'state':
          this.loadingSelState = false
          is_success ? this.items_state = list.states : this.items_state = []
          break
        /*case 'city':
          this.loadingSelCity = false
          is_success ? this.items_city = list.cities : this.items_city = []
          break*/
        case 'municipality':
          this.loadingSelMunicip = false
          is_success ? this.items_municipality = list.municipalities : this.items_municipality = []
          break
        case 'parish':
          this.loadingSelParish = false
          is_success ? this.items_parish = list.parishes : this.items_parish = []
          break
        case 'sector':
          this.loadingSelSector = false
          is_success ? this.items_sector = list.sectors : this.items_sector = []
          setTimeout(() => {
            if (this.scope_address.sector_id) {
              let sector = this.items_sector.filter(sector => {
                return sector.id == this.scope_address.sector_id
              })
              this.scope_address.sector_id = sector[0]
              this.loading_signal = false
              this.$emit('loading_signal', false)
            }
          }, 400)
          break
        default:
          this.emitNotification('error', 6000, 'base.something_wrong')
          break
      }
    },
    clearListData (type) {
      switch (type) {
        case 'country':
          break
        case 'state':
          this.scope_address.state_id = ''
          // this.scope_address.city_id = ''
          this.scope_address.municipality_id = ''
          this.scope_address.parish_id = ''
          break
        // case 'city':
        case 'municipality':
          // this.scope_address.city_id = ''
          this.scope_address.municipality_id = ''
          this.scope_address.parish_id = ''
          break
        case 'parish':
          this.scope_address.parish_id = ''
          break
        case 'sector':
          this.scope_address.sector_id = ''
          break
        default:
          this.emitNotification('error', 6000, 'base.something_wrong')
          break
      }
    },
    showLoadedData () {
      if (this.scope_address.state_id != '') {
        this.getListOf('municipality', this.scope_address.state_id, true)
      }
      if (this.scope_address.municipality_id != '') {
        this.getListOf('parish', this.scope_address.municipality_id, true)
      }
      if (this.scope_address.parish_id != '') {
        this.getListOf('sector', this.scope_address.parish_id, true)
      }
    },
    checkSectorID (addr) {
      let current_sector_id
      if (addr.sector_id) {
        if (addr.sector_id.id) {
          current_sector_id = addr.sector_id.id
        } else {
          current_sector_id = addr.sector_id
        }
      }
      return current_sector_id
    },
    emitNotification(style, timeout, notifText, extra) {
      this.$store.dispatch('pushNotification', {
        snackbar:true,
        style:style,
        mode:null,
        timeout:timeout,
        notifText:notifText,
        extra:extra
      })
    }
  },
  watch:{
    is_states (val) {
      if (val) {
        this.loadingSelState = false
      }
    },
    scope_address: {
      handler(val){
        if (val.municipality_id == '' && this.current_address.municipality_id && this.current_address.municipality_id != '') {
          this.scope_address.municipality_id = this.current_address.municipality_id
        }
        if (val.parish_id == '' && this.current_address.parish_id && this.current_address.parish_id != '') {
          this.scope_address.parish_id = this.current_address.parish_id
        }
        if (val.sector_id == '' && this.current_address.sector_id && this.current_address.sector_id != '') {
          this.scope_address.sector_id = this.checkSectorID(this.current_address)
        }
      },
      deep: true
    },
    same_as_previous_addr (val) {
      if (val) {
        this.scope_address = Object.assign({}, this.prev_address)
        this.showLoadedData()
      } else {
        this.scope_address = Object.assign({}, this.empty_address)
        this.$validator.reset()
      }
    },
    same_as_previous_cont (val) {
      if (val) {
        this.scope_contact = Object.assign({}, this.prev_contact)
        // delete this.scope_contact.email
        // delete this.scope_contact.cellphone
        this.$validator.reset()
      } else {
        this.scope_contact = Object.assign({}, this.empty_contact)
        this.$validator.reset()
      }
    }
  },
  created(){
    if (this.is_states) {
      this.loadingSelState = false
    }
    if (Object.keys(this.current_address).length > 0) {
      this.scope_address = Object.assign({}, this.current_address)
      this.scope_address.sector_id = this.checkSectorID(this.current_address)
    } else {
      this.scope_address = Object.assign({}, this.empty_address)
    }
    if (Object.keys(this.current_contact).length > 0) {
      this.scope_contact = Object.assign({}, this.current_contact)
    } else {
      this.scope_contact = Object.assign({}, this.empty_contact)
    }
  },
  mounted(){
    this.loadNaturalPrefixes()
    this.showLoadedData()
  }
}
