export default {
  name: 'notification-modal',
  computed:{
    show:{
      get(){
        return this.$store.getters.getNotificationModalShow
      },
      set(newValue){
        this.$store.dispatch('closeNotificationModal')
      }
    }
  },
  methods:{
    close(){
      this.$store.dispatch('closeNotificationModal')
    }
  }
}