//
import Vue from "vue";
const moment = require("moment");
Vue.use(require("vue-moment"), {
  moment
});

// Round decimals function
const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};
// Min Value Function
const minValue = (value, min, decimals) => {
  if (Math.abs(value) < min) {
    return value.toFixed(decimals);
  } else {
    return value;
  }
};
// Money Format
const formatVES = (value, type) => {
  if (type == "VES") {
    return value.toLocaleString("es-ve", {
      style: "currency",
      currency: "VES"
    });
  } else if (type == "USD") {
    return value.toLocaleString("en", { style: "currency", currency: "USD" });
  } else if (type == "percent") {
    return value.toLocaleString("en", {
      style: "percent",
      maximumSignificantDigits: 2
    });
  } else {
    return round(value, 2);
  }
};
// Return Object Function
const ReturnObject = value => {
  if (value != "null" && value != null) {
    if (typeof value == "object") {
      return value;
    } else if (typeof value == "string" && value != "null") {
      return JSON.parse(value);
    } else {
      return null;
    }
  } else {
    return null;
  }
};
// Return JSON Function
const ReturnJson = value => {
  if (typeof value == "object") {
    return JSON.stringify(value);
  } else if (typeof value == "string" && value != "null") {
    return value;
  } else {
    return null;
  }
};

const NotDebit = [
  "background: #555",
  "color: yellow",
  "display: block",
  "text-align: center",
  "font-size:14px"
].join(";");

const Payments = [
  "background: #d8d7d7",
  "color: red",
  "display: block",
  "text-align: center",
  "font-size:14px"
].join(";");

export default {
  state: {
    rate: 1,
    Total: 0,
    tax_rate: 0,
    tax_sale: 0,
    RetIVA: 0,
    RetISRL: 0,
    SubTotal: 0,
    TotalIVA: 0,
    statusDoc: "",
    exchange_rate: {},
    ItemPago: [],
    Concepts: [],
    num_month_1: [],
    num_month_2: [],
    ConceptDebit: []
  },
  getters: {
    getItemPago: state => {
      return state.ItemPago;
    },
    getAllConcepts: state => {
      return state.Concepts;
    },
    getConceptsDebit: state => {
      return state.ConceptDebit;
    },
    getTotals: state => {
      let totals = {
        SubTotal: state.SubTotal,
        TotalIVA: state.TotalIVA,
        amount: state.Total
      };
      return totals;
    },
    getWithHolding: state => {
      let retentions = {
        RetISRL: state.RetISRL,
        RetIVA: state.RetIVA
      };
      return retentions;
    },
    getRates: state => {
      let rates = {
        rate: state.rate,
        tax_rate: state.tax_rate,
        tax_sale: state.tax_sale
      };
      return rates;
    }
  },
  mutations: {
    ItemsPayments(state, val) {
      state.ItemPago = val;
    },
    ConceptsDebit(state, val) {
      state.ConceptDebit = val;
    },
    AllConcepts(state, val) {
      state.Concepts = val;
    },
    WithHolding(state, val) {
      state.RetIVA = val.RetIVA;
      state.RetISRL = val.RetISRL;
    },
    TotalsDoc(state, val) {
      state.SubTotal = val.SubTotal;
      state.TotalIVA = val.TotalIVA;
      state.Total = val.Total;
    },
    rates(state, val) {
      state.rate = val.rate;
      state.tax_rate = val.tax_rate;
      state.tax_sale = val.tax_sale;
    },
    NumberDaysMonths(state, val) {
      state.num_month_1 = val.num_month_1;
      state.num_month_2 = val.num_month_2;
    },
    setExchangeRate(state, val) {
      state.exchange_rate = val;
    }
  },
  actions: {
    DocQuerys({ getters }) {
      return new Promise((resolve, reject) => {
        window.axios
          .get(getters.getQueries)
          .then(response => {
            // eslint-disable-next-line no-console
            // console.log('DocQuerys', response.data);
            // Base Currency
            let ExchangeRates = [],
              TypesDocument = [],
              StatusDocument = [];
            let ConceptTypes = [],
              TaxesIVA = [],
              ClientType = [],
              document_adjustment_motives=[];
            // Money Received;
            let SourcesMoneyReceived = [],
              PaymentMethods = [],
              StatusMoneyReceived = [];
            let baseCurrency = response.data.catalog_elements.find(currency => {
              return currency.category == "base_currency";
            });
            // eslint-disable-next-line no-console
            let search_for_exchange = `${baseCurrency.id}x`;
            let currency_id = baseCurrency.id;
            baseCurrency.decimal_places =
              baseCurrency.decimal_places != undefined
                ? Number(baseCurrency.decimal_places)
                : 2;
            baseCurrency = {
              id: `${baseCurrency.id}x`,
              name: baseCurrency.name,
              rate: 1,
              decimal_places: baseCurrency.decimal_places,
              icon: baseCurrency.description.icon,
              currency_code: baseCurrency.description.currency_code,
              symbol: baseCurrency.description.symbol,
              updated_at: baseCurrency.updated_at
            };
            // Exchange Rates
            ExchangeRates.push(baseCurrency);
            let exchange_rates = response.data.exchange_rate;
            for (let index = 0; index < exchange_rates.length; index++) {
              ExchangeRates.push(exchange_rates[index]);
            }
            // Tax Bill Rate
            let TaxBillRate = response.data.tax_bill_rate
            // Tax
            let ListIVA = response.data.catalog_elements.filter(iva => {
              return iva.category == "tax";
            });
            // Impuesto a Grandes Transacciones Financieras (IGTF)
            const igtf_is_active = response.data.invoices_elements.igtf_active;
            const IGTF = response.data.catalog_elements.filter(iva => {
              return iva.category === 'other_taxes' && iva.name === 'IGTF';
            }).map(tax => {
              return {
                value: tax.description.value,
                text: `${tax.name} ${tax.description.value}${tax.description.unit}`
              }
            })[0]

            let IVA = ListIVA[0];
            ListIVA.forEach(element => {
              let this_id = element.id;
              if (this_id < IVA.id) {
                IVA = element;
              }
              TaxesIVA.push({
                value: element.id,
                text:
                  element.name +
                  " " +
                  element.description.value +
                  " " +
                  element.description.unit
              });
            });
            // let IVA = ListIVA.find(iva=>{return iva.description.default;});
            let IVAval = {
              value: IVA.description.value,
              text: IVA.name + " " + IVA.description.value + "%"
            };
            let ListUT = response.data.catalog_elements.filter(ut => {
              return ut.category == "constant_ut";
            });
            let UT = ListUT[0];
            ListUT.forEach(element => {
              let this_id = element.id;
              if (this_id < UT.id) {
                UT = element;
              }
            });
            UT = UT.description.value;
            let ListISRL = response.data.catalog_elements.filter(isrl => {
              return isrl.category == "constant_islr";
            });
            let ISRL = ListISRL[0];
            ListISRL.forEach(element => {
              let this_id = element.id;
              if (this_id < ISRL.id) {
                ISRL = element;
              }
            });
            ISRL = ISRL.description.value;
            // eslint-disable-next-line no-console
            // console.log("IVA... UT... ISLR... ", IVA, UT, ISRL);

            // Document adjustment motives
            let doc_adjust_motives = response.data.catalog_elements.filter(catalog => {
              return catalog.category == "document_adjustment_motives";
            });
            doc_adjust_motives.map(motive=>{
              document_adjustment_motives.push(...motive.description);
            })

            // Type Document
            response.data.invoices_elements.client_document_types.forEach(
              element => {
                TypesDocument.push({
                  value: element,
                  text: "document_control.types." + element
                });
              }
            );
            // eslint-disable-next-line no-console
            // console.log('client_document_types',response.data.invoices_elements);
            // Status Document
            response.data.invoices_elements.client_document_status.forEach(
              element => {
                StatusDocument.push({
                  value: element,
                  text: "document_control.status." + element
                });
              }
            );
            // eslint-disable-next-line no-console
            // console.log('client_document_status',response.data.invoices_elements.client_document_status);
            // Concepts Types
            response.data.invoices_elements.concept_types.forEach(element => {
              // the type of adjustment concept is filtered, since this type of concept will only be created by the system
              if (element != "adjustment") {
                ConceptTypes.push({
                  value: element,
                  text: "concept.types." + element
                });
              }
            });
            // Client type
            ClientType = response.data.type_client.map(client => {
              return {
                value: client.id,
                text: client.name,
                is_active: client.is_active,
                is_franchise: client.is_franchise
              };
            });
            // ClientType = response.data.type_client;
            // Banks List
            let Banks = response.data.catalog_elements.filter(bank => {
              return bank.category == "bank";
            });
            let ListBanks = Banks.map(bank => {
              return {
                value: bank.id,
                text: bank.name,
                currency_code: bank.description.currency_code
              };
            });
            // Money Received;
            response.data.money_received.source.forEach(
              element => {
                SourcesMoneyReceived.push({
                  value: element,
                  text: "money_received.sources." + element.toLowerCase()
                });
              }
            );
            response.data.money_received.payment_method.forEach(
              element => {
                PaymentMethods.push({
                  value: element,
                  text: "money_received.payment_methods." + element.toLowerCase()
                });
              }
            );
            response.data.money_received.status.forEach(
              element => {
                StatusMoneyReceived.push({
                  value: element,
                  text: "money_received.status." + element
                });
              }
            );
            resolve({
              baseCurrency: baseCurrency,
              ExchangeRates: ExchangeRates,
              TaxBillRate: TaxBillRate,
              search_for_exchange: search_for_exchange,
              ListIVA: TaxesIVA,
              TypeDocument: TypesDocument,
              StatusDocument: StatusDocument,
              ConceptTypes: ConceptTypes,
              ClientType: ClientType,
              IVA: IVAval,
              Banks: ListBanks,
              UT: UT,
              ISRL: ISRL,
              IGTF: igtf_is_active ? IGTF : null,
              currency_id: currency_id,
              symbol: baseCurrency.symbol,
              document_adjustment_motives: document_adjustment_motives,
              sourcesMoneyReceived: SourcesMoneyReceived,
              paymentMethods: PaymentMethods,
              statusMoneyReceived: StatusMoneyReceived
            });
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error("error", error);
            reject(error);
          });
      });
    },
    DocList({ getters }, params) {
      // eslint-disable-next-line no-console
      // console.log(params);
      // console.clear();
      return new Promise((resolve, reject) => {
        window.axios
          .get(getters.getClientDocument(), {
            params: {
              page: params.page,
              paginate: params.paginate,
              sort_by: params.sort_by,
              sort_desc: params.sort_desc,
              type: params.type,
              start_date: params.start_date,
              end_date: params.end_date,
              control_number: params.control_number,
              document_number: params.document_number,
              status: params.status,
              subscriber_id: params.subscriber_id
            }
          })
          .then(response => {
            let client_documents = response.data.client_documents.data;
            // eslint-disable-next-line no-console
            // console.log('List Docs', client_documents);
            client_documents.map(doc => {
              let exchange_rate = ReturnObject(doc.exchange_rate);
              let rate = 1;
              if (exchange_rate != null && exchange_rate != "") {
                rate = Number(exchange_rate.rate);
              } else {
                rate = 1;
              }
              // eslint-disable-next-line no-console
              // console.log(doc.id, exchange_rate, rate);
              doc.rate = rate;
              doc.amount =
                doc.amount != null ? Number(doc.amount) : Number(0).toFixed(2);
              doc.symbol =
                exchange_rate != null ? exchange_rate.symbol : params.symbol;
            });
            resolve({
              client_documents: response.data.client_documents.data,
              totalValues: response.data.client_documents.total
            });
          })
          .catch(error => {
            reject(error);
            if (error.response) {
              // eslint-disable-next-line no-console
              // console.log('Error', error.response);
            }
          });
      });
    },
    NumberBillableDays({ commit }, dates) {
      // eslint-disable-next-line no-console
      // console.log('ActionsDates',dates.start,dates.end);
      // Number days in Documents
      let start_day = moment(dates.start, "YYYY-MM-DD");
      let end_day = moment(dates.end, "YYYY-MM-DD");
      // Total Number days
      let number_days = end_day.diff(start_day, "days") + 1;
      // Number days month
      // let number_days_month = moment(dates.end, "YYYY-MM-DD").daysInMonth();
      // Calculation of the number of days for calculations in documents
      let first_Day = moment(dates.end, "YYYY-MM-DD").startOf("month");
      let last_day = moment(dates.start, "YYYY-MM-DD").endOf("month");
      // Initialize vars
      let days_1 = 0,
        total_days_1 = 0,
        days_2 = 0,
        total_days_2 = 0;
      let num_month_1 = [],
        num_month_2 = [];
      // We compare the total numbers of days of each month
      if (first_Day.format("MM") == last_day.format("MM")) {
        days_1 = (end_day.diff(start_day, "days") + 1) / 2;
        total_days_1 = moment(start_day, "YYYY-MM-DD").daysInMonth();
        let pivote = [
          end_day.format("YYYY-MM-DD"),
          start_day.format("YYYY-MM-DD"),
          days_1,
          total_days_1,
          days_1 / total_days_1
        ];
        num_month_1 = pivote;
        num_month_2 = pivote;
      } else {
        // eslint-disable-next-line no-console
        // console.log(first_Day.format('MM'),last_day.format('MM'));
        days_1 = end_day.diff(first_Day, "days") + 1;
        total_days_1 = moment(first_Day, "YYYY-MM-DD").daysInMonth();
        days_2 = last_day.diff(start_day, "days") + 1;
        total_days_2 = moment(last_day, "YYYY-MM-DD").daysInMonth();
        num_month_1 = [
          end_day.format("YYYY-MM-DD"),
          first_Day.format("YYYY-MM-DD"),
          days_1,
          total_days_1,
          days_1 / total_days_1
        ];
        num_month_2 = [
          last_day.format("YYYY-MM-DD"),
          start_day.format("YYYY-MM-DD"),
          days_2,
          total_days_2,
          days_2 / total_days_2
        ];
      }
      // Min and Max days
      let min_day1 = moment(dates.start, "YYYY-MM-DD").format("YYYY-MM-DD");
      let min_day2 = moment(dates.start, "YYYY-MM-DD")
        .add(-30, "days")
        .format("YYYY-MM-DD");
      let max_day1 = moment(dates.end, "YYYY-MM-DD").format("YYYY-MM-DD");
      let max_day2 = moment(dates.start, "YYYY-MM-DD")
        .add(30, "days")
        .format("YYYY-MM-DD");
      commit("NumberDaysMonths", {
        num_month_1: num_month_1,
        num_month_2: num_month_2
      });
      return {
        start_day: min_day1,
        end_day: max_day1,
        num_month_1: num_month_1,
        num_month_2: num_month_2,
        number_days: number_days,
        min_day1: min_day1,
        min_day2: min_day2,
        max_day1: max_day1,
        max_day2: max_day2
      };
    },
    ExchangeRate({ commit }, exchange) {
      // eslint-disable-next-line no-console
      // console.log('rate',exchange);
      let exchange_rate = "";
      if (
        Object.prototype.hasOwnProperty.call(exchange, "exchange_rate") &&
        exchange.exchange_rate != null &&
        exchange.exchange_rate != "null" &&
        exchange.exchange_rate != undefined
      ) {
        exchange_rate = ReturnObject(exchange.exchange_rate);
        commit("setExchangeRate", exchange_rate);
        return {
          exchange_rate: exchange_rate,
          currency_code: exchange_rate.currency_code,
          symbol: exchange_rate.symbol,
          id: exchange_rate.id,
          rate: Number(exchange_rate.rate)
        };
      } else {
        if (
          Object.prototype.hasOwnProperty.call(
            exchange.base_currency,
            "currency_code"
          )
        ) {
          exchange_rate = ReturnObject(exchange.base_currency);
          commit("setExchangeRate", exchange_rate);
          return {
            exchange_rate: exchange_rate,
            currency_code: exchange_rate.currency_code,
            symbol: exchange_rate.symbol,
            id: exchange_rate.id,
            rate: 1
          };
        } else {
          return null;
        }
      }
    },
    ClientDocCalculations({ commit }, doc) {
      // eslint-disable-next-line no-console
      // console.log('llego',doc.doc);
      // console.log(Object.entries(doc.concepts).length);
      if (
        Object.entries(doc.concepts).length != 0 &&
        doc.concepts.length != undefined &&
        doc.doc.contract_id !== null &&
        doc.rate &&
        doc.IVA &&
        doc.ISRL &&
        doc.UT
      ) {
        // eslint-disable-next-line no-console
        // console.log(doc.IVA,doc.UT,doc.ISRL);
        // Concepts
        let concepts = doc.concepts;
        // Initialize Totals
        let SubTotal = 0,
          TotalIVA = 0,
          Totals = 0;
        // Initialize retentions
        let tax_rate = doc.tax_rate != null ? doc.tax_rate : 0;
        let tax_sale = doc.tax_sale != null ? doc.tax_sale : 0;
        let rate = doc.rate != null ? doc.rate : 1;
        let UT = doc.UT,
          ISRL = doc.ISRL,
          RetIVA = 0,
          RetISRL = 0,
          IGTF = doc.IGTF ? doc.IGTF : null,
          IVA = doc.IVA;
        // Initialize dates
        let calulate = [];
        let num_month_1 = doc.num_month_1;
        let num_month_2 = doc.num_month_2;
        // Initialize arrays
        let ItemPay = [];
        let ItemConcepts = [];
        let ItemAdjustment = [];
        // Currency Code
        let currency_code = "";
        let exchange_rate = ReturnObject(doc.doc.exchange_rate);
        let currency = ReturnObject(doc.doc.currency);
        // eslint-disable-next-line no-console
        // console.log('exchange',exchange_rate);
        // eslint-disable-next-line no-console
        // console.log('currency',currency);
        if (exchange_rate != null) {
          currency_code = exchange_rate.currency_code;
        } else if (exchange_rate == null && currency != null) {
          // eslint-disable-next-line no-console
          if (currency.description) {
            currency_code = currency.description.currency_code;
          } else if (currency.currency_code) {
            currency_code = currency.currency_code;
          }
        }

        let code1 =
          currency_code != null && currency_code != undefined
            ? currency_code
            : "USD";
        let code2 = "";
        // eslint-disable-next-line no-console
        if (currency != null) {
          if (
            currency.description != null &&
            currency.description != undefined
          ) {
            code2 = currency.description.currency_code;
          } else if (
            currency.currency_code != null &&
            currency.currency_code != undefined
          ) {
            code2 = currency.currency_code;
          } else {
            code2 = "USD";
          }
        }
        // eslint-disable-next-line no-console
        // console.log('Code',currency_code);
        // Concepts type debit calculations
        concepts.map(concept => {
          let price = 0;
          if (!concept.is_percentage) {
            if (concept.type == "debit") {
              if (concept.is_one_time) {
                price = Number(concept.amount) * rate;
                // eslint-disable-next-line no-console
                // console.log('Amount Rate ',concept.amount, rate, concept.quantity, price.toFixed(2));
              } else {
                price =
                  Number(concept.amount) *
                  rate *
                  (num_month_1[4] + num_month_2[4]);
                // eslint-disable-next-line no-console
                // console.log('Amoun rate ',concept.amount, rate, concept.quantity,(num_month_1[4]+ num_month_2[4]).toFixed(2), price.toFixed(2));
              }
              concept.price = price;
              concept.total = Number(concept.quantity) * price;
              // eslint-disable-next-line no-console
              // console.log('P T IVA',concept.price, concept.total, IVA.value);
              SubTotal = SubTotal + Number(concept.quantity) * price;
              TotalIVA =
                TotalIVA + Number(concept.quantity) * price * (IVA.value / 100);
              // eslint-disable-next-line no-console
              // console.log(Totals.toFixed(2), SubTotal.toFixed(2), TotalIVA.toFixed(2));
              calulate.push({
                quantity: concept.quantity,
                price: round(price, 2),
                total: round(concept.total, 2),
                TotalIVA: round(
                  Number(concept.quantity) * price * (IVA.value / 100),
                  2
                ),
                Totals: round(SubTotal + TotalIVA, 2)
              });
            } else if (concept.type == "adjustment") {
              price = Number(concept.amount) * rate;
              concept.price = -1 * price;
              concept.total = -1 * Number(concept.quantity) * price;
              SubTotal = SubTotal - Number(concept.quantity) * price;
              calulate.push({
                quantity: concept.quantity,
                price: round(price, 2),
                total: round(concept.total, 2),
                TotalIVA: TotalIVA,
                Totals: round(SubTotal + TotalIVA, 2)
              });
            }
            // adjustment
          } else {
            concept.price = 0;
            concept.total = 0;
          }
        });
        // The calculation of the Total IVA of the document is modified, so that it is calculated with the base amount
        TotalIVA = SubTotal * (IVA.value / 100);
        Totals = SubTotal + TotalIVA;
        // Filter Concepts type adjustment
        ItemAdjustment = concepts.filter(concept => {
          return concept.type == "adjustment";
        });
        // Filter Concepts type debit
        ItemConcepts = concepts.filter(concept => {
          return concept.price >= 0 && concept.type == "debit";
        });
        ItemConcepts = [...ItemConcepts, ...ItemAdjustment];
        // Totales Antes de retencions, impuestos, descuentos.
        ItemPay.push(
          {
            text: "paymentNote.taxbase",
            value: SubTotal,
            type: 1,
            locale: true
          },
          { text: IVA.text, value: TotalIVA, type: 1, locale: false },
          { text: "Total", value: SubTotal + TotalIVA, type: 1, locale: false }
        );
        // eslint-disable-next-line no-console
        // console.log(SubTotal, TotalIVA, Totals);
        // Se agregan los conceptos que no son debito
        let Total_payments = 0;
        let Total_discount = 0;
        let Total_credit = 0;
        let Total_exoneration = 0;
        let Total_retention = 0;
        let Total_retention_tax = 0;
        concepts.map(concept => {
          if (concept.type != "debit" && concept.type != "adjustment") {
            let extra = 0;
            let sing = -1;
            let type = 3;
            if (concept.is_percentage) {
              extra = (sing * Number(concept.amount) * SubTotal) / 100;
              if (process.env.NODE_ENV == "development") {
                // eslint-disable-next-line no-console
                console.log(
                  `\nSubTotal= ${formatVES(SubTotal, code1)}\n%= ${formatVES(
                    concept.amount / 100,
                    "percent"
                  )}\n${concept.type} = ${formatVES(extra, code1)}`
                );
              }
            } else {
              extra = sing * Number(concept.amount) * rate;
              if (process.env.NODE_ENV == "development") {
                // eslint-disable-next-line no-console
                console.log(
                  `\nRate  = ${rate}\nAmount= ${formatVES(
                    concept.amount,
                    code2
                  )}\n${concept.type} = ${formatVES(extra, code1)}`
                );
              }
            }
            if (
              (concept.type == "credit" &&
                concept.name.includes("Abono a Nota de Cobro")) ||
              (concept.type == "credit" &&
                concept.name.includes("Pago de Nota de Cobro"))
            ) {
              Total_payments = Total_payments + extra;
              type = 8;
            } else if (
              (concept.type == "credit" &&
                !concept.name.includes("Abono a Nota de Cobro")) ||
              (concept.type == "credit" &&
                !concept.name.includes("Pago de Nota de Cobro"))
            ) {
              Total_credit = Total_credit + extra;
              type = 6;
            } else if (concept.type == "discount") {
              Total_discount = Total_discount + extra;
              type = 4;
            } else if (concept.type == "exoneration") {
              Total_exoneration = Total_exoneration + extra;
              type = 2;
            } else if (concept.type == "retention") {
              // Retention type concept
              Total_retention = Total_retention + extra;
              type = 12;
            }
            Totals = Totals + extra;
            // eslint-disable-next-line no-console
            // console.log(Totals);
            calulate.push({
              quantity: 1,
              price: round(extra, 2),
              total: round(extra, 2),
              TotalIVA: round(0, 2),
              Totals: round(Totals, 2)
            });
            ItemPay.push({
              text: concept.name,
              value: extra,
              type: type,
              ccpt: concept
            });
          }
        });
        // Solo se imprimen en consola en modo desarrollo
        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.log(
            `\n%cTotal Payments ${formatVES(Total_payments, code1)}`,
            Payments
          );
          // eslint-disable-next-line no-console
          console.log(
            `%cTotal Credit ${formatVES(Total_credit, code1)}`,
            NotDebit
          );
          // eslint-disable-next-line no-console
          console.log(
            `%cTotal Discount ${formatVES(Total_discount, code1)}`,
            NotDebit
          );
          // eslint-disable-next-line no-console
          console.log(
            `%cTotal Exoneration ${formatVES(Total_exoneration, code1)}`,
            NotDebit
          );
          // eslint-disable-next-line no-console
          console.log(
            `%cTotal Retention ${formatVES(Total_retention, code1)}`,
            NotDebit
          );
        }
        // eslint-disable-next-line no-console
        // console.log('discounts', Totals);

        // Retencion por IVA
        // eslint-disable-next-line no-console
        // console.log('tax',tax_rate, tax_sale);
        if (tax_rate) {
          RetIVA = -(TotalIVA * Number(tax_rate));
          Total_retention_tax = Total_retention_tax + RetIVA;
          // eslint-disable-next-line no-console
          // console.log(`\nIVA     = ${tax_rate}\nTotal IVA= ${formatVES(TotalIVA,currency_code)}\nRetIVA   = ${formatVES(RetIVA,currency_code)}`);
          // console.log('IVA',Totals, RetIVA);
          Totals = Totals + RetIVA;
          calulate.push({
            quantity: 1,
            price: round(RetIVA, 2),
            total: round(RetIVA, 2),
            TotalIVA: round(0, 2),
            Totals: round(Totals, 2)
          });
          ItemPay.push({
            text: "paymentNote.withholdingIVA",
            value: RetIVA,
            type: 10,
            locale: true
          });
        }

        // Retencion por ISRL
        if (tax_sale) {
          // if (tax_sale && currency_code == "VES") {
          // RetISRL = -(
          //   SubTotal * Number(tax_sale) -
          //   UT * Number(tax_sale) * ISRL
          // );
          RetISRL = -(SubTotal * Number(tax_sale));
          Total_retention_tax = Total_retention_tax + RetISRL;
          Totals = Totals + RetISRL;
          // eslint-disable-next-line no-console
          // console.log(`\nISRL    = ${tax_sale}\nSubTotal= ${formatVES(SubTotal,currency_code)}\nUT      = ${round(UT,2)}\nTaxISRL = ${round(ISRL,2)}\nSubTotal*tax_sale= ${formatVES(SubTotal*Number(tax_sale),currency_code)}\nUT*ISRL*TaxISRL= -${formatVES(UT*Number(tax_sale)*ISRL,currency_code)}\nTotal    =${formatVES(RetISRL,currency_code)}`);
          calulate.push({
            quantity: 1,
            price: round(RetIVA, 2),
            total: round(RetIVA, 2),
            TotalIVA: round(0, 2),
            Totals: round(Totals, 2)
          });
          ItemPay.push({
            text: "paymentNote.withholdingISRL",
            value: RetISRL,
            type: 10,
            locale: true
          });
        }

        // If credits the total is added
        if (Total_discount != 0 && Total_exoneration != 0) {
          ItemPay.push({
            text: "paymentNote.totalWithOutDiscount",
            value:
              Totals -
              Total_retention -
              Total_retention_tax -
              Total_payments -
              Total_credit -
              Total_discount,
            type: 3,
            locale: true
          });
        }
        // If credits the total is added
        if (
          Total_credit != 0 &&
          (Total_discount != 0 || Total_exoneration != 0)
        ) {
          ItemPay.push({
            text: "paymentNote.totalWithOutCredits",
            value:
              Totals -
              Total_retention -
              Total_retention_tax -
              Total_payments -
              Total_credit,
            type: 5,
            locale: true
          });
        }
        // If credits and / or payments apply, the total is added
        if (
          Total_payments != 0 &&
          (Total_discount != 0 || Total_exoneration != 0 || Total_credit != 0)
        ) {
          ItemPay.push({
            text: "paymentNote.totalWithOutCP",
            value:
              Totals - Total_retention - Total_retention_tax - Total_payments,
            type: 7,
            locale: true
          });
        }
        // If deductions apply the total is added
        if (
          (Total_retention != 0 || Total_retention_tax != 0) &&
          (Total_discount != 0 ||
            Total_exoneration != 0 ||
            Total_credit != 0 ||
            Total_payments != 0)
        ) {
          ItemPay.push({
            text: "paymentNote.totalWithOutRet",
            value: Totals - Total_retention - Total_retention_tax,
            type: 9,
            locale: true
          });
        }

        if (process.env.NODE_ENV == "development") {
          // eslint-disable-next-line no-console
          console.log(
            `%cTotal Retention Tax ${formatVES(Total_retention_tax, code1)}`,
            NotDebit
          );
        }
        // Tax for payment in foreign currency
        if (IGTF && code1 != 'VES') {
          let igtf_amount = 0
          if (doc.doc.type == 'invoice') {
            igtf_amount = doc.doc.extra.igtf_amount
            ItemPay.push(
              { text: IGTF.text, value: igtf_amount, type: 22, locale: false },
            )
          }
          else {
            igtf_amount = Totals * (IGTF.value / 100)
            const total_ref = Totals + igtf_amount
            ItemPay.push(
              { text: IGTF.text, value: igtf_amount, type: 24, locale: false },
              { text: 'paymentNote.totalPay', value: total_ref, type: 25, locale: true }
            )
          }
        }
        Totals = minValue(Totals, 1e-3, 4);
        // eslint-disable-next-line no-console
        // console.log('Totals',Totals);
        // console.table(calulate);
        if (
          Total_retention != 0 ||
          Total_retention_tax != 0 ||
          Total_payments != 0 ||
          Total_credit != 0 ||
          Total_discount != 0 ||
          Total_exoneration != 0
        ) {
          ItemPay.push({
            text: "paymentNote.totalPay",
            value: Totals,
            type: 23,
            locale: true
          });
        }
        ItemPay = ItemPay.sort((a, b) => a.type - b.type);
        doc.doc.amount = Totals;
        doc.doc.concepts = concepts;
        doc.doc.exchange_rate =
          ReturnJson(exchange_rate) == "null"
            ? null
            : ReturnJson(exchange_rate);
        doc.doc.currency = ReturnJson(currency);
        commit("rates", {
          rate: doc.rate,
          tax_rate: doc.tax_rate,
          tax_sale: doc.tax_sale
        });
        commit("WithHolding", { RetISRL: RetISRL, RetIVA: RetIVA });
        commit("AllConcepts", concepts);
        commit("ConceptsDebit", ItemConcepts);
        commit("ItemsPayments", ItemPay);
        commit("TotalsDoc", {
          SubTotal: SubTotal,
          TotalIVA: TotalIVA,
          Total: Totals
        });
        return {
          doc: doc.doc,
          concepts: concepts,
          ItemsConcept: ItemConcepts,
          ItemPago: ItemPay,
          amount: Totals,
          AllTotals: {
            SubTotal: SubTotal / rate,
            TotalIVA: TotalIVA / rate,
            Total: Totals / rate,
            TotalWithOutR:
              (Totals - Total_retention - Total_retention_tax) / rate,
            Total_payments: Total_payments / rate,
            Total_discount: Total_discount / rate,
            Total_credit: Total_credit / rate,
            Total_exoneration: Total_exoneration / rate,
            Total_retention: Total_retention / rate,
            Total_retention_tax: Total_retention_tax / rate
          }
        };
        // eslint-disable-next-line no-console
        // console.table(concepts);
      } else {
        commit("rates", { rate: 1, tax_rate: 0, tax_sale: 0 });
        commit("WithHolding", { RetISRL: 0, RetIVA: 0 });
        commit("AllConcepts", []);
        commit("ConceptsDebit", []);
        commit("ItemsPayments", []);
        commit("TotalsDoc", { SubTotal: 0, TotalIVA: 0, Total: 0 });
        return null;
      }
    }
  }
};
