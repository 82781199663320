<template>
  <v-dialog v-model="dialog_replica" max-width="380" persistent scrollable>
    <v-card confirmation>
      <v-card-title class="info">
        <span class="headline font-weight-bold py-1">
          {{ `${$t('replicate')}`}}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters class="d-flex justify-center">
          <v-col class="d-flex flex-column">
            <span> {{`${computeType} - ${computeStatus}`}} </span>
            <span> N° {{document.control_number}} </span>
            <span> {{`${$t('paymentNote.subscriber')}: ${document.legal_denomination}`}} </span>
            <span> {{ document.person_type == 'natural' ? $tc('subscribers.dni',0) : $tc('subscribers.dni',1)}}: {{document.dni}} </span>
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12"
              class="pa-0 pt-2 text-center"
            >
              <b>{{ $t('document_control.change_header') }}</b>
              <v-row class="my-3">
                <v-col cols="5" class="small-label bold-label text-right">
                  <span class="v-label" :class="!change_header ? 'primary--text':''">
                    No
                  </span>
                </v-col>
                <v-col cols="2">
                  <v-switch inset
                    v-model="change_header"
                    color="primary"
                    class="small-label bold-label ma-0 pa-0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="5" class="small-label bold-label text-left">
                  <span class="v-label" :class="change_header ? 'primary--text':''">
                    {{$tc('yes',0)}}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <!--  -->
            <v-col cols="12"
              class="pa-0 pt-2 text-center"
            >
              <b>{{ $t('document_control.change_dates') }}</b>
              <v-row class="my-3">
                <v-col cols="5" class="small-label bold-label text-right">
                  <span class="v-label" :class="!change_dates ? 'primary--text':''">
                    No
                  </span>
                </v-col>
                <v-col cols="2">
                  <v-switch inset
                    v-model="change_dates"
                    color="primary"
                    class="small-label bold-label ma-0 pa-0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="5" class="small-label bold-label text-left">
                  <span class="v-label" :class="change_dates ? 'primary--text':''">
                    {{$tc('yes',0)}}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel
          :disabled="loadig_replicas"
          @click="close"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{$t('base.cancel')}}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save
          :loading="loadig_replicas"
          @click="ReplicateDoc"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{$t('base.confirm')}}
          </span>
          <v-icon small>fas fa-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // props: [''],
  name:'',
  data(){
    return {
      dialog_replica: false,
      loadig_replicas: false,
      change_header: true,
      change_dates: true,
      document:{},
    }
  },
  computed: {
    computeType(){
      return this.$t('document_control.types.'+ this.document.type);
    },
    computeStatus(){
      return this.$t('document_control.status.'+ this.document.status);
    },
  },
  methods: {
    open(item){
      this.dialog_replica = true
      this.document = item;
    },
    close(){
      this.dialog_replica = false;
      this.document = {};
      this.change_header = true;
      this.change_dates = true;
    },
    ReplicateDoc(){
      let params = {
        change_header:1,
        change_dates:1,
      };
      if (!this.change_header) params.change_header = 0;
      if (!this.change_dates) params.change_dates = 0;
      axios.post(this.$store.getters.getClientDocumentReplicate(this.document.id), params)
      .then(response=>{
        // eslint-disable-next-line no-console
        // console.log(response.data);
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifText: 'success',
          notifPos: 1,
          extra: {
            model: this.computeType,
            action: this.$tc('replicated',2) 
          },
          pos:2
        });
        this.close();
        this.$emit('closePreview');
      }).catch(error=>{
        // eslint-disable-next-line no-console
        // console.log(error.response);
      });
    }
  },
  watch:{}
}
</script>
