<template>
  <div :class="classParent">
    <ValidationObserver ref="form_contact" v-slot="{ handleSubmit }">
      <v-form class="contact-form" @submit.prevent="handleSubmit(submit)">
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="first_name"
              :name="$t('subscribers.first_name')"
              :rules="{
                required: true,
                min: 3,
                regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
              }"
            >
              <v-text-field
                v-model="contact.first_name"
                name="first_name"
                prepend-icon="fas fa-user"
                counter
                maxlength="40"
                class="input-uppercase"
                :label="$t('subscribers.first_name')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="last_name"
              :name="$t('subscribers.last_name')"
              :rules="{
                required: true,
                min: 3,
                regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
              }"
            >
              <v-text-field
                v-model="contact.last_name"
                name="last_name"
                prepend-icon="fas fa-user"
                counter
                maxlength="40"
                class="input-uppercase"
                :label="$t('subscribers.last_name')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-row no-gutters>
              <v-col cols="4" sm="5" class="pa-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="prefix"
                  :name="$tc('subscribers.prefix', 1)"
                  rules="required"
                >
                  <v-select
                    v-model="contact.prefix"
                    :items="natural_prefixes"
                    name="prefix"
                    prepend-icon="fas fa-list"
                    menu-props="auto"
                    :label="$tc('subscribers.prefix', 1)"
                    :error-messages="errors"
                    :disabled="loadingContact || loadingPrefixes || loadingForm"
                    :loading="loadingContact || loadingPrefixes || loadingForm"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="8" sm="7" class="pa-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="dni"
                  :name="$t('dni')"
                  rules="required|numeric|min:6|max:9"
                >
                  <v-text-field
                    v-model="contact.dni"
                    name="dni"
                    counter
                    maxlength="9"
                    prepend-inner-icon="fas fa-address-card"
                    :label="$t('dni')"
                    :error-messages="errors"
                    :disabled="loadingContact || loadingForm"
                    :loading="loadingContact || loadingForm"
                    class="small-inner-icon"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="position"
              :name="$tc('actor.position')"
              :rules="{
                required: true,
                regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
              }"
            >
              <v-text-field
                v-model="contact.position"
                name="position"
                prepend-icon="fas fa-sitemap"
                :label="$tc('actor.position')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="local_phone"
              :name="$t('phone')"
              rules="numeric|digits:11"
            >
              <v-text-field
                v-model="contact.local_phone"
                name="local_phone"
                counter
                maxlength="11"
                prepend-icon="fas fa-phone fa-rotate-180"
                :label="$t('phone')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="cellphone"
              :name="$t('cellphone')"
              rules="required|numeric|digits:11"
            >
              <v-text-field
                v-model="contact.cellphone"
                name="cellphone"
                counter
                maxlength="11"
                prepend-icon="fas fa-mobile-alt"
                :label="$t('cellphone')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="email"
              :name="$t('base.email')"
              rules="required|email"
            >
              <v-text-field
                v-model="contact.email"
                name="email"
                counter
                maxlength="140"
                prepend-icon="fas fa-envelope"
                :label="$t('base.email')"
                :error-messages="errors"
                :disabled="loadingContact || loadingForm"
                :loading="loadingContact || loadingForm"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<style type="text/css">
.small-inner-icon i {
  font-size: 18px !important;
}
</style>

<script>
export default {
  name: "contac-subscriber",
  components: {},
  props: {
    /**
     * Class parent css
     */
    classParent: {
      type: String,
      default: ""
    },
    /**
     * Data Contact
     */
    same_as_previous: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    /**
     * Loadding all fields
     */
    loadingContact: {
      type: Boolean,
      default: false
    },
    /**
     * Check if form by update or create
     */
    editMode: {
      type: Boolean,
      default: false
    },
    /**
     * Contract Id
     */
    contractId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loadingPrefixes: true,
      contact: {},
      natural_prefixes: [],
      copy_contact: "",
      loadingForm: false,
      modeNode: process.env.NODE_ENV
    };
  },
  computed: {},
  methods: {
    loadNaturalPrefixes() {
      if (this.natural_prefixes.length == 0) {
        this.loadingPrefixes = true;
        axios
          .get(this.$store.getters.getPrefixes, {
            params: {
              type: "natural"
            }
          })
          .then(response => {
            this.natural_prefixes = response.data.prefixes;
          })
          .catch(err => {
            this.natural_prefixes = [];
          })
          .finally(() => {
            this.loadingPrefixes = false;
          });
      }
    },
    getContact() {
      if (this.same_as_previous) {
        this.loadingForm = false;
        // eslint-disable-next-line no-console
        // console.log('same',this.same_as_previous);
        this.contact = this.same_as_previous;
        // Prefix
        var prefix =
          this.same_as_previous.dni != null &&
          this.same_as_previous.dni != undefined
            ? this.same_as_previous.dni
            : "";
        if (typeof prefix == "string" && prefix != "") {
          this.contact.prefix = prefix.charAt(0);
          this.contact.dni = Number(prefix.substring(1));
        }
        this.copy_contact = JSON.stringify(this.contact);
      } else if (!this.editMode) {
        this.contact.contract_id = this.contractId;
      }
    },
    validateForm() {
      this.$refs.form_contact.validate()
      .then((success) => {
        if (success) {
          if (this.edit == false) {
            this.createContact();
          } else {
            this.validCloneByUpdate();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
          this.$emit("contactError");
        }
      });
    },
    resetForm() {
      // eslint-disable-next-line no-console
      // console.log('Reset Validation Form')
      this.$refs.form_contact.reset();
    },
    validCloneByUpdate() {
      const copy = JSON.parse(this.copy_contact);
      const origin = JSON.parse(JSON.stringify(this.contact));
      let notSame = [];
      for (var key in origin) {
        if (typeof origin[key] != "object") {
          if (origin[key] != copy[key]) {
            notSame.push({ key: origin[key] != copy[key] });
          }
        }
      }
      setTimeout(() => {
        if (notSame.length && notSame.length > 0) {
          this.updateContact();
        } else {
          this.$emit("contact", this.contact, "same");
          this.$awn.closeToasts();
          this.$awn.warning(this.$t("DSB.profile.nothing_to_change"), {
            durations: { warning: 4000 }
          });
        }
      }, 5);
    },
    createContact() {
      const url = this.$store.getters.getContractContacts();
      this.loadingForm = true;
      this.$emit("loading", true);
      axios
        .post(url, this.contact)
        .then(response => {
          if (response.status == 200) {
            this.$emit("contact", this.contact);
            this.$emit(
              "notify",
              this.$tc("created", 1),
              this.$tc("subscribers.contact", 1)
            );
            this.pushNoty("subscribers.contact", "created");
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loadingForm = false;
            }, 1500);
          } else {
            this.loadingForm = false;
            this.$emit("loading", false);
          }
        });
    },
    updateContact() {
      this.$awn.closeToasts();
      const url = this.$store.getters.getContractContacts(this.contact.id);
      this.loadingForm = true;
      this.$emit("loading", true);
      axios
        .patch(url, this.contact)
        .then(response => {
          if (response.status == 204) {
            //
          }
          if (response.status == 200) {
            this.$awn.closeToasts();
            this.$emit("contact", this.contact, "update");
            this.$emit(
              "notify",
              this.$tc("updated", 1),
              this.$tc("subscribers.contact", 1)
            );
            this.pushNoty("subscribers.contact", "updated");
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loadingForm = false;
            }, 1500);
          } else {
            this.loadingForm = false;
            this.$emit("loading", false);
          }
        });
    },
    pushNoty(model, action, pos = 1, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: this.$tc(model, pos),
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    }
  },
  watch: {},
  mounted() {
    this.loadNaturalPrefixes();
    this.getContact();
  }
};
</script>
