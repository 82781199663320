<template>
<div>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="formatDate(value)"
        :label="label"
        :name="name"
        prepend-icon="fas fa-calendar-alt"
        readonly
        persistent-hint
        :hint="$store.getters.getDateFormat[0]"
        :error-messages="errorMessages"
      ></v-text-field>
    </template>
    <v-date-picker ref="picker"
      :readonly="readonly"
      :disabled="disabled"
      :value="value"
      :min="min"
      :max="max"
      @input="update"
    ></v-date-picker>
  </v-menu>
</div>
</template>
<script>
export default {
  props:{
    readonly:{
      default:false,
      type:Boolean
    },
    disabled:{
      default:false,
      type:Boolean
    },
    errorMessages: {
      default:''
    },
    value:{
      required:true,
      default:"",
      type:String
    },
    label:{
      required:true,
      default:"",
      type:String
    },
    name:{
      required:true,
      default:"",
      type:String
    },
    min:{
      type:String
    },
    max:{
      type:String
    }
  },
  data(){
    return {
      menu:false,
    }
  },
  watch:{
    value:{
      immediate:true,
      handler(){}
    },
    max:{
      immediate:true,
      handler(){
        this.$emit('input','')
      }
    },
    min:{
      immediate:true,
      handler(){
        this.$emit('input','')
      }
    }
  },
  methods:{
    update(evt){
      this.$emit('input',evt)
      this.menu = false
    },
    formatDate (date) {
      if (!date) return ''
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
  }
}
</script>