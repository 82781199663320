export default {
  name: 'notifications',
  data () {
    return {}
  },
  computed:{
    snackbar:{
      get(){
        return this.$store.getters.getSnackbar
      },
      set(newValue){
        this.$store.dispatch('snackValue',newValue)
      }
    }
  }
}
