var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11","lg":"8"}},[_c('v-data-table',{staticClass:"elevation-1 accounts-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.bank_accounts,"footer-props":{
          'items-per-page-options': [5, 10, 50]
        },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable,"hide-default-footer":_vm.totalValues == 0,"hide-default-header":_vm.no_data},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.permissions.create,"disableNew":_vm.loadingTable,"title":_vm.$tc('banks.bank_account', 2),"nameButton":("" + (_vm.$tc('base.new', 2)))},on:{"newItem":_vm.openDialog}})]},proxy:true},{key:"item.bank.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow-hover"},[_c('span',[_vm._v(_vm._s(item.bank.name))])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name == "both" ? _vm.$t("both") : _vm.$t("banks." + item.name))+" ")])]}},{key:"item.description.account_holder_dni",fn:function(ref){
        var item = ref.item;
return [(item.description.account_holder)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.description.account_holder_dni)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description.account_holder))])]):_c('span',[_vm._v(_vm._s(item.description.account_holder_dni))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","small":"","edit":""},on:{"click":function($event){return _vm.openDialog(item)}}},on),[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.permissions.delete)?_c('v-btn',_vm._g({attrs:{"icon":"","small":"","delete":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("fas fa-times")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.delete")))])])]}}],null,true)})],1),_c('add-edit-dialog',{ref:"account_dialog",attrs:{"permissions":_vm.permissions},on:{"reloadTable":_vm.getAccounts}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }