import DebtDialog from "./DebtDialog.vue";
import ShowTotal from "./Showtotal.vue";

export default {
  name: "DebtPerSubscriber",
  components: {
    DebtDialog,
    ShowTotal
  },
  data() {
    return {
      amount_operator: '>',
      amount_limit: 0,
      tmp_limit: null,
      // Table Options
      loadingTable: false,
      totalValues: 0,
      perPage: 10,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["legal_denomination"],
        sortDesc: [false]
      },
      // Data and Search
      step: "tab-1",
      copyList: "",
      total_debt: 0,
      total_corporate_debt: 0,
      total_franchise_debt: 0,
      subscribers: [],
      searchBy: {
        by_name: "",
        sector_id: null,
        franchise_id:
          this.$store.getters.getFranchiseID == null
            ? 0
            : Number(this.$store.getters.getFranchiseID),
      },
      // Permissions or policies
      policies: {
        show_subscriber_debt: false,
        get_rates: false
      },
      // Others
      unwatch: "",
      base_currency: {},
      currency_symbol: "",
      currency_decimal_places: 2,
      modeNode: process.env.NODE_ENV,
      rateConverted: false,
      subscribers_copy: '',
      selected_rate: 1,
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          value: "id",
          text: this.$t("base.code"),
          align: "center",
          filterable: false,
          width: 86
        },
        {
          value: "code",
          text: this.$tc("subscribers.code", 2),
          align: "left",
          filterable: false,
          width: 110
        },
        {
          value: "doc",
          text: this.$tc("subscribers.dni", 2),
          align: "left",
          filterable: false,
          width: 120,
        },
        {
          value: "legal_denomination",
          text: this.$t("subscribers.name"),
          align: "left",
          filterable: false
        },
        {
          value: "dues",
          text: this.$tc("subscribers.dues", 1),
          align: "center",
          filterable: false,
          width: 90
        },
        {
          value: "total_debt",
          text: "Total",
          align: "right",
          filterable: false,
          width: this.rateConverted ? 180 : 120
        }
      ];
      if (this.policies.show_subscriber_debt) {
        headers.push({
          text: this.$t("action"),
          value: "action",
          sortable: false,
          filterable: false,
          align: "left",
          width: 100
        });
      }
      return headers;
    }
  },
  methods: {
    List() {
      this.loadingTable = true;
      this.subscribers = []
      this.totalValues = 0
      // paramas by filter
      let ops = {
        params: {
          amount_limit: this.amount_limit,
          operator: this.amount_operator,
          page: this.options.page,
          paginate: this.options.itemsPerPage,
          orderBy: this.options.sortBy[0] || null,
          dirDesc: this.options.sortDesc[0] || null,
          type_client_id: this.searchBy.type_client_id,
          franchise_id: this.searchBy.franchise_id,
          service_id: this.searchBy.service_id,
          package_id: this.searchBy.package_id,
          sector_id: this.searchBy.sector_id,
          legal_denomination: this.searchBy.legal_denomination,
          doc: this.searchBy.doc
        }
      };
      if (this.step == "tab-2") {
        ops.params.cutoff = 1;
      } else {
        delete ops.params.cutoff;
      }
      axios
        .get(this.$store.getters.filterDebtPerSubscriber, ops)
        .then(response => {
          if (response.data) {
            this.total_corporate_debt = response.data.gral_corporate_debt;
            this.total_franchise_debt = response.data.gral_franchise_debt;
            this.total_debt = response.data.gral_total_debt;
            this.subscribers = response.data.subscribers.data;
            this.totalValues = response.data.subscribers.total;
            this.subscribers_copy = JSON.stringify(this.subscribers)
            if (this.rateConverted) {
              this.changeToRate(
                this.selected_rate, this.currency_symbol, this.currency_decimal_places, true
              )
            }
          }
        })
        .catch(error => {
          if (error.response) {
            if (this.modeNode == "development") {
              // eslint-disable-next-line no-console
              console.error(error.response);
            }
          }
        })
        .finally(() => {
          this.delayOnSearch();
        });
    },
    delayOnSearch() {
      if (this.modeNode == "development") {
        setTimeout(() => {
          this.loadingTable = false;
        }, 400);
      } else {
        this.loadingTable = false;
      }
    },
    showItem(data) {
      this.$refs.debt_subscriber.openDebt(data);
    },
    PoliciesFilter() {
      this.policies.get_rates = this.getPermissionGroup({
        permission:[
          'index_exchangeRate'
        ],
        mode:'all'
      });
      this.policies.get_all = this.getPermissionGroup({
        permission:[
          'allOverdueCutoff_subscriber'
        ],
        mode:'all'
      });
      this.policies.show_subscriber_debt = this.getPermissionGroup({
        permission:[
          'getOverdueCutoff_subscriber'
        ],
        mode:'all'
      });
    },
    start() {
      axios
        .get(this.$store.getters.catalogByCategory("base_currency"))
        .then(response => {
          if (response.data.success) {
            const currency = response.data.success[0];
            currency.decimal_places =
              currency.decimal_places != undefined
                ? Number(currency.decimal_places)
                : 2;
            this.base_currency = {
              id: currency.id + "x",
              name: currency.name,
              rate: 1,
              decimal_places: currency.decimal_places,
              icon: currency.description.icon,
              currency_code: currency.description.currency_code,
              symbol: currency.description.symbol,
              updated_at: currency.updated_at
            };
            this.currency_symbol = this.base_currency.symbol;
            this.currency_decimal_places = this.base_currency.decimal_places;
          }
        });
    },
    filterBy(item, type){
      // eslint-disable-next-line no-console
      // console.log(item, type);
      this.options.page = 1;
      this.searchBy[type] = item;
      this.List();
    },
    eraseFilter() {
      this.searchBy = {
        franchise_id:
          this.$store.getters.getFranchiseID == null
            ? 0
            : this.$store.getters.getFranchiseID
      };
      this.amount_operator = '>'
      this.tmp_limit = null
      this.amount_limit = 0
      this.List();
    },
    setLimit () {
      this.tmp_limit = this.amount_limit
    },
    checkLimit () {
      if (this.tmp_limit != this.amount_limit) {
        this.List()
        this.tmp_limit = null
      }
    },
    toggleOperator () {
      this.amount_operator = this.amount_operator === '>' ? '<' : '>'
      this.tmp_limit = null
      this.amount_limit = 0
    },
    changeToRate(rate, symbol, decimal_places, force_true = false){
      if (force_true) {
        this.rateConverted = true
      } else {
        this.rateConverted = !this.rateConverted
      }
      if (this.rateConverted) {
        this.selected_rate = rate
        this.currency_symbol = symbol
        this.currency_decimal_places = decimal_places
        this.subscribers.map(val=>{
          if (typeof(val.total_debt) == 'number' && val.total_debt > 0) {
            let debt = val.total_debt
            val.total_debt = debt * rate
          }
        })
      } else {
        this.selected_rate = 1
        this.currency_symbol = this.base_currency.symbol
        this.currency_decimal_places = this.base_currency.decimal_places
        this.subscribers = JSON.parse(this.subscribers_copy);
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.List();
      },
      deep: true
    },
    step() {
      this.searches = { dni: "", active: "", type: "", franchise_id: "" };
      this.options = { page: 1, sortBy: ["legal_denomination"], sortDesc: [false] };
    }
  },
  created() {
    this.start();
    this.PoliciesFilter();
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        if (val) {
          this.List();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {}
};
