<template>
  <v-dialog
    v-model="dialog1"
    max-width="500px"
    scrollable
    @click:outside="close"
  >
    <v-card tile>
      <toolbarDialog
        :editMode="edit"
        :numNew="0"
        :title="$tc('roles.groups',1)"
      />
      <v-card-text>
        <v-container fluid style="padding-top: 0 !important">
          <ValidationObserver ref="form_subscribersGroup" v-slot="{ }">
            <v-form
              data-vv-scope="SubscribersGroup"
              @submit.prevent="save()"
            >
              <v-row no-gutters class="d-flex justify-center">
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="name"
                    :name="$t('banks.name')"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model="subscribersGroup.name"
                      name="name"
                      counter="100"
                      maxlength="100"
                      :label="$t('banks.name')"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="description"
                    :name="$t('billing.description')"
                    rules="required"
                  >
                    <v-textarea
                      v-model="subscribersGroup.description.observations"
                      name="description"
                      no-resize
                      rows="3"
                      :label="$t('billing.description')"
                      :disabled="loading"
                      :loading="loading"
                      :error-messages="errors"
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn v-if="!fullscreen" text cancel @click="close" :disabled="loading">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.cancel')}}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save
          :loading="loading"
          @click="save"
        >
          <template v-if="!edit">
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.save')}}</span>
            <v-icon small>fas fa-save</v-icon>
          </template>
          <template v-else>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.edit')}}</span>
            <v-icon small>fas fa-edit</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SubscribersGroupsDialog",
  data() {
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      fullscreen:false,
      currencies:[],
      countries:[],
      subscribersGroup:{
        name:'',
        category: 'subscriber_group',
        description:{},
      },
    }
  },
  computed: {},
  methods:{
    open(item){
      this.edit = item.edit;
      this.currencies = item.currencies;
      this.countries = item.countries;
      if(!this.edit) {
        this.subscribersGroup = {
          name:'',
          category:"subscriber_group",
          description:{},
        };
      } else {
        this.subscribersGroup = {
          id: item.subscribersGroup.id,
          name: item.subscribersGroup.name,
          category:item.subscribersGroup.category,
          description:item.subscribersGroup.description,
        };
      }
      this.dialog1 = true;
    },
    close(){
      this.$refs.form_subscribersGroup.reset();
      this.subscribersGroup = {
        name:'',
        category:"subscriber_group",
        description:{},
      };
      this.edit = false;
      this.dialog1 = false;
    },
    save(){
      this.$refs.form_subscribersGroup.validate()
      .then((success)=>{
        if (success) {
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
        }
      });
    },
    create(){
      axios.post(this.$store.getters.catalogList(), this.subscribersGroup)
      .then((response)=>{
        // eslint-disable-next-line no-console
        if (response.status == 200) {
          let nam = response.data.success.name ?
                      response.data.success.name :
                      response.data.catalog.name
          const text = this.$tc('roles.groups',1) + ' ' + nam;
          this.pushNoty(text, "created", 1);
          this.$emit('updateTable');
          this.close();
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loading = false;
      });
    },
    update(){
      let nameSubscribersGroup = this.subscribersGroup.name;
      axios.put(this.$store.getters.catalogList(this.subscribersGroup.id), this.subscribersGroup)
      .then((response)=>{
        if (response.status == 200) {
          const text = this.$tc('roles.groups',1)+' '+nameSubscribersGroup;
          this.pushNoty(text, "updated", 1);
          this.$emit('updateTable');
          this.close();
        }
        if (response.status == 204) {
          this.close();
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loading = false;
      });
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  watch: {},
  mounted(){},
}
</script>
