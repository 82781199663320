export default {
  name: "employee-filter",
  components: {},
  props: {
    /**
     * Class parent css
     */
    classParent: {
      type: String,
      default: ""
    },
    /**
     * Boolean that disables all fields
     */
    disableFields: {
      type: Boolean,
      default: false
    },
    /**
     * Boolean that disables all fields
     */
    loadingFields: {
      type: Boolean,
      default: false
    },
    selectEmployeeTypes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    ListFranchises: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      dni: "",
      legal_denomination: "",
      employee_type: "",
      franchise_id:"",
      f_id:
        this.$store.getters.getFranchiseID == null
          ? 0
          : this.$store.getters.getFranchiseID
    };
  },
  computed: {},
  methods: {
    EraseFilter() {
      this.dni = "";
      this.legal_denomination = "";
      this.employee_type = "";
      this.franchise_id = "";
      this.$emit('EraseFilter');
    },
    Search() {
      const data = {
        dni: this.dni,
        legal_denomination: this.legal_denomination,
        employee_type: this.employee_type,
        franchise_id: this.franchise_id,
      }
      this.$emit('Search', data);
    }
  },
  watch: {},
  mounted() {}
};
