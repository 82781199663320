var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","scrollable":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"tile":""}},[_c('toolbarDialog',{attrs:{"editMode":_vm.edit,"numNew":0,"title":_vm.$tc('banks.banks',1)}}),_c('v-card-text',[_c('v-container',{staticStyle:{"padding-top":"0 !important"},attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"form_bank",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"banks-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('banks.name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":_vm.$t('banks.name'),"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.bank.name),callback:function ($$v) {_vm.$set(_vm.bank, "name", $$v)},expression:"bank.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"vid":"currency_code","name":_vm.$t('system.currency_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.currencies,"name":"currency_code","item-text":"name","item-value":"description.currency_code","disabled":_vm.loading,"error-messages":errors,"label":_vm.$t('system.currency_code')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.description.currency_code)}})],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('system.'+data.item.name) + ' (' + data.item.description.currency_code + ')')+" ")])],1)]}}],null,true),model:{value:(_vm.bank.description.currency_code),callback:function ($$v) {_vm.$set(_vm.bank.description, "currency_code", $$v)},expression:"bank.description.currency_code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"vid":"country","name":_vm.$t('country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"name":"country","items":_vm.countries,"item-text":"name","item-value":"id","disabled":_vm.loading,"error-messages":errors,"label":_vm.$t('country')},model:{value:(_vm.bank.description.country_id),callback:function ($$v) {_vm.$set(_vm.bank.description, "country_id", $$v)},expression:"bank.description.country_id"}})]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(!_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":"","disabled":_vm.loading},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.cancel')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e(),_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.save')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.edit')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }