import dialpack from './DialogPackage.vue'
import serviceIn from './ServicesIn.vue'
export default {
  components:{
    dialpack,
    servicein:serviceIn
  },
  data(){
    return {
      sortBy:[],
      sortDesc:[],
      packages: [],
      pack: {},
      perPage: 10,
      page: 1,
      totalValues: 0,
      mode: 1,
      unwatch: '',
      filter: '',
      expand:false,
      loadingTable: false
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(typeof(val) == 'boolean' && val){
        this.getPackage()
      }
    })
  },
  watch:{
    sortDesc(){
      this.getPackage()
    },
    perPage(){
      this.getPackage()
    },
    page(){
      this.getPackage()
    },
    mode (val) {
      if (val == 1) {
        this.getPackage()
      }
    }
  },
  computed:{
    headers(){
      return [
        {
          text: this.$t('code'),
          align: 'left',
          value: 'code',
        },
        {
          text: this.$t('name'),
          align: 'left',
          value: 'name',
        },
        { 
          text: this.$t('description'), 
          value: 'description',
        },
        { 
          text: this.$tc('active',0), 
          value: 'active',
        },
        { 
          text: this.$t('actions'), 
          value: 'action', 
          sortable: false ,
          align: 'center',
          width: 140
        }
      ]
    }
  },
  methods:{
    expandIt(){
      if(this.expand){
        this.expand = false
        this.filter = ""
        this.getPackage()
      }
      else{
        this.expand = true
        let obj = this
        setTimeout(function() {
          obj.$refs.search.focus()
        }, 400)
      }
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.page = 1 
            this.getPackage()
          }
        })
        .catch(error => {
            // console.log(error)
        })
    },
    changeMode(number,pack){
      this.pack = pack
      this.mode = number
    },
    getPackage(){
      this.packages = []
      this.loadingTable = true
      let param = {
        page:this.page,
        paginate:this.perPage,
      }
      if(this.filter != '' && this.filter != undefined){
        param['search'] = this.filter
      }

      if(this.sortDesc.length > 0){
        param['sortBy'] = this.sortBy[0]
        param['sortDesc'] = this.sortDesc[0]
      }
      
      axios.get(this.$store.getters.getPackage(),{
        params:param
      })
        .then(response => {
          this.packages = response.data.package.data
          this.totalValues = response.data.package.total
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    removed(pack){
      this.$store.dispatch('confirmationValue',{
        show: true,
        title: this.$t('base.delete'),
        model:this.$tc('package.package',2),
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record:pack.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getPackage(pack.id),
          method: 'delete'
        }
      })
    }
  },
  mounted(){
    this.getPackage()
  },
  beforeDestroy () {
    this.unwatch()
  }
}
