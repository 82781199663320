import SubscriberDetails from './SubscriberDetails.vue'

export default {
  components:{
    SubscriberDetails
  },
  data(){
    return {
      initialized: false,
      nodata: true,
      subscribers:[],
      totalValues: 0,
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      subscriber_details: [],
      // Edit Subscriber Info Modal
      edit_subscriber: false,
      subscriber: [],
      unwatch: '',
      policies:{
        list_type_client: false,
        list_franchise: false,
        list_zone: false,
        show_subscriber: false,
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      step: 'tab-1',
      main_filters: {
        franchise_id: this.$route.params.franchise_id,
        classification: this.$route.params.classification
      }
    };
  },
  methods:{
    async getSubscribers () {
      this.subscribers = []
      this.totalValues = 0
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'get_all': true,
          // name:this.searchBy.by_name,
          franchise_id: this.searchBy.franchise_id,
          sector_id:this.searchBy.sector_id,
          zone_id: this.searchBy.zone_id,
          type_client_id: this.searchBy.type_client_id,
          filter: this.searchBy.search,
          condition: this.step.match(/\d+/g)[0],
          classification: this.main_filters.classification,
          status: this.searchBy.contract_status,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      } else {
        this.options.sortBy[0] = 'subscriber.legal_denomination'
        ops.params.orderBy = this.options.sortBy[0]
      }

      if (this.main_filters.franchise_id) {
        ops.params.franchise_id = this.main_filters.franchise_id
      }

      axios.get(this.$store.getters.filterAllContractsDetails(), ops)
        .then(response => {
          this.nodata = false
          this.subscribers = response.data.success.data
          this.totalValues = response.data.success.total
        })
        .catch(err=>{
          this.subscribers = []
          this.totalValues = 0
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
        })
    },
    PoliciesSubscriber(){
      this.policies.list_type_client = this.getPermissionGroup({
        permission:[
          'index_typeClient'
        ],
        mode:'all'
      });
      this.policies.list_franchise = this.getPermissionGroup({
        permission:[
          'index_franchise'
        ],
        mode:'all'
      });
      this.policies.list_zone = this.getPermissionGroup({
        permission:[
          'index_zone'
        ],
        mode:'all'
      });
      this.policies.show_subscriber = this.getPermissionGroup({
        permission:[
          'show_subscriber'
        ],
        mode:'all'
      });
    },
    filterBy(item, type){
      this.options.page = 1;
      this.searchBy[type] = item;
      // eslint-disable-next-line no-console
      // console.log('searchBy',this.searchBy);
      this.getSubscribers();
    },
    eraseFilter(){
      this.searchBy = {
        by_name: '',
        sector_id : null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      };
      this.getSubscribers();
    },
    viewDetails (id) {
      this.$refs.subscriber_dialog.viewDetails(id)
    }
  },
  watch:{
    options: {
      handler () {
        this.getSubscribers()
      },
      deep: true,
    },
    step(){
      this.options = {
        page: 1,
        itemsPerPage: 10,
        sortBy:['subscriber.legal_denomination'],
        sortDesc:[false]
      };
    }
  },
  computed:{
    headers () {
      let headers = [
        {
          text: this.$t('base.code'),
          align: 'center',
          value: 'subscriber.id',
          width: 86
        },
        {
          text: this.$tc('subscribers.code',2),
          align: 'left',
          value: 'code',
          width: 110
        },
        {
          text: this.$tc('subscribers.dni',2),
          align: 'left',
          value: 'subscriber.doc',
          width: 120
        },
        {
          text: this.$t('subscribers.name'),
          align: 'left',
          value: 'subscriber.legal_denomination',
        },
        /*{
          text: this.$t('subscribers.status'),
          value: 'subscriber.is_prospect',
          sortable: false ,
          width: 120
        },*/
      ];
      if (!this.$store.getters.isFranchise && this.main_filters.classification != 'corporate') {
        headers.push(
          {
            text: this.$tc('franchise.company',2),
            value: 'franchise.full_name',
          }
        );
      }
      if (this.policies.show_subscriber) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false ,
            align: 'right',
            width: 100
          }
        )
      }
      return headers
    },
  },
  beforeCreate () {
    if (!this.$route.params.franchise_id && !this.$route.params.classification) {
      this.$router.push({name: 'filterSubscribersTotals'})
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
    this.PoliciesSubscriber()
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    //
  },
}
