var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banks,"items-per-page":_vm.perPage,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"loading":_vm.loadingTable,"sort-by":['updated_at'],"sort-desc":[true],"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":"","disableNew":_vm.loadingTable,"title":_vm.$tc('banks.banks', 2),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newItem}}),_c('CatalogFilter',{attrs:{"showSearch":"","showCurrencies":"","sendCountries":"","disableFields":_vm.loadingTable},on:{"search":_vm.searchBy,"currency_code":_vm.searchBy,"currencies":_vm.getArrays,"countries":_vm.getArrays,"eraseFilter":_vm.List}})]},proxy:true},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"edit":"","disabled":_vm.loadingTable,"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"delete":"","disabled":_vm.loadingTable,"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.delete')))])])]}}],null,true)})]],2)],1),_c('BankDialog',{ref:"bank_dialog",on:{"updateTable":_vm.List}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }