export default {
  components: {},
  data() {
    return {
      dialog: false,
      type_client: {
        id: "",
        name: "",
        description: "",
        is_active: true,
        is_franchise: false,
        parent_id: null
      },
      type_clients: [],
      perPage: 10,
      page: 1,
      totalValues: 0,
      loading: false
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {},
  methods: {
    setFields() {
      this.type_client.id = "";
      this.type_client.name = "";
      this.type_client.description = "";
      this.type_client.is_active = true;
      this.type_client.is_franchise = false;
      this.type_client.parent_id = "";
    },
    open(value) {
      this.setFields();
      this.type_client =
        value != undefined && value != ""
          ? Object.assign({}, value)
          : {
              id: "",
              name: "",
              description: "",
              is_active: true,
              is_franchise: false
            };
      this.dialog = true;
    },
    close() {
      this.$refs.form_type_client.reset();
      this.dialog = false;
      this.setFields();
    },
    saveTypeClient() {
      this.$refs.form_type_client.validate()
      .then(success => {
        if (success) {
          this.loading = true;
          if (this.type_client.id == "") {
            this.create();
          } else {
            this.update();
          }
        }
      });
    },
    create() {
      axios
        .post(this.$store.getters.getTypeClient(), this.type_client)
        .then(response => {
          if (this.type_client.parent_id) {
            this.$emit("getSubTypeClient");
          }
          this.$emit("getTypeClient");
          let translation =
            this.type_client.parent_id == null
              ? this.$t("typeClient.typeClient")
              : this.$t("typeClient.subTypeClient");
          this.pushNoty(translation, "created", 1);
          this.setFields();
          this.$refs.form_type_client.reset();
          this.dialog = false;
        })
        .catch(err => {
          // console.log(err)
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      axios
        .put(
          this.$store.getters.getTypeClient(this.type_client.id),
          this.type_client
        )
        .then(response => {
          if (this.type_client.parent_id) {
            this.$emit("getSubTypeClient");
          }
          this.$emit("getTypeClient");
          let translation =
            this.type_client.parent_id == null
              ? this.$t("typeClient.typeClient")
              : this.$t("typeClient.subTypeClient");
          this.pushNoty(translation, "updated", 1);
          this.$refs.form_type_client.reset();
          this.dialog = false;
        })
        .catch(err => {
          // Always catch errors to avoid a console error log
          // console.log(err)
        })
        .finally(() => {
          // Good  Practice, Thank you
          this.loading = false;
        });
    },
    editItem(value) {
      this.type_client = Object.assign({}, value);
      this.dialog = true;
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  mounted() {}
};
