import "./SectorFilter.scss";

export default {
  name: "sector-filter",
  components: {},
  props: {
    /**
     * Class parent css
     */
    classParent: {
      type: String,
      default: ""
    },
    /**
     * It is a Boolean and if it is true, it shows the button to delete all filters.
     */
    showBtnFilter: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, it shows the search fields: country, state, municipality and parish.
     */
    showFamily: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SECTORS field and initializes the list of Sectors.
     * It is also required that it be true showFamily
     */
    showSector: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the FRANCHISES field and initializes the list of Franchises.
     */
    showFranchise: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the ZONES field and initializes the list of zones.
     */
    showZones: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SEARCH field
     */
    showSearch: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SEARCH ENTER field
     */
    showSearchEnter: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SEARCH fields id and legal_denomination
     */
    showSearchSubscriber: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SEARCH field
     */
    showSubscriber: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the SEARCH field
     */
    showTypesClients: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the Contract Status Select
     */
    showContractStatuses: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Integer that defines the group of Contract Statuses to list/display
     */
    stepContractStatuses: {
      type: Number,
      default: 0
    },
    /**
     * It is a Boolean and if it is true, displays the Services Select
     */
    showServices: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the Packages Select
     */
    showPackages: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the Subscriber's Groups Select
     */
    showSubscribersGroups: {
      type: Boolean,
      default: false
    },
    /**
     * Boolean that disables all fields
     */
    disableFields: {
      type: Boolean,
      default: false
    },
    /**
     * Municipality id
     */
    m_id: {
      type: Number,
      default: null
    },
    /**
     * Parish id
     */
    p_id: {
      type: Number,
      default: null
    },
    /**
     * Sector id
     */
    s_id: {
      type: Number,
      default: null
    },
    /**
     * Franchise id
     */
    f_id: {
      type: Number,
      default: null
    },
    /**
     * zone id
     */
    z_id: {
      type: Number,
      default: null
    },
    /**
     * Service id
     */
    srv_id: {
      type: Number,
      default: null
    },
    /**
     * Package id
     */
    pkg_id: {
      type: Number,
      default: null
    },
    /**
     * Subscribers Groups id
     */
    subs_grp_id: {
      type: Number,
      default: null
    },
    /**
     * Change the name of the action that the button emits
     */
    emitSearch: {
      type: String,
      default: "search"
    },
    /**
     * Change the name of the action that the button emits
     */
    emitSearchEnter: {
      type: String,
      default: "searchEnter"
    },
    /**
     * Change the minimum number of characters in the search field
     */
    minVal: {
      type: Number,
      default: 2
    },
    /**
     * Change the delay time to output the search field
     */
    timeDelay: {
      type: Number,
      default: 500
    },
    /**
     * Family tree of a sector
     */
    family_tree: {
      type: Object
    }
  },
  data() {
    return {
      show: {
        countries: false,
        states: false,
        municipalities: false,
        parishes: false,
        sectors: false,
        franchises: false,
        zones: false,
        types_clients: false,
        contract_statuses: false,
        services: false,
        packages: false,
        subscribers_groups: false
      },
      load: {
        countries: false,
        states: false,
        municipalities: false,
        parishes: false,
        sectors: false,
        franchises: false,
        zones: false,
        types_clients: false,
        contract_statuses: false,
        services: false,
        packages: false,
        subscribers_groups: false
      },
      countries: [],
      country_id: 237,
      states: [],
      state_id: null,
      municipalities: [],
      municipality_id: this.m_id,
      parishes: [],
      parish_id: this.p_id,
      sectors: [],
      sector_id: this.s_id,
      franchises: [],
      franchise_id: this.f_id,
      search: "",
      searchEnter: "",
      zones: [],
      zone_id: this.z_id,
      services: [],
      service_id: this.srv_id,
      packages: [],
      package_id: this.pkg_id,
      subscribers_groups: [
        {
          id: 0,
          name: this.$tc('base.none', 1)
        }
      ],
      subscriber_group_id: this.subs_grp_id,
      isErase: false,
      timeOut: null,
      suscriber: "",
      types_clients: [],
      type_client_id: null,
      contract_statuses: [],
      contract_status: [],
      doc: "",
      legal_denomination: ""
    };
  },
  computed: {
    computedContractStatuses: {
      get() {
        if (this.stepContractStatuses == 0) {
          return this.contract_statuses;
        } else {
          let group = "";
          switch (this.stepContractStatuses) {
            case 1:
              group = "request";
              break;
            case 2:
              group = "completed";
              break;
            case 3:
              group = "inactive";
              break;
          }
          return this.contract_statuses.filter(status => {
            return status.parent_id == group;
          });
        }
      },
      set() {}
    },
    computedDisableServ: {
      get() {
        if (this.franchise_id || this.package_id) {
          return true;
        }
        return false;
      },
      set(val) {
        //
      }
    }
  },
  methods: {
    getListOf(type, id = null) {
      let type_search = type;
      let url = "";
      let getList = false;
      if (type_search) {
        if (type_search == "countries") {
          this.show = {
            countries: true,
            states: true,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.countries = true;
          this.state_id = null;
          this.municipality_id = null;
          this.parish_id = null;
          this.sector_id = null;
          getList = true;
          url = this.$store.getters.getAllCountries;
        } else if (type_search == "states" && id != null && id != undefined) {
          this.show = {
            countries: false,
            states: true,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.states = true;
          this.municipality_id = null;
          this.parish_id = null;
          this.sector_id = null;
          getList = true;
          url = this.$store.getters.getStates(id);
        } else if (
          type_search == "municipalities" &&
          id != null &&
          id != undefined
        ) {
          this.show = {
            countries: false,
            states: false,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.municipalities = true;
          this.municipality_id = null;
          this.parish_id = null;
          this.sector_id = null;
          getList = true;
          url = this.$store.getters.getMunicipalities(id);
        } else if (type_search == "parishes" && id != null && id != undefined) {
          this.show = {
            countries: false,
            states: false,
            municipalities: false,
            parishes: true,
            sectors: true
          };
          this.load.parishes = true;
          this.parish_id = null;
          this.sector_id = null;
          getList = true;
          url = this.$store.getters.getParishes(id);
        } else if (
          type_search == "sectors" &&
          this.showSector &&
          id != null &&
          id != undefined
        ) {
          this.load.sectors = true;
          this.sector_id = null;
          getList = true;
          url = this.$store.getters.getSectorsByParish(id);
        } else {
          getList = false;
        }
        // Get List
        if (getList) {
          // this.printAll();
          this.List(url, type_search);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log("Not type", type_search);
      }
    },
    List(url, type_search) {
      if (this.showFamily) {
        axios
          .get(url)
          .then(response => {
            // eslint-disable-next-line no-console
            // console.log(type_search,response.data[type_search]);
            // console.log(this[type_search]);
            this[type_search] = response.data[type_search];
            if (this.family_tree != undefined && this.family_tree != null) {
              this.country_id = Number(this.family_tree.country_id);
              this.state_id = Number(this.family_tree.state_id);
              this.municipality_id = Number(this.family_tree.municipality_id);
              this.parish_id = Number(this.family_tree.parish_id);
              this.sector_id = Number(this.family_tree.sector_id);
            }
          })
          .catch(error => {
            this[type_search] = [];
            // eslint-disable-next-line no-console
            console.log(error);
          })
          .finally(() => {
            this.show[type_search] = false;
            this.load[type_search] = false;
          });
      }
    },
    getFranchises() {
      if (this.showFranchise) {
        this.load.franchises = true;
        this.show.franchises = true;
        axios.get(this.$store.getters.getFranchises(),{
          params: {
            paginate: 0
          }
        })
          .then(response => {
            if (response.data.franchises.data) {
              this.franchises = response.data.franchises.data;
            }
          })
          .catch(error => {
            if (error.response) {
              //
            }
          })
          .finally(() => {
            this.load.franchises = false;
            this.show.franchises = false;
          });
      }
    },
    getZones() {
      if (this.showZones) {
        this.load.zones = true;
        this.show.zones = true;
        axios
          .get(this.$store.getters.getZones(), {
            params: {
              franchise_id: this.franchise_id,
              get_all: true,
              select_only: 1,
              status: 1
            }
          })
          .then(response => {
            this.zones = response.data.zones;
          })
          .catch(error => {
            if (error.response) {
              // console.log('Zone Error',error.response.data);
            }
          })
          .finally(() => {
            this.load.zones = false;
            this.show.zones = false;
          });
      }
    },
    getTypesClients() {
      if (this.showTypesClients) {
        this.load.types_clients = true;
        this.show.types_clients = true;
        axios
          .get(this.$store.getters.getTypeClient(), {
            params: {
              paginate: 100,
              page: 1,
              select_only: true,
              orderBy: "name",
              dirDesc: false,
              active_only: true
            }
          })
          .then(response => {
            this.types_clients = response.data.typeclient.data;
          })
          .catch(error => {
            this.types_clients = [];
            if (error.response) {
              // console.log('Zone Error',error.response.data);
            }
          })
          .finally(() => {
            this.load.types_clients = false;
            this.show.types_clients = false;
          });
      }
    },
    getContractStatuses() {
      if (this.showContractStatuses) {
        this.load.contract_statuses = true;
        this.show.contract_statuses = true;
        axios
          .get(this.$store.getters.getContractStatuses)
          .then(response => {
            if (response.data.statuses) {
              // eslint-disable-next-line no-console
              // console.log(response.data.statuses);
              this.contract_statuses = response.data.statuses;
            }
          })
          .catch(error => {
            if (error.response) {
              //
            }
          })
          .finally(() => {
            this.load.contract_statuses = false;
            this.show.contract_statuses = false;
          });
      }
    },
    getServices() {
      if (this.showServices) {
        this.load.services = true;
        this.show.services = true;
        axios
          .get(this.$store.getters.getService(), {
            params: {
              select_only: true
            }
          })
          .then(response => {
            if (response.data && response.data.services) {
              this.services = response.data.services;
            }
          })
          .catch(error => {
            if (error.response) {
              //
            }
          })
          .finally(() => {
            this.load.services = false;
            this.show.services = false;
          });
      }
    },
    getPackages() {
      if (this.showPackages) {
        this.load.packages = true;
        this.show.packages = true;
        axios
          .get(this.$store.getters.getPackage(), {
            params: {
              select_only: true,
              paginate: "All"
            }
          })
          .then(response => {
            if (response.data) {
              this.packages = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              //
            }
          })
          .finally(() => {
            this.load.packages = false;
            this.show.packages = false;
          });
      }
    },
    getSubscribersGroups () {
      if (this.showSubscribersGroups) {
        this.load.subscribers_groups = true
        this.show.subscribers_groups = true
        axios.get(this.$store.getters.catalogByCategory('subscriber_group'))
          .then(response => {
            if (response.data && response.data.success) {
              this.subscribers_groups = response.data.success
            }
          })
          .catch(error => {
            if (error.response) {
              //
            }
          })
          .finally(() => {
            this.load.subscribers_groups = false;
            this.show.subscribers_groups = false;
          })
      }
    },
    EraseFilter() {
      this.isErase = true;
      this.show = {
        countries: true,
        states: false,
        municipalities: true,
        parishes: true,
        sectors: true
      };
      this.state_id = null;
      this.municipality_id = null;
      this.parish_id = null;
      this.sector_id = null;
      this.franchise_id =
        this.$store.getters.getFranchiseID == null
          ? 0
          : this.$store.getters.getFranchiseID;
      this.zone_id = null;
      this.search = null;
      this.searchEnter = null;
      this.suscriber = null;
      this.service_id = null;
      this.package_id = null;
      this.subscriber_group_id = null;
      this.type_client_id = null;
      this.contract_status = [];
      this.doc = "";
      this.legal_denomination = "";
      if (this.showSearchSubscriber) {
        this.$refs.form_subscriber_doc.reset();
      }
      this.$emit("eraseFilter", "");
      setTimeout(() => {
        this.isErase = false;
      }, 100);
    },
    FamillyTree() {
      if (this.family_tree != undefined && this.family_tree != null) {
        this.state_id = this.family_tree.state_id;
        this.getListOf("municipalities", this.state_id);
        this.municipality_id = this.family_tree.municipality_id;
        this.getListOf("parishes", this.municipality_id);
        this.$emit("openModal");
      } else if (this.family_tree == undefined) {
        // eslint-disable-next-line no-console
        // console.log('Somethin Wron', this.family_tree);
      }
    },
    printAll(place) {
      // eslint-disable-next-line no-console
      console.log(place);
      // eslint-disable-next-line no-console
      console.log(
        ` state_id: ${this.state_id} \n municipality_id ${this.municipality_id} \n parish_id ${this.parish_id} \n sector_id ${this.sector_id}`
      );
    },
    searchByContractStatuses() {
      this.$emit("contract_status", this.contract_status, "contract_status");
    },
    clearSearchByContractStatuses() {
      this.contract_status = [];
      this.$emit("contract_status", this.contract_status, "contract_status");
    },
    removeContractStatuses(item) {
      const index = this.contract_status.indexOf(item.text);
      if (index >= 0) this.contract_status.splice(index, 1);
    },
    searchEnterEmit() {
      const val = this.searchEnter;
      if (
        (val != null && val != undefined && val.length > this.minVal) ||
        val == ""
      ) {
        this.$emit(this.emitSearchEnter, val, this.emitSearchEnter);
      }
    },
    searchSusbcriber(type, field) {
      const val = field;
      this.$refs.form_subscriber_doc.validate().then(success => {
        if (success) {
          if (
            (val != null && val != undefined && val.length > this.minVal) ||
            val == ""
          ) {
            this.$emit(type, val, type);
          }
        }
      });
    }
  },
  mounted() {
    this.getListOf("countries");
    this.getListOf("states", this.country_id);
    this.getFranchises();
    this.FamillyTree();
    this.getZones();
    this.getTypesClients();
    this.getContractStatuses();
    this.getServices();
    this.getPackages();
    this.getSubscribersGroups();
  },
  watch: {
    country_id(val) {
      if (!this.isErase) {
        this.$emit("country_id", val, "country_id");
      }
    },
    state_id(val) {
      if (!this.isErase) {
        this.$emit("state_id", val, "state_id");
      }
    },
    municipality_id(val) {
      if (!this.isErase) {
        this.$emit("municipality_id", val, "municipality_id");
      }
    },
    parish_id(val) {
      if (!this.isErase) {
        this.$emit("parish_id", val, "parish_id");
      }
    },
    sector_id(val) {
      if (!this.isErase) {
        this.$emit("sector_id", val, "sector_id");
      }
    },
    franchise_id(val) {
      if (!this.isErase) {
        this.$emit("franchise_id", val, "franchise_id");
      }
    },
    zone_id(val) {
      if (!this.isErase) {
        this.$emit("zone_id", val, "zone_id");
      }
    },
    // type_client_id
    type_client_id(val) {
      if (!this.isErase) {
        this.$emit("type_client_id", val, "type_client_id");
      }
    },
    service_id(val) {
      if (!this.isErase) {
        this.$emit("service_id", val, "service_id");
      }
    },
    package_id(val) {
      if (!this.isErase) {
        this.$emit("package_id", val, "package_id");
      }
    },
    subscriber_group_id(val) {
      if (!this.isErase) {
        this.$emit("subscriber_group_id", val, "subscriber_group_id");
      }
    },
    search(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (
          (val != null && val != undefined && val.length > this.minVal) ||
          val == ""
        ) {
          this.$emit(this.emitSearch, val, this.emitSearch);
        }
      }, this.timeDelay);
    },
    suscriber(val) {
      const dni = /^([\s['vVgGeEjJ']?\d{2,10})/; // /^([\s\D{1}]?\d{3,10})/
      const words = /^[a-zA-Z\s]/; //  /^(?:\w+\s?){2}/
      // eslint-disable-next-line no-console
      // console.log(val, dni.test(val), words.test(val), val.length)
      if (val != undefined) {
        if (dni.test(val)) {
          this.$emit("suscriber", val, "doc");
        } else if (words.test(val) && val.length > 2) {
          this.$emit("suscriber", val, "legal_denomination");
        } else if (val == "") {
          this.$emit("suscriber", val, "suscriber");
        }
      }
    },
    // Watch to automatically search by Contract Status (dalayed)
    contract_status(val) {
      if (!this.isErase) {
        // By now, it will only clear the filter when no item is selected
        if (val.length == 0) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.$emit("contract_status", val, "contract_status");
          }, this.timeDelay);
        }
      }
    },
    stepContractStatuses(val) {
      if (val == 2) {
        this.contract_status = ["active"];
      } else {
        this.contract_status = [];
      }
    }
  }
};
