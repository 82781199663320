import TaxBillRateMenu  from './TaxBillRateMenu.vue'

export default {
  name: 'ExchangeRateSelect',
  components: {
    TaxBillRateMenu
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false
    },
    disableField: {
      type: Boolean,
      default: false
    },
    readonly: {
      default: false,
      type: Boolean
    },
    dense: {
      default: false,
      type: Boolean
    },
    returnID: {
      type: Boolean,
      default: false
    },
    returnJSON: {
      type: Boolean,
      default: false
    },
    darkRate : {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    loadingField: {
      type: Boolean,
      default: false
    },
    rates: {
      type: Array,
      default: function() {
        return [];
      }
    },
    base: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    idExchangeRate: {
      type: [Number, String],
      default: 0,
    },
    classInput: {
      type: String,
      default: ''
    },
    labelTag: {
      type: String,
      default: 'date'
    },
    nameTag: {
      type: String,
      default: 'ExchangeRate'
    },
    errorMessages: {
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    },
    taxBillRate1: {
      type: Object,
      default: function() {
        return {
          currency_code: ''
        };
      }
    },
    dontDisable: {
      type: Boolean,
      default: false
    },
    showAppendOuterIcon: {
      type: Boolean,
      default: false
    },
    /* Numero de letras permitidas en el texto de un item seleccionado */
    numberLetters: {
      type: Number,
      default: 23
    }
  },
  data() {
    return {
      taxBillRateHint: '',
      ExchangeRates: [],
      ExchangeRate: '',
      symbol: '',
      decimal_places: 2,
      taxBillRate: {},
      showAppendOuterBtn: false
    };
  },
  computed: {
    filteredTaxBillRates() {
      return this.rates.filter((rate) => {
        return rate.currency_code === this.taxBillRate.currency_code && !rate.default
      });
    },
    hasExchangeRatePermission () {
      return this.getPermissionGroup({
        permission: ['listAll_exchangeRate'],
        mode: 'all'
      });
    }
  },
  watch:{
    idExchangeRate (val) {
      this.ExchangeRate = val
    },
    ExchangeRate: {
      handler (val) {
        if (val) {
          let found = this.rates.find(rate => rate.id === val)
          if (found) this.showHint(found)
        }
      },
      immediate: true
    },
    showAppendOuterIcon: {
      handler (val) {
        this.showAppendOuterBtn = val
      },
      immediate: true,
      deep: true
    },
    taxBillRate1: {
      handler (val) {
        this.taxBillRate = val
      },
      immediate: true,
      deep: true
    },
    taxBillRate: {
      handler (val) {
        this.assembleHint()
        this.$emit('setTaxBillRate1', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    assembleHint () {
      this.taxBillRateHint = `
        ${this.taxBillRate.name}
        (${this.taxBillRate.symbol}
        ${this.$formatAmount(this.taxBillRate.rate, this.taxBillRate.decimal_places)})
      `
    },
    showHint (selectedRate) {
      this.taxBillRateHint = ''
      if (this.taxBillRate && this.taxBillRate.currency_code) {
        if (selectedRate.currency_code !== this.taxBillRate.currency_code) {
          this.assembleHint()
        }
      } else {
        // this.ExchangeRate = 0
        this.taxBillRateHint = this.$t('billing.tax_bill_rate_missing')
      }
    },
    newTaxBillRate (item) {
      this.taxBillRate = item
    },
    inputRate(item) {
      if (!!item && item !== "") {
        let found = this.rates.find(rate =>
          rate.name !== this.base ? rate.id === item : rate.id === item
        );
        if (found) {
          this.taxBillRate = this.taxBillRate1
          if (this.showAppendOuterIcon) {
            this.showAppendOuterBtn = found.currency_code !== this.taxBillRate.currency_code
          }
          setTimeout(() => {
            this.showHint(found)
          }, 70)
        }
        // eslint-disable-next-line no-console
        // console.log('found', found)
        //
        /** Si returnID es TRUE devolvera el ID de la tasa de cambio */
        if (this.returnID && !this.returnJSON) {
          this.$emit("input", item);
        /** Si returnJSON es TRUE devolvera la tasa de cambio en formato JSON */
        } else if (!this.returnID && this.returnJSON && !!found) {
          this.$emit("input", JSON.stringify(found));
        /** Si returnJSON y returnID son FALSE devolvera la tasa de cambio en formato objeto */
        } else if (!this.returnID && !this.returnJSON && !!found) {
          this.$emit("input",found);
        }
      } else {
        this.$emit("input", '');
      }
    },
    /**
     * Funcion para emitir los cambios en la seleccion de la tasa de cambio
     * @param {item} Catalog Objeto del catalogo de Tasas de Cambio
     * @return {string | json | object} Tasa de cambio
     */
    changeRate(item) {
      // eslint-disable-next-line no-console
      // console.log('item', item, !!item, item !='', !item);
      if (!!item && item !== "") {
        let found = this.rates.find(rate =>
          rate.name !== this.base ? rate.id === item : rate.id === item
        );
        // eslint-disable-next-line no-console
        // console.log('found', found);
        //
        /** Si returnID es TRUE devolvera el ID de la tasa de cambio */
        if (this.returnID && !this.returnJSON) {
          this.$emit("change", JSON.stringify(item));
        /** Si returnJSON es TRUE devolvera la tasa de cambio en formato JSON */
        } else if (!this.returnID && this.returnJSON && !!found) {
          this.$emit("change", JSON.stringify(found));
        /** Si returnJSON y returnID son FALSE devolvera la tasa de cambio en formato objeto */
        } else if (!this.returnID && !this.returnJSON && !!found) {
          this.$emit("change",found);
        }
      } else {
        this.$emit("change", '');
      }
    },
    clearField() {
      this.ExchangeRate = "";
      // eslint-disable-next-line no-console
      // console.log("Se limpio el campo exchange rate");
    },
    /**
     * Funcion para cortar un texto
     * @param {string} name Nombre de la tasa de cambio
     * @param {number} number [number = 25] Numero de letras que se permitiran en el texto
     * @return {string} Nombre de la tasa de cambio Recortado
     */
    cutName(name) {
      return name.slice(0, this.numberLetters)
    },
    /**
     * Funcion para traducir o mostrar el nombre de la tasa de cambio seleccionada en el Select
     * @param {item} Catalog Objeto del catalogo de Tasas de Cambio
     * @return {string} Nombre de la tasa de cambio
     */
    selectedName(item){
      if (this.$te("system." + item.name)) {
        return `${this.$t("system." + item.name)} (${item.symbol})`
      } else if(item.full_name) {
        return this.cutName(item.full_name);
      } else {
        return this.cutName(item.name);
      }
    },
    /**
     * Funcion para traducir o mostrar el nombre de una tasa de cambio
     * @param {item} Catalog Objeto del catalogo de Tasas de Cambio
     * @return {string} Nombre de la tasa de cambio
     */
    itemName(item) {
      if (this.$te("system." + item.name)) {
        return `${this.$t("system." + item.name)} (${item.symbol})`
      } else if(item.full_name) {
        return item.full_name;
      } else {
        return item.name;
      }
    }
  },
  mounted() {
    if (this.idExchangeRate != 0) {
      this.ExchangeRate = this.idExchangeRate;
    }
  }
}
