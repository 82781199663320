export default {
  name: "FranchiseDialog",
  data() {
    return {
      dialog: false,
      fullscreen: false,
      loading: false,
      showP: false,
      showPwd: false,
      isCountry: false,
      loadingStateSelect: false,
      loadingFranchiseTable: false,
      loadingSelPrefix: false,
      company: {
        name: "",
        email_admin: "",
        password: "",
        identification: "",
        name_franchise: "",
        branch_office: null,
        object_franchise: "",
        country: null,
        state: null,
        name_institution_registration: "",
        register_number: "",
        volume: "",
        folio: "",
        legal_representative: "",
        fiscal_address: "",
        latitude: "",
        longitude: "",
        telephone: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        email: "",
        company_owned: false
      },
      defaultItem: {
        name: "",
        email_admin: "",
        password: "",
        identification: "",
        name_franchise: "",
        object_franchise: "",
        country: null,
        state: null,
        name_institution_registration: "",
        register_number: "",
        volume: "",
        folio: "",
        legal_representative: "",
        fiscal_address: "",
        latitude: "",
        longitude: "",
        telephone: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        email: "",
        company_owned: false
      },
      action: "",
      editedIndex: -1,
      prefixes:[],
      dni_type: 3,
      date_register: null,
      items_country: [],
      items_state: [],
      date_menu: false,
      today: new Date()
        .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
        .substr(0, 10),
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("franchise.register")
        : this.$t("franchise.update");
    },
    computedDateFormatted() {
      return this.formatDate(this.company.date_register);
    },
  },
  methods: {
    newItem(action){
      this.loadPrefixes();
      this.getCountries();
      this.action = action;
      this.editedIndex == -1;
      this.company = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item, action, index){
      this.action = action;
      this.loadPrefixes();
      this.getCountries();
      this.editedIndex = index;
      this.isCountry = true;
      this.company = Object.assign({}, item);
      this.company.country = item.country;
      this.company.state = item.state;
      this.company.name = item.admin.name;
      this.company.email_admin = item.admin.email;
      this.countrySelected();
      if (item.geo_location) {
        this.company.latitude = item.geo_location.coordinates[1];
        this.company.longitude = item.geo_location.coordinates[0];
      }
      // Prefix
      var prefix = item.identification != null && item.identification != undefined ? item.identification : '';
      // console.log('prefix', prefix);
      if (typeof(prefix.charAt(0))=='string' && prefix!='' && isNaN(Number(prefix.charAt(0)))) {
        this.company.prefix = prefix.charAt(0);
        this.company.dni = prefix.substring(1);
        this.ChangePrefix(prefix.charAt(0));
      } else {
        this.company.prefix = null;
        this.company.dni = prefix;
      }
      this.dialog = true;
    },
    close() {
      this.loading = false;
      this.dialog = false;
      this.dni_type = 3;
      setTimeout(() => {
        this.company = Object.assign({}, this.defaultItem);
        this.$refs.form_franchise.reset();
        this.editedIndex = -1;
      }, 300);
      this.action = "";
      this.isCountry = false;
      this.items_state = [];
      this.fullscreen = false;
    },
    loadPrefixes() {
      this.loadingSelPrefix = true;
      axios.get(this.$store.getters.getPrefixes)
        .then(response => {
          this.prefixes = response.data.prefixes;
        })
        .catch(err => {
          this.prefixes = [];
        })
        .finally(() => {
          this.loadingSelPrefix = false;
        })
    },
    ChangePrefix(item, reset = false){
      if (item=='V'|| item=='E') {
        this.dni_type = 0;
      } else if (item=='J'|| item=='G') {
        this.dni_type = 1;
      } else {
        this.dni_type = 3;
      }
      if (reset) {
        this.$refs.form_franchise.reset();
      }
    },
    getCountries() {
      if (this.items_country.length == 0) {
        axios
          .get(this.$store.getters.getAllCountries)
          .then(response => {
            this.items_country = response.data.countries;
          })
          .catch(err => {
            this.items_country = [];
          });
      }
    },
    countrySelected() {
      if (this.company.country) {
        this.loadingStateSelect = true;
        axios
          .get(this.$store.getters.getStates(this.company.country))
          .then(response => {
            this.loadingStateSelect = false;
            this.isCountry = true;
            this.items_state = response.data.states;
          })
          .catch(error => {
            this.loadingStateSelect = false;
            this.isCountry = false;
            this.items_state = [];
          });
      } else {
        this.isCountry = false;
      }
    },
    save() {
      this.$refs.form_franchise.validate()
      .then(success => {
        if (success) {
          const prefix = this.company.prefix;
          const dni = this.company.dni;
          this.company.identification = prefix + dni;
          if (this.editedIndex > -1) {
            this.update();
          } else {
            this.create();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "error",
            timeout: 6000,
            notifText: `${this.$t("request_field")}`
          });
        }
      });
    },
    create() {
      this.loading = true;
      let form_data = new FormData();
      for (let key in this.company) {
        if (this.company[key] != "") {
          form_data.append(key, this.company[key]);
        }
      }
      axios
        .post(this.$store.getters.getFranchises(), form_data)
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 4000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: this.$tc("franchise.company", 2),
                action: this.$tc("created", 2)
              }
            });
          }
          this.$emit('create');
          this.close();
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      let form_data = new FormData();
      for (let key in this.company) {
        if (
          key != "created_at" &&
          key != "updated_at" &&
          key != "deleted_at" &&
          this.company[key] !== null
        ) {
          form_data.append(key, this.company[key]);
        }
      }
      form_data.append("_method", "PUT");
      form_data.delete("is_active");
      form_data.delete("geo_location");
      form_data.delete("configuration");
      axios
        .post(this.$store.getters.getFranchises(this.company.id), form_data)
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 4000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: this.$tc("franchise.company", 2),
                action: this.$tc("updated", 2)
              }
            });
          }
          this.$emit('update');
          this.close();
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    }
  },
  watch: {
    "company.password": {
      handler(val) {
        if (this.showP) {
          this.$refs.verify.changePass(val);
        }
      }
    }
  }
};
