var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('subscribers.prospect',2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-clock")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('active',1))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-user-check")])],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('inactive',1))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-user-alt-slash")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-0 subscribers-table",attrs:{"headers":_vm.headers,"items":_vm.subscribers,"mobile-breakpoint":0,"hide-default-header":_vm.nodata,"footer-props":{
                      'items-per-page-options': [10, 50, 100, 500, 1000]
                    },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.$tc('contracts.contracts',2)+' '+_vm.$tc('by',2)+' '+_vm.$tc('subscribers.subscribers',1)}}),_c('sectorFilter',{attrs:{"showBtnFilter":"","showFamily":"","showSector":"","showZones":_vm.policies.list_zone,"showSearch":"","showTypesClients":_vm.policies.list_type_client,"showContractStatuses":"","stepContractStatuses":Number(_vm.step.match(/\d+/g)[0]),"showFranchise":_vm.franchise_id == 0 && _vm.policies.list_franchise,"disableFields":_vm.loadingTable,"f_id":_vm.main_filters.franchise_id},on:{"franchise_id":_vm.filterBy,"sector_id":_vm.filterBy,"zone_id":_vm.filterBy,"type_client_id":_vm.filterBy,"search":_vm.filterBy,"contract_status":_vm.filterBy,"eraseFilter":_vm.eraseFilter}})]},proxy:true},{key:"item.item.subscriber.id",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscriber.id.toString().length < 5 ? ("00000" + item.subscriber.id).slice(-5) : item.subscriber.id)+" ")]}},{key:"item.item.subscriber.is_prospect",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.subscriber.is_prospect ? _vm.$tc('subscribers.prospect',1) : _vm.$tc('subscribers.subscribers',1))+" ")])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.policies.show_subscriber)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.$i18n.locale == 'es' ? 'mr-4':'mr-4',attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.viewDetails(item.subscriber.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('subscriber-details',{ref:"subscriber_dialog",on:{"editedSubscriber":function($event){return _vm.getSubscribers(_vm.expand, _vm.prospects_only, _vm.inactive_only, _vm.permanently_retired)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }