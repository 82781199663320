import CompanyPreview from "./CompanyPreview.vue";
export default {
  name: "dialogCompany",
  components: {
    CompanyPreview
  },
  data() {
    return {
      date_menu: false,
      dialog_company: false,
      edit: false,
      fullscreen: false,
      isCountry: false,
      loading: false,
      load_countries: false,
      load_states: false,
      showP: false,
      showPwd: false,
      logo: null,
      head_logo: null,
      items_country: [],
      items_state: [],
      company: {},
      defaultItem: {
        // is_active: true,
        name: "",
        email_admin: "",
        password: "",
        name_company: "",
        identification: "",
        object_company: "",
        country: null,
        state: null,
        name_institution_registration: "",
        register_number: "",
        volume: "",
        folio: "",
        legal_representative: "",
        fiscal_address: "",
        latitude: "",
        longitude: "",
        telephone: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        firm: null,
        seal: null,
        color1: "#7D7D7D",
        color2: "#353434",
        logo: null,
        head_logo: null,
        email: ""
      },
      av_image: "",
      av_head_logo: "",
      av_firm: "",
      av_seal: "",
      editedIndex: -1,
      today: new Date()
        .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
        .substr(0, 10)
    };
  },
  computed: {
    formTitle() {
      return this.edit
        ? this.$t("company.register")
        : this.$t("company.update");
    },
    computedDateFormatted() {
      return this.formatDate(this.company.date_register);
    }
  },
  methods: {
    open(company, edit, editedIndex = -1, filter) {
      this.logo = null;
      this.head_logo = null;
      this.fullscreen = true;
      this.loading = false;
      if (!edit) {
        this.getCountries();
        this.company = { ...this.defaultItem };
        this.edit = false;
        this.editedIndex = editedIndex;
      } else {
        this.getCountries();
        this.filter = filter;
        this.company = company;
        this.edit = true;
        this.editedIndex = editedIndex;
        this.getStates();
      }
      this.dialog_company = true;
    },
    close() {
      this.dialog_company = false;
      this.loading = false;
      this.$refs.form_company.reset();
      this.editedIndex = -1;
      this.av_image = "";
      this.av_head_logo = "";
      this.av_firm = "";
      this.av_seal = "";
      this.company = {};
      this.isCountry = false;
      this.logo = null;
      this.head_logo = null;
      this.fullscreen = false;
    },
    save() {
      this.loading = true;
      this.$refs.form_company.validate().then(success => {
        if (success) {
          if (this.edit) {
            this.update();
          } else {
            this.create();
          }
        } else {
          this.loading = false;
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "error",
            timeout: 6000,
            notifText: `${this.$t("request_field")}`
          });
        }
      });
    },
    create() {
      let form_data = new FormData();
      for (let key in this.company) {
        if (key == "firm" && this.company.firm != null) {
          form_data.append("firm", this.company.firm);
        } else if (key == "seal" && this.company.seal != null) {
          form_data.append("seal", this.company.seal);
        } else if (key == "logo" && this.company.logo != null) {
          form_data.append("logo", this.company.logo);
        } else if (key == "head_logo" && this.company.head_logo != null) {
          form_data.append("head_logo", this.company.head_logo);
        } else if (
          key != "firm" &&
          key != "seal" &&
          key != "logo" &&
          key != "head_logo"
        ) {
          form_data.append(key, this.company[key]);
        }
      }
      axios
        .post(this.$store.getters.getCompanies(), form_data)
        .then(response => {
          if (response.status == 200) {
            this.$emit("cuCompany");
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: this.$tc("company.company", 2),
                action: this.$tc("created", 2)
              }
            });
          }
          this.loading = false;
          this.close();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    update() {
      let filter = this.filter;
      let compare = {
        logo: filter[0].logo,
        head_logo: filter[0].head_logo,
        seal: filter[0].seal,
        firm: filter[0].firm
      };
      let form_data = new FormData();
      for (let key in this.company) {
        if (
          key == "firm" &&
          this.company.firm != null &&
          this.company.firm != compare.firm
        ) {
          form_data.append("firm", this.company.firm);
        } else if (
          key == "seal" &&
          this.company.seal != null &&
          this.company.seal != compare.seal
        ) {
          form_data.append("seal", this.company.seal);
        } else if (
          key == "logo" &&
          this.company.logo != null &&
          this.company.logo != compare.logo
        ) {
          form_data.append("logo", this.company.logo);
        } else if (
          key == "head_logo" &&
          this.company.head_logo != null &&
          this.company.head_logo != compare.head_logo
        ) {
          form_data.append("head_logo", this.company.head_logo);
        } else if (
          key != "firm" &&
          key != "seal" &&
          key != "logo" &&
          key != "head_logo"
        ) {
          form_data.append(key, this.company[key]);
        }
      }
      form_data.append("_method", "PUT");
      axios
        .post(this.$store.getters.getCompanies(this.company.id), form_data)
        .then(response => {
          if (response.status == 200) {
            this.$emit("cuCompany");
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: this.$tc("company.company", 2),
                action: this.$tc("updated", 2)
              }
            });
          }
          this.loading = false;
          this.close();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    loadImg(e, scope) {
      if (e) {
        if (!["image/png", "image/jpeg", "image/jpg"].includes(e.type)) {
          this.dropImg(scope, "type");
        } else if (e.size > 2097152) {
          this.dropImg(scope, "size");
        } else {
          const fr = new FileReader();
          fr.readAsDataURL(e);
          fr.addEventListener("load", () => {
            this[`av_${scope == "logo" ? "image" : scope}`] = fr.result;
            this.company[scope] = e;
          });
        }
      } else {
        this.dropImg(scope, false);
      }
    },
    dropImg(scope, type) {
      let extra = "";
      let compare = this.companies.filter(comp => comp.id == this.company.id);
      // eslint-disable-next-line no-console
      console.log(compare);
      compare ? (compare = compare[0]) : null;
      switch (scope) {
        case "logo":
          this.av_image = "";
          compare && compare.logo
            ? (this.company.logo = compare.logo)
            : (this.company.logo = "");
          type ? this.$refs["company.logo"].reset() : "";
          extra = `${this.$tc("the", 1)} logo`;
          break;
        case "head_logo":
          this.av_head_logo = "";
          compare && compare.head_logo
            ? (this.company.head_logo = compare.head_logo)
            : (this.company.head_logo = "");
          type ? this.$refs["head_logo"].reset() : "";
          extra = `${this.$tc("the", 1)} logo`;
          break;
        case "firm":
          this.av_firm = "";
          compare && compare.firm
            ? (this.company.firm = compare.firm)
            : (this.company.firm = "");
          type ? this.$refs["firm"].reset() : "";
          extra = `${this.$tc("the", 0)} ${this.$t("company.firm")}`;
          break;
        case "seal":
          this.av_seal = "";
          compare && compare.seal
            ? (this.company.seal = compare.seal)
            : (this.company.seal = "");
          type ? this.$refs["seal"].reset() : "";
          extra = `${this.$tc("the", 1)} ${this.$t("company.seal")}`;
          break;
      }
      if (type) {
        let msg = "";
        type == "type"
          ? (msg = "avatar_must_valid_image")
          : (msg = "avatar_max_2048_kb");
        this.$store.dispatch("pushNotification", {
          snackbar: true,
          style: "error",
          timeout: 6000,
          notifText: "error422." + msg,
          extra: extra
        });
      }
    },
    getCountries() {
      this.load_countries = true;
      axios
        .get(this.$store.getters.getAllCountries)
        .then(response => {
          this.items_country = response.data.countries;
        })
        .catch(err => {
          let error = err.response;
          if (err.response.data) {
            error = err.response.data.error;
          }
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "error",
            timeout: 6000,
            notifText: error
          });
          this.items_country = [];
        })
        .finally(() => {
          this.load_countries = false;
        });
    },
    getStates() {
      if (this.company.country) {
        this.load_states = true;
        axios
          .get(this.$store.getters.getStates(this.company.country))
          .then(response => {
            this.isCountry = true;
            this.items_state = response.data.states;
          })
          .catch(err => {
            this.loadingStateSelect = false;
            this.isCountry = false;
            this.items_state = [];
          })
          .finally(() => {
            this.load_states = false;
          });
      } else {
        this.isCountry = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    }
  },
  watch: {
    "company.password": {
      handler(val) {
        if (this.showP) {
          this.$refs.verify.changePass(val);
        }
      }
    }
  },
  mounted() {}
};
