var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1 subscribers-table",attrs:{"headers":_vm.headers,"items":_vm.subscribers,"mobile-breakpoint":0,"hide-default-header":_vm.nodata,"footer-props":{
                'items-per-page-options': [10, 50, 100, 500, 1000]
              },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.policies.export_excel,"title":_vm.$tc('subscribers.subscribers', 2),"nameButton":_vm.$t('download') + ' Excel',"iconButton":'fas fa-file-excel',"disableNew":_vm.loadingTable},on:{"newItem":function($event){return _vm.getSubscribers(true)}}}),_c('sectorFilter',{attrs:{"showBtnFilter":"","showFamily":"","showSector":"","CshowZones":"","CshowSearchEnter":"","showSubscribersGroups":"","showTypesClients":_vm.policies.list_type_client,"showFranchise":_vm.policies.list_franchise,"showServices":_vm.policies.list_service,"showPackages":_vm.policies.list_package,"showContractStatuses":"","showSearchSubscriber":"","disableFields":_vm.loadingTable,"emitSearchEnter":'search',"f_id":_vm.searchBy.franchise_id,"srv_id":_vm.searchBy.service_id,"pkg_id":_vm.searchBy.package_id,"subs_grp_id":_vm.searchBy.subscriber_group_id},on:{"franchise_id":_vm.filterBy,"sector_id":_vm.filterBy,"type_client_id":_vm.filterBy,"search":_vm.filterBy,"service_id":_vm.filterBy,"package_id":_vm.filterBy,"subscriber_group_id":_vm.filterBy,"contract_status":_vm.filterBy,"doc":_vm.filterBy,"legal_denomination":_vm.filterBy,"eraseFilter":_vm.eraseFilter}})]},proxy:true},{key:"item.id",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.toString().length < 5 ? ("00000" + item.id).slice(-5) : item.id)+" ")]}},{key:"item.is_prospect",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.is_prospect ? _vm.$tc("subscribers.prospect", 1) : _vm.$tc("subscribers.subscribers", 1))+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.policies.show_subscriber)?_c('v-btn',_vm._g({class:_vm.$i18n.locale == 'es' ? 'mr-4' : 'mr-4',attrs:{"icon":"","disabled":_vm.loadingTable,"color":"primary"},on:{"click":function($event){return _vm.viewDetails(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.view_details")))])])]}}],null,true)})],1)],1)]],2)],1),_c('subscriber-details',{ref:"subscriber_dialog",on:{"editedSubscriber":function($event){return _vm.getSubscribers(_vm.expand, _vm.prospects_only, _vm.inactive_only, _vm.permanently_retired)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }