import ServicesTypesDialog from './ServicesTypesDialog.vue';
import CatalogFilter from '../../components/Catalogs/FilterCatalog.vue';

export default {
  name: "ServicesTypes",
  components:{
    ServicesTypesDialog,
    CatalogFilter
  },
  data() {
    return {
      loadingTable:false,
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      servicesTypes:[],
      copyList: '',
      modeNode: process.env.NODE_ENV,
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'ServiceTypes'){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('Taxes.name'),
          value: 'name',
          align: 'left'
        },
        {
          text: this.$t('description'),
          value: 'description.observations',
          sortable: false,
          align: 'left'
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
          width: 120
        }
      ];
    },
    computedDateFormatt () {
      return this.formatDate(this.today);
    },
  },
  methods:{
    List(){
      this.loadingTable = true;
      axios.get(this.$store.getters.catalogByCategory('service_type'))
      .then(response=>{
        if (response.data.success) {
          // eslint-disable-next-line no-console
          // console.log('response', response.data);
          this.servicesTypes = response.data.success;
          this.copyList = JSON.stringify(this.servicesTypes);
        }
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response);
        }
      }).finally(()=>{
        this.delayOnSearch();
      });
    },
    /**
     * Get arrays from filter
     * @param {Array} val
     * @param {string} code
     */
    getArrays(val, code){
      this[code] = val;
    },
    newItem(){
      let obj = {
        edit:false,
        serviceType:Object.assign({}, {}),
      }
      this.$refs.serviceType_dialog.open(obj);
    },
    editItem(item){
      let obj = {
        edit:true,
        serviceType:Object.assign({}, item),
      }
      this.$refs.serviceType_dialog.open(obj);
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        template: 'ServiceTypes',
        show: true,
        model: this.$t('service.service_type')+' '+item.name,
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.catalogList(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    /**
     * Function to filter banks by name or code
     * @param {string||number} val
     * @param {string} code
     */
    searchBy(val, code){
      if (val != null && val != undefined && val != "") {
        this.loadingTable = true;
        //
        let servicesTypes = JSON.parse(JSON.stringify(this.servicesTypes));
        let newListservicesTypes = [];
        if (code != 'search') {
          servicesTypes.map( (servicesTypes) => {
            if (servicesTypes.description[code] == val) {
              newListservicesTypes.push(servicesTypes);
            }
          });
        } else {
          newListservicesTypes = servicesTypes.filter(function(servicesTypes) {
            let name = servicesTypes.name.toLowerCase();
            return name.includes(val.toLowerCase());
          });
        }
        this.servicesTypes = newListservicesTypes;
        this.delayOnSearch();
      } else if (val == null || val == undefined || val == "") {
        this.servicesTypes = JSON.parse(this.copyList);
      }
    },
    delayOnSearch(){
      if (this.modeNode == 'development') {
        setTimeout(() => {
          this.loadingTable = false;
        }, 1500);
      }else{
        this.loadingTable = false;
      }
    }
  },
  mounted(){
    this.List();
  }
}
