import InspectionDialog from './InspectionDialog.vue'

export default {
  name: 'Inspection',
  components:{
    InspectionDialog
  },
  data(){
    return {
      contracts:[],
      totalValues: 0,
      items: [],
      loading: false,
      loadingTable: false,
      nodata: true,
      edit: false,
      expand: false,
      tab_val: 'tab-1' ,
      filter: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      dialog_overlay: false
    }
  },
  methods:{
    expandIt(){
      this.expand = true
      let obj = this
      setTimeout(function() {
        obj.$refs.search.focus()
      }, 400)
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.options.page = 1 
            this.getInspections(true)
          }
        })
        .catch(error => {
          //
        })
    },
    async getInspections(is_filter){
      this.loadingTable = true
      this.contracts = []
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage, 
          'page': this.options.page,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        let validation = await this.$validator.validate('filter', this.filter)
        if (validation) {
          ops.params.filter = this.filter
        } else {
          this.loadingTable = false
          this.options.page = 1
          return false
        }
      }

      ops.params.step = this.tab_val == 'tab-1' ? 1:
        this.tab_val == 'tab-2' ? 2 : 3
      axios.get(this.$store.getters.getInspections(), ops)
        .then(response => {
          if (response.data && response.data.inspections) {
            let resp = response.data.inspections
            if (resp.data.length > 0) {
              this.nodata = false
              this.contracts = resp.data
              this.totalValues = resp.total
            }
          }
        })
        .catch(err=>{
          this.nodata = true
          this.contracts = []
          this.totalValues = 0
        })
        .finally(()=>{
          this.loadingTable = false
        })
    },
    viewDetails(item){
      this.$refs.details_dialog.open(item, this.tab_val)
    },
    close(){
      this.setClear()
    },
    setClear(){
      this.loading = false
      this.$validator.reset()
    }
  },
  watch:{
    tab_val(){
      this.nodata = true
      this.expand = false
      this.options.page = 1
      this.getInspections(this.expand)
    },
    options: {
      handler () {
        this.getInspections(this.expand)
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = ''
        this.$validator.reset()
        this.options.page = 1
        this.getInspections(false)
      }
    }
  },
  computed:{
    headers(){
      let headers = [
        {
          text: this.$tc('base.code', 3),
          align: 'left',
          value: 'id',
          width: 86
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'subscriber.doc',
          width: 100
        },
        { 
          text: this.$t('subscribers.legal_denomination'), 
          value: 'subscriber.legal_denomination',
        }
      ]

      if(this.tab_val == 'tab-3'){
        headers.push({
          text: this.$t('state'),
          value: 'status',
          width: 100
        })
      }
      
      headers.push({ 
        text: this.$t('actions'), 
        value: 'action', 
        sortable: false ,
        align: 'right',
        width: 80
      })
      return headers 
    } 
  }
}
