import OverwriteBalance from "./OverwriteBalance.vue";

export default {
  name: 'wallet-movements',
  components:{
    OverwriteBalance
  },
  props:{
    //
  },
  data(){
    return {
      wallet_movements: false,
      fullscreen: false,
      loadingDialog: true,
      subscriber_id: 0,
      balance: null,
      movements: [],
      no_data: true,
      permissions: {
        overwrite_balance: false
      }
    }
  },
  computed: {
    computedBaseCurrencySymbol () {
      return this.$store.getters.getBaseCurrency ?
        Object.values(this.$store.getters.getBaseCurrency)[0].symbol : ''
    },
    computedBalance () {
      return this.balance !== null ?
        this.computedBaseCurrencySymbol + this.$formatAmount(this.balance) : ''
    },
    headers(){
      let headers = [
        {
          text: this.$t('date'),
          value: 'created_at',
          align: 'center',
          sortable: false, 
          width: 60
        },
        {
          text: this.$t('Payments.ref'),
          value: 'uuid',
          align: 'left',
          sortable: false,
          width: 265
        },
        {
          text: this.$tc('concept.name',1),
          value: 'description',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$tc('amount',1),
          value: 'amount',
          align: 'center',
          sortable: false,
          width: 60
        },
        {
          text: this.$tc('subscribers.balance',1),
          value: 'running_balance',
          align: 'center',
          sortable: false,
          width: 60
        }
      ]
      return headers
    }
  },
  methods:{
    open_dialog (subscriber_id, balance) {
      this.balance = balance
      this.wallet_movements = true
      if (subscriber_id) {
        this.subscriber_id = subscriber_id
        this.getMovements()
      } else {
        this.close_dialog()
      }
    },
    close_dialog () {
      this.wallet_movements = false
      this.subscriber_id = 0
      this.balance = null
      this.no_data = true
      this.movements = []
      this.fullscreen = false
    },
    screen(val){
      this.fullscreen = val
    },
    newItem(){
      this.$refs.overwrite_balance_dialog.open()
    },
    updatedBalance (balance) {
      this.balance = balance
      this.getMovements()
      this.$emit('refreshBalance', this.balance)
    },
    getMovements () {
      this.loadingDialog = true
      axios.get(this.$store.getters.subscriberWalletMovements(this.subscriber_id))
        .then(response => {
          this.movements = response.data
          if (this.movements.length > 0) {
            this.no_data = false
          }
        })
        .finally(()=>{
          setTimeout(() => {
            this.loadingDialog = false
          }, 400);
        })
    },
    getPolicies () {
      if (this.$store.getters.isAdmin){
        this.permissions = {
          overwrite_balance: true
        }
      } else {
        this.permissions.overwrite_balance = this.getPermissionGroup({
          permission:[
            'overwriteBalance_subscriber'
          ],
          mode:'all'
        });
      }
    }
  },
  watch: {},
  mounted () {
    this.getPolicies()
  }
}
