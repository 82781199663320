import axios from 'axios'
import router from '@/router'
import { store } from '@/store'
import { i18n } from './locale.js'
import Vue from 'vue'

window.axios = axios;

if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null){
  window.axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
}
if(localStorage.getItem('nkhokwe') != '' && localStorage.getItem('nkhokwe') != null){
  window.axios.defaults.headers['nkhokwe'] = localStorage.getItem('nkhokwe')
}

// Handle responses, looks for Fatal header from backend
const responseHandler = (response) => {
  // Global notification for 204 responses
  if (response.status == 204) {
    let emit = true
    if (response.config && response.config.params && response.config.params.is_stealth) {
      emit = false
    }
    if (emit) {
      Vue.prototype.$awn.warning(i18n.tc('DSB.profile.nothing_to_change'), {durations:{alert: 6000}})
    }
  }
  if (response.status == 206) {
    let message = Object.values(response.data.error.error)[0]
    if (message) {
      Vue.prototype.$awn.warning(i18n.tc('error422.'+message), {durations:{alert: 6000}})
      // emitNotification('warning', 6000, 'error422.'+message)
    }
  }
  // Fatal header present, force a safety logout
  if (response.headers.fatal) {
    store.dispatch('clearNotifications')
    store.dispatch('logoutToken')
    delete window.axios.defaults.headers['Authorization']
    delete window.axios.defaults.headers['nkhokwe']
    store.dispatch('diaCompany', false)
    store.dispatch('toggleDark', false)
    store.dispatch('pushNotification', {
      snackbar:true,
      style:'error',
      mode:null,
      timeout:2000,
      notifText:'safety_log_out',
      extra:null
    })
    localStorage.removeItem('id')
    localStorage.removeItem('name')
    localStorage.removeItem('token')
    localStorage.removeItem('is_anapa')
    localStorage.removeItem('anapa')
    localStorage.removeItem('anapa_woyang')
    localStorage.removeItem('wurzel')
    localStorage.removeItem('woyang')
    localStorage.removeItem('mtundu')
    localStorage.removeItem('nkhokwe')
    localStorage.removeItem('zokonda')
    localStorage.removeItem('policies')
    router.push({ name: 'home' })
    setTimeout(function() {
      location.reload()
    }, 2700);
  }
  return response
}

const errorHandler = (err) => {
  // if (response.config.method == 'post') {}
  let extra = ''
  let pos = null
  if (err.response) {
    if (err.response.status) {
      switch (err.response.status) {
        case 401: {
          Vue.prototype.$awn.alert(i18n.tc(err.response.data.error), {durations:{alert: 6000}})
          break
        }
        case 403: {
          if (err && err.response && err.response.data && err.response.data.error) {
            let error = Object.values(err.response.data.error)[0];
            Vue.prototype.$awn.alert(i18n.tc('error422.'+error[0]), {durations:{alert: 6000}})
          } else {
            Vue.prototype.$awn.alert(i18n.tc('error422.forbidden'), {durations:{alert: 6000}})
          }
          break;
        }
        case 404: {
          if (err.response.data.extra) {
            if (err.response.data.extra.pos) {
              pos = err.response.data.extra.pos
            }
            extra = i18n.tc(err.response.data.extra.msg, err.response.data.extra.pos)
          }
          // emitNotification('error', 6000, err.response.data.error, [extra], pos)
          if (err.response.data.error) {
            Vue.prototype.$awn.alert(i18n.tc(err.response.data.error, pos, [extra]), {durations:{alert: 6000}})
          }
          break
        }
        case 409: {
          Vue.prototype.$awn.alert(i18n.tc(err.response.data.error), {durations:{alert: 6000}})
          // emitNotification('error', 6000, err.response.data.error)
          break
        }
        case 422: {
          if (err.response.data.extra) {
            if (err.response.data.extra.pos) {
              pos = err.response.data.extra.pos
            }
            extra = i18n.tc(err.response.data.extra.msg, err.response.data.extra.pos)
          }
          let avatar = ''
          let error = ''
          if (err.response.data.errors && Object.values(err.response.data.errors)[0][0].match(/row \d+/)) {
            error = Object.values(err.response.data.errors)[0][0]
            let row = i18n.tc('error422.error_on_row', pos, [error.match(/\d+/)[0]])
            let msg = i18n.tc('error422.'+error.split('.')[1].trim(), pos, [])
            Vue.prototype.$awn.alert(row+': '+msg, {durations:{alert: 6000}})
            break
          } else {
            error = Object.values(err.response.data.error)[0]
          }
          if (err.response.config.url.includes('actor', 'actors')) {// Is Actors URL
            avatar = i18n.t('actor.avatar')
          }
          if (err.response.config.url.includes('subscriber', 'subscribers')) {// Is Subscribers URL
            avatar = i18n.t('subscribers.avatar')
          }
          Vue.prototype.$awn.alert(i18n.tc('error422.'+error[0], pos, [avatar, extra]), {durations:{alert: 6000}})
          break
        }
        case 423: {
          Vue.prototype.$awn.alert(i18n.tc('emailverify.not_verified'), {durations:{alert: 6000}})
          // emitNotification('error', 6000, 'emailverify.not_verified')
          break
        }
        case 429: {
          Vue.prototype.$awn.alert(i18n.tc('too_many_attempts'), {durations:{alert: 6000}})
          // emitNotification('error', 6000, 'too_many_attempts')
          break
        }
        case 500: {
          Vue.prototype.$awn.alert(i18n.tc(err.response.statusText), {durations:{alert: 6000}})
          // emitNotification('error', 6000, err.response.statusText)
          break
        }
        case 503: {
          if (!store.getters.is503) {
            store.dispatch('change503', true)
          }
          break
        }
        default: {
          Vue.prototype.$awn.alert(i18n.tc('base.something_wrong'), {durations:{alert: 6000}})
          // emitNotification('error', 6000, 'base.something_wrong')
        }
      }
    } else {//No status
      Vue.prototype.$awn.alert(i18n.tc('network_error'), {durations:{alert: 6000}})
      // emitNotification('error', 6000, 'network_error')
    }
  } else {//No response
    Vue.prototype.$awn.alert(i18n.tc(err.message), {durations:{alert: 6000}})
    // emitNotification('error', 6000, err.message)
  }
  // throw new Error(err)
  throw err
}

function emitNotification(style, timeout, notifText, extra=null, pos=null) {
  store.dispatch('pushNotification', {
    snackbar: true,
    style: style,
    mode: null,
    timeout: timeout,
    notifText: notifText,
    notifPos: pos,
    extra: extra
  })
}

export default emitNotification;

// Intercept all responses with given handler
axios.interceptors.response.use(
  response => responseHandler(response),
  err => errorHandler(err)
)
