//
import PreView from '../../components/HistoryBills/preview.vue';

const ReturnObject = (value)=>{
  if(typeof(value)=='object'){
    return value;
  } else if(typeof(value)=='string' && value != 'null'){
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "PaymentsReceived",
  components:{
    PreView: PreView,
  },
  data (){
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      is_filter: false,
      fullscreen:false,
      loadingTable:false,
      prospects_only:false,
      options:{
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      count:0,
      perPage: 10,
      totalRates:0,
      totalValues:0,
      payments:[],
      search:'',
      unwatch: '',
      symbol:'',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
    };
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('billing.description'),
          value:'description',
          align: 'center',
        },
        {
          text: this.$t('Payments.amount'),
          value:'amount',
          align: 'right',
        },
        {
          text: this.$t('Taxes.lastUpdate'),
          value: 'updated_at',
          align: 'center'
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center'
        }
      ];
    },
    computeTitle(){
      return this.$tc('Payments.name',2) +' '+ this.$tc('Payments.received',2);
    }
  },
  created(){},
  beforeDestroy() {},
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
  },
  methods:{
    List(){
      this.loadingTable = true;
      axios.get(this.$store.getters.getPayments(),
      {
        params:{
          page:this.options.page,
          paginate:this.perPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
        }
      })
      .then(response=>{
        // eslint-disable-next-line no-console
        // console.log(response.data.proof_payments.data);
        let symbol_base =Object.values(this.$store.getters.getBaseCurrency)[0].symbol;
        response.data.proof_payments.data.map(proof=>{
          let exchange_rate = ReturnObject(proof.exchange_rate);
          if (exchange_rate != null) {
            proof.symbol = exchange_rate.symbol;
          } else if (exchange_rate == null){
            proof.symbol =symbol_base;
          }
        });
        // eslint-disable-next-line no-console
        // console.log(response.data.proof_payments.data);
        this.payments = response.data.proof_payments.data;
        this.totalValues = response.data.proof_payments.total;
      })
      .catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        }
        else {
          // eslint-disable-next-line no-console
          // console.error('Error',error.message);
        }
      })
      .finally(()=>{
        this.loadingTable = false;
      });
    },
    ShowPaymen(item){
      // eslint-disable-next-line no-console
      // console.info('Pago',item);
      this.getClientDocument(item);

    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      // console.log('url',this.$store.getters.getClientDocument(item));
      axios.get(this.$store.getters.getClientDocument(item.client_document_id))
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('res:',response);
        // Le pasamos el simbolo al modal para evitar una consulta
        response.data.payment = JSON.stringify(item);
        this.$refs.pre_view.open(response.data, false);
      })
      .catch((error)=>{
        this.dialog1 = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error);
        }
      });
    },
  },
  mounted(){},
};
