var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"pa-1 pb-5",attrs:{"rounded":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.itemsChange),function(item,i){return [(item.sub && item.sub.filter(function (menu) { return _vm.$store.getters.isRoot || _vm.$store.getters.isAdmin || _vm.$store.getters.isFranchiseAdmin ?
            menu.show == true :
            menu.permission !== undefined && menu.show !== undefined ?
              _vm.$store.getters.getPoliciesGroup(menu.permission) && menu.show === true :
              menu.permission !== undefined ?
                _vm.$store.getters.getPoliciesGroup(menu.permission) :
                menu.show === true; }
        ).length > 0)?_c('v-list-group',{key:i,attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',{staticClass:"mx-1"},[(_vm.$store.getters.getMiniTooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(item.icon)}},Object.assign({}, on,
                  item.action)))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc(item.title,item.level == undefined ? 1 : item.level)))])]):_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(item.icon)}},item.action))],1),_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$tc(item.title,item.level == undefined ? 1 : item.level))}})],1)]},proxy:true}],null,true)},_vm._l((item.sub.filter(function (menu) { return _vm.$store.getters.isRoot || _vm.$store.getters.isAdmin || _vm.$store.getters.isFranchiseAdmin ?
            menu.show == true :
            menu.permission !== undefined && menu.show !== undefined ?
              _vm.$store.getters.getPoliciesGroup(menu.permission) && menu.show === true :
              menu.permission !== undefined ?
                _vm.$store.getters.getPoliciesGroup(menu.permission) :
                menu.show === true; })),function(sub,index){return _c('v-list-item',{key:("sublist-" + i + "-" + index),staticClass:"sider_menu mb-1 pl-5 pr-2",attrs:{"to":sub.route}},[_c('v-list-item-action',{staticClass:"mx-1"},[(_vm.$store.getters.getMiniTooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(sub.icon)}},Object.assign({}, on,
                  sub.action)))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc(sub.title,sub.level == undefined ? 1 : sub.level)))])]):_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(sub.icon)}},sub.action))],1),_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$tc(sub.title,sub.level == undefined ? 1 : sub.level))}})],1)],1)}),1):(!item.sub)?_c('v-list-item',{key:i,staticClass:"sider_menu mb-1 px-2",attrs:{"to":item.route}},[_c('v-list-item-action',{staticClass:"mx-1"},[(_vm.$store.getters.getMiniTooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(item.icon)}},Object.assign({}, on,
                item.action)))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc(item.title,item.level == undefined ? 1 : item.level)))])]):_c('v-icon',_vm._g({domProps:{"innerHTML":_vm._s(item.icon)}},item.action))],1),_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$tc(item.title,item.level == undefined ? 1 : item.level))}})],1)],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }