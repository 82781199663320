var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"form_broadcast",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"5"}},[_c('v-switch',{attrs:{"label":((_vm.$t('base.send')) + " " + (_vm.$tc('to',1)) + " " + (_vm.$tc('all',3))),"color":"primary","Cclass":"small-label"},model:{value:(_vm.new_notification.to_everybody),callback:function ($$v) {_vm.$set(_vm.new_notification, "to_everybody", $$v)},expression:"new_notification.to_everybody"}})],1),_c('v-col',{staticClass:"pt-1 pt-sm-0 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"franchises_ids","name":_vm.$tc('franchise.contractor', 1),"rules":{
                required: !_vm.new_notification.to_everybody && !_vm.new_notification.users_ids.length
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"multiple":"","small-chips":"","name":"franchises_ids","items":_vm.franchises_list,"item-text":"full_name","item-value":"id","search-input":_vm.searchFranchise,"label":_vm.$tc('franchise.contractor', 1),"prepend-icon":"fas fa-building","loading":_vm.loadingFranchises,"disabled":_vm.disable_select_franchises || _vm.loadingFranchises,"error-messages":errors},on:{"update:searchInput":function($event){_vm.searchFranchise=$event},"update:search-input":function($event){_vm.searchFranchise=$event},"change":_vm.clearUsersSelect},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.force_enable_select_users ? 'success' : 'primary',"disabled":!_vm.new_notification.franchises_ids.length},on:{"click":_vm.filterUsersByFranchise}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-level-down-alt")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(((_vm.$t('filter')) + ": " + (_vm.$tc('actor.actor',2)) + " " + (_vm.$tc('by',3)) + " " + (_vm.$tc('franchise.contractor',2))))+" ")])])]},proxy:true}],null,true),model:{value:(_vm.new_notification.franchises_ids),callback:function ($$v) {_vm.$set(_vm.new_notification, "franchises_ids", $$v)},expression:"new_notification.franchises_ids"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1 pt-sm-0 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"users_ids","name":_vm.$tc('actor.actor', 2),"rules":{
                required: !_vm.new_notification.to_everybody && !_vm.new_notification.franchises_ids.length || _vm.force_enable_select_users
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"multiple":"","small-chips":"","name":"users_ids","items":_vm.users_list,"item-text":"text","item-value":"value","search-input":_vm.searchUser,"label":_vm.$tc('actor.actor', 2),"prepend-icon":"fas fa-users","loading":_vm.loadingUsers,"disabled":_vm.disable_select_users && !_vm.force_enable_select_users,"error-messages":errors},on:{"update:searchInput":function($event){_vm.searchUser=$event},"update:search-input":function($event){_vm.searchUser=$event}},model:{value:(_vm.new_notification.users_ids),callback:function ($$v) {_vm.$set(_vm.new_notification, "users_ids", $$v)},expression:"new_notification.users_ids"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"text","name":_vm.$t('base.message'),"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-textarea',{attrs:{"no-resize":"","name":"text","rows":"4","prepend-icon":"fas fa-envelope-open-text","label":_vm.$t('base.message'),"error-messages":errors},model:{value:(_vm.new_notification.text),callback:function ($$v) {_vm.$set(_vm.new_notification, "text", $$v)},expression:"new_notification.text"}})]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }