<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    @click:outside="close"
  >
    <v-card>
      <toolbarDialog
        :title="`${$t('force_state')} - ${computeTitle}`"
        titleSize="title"
        :showOnlyTitle="'otro'"
        @close="close"
      />
      <v-card-text>
        <ForceStatus
          v-if="dialog"
          ref="force_status"
          :item_service="item_service"
          @loading_data="loadingForm"
          @update="update"
          @toggleButton="toggleBtn"
        />
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="!fullscreen"
          text
          cancel
          @click="close"
          :disabled="loading"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
            $t("base.cancel")
          }}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save
          :loading="loading"
          :disabled="disableBtn"
          @click="save"
        >
          <template>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
              $t("base.save")
            }}</span>
            <v-icon small>fas fa-save</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ForceStatus from "./ForceStatus.vue";
export default {
  name: "dialog_force_status",
  components: {
    ForceStatus
  },
  props: {},
  computed: {
    computeTitle() {
      return this.title == "package"
        ? this.$tc("package.package", 2)
        : this.$tc("service.service", 1);
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fullscreen: false,
      item_service: {},
      title: "",
      disableBtn: true
    };
  },
  methods: {
    open(data) {
      this.item_service = data;
      this.title = data.type;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.disableBtn = true
    },
    save() {
      this.$refs.force_status.save();
    },
    loadingForm(data) {
      this.loading = data;
    },
    update(contract) {
      this.$emit('updateStatus', contract);
      this.close();
    },
    toggleBtn (val) {
      this.disableBtn = val
    }
  }
};
</script>
