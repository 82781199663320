export default {
  name: 'dialog-subscriber',
  components:{},
  props:{
    // Edit mode
    editMode:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      dialog_subscriber: false,
      fullscreen: false,
      loadingDialog: false,
      edit: this.editMode,
      subscriber_data:{},
      modeNode:process.env.NODE_ENV,
      disable_btn: false
    }
  },
  computed:{},
  methods:{
    open_dialog(data){
      // eslint-disable-next-line no-console
      // console.log('open', data);
      this.dialog_subscriber = true;
      this.loadingDialog = false;
      if (data) {
        this.subscriber_data = Object.assign({}, data);
      }
    },
    close_dialog(){
      this.dialog_subscriber = false;
      this.$refs.subscriber_form.resetForm();
    },
    screen(val){
      this.fullscreen = val;
    },
    save(){
      this.$refs.subscriber_form.validateForm();
    },
    getLoading(val){
      this.loadingDialog = val;
    },
    updateSubscriber(data){
      this.$emit('updateSubscriber', JSON.stringify(data));
      this.close_dialog();
    },
    toggleButton(val){
      if (val) {
        this.disable_btn = val
      } else {
        setTimeout(() => {
          this.disable_btn = val
        }, 100)
      }
    }
  },
  watch: {},
  mounted(){},
}
