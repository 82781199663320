export default {
  name: "Card",
  components: {},
  props:{
    /**
    * Class parent css
    */
    classParent:{
      type:String,
      default:''
    },

    /**
    * Title description
    */
    titleCard1:{
      type:String,
      default:''
    },
    /**
    * Main Text
    */
    titleCard2:{
      type:[String, Number],
      default:''
    },
    /**
    * Text for description
    */
    descriptionCard:{
      type:String,
      default:''
    },
    /**
    * Icon Card
    */
    iconCard:{
      type:String,
      default:'far fa-circle'
    },
    /**
    * Icon Card
    */
    colorIconCard:{
      type:String,
      default:''
    },
    /**
    * Button Card
    */
    btnCard:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      //
    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
  mounted() {
    //
  },
  watch: {
    //
  }
};
