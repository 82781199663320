<template>
  <v-container fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="py-4" v-if="success || succeeded || verified || confirmed">
          <v-card-text class="text-center pt-2">
            <v-flex xs12 :class="{'pb-3': this.$store.getters.isAuth}">
              <i class="fas fa-envelope fa-6x grey--text"></i>
            </v-flex>
            <v-layout row wrap align-top justify-center mt-4 mb-2 pr-3 py-3>
              <v-flex xs12 sm2>
                <v-icon medium color="green">fas fa-check</v-icon>
              </v-flex>
              <v-flex xs12 sm10>
                <h2>
                  {{
                    verified || confirmed ?
                      $t('emailverify.already_verified_email') :
                      $t('emailverify.success')
                  }}
                </h2>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center py-3 pb-4">
            <v-btn dark
              v-if="!this.$store.getters.isAuth && !succeeded && !confirmed"
              color="green lighten-1"
              @click="logIn"
            >
              {{ $t('base.login') }}
              <v-icon small class="ml-2">fas fa-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="py-4" v-else-if="error || failed">
          <v-card-text class="text-center pt-2">
            <v-flex xs12>
              <i class="fas fa-envelope fa-6x grey--text"></i>
            </v-flex>
            <v-layout row wrap align-top justify-center mt-4 mb-2 pr-3 py-2>
              <v-flex xs12 sm2>
                <v-icon medium color="red">fas fa-times</v-icon>
              </v-flex>
              <v-flex xs12 sm10>
                <h2 v-html="$tc('emailverify.expired', pos, [$t(msg)])"></h2>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center py-3 pb-4">
            <v-btn dark
              v-if="!this.$store.getters.isAuth && !failed"
              color="red lighten-1"
              @click="logIn"
            >
              {{ $t('base.login') }}
              <v-icon small class="ml-2">fas fa-arrow-right</v-icon>
            </v-btn>
            <v-btn dark
              v-else
              :loading="loading"
              color="red lighten-1"
              @click="resendEmail"
            >
              <v-icon small class="mr-2">fas fa-undo-alt</v-icon>
              {{ $t("emailverify.resend") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script type="text/javascript">
export default {
  name: 'email-verify',
  data () {
    return {
      pos: 1,
      success: false,
      error: false,
      succeeded: false,// Will allow to offer proper actions to Clients
      failed: false,
      verified: false,
      confirmed: false,
      msg: '',
      loading: false,
      // ID's
      subject: 0,
      company: 0
    }
  },
  created() {
    this.changeMode()
  },
  watch:{
    $route (to, from){
      this.changeMode()
    }
  },
  methods: {
    logIn(){
      this.$store.dispatch('diaLogin', true)
    },
    changeMode(){
      if (this.$store.getters.isVerified) {
        this.$store.getters.isAuth ?
          this.$router.push({ name: 'dashboard' }) :
          this.$router.push({ name: 'home' })
        return false
      }
      this.subject = 0
      this.company = 0
      this.msg = 'emailverify.request_resend'
      this.success = false
      this.error = false
      this.succeeded = false
      this.failed = false
      this.verified = false
      this.confirmed = false
      switch (this.$router.currentRoute.params.pathMatch) {
        case 'success':
          this.success = true
          break
        case 'succeeded':
          this.succeeded = true
          break
        case 'verified':
          this.verified = true
          break
        case 'confirmed':
          this.confirmed = true
          break
        default://Error case
          this.checkToken()
          break
      }
      // Logged in Users can't access to Client views
      if (this.$store.getters.isAuth) {
        this.msg = 'emailverify.request_resend_logged'
        if (this.succeeded || this.failed || this.confirmed) {
          this.$router.push({ name: 'dashboard' })
        }
        if (this.error) {
          if (this.$store.getters.isVerified) {
            this.$router.push({ name: 'dashboard' })
          } else {
            axios.get(this.$store.getters.checkEmailUrl(localStorage.getItem('id')))
              .then((response) => {
                this.$store.dispatch('setVerifiedEmail', true)
                this.$router.push({ name: 'dashboard' })
              }).catch(() => {
                this.$store.dispatch('setVerifiedEmail', false)
              })
          }
        }
      }
    },
    checkToken(){
      try {
        let token = this.$router.currentRoute.params.token
        let length = token.toString().length
        let rndm1 = token.slice(0, 16)
        let rndm2 = rndm1.slice(0, -2)+'14'
        let encrypted = token.slice(16, length).toString()
        let keyIv = this.$CryptoJS.enc.Hex.parse(rndm1 + rndm1)
        let payload = this.$CryptoJS.AES.decrypt(encrypted, keyIv, {
          'mode': this.$CryptoJS.mode.CBC,
          iv: keyIv,
          padding: this.$CryptoJS.pad.ZeroPadding
        }).toString(this.$CryptoJS.enc.Utf8)

        let ids = payload.split('*$*')

        if (ids.length > 1) {// Clients
          this.subject = parseInt(ids[1])
          this.company = parseInt(ids[0])
          this.msg = 'emailverify.request_resend_logged'
          this.failed = true
          this.pos = 2
          !this.$store.getters.isVerified ? this.checkSubscriberEmail() : ''
        } else {// Users
          this.subject = parseInt(ids[0])
          this.error = true
          this.pos = 1
        }
      } catch (error) {
        this.emitNotification('error', 6000, error.toString())
        this.$router.push({ name: 'NotFound404' })
      }
    },
    checkSubscriberEmail(){
      axios.get(this.$store.getters.checkSubscriberEmailUrl(this.company, this.subject))
        .then((response) => {
          this.$store.dispatch('setVerifiedEmail', true)
          this.emitNotification('success', 4000, 'emailverify.already_verified_email')
          this.$router.push({ name: 'dashboard' })
        }).catch(() => {
          this.$store.dispatch('setVerifiedEmail', false)
        })
    },
    resendEmail(){
      let data = {}
      data.id = this.subject
      data.cmpny = this.failed ? this.company : 0
      this.loading = true
      this.$store.dispatch('resendEmailVerif', data)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.emitNotification('success', 4000, response.data.success)
            if (response.data.already) {
              this.$store.dispatch('setVerifiedEmail', true)
              this.$store.getters.isAuth ?
                this.$router.push({ name: 'dashboard' }) :
                this.$router.push({ name: 'home' })
            }
          }
        }).catch((error) => {
          //
        })
        .finally(() => {
          this.loading = false
        })
    },
    emitNotification (style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText
      });
    }
  }
}
</script>
