var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-n1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","C:dense":"!loadingTable","headers":_vm.headers,"items":_vm.all_history,"footer-props":{
        'items-per-page-options': [5, 10, 50]
      },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable,"hide-default-footer":_vm.totalValues == 0,"hide-default-header":_vm.no_data},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoice_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.invoice_date).format(_vm.$store.getters.getDateFormat[0]))+" ")]}},{key:"item.invoice_amount",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-right mr-4"},[_c('span',{staticClass:"float-left"},[_vm._v("Bs.")]),_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.invoice_amount,2))+" ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(_vm.permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","small":"","edit":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(_vm.permissions.delete)?_c('v-btn',_vm._g({attrs:{"icon":"","small":"","delete":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("fas fa-times")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.delete')))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }