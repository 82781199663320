import IdentityDialog from "./IdentityDialog.vue";
export default {
  name: "Identity",
  components: {
    IdentityDialog
  },
  data() {
    return {
      loadingTable: false,
      disabledItems: false,
      perPage: 10,
      catalogItems: [],
      copyList: "",
      modeNode: process.env.NODE_ENV,
      showNewItem: false,
      selectTypes: [
        {
          value: "subscribers_active",
          text: "identity_labels.subscribers_active"
        },
        {
          value: "requests_in_progress",
          text: "identity_labels.requests_in_progress"
        },
        {
          value: "installations_completed",
          text: "identity_labels.installations_completed"
        },
        {
          value: "installations_pending",
          text: "identity_labels.installations_pending"
        },
        {
          value: "points_available",
          text: "identity_labels.points_available"
        },
        {
          value: "points_occupied",
          text: "identity_labels.points_occupied"
        },
        {
          value: "points_remaining",
          text: "identity_labels.points_remaining"
        }
      ],
      newSelecTypes: [],
      permissions: {
        identity: {
          create: false,
          edit: false,
          delete: false
        }
      }
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val && this.$store.getters.getConfirmationTemplate == "Identity") {
          this.List();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("Taxes.name"),
          value: "name",
          align: "left"
        },
        {
          text: this.$t("concept.type"),
          value: "description.type",
          align: "center"
        }
      ];
      if (this.permissions.identity.edit || this.permissions.identity.delete) {
        headers.push(
          {
            text: this.$t("actions"),
            value: "action",
            sortable: false,
            filterable: false,
            align: "left",
            width: 120
          }
        )
      }
      return headers
    },
    computedDateFormatt() {
      return this.formatDate(this.today);
    }
  },
  watch: {},
  methods: {
    List() {
      this.loadingTable = true;
      axios
        .get(this.$store.getters.catalogByCategory('corporate_identity'))
        .then(response => {
          if (response.data.success) {
            let types = [];
            response.data.success.map(cat => {
              const value = cat.description.type;
              const found = types.filter(element => {
                return element === value;
              });
              if (found.length == 0) types.push(cat.description.type);
            });
            //
            this.newSelectType = [];
            let newSelectType = [];
            const selectTypes = [...this.selectTypes];
            if (types.length > 0) {
              selectTypes.map((type, i) => {
                const value = type.value;
                const found = types.filter(element => {
                  return element === value;
                });
                if (found.length == 0) {
                  newSelectType.push(type);
                }
              });
              if (newSelectType.length > 0) {
                this.showNewItem = true;
              } else {
                this.showNewItem = false;
              }
              this.newSelectType = [...newSelectType];
            } else {
              this.showNewItem = true;
              this.newSelectType = [...selectTypes];
            }
            this.catalogItems = response.data.success;
            this.copyList = JSON.stringify(this.catalogItems);
          }
        })
        .catch(error => {
          this.showNewItem = false;
          this.disabledItems = true;
          if (error.response) {
            // eslint-disable-next-line no-console
            console.error(error.response);
          }
        })
        .finally(() => {
          this.delayOnSearch();
        });
    },
    delayOnSearch() {
      if (this.modeNode == "development") {
        setTimeout(() => {
          this.loadingTable = false;
        }, 1500);
      } else {
        this.loadingTable = false;
      }
    },
    newItem() {
      let obj = {
        edit: false,
        catalog: Object.assign({}, {}),
        selectTypes: this.newSelectType
      };
      this.$refs.identity_dialog.open(obj);
    },
    editItem(item) {
      let obj = {
        edit: true,
        catalog: Object.assign({}, item),
        selectTypes: this.newSelectType
      };
      this.$refs.identity_dialog.open(obj);
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        template: "Identity",
        show: true,
        model: this.$t('corporate_identity') + " " + item.name,
        title: this.$t("base.delete"),
        pre: "success",
        action: this.$tc("deleted", 1),
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: item.name
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.catalogList(item.id),
          method: "delete"
        },
        pos: 1
      });
    },
    PermissionsCorporateIdentity () {
      if (this.$store.getters.isAdmin) {
        this.permissions.identity = {
          create: true,
          edit: true,
          delete: true
        }
      } else {
        this.permissions.identity.create = this.getPermissionGroup({
          permission:['storeCorporateIdentity_catalog'],
          mode:'all'
        });
        this.permissions.identity.edit = this.getPermissionGroup({
          permission:['updateCorporateIdentity_catalog'],
          mode:'all'
        });
        this.permissions.identity.delete = this.getPermissionGroup({
          permission:['destroyCorporateIdentity_catalog'],
          mode:'all'
        });
      }
    }
  },
  mounted() {
    this.PermissionsCorporateIdentity()
    this.List();
  }
};
