import Address from './Address.vue';
import Contact from './Contact.vue';
export default {
  name: 'dialog-contact-address',
  components:{
    Contact,
    Address
  },
  props:{
    /**
    * Class parent css
    */
    classParent:{
      type:String,
      default:''
    },
    /**
    * Permissions you need to render the address and / or contact
    */
    permissions:{
      type: Object,
      default: function () {
        return {
          editContact: false,
          editAddress: false,
        }
      }
    },
  },
  data(){
    return {
      dialog_ca: false,
      fullscreen: false,
      loadingDialog: false,
      saveContact: false,
      saveAddress: false,
      edit: true,
      infoAddress:{},
      modeNode:process.env.NODE_ENV,
      dataSave:{
        address: 'init',
        contact: 'init',
      },
      addressType:'',
      contactType:'',
    }
  },
  computed:{},
  methods:{
    open_dialog(data){
      const obj = Object.assign({}, data);
      // eslint-disable-next-line no-console
      // console.log('obj', obj);
      this.dialog_ca = true;
      this.saveContact = false;
      this.saveAddress = false;
      this.infoAddress = data;
      this.addressType = obj.address.type;
      this.contactType = obj.contact.type;
      this.dataSave ={
        address: 'init',
        contact: 'init',
      }
    },
    close_dialog(){
      this.dialog_ca = false;
      this.fullscreen = false;
      this.infoAddress = {};
      this.dataSave ={
        address: 'nothing',
        contact: 'nothing',
      }
      if (this.permissions.editContact) {
        this.$refs.contact_form.resetForm();
      }
      if (this.permissions.editAddress) {
        this.$refs.address_form.resetForm();
      }
    },
    screen(val){
      this.fullscreen = val;
    },
    save(){
      if (this.permissions.editContact) {
        if (!this.permissions.editAddress) {
          this.dataSave.address = 'Not address';
        }
        this.$refs.contact_form.validateForm();
      }
      if (this.permissions.editAddress) {
        if (!this.permissions.editContact) {
          this.dataSave.contact = 'Not contact';
        }
        this.$refs.address_form.validateForm();
      }
    },
    getLoadingContact(val){
      this.saveAddress = val;
    },
    getLoadingAddress(val){
      this.saveContact = val;
    },
    getContact(contact, type){
      // eslint-disable-next-line no-console
      if (contact) {
        this.dataSave.contact = type;
      }
    },
    getAddress(address, type){
      // eslint-disable-next-line no-console
      if (address) {
        this.dataSave.address = type;
      }
    },
  },
  watch: {
    dataSave:{
      immediate:true,
      deep: true,
      handler (val) {
        const addressStatus = val.address;
        const contacStatus = val.contact;
        if (addressStatus == 'update' || contacStatus == 'update') {
          this.$emit('updateContract')
          if (addressStatus == 'update' && this.addressType == 'tech') {
            this.$emit('techAddressChanged')
          }
        }
        if (addressStatus == 'update' && contacStatus != 'init' || addressStatus != 'init' && contacStatus == 'update') {
          this.close_dialog();
        }
      }
    }
  },
  mounted(){},
}
