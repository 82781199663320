<template>
  <div :class="classParent">
    <ValidationObserver ref="contract_status" v-slot="{ handleSubmit }">
      <v-form
        class="contract-status-form"
        @submit.prevent="handleSubmit(submit)"
      >
        <v-row no-gutters class="contract_status-row-1">
          <v-col cols="12" sm="12" class="contract-status">
            <ValidationProvider
              v-slot="{ errors }"
              vid="statuses"
              :name="$t('DSB.menu.type_client')"
              rules="required"
            >
              <v-select
                v-model="ctrcServ.status"
                :items="selectedStatuses"
                name="statuses"
                prepend-icon="fas fa-list"
                :clearable="modeNode == 'development'"
                :label="$t('employees.status')"
                :error-messages="errors"
                :disabled="loadingStatuses || loading"
                :loading="loadingStatuses || loading"
                @change="statusChanged"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="12" class="contract-status">
            <ValidationProvider
              v-slot="{ errors }"
              vid="motive"
              :name="$t('concept.motive')"
              rules="required"
            >
              <v-textarea
                v-model="ctrcServ.motive"
                name="motive"
                no-resize
                rows="2"
                :label="$t('concept.motive')"
                :disabled="loading"
                :loading="loading"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="12" class="contract-status">
            <v-textarea
              v-model="ctrcServ.observations"
              name="observations"
              no-resize
              rows="2"
              :label="$t('subscribers.observations')"
              :data-vv-as="$t('subscribers.observations')"
              :disabled="loading"
              :loading="loading"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12" class="contract-status">
            <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
              <v-card-title>
                <div class="title primary--text">
                  <span class="px-1 white">
                    {{ $t('base.info') }}
                  </span>
                </div>
              </v-card-title>
              <v-card-text class="py-0">
                <v-container class="pt-1 pb-2">
                  <v-row>
                    <v-col md="12">
                      <div v-for="service in services" :key="'service-' + service.id">
                        <!-- Lista de parametros de servicio -->
                        <div class="d-flex justify-space-between">
                          <div>{{ service.name }}</div>
                          <div>{{ service.price | formatCurrency(0) }}</div>
                        </div>
                        <v-list dense class="pt-0" style="width: 100%;">
                          <v-list-item
                            dense
                            v-for="param in service.service_param"
                            :key="'param-' + param.id"
                            style="min-height: 20px !important;"
                          >
                            <v-list-item-content class="py-0">
                              <v-list-item-title
                                class="d-flex justify-space-between"
                                style="font-size: 13px;font-weight: 400;min-height: 20px !important;"
                              >
                                <div>
                                  <b>&middot;</b> {{ param.qty }}
                                  {{ param.param.measure }}
                                </div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "force_service_contract_status",
  components: {},
  props: {
    // Class parent css
    classParent: {
      type: String,
      default: ""
    },
    item_service: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    }
  },
  computed: {
    selectedStatuses() {
      return this.statuses.map(status => {
        return {
          value: status.value,
          text: this.$t(status.text)
        };
      });
    }
  },
  data() {
    return {
      loading: false,
      loadingStatuses: false,
      dialog: false,
      ctrcServ: {
        origin: "system"
      },
      statuses: [],
      modeNode: process.env.NODE_ENV,
      ctrc_id: [],
      services: []
    };
  },
  methods: {
    save() {
      this.$refs.contract_status.validate().then(success => {
        if (success) {
          this.forceStatus();
        }
      });
    },
    forceStatus() {
      this.loading = true;
      axios
        .patch(
          this.$store.getters.overrideContractServiceStatus(this.ctrc_id),
          {
            status: this.ctrcServ.status,
            motive: this.ctrcServ.motive,
            motive_details: this.ctrcServ.observations
          }
        )
        .then(response => {
          let contract = response.data.services[response.data.services.length - 1].contract
          this.$emit('update', contract);
          if (this.modeNode == "development") {
            // eslint-disable-next-line no-console
            // console.log(response.data.services);
          }
        })
        .catch(error => {
          if (this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log(error.response);
          }
        })
        .finally(() => {
          this.loading = false;
          this.emitLoading(this.loading);
        });
    },
    getStatuses() {
      this.loadingStatuses = true;
      this.emitLoading(this.loadingStatuses);
      axios
        .get(this.$store.getters.getContractServiceStatuses)
        .then(response => {
          let statuses = [];
          response.data.statuses.map(status => {
            statuses.push({
              value: status,
              text: "service.statuses." + status
            });
          });
          this.statuses = statuses;
        })
        .catch(error => {
          if (this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log(error.response);
          }
        })
        .finally(() => {
          this.loadingStatuses = false;
          this.emitLoading(this.loadingStatuses);
        });
    },
    getItemService() {
      if (this.item_service.type == "service") {
        this.ctrc_id.push(this.item_service.id);
        this.ctrcServ.status = this.item_service.status;
        this.services.push(this.item_service);
      } else if (this.item_service.type == "package") {
        const service = this.item_service.services;
        this.ctrcServ.status = this.item_service.status;
        this.services = service;
        if (service.length >= 1) {
          service.map(serv => {
            this.ctrc_id.push(serv.id);
          });
        }
      }
      if (this.modeNode == "development") {
        // eslint-disable-next-line no-console
        // console.log("Item service", this.item_service);
        // eslint-disable-next-line no-console
        // console.log("contract IDs", this.ctrc_id);
        // eslint-disable-next-line no-console
        // console.log(this.services);
      }
    },
    emitLoading(data) {
      this.$emit("loading_data", data);
    },
    statusChanged (e) {
      let toggle = e === this.item_service.status
      this.$emit('toggleButton', toggle)
    }
  },
  mounted() {
    this.getItemService();
    this.getStatuses();
  }
};
</script>
