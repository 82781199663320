export default {
  name: "OldBillingHistoryForm",
  props: {
    subscriber_id: Number,
    contract_code: {
      type:[String, Number],
      default:''
    },
    editable_history: {
      type: [Object],
      default: {}
    }
  },
  data (){
    return {
      loading: false,
      date_menu: false,
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      empty_history: {
        'subscriber_id': this.subscriber_id,
        'code': this.contract_code,
        'invoice_number': '',
        'control_number': null,
        'invoice_date': '',
        'invoice_amount': 0,
        'invoice_description': '',
      },
      new_history: {},
      editing: false,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    this.clear()
    if (Object.keys(this.editable_history).length > 0 && this.editable_history.constructor === Object) {
      this.new_history = Object.assign({}, this.editable_history)
      this.editing = true
    }
  },
  computed:{
    computedDateFormatted () {
      return this.formatDate(this.new_history.invoice_date)
    },
    computedAmount: {
      get () {
        let amount = 0
        if (this.new_history && this.new_history.invoice_amount) {
          amount = this.new_history.invoice_amount
        }
        return parseFloat(amount)
      },
      set (newVal) {
        this.new_history.invoice_amount = newVal
      }
    }
  },
  methods:{
    clear () {
      this.new_history = Object.assign({}, this.empty_history)
    },
    save () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          // this.loading = true
          this.$emit('loading', true)
          if (!this.editing) {// Store (POST)
            axios.post(this.$store.getters.getOldBillingHistory(), this.new_history)
              .then(response =>{
                if (response && response.data && response.data.success) {
                  this.formSuccess()
                }
              })
              .catch((err)=>{
                // eslint-disable-next-line no-console
                console.log(err)
              }).finally(()=>{
                // this.loading = false
                this.$emit('loading', false)
              })
          } else {// Update (PUT)
            axios.put(this.$store.getters.getOldBillingHistory(this.new_history.id), this.new_history)
              .then(response =>{
                if (response && response.data && response.data.history) {
                  this.formSuccess()
                }
              })
              .catch((err)=>{
                // eslint-disable-next-line no-console
                console.log(err)
              }).finally(()=>{
                // this.loading = false
                this.$emit('loading', false)
              })
          }
        }
      })
    },
    formSuccess () {
      this.clear()
      this.$validator.reset()
      let action = this.editing ? this.$tc('updated',1) : this.$tc('created',1)
      this.$awn.success(
        this.$tc('success', 1,
          {
            model: this.$t('billing.history'),
            action: action
          }
        )
      , {durations:{alert: 4000}})
      setTimeout(() => {
        this.$emit('changeMode', 'table')
      }, 400)
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    changeMode () {
      this.$emit('changeMode', 'file_load')
    }
  },
  beforeDestroy () {
    this.$emit('setEditableHistory', {})
    this.$validator.reset()
    this.unwatch()
  }
}
