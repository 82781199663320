import BankDialog from './bankDialog.vue';
import CatalogFilter from '../../components/Catalogs/FilterCatalog.vue';

export default {
  name: "Banks",
  components:{
    BankDialog,
    CatalogFilter
  },
  data() {
    return {
      loadingTable:false,
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      currencies:[],
      countries:[],
      banks:[],
      copyList: '',
      modeNode: process.env.NODE_ENV,
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'Banks'){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('Taxes.name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('banks.currency_code'),
          value: 'description.currency_code',
          align: 'center'
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'left',
          width: 120
        }
      ];
    },
    computedDateFormatt () {
      return this.formatDate(this.today);
    },
  },
  methods:{
    List(){
      this.loadingTable = true;
      axios.get(this.$store.getters.getQueries)
      .then(response=>{
        this.banks = response.data.catalog_elements.filter(bank =>{return bank.category == "bank";});
        this.copyList = JSON.stringify(this.banks);
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response);
        }
      }).finally(()=>{
        this.delayOnSearch();
      });
    },
    /**
     * Get arrays from filter
     * @param {Array} val
     * @param {string} code
     */
    getArrays(val, code){
      this[code] = val;
    },
    newItem(){
      let obj = {
        edit:false,
        bank:Object.assign({}, {}),
        currencies:this.currencies,
        countries: this.countries,
      }
      this.$refs.bank_dialog.open(obj);
    },
    editItem(item){
      let obj = {
        edit:true,
        bank:Object.assign({}, item),
        currencies:this.currencies,
        countries: this.countries,
      }
      this.$refs.bank_dialog.open(obj);
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        template: 'Banks',
        show: true,
        model: this.$tc('banks.banks',1)+' '+item.name,
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.catalogList(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    /**
     * Function to filter banks by name or code
     * @param {string||number} val
     * @param {string} code
     */
    searchBy(val, code){
      if (val != null && val != undefined && val != "") {
        this.loadingTable = true;
        //
        let banks = JSON.parse(JSON.stringify(this.banks));
        let newListBanks = [];
        if (code != 'search') {
          banks.map( (bank) => {
            if (bank.description[code] == val) {
              newListBanks.push(bank);
            }
          });
        } else {
          newListBanks = banks.filter(function(bank) {
            let name = bank.name.toLowerCase();
            return name.includes(val.toLowerCase());
          });
        }
        this.banks = newListBanks;
        this.delayOnSearch();
      } else if (val == null || val == undefined || val == "") {
        this.banks = JSON.parse(this.copyList);
      }
    },
    delayOnSearch(){
      if (this.modeNode == 'development') {
        setTimeout(() => {
          this.loadingTable = false;
        }, 1500);
      }else{
        this.loadingTable = false;
      }
    }
  },
  mounted(){
    this.List();
  }
}
