<template>
  <v-row>
    <v-col v-if="no_data" cols="12" class="text-center pa-0 pb-1">
      <v-progress-circular
        :size="40"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br />
      <span class="caption primary--text pl-3">
        {{ $t("base.loading") }}...
      </span>
    </v-col>
    <template v-else>
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="6"
        v-for="(item, index) in computedService"
        :key="'services-' + index"
        class="pt-0 pb-3"
      >
        <ServiceCard
          :item="item"
          :showBtnParameters="showBtnParameters"
          :showBtnActivate="computedShowBtnActivate"
          :showBtnHistorical="$router.currentRoute.name != 'request'"
          :showBtnChangeServPack="computedShowBtnChangeServPack"
          :editInstallationDate="computedEditInstallationDate"
          :showBillingStartDate="computedShowBillingStartDate"
          :editBillingStartDate="computedEditBillingStartDate"
          :viewPrice="computedViewPrice"
          @config="openJSON"
          @historical="openHistorical"
          @changeStatus="changeStatus"
          @changeServPack="openChangeServPack"
          @updateCard="getServices()"
        />
      </v-col>
      <v-col
        cols="12"
        class="text-right py-1 pl-0 pr-4"
        v-if="totalserv != 0 && computedViewPrice"
        :style="`border-top:1px solid ${$vuetify.theme.themes.light.primary}`"
      >
        <span class="body-1 font-weight-bold primary--text">
          <small class="pr-4">{{ $t("paymentNote.priceTotal") }}:</small>
          {{ totalserv | formatCurrency(0) }}
        </span>
      </v-col>
    </template>
    <!----------------------------------------------------------------------->
    <!------------------------ Service config. Modal ------------------------>
    <!----------------------------------------------------------------------->
    <jsondialog ref="jsondialog" @updateJsonConfig="updateJsonConfig" />
    <!----------------------------------------------------------------------->
    <!------------------------ Service History Modal ------------------------>
    <!----------------------------------------------------------------------->
    <ActivityDialog ref="activity_dialog" />
    <DialogForceStatus ref="dialog_force_status" @updateStatus="getServices"/>

    <!----------------------------------------------------------------------->
    <ForceChangeServPack
      ref="change_service_package"
      :contract_id="contract"
      @updateServPack="getServices"
    />
  </v-row>
</template>
<script>
import jsondialog from "../ServiceInstallation/JsonConfiguration.vue";
import ServiceCard from "./ServiceCard.vue";
import ActivityDialog from "@/components/ActivityTimeLine/Dialog.vue";
import DialogForceStatus from "../../components/ContractServices/DialogForceStatus.vue";
import ForceChangeServPack from "./ForceChangeServPack";
export default {
  components: {
    jsondialog: jsondialog,
    ServiceCard,
    ActivityDialog,
    DialogForceStatus,
    ForceChangeServPack
  },
  props: {
    contract: {
      type: Number,
      default: 0
    },
    showBtnParameters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details: [],
      services: [],
      loading: false,
      permissions: {
        view_service_price: false,
        editCDS: false,
        override_serv_pack: false,
        override_service_status: false,
        input_installation_date: false,
        input_billing_start_date: false
      }
    };
  },
  methods: {
    getServices(contract = null) {
      this.loading = true;
      axios
        .get(this.$store.getters.getContractServices(), {
          params: {
            contract: this.contract,
            paginate: "All",
            split: true
          }
        })
        .then(response => {
          this.services = response.data.package
          if (contract) {
            this.$emit('updateContract', contract)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openJSON(item) {
      this.$refs.jsondialog.open(
        item,
        "wan_config",
        "serviceConfig",
        true,
        this.computed_discernment
      );
    },
    updateJsonConfig() {
      this.getServices();
    },
    /**
     * Get Permissions
     */
    PermissionsSubscriber() {
      this.permissions.view_service_price = this.getPermissionGroup({
        permission: ["viewServicePrice_contractService"],
        mode: "all"
      });
      this.permissions.editCDS = this.getPermissionGroup({
        permission: ["editWan_contractService"],
        mode: "all"
      });
      this.permissions.override_service_status = this.getPermissionGroup({
        permission: ["overrideStatus_contractService"],
        mode: "all"
      });
      this.permissions.override_serv_pack = this.getPermissionGroup({
        permission: ["overrideService_contractService"],
        mode: "all"
      });
      this.permissions.input_installation_date = this.getPermissionGroup({
        permission: ['inputInstallationDate_serviceInstallation'],
        mode: 'all'
      });
      this.permissions.input_billing_start_date = this.getPermissionGroup({
        permission: ['inputBillingStartDate_serviceInstallation'],
        mode: 'all'
      });
    },
    changeStatus(data) {
      this.$refs.dialog_force_status.open(data);
    },
    openHistorical(data) {
      this.$refs.activity_dialog.open(data);
    },
    openChangeServPack(data) {
      this.$refs.change_service_package.open(data);
    }
  },
  computed: {
    totalserv() {
      let total = 0;
      if (this.services.length > 0) {
        total = this.services
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.price),
            0
          )
          .toFixed(2);
      }
      return total;
    },
    no_data() {
      return this.loading ? true : false;
    },
    computedViewPrice () {
      return this.permissions.view_service_price
    },
    computed_discernment() {
      if (this.$router.currentRoute.name == "filterSubscribersDetails") {
        return false;
      } else {
        return this.permissions.editCDS;
      }
    },
    computedService() {
      let services = [];
      let packages = [];
      this.services.forEach(m => {
        let pack_status = "active";
        if (m.package == undefined || m.package == null) {
          services.push({
            type: "service",
            status: m.status,
            id: m.id,
            contract_id: this.contract,
            service_id: m.service_id,
            qty: m.qty,
            name:
              m.service.parent != undefined
                ? `${m.service.parent.name} ${m.service.name}`
                : `${m.service.name}`,
            description: m.description,
            price: m.price,
            service_param: m.service_param,
            service: m.service,
            wan_config: m.wan_config,
            service_installation_id: m.service_installation_id,
            installation_date: m.installation_date,
            billing_start_date: m.billing_start_date
          });
        } else {
          if (m.status != "active" && pack_status == "active") {
            pack_status = m.status;
          }
          let packInx = packages.findIndex(pack => pack.id == m.package_id);
          if (packInx == -1) {
            packages.push({
              type: "package",
              id: m.package_id,
              status: pack_status,
              name: m.package.name,
              description: m.package.description,
              price: 0,
              service_installation_id: m.service_installation_id,
              installation_date: m.installation_date,
              billing_start_date: m.billing_start_date,
              services: [
                {
                  id: m.id,
                  contract_id: this.contract,
                  service_id: m.service_id,
                  qty: m.qty,
                  name:
                    m.service.parent != undefined
                      ? `${m.service.parent.name} ${m.service.name}`
                      : `${m.service.name}`,
                  description: m.description,
                  price: m.price,
                  service_param: m.service_param,
                  service: m.service,
                  wan_config: m.wan_config
                }
              ]
            });
          } else {
            packages[packInx].services.push({
              id: m.id,
              contract_id: this.contract,
              service_id: m.service_id,
              qty: m.qty,
              name:
                m.service.parent != undefined
                  ? `${m.service.parent.name} ${m.service.name}`
                  : `${m.service.name}`,
              description: m.description,
              price: m.price,
              service_param: m.service_param,
              service: m.service,
              wan_config: m.wan_config
            });
          }
        }
      });
      // Calculate each Package's price
      packages.map((pack, i) => {
        packages[i].price = pack.services.reduce((sum, serv) => sum + Number(serv.price), 0)
      })
      return services.concat(packages);
    },
    computedShowBtnActivate() {
      return (
        this.$router.currentRoute.name != "request" &&
        this.permissions.override_service_status
      );
    },
    computedShowBtnChangeServPack() {
      return (
        this.permissions.override_serv_pack &&
        this.$router.currentRoute.name != "request" &&
        this.$router.currentRoute.name != "AllSubscribersCDS"
      );
    },
    computedEditInstallationDate() {
      return (
        this.permissions.input_installation_date &&
        this.$router.currentRoute.name != "request" &&
        this.$router.currentRoute.name != "AllSubscribersCDS"
      )
    },
    computedShowBillingStartDate() {
      return (
        this.$router.currentRoute.name != "request" &&
        this.$router.currentRoute.name != "AllSubscribersCDS"
      )
    },
    computedEditBillingStartDate() {
      return (
        this.permissions.input_billing_start_date &&
        this.$router.currentRoute.name != "request" &&
        this.$router.currentRoute.name != "AllSubscribersCDS"
      )
    }
  },
  mounted() {
    if (this.contract != undefined) {
      this.PermissionsSubscriber();
      this.getServices();
    }
  }
};
</script>
