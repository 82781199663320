export default {
  state:{
    notification_title: '',
    notification_type: '',
    notification_content: '',
    notification_show: false
  },
  getters:{
    getNotificationModalTitle: (state) => {
      return state.notification_title
    },
    getNotificationModalType: (state) => {
      return state.notification_type
    },
    getNotificationModalContent: (state) => {
      return state.notification_content
    },
    getNotificationModalShow: (state) => {
      return state.notification_show
    }
  },
  mutations:{
    setNotificationModal(state,value){
      state.notification_title = value.title
      state.notification_type = value.type
      state.notification_content = value.text
      state.notification_show = true
    },
    closeNotificationModal(state){
      state.notification_show = false
      state.notification_title = ''
      state.notification_content = ''
      setTimeout(()=>{state.notification_type = ''},400)
    }
  },
  actions:{
    notificationModal({commit},value){
      commit('setNotificationModal',value)
    },
    closeNotificationModal({commit}){
      commit('closeNotificationModal')
    }
  }
}