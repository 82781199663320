<template>
  <v-row class="mb-10" :class="{'pt-6': fullscreen ? true : false}" justify="center"
    v-if="its_preview">

    <v-col cols="12" sm="6" md="4" class="py-0 mb-6 mb-md-0">
      <v-card outlined class="card-outlined" style="margin-bottom: 0px">
        <v-card-title>
          <div class="title primary--text">
            <span class="px-1 white">
              {{$tc('subscribers.subscribers',1)}}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="d-flex justify-center">
            <v-col class="d-flex flex-column">
              <span> {{$t('paymentNote.count')}}: {{subscriber.id}} </span>
              <span>{{ subscriber.person_type == 'natural' ? $tc('subscribers.dni',0) : $tc('subscribers.dni',1)}}: {{subscriber.doc}} </span>
              <span>{{$t('paymentNote.subscriber')}}: {{subscriber.legal_denomination}} </span>
              <span v-show="subscriber.tax_rate!=null"> RetIVA: {{subscriber.tax_rate}} </span>
              <span v-show="subscriber.tax_sale!=null"> RetISRL: {{subscriber.tax_sale}} </span>
              <span>{{$t('paymentNote.daysCredit')}}: {{subscriber.credit_days}} </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    
    <v-col cols="12" sm="6" md="4" class="py-0 mb-6 mb-md-0">
      <v-card outlined class="card-outlined" style="margin-bottom: 0px">
        <v-card-title>
          <div class="title primary--text">
            <span class="px-1 white">
              {{$t('billing.date_billind')}}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="d-flex justify-center">
            <v-col class="d-flex flex-column">
              <span> 
              {{
                $t('from_to',
                {
                  start:formatDate(Document.start_date), 
                  end: formatDate(Document.end_date)
                })
              }}
              </span>
              <span> 
                {{$t('billing.date_issue')}}: 
                <b> {{ formatDate(Document.issue_date) }}</b> 
              </span>
              <span> 
                {{$t('billing.date_due')}}: 
                <b> {{ formatDate(Document.due_date) }} </b> 
              </span>
              <span> 
                {{$t('billing.date_cutoff')}}: 
                <span class="red--text"> {{ formatDate(Document.cutoff_date) }} </span> 
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="4" class="py-0 mb-0 mb-md-0">
      <v-card outlined class="card-outlined" style="margin-bottom: 0px">
        <v-card-title>
          <div class="title primary--text">
            <span class="px-1 white">
              {{computeBillsName}}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="d-flex justify-center">
            <v-col class="d-flex flex-column">
              <!-- <span> {{$t('billing.control_number')}} </span> -->
              <span> N° {{ Document.document_number }} </span>
              <!-- <span> {{$t('billing.description')}} </span> -->
              <span> {{Document.remark}} </span>
              <!-- <span> {{$t('billing.state')}}: {{computeBillStatus}} </span> -->
              <span v-show="Document.send_date != null"> 
                <span class="text-capitalize"> {{$tc('sent',2)}}: </span> 
                <b> {{$moment(Document.send_date).format($store.getters.getDateFormat[0])}} </b> 
              </span>
              <span> {{$t('billing.amount')}}: 
                <span class="red--text"> 
                  <!-- {{ exchange_rate != null ? exchange_rate.symbol : symbol }} -->
                  {{ symbol }}
                  {{Document.amount | formatAmount(decimal_places_header) }} 
                </span> 
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Digital Billing -->
      <v-card outlined class="card-outlined mt-6" style="margin-bottom: 0px">
        <v-card-title>
          <div class="title primary--text">
            <span class="px-1 white">
              {{
                $i18n.locale === 'es'
                  ? `${$t('paymentNote.bill')} Digital`
                  : `Digital ${$t('paymentNote.bill')}`
              }}
            </span>
          </div>
        </v-card-title>
        <v-card-text class="pt-1 pb-3">
          <v-row no-gutters class="d-flex justify-center">
            <v-col class="d-flex flex-column">
              <span>
                {{ $t('paymentNote.bill') }}: {{ Document.document_number_digital || '- - - -' }}
              </span>
              <span>
                Control: {{ Document.control_number_digital || '- - - -' }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "HeaderDoc",
  components:{
  },
  props:[
    'Document', 
    'subscriber',
    'symbol',
    'decimal_places_header',
    'exchange_rate', 
    'fullscreen', 
    'its_preview',
  ],
  data (){
    return {
    }
  },
  computed: {
    computeBillsName(){
      return this.$t('document_control.types.'+ this.Document.type);
    },
  },
  methods:{
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
  },
  watch:{},
  mounted(){}
}
</script>
