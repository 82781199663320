<template>
  <v-badge
    offset-x="8"
    offset-y="6"
    color="#FF0000"
    class="notif"
    v-if="$store.getters.getTotalNotifications != '' && typeof($store.getters.getTotalNotifications) == 'number' && $store.getters.getTotalNotifications > 0"
  >
    <template v-slot:badge>
      <span :class="$store.getters.getTotalNotifications > 99 ? 'smaller':''">
        {{ $store.getters.getTotalNotifications > 99 ? '+99' : $store.getters.getTotalNotifications }}
      </span>
    </template>
    <v-icon class="no-bg" @click="createNot">fas fa-bell</v-icon>
  </v-badge>
  <v-icon v-else class="no-bg pl-05" @click="createNot">fas fa-bell</v-icon>
</template>

<style lang="scss">
  .notif {
    .v-badge__badge {
      height: 17px !important;
      min-width: 17px !important;
      font-size: 11px;
      font-weight: 900;
      padding: 2px 0;
      letter-spacing: 0.1px;
      span.smaller {
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
  .no-bg::after {
    background-color: transparent !important;
  }
  .pl-05 {
    padding-left: 2px;
  }
</style>

<script>
  export default{
    name:"ws-notification",
    methods:{
      createNot(){
        // this.$store.commit('socketMessage',{'message':'test'})
        /*this.$store.dispatch('sendNotification',{
          text: 'logged_out',
          user_id: 2
        })
        this.$store.dispatch('sendNotification',{
          text: 'test definitivo',
          policies: [1]
        })*/
        // this.$awn.info(this.$tc('base.lng_changed_to', 0, ['<b>Español</b>']), {durations:{info: 400000}})
      },
      sendByType (type, text) {
        switch (type) {
          case 'info':
            this.$awn.info(this.$root.$t(text))
            break
          case 'success':
            this.$awn.success(this.$root.$t(text))
            break
          case 'warning':
            this.$awn.warning(this.$root.$t(text))
            break
          case 'alert':
            this.$awn.alert(this.$root.$t(text))
            break
          case 'upgrade':
            this.$awn.success(this.$root.$t(text))
            break
          default:
            this.$awn.tip(this.$root.$t(text))
            break
        }
      }
    },
    mounted(){
      this.$store.subscribe((mutation) => {
        if(mutation.type=='SOCKET_ONMESSAGE'){
          const data = mutation.payload
          if(data.id != this.$store.getters.getLastMsgID && data.user_id==this.$store.getters.dataUser.pkUser){
            this.sendByType(data.type,data.text)
            this.$store.dispatch('getNotifications')
            this.$store.dispatch('setLastMsgID', data.id)
          }
        }
      })
    }
  }
</script>
