export default {
  components:{
    //
  },
  data() {
    return {
      is_development: process.env.NODE_ENV == 'development',
      auto_verify: false,
      expand_status: false,
      auto_prorated_month: false,
      internal_external_monthly_billing: 'int',
      subscriber_wallet_enabled: false,
      expand_status2: false,
      expand_status3: false,
      expand_status4: false,// Subscriber Wallet
      settings_loaded: false,
      base_currency: {
        category: 'base_currency',
        name: '',
        symbol: '',
        icon: ''
      },
      dialog: false,
      items: [],
      baseLoading: false,
      loading: false,
      nodata: true,
      no_currency: false,
      is_currency: false,
      preset: null,
      currency_code: null,
      blockInputs: false,
      loadingCurrencies: false,
      currencies: [],
      unwatch: '',
      base_currency_notice: false,
      history_dialog: false,
    }
  },
  methods:{
    getCompanySettings(){
      axios.get(this.$store.getters.getCompanySettings)
        .then(response =>{
          if(response.status == 200){
            this.settings_loaded = true
            if (response.data.settings) {
              if (Object.prototype.hasOwnProperty.call(response.data.settings, 'email_auto_verify')) {
                this.auto_verify = response.data.settings.email_auto_verify
              }
              if (Object.prototype.hasOwnProperty.call(response.data.settings, 'auto_prorated_month')) {
                this.auto_prorated_month = response.data.settings.auto_prorated_month
              }
              if (Object.prototype.hasOwnProperty.call(response.data.settings, 'internal_external_monthly_billing')) {
                this.internal_external_monthly_billing = response.data.settings.internal_external_monthly_billing
              }
              if (Object.prototype.hasOwnProperty.call(response.data.settings, 'subscriber_wallet_enabled')) {
                this.subscriber_wallet_enabled = response.data.settings.subscriber_wallet_enabled
              }
            }
          }
        })
        .catch(err=>{
          //
        })
    },
    toggle_auto_prorated_month(){
      let action = this.auto_prorated_month ? this.$t('base.disable') : this.$t('base.enable')
      let action2 = this.auto_prorated_month ? this.$tc('disabled',2) : this.$tc('enabled',2)
      this.$store.dispatch('confirmationValue',{
        template: 'auto_prorated_month',
        show: true,
        model: this.$t('prorated_auto_month'),
        title: '',
        pre: 'success',
        action: action2,
        content: this.$t('base.confirm_delete',{
          action: '<b>'+action+'</b>',
          record: this.$t('prorated_auto_month')
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.getCompanySettings,
          method: 'put',
          data: {
            'settings': {
              'auto_prorated_month': !this.auto_prorated_month
            }
          }
        },
        pos: 2
      })
    },
    toggle_system_billing_mode(){
      let changed_mode = 'ext'
      let changed_mode_txt = '(Ext.)'
      if (this.internal_external_monthly_billing != 'int') {
        changed_mode = 'int'
        changed_mode_txt = '(Int.)'
      }
      this.$store.dispatch('confirmationValue',{
        template: 'internal_external_monthly_billing',
        show: true,
        model: this.$t('system.global_billing_mode'),
        title: '',
        pre: 'success',
        action: this.$tc('changed',1),
        content: this.$t('base.confirm_delete',{
          action: '<b>'+this.$t('base.change')+'</b> ' + changed_mode_txt,
          record: this.$t('system.global_billing_mode')
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.getCompanySettings,
          method: 'put',
          data: {
            'settings': {
              'internal_external_monthly_billing': changed_mode
            }
          }
        }
      })
    },
    toggle_email_auto_verify(){
      let action = this.auto_verify ? this.$t('base.disable') : this.$t('base.enable')
      let action2 = this.auto_verify ? this.$tc('disabled',2) : this.$tc('enabled',2)
      this.$store.dispatch('confirmationValue',{
        template: 'email_auto_verify',
        show: true,
        model: this.$t('email_auto_verification'),
        title: '',
        pre: 'success',
        action: action2,
        content: this.$t('base.confirm_delete',{
          action: '<b>'+action+'</b>',
          record: this.$t('email_auto_verification')
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.getCompanySettings,
          method: 'put',
          data: {
            'settings': {
              'email_auto_verify': !this.auto_verify
            }
          }
        },
        pos: 2
      })
    },
    toggle_subscriber_wallet_mode () {
      let action = this.subscriber_wallet_enabled ? this.$t('base.disable') : this.$t('base.enable')
      let action2 = this.subscriber_wallet_enabled ? this.$tc('disabled',2) : this.$tc('enabled',2)
      this.$store.dispatch('confirmationValue',{
        template: 'subscriber_wallet_enabled',
        show: true,
        model: this.computedSubscriberWalletTitle2,
        title: '',
        pre: 'success',
        action: action2,
        content: this.$t('base.confirm_delete',{
          action: '<b>'+action+'</b>',
          record: this.computedSubscriberWalletTitle2
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.getCompanySettings,
          method: 'put',
          data: {
            'settings': {
              'subscriber_wallet_enabled': !this.subscriber_wallet_enabled
            }
          }
        },
        pos: 2
      })
    },
    save(){
      this.$validator.validate('currency.*').then( valid => {
        if (valid){
          this.base_currency.description = {
            "symbol": this.base_currency.symbol,
            "icon": this.base_currency.icon,
            "currency_code": this.base_currency.currency_code,
            "decimal_places": Number(this.base_currency.decimal_places)
          }
          this.base_currency_notice = true
        }
      })
    },
    save1(){
      this.loading = true
      this.base_currency_notice = false
      let clone = Object.assign({}, this.base_currency)
      delete clone.symbol
      delete clone.icon
      delete clone.currency_code
      axios.post(this.$store.getters.catalogList(), clone)
        .then(response =>{
          if(response.status == 200){
            this.$store.dispatch('baseCurrency')
            this.emitNotification('success', 6000, 'system.currency_stablished')
            this.getBaseCurrency('base_currency')
            this.setClear()
          }
        })
        .catch(err=>{
          this.loading = false
        })
    },
    close(){
      this.setClear()
    },
    getBaseCurrency(){
      this.baseLoading = true
      this.$store.dispatch('baseCurrency')
        .then(response => {
          if (response.data.success) {
            this.baseLoading = false
            response.data.success[0].symbol = response.data.success[0].description.symbol
            response.data.success[0].icon = response.data.success[0].description.icon
            response.data.success[0].decimal_places = response.data.success[0].description.decimal_places
            this.nodata = false
            this.no_currency = false
            this.is_currency = true
            this.items = response.data.success
          }
        })
        .catch(err=>{
          this.baseLoading = false
          this.nodata = true
          this.no_currency = true
          this.is_currency = false
          this.noCurrencyAlert()
        })
    },
    getAllCurrencies(){
      this.loadingCurrencies = true
      axios.get(this.$store.getters.catalogByCategory('currency'))
        .then(response => {
          if (response.data.success) {
            this.currencies = response.data.success
          }
        })
        .finally(()=>{
          this.loadingCurrencies = false
        })
    },
    setClear(){
      this.loading = false
      this.dialog = false
      this.blockInputs = false
      this.preset = null
      this.base_currency.name = ''
      this.base_currency.symbol = ''
      this.base_currency.icon = ''
      this.base_currency.currency_code = ''
      this.$validator.reset()
    },
    fillPreset(name){
      setTimeout(() => {
        this.base_currency.name = name
        this.base_currency.symbol = this.preset.symbol
        this.base_currency.icon = this.preset.icon
        this.base_currency.currency_code = this.preset.currency_code
        this.blockInputs = true
      }, 200)
    },
    noCurrencyAlert() {
      if (!this.$store.getters.isRoot) {
        this.$awn.alert(this.$tc('system.must_set_base_currency', 0, []), {durations:{alert: 6000}})
      }
    },
    emitNotification(style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      })
    }
  },
  computed:{
    computedSubscriberWalletTitle1 () {
      return this.$i18n.locale == 'es' ?
              `¿${this.$t('base.enable')} ${this.$tc('the',2)} ${this.$t('subscribers.wallet')} ${this.$t('of')} ${this.$tc('the_2',3)} ${this.$tc('subscribers.subscribers',2)}?`:
              `${this.$t('base.enable')} ${this.$tc('the',2)} ${this.$tc('subscribers.subscribers',2)} ${this.$t('subscribers.wallet')}?`
    },
    computedSubscriberWalletTitle2 () {
      return this.$i18n.locale == 'es' ?
              `${this.$t('subscribers.wallet')} ${this.$t('of')} ${this.$tc('subscribers.subscribers',2)}`:
              `${this.$tc('subscribers.subscribers',2)} ${this.$t('subscribers.wallet')}`
    },
    headers() {
      return [
        {
          text: this.$t('name'),
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('system.decimal_places'),
          align: 'center',
          sortable: false,
          value: 'decimal_places',
          width: 150
        },
        {
          text: this.$t('system.symbol'),
          align: 'center',
          sortable: false,
          value: 'symbol',
          width: 80
        },
        {
          text: this.$t('system.icon'),
          align: 'right',
          sortable: false,
          value: 'icon',
          width: 80
        }
      ]
    },
  },
  watch: {
    dialog (val) {
      if (val && this.currencies.length == 0) {
        this.getAllCurrencies()
      }
    },
    '$route': function(to, from) {
      if (to.name == 'system' && this.no_currency) {
        this.noCurrencyAlert()
      }
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        if (this.$store.getters.getConfirmationTemplate == 'email_auto_verify' ||
            this.$store.getters.getConfirmationTemplate == 'auto_prorated_month' ||
            this.$store.getters.getConfirmationTemplate == 'internal_external_monthly_billing' ||
            this.$store.getters.getConfirmationTemplate == 'subscriber_wallet_enabled'
        ) {
          this.getCompanySettings()
        }
      }
    })
  },
  mounted(){
    if (this.$store.getters.isAdmin/* && process.env.NODE_ENV == 'development'*/) {
      this.getCompanySettings()
    }
    let one = 1
    if (one == one) {
      this.getBaseCurrency()
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
