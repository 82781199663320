import SubscriberDetails from "../Subscribers/SubscriberDetails.vue";

export default {
  components: {
    SubscriberDetails
  },
  data() {
    return {
      nodata: true,
      subscribers: [],
      totalValues: 0,
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      unwatch: "",
      policies: {
        list_type_client: false,
        list_franchise: false,
        list_service: false,
        list_package: false,
        show_subscriber: false,
        export_excel: false
      },
      modeNode: process.env.NODE_ENV,
      franchise_id:
        this.$store.getters.getFranchiseID == null
          ? 0
          : this.$store.getters.getFranchiseID,
      searchBy: {
        franchise_id:
          this.$store.getters.getFranchiseID == null
            ? 0
            : this.$store.getters.getFranchiseID,
        type_client_id: 0,
        service_id: null,
        package_id: null,
        subscriber_group_id: null,
        search: "",
        contract_status: []
      }
    };
  },
  methods: {
    async getSubscribers(is_download) {
      this.loadingTable = true;
      let ops = {
        params: {
          paginate: this.options.itemsPerPage,
          page: this.options.page,
          franchise_id: this.searchBy.franchise_id,
          sector_id: this.searchBy.sector_id,
          type_client_id: this.searchBy.type_client_id,
          service_id: this.searchBy.service_id,
          package_id: this.searchBy.package_id,
          subscriber_group_id: this.searchBy.subscriber_group_id,
          filter: this.searchBy.search,
          contract_status: this.searchBy.contract_status,
          legal_denomination: this.searchBy.legal_denomination,
          doc: this.searchBy.doc
        }
      };

      if (is_download) {
        ops.params.download = true;
        ops.responseType = "blob";
      } else {
        this.subscribers = [];
        this.totalValues = 0;
      }

      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0];
      } else {
        this.options.sortDesc[0] = false;
        ops.params.dirDesc = this.options.sortDesc[0];
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0];
      } else {
        this.options.sortBy[0] = "legal_denomination";
        ops.params.orderBy = this.options.sortBy[0];
      }

      axios
        .get(this.$store.getters.getAllSubscribersCDS(), ops)
        .then(response => {
          if (is_download) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "subscriber_list.xlsx");
            document.body.appendChild(link);
            link.click();
          } else {
            if (response.data.success && response.data.success.total) {
              this.nodata = false;
            }
            this.subscribers = response.data.success.data;
            this.totalValues = response.data.success.total;
          }
        })
        .catch(err => {
          if (err.response.status != 403) {
            this.subscribers = [];
            this.totalValues = 0;
          }
        })
        .finally(() => {
          this.loadingTable = false;
          this.initial ? (this.initial = false) : "";
        });
    },
    PoliciesSubscriber() {
      this.policies.list_type_client = this.getPermissionGroup({
        permission: ["index_typeClient"],
        mode: "all"
      });
      this.policies.list_franchise = this.getPermissionGroup({
        permission: ["index_franchise"],
        mode: "all"
      });
      this.policies.list_service = this.getPermissionGroup({
        permission: ["index_service"],
        mode: "all"
      });
      this.policies.list_package = this.getPermissionGroup({
        permission: ["index_servicePackage"],
        mode: "all"
      });
      this.policies.show_subscriber = this.getPermissionGroup({
        permission: ["show_subscriber"],
        mode: "all"
      });
      this.policies.export_excel = this.getPermissionGroup({
        permission: ["exportListAll_subscriber"],
        mode: "all"
      });
    },
    filterBy(item, type) {
      this.options.page = 1;
      this.searchBy[type] = item;
      this.getSubscribers();
    },
    eraseFilter() {
      this.searchBy = {
        franchise_id:
          this.$store.getters.getFranchiseID == null
            ? 0
            : this.$store.getters.getFranchiseID,
        type_client_id: 0,
        service_id: null,
        package_id: null,
        subscriber_group_id: null,
        search: "",
        dni: "",
        legal_denomination: "",
        contract_status: []
      };
      this.getSubscribers();
    },
    viewDetails(id) {
      this.$refs.subscriber_dialog.viewDetails(id);
    }
  },
  watch: {
    options: {
      handler() {
        this.getSubscribers();
      },
      deep: true
    }
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("base.code"),
          align: "center",
          value: "id",
          width: 86
        },
        {
          text: this.$tc("subscribers.code", 2),
          align: "left",
          value: "code",
          width: 110
        },
        {
          text: this.$tc('subscribers.dni',0)+'/'+this.$tc('subscribers.dni',1),
          align: "left",
          value: "doc",
          width: 120
        },
        {
          text: this.$t('subscribers.first_name')+'/'+this.$t('subscribers.legal_denomination'),
          align: "left",
          value: "legal_denomination"
        },
        {
          text: this.$t("subscribers.status"),
          value: "is_prospect",
          width: 120
        }
      ];
      if (!this.$store.getters.isFranchise) {
        headers.push({
          text: this.$tc("franchise.company", 2),
          value: "first_contract.franchise.full_name",
          width: 170
        });
      }
      if (this.policies.show_subscriber) {
        headers.push({
          text: this.$t("action"),
          value: "action",
          sortable: false,
          align: "right",
          width: 100
        });
      }
      return headers;
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val) {
          //
        }
      }
    );
    this.PoliciesSubscriber();
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    //
  }
};
