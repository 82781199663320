var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('Payments.reconciliation'))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-cash-register")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(("" + (_vm.$tc('requests.completed',2))))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-check-double")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-0 contracts-table",attrs:{"headers":_vm.headers,"items":_vm.contracts,"mobile-breakpoint":0,"footer-props":{
                    'items-per-page-options': [10, 50, 100, 500, 1000]
                  },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"disableNew":_vm.loadingTable,"title":_vm.$tc('requests.requests',2)}})]},proxy:true},{key:"item.id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.toString().length < 5 ? ("00000" + item.id).slice(-5) : item.id)+" ")]}},{key:"item.updated_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format(_vm.$store.getters.getDateFormat[2]))+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.policies.show),expression:"policies.show"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.getClientDocument(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.status=='generate_charge_note')?_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.step=='tab-1'),expression:"step=='tab-1'"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editDocContrat(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])])]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('PreView',{ref:"pre_view"}),_c('EditDoc',{ref:"edit_doc",on:{"UpdateDoc":_vm.getContracts,"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }