var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[(_vm.loading)?_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('v-progress-circular',{attrs:{"size":74,"width":4,"color":"primary","indeterminate":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('base.wait'))),_c('span',{staticStyle:{"letter-spacing":"0.1px"}},[_vm._v("…")])])])],1):_vm._e(),(_vm.success)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":"","lg4":""}},[_c('v-card',{staticClass:"pl-2 pr-3"},[_c('ValidationObserver',{ref:"form_pass_reset",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"pass-reset-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$t("base.password_reset")))])]),_c('v-card-text',{staticClass:"text-center pt-0"},[_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('base.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","prepend-icon":"fas fa-envelope","autocomplete":"off","label":_vm.$t('base.email'),"error-messages":errors},model:{value:(_vm.register.email),callback:function ($$v) {_vm.$set(_vm.register, "email", $$v)},expression:"register.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":_vm.$t('base.password'),"rules":{
                      'required':true,
                      regex:/^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{ref:"new_password",attrs:{"name":"password","prepend-icon":"fas fa-lock","counter":"","minlength":"8","maxlength":"16","label":_vm.$t('base.password'),"append-icon":_vm.show2 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show2 ? 'text' : 'password',"error-messages":errors},on:{"click:append":function($event){_vm.show2 = !_vm.show2},"focus":function($event){$event.stopPropagation();_vm.showP=true},"blur":function($event){$event.stopPropagation();_vm.showP=false}},model:{value:(_vm.register.password),callback:function ($$v) {_vm.$set(_vm.register, "password", $$v)},expression:"register.password"}})]}}],null,true)}),_c('verifypassword',{directives:[{name:"show",rawName:"v-show",value:(_vm.showP),expression:"showP"}],ref:"verify",staticClass:"text-left"}),_c('ValidationProvider',{attrs:{"vid":"password_confirmation","name":_vm.$t('base.pwd', [_vm.$t('base.confirm')]),"rules":{
                  required : true,
                  passConfirm: _vm.register.password
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password_confirmation","prepend-icon":"fas fa-lock","counter":"","minlength":"8","maxlength":"16","label":_vm.$t('base.pwd', [_vm.$t('base.confirm')]),"error-messages":errors,"append-icon":_vm.show3 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show3 ? 'text' : 'password'},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.register.password_confirmation),callback:function ($$v) {_vm.$set(_vm.register, "password_confirmation", $$v)},expression:"register.password_confirmation"}})]}}],null,true)}),_c('v-text-field',{staticClass:"d-none",attrs:{"type":"hidden"},model:{value:(_vm.register.token),callback:function ($$v) {_vm.$set(_vm.register, "token", $$v)},expression:"register.token"}})],1),_c('v-card-actions',{staticClass:"justify-center py-3 pb-5"},[_c('v-btn',{attrs:{"dark":"","loading":_vm.loading2,"color":"green lighten-1"},on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.$t("base.send"))+" ")])],1)],1)]}}],null,false,459165689)})],1)],1):_vm._e(),(_vm.error)?_c('v-flex',{attrs:{"xs12":"","sm8":"","md6":""}},[_c('v-card',{staticClass:"py-4"},[_c('v-card-text',{staticClass:"text-center pt-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('i',{staticClass:"fas fa-lock fa-6x grey--text"})]),_c('v-layout',{attrs:{"row":"","wrap":"","align-top":"","justify-center":"","mt-4":"","mb-2":"","pr-3":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":""}},[_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("fas fa-times")])],1),_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t(_vm.error_message))),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('password_reset.re_request'))+" ")])])],1)],1),_c('v-card-actions',{staticClass:"justify-center py-1 pb-4"},[_c('v-btn',{attrs:{"dark":"","color":"red lighten-1"},on:{"click":_vm.openRecovery}},[_vm._v(" "+_vm._s(_vm.$t('base.password_recovery'))+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }