var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pt-8":"","px-8":"","px-sm-5":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.getters.isFranchise),expression:"!$store.getters.isFranchise"}]},[_c('v-col',{staticClass:"mx-auto pa-0",attrs:{"cols":"12","sm":"9"}},[_c('v-data-table',{staticClass:"elevation-1 subscribers-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.totals,"hide-default-header":_vm.nodata,"footer-props":{
            'items-per-page-options': [10, 50, 100, 500, 1000]
          },"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.$tc('contracts.contracts',2)+' '+_vm.$tc('by',2)+' '+_vm.$tc('subscribers.subscribers',1)}})]},proxy:true},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('b',{staticClass:"body-0 text-uppercase",class:item.title == 'divider' ? 'grey--text' : ''},[_vm._v(" "+_vm._s(_vm.$te('table.'+item.title) ? _vm.$tc('table.'+item.title,1) : item.title == 'divider' ? _vm.$tc('franchise.company',1) : item.title)+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.title != 'divider')?_c('v-btn',_vm._g({class:_vm.$i18n.locale == 'es' ? 'mr-4':'mr-4',attrs:{"icon":"","color":"primary","disabled":!item.total},on:{"click":function($event){return _vm.goToDetailedView(item.filter)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])]}}],null,true)})],1)],1),(_vm.$store.getters.isFranchise && _vm.totals.length > 0)?_c('v-row',[_c('v-col',{staticClass:"mx-auto pa-0",attrs:{"cols":"12","sm":"9"}},[_c('v-card',{attrs:{"outlined":"","chart":""}},[_c('v-list-item',{staticClass:"primary elevation-4",attrs:{"header-chart":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"title mt-4 mb-0 text-uppercase white--text"},[_vm._v(" "+_vm._s(("" + (_vm.$tc('subscribers.subscribers',2))))+" ")]),_c('div',{staticClass:"overline mb-5 white--text ml-auto"},[_c('span',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(((_vm.$i18n.locale === 'es' ? 'Total Gral.' : 'Gen. Total') + ": " + (_vm.totals[0].total)))+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(((_vm.$tc('subscribers.prospect',2)) + ": " + (_vm.totals[0].prospect)))+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(((_vm.$tc('active',1)) + ": " + (_vm.totals[0].active))))]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(((_vm.$tc('inactive',1)) + ": " + (_vm.totals[0].inactive))))])])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"white","disabled":!_vm.totals[0].total},on:{"click":function($event){return _vm.goToDetailedView('all')}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fas fa-eye")])],1)]}}],null,false,437074570)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])],1),(_vm.totals[0].total)?_c('v-card-actions',{attrs:{"content-chart":""}},[_c('fusioncharts',{ref:"thrhdfhd",attrs:{"type":_vm.chartAdminFranchise.type,"width":_vm.chartAdminFranchise.width,"height":_vm.chartAdminFranchise.height,"dataFormat":_vm.chartAdminFranchise.dataFormat,"dataSource":_vm.dataSourceFranchise}})],1):_c('v-card-actions',{staticClass:"d-block text-center py-12"},[_c('small',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }