export default {
  data(){
    return {
      initialized: false,
      nodata: true,
      totals:[],
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      options: {
        page: 1,
        itemsPerPage: this.$store.getters.isFranchise ? 1 : -1
      },
      subscriber_details: [],
      // Edit Subscriber Info Modal
      edit_subscriber: false,
      subscriber: [],
      unwatch: '',
      policies:{
        //
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      chartAdminFranchise: {
        type: 'pie2d',
        width: '100%',
        // height: '50%',
        dataFormat: 'json',
        radioValue: 'none',
        lastActive: 'none',
      }
    };
  },
  methods:{
    async getTotals () {
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'get_all': true,
          name:this.searchBy.by_name,
          sector_id:this.searchBy.parish_id,
          franchise_id: this.searchBy.franchise_id,
          zone_id: this.searchBy.zone_id,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }

      axios.get(this.$store.getters.filterAllContractsTotals(), ops)
        .then(response => {
          this.nodata = false
          this.totals = response.data
        })
        .catch(err=>{
          this.totals = []
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
        })
    },
    /*PoliciesSubscriber(){
      this.policies.show = this.getPermissionGroup({
        permission:[
          'show_subscriber',
          'index_contractContact',
          'index_contractAddress',
          'index_contractService',
        ],
        mode:'all'
      });
      this.policies.edit = this.getPermissionGroup({
        permission:[
          'index_typeClient',
          'update_subscriber',
        ],
        mode:'all'
      });
      this.policies.payment_type = this.getPermissionGroup({
        permission:[
          'changeBilling_contract',
        ],
        mode:'all'
      });
    },*/
    filterBy(item, type){
      this.options.page = 1;
      this.searchBy[type] = item;
      // eslint-disable-next-line no-console
      // console.log('searchBy',this.searchBy);
      this.getSubscribers();
    },
    eraseFilter(){
      this.searchBy = {
        by_name: '',
        sector_id : null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      };
      this.getSubscribers();
    },
    goToDetailedView (by) {
      this.$router.push({
        name: 'filterSubscribersDetails',
        params: {
          franchise_id: typeof by === 'number' ? by : 0,
          classification: typeof by === 'string' ? by : 'massives'
        }
      })
    }
  },
  watch:{
    options: {
      handler () {
        this.getTotals()
      },
      deep: true,
    },
  },
  computed:{
    headers () {
      let headers = [
        {
          text: '',
          value: 'title',
          sortable: false ,
          align: 'left',
        },
        {
          text: this.$i18n.locale === 'es' ? 'Total Gral.' : 'Gen. Total',
          value: 'total',
          sortable: false ,
          align: 'center',
        },
        {
          text: this.$tc('subscribers.prospect',2),
          value: 'prospect',
          sortable: false ,
          align: 'center',
        },
        {
          text: this.$tc('active',1),
          value: 'active',
          sortable: false ,
          align: 'center',
        },
        {
          text: this.$tc('inactive',1),
          value: 'inactive',
          sortable: false ,
          align: 'center',
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false ,
          align: 'center',
        }
      ];
      return headers
    },
    dataSourceFranchise(){
      return {
        "chart": {
          // "caption": this.$tc('franchise.company',1) + ' ' + this.$tc('franchise.total',2) + ': ' + this.totals,
          // "plottooltext": "<b>$percentValue</b>" + ' ' + this.$tc('franchise.company',1) + this.$t('company.active'),
          "showLegend": "1",
          // "exportEnabled": "1",
          "showPercentValues": "1",
          "legendPosition": "bottom",
          "useDataPlotColorForLabels": "1",
          "enableMultiSlicing": "0",
          "theme": "fusion",
          "showlegend": "0",
          "bgColor": this.$vuetify.theme.dark === true ? this.$vuetify.theme.themes.dark.chart.bgColor : this.$vuetify.theme.themes.light.chart.bgColor,
          "paletteColors": this.$vuetify.theme.dark === false ? this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.light.chart.paletteColors : this.$store.getters.primaryColor+`,`+this.$vuetify.theme.themes.dark.chart.paletteColors
        },
        "data": [
          {
            "label": this.$tc('subscribers.prospect',2),
            "value": this.totals[0].prospect
          },
          {
            "label": this.$tc('active',1),
            "value": this.totals[0].active
          },
          {
            "label": this.$tc('inactive',1),
            "value": this.totals[0].inactive
          }
        ]
      }
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    // this.PoliciesSubscriber()
  },
}
