export default {
  data(){
    return {
      name:'',
      unwatch:'',
      showJson:[],
      dialog:false,
      title: '',
      readOnly:false,
      loading:false,
      editCDS:false,
      mode:3,
      dbvalue:'',
      modeldb:{},
      index:null,
      newVal:{
        title:'',
        value:''
      },
      clone:{},
      // Preloaded Configurations
      parent_configs: [],
      child_configs: [],
      joined_configs: [],
      filled_configs: {}
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'jsonConfig'){
        this.showJson = Object.assign([],this.clone)
        this.showJson = this.showJson.map((m, key) => {
          return {
            index: key,
            title: m.title,
            value: m.value
          }
        })
        this.modeldb[this.dbvalue] = this.showJson
        this.$emit('changeModel',{
          field:this.dbvalue,
          model:this.modeldb
        })
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  methods:{
    open(information, jsonValue, title, readOnly = false, editCDS = false){
      if (information.service) {
        if (information.service.parent && information.service.parent.configurations) {
          if (information.service.parent.configurations.length > 0) {
            this.parent_configs = information.service.parent.configurations
          }
        }
        if (information.service.configurations && information.service.configurations.length > 0) {
          this.child_configs = information.service.configurations
        }
        this.joined_configs = this.parent_configs.concat(this.child_configs)
      }
      this.title = title
      // this.mode = 3
      this.dialog = true
      if (editCDS) {
        this.readOnly = true;
        this.editCDS = true;
      } else {
        this.readOnly = readOnly;
        this.editCDS = false;
      }
      this.dbvalue = jsonValue
      this.modeldb = information
      this.showJson = Object.assign([],information[jsonValue])
      this.showJson = this.showJson.map((m, key) => {
        return {
          index: key,
          title: m.title,
          value: m.value
        }
      })
      switch (this.dbvalue){
        case 'wan_config':
        case 'lan_config':
          this.mode = 3
          this.name = information.service.parent != undefined ?
                            `${information.service.parent.name} ${information.service.name}`:
                            `${information.service.name}`
          if (this.modeldb[this.dbvalue] !== null) {
            this.filled_configs = Object.assign({}, this.modeldb[this.dbvalue][0])
          }
          break;
        case 'config':
          this.mode = 1
          this.name = information.equipment_materials.name
          break;
      }
    },
    close(){
      this.dialog = false
      this.parent_configs = []
      this.child_configs = []
      this.joined_configs = []
      this.filled_configs = {}
    },
    remove(index,value){
      this.clone = this.showJson.filter(m => m.index !== index)
      let clonedb = Object.assign({},this.modeldb)
      clonedb[this.dbvalue] = this.clone

      let url = ""
      switch (this.dbvalue){
        case 'wan_config':
        case 'lan_config':
          url = this.$store.getters.getContractServices(this.modeldb.id)
          break;
        case 'config':
          url = this.$store.getters.getContractInstalledHardware(this.modeldb.id)
          break;
      }
      this.$store.dispatch('confirmationValue',{
          template: 'jsonConfig',
          show: true,
          title: this.$t('base.delete'),
          pre: 'success',
          model: this.$tc(`service_install.${this.title}`,2,{name:this.name}),
          action: this.$tc('deleted',2),
          content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
            record: value.title
          }),
          type: 'error',
          confirm:{
              url: url,
              method: 'put',
              //El metodo acepta cualquier tipo de metodo y luego se le envia la data...
              data:clonedb,
          },
          pos: 2
      })
    },
    changeMode(number,item,index){
      this.mode = number
      this.newVal = item != undefined ? Object.assign({},item) : {
        title:'',
        value:''
      }
      this.$validator.reset()
      this.index = index != undefined ? index : null
    },
    save(){
      this.$validator.validateAll()
      .then(() =>{
        if (this.errors.all()==''){
          this.loading = true
          let create = true
          if(this.index == null){
            this.newVal['index'] = this.showJson.length
            this.showJson.push(JSON.parse(JSON.stringify(this.newVal)))
          }
          else {
            create = false
            this.$set(this.showJson,this.index,JSON.parse(JSON.stringify(this.newVal)))
          }
          var url = ''
          switch (this.dbvalue){
            case 'wan_config':
            case 'lan_config':
              url = this.$store.getters.getContractServices(this.modeldb.id)
              break;
            case 'config':
              url = this.$store.getters.getContractInstalledHardware(this.modeldb.id)
              break;
          }
          let clone = Object.assign({},this.modeldb)
          clone[this.dbvalue] = Object.assign([],this.showJson)
          axios.put(url,clone)
          .then(response => {
            this.changeMode(1)
            this.modeldb = clone
            this.$store.dispatch('pushNotification', {
                snackbar: true,
                style: 'success',
                timeout: 6000,
                notifText: 'success',
                notifPos: 2,
                extra: {
                    model: this.$tc(`service_install.${this.title}`,2,{name:this.name}),
                    action: create ?  this.$tc('created', 2) : this.$tc('updated', 2)
                }
            })
            this.$emit('changeModel',{
              field:this.dbvalue,
              model:this.modeldb
            })
          })
          .finally(()=>{
            this.loading = false
          })
        }
      })
    },
    save2(){
      this.$validator.validate('configs.*')
      .then((valid) =>{
        if (valid){
          this.loading = true
          let clone = Object.assign({}, this.modeldb)
          clone[this.dbvalue] = [Object.assign({}, this.filled_configs)]
          axios.put(this.$store.getters.getContractServices(this.modeldb.id), clone)
            .then(response => {
              this.modeldb = Object.assign({}, clone)
              // this.$set(this.modeldb, this.dbvalue, this.filled_configs)
              this.$store.dispatch('pushNotification', {
                  snackbar: true,
                  style: 'success',
                  timeout: 6000,
                  notifText: 'success2',
                  notifPos: 2,
                  extra: {
                      model: this.$tc(`service_install.${this.title}`,2,{name:this.name}),
                      action: this.$tc('saved', 2)
                  }
              })
              this.$emit('changeModel',{
                field:this.dbvalue,
                model:this.modeldb
              })
              this.close()
            })
            .finally(()=>{
              this.loading = false
            })
        }
      })
    },
    // Update the JSON if the user has CDS permissions
    save3(){
      this.$validator.validate('configs.*')
      .then((valid) =>{
        if (valid){
          this.loading = true
          let clone = Object.assign({}, this.modeldb)
          clone[this.dbvalue] = [Object.assign({}, this.filled_configs)]
          axios.put(this.$store.getters.getContractServices(this.modeldb.id), clone)
            .then(response => {
              this.modeldb = Object.assign({}, clone)
              // this.$set(this.modeldb, this.dbvalue, this.filled_configs)
              this.$store.dispatch('pushNotification', {
                  snackbar: true,
                  style: 'success',
                  timeout: 6000,
                  notifText: 'success2',
                  notifPos: 2,
                  extra: {
                      model: this.$tc(`service_install.${this.title}`,2,{name:this.name}),
                      action: this.$tc('saved', 2)
                  }
              })
              this.$emit('updateJsonConfig',{
                field:this.dbvalue,
                model:this.modeldb
              })
              this.close()
            })
            .finally(()=>{
              this.loading = false
            })
        }
      });
    },
  },
  watch: {
    //
  },
  computed:{
    headers(){
      let head = [
        {
          text: this.$t('name'),
          align: 'left',
          value: 'title',
        },
        {
          text: this.$t('Taxes.value'),
          align: 'left',
          value: 'value',
        },
      ]
      if(!this.readOnly){
        head.push({
          text: this.$t('actions'),
          value: 'action',
          sortable: false ,
          align: 'right'
        })
      }
      return head
    }
  }
}
