var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-n2 mt-6 pt-05",attrs:{"text":"","x-small":"","fab":"","color":"success"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-random fa-rotate-180")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.change")))])])]}}])},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"300px"},attrs:{"dense":""}},_vm._l((_vm.filteredTaxBillRates),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":item.id === _vm.taxBillRate0.id},on:{"click":function($event){return _vm.changeTaxBillRate(item)}}},[_c('v-list-item-title',{class:{
          'success--text': item.default && item.id !== _vm.taxBillRate0.id
        }},[_vm._v(" "+_vm._s(_vm.itemName(item))+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }