<template>
  <v-dialog scrollable persistent
    v-model="dialog"
    max-width="400"
  >
    <v-card>
      <toolbarDialog editMode
        :title="`${$t('subscribers.balance')}`"
        titleSize="title"
        @close="close"
      />
      <v-card-text class="pb-1">
        <v-col cols="5" class="text-center pa-0 pb-1 mx-auto">
          <moneyfield
            v-model="balance"
            name="balance"
            labelfield="subscribers.balance"
            scopefield="balance"
            :decimalprecision="2"
          ></moneyfield>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn save
          :loading="loading"
          :disabled="!computedBalanceChanged"
          @click="save"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">{{
            $t("base.save")
          }}</span>
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
        <v-btn text cancel
          @click="close"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{ $t('base.cancel') }}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'overwrite-balance',
  components:{},
  props:{
    // Subscriber ID
    subscriber_id: {
      type: Number,
      default: 0
    },
    // Current Balance
    current_balance: {
      type: Number,
      default: 0
    },
  },
  data(){
    return {
      dialog: false,
      loading: false,
      balance: 0
    }
  },
  computed: {
    computedBalanceChanged () {
      return this.balance != this.current_balance
    }
  },
  methods:{
    open () {
      this.balance = this.current_balance
      this.dialog = true
    },
    save () {
      this.loading = true
      axios.patch(this.$store.getters.subscriberOverwriteBalance(this.subscriber_id), {
        balance: this.balance
      })
        .then(response => {
          if (response.data && response.data.success) {
            this.$emit('balanceOverwrite', response.data.success)
            this.close()
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.balance = 0
      }, 100)
    }
  },
  watch: {},
  mounted () {}
}
</script>
