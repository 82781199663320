<template>
  <v-btn text rounded fab
    class="ma-0 i18n"
    :class="!this.$store.getters.isAuth && !this.$store.getters.dbSelected ? 'mr-1':''"
    @click="changeLang"
  >
    <v-icon class="ma-0 mr-2">fas fa-globe</v-icon>
    <span v-if="this.$i18n.locale === 'es'">ES</span>
    <span v-else>EN</span>
  </v-btn>
</template>

<script>
export default {
  methods: {
    changeLang: function () {
      let nextLang = ''
      let verbLang = ''
      if (this.$i18n.locale === 'es') {
        nextLang = 'en'
        verbLang = '<b>English</b>'
      } else {
        nextLang = 'es'
        verbLang = '<b>Español</b>'
      }
      // Standard localization for VeeValidate (deferred to i18n method below)
      /* this.$validator.localize(nextLang)*/
      // Change system wide Language
      this.$i18n.locale = nextLang
      // Change vuetify components Language (e.g. DataTables)
      this.$vuetify.lang.current = nextLang
      // Change MomentJS locale
      this.$moment.locale(nextLang)
      // Save lang in local storage
      localStorage.setItem('restiLang', nextLang)
      // Changes manual date format
      this.$store.dispatch('changeDateFormat')
      // Changes manual num format
      var numeral = require('numeral')
      numeral.locale(nextLang)
      // Notify lang change
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: 'info',
        mode: null,
        timeout: 4000,
        notifText: 'base.lng_changed_to',
        extra: verbLang
      })
    }
  }
}
</script>
