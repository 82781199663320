import ExistingSubscriber from "./DialogExistingSubscriber.vue";
import SubscribersGroupsBtn from "../SubscribersGroups/FabButton.vue";
import "./SubscriberForm.scss";

export default {
  name: "subscriber-form",
  components: {
    SubscribersGroupsBtn,
    ExistingSubscriber
  },
  props: {
    // Class parent css
    classParent: {
      type: String,
      default: ""
    },
    // Data Subscriber
    same_as_previous: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    f_contact:{
      type: Object,
      default: function() {
        return { local_phone: "" };
      }
    },
    // If the subscriber is migrated
    isMigrate: {
      type: Boolean,
      default: false
    },
    // Show phone, telephone and email Fields
    showContacFields: {
      type: Boolean,
      default: false
    },
    // Edit mode
    editMode: {
      type: Boolean,
      default: false
    },
    // Show code subscriber
    showCode: {
      type: Boolean,
      default: false
    },
    // Edit code subscriber
    editCode: {
      type: Boolean,
      default: false
    },
    // The Subscribers Group FAB must be nudged down
    fab_nudged: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      loadingTypeClient: false,
      loadingPrefix: false,
      is_firm: false,
      date_menu: false,
      showValidTaxRate: false,
      showValidTaxSale: false,
      av_image: "",
      subscriber: {},
      others_contact_medium: '',
      client_types: [],
      prefixes: [],
      genders: ["F", "M"],
      dni_type: 3,
      max_length: 0,
      years_18_ago: new Date(Date.now() - 567648000000)
        .toISOString()
        .substr(0, 10),
      iva_items: process.env.VUE_APP_IVA_ITEMS,
      islr_items: process.env.VUE_APP_ISLR_ITEMS,
      modeNode: process.env.NODE_ENV,
      copyData: "",
      franchise_contact: this.f_contact,
      permissions: {
        setGroup: false,
        editDni: false,
        editCode: false
      },
      validCode: false,
      group_key: null,// To force Group Fab reactivity
      comparisonCode: ''
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.subscriber.birthdate);
    },
    computedIvaItems() {
      let items = [];
      JSON.parse(this.iva_items).forEach(element => {
        items.push({
          text: element ? this.$toPercent(element) : "0%",
          value: element
        });
      });
      return items;
    },
    computedIslrItems() {
      let items = [];
      JSON.parse(this.islr_items).forEach(element => {
        items.push({
          text: element ? this.$toPercent(element) : "0%",
          value: element
        });
      });
      return items;
    },
    computedPrefix() {
      if (this.editMode) {
        return !(this.editMode && this.permissions.editDni);
      } else {
        return false;
      }
    },
  },
  methods: {
    getSubscriber() {
      // eslint-disable-next-line no-console
      // console.log(this.same_as_previous, this.editMode);
      if (this.same_as_previous && this.editMode) {
        this.processSubscriberData(this.same_as_previous);
      }
      if (!this.editMode) {
        this.subscriber = {
          code: null,
        }
      }
    },
    /**
     *  Process Subscriber Data
     *
     * @param {subscriber} SubscriberData
     */
    processSubscriberData(SubscriberData) {
      // eslint-disable-next-line no-console
      // console.log("same_as_previous before", SubscriberData);
      this.subscriber = SubscriberData;
      // Prefix and dni
      this.subscriber.prefix = SubscriberData.doc.substring(0, 1);
      this.subscriber.dni = Number(SubscriberData.doc.substring(1, 10));
      // firm name, first name and last name
      if (SubscriberData.full_name) {
        this.subscriber.firm_name = SubscriberData.full_name.firm_name
          ? SubscriberData.full_name.firm_name
          : "";
        this.subscriber.first_name = SubscriberData.full_name.first_name
          ? SubscriberData.full_name.first_name
          : "";
        this.subscriber.last_name = SubscriberData.full_name.last_name
          ? SubscriberData.full_name.last_name
          : "";
        if (!this.subscriber.legal_denomination) {
          this.subscriber.legal_denomination = SubscriberData.full_name
            .denomination
            ? SubscriberData.full_name.denomination
            : "";
        }
      }
      // Taxes
      this.subscriber.tax_rate =
        SubscriberData.tax_rate != "" && SubscriberData.tax_rate != null
          ? Number(SubscriberData.tax_rate)
          : 0;
      this.subscriber.tax_sale =
        SubscriberData.tax_sale != "" && SubscriberData.tax_sale != null
          ? Number(SubscriberData.tax_sale)
          : 0;
      // Image
      this.av_image = SubscriberData.avatar_url
        ? SubscriberData.avatar_url
        : "";
      // Is firm
      if (this.subscriber.prefix == "V" || this.subscriber.prefix == "E") {
        this.is_firm = SubscriberData.person_type == "legal";
      }
      if (this.subscriber.contact_medium && !['whatsapp', 'call', 'email'].includes(this.subscriber.contact_medium)) {
        this.others_contact_medium = this.subscriber.contact_medium
        this.subscriber.contact_medium = 'others'
      }
      this.copyData = JSON.stringify(this.subscriber);
      // eslint-disable-next-line no-console
      // console.log("same_as_previous", this.subscriber);
      this.ValidAllTax();
      this.prefixChange();
    },
    getSubscriberTypes() {
      this.loadingTypeClient = true;
      axios
        .get(this.$store.getters.getTypeClient(), {
          params: {
            paginate: 200,
            page: 1,
            select_only: true,
            orderBy: "name",
            dirDesc: false,
            active_only: true
          }
        })
        .then(response => {
          this.client_types = response.data.typeclient.data;
        })
        .catch(err => {
          this.client_types = [];
          if (err.response && this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log("Error", err.response);
          }
        })
        .finally(() => {
          this.loadingTypeClient = false;
        });
    },
    loadPrefixes() {
      this.loadingPrefix = true;
      axios
        .get(this.$store.getters.getPrefixes)
        .then(response => {
          this.prefixes = response.data.prefixes;
        })
        .catch(err => {
          this.prefixes = [];
          if (err.response && this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log("Error", err.response);
          }
        })
        .finally(() => {
          this.loadingPrefix = false;
        });
    },
    prefixChange() {
      switch (this.subscriber.prefix) {
        case "V":
        case "E":
          this.dni_type = 0;
          this.max_length = 8;
          // eslint-disable-next-line no-console
          // console.log('V-E', this.dni_type, this.max_length);
          break;
        case "J":
        case "G":
          this.is_firm = false;
          this.dni_type = 1;
          this.max_length = 9;
          // eslint-disable-next-line no-console
          // console.log('J-G', this.dni_type, this.max_length);
          break;
        default:
          break;
      }
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    agentChange(data) {
      // eslint-disable-next-line no-console
      // console.log("agentChange", data);
      if (!data) {
        this.subscriber.tax_rate = 0;
        this.subscriber.tax_sale = 0;
      }
      this.ValidAllTax();
    },
    /**
     * Image functions
     */
    clickFile() {
      this.$refs.avtr.$refs.input.click();
    },
    dropImg() {
      this.av_image = "";
      this.subscriber.avatar = "";
    },
    displayPreview(e) {
      if (!["image/png", "image/jpeg", "image/jpg"].includes(e.type)) {
        this.dropImg();
        this.$awn.alert(
          this.$tc("error422.avatar_must_valid_image", 0, [
            this.$t("subscribers.avatar")
          ]),
          { durations: { alert: 6000 } }
        );
      } else if (e.size > 2097152) {
        this.dropImg();
        this.$awn.alert(
          this.$tc("error422.avatar_max_2048_kb", 0, [
            this.$t("subscribers.avatar")
          ]),
          { durations: { alert: 6000 } }
        );
      } else {
        const fr = new FileReader();
        fr.readAsDataURL(e);
        fr.addEventListener("load", () => {
          this.av_image = fr.result;
          this.subscriber.avatar = e;
        });
      }
    },
    /**
     * Push success notification
     *
     * @param {string} model
     * @param {string} action
     * @param {number} pos
     * @param {number} time
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: this.$tc(model, pos),
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
    focusDNI () {
      this.$emit('toggleBtn', true)
    },
    subscriberExists() {
      this.$refs.subscriber_dni.validate().then(success => {
        if (success.valid) {
          const val = {
            prefix: this.subscriber.prefix,
            ced: this.subscriber.dni
          };
          if (this.editMode) {
            const copy = JSON.parse(this.copyData);
            const compareDNI = copy.dni == val.ced;
            const comparePrefix = copy.prefix == val.prefix;
            // eslint-disable-next-line no-console
            // console.log(`${copy.dni} ${val.ced} ${compareDNI}\n${copy.prefix} ${val.prefix} ${comparePrefix}`);
            if (!compareDNI || !comparePrefix) {
              this.getSubscriberByDoc(val);
            } else {
              this.$emit('toggleBtn', false)
            }
          } else {
            this.getSubscriberByDoc(val);
          }
        }
      });
    },
    /**
     * Check if entered Subscriber Number (code) exists
     */
    checkIfCodeExists() {
      if (this.subscriber.code != this.comparisonCode) {
        this.$refs.subscriber_code.validate().then(success => {
          if (success.valid) {
            this.loading = true
            let url = this.subscriber.id ?
                        this.$store.getters.checkContractCode(this.subscriber.code, this.subscriber.id) :
                        this.$store.getters.checkContractCode(this.subscriber.code)
            axios
              .get(url, {
                params: {
                  is_stealth: true
                }
              })
              .then(response => {
                if (response.status == 200) {
                  if (!this.editMode) {
                    let res = response.data;
                    res.code = this.subscriber.code;
                    this.$refs.existing_subscriber.open_dialog(res, true);
                  } else {
                    this.subscriber.code = this.comparisonCode
                    this.$awn.warning(
                      this.$tc("subscribers.subscriber_code_already_exists", 1),
                      { durations: { warning: 4000 } }
                    )
                  }
                }
              })
              .catch(err => {
                //
              })
              .finally(() => {
                this.loading = false
                this.comparisonCode = this.subscriber.code
              })
          }
        })
      }
    },
    /**
     * Get subscriber by doc
     * @param {object: doc} val
     * doc:{prefix: String, ced: Number}
     */
    getSubscriberByDoc(val) {
      this.loading = true;
      axios
        .get(this.$store.getters.getSubscriberByDoc(val), {
          params: {
            is_stealth: true
          }
        })
        .then(response => {
          if (response.status == 200) {
            if (!this.editMode) {
              let res = response.data.success;
              res.code = this.subscriber.code;
              this.$refs.existing_subscriber.open_dialog(res);
            } else {
              this.$awn.warning(
                this.$tc("subscribers.subscriber_already_exists", 1),
                { durations: { warning: 4000 } }
              );
              const copy = JSON.parse(this.copyData);
              this.subscriber.prefix = copy.prefix;
              this.subscriber.dni = copy.dni;
            }
          } else if (response.status == 204) {
            // eslint-disable-next-line no-console
            // console.log('response', response);
          }
        })
        .catch(err => {
          if (err.response && this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log("Error", err.response);
          }
        })
        .finally(() => {
          this.$emit('toggleBtn', false)
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loading = false;
            }, 400);
          } else {
            this.loading = false;
            this.$emit("loading", false);
          }
        });
    },
    /**
     * Validate Form
     *
     * @param {editMode} editMode
     * @param {subscriber} subscriber
     * @returns {SubscriberError}
     */
    validateForm() {
      const id = this.subscriber.id;
      this.$refs.form_subscriber.validate().then(success => {
        if (success) {
          if (this.editMode == false && !id) {
            this.beforeSaveSubscriber();
          } else {
            this.validCloneByUpdate();
          }
        } else {
          this.$emit("SubscriberError");
        }
      });
    },
    /**
     * Reset Form
     */
    resetForm() {
      this.$refs.form_subscriber.reset();
    },
    /**
     * Validate the fields before updating
     */
    validCloneByUpdate() {
      const copy = JSON.parse(this.copyData);
      const origin = JSON.parse(JSON.stringify(this.subscriber));
      let notSame = [];
      for (var key in origin) {
        if (key != "full_name") {
          // eslint-disable-next-line no-console
          if (origin[key] != copy[key]) {
            notSame.push({ key: origin[key] != copy[key] });
          }
        }
      }
      setTimeout(() => {
        if (notSame.length && notSame.length > 0) {
          this.beforeSaveSubscriber();
        } else {
          this.status204();
          // eslint-disable-next-line no-console
          // console.log('validClone');
        }
      }, 5);
    },
    /**
     * Emit value if it is status 204
     */
    status204() {
      this.sendContactFields();
      this.$emit("same", this.subscriber);
      this.$awn.closeToasts();
      this.$awn.warning(this.$t("DSB.profile.nothing_to_change"), {
        durations: { warning: 4000 }
      });
    },
    beforeSaveSubscriber() {
      /**
       * The legal denomination is built
       * depending on the prefix of the DNI and
       * if it is a personal signature or not
       */
      let clone = Object.assign({}, this.subscriber);
      clone.is_firm = this.is_firm;
      if ((clone.prefix == "V" || clone.prefix == "E") && clone.is_firm) {
        clone.legal_denomination = {
          type: "firm",
          firm_name: clone.firm_name.toUpperCase().trim(),
          first_name: clone.first_name.toUpperCase().trim(),
          last_name: clone.last_name.toUpperCase().trim()
        };
      } else if (
        (clone.prefix == "V" || clone.prefix == "E") &&
        !this.is_firm
      ) {
        clone.legal_denomination = {
          type: "natural",
          first_name: clone.first_name.toUpperCase().trim(),
          last_name: clone.last_name.toUpperCase().trim()
        };
      } else {
        clone.legal_denomination = {
          type: "legal",
          denomination: clone.legal_denomination.toUpperCase().trim()
        };
        if (this.editMode) {
          clone.gender = "";
          clone.birthdate = "";
        } else {
          clone.gender = null;
          clone.birthdate = null;
        }
      }
      // Convert to String so it passes with FormData
      clone.legal_denomination = JSON.stringify(clone.legal_denomination);
      // Always delete the fields
      delete clone.firm_name;
      delete clone.first_name;
      delete clone.last_name;
      delete clone.person_type;
      delete clone.is_prospect;
      if (this.editMode) {
        const copyData = JSON.parse(this.copyData);
        // If the avatar hasn't change, don't send it
        if (copyData.avatar == clone.avatar) {
          delete clone.avatar;
        }
      }
      clone.subs = true;
      // eslint-disable-next-line no-console
      // console.log('clone', clone);
      this.saveSubscriber(clone);
    },
    /**
     * Save Subscriber
     */
    saveSubscriber(data) {
      this.loading = true;
      this.$emit("loading", true);
      let clone = data;
      // Create FormData to send to path
      let form_data = new FormData();
      Object.keys(clone).forEach(key => {
        if (key == "avatar" && clone["avatar"] != null) {
          form_data.append("avatar", clone["avatar"]);
        } else if (
          key == "created_at" ||
          key == "updated_at" ||
          key == "group" ||
          key == "person_type"
        ) {
          delete clone[key];
        } else {
          // clone[key] === '' ||
          clone[key] === null
            ? key === 'group_id' ? // When group_id is null...
              form_data.append(key, '') : // pass it empty for backend to take it
              delete clone[key]
            : clone[key] === false
            ? form_data.append(key, 0)
            : clone[key] === true
            ? form_data.append(key, 1)
            : form_data.append(key, clone[key]);
        }
      });
      // Send petition to api
      if (this.editMode && this.subscriber.id != 0) {
        form_data.append("_method", "PATCH");
      }
      if (this.isMigrate) {
        form_data.append('old_subscriber_num', this.subscriber.code)
      }
      if (this.$store.getters.isFranchise) {
        this.sendContactFields();
      }
      if (this.subscriber.contact_medium === 'others') {
        form_data.append('contact_medium', this.others_contact_medium)
      }
      axios
        .post(this.$store.getters.getSubscribers(this.subscriber.id), form_data)
        .then(response => {
          if (response.status == 204) {
            // eslint-disable-next-line no-console
            // console.log('response post');
            this.status204();
          }
          if (response.data.success) {
            // eslint-disable-next-line no-console
            // console.log('Form', response.data);
            let subscriber = {};
            if (response.data.success.subscriber) {
              const subscriberData = Object.assign({}, response.data.success.subscriber);
              subscriber = Object.assign({}, response.data.success.subscriber);
              subscriber.prefix = subscriberData.doc.substring(0, 1);
              subscriber.dni = subscriberData.doc.substring(1,10);
              // eslint-disable-next-line no-console
              if (subscriberData.full_name) {
                let full_name;
                if (typeof(subscriberData.full_name) == "string") {
                  full_name = JSON.parse(subscriberData.full_name);
                } else {
                  full_name = subscriberData.full_name;
                }
                subscriber.full_name = full_name;
                if (full_name.type === "natural") {
                  subscriber.first_name = full_name.first_name;
                  subscriber.last_name = full_name.last_name;
                } else if(full_name.type === "firm"){
                  subscriber.first_name = full_name.first_name;
                  subscriber.last_name = full_name.last_name;
                  subscriber.firm_name = full_name.firm_name;
                } else if(full_name.type === "legal"){
                  subscriber.denomination = full_name.denomination;
                }
                // eslint-disable-next-line no-console
                // console.log('subscriber',subscriber);
              }
              if (this.editMode) {
                // eslint-disable-next-line no-console
                this.$emit("update", subscriber);
                this.pushNoty("subscribers.subscribers", "updated", 1);
              } else {
                // eslint-disable-next-line no-console
                this.$emit("create", {
                  cntrct: response.data.success.cntrct,
                  ndx: response.data.success.ndx,
                  response: subscriber,
                  subscriberForm: Object.assign({}, this.subscriber)
                });
                this.pushNoty("subscribers.subscribers", "created", 1);
              }
            }
          }
        })
        .catch(err => {
          if (err.response && this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log('data', err.response.data);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loading = false;
            }, 3500);
          } else {
            this.loading = false;
            this.$emit("loading", false);
          }
        });
    },
    /**
     * Send the fields if it is franchise
     */
    sendContactFields() {
      this.$emit("franchiseContact", this.franchise_contact);
    },
    cancelNewContract() {
      const type_client_id = this.subscriber.type_client_id;
      const prefix = this.subscriber.prefix;
      // eslint-disable-next-line no-console
      // console.log('ok');
      this.subscriber = {};
      this.subscriber.type_client_id = type_client_id;
      this.subscriber.prefix = prefix;
    },
    /**
     *
     * @param {ContractWithSubscriber} data
     */
    NewContract(data) {
      this.pushNoty("contracts.contracts", "created", 1);
      this.processSubscriberData(data.contract.subscriber);
      this.$emit('newContractExistingSubscriber', this.subscriber, data.id);
    },
    /**
     * Get Permissions
     */
    PermissionsSubscriber() {
      this.permissions.editDni = this.getPermissionGroup({
        permission: ["editDni_subscriber"],
        mode: "all"
      });
      this.permissions.editCode = this.getPermissionGroup({
        permission: ["editCode_subscriber"],
        mode: "all"
      });
      this.permissions.setGroup = this.getPermissionGroup({
        permission: ["setGroupID_subscriber"],
        mode: "all"
      });
    },
    validTax(agent_ret, tax, tax_name) {
      // eslint-disable-next-line no-console
      // console.log(
      //   `agent_ret ${agent_ret}\n tax ${tax} ${tax == 0} ${tax === ""}\n ${tax_name} ${
      //     this.subscriber[tax_name]
      //   } ${this.subscriber[tax_name] == 0} ${this.subscriber[tax_name] ===
      //     ""}\n`
      // );
      if (agent_ret) {
        if (this.subscriber[tax_name]) {
          if (this.subscriber[tax_name] > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          if (tax) {
            if (tax === "" || tax == 0) {
              return true;
            } else {
              return false;
            }
          } else {
            this.subscriber[tax_name] = 0;
            return true;
          }
        }
      } else {
        return false;
      }
    },
    ValidAllTax(){
      this.showValidTaxRate = this.validTax(
        this.subscriber.agent_ret,
        this.subscriber.tax_sale,
        "tax_rate"
      );
      this.showValidTaxSale = this.validTax(
        this.subscriber.agent_ret,
        this.subscriber.tax_rate,
        "tax_sale"
      );
    },
    setSubscribersGroupsID (group_obj) {
      if (group_obj.id) {
        this.subscriber.group_id = group_obj.id
        this.subscriber.group = group_obj
      } else {
        this.subscriber.group_id = null
        this.subscriber.group = {
          id: 0,
          name: this.$tc('base.none', 1)
        }
      }
      this.group_key = this.subscriber.group_id
    }
  },
  watch: {
    date_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    is_firm(val) {
      if (val) {
        this.max_length = 9;
      } else {
        this.subscriber.firm_name = "";
        this.max_length = 8;
        this.subscriber.tax_rate = 0;
        this.subscriber.tax_sale = 0;
        this.subscriber.agent_ret = false;
        this.$refs.form_subscriber.reset();
      }
    },
    'subscriber.code':{
      handler(val){
        if (this.isMigrate) {
          if (val != null && val != undefined && val.length > 2) {
            this.validCode = false;
          } else {
            this.validCode = true;
          }
        } else {
          this.validCode = false;
        }
      }
    }
  },
  mounted() {
    this.PermissionsSubscriber();
    this.loadPrefixes();
    this.getSubscriberTypes();
    this.getSubscriber();
  }
};
