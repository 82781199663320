var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","hide-overlay":"","content-class":"preview-company"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-app',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.$vuetify.breakpoint.name === 'xs' ? (_vm.miniPreview = true) : _vm.miniPreview,"stateless":"","clipped":"","width":"250","mini-variant-width":"70","preview":""},model:{value:(_vm.drawerPreview),callback:function ($$v) {_vm.drawerPreview=$$v},expression:"drawerPreview"}},[_c('v-list',{staticClass:"cursor-disabled"},[_c('v-list-item-group',{attrs:{"color":_vm.company.color1}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("fas fa-tachometer-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("DSB.menu.dashboard")))])],1)],1)],1)],1)],1),_c('v-app-bar',{staticClass:"main-toolbar pl-0",attrs:{"dark":"","color":_vm.company.color1,"fixed":"","dense":"","clipped-left":"","elevate-on-scroll":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({staticClass:"circle",attrs:{"dark":""},on:{"click":function($event){$event.stopPropagation();_vm.drawerPreview = !_vm.drawerPreview}}},on))]}}])},[(_vm.drawerPreview)?_c('span',[_vm._v(_vm._s(_vm.$t("base.hide_menu")))]):_c('span',[_vm._v(_vm._s(_vm.$t("base.show_menu")))])]),_c('v-toolbar-title',{staticClass:"pl-0 subtitle-1 text-uppercase"},[_c('img',{attrs:{"src":_vm.company.head_logo == '' ? _vm.company.logo : _vm.company.head_logo,"alt":_vm.company.name_company,"height":"38px","align":"absmiddle"}}),_c('span',{staticClass:"comp_name only-preview",style:({
            'background-color': _vm.company.color1 + '!important'
          })},[_vm._v(_vm._s(_vm.company.name_company)+" ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0 hidden-xs-only circle",attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.miniPreview = !_vm.miniPreview}}},on),[(_vm.miniPreview)?_c('v-icon',{staticClass:"pl-1"},[_vm._v("fas fa-chevron-right")]):_c('v-icon',{staticClass:"pr-1"},[_vm._v("fas fa-chevron-left")])],1)]}}])},[(_vm.miniPreview)?_c('span',[_vm._v(_vm._s(_vm.$t("base.restore_menu")))]):_c('span',[_vm._v(_vm._s(_vm.$t("base.condensate_menu")))])]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"cursor-disabled"},[_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("fas fa-bell")])],1),_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-user")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v(_vm._s(_vm.company.name))])],1),_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{staticClass:"ma-0 mr-2"},[_vm._v("fas fa-globe")]),_c('span',[_vm._v("ES")])],1)],1)],1),_c('v-content',{staticClass:"pl-0 pb-10"},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":"","pa-0":""}},[_c('v-row',{staticClass:"pr-6 pt-0 pb-5",class:{
            open: _vm.drawerPreview && !_vm.miniPreview,
            mini: _vm.drawerPreview && _vm.miniPreview,
            closed: !_vm.drawerPreview
          },attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pr-3' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 step-inner mx-auto card-outlined",style:({
                'border-color': _vm.company.color1 + '!important'
              }),attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title",style:({
                    color: _vm.company.color1 + '!important',
                    'caret-color': _vm.company.color1
                  })},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("company.basic_data"))+" ")])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"pt-1 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l(([
                                {
                                  title: _vm.$t('company.name_company'),
                                  content: _vm.company.name_company
                                },
                                {
                                  title: _vm.$t('company.identification'),
                                  content: _vm.company.identification
                                },
                                {
                                  title: _vm.$t('company.object_company'),
                                  content: _vm.company.object_company
                                },
                                // { title: 'Logo', content: company.logo},
                                {
                                  title: _vm.$t('company.telephone'),
                                  content: _vm.company.telephone
                                },
                                {
                                  title: _vm.$t('company.email'),
                                  content: _vm.company.email
                                }
                              ]),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.content))])])}),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.printable_logo"))+" ")]),_c('td',[_c('img',{staticClass:"grey lighten-1",attrs:{"src":_vm.av_image == ''
                                      ? _vm.company.logo
                                      : require("@/assets/default.png"),"alt":_vm.company.name_company,"height":"38px","align":"absmiddle"}})])])],2)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pl-3' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 step-inner mx-auto card-outlined",style:({
                'border-color': _vm.company.color1 + '!important'
              }),attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title",style:({
                    color: _vm.company.color1 + '!important',
                    'caret-color': _vm.company.color1
                  })},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("company.legal_data"))+" ")])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"pt-1 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l(([
                                {
                                  title: _vm.$t('company.legal_representative'),
                                  content: _vm.company.legal_representative
                                },
                                {
                                  title: _vm.$t(
                                    'company.name_institution_registration'
                                  ),
                                  content:
                                    _vm.company.name_institution_registration
                                },
                                {
                                  title: _vm.$t('company.register_number'),
                                  content: _vm.company.register_number
                                },
                                {
                                  title: _vm.$t('company.volume'),
                                  content: _vm.company.volume
                                },
                                {
                                  title: _vm.$t('company.folio'),
                                  content: _vm.company.folio
                                },
                                {
                                  title: _vm.$t('company.date_register'),
                                  content: _vm.company.date_register
                                } ]),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.content))])])}),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.firm"))+" ")]),_c('td',[_c('img',{staticClass:"grey lighten-1",attrs:{"src":_vm.company.firm
                                      ? _vm.company.firm
                                      : require("@/assets/default.png"),"alt":_vm.company.name_company,"height":"38px","align":"absmiddle"}})])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.seal"))+" ")]),_c('td',[_c('img',{staticClass:"grey lighten-1",attrs:{"src":_vm.company.seal
                                      ? _vm.company.seal
                                      : require("@/assets/default.png"),"alt":_vm.company.name_company,"height":"38px","align":"absmiddle"}})])])],2)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pr-3' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 step-inner mx-auto card-outlined",style:({
                'border-color': _vm.company.color1 + '!important'
              }),attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title",style:({
                    color: _vm.company.color1 + '!important',
                    'caret-color': _vm.company.color1
                  })},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("company.geographical_information"))+" ")])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"pt-1 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load_countries),expression:"!load_countries"}]},[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.country"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.items_country[_vm.company.country - 1] ? _vm.items_country[_vm.company.country - 1].name : "")+" ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load_states),expression:"!load_states"}]},[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.state"))+" ")]),_c('td',_vm._l((_vm.items_state),function(item,index){return _c('span',{key:index},[(item.id == _vm.company.state)?[_vm._v(_vm._s(item.name))]:_vm._e()],2)}),0)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("company.fiscal_address"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.company.fiscal_address)+" ")])]),(_vm.company.geo_location)?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$tc("contracts.coordinates", 2))+" ")]),_c('td',[_c('a',{style:({
                                    color: _vm.company.color1
                                  }),attrs:{"href":("https://www.google.com/maps/@" + (_vm.company.latitude) + "," + (_vm.company.longitude) + ",20z"),"target":"_blank"}},[_c('strong',[_vm._v(_vm._s(_vm.company.latitude)+", "+_vm._s(_vm.company.longitude))])])])]):_vm._e()])]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pl-3' : '',attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 step-inner mx-auto card-outlined",style:({
                'border-color': _vm.company.color1 + '!important'
              }),attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title",style:({
                    color: _vm.company.color1 + '!important',
                    'caret-color': _vm.company.color1
                  })},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("company.system_administrator"))+" ")])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"pt-1 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l(([
                                {
                                  title: _vm.$t('company.name'),
                                  content: _vm.company.name
                                },
                                {
                                  title: _vm.$t('company.email_admin'),
                                  content: _vm.company.email_admin
                                }
                              ]),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.content))])])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0 step-inner mx-auto card-outlined",style:({
                'border-color': _vm.company.color1 + '!important'
              }),attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title",style:({
                    color: _vm.company.color1 + '!important',
                    'caret-color': _vm.company.color1
                  })},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("company.social_networking"))+" ")])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"pt-1 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l(([
                                {
                                  title: 'Facebook',
                                  content: _vm.company.facebook
                                },
                                {
                                  title: 'Twitter',
                                  content: _vm.company.twitter
                                }
                              ]),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.content))])])}),0)]},proxy:true}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l(([
                                {
                                  title: 'Instagram',
                                  content: _vm.company.instagram
                                },
                                {
                                  title: 'Linkedin',
                                  content: _vm.company.linkedin
                                }
                              ]),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.content))])])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-6 mr-1",attrs:{"color":_vm.company.color1,"fab":"","dark":"","fixed":"","bottom":"","right":"","small":""},on:{"click":_vm.close}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("close"))+" "+_vm._s(_vm.$t("preview")))])])],1)],1)],1),_c('v-footer',{attrs:{"app":"","color":_vm.company.color1}},[_c('span',{staticClass:"white--text"},[_vm._v("© "+_vm._s(new Date().getFullYear()))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }