var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","fullscreen":_vm.fullscreen,"max-width":_vm.no_data ? 440 : 900,"no-click-animation":""},model:{value:(_vm.wallet_movements),callback:function ($$v) {_vm.wallet_movements=$$v},expression:"wallet_movements"}},[_c('v-card',[_c('toolbarDialog',{attrs:{"showOnlyTitle":"","showNew":_vm.permissions.overwrite_balance,"btnName":_vm.$t('base.edit'),"btnIcon":"fas fa-pencil-alt","screenMode":true,"screen":_vm.fullscreen,"title":((_vm.$t('subscribers.balance')) + " " + _vm.computedBalance),"titleSize":"title"},on:{"fullscreen":_vm.screen,"newItem":_vm.newItem,"close":_vm.close_dialog}}),_c('v-card-text',{staticClass:"pb-1"},[(_vm.loadingDialog)?_c('v-col',{staticClass:"text-center pa-0 pb-1",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":40,"width":4,"color":"primary","indeterminate":""}}),_c('br'),_c('span',{staticClass:"caption primary--text pl-3"},[_vm._v(" "+_vm._s(_vm.$t('base.loading'))+"... ")])],1):[_c('v-data-table',{class:{
            'wallet-movements-table': !_vm.no_data,
            'mt-1': _vm.fullscreen,
            'mt-n4': !_vm.fullscreen
          },attrs:{"headers":_vm.headers,"items":_vm.movements,"hide-default-footer":true,"hide-default-header":_vm.no_data},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format(_vm.$store.getters.getDateFormat[3]))+" ")]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_c('p',{class:{
                'mb-0 text-right': true,
                'error--text': item.amount < 0,
                'success--text': item.amount > 0
              }},[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.amount))+" ")])]}},{key:"item.running_balance",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.running_balance))+" ")])]}}])})]],2),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(!_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":"","disabled":_vm.loadingDialog},on:{"click":_vm.close_dialog}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.close')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()],1)],1),(_vm.permissions.overwrite_balance)?_c('OverwriteBalance',{ref:"overwrite_balance_dialog",attrs:{"subscriber_id":_vm.subscriber_id,"current_balance":_vm.balance},on:{"balanceOverwrite":_vm.updatedBalance}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }