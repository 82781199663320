export default {
  name: "MotiveDialog",
  props: {
    parent: {
      type: [Object],
      default: {}
    }
  },
  data() {
    return {
      dialog1: false,
      loading: false,
      motive: ''
    }
  },
  computed: {},
  methods:{
    open(){
      this.dialog1 = true
    },
    close(){
      this.$refs.form_motive.reset()
      this.motive = ''
      this.dialog1 = false
    },
    addMotive(){
      this.$refs.form_motive.validate().then((success)=>{
        if (success) {
          if (this.parent.description.includes(this.motive)) {
            this.$awn.alert(
              this.$tc('error422.name_already_registered', 0, []),
              { durations: { alert: 4000 } }
            )
            return false
          }
          let url = ''
          let type = ''
          let data = {}
          // Check if the catalog exists
          if (Object.keys(this.parent).length === 1 && this.parent.constructor === Object) {
            // Must be created
            type = 'post'
            url = this.$store.getters.catalogList()
            data = {
              category: 'document_adjustment_motives',
              name: 'single_entry',
              description: [this.motive],
            }
          } else {
            // Existing will be updated
            this.parent.description.push(this.motive)
            type = 'patch'
            url = this.$store.getters.catalogList(this.parent.id)
            data = {
              description: this.parent.description
            }
          }

          this.loading = true
          axios({
            url: url,
            method: type,
            data: data
          })
            .then((response)=>{
              if (response.status == 200) {
                this.$awn.success(
                  this.$tc("success", 1, {
                    model: this.$t('concept.motive'),
                    action: this.$tc('created', 1)
                  }),
                  { durations: { success: 4000 } }
                )
                this.$emit('updateTable')
                this.close()
              }
            })
            .catch((error)=>{
              if (error.response) {
                //
              }
            })
            .finally(()=>{
              this.loading = false
            })
        }
      })
    }
  },
  watch: {},
  mounted(){},
}
