import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// Translations provided by Vuetify (typescript)
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'
import '@/styles/icon/_icon.scss'


Vue.use(Vuetify)

let lang = localStorage.getItem("restiLang") != undefined ?
  localStorage.getItem("restiLang") :
  process.env.VUE_APP_LOCALE

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values:{
      'currencyexchange': 'fa-icon-currency-exchange'
    }
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0a57a5',
        info: '#0a57a5',
        info_alt: '#2972c4',
        font_color: '#303030',
        chart: {
            "bgColor": "#FAFAFA",
            "paletteColors": "#2972C4,#1e1e1e"
        }
      },
      dark: {
        primary: '#0a57a5',
        info: '#0a57a5',
        info_alt: '#184475',
        font_color: '#CFCFCF',
        chart: {
            "bgColor": "#1e1e1e",
            "paletteColors": "#2972C4,#CFCFCF"
        }
      }
    }
  },
  lang: {
    locales: { es, en },
    current: lang,
  }
})
