<template>
  <v-container fill-height :class="classParent">
    <!-- Return Button -->
    <v-tooltip bottom v-if="false">
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="NumberField!=503 && modeNode == 'development'"
          color=""
          v-on="on"
          @click="goBack"
        >
          <v-icon>fas fa-undo</v-icon>
        </v-btn>
      </template>
      <span>{{$t('back')}}</span>
    </v-tooltip>
    <!-- Component -->
    <v-layout row wrap align-center text-center v-if="false">
      <!-- APP Bar -->
      <v-app-bar
        v-if="$store.getters.is503"
        app
        fixed
        dense
        clipped-left
        elevate-on-scroll
        :class="classAppBar"
      ></v-app-bar>
      <!-- Text Error -->
      <v-flex xs12>
        <h1>{{NumberField}}</h1>
        <h2 class="warning--text">
          <v-icon size="100" color="warning"> {{ iconField }} </v-icon>
          <br><br>
          {{ $t(titleField) }}
        </h2>
        <div class="warning--description">
          {{ $t(textField) }}
        </div>
      </v-flex>
    </v-layout>

    <!-- APP Bar -->
    <v-app-bar
      v-if="$store.getters.is503"
      app
      fixed
      dense
      clipped-left
      elevate-on-scroll
      :class="classAppBar"
    ></v-app-bar>

    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="col-message-error">
            <!-- <h1>{{NumberField}}</h1> -->
            <h2 class="warning--text">
              <!-- <v-icon size="100" color="warning"> {{ iconField }} </v-icon> -->
              <br><br>
              {{ $t(titleField) }}
            </h2>
            <div class="warning--description">
              {{ $t(textField) }}
            </div>
          </v-col>
          <v-col cols="12" class="col-button-back">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="NumberField!=503 && modeNode == 'development'"
                  color=""
                  v-on="on"
                  @click="goBack"
                >
                  <v-icon class="mr-3">fas fa-undo</v-icon>
                  {{$t('back')}}
                </v-btn>
              </template>
              <span>{{$t('back')}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="col-img-error">
        <v-img
          :lazy-src="require('@/assets/img_error_.png')"
          :src="require('@/assets/img_error_.png')"
        >
          <h1>{{NumberField}}</h1>
        </v-img>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  props:{
    appBarfield:{
      default:false,
      type:Boolean
    },
    titleField:{
      type:String,
      default:''
    },
    textField:{
      type:String,
      default:''
    },
    NumberField:{
      type:[String, Number],
      default:''
    },
    iconField:{
      type:String,
      default:''
    },
    classAppBar:{
      type:String,
      default:''
    },
    classParent:{
      type:String,
      default:''
    },
  },
  data (){
    return {
      modeNode:process.env.NODE_ENV,
    };
  },
  computed:{},
  methods:{
    goBack(){
      this.$router.push(this.$store.getters.isRouteFrom);
    },
  },
  watch:{},
  mounted(){},
}
</script>

<style type="text/css">
  .theme--light.v-app-bar.v-toolbar.v-sheet.mode503 {
    background-color: #FFFFFF;
  }
</style>
