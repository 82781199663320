<template>
  <v-toolbar dense tile :class="classParent">
    <v-toolbar-title>
      <span
        :class="
          fullscreen
            ? 'subtitle-1 font-weight-bold py-1'
            : titleSize + ' font-weight-bold'
        "
      >
        <span v-if="!editMode && showOnlyTitle == 'no'">
          {{ `${$tc("base.new", numNew)}` }}
        </span>
        <span v-if="editMode && showOnlyTitle == 'no'">
          {{ `${$t("base.edit")}` }}
        </span>
        &nbsp;
        <span>{{ title }}</span>
      </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn
        text
        v-show="showNew && btnName"
        class="mr-8"
        @click="newItem"
      >
        <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3 pt-1">
          {{ btnName }}
        </span>
        <v-icon>{{ btnIcon }}</v-icon>
      </v-btn>
      <v-btn v-if="screenMode" text fab @click="changeScreen">
        <v-icon>
          <template v-if="fullscreen">fa-compress-arrows-alt</template>
          <template v-else>fa-expand-arrows-alt</template>
        </v-icon>
      </v-btn>
      <v-btn v-if="fullscreen" text cancel @click="closeDialog">
        <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: "toolbar-dialog",
  components: {},
  props: {
    // Styles
    classParent: {
      type: String,
      default: ""
    },
    //
    showOnlyTitle: {
      type: String,
      default: "no"
    },
    // Title
    title: {
      type: String,
      default: ""
    },
    // Title Size Class
    titleSize: {
      type: String,
      default: "headline"
    },
    // Show Action button
    showNew:{
      type:Boolean,
      default: false,
    },
    // Button's label
    btnName:{
      type:String,
      default: '',
    },
    // Button's icon
    btnIcon:{
      type:String,
      default: '',
    },
    // Change the name of the action that the button emits
    emitButton:{
      type:String,
      default: 'newItem',
    },
    // New
    numNew: {
      type: Number,
      default: 0
    },
    // Edit mode
    editMode: {
      type: Boolean,
      default: false
    },
    // Show FullScreen
    screenMode: {
      type: Boolean,
      default: false
    },
    screen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreen: this.screen,
      search: ""
    };
  },
  computed: {},
  methods: {
    newItem(){
      this.$emit(this.emitButton);
    },
    changeScreen() {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen", this.fullscreen);
    },
    closeDialog() {
      this.fullscreen = false;
      this.$emit("close");
    }
  },
  mounted() {},
  watch: {}
};
</script>
