<template>
  <v-col col-preview>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="previewfile mx-auto" :class="!file.url ? 'no-file' : ''" style="width:156px; height: 224px;">
          <iframe v-if="file.url" :src="file.url" frameborder="0"></iframe>
          <span v-else class="title font-weight-bold mt-12">
            {{ $tc('file_library.file', 1)+' '+$tc('not_found', 1) }}
          </span>
          <span class="infofile">
            <p class="title">
              <v-icon>far fa-{{file.type == 'application/pdf' ? 'file-pdf' : 'file-image' }}</v-icon>
              {{
                $te('file_library.'+file.file_name) ?
                $tc('file_library.'+file.file_name, 1) :
                $t('policy.translation_not_found')
              }}
              <span size>{{file.size}}</span>
            </p>
            <span actions v-if="file.url">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"
                    size="20"
                    class="mx-1"
                    @click="downloadFile('window', file.url)"
                  >
                    fas fa-eye
                  </v-icon>
                </template>
                <span>{{ `${$t('view')} ${$tc('file_library.file', 1)}` }}</span>
              </v-tooltip>
              <!--<v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"
                    size="20"
                    class="ml-2"
                    @click="downloadFile('download', file.url,
                      $te('file_library.'+file.file_name) ?
                        $tc('file_library.'+file.file_name, 1) :
                        file.file_name
                    )"
                  >
                    fas fa-file-download
                  </v-icon>
                </template>
                <span>{{ `${$t('download')} ${$tc('file_library.file', 1)}` }}</span>
              </v-tooltip>-->
            </span>
          </span>
        </span>
      </template>
      <span>
        {{
          $te('file_library.'+file.file_name) ?
          $tc('file_library.'+file.file_name, 1) :
          $t('policy.translation_not_found')
        }}
      </span>
    </v-tooltip>
  </v-col>
</template>

<script>
export default {
  props:{
    file:{
      type:[Object,String],
      default:null
    }
  },
  data(){
    return {
      //
    }
  },
  methods:{
    //
  },
  computed:{
    //
  },
  watch: {
    //
  }
}
</script>