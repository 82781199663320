var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('active',1))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-user-check")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('inactive',1))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-user-alt-slash")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.employees,"items-per-page":_vm.perPage,"footer-props":{
                    'items-per-page-options': [5, 10, 50]
                  },"search":_vm.search,"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":!_vm.expand && _vm.step == 'tab-1' && !_vm.loading_type && !_vm.loading_franchise && _vm.Permissions.create,"disableNew":_vm.loadingTable,"title":_vm.$t('employees.employees'),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newItem}}),_c('EmployeeFilter',{attrs:{"disableFields":_vm.loadingTable || _vm.loading_type || _vm.loading_franchise,"loadingFields":_vm.loadingTable || _vm.loading_type || _vm.loading_franchise,"selectEmployeeTypes":_vm.selectEmployeeTypes,"ListFranchises":_vm.ListFranchises},on:{"Search":_vm.SearchEmployee,"EraseFilter":_vm.EraseFilter}})]},proxy:true},{key:"item.person_type",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc('subscribers.'+item.person_type,1))+" ")]}},{key:"item.employee_type",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:item.is_external_collector ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.$tc('employees.types.'+item.employee_type,item.gender == 'F' ? 2 : 1))+" "+_vm._s(item.is_external_collector ? '(Ext.)' : '')+" ")])]}},{key:"item.is_external_collector",fn:function(ref){
                  var item = ref.item;
return [(item.employee_type == 'collector')?_c('v-checkbox',{staticClass:"employee-external-checkbox ma-0 pa-0",attrs:{"hide-details":"","value":item.id,"ripple":false,"disabled":item.is_external_collector || _vm.loadingTable},on:{"click":function($event){return _vm.changeExternalCollector(item)}},model:{value:(_vm.selected_as_external),callback:function ($$v) {_vm.selected_as_external=$$v},expression:"selected_as_external"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.Permissions.attach && (item.employee_type == 'collector'))?_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.employeeContracts(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-users")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('contracts.contracts',2)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.active==true && _vm.Permissions.edit && item.employee_type != 'collection_analyst')?_c('v-btn',_vm._g({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.deactivateItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-user-check")])],1):_vm._e(),(item.active==false && _vm.Permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","delete":""},on:{"click":function($event){return _vm.activateItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-user-alt-slash")])],1):_vm._e()]}}],null,true)},[(item.active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('deactivate'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('activate'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.Permissions.edit)?_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])])]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('DialogEmployees',{ref:"dialog_employees",on:{"updateList":_vm.List}}),_c('v-dialog',{attrs:{"max-width":"980px","persistent":"","scrollable":"","fullscreen":"","no-click-animation":""},model:{value:(_vm.dialogContracts),callback:function ($$v) {_vm.dialogContracts=$$v},expression:"dialogContracts"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dense":"","tile":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"subtitle-0 py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc('contracts.contracts',2))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('of'))+": "+_vm._s(_vm.employeeContract.legal_denomination ? _vm.employeeContract.legal_denomination[0][0] : '')+" ")])])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 ml-sm-10",attrs:{"text":""},on:{"click":_vm.close}},on),[_c('v-icon',[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],1)],1),_c('v-card-text',[(_vm.dialogContracts)?_c('EmployeeContracts',{ref:"employee_contract",attrs:{"employeeProp":_vm.employeeContract}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }