<template>
  <v-dialog v-model="modalConfig" persistent scrollable width="760">
    <v-card tile>
      <v-toolbar
        dense
        tile
        max-height="48"
        class="requests-modal-toolbar elevation-4"
        style="z-index: 1;"
      >
        <v-toolbar-title>
          <span class="headline font-weight-bold">
            <span>{{ $t('base.configuration') }}</span>
          </span>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>
      <v-card-text class="px-4">
        <v-container fluid style="padding-top: 0 !important">
          <v-row>
            <v-col cols="12" md="12" class="pt-2 pb-5 text-right">
              <v-switch hide-details
                v-model="monthly_fraction"
                :label="$t('prorated_auto_month')"
                color="primary"
                class="small-label my-0 py-0 float-right"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                v-model="clientes"
                v-validate="'required'"
                name="clientes"
                :items="type_clients"
                item-value="value"
                :error-messages="errors.collect('clientes')"
                :label="$t('franchise.customers')"
                :data-vv-as="$t('franchise.customers')"
                :menu-props="{ maxHeight: '400' }"
                chips
                small-chips
                clearable
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item.value, action='client')"
                  >{{ data.item.text }}</v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                v-model="paquetes"
                v-validate="'required'"
                name="paquetes"
                :items="type_packages"
                item-value="value"
                :error-messages="errors.collect('paquetes')"
                :label="$tc('package.package',1)"
                :data-vv-as="$tc('package.package',1)"
                :menu-props="{ maxHeight: '400' }"
                chips
                small-chips
                clearable
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item.value, action='package')"
                  >{{ data.item.text }}</v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel @click="close">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.cancel')}}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn color="info" :loading="loading" @click="save(item)">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">{{$t('base.save')}}</span>
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modalConfig",
  data() {
    return {
      loading: false,
      modalConfig: false,
      item: "",
      type_clients: [],
      type_packages: [],
      monthly_fraction: false,
      clientes: [],
      paquetes: []
    };
  },
  methods: {
    close() {
      this.setFields();
      this.modalConfig = false;
    },
    setFields() {
      (this.item = ""),
        (this.type_clients = []),
        (this.type_packages = []),
        (this.clientes = []),
        (this.paquetes = []),
        this.$validator.reset();
    },
    save(item) {
      this.$validator.validateAll().then(() => {
        if (this.errors.all() == "") {
          let clientes = Object.assign({}, this.clientes);
          let paquetes = Object.assign({}, this.paquetes);
          this.loading = true;
          axios
            .patch(this.$store.getters.configurateFranchise(this.item), {
              fraction: this.monthly_fraction,
              clientes: clientes,
              paquetes: paquetes
            })
            .then(response => {
              this.modalConfig = false;
              this.setFields();
              this.$emit("updateTable");
              this.$store
                .dispatch("pushNotification", {
                  snackbar: true,
                  style: "success",
                  mode: null,
                  timeout: 4000,
                  notifText: "success",
                  notifPos: 2,
                  extra: {
                    model: this.$t("base.configuration"),
                    action: this.$tc("created", 2)
                  }
                })
                .finally(() => {
                  this.$validator.reset();
                  this.loading = false;
                });
            });
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    open(item, data) {
      this.$validator.reset();
      if (data != null) {
        this.monthly_fraction = data.fraction;
        this.clientes = data.clientes;
        this.paquetes = data.paquetes;
      }
      axios
        .get(this.$store.getters.getTypeClient(), {
          params: {
            paginate: "AllFranchise"
          }
        })
        .then(response => {
          response.data.map(type => {
            this.type_clients.push({
              value: type.id,
              text: type.name
            });
          });
        });
      axios
        .get(this.$store.getters.getPackage(), {
          params: {
            paginate: "AllFranchise"
          }
        })
        .then(response => {
          response.data.map(type => {
            this.type_packages.push({
              value: type.id,
              text: type.name
            });
          });
        });
      this.modalConfig = true;
      this.item = item;
    },
    remove(item, action) {
      if (action == "client") {
        const index = this.clientes.indexOf(item);
        if (index >= 0) this.clientes.splice(index, 1);
      } else if (action == "package") {
        const index = this.paquetes.indexOf(item);
        if (index >= 0) this.paquetes.splice(index, 1);
      }
    }
  },
  watch: {
    modalConfig: function(val) {
      document.querySelector("html").style.overflow = val ? "hidden" : null;
    }
  }
};
</script>
