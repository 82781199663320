 export default {
  name: "ActivityDiaLog",
  data() {
    return {
      dialog: false,
      fullscreen: false,
      no_data: false,
      logs: [],
      data: '',
      params: {
        log_name: 'ContractService',
        subject_type: 'ContractService',
        subjects_ids: [0]
      }
    }
  },
  methods:{
    printStatus (status) {
      let text = ''
      switch (status) {
        case "draft":
        case "requested":
        case "activation_in_progress":
        case "trial_period":
        case "voluntary_suspension_in_progress":
        case "suspension_by_cut_in_progress":
          text = `<small class="font-italic font-weight-bold warning--text text--darken-2">${this.$t(`history_log.${status}`)}</small>`
          break;
        case "active":
          text = `<small class="font-italic font-weight-bold success--text text--darken-2">${this.$t(`history_log.${status}`)}</small>`
          break;
        case "retired":
        case "voluntarily_suspended":
        case "suspended_by_cut":
          text = `<small class="font-italic font-weight-bold error--text text--darken-2">${this.$t(`history_log.${status}`)}</small>`
          break;
      }
      return text
    },
    open(item){
      this.dialog = true
      this.no_data = false
      this.data = item
      if (this.data.type == 'service') {
        this.params.subjects_ids.fill(item.id)
      } else {
        this.params.subjects_ids = this.data.services.map(serv => serv.id)
      }
      this.getLogs()
    },
    close() {
      this.dialog = false
      this.fullscreen = false
      this.no_data = false
      this.logs = []
      this.data = ''
      this.params = {
        log_name: 'ContractService',
        subject_type: 'ContractService',
        subjects_ids: [0]
      }
    },
    getLogs () {
      this.params.subjects_ids = JSON.stringify(this.params.subjects_ids)
      axios.get(this.$store.getters.getActivity, {
        params: this.params
      })
        .then((response) => {
          setTimeout(() => {
            this.logs = response.data.logs
            if (this.logs.length == 0) {
              this.no_data = true
            }
          }, 400)
        })
    }
  }
}
