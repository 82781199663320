<template>
  <v-speed-dial fixed bottom right
    v-model="speed_dial"
    direction="top"
    transition="slide-y-reverse-transition"
    :class="loadingRates || showDisabled ? 'cursor-disabled' : ''"
  >
    <!-- Main Float Button -->
    <template v-slot:activator>
      <v-tooltip left
        v-model="show_rate"
      >
        <template v-slot:activator="{  attrs }">
          <v-btn fab dark small
            v-bind="attrs"
            v-model="speed_dial"
            :color="speed_dial ? 'error' : showDisabled ? 'grey' : 'primary'"
            :loading="loadingRates"
          >
            <v-icon v-if="speed_dial">fas fa-times</v-icon>
            <v-icon v-else>fas fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <span>
            {{ selected_rate.symbol }}
            {{ selected_rate.rate | formatAmount(selected_rate.decimal_places) }}
        </span>
      </v-tooltip>
    </template>
    <!-- Percentage -->
    <v-btn fab dark x-small
      v-if="!show_return"
      v-show="showPercentage"
      color="green"
      @click="changeToPercentBtn"
    >
      <v-icon small>fa fa-percent</v-icon>
    </v-btn>
    <!-- Return -->
    <v-btn fab dark x-small
      v-else
      color="green"
      @click="resetValues()"
    >
      <v-icon small>fas fa-undo-alt</v-icon>
    </v-btn>
    <!-- Exchange Rates -->
    <template v-if="!show_return">
      <v-tooltip left
        v-for="item in ex_rates"
        :key="item.id"
      >
        <template v-slot:activator="{ on }">
          <v-btn fab dark x-small
            v-on="on"
            color="primary"
            class="font-weight-bold"
            @click="changeToRate(item)"
          >
            <span class="custom-rate-symbol-size">{{ item.symbol }}</span>
          </v-btn>
        </template>
        <span>{{ item.full_name }}</span>
      </v-tooltip>
    </template>
  </v-speed-dial>
</template>

<style type="text/css">
  .custom-rate-symbol-size {
    font-size: 11px;
    text-transform: none;
  }
</style>

<script>
export default {
  props:{
    showDisabled:{
      default:false,
      type:Boolean
    },
    forceShowReturn:{
      default:false,
      type:Boolean
    },
    showPercentage:{
      default:true,
      type:Boolean
    },
    showCurrencies:{
      default:false,
      type:Boolean
    },
    baseCurrencySymbol:{
      default:'',
      type:String
    }
  },
  data: () => ({
    show_rate: false,
    is_percent: false,
    show_return: false,
    speed_dial: false,
    ex_rates: [],
    selected_rate: {
      rate: 1,
      decimal_places: 2
    },
    loadingRates: false,
  }),
  computed: {
    computed_show_return: {
      get () {
        return this.forceShowReturn
      },
      set (val) {
        this.show_return = val
      }
    }
  },
  methods:{
    getExchangeRates () {
      this.loadingRates = true
      let ops = {
        params: {
          'limit': 7,
          'select_only': true
        }
      }
      axios.get(this.$store.getters.exchangeRates(), ops)
        .then(response => {
          if (response.data && response.data.success) {
            this.ex_rates = response.data.success
          }
        })
        .catch(err=>{
          this.ex_rates = []
        })
        .finally(()=>{
          this.loadingRates = false
        })
    },
    changeToPercentBtn () {
      this.is_percent = !this.is_percent
      this.show_return = !this.show_return
      this.$emit('changeToPercent')
    },
    changeToRate (ex_rate) {
      this.selected_rate = ex_rate
      this.show_return = !this.show_return
      this.$emit('changeToRate', ex_rate.rate, ex_rate.symbol, ex_rate.decimal_places)
    },
    resetValues () {
      if (this.is_percent) {
        this.changeToPercentBtn()
      } else {
        this.changeToRate(1, this.baseCurrencySymbol)
      }
    }
  },
  mounted () {
    if (this.showCurrencies) {
      this.getExchangeRates()
    }
  },
  watch: {
    selected_rate: {
      handler (val) {
        if (val != 1 || (val.rate && val.rate != 1)) {
          this.show_rate = true
        } else {
          this.show_rate = false
        }
      },
      deep: true,
    }
  }
}
</script>
