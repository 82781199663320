export default {
  name: "dialogZone",
  components: {},
  data() {
    return {
      dialogZone: false,
      loadingZone: false,
      disableZone: false,
      fullscreen: false,
      edit:false,
      zone:{
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        extra: {
          description: ''
        }
      },
    }
  },
  computed: {},
  methods: {
    openZone(editMode, item){
      this.edit = editMode;
      this.dialogZone = true;
      if (!editMode) {
        this.newZone();
      } else{
        let Zone = Object.assign({}, item);
        if (!Zone.extra) {
          Zone.extra = {
            description: ''
          }
        }
        this.zone = Zone;
      }
    },
    openModal(){
      this.dialogZone = true;
    },
    closeZone(){
      this.dialogZone = false;
      this.zone = {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        extra: {
          description: ''
        }
      };
      setTimeout(()=> {
        this.$refs.form_zone.reset();
      }, 200);
    },
    newZone(){
      this.disableZone = false;
      this.loadingZone = false;
      this.zone = {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        extra: {
          description: ''
        }
      };
    },
    screen(val){
      this.fullscreen = val;
    },
    getParish(item){
      // eslint-disable-next-line no-console
      // console.log(item);
      if (item != null && item != undefined) {
        this.zone.parish_id = item;
        this.disableZone = false;
      }
    },
    save(){
      this.$refs.form_zone.validate()
      .then((success)=>{
        if (success) {
          this.loadingZone = true;
          let zone = Object.assign({}, this.zone);
          let extra = this.zone.extra;
          zone.extra = JSON.stringify(extra);
          if(this.edit == false){
            this.create(zone);
          } else {
            this.update(zone);
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    create(zone){
      axios.post(this.$store.getters.getZones(), zone)
      .then(response=>{
        const text = this.$tc('zones.zone',0) + ' ' + response.data.zone.name;
        this.$emit('createZone');
        this.pushNoty(text, "created", 2);
        this.closeZone();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      }).finally(()=>{
        this.loadingZone = false;
      });
    },
    update(zone){
      axios.put(this.$store.getters.getZones(zone.id), zone)
      .then(response=>{
        const text = this.$tc('zones.zone',0) + ' ' + response.data.zone.name;
        this.$emit('createZone');
        this.pushNoty(text, "updated", 2);
        this.closeZone();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      }).finally(()=>{
        this.loadingZone = false;
      });
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  mounted() {},
  watch: {}
};
