import BroadcastNotificationForm  from './Form.vue'

export default {
  name: "BroadcastNotificationDialog",
  components:{
    BroadcastNotificationForm
  },
  props: {
    //
  },
  data (){
    return {
      loading: false,
      dialog: false,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    //
  },
  computed:{
    //
  },
  watch: {
    dialog (val) {
      if (val) {
        setTimeout(() => {
          this.$refs.broadcast_notification_form.loadLists()
        }, 70)
      }
    }
  },
  methods:{
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
      if (this.$refs.broadcast_notification_form) {
        setTimeout(() => {
          this.$refs.broadcast_notification_form.clear()
        }, 400)
      }
    },
    sendNotification () {
      this.$refs.broadcast_notification_form.broadcastNotification()
    },
    loadingBtn (val) {
      this.loading = val
    },
  },
  beforeDestroy () {
    this.unwatch()
  }
}
