export default {
  name: "OldBillingHistoryTable",
  props: {
    subscriber_id: Number,
    contract_code: {
      type: [String, Number],
      default: ''
    },
    permissions: {
      type: [Object],
      default: {}
    }
  },
  data (){
    return {
      no_data: false,
      loadingTable: false,
      all_history: [],
      totalValues: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'oldBillingHistory'){
        this.options.page = 1
        this.getHistory()
      }
    })
  },
  mounted () {
    //
  },
  computed:{
    headers(){
      let headers = [
        {
          text: this.$t('billing.invoice_number'),
          value:'invoice_number',
          align: 'left',
        },
        {
          text: this.$t('billing.invoice_date'),
          value:'invoice_date',
          align: 'center',
        },
        {
          text: this.$t('billing.amount'),
          value:'invoice_amount',
          align: 'center',
        }
      ]
      if (this.permissions.edit || this.permissions.delete) {
        headers.push(
          {
            text: this.$t('actions'),
            value:'action',
            align: 'center',
            sortable: false
          }
        )
      }
      return headers
    }
  },
  methods:{
    getHistory () {
      this.all_history = []
      this.loadingTable = true
      this.no_data = false

      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'subscriber_id': this.subscriber_id,
          'code': this.contract_code
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }

      axios.get(this.$store.getters.getOldBillingHistory(), ops)
        .then(response =>{
          this.all_history = response.data.history.data
          this.totalValues = response.data.history.total
          if (this.totalValues == 0) {
            this.no_data = true
          }
        })
        .catch((err)=>{
          // eslint-disable-next-line no-console
          console.log(err)
        }).finally(()=>{
          this.loadingTable = false
        })
    },
    editItem (item) {
      this.$emit('setEditableHistory', item)
      this.$emit('changeMode', 'form', true)
    },
    deleteItem (item) {
      this.$store.dispatch('confirmationValue',{
        template: 'oldBillingHistory',
        show: true,
        model: this.$t('billing.history'),
        title: this.$t('base.delete'),
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record: item.invoice_number
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getOldBillingHistory(item.id),
          method: 'delete',
        },
        pos: 1
      });
    }
  },
  watch: {
    options: {
      handler () {
        this.getHistory()
      },
      deep: true,
    },
  },
  beforeDestroy () {
    this.unwatch()
  }
}
