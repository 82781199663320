export default {
  data(){
    return {
      initialized: false,
      nodata: true,
      totals:[],
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      inPercent: false,
      options: {
        page: 1,
        itemsPerPage: -1
      },
      contract_statuses: [],
      contract_statuses_headers: [],
      unwatch: '',
      policies:{
        view_details: false
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      specific_franchise: [],
      copy_totals:''
    }
  },
  methods:{
    async getTotals (frn_id = 0, frn_name = '') {
      this.totals = []
      this.specific_franchise = []
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
        }
      }
      if (frn_id != 0 && frn_name != '') {
        this.specific_franchise.id = frn_id
        this.specific_franchise.name = frn_name
        ops.params.franchise_id = frn_id
      }

      axios.get(this.$store.getters.filterContractsPerServiceTotals, ops)
        .then(response => {
          this.nodata = false
          let totals = response.data
          let parent
          totals = totals.sort((a, b) => (a.order > b.order) ? 1 : -1)
          totals.map( t => {
            if (t.total == "") {
              parent = t.title;
              t.parent = '';
            } else {
              t.parent = parent;
            }
          })
          this.totals = totals
          this.copy_totals = JSON.stringify(this.totals);
          if (this.inPercent) {
            this.inPercent = false
            this.changeToPercentBtn()
          }
        })
        .catch(err=>{
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
          this.initItemsTable()
        })
    },
    PoliciesFilter(){
      this.policies.view_details = this.getPermissionGroup({
        permission:[
          'filterPerServiceDetails_contract'
        ],
        mode:'all'
      })
    },
    goToDetailedView (by) {
      if (this.specific_franchise.id) {
        let old_by = Object.assign({}, by)
        by = {frn: this.specific_franchise.id}
        if (old_by.pkg) {
          by = {...by, ...old_by}
        }
      }
      if (this.policies.view_details) {
        this.$router.push({
          name: 'filterContractsPerServiceDetails',
          params: {
            arr_filter: by
          }
        })
      }
    },
    dividerTitle (divider_title) {
      let val = ''
      switch (divider_title) {
        case 'divider_serv':
          val = this.$tc('by',2) +' '+ this.$tc('service.service',2)
          break
        case 'divider_pack':
          val = this.$store.getters.isFranchise ?
            this.$tc('by',2) +' '+ this.$tc('service.service',2) :
            this.$tc('by',2) +' '+ this.$tc('package.package',1)
          break
        case 'divider_franchise':
          val = this.$tc('franchise.company',1)
          break
      }
      return val
    },
    getContractStatuses () {
      axios.get(this.$store.getters.getContractStatuses)
        .then(response => {
          if (response.data && response.data.statuses) {
            this.contract_statuses = response.data.statuses
          }
        })
        .catch(err=>{
          this.contract_statuses = []
        })
    },
    changeToPercentBtn(){
      this.inPercent = !this.inPercent;
      if (this.inPercent) {
        this.totals.map(val=>{
          if (typeof(val.total) == 'number') {
            const total = Number(val.total);
            let by_status = val.by_status;
            for (var key in by_status) {
              if (typeof(by_status[key]) == 'number') {
                var percent = ((by_status[key]/total)*100).toFixed(2);
                by_status[key] = percent+'%';
              }
            }
          }
        })
      } else {
        this.totals = JSON.parse(this.copy_totals);
      }
    },
    initItemsTable(){
      let noTr = document.querySelectorAll("table tr b[divider]:not([divider^='section-'])")

      noTr.forEach(element => {
        element.parentNode.parentNode.classList.add('d-none')
      });
    },
    showItemTable(item) {
      var section_divider = document.querySelector(`table tr b[divider='section-${item}']`)
      var btn_item = document.querySelector(`button[btn-item="${item}"] i`)
      let table_items = document.querySelectorAll(`table tr b[divider='${item}']`)
      if (section_divider.getAttribute('open-section') == "true") {
        table_items.forEach(element => {
          element.parentNode.parentNode.classList.add('d-none')
          section_divider.setAttribute('open-section',false)
          btn_item.classList.replace('fa-minus','fa-plus')
        });
      }else {
        table_items.forEach(element => {
          element.parentNode.parentNode.classList.remove('d-none')
          section_divider.setAttribute('open-section',true)
          btn_item.classList.replace('fa-plus','fa-minus')
        });
      }

    }
  },
  watch:{
    options: {
      handler () {
        this.getTotals()
      },
      deep: true,
    }
  },
  computed:{
    computedContractStatuses () {
      this.contract_statuses_headers = this.contract_statuses.map(status => {
        return {
          'text': status.value == 'migrating' ? this.$t('service.'+status.value)+'(M)' : this.$t('service.'+status.value),
          'value': 'by_status.'+status.value,
          'sortable': false ,
          'align': 'center',
          'width': 20
        }
      })
      return this.contract_statuses_headers
    },
    computedTableTitle () {
      if (this.specific_franchise.name) {
        return this.specific_franchise.name
      } else {
        return this.$tc('contracts.contracts',2)+' '+this.$tc('by',2)+' '+this.$tc('service.service',1)
      }
    },
    headers () {
      let headers = [
        {
          text: '',
          value: 'title',
          sortable: false ,
          align: 'left',
        },
        {
          text: this.$i18n.locale === 'es' ? 'Total Gral.' : 'Gen. Total',
          value: 'total',
          sortable: false ,
          align: 'center',
          width: 20
        }
      ];
      if (this.computedContractStatuses.length > 0) {
        headers = [...headers, ...this.computedContractStatuses]
      }
      headers.push(
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          align: 'right'
        }
      )
      return headers
    }
  },
  created () {
    this.getContractStatuses()
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
    this.PoliciesFilter()
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    //
  },
}
