import './axiosDeclared.js';
import emitNotification from './axiosDeclared.js';
import Vuetify from './vuetify';
export default{
  state:{
    status: '',
    completed: false,
    pkUser: localStorage.getItem('id') || '',
    root: localStorage.getItem('wurzel') || null,
    admin: localStorage.getItem('woyang') || null,
    usrName: localStorage.getItem('name') || '',
    mtundu: localStorage.getItem('mtundu') || null,// Chichewa
    nkhokwe: localStorage.getItem('nkhokwe') || null,// Chichewa
    TokenUser: localStorage.getItem('token') || '',
    usrRemember: document.cookie.match(/usrRmmbr\s*=\s*.*?(;|$)/) ?
                 document.cookie.match(/usrRmmbr\s*=\s*.*?(;|$)/)[0].replace(/;$/, '').split('=')[1] : null,
    verified: null,
    company_name: null,
    company_logo: null,
    company_dni: null,
    company_fiscal_address: null,
    policies: localStorage.getItem('policies') ? JSON.parse(localStorage.getItem('policies')) : [],
    franchise: localStorage.getItem('is_anapa') || null,
    franchise_id: localStorage.getItem('anapa') || null,// Kirguís
    franchise_name: null,
    franchise_branch_office: '',
    franchise_admin: localStorage.getItem('anapa_woyang') || null,
    usrSttngs: JSON.parse(localStorage.getItem('zokonda')) || null,// Chichewa
    tmpSttngs: null,// Temporally save settings to send on logout
    extTkn: localStorage.getItem('ext_token') || null,
    corporate_user: localStorage.getItem('corpu') || false,
    routeFrom:'',
  },
  getters:{
    isAuth2: (state) => !!state.TokenUser && state.completed,
    isAuth: (state) => !!state.TokenUser,
    isRouteFrom: (state) => state.routeFrom,
    isCorporateUser: (state) => state.corporate_user,
    authStatus: (state) => state.status,
    dataUser: (state) => {
      let user = {
        'pkUser': Number(state.pkUser),
        'nmUser': state.usrName,
        'stUser': state.usrSttngs
      }
      return user;
    },
    tmpSettings: (state) => state.tmpSttngs,
    rmmbrdUsr: (state) => state.usrRemember,
    isVerified: (state) => state.verified,
    isRoot: (state) => state.root === null ? null : !!state.root,
    isAdmin: (state) => state.admin === null ? null : !!state.admin,
    dbSelected: (state) => state.nkhokwe,
    primaryColor: (state) => state.mtundu,
    companyInfo: (state) => {
      let company_info = {
        'id': state.nkhokwe,
        'name': state.company_name,
        'logo': state.company_logo,
        'dni': state.company_dni,
        'address': state.company_fiscal_address,
      }
      return company_info;
    },
    getPolicies: (state) => {
      return state.policies;
    },
    getPoliciesGroup:(state) => (value) => {
      // Value Structure
      // {
      //   permission: [String,Array],
      //   method:'c' //If a string you should a letter reference to c.r.u.d else the string permission will be take all word
      //   mode: 'all' // if a array you can send  mode ('all','only') all its all elements array permission, only its with only councidence return true
      // }
      let validation = false
      let search = []
      switch (typeof(value.permission)){
        case 'object':
          search = value.permission.map(m => state.policies.includes(m));
          if(value.mode !== undefined && value.mode === 'all'){
            validation = !search.includes(false);
          }
          else{
            validation = search.includes(true);
          }
          break;
        case 'string':
        default:
          if(value.method !== undefined){
            if('crud'.indexOf(value.method) !== '-1'){
              let method = value.method === 'c' ? 'store' : value.method === 'r' ?
                'index' : value.method === 'u' ? 'update' : 'destroy';
              validation = state.policies.indexOf(`${method}_${value.permission}`) !== -1;
            }
            else{
              validation = state.policies.indexOf(`${value.method}_${value.permission}`) !== -1;
            }
          }
          else{
            validation = state.policies.indexOf(`${value.permission}`) !== -1;
          }
          break;
      }
      return validation;
    },
    getFranchiseID: (state) => state.franchise_id,
    getFranchiseName: (state) => state.franchise_name,
    getFranchiseBranchOffice: (state) => state.franchise_branch_office,
    isFranchise: (state) => state.franchise,
    isFranchiseAdmin: (state) => state.franchise_admin,
    getExtToken: (state) => state.extTkn,
  },
  mutations:{
    loginTkn: (state, token) => {
      state.TokenUser = token
      state.status = 'Success'
    },
    loginUser: (state, usr) => {
      state.pkUser = usr.id
      state.usrName = usr.name
    },
    userSettings: (state, settings) => {
      state.usrSttngs = settings
    },
    extToken: (state, extTkn) => {
      state.extTkn = extTkn
      localStorage.setItem('ext_token', extTkn)
    },
    corporateUser:(state)=>{
      let user = false;
      if (!state.root && !state.admin && !state.franchise && !state.franchise_admin) {
        user = true;
      } else {
        user = false;
      }
      localStorage.setItem('corpu',user);
      state.corporate_user = user;
    },
    userRoot: (state, root) => {
      state.root = root
      localStorage.setItem('policies', JSON.stringify([]))
    },
    userAdmin: (state, admin) => {
      state.admin = admin
    },
    storeUser: (state, val) => {
      state.usrRemember = val
    },
    logoutTkn: (state) => {
      state.TokenUser = ''
      state.status = ''
      state.pkUser = ''
    },
    logoutUser: (state) => {
      state.usrName = ''
      state.verified = null
      state.mtundu = null
      state.company_name = null
      state.company_logo = null
    },
    errorToken: (state) => {
      state.status = 'error'
    },
    loadingToken: (state) => {
      state.status = 'loading'
    },
    setCompleted: (state, value) => {
      state.completed = value
    },
    verifiedEmail: (state, value) => {
      state.verified = value
    },
    selectDB: (state, value) => {
      state.nkhokwe = value
    },
    changeColor: (state, value) => {
      state.mtundu = value
    },
    compInfo: (state, comp) => {
      state.company_name = comp.name
      state.company_logo = comp.logo
      state.company_dni = comp.dni
      state.company_fiscal_address = comp.address
    },
    setPolicies: (state, policies) => {
      state.policies = policies
    },
    setFranchiseID: (state, data) => {
      if (data && Number.isInteger(data.franchise_id)) {
        state.franchise_id = data.franchise_id
        state.franchise_name = data.franchise_name
        if (data.franchise_branch_office) {
          state.franchise_branch_office = data.franchise_branch_office
        }
        state.franchise = true
        localStorage.setItem('is_anapa', true)
        localStorage.setItem('anapa', data.franchise_id)
        if (data.franchise_admin) {
          state.franchise_admin = data.franchise_admin
          localStorage.setItem('anapa_woyang', data.franchise_admin)
        }
      } else {
        state.franchise_id = null
        state.franchise_name = null
        state.franchise_branch_office = ''
        state.franchise = null
        state.franchise_admin = null
        localStorage.removeItem('is_anapa')
        localStorage.removeItem('anapa')
        localStorage.removeItem('anapa_woyang')
      }
    },
    tgglSettings: (state, data) => {
      Object.keys(state.usrSttngs).forEach(key => {
        state.usrSttngs[key] = data[key]
        if (key == 'dark_mode') {
          Vuetify.framework.theme.dark = data[key]
        }
      })
      let settings = state.usrSttngs
      state.usrSttngs = null
      state.usrSttngs = settings
      localStorage.setItem('zokonda', JSON.stringify(settings))
    },
    clrSettings: (state) => {
      state.tmpSttngs = state.usrSttngs// Store them temporally to send them in logout
      state.usrSttngs = null
    },
    clrTmpSettings: (state) => {
      state.tmpSttngs = null
    },
    routerFrom: (state, from)=>{
      state.routeFrom = from
    }
  },
  actions:{
    toggleSettings: ({commit}, data) => {
      commit('tgglSettings', data)
    },
    clearSettings: ({commit}) => {
      commit('clrSettings')
    },
    getUserSettings: ({commit, getters}) => {
      axios.get(getters.getUserSettings)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem('zokonda', JSON.stringify(response.data.success))
            commit('userSettings', response.data.success)
          }
        }).catch((error) => {
          //
        })
    },
    rememberUser: ({ commit }, data) => {
      if (data.remember) {
        let tdy = new Date()
        tdy.setTime(tdy.getTime() + (30*24*60*60*1000))// Remember for 30 days
        var expires = 'expires='+ tdy.toUTCString()
        document.cookie = 'usrRmmbr' + '=' + data.email + ';' + expires + '; SameSite=Strict; path=/'
        commit('storeUser', data.email)
      } else {
        document.cookie = "usrRmmbr=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;"
        commit('storeUser', null)
      }
    },
    loginToken: ({ commit, getters, dispatch }, info) => {
      return window.axios
        .post(getters.logInUrl, info.user)
        .then((response) => {
          if (response.data.success) {
            if (Number.isInteger(response.data.success.id) && (response.data.success.name != '') && (response.data.success.token != '')) {
              localStorage.setItem('id', response.data.success.id)
              localStorage.setItem('name', response.data.success.name)
              localStorage.setItem('token', response.data.success.token)
              window.axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
              commit('loginTkn', response.data.success.token)
              commit('loginUser', response.data.success)
              commit('extToken', response.data.success.external_token_corp)
              if (response.data.success.settings) {
                localStorage.setItem('zokonda', JSON.stringify(response.data.success.settings))
                commit('userSettings', response.data.success.settings)
              }
              // Remember cookie
              dispatch('rememberUser', info.user)
              if (response.data.success.wurzel) {// User is Super Admin
                localStorage.setItem('wurzel', response.data.success.wurzel)
                localStorage.setItem('nkhokwe', 0)
                commit('userRoot', response.data.success.wurzel)
                commit('selectDB', 0)
              }
            } else {
              return 'Error'
            }
          }
          return response
        }).catch((error) => {
          commit('errorToken', error)
          localStorage.removeItem('id')
          localStorage.removeItem('name')
          localStorage.removeItem('token')
          localStorage.removeItem('ext_token')
          localStorage.removeItem('is_anapa')
          localStorage.removeItem('anapa')
          localStorage.removeItem('anapa_woyang')
          localStorage.removeItem('wurzel')
          localStorage.removeItem('woyang')
          localStorage.removeItem('nkhokwe')
          localStorage.removeItem('zokonda')
          localStorage.removeItem('policies')
          if (error.response) {
            return error.response
          }
          return error
        })
    },
    logoutToken: ({ commit, getters, dispatch }) => {
      let settings = JSON.stringify(getters.tmpSettings)
      commit('logoutTkn')
      commit('logoutUser')
      commit('clrTmpSettings')
      commit('userRoot', null)
      commit('userAdmin', null)
      commit('selectDB', null)
      commit('setPolicies', [])
      commit('setFranchiseID', null)
      dispatch('setClearNotifications')
      return axios
        .get(getters.logOutUrl, {
          params:{
            settings: settings,
          }
        })
        .then((response) => {
          return response
        }).catch((error) => {
          if (error.response) {
            return error.response
          }
          return error
        })
    },
    /*regisUser: ({ commit,getters }, info) => {
      return window.axios
        .post(getters.regUserUrl, info.user)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem('id', response.data.success.id)
            localStorage.setItem('name', response.data.success.name)
            localStorage.setItem('token', response.data.success.token)
            window.axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            commit('loginTkn', response.data.success.token)
            commit('loginUser', response.data.success)
          }
          return response
        }).catch((error) => {
          if (error.response) {
            return error.response
          }
          return error
        })
    },*/
    updateUser: ({ commit, getters }, info) => {
      return window.axios
        .patch(getters.updateUserUrl, info.user)
        .then((response) => {
          if (response.data.success) {
            if (info.user.name) {
              localStorage.setItem('name', info.user.name)
              commit('loginUser', info.user)
            }
          }
          return response
        }).catch((error) => {
          if (error.response) {
            return error.response
          }
          return error
        })
    },
    resendEmailVerif: ({getters}, data) => {
      let url = data.cmpny && data.id ?
        getters.resendSubscriberEmailUrl(data.cmpny, data.id) :
        getters.resendEmailUrl(localStorage.getItem('id'))
      return window.axios.get(url)
        .then((response) => {
          return response
        }).catch((error) => {
          if (error.response) {
            return error.response
          }
          return error
        })
    },
    setCompleted: ({ commit }, value) => {
      commit('setCompleted', value)
    },
    setVerifiedEmail: ({ commit }, value) => {
      commit('verifiedEmail', value)
    },
    setUserRoot: ({ getters, commit, dispatch }) => {
      axios.get(getters.checkIfRoot)
        .then(function (response) {
          if (response.data) {
            commit('userRoot', response.data)
            localStorage.setItem('wurzel', response.data)
          } else {
            if (getters.isAdmin === null) {
              dispatch('checkUserAdmin')
            }
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    },
    setUserAdmin: ({ commit }, value) => {
      commit('userAdmin', value)
      localStorage.setItem('woyang', value)
    },
    checkUserAdmin: ({ getters, commit, dispatch }) => {
      axios.get(getters.checkIfAdmin)
        .then(function (response) {
          if (response.data) {
            commit('userAdmin', response.data)
            localStorage.setItem('woyang', response.data)
          } else {
            // if (getters.isFranchise === null || getters.getFranchiseID === null || getters.isFranchiseAdmin === null) {
            dispatch('checkUserFranchise')
            // }
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    },
    getUserInfo: ({ getters, commit }) => {
      axios.get(getters.getUserInfo)
        .then(function (response) {
          if (response.data && response.data.success) {
            localStorage.setItem('id', response.data.success.id)
            localStorage.setItem('name', response.data.success.name)
            commit('loginUser', response.data.success)
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    },
    setDBSelected: ({ commit }, value) => {
      commit('selectDB', value)
    },
    setPrimaryColor: ({ commit }, value) => {
      // value = (Number(`0x1${value}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
      commit('changeColor', value)
      Vuetify.framework.theme.themes.light.primary = value
      Vuetify.framework.theme.themes.dark.primary = value
      Vuetify.framework.theme.themes.light.info = value
      Vuetify.framework.theme.themes.dark.info = value
      localStorage.setItem('mtundu', value)
    },
    setCompanyInfo: ({ commit }, value) => {
      commit('compInfo', value)
    },
    searchUserPolicies: async ({ getters, commit }) => {
      commit('corporateUser');
      try {
        let response = await axios.get(getters.getUserPolicies)
        if (response.data && response.data.success) {
          localStorage.setItem('policies', JSON.stringify(response.data.success))
          commit('setPolicies', response.data.success)
        }
      } catch (error) {
        //error
      }
    },
    connFranchise: ({ commit, dispatch }, data) => {
      commit('setFranchiseID', data)
      if (!data.franchise_admin) {
        dispatch('checkUserFranchise')
      }
    },
    checkUserFranchise: ({ getters, commit }) => {
      axios.get(getters.checkIfFranchise)
        .then(function (response) {
          if (response.data.franchise_id) {
            commit('setFranchiseID', response.data)
            commit('extToken', response.data.external_token_franchise)
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    },
    MasterCRUD:({getters}, data)=>{
      let crud = {store:false,update:false,show:false,destroy:false};
      let polices = getters.getPolicies;
      polices.forEach(p=>{
        for (var key in crud) {
          if (p == `${key}_${data}`) {
            crud[key] = true;
          }
        }
      });
      return crud;
    },
    SetRouterFom:({commit}, from)=>{
      commit('routerFrom', from);
    }
  }
}
