<template>
  <v-dialog
    v-model="dialog_send"
    max-width="480px"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <v-card tile class="send-mail">
      <v-toolbar dense tile>
        <v-toolbar-title>
          <span class="subtitle-1 font-weight-bold py-1">
            <template>
              {{ $t('base.send') }}
              {{ is_tax_bill ? $t('file_library.tax_bill') : $t('file_library.invoice_sendable') }}
              {{ $tc('to',2) }}
              {{ $t('paymentNote.subscriber') }}
            </template>
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- O LARGE PREVIEW -->
        <v-col col-preview>
          <span class="previewfile mx-auto" :class="!file.url ? 'no-file' : ''" style="width:400px; height: 500px;">
            <iframe v-if="file.url" :src="file.url" frameborder="0" style="width: 110.5%;height: 120% !important; margin-top: -17px; margin-left: -2px;"></iframe>
          </span>
        </v-col>
        <!-- X LARGE PREVIEW -->
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel :disabled="loading" @click="close_send">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">
            {{$t('base.close')}}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        
        <v-btn save :loading="loading" @click="sendMail">
          <template>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('base.send')}}
            </span>
            <v-icon size="20">fab fa-telegram-plane</v-icon>
          </template>
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SendInvoice",
  data (){
    return {
      dialog_send:false,
      loading:false,
      file:{},
      is_tax_bill: false,
      document_id: 0,
      document_number: null,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'invoice_sendable'){
        this.close_send()
      }
    })
  },
  methods:{
    open(document_id, document_number, isTaxBill){
      this.is_tax_bill = isTaxBill
      this.document_id = document_id
      this.document_number = document_number
      axios.get(this.$store.getters.getDocumentPDF(this.document_id),{
        params: {
          'is_tax_bill': this.is_tax_bill,
          'invoice_sendable': true
        }
      })
        .then((response)=>{
          if (response.data.success) {
            this.file = response.data.success
            this.dialog_send = true
          }
        }).catch((error)=>{
          if (error.response) {
            // eslint-disable-next-line no-console
            console.error(error.response);
          }
        }).finally(()=>{
          this.$emit('stop', isTaxBill)
        });
    },
    sendMail(){
      this.$store.dispatch('confirmationValue',{
        template: 'invoice_sendable',
        show: true,
        model: this.$t('file_library.invoice_sendable'),
        title: this.$t('base.send'),
        action: this.$tc('sent',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.send'),
          record: this.$t('file_library.invoice_sendable')+'_'+this.document_number
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.sendClientDocumentEmail(this.document_id),
          method: 'post',
          data: {
            file_id: this.file.id,
          },
        },
        pos:2
      });
    },
    close_send(){
      this.is_tax_bill = false
      this.document_id = 0
      this.document_number = null
      this.file={}
      this.dialog_send = false
    },
  },
  beforeDestroy () {
    this.unwatch()
  }
}
</script>
