var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":_vm.nodata ? false : true,"headers":_vm.headers,"items":_vm.subscribersGroups,"hide-default-header":_vm.nodata,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.policies.create,"disableNew":_vm.loadingTable,"title":_vm.$t('subscribers.groups'),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newItem}}),_c('CatalogFilter',{attrs:{"showSearch":"","disableFields":_vm.loadingTable},on:{"search":_vm.searchBy,"eraseFilter":_vm.eraseFilter}})]},proxy:true},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format(_vm.$store.getters.getDateFormat[2]))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.policies.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","edit":"","disabled":_vm.loadingTable},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.policies.delete)?_c('v-btn',_vm._g({attrs:{"icon":"","delete":"","disabled":_vm.loadingTable},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.delete')))])])]}}],null,true)})]],2)],1),(_vm.policies.create || _vm.policies.edit)?_c('SubscribersGroupsDialog',{ref:"subscriber_group_dialog",on:{"updateTable":_vm.getGroups}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }