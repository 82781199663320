import Vuetify from './vuetify'
import router from '@/router'
import {i18n} from '@/plugins/locale.js'

export default {
  state:{
    mini: false,
    dialogin: false,
    diarecov: false,
    diacompany: false,
    date_format: null,
    base_currency: null,
    maintenance_mode: localStorage.getItem('zilibe') || false// Chichewa
  },
  getters:{
    getMiniTooltip: (state) => {
      return state.mini
    },
    getDialogin: (state) => {
      return state.dialogin
    },
    getDiarecov: (state) => {
      return state.diarecov
    },
    getDiaCompany: (state) => {
      return state.diacompany
    },
    getDateFormat: (state) => {
      return state.date_format
    },
    getBaseCurrency: (state) => {
      return state.base_currency
    },
    is503: (state) => {
      return state.maintenance_mode
    }
  },
  mutations:{
    miniTooltips(state,minitool){
      state.mini = minitool
    },
    diaLogin(state,login){
      state.dialogin = login
    },
    diaRecov(state,login){
      state.diarecov = login
    },
    diaCmpny(state,comp){
      state.diacompany = comp
    },
    chngDateFormat(state,format){
      state.date_format = format
    },
    chngBaseCurrency(state,currency){
      state.base_currency = currency
    },
    chng503(state, mode){
      let old = state.maintenance_mode
      state.maintenance_mode = mode
      if (old != mode) {
        if (mode) {
          localStorage.setItem('zilibe', true)
          if (router.currentRoute.name != '503') {
            router.push({ name: '503' })
          }
        } else {
          localStorage.removeItem('zilibe')
          if (router.currentRoute.name != 'home') {
            if (router.currentRoute.name != 'about') {
              router.push({ name: 'home' })
            }
          }
        }
      }
    }
  },
  actions:{
    miniTooltips({commit},minitool){
      commit('miniTooltips',minitool)
    },
    diaLogin({commit},login){
      commit('diaLogin',login)
    },
    diaRecov({commit},login){
      commit('diaRecov',login)
    },
    diaCompany({commit},comp){
      commit('diaCmpny',comp)
    },
    closediarecov({commit}){
      commit('diaRecov',false)
    },
    closedialogin({commit}){
      commit('diaLogin',false)
    },
    changeDateFormat({commit}){
      if (i18n.locale == 'es') {
        commit('chngDateFormat', ['DD/MM/YYYY', 'dddd, DD [de] MMMM [de] YYYY', 'DD/MM/YY hh:mm a', 'DD/MM/YY'])
      } else {
        commit('chngDateFormat', ['MM/DD/YYYY', 'dddd, MMMM Do, YYYY', 'MM/DD/YY H:mm', 'MM/DD/YY'])
      }
    },
    baseCurrency({getters, commit}){
      let currency = {}
      return axios
        .get(getters.catalogByCategory('base_currency'))
        .then(function (response) {
          if (response.data.success) {
            currency[response.data.success[0].name] = response.data.success[0].description
            commit('chngBaseCurrency', currency)
            // localStorage.setItem('ndalama', JSON.stringify(currency))
            return response
          }
        })
        .catch(function (err) {
          commit('chngBaseCurrency', false)
          return false
        })
    },
    changeCurrency({commit}, new_currency){
      commit('chngBaseCurrency', new_currency)
    },
    check503({getters, commit}){
      axios.get(getters.get503Status)
        .then((response) => {
          if (response.status == 200) {
            commit('chng503', false)
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          // console.log(error);
        })
    },
    change503({commit}, mode){
      commit('chng503', mode)
    },
  }
}
