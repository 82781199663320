//
import PreView from '../../components/HistoryBills/preview.vue';
import EditDoc from '../../components/HistoryBills/editDoc.vue';
export default {
  name: "RequestFranchise",
  components:{
    PreView,
    EditDoc,
  },
  data (){
    return {
      edit: false,
      its_edit:false,
      expand: false,
      dialog1: false,
      loading: false,
      is_filter: false,
      fullscreen:false,
      loadingTable:false,
      prospects_only:false,
      loadingSubscriber:false,
      its_filter_subscriber:false,
      status_contract:null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      totalValues:0,
      contracts:[],
      filter: '',
      unwatch: '',
      step : 'tab-1',
      policies:{
        show:false,
        edit:false,
      }
    };
  },
  computed: {
    headers(){
      let headers = [
        {
          text: this.$tc('base.code',3),
          align: 'left',
          value: 'id',
          width: 100
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'doc',
          width: 140,
          sortable: false ,
        },
        {
          text: this.$t('subscribers.legal_denomination'),
          value: 'legal_denomination',
          sortable: false ,
        },
        {
          text: this.$t('Taxes.lastUpdate'),
          value: 'updated_at',
          align: 'center'
        },
      ];
      if (this.policies.show || this.policies.edit) {
        headers.push(
          {
            text: this.policies.show && this.policies.edit? this.$t('actions'):this.$t('action'),
            value: 'action',
            sortable: false,
            align: 'center'
          }
        );
      }
      return headers;
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.getContracts();
      }
    });
  },
  beforeDestroy() {
    this.unwatch()
  },
  watch: {
    options: {
      handler () {
        this.getContracts(this.expand);
      },
      deep: true,
    },
    step(){
      this.PoliciesDocumentRequest();
      this.getContracts();
    },
  },
  methods:{
    getContracts(){
      let ops = {
        params:{
          page: this.options.page,
          paginate: this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
        }
      };
      if (this.step =='tab-1') {
        // Cotizacion - Aceptada - Enviada // Contrado - En espera de generacion de nota de cobro
        ops.params.contract_status = 'generate_charge_note';
        ops.params.document_type   = 'payment_note';
        ops.params.document_status = 'pending';
        ops.params.was_send = 0;
      } else if (this.step =='tab-2') {
        // Nota de Cobro - Pagada - No enviada // Contrado - Esperando instalacion
        ops.params.contract_status = 'awaiting_installation';
        ops.params.document_type   = 'payment_note';
        ops.params.document_status = 'paid';
        // ops.params.was_send = 1;
      }
      // eslint-disable-next-line no-console
      // console.log('ops',typeof(ops.params.was_send));
      this.contracts = [];
      this.loadingTable = true;
      axios.get(this.$store.getters.getContractsByParameters,ops)
      .then(response => {
        // eslint-disable-next-line no-console
        // console.log('Contratos',response.data.contracts.data);
        response.data.contracts.data.map(contract=>{
          // eslint-disable-next-line no-console
          // console.log(contract);
          contract.doc = contract.subscriber.doc;
          contract.legal_denomination = contract.subscriber.legal_denomination;
        });
        this.contracts = response.data.contracts.data;
        this.totalValues = response.data.contracts.total;
      })
      .catch((error)=>{
        // eslint-disable-next-line no-console
        // console.error('Error: ',error.response);
      })
      .finally(() => {
        this.loadingTable = false;
      });
    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      // console.log('Contract',item);
      let status_doc = null;
      this.status_contract = item.status;
      let data1 = {
        contract_id: item.id,
        type:'',
        status:'',
      };
      if (this.step =='tab-1') {
        data1.type='payment_note';
        data1.status='pending';
        status_doc = false;
        // eslint-disable-next-line no-console
        // console.log('data', data1);
        this.DocumentBuild(data1, status_doc);
      } else if (this.step =='tab-2') {
        data1.type='invoice';
        data1.status='paid';
        status_doc = true;
        this.DocumentContract(data1, status_doc);
      }
    },
    DocumentBuild(data1, status_doc){
      axios.post(this.$store.getters.getDocumentBuildFranchise,data1)
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('build:',response.data);
        // Le pasamos el simbolo al modal para evitar una consulta
        response.data.symbol = this.symbol;
        response.data.status_contract = this.status_contract;
        if (this.its_edit) {
          this.$refs.edit_doc.open(response.data, true);
        } else {
          this.$refs.pre_view.open(response.data, status_doc);
        }
      })
      .catch((error)=>{
        this.dialog1 = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        } else {
          // eslint-disable-next-line no-console
          // console.error('Error',error.message);
        }
      });
    },
    DocumentContract(data1, status_doc){
      axios.post(this.$store.getters.getClientDocumentContract,data1)
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('doc:',response.data);
          // Le pasamos el simbolo al modal para evitar una consulta
          response.data.symbol = this.symbol;
          response.data.status_contract = this.status_contract;
          this.$refs.pre_view.open(response.data, status_doc);
      })
      .catch((error)=>{
        this.dialog1 = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        } else {
          // eslint-disable-next-line no-console
          // console.error('Error',error.message);
        }
      });
    },
    editDocContrat(item){
      // eslint-disable-next-line no-console
      // console.log('pk', item);
      this.its_edit = true;
      this.getClientDocument(item);
    },
    close(){
      this.its_edit = false;
    },
    accept(item){
      // eslint-disable-next-line no-console
      // console.log('Contract',item);
      let data1 = {
        contract_id: item.id,
        type: 'payment_note'
      };
      // eslint-disable-next-line no-console
      // console.log('Data', data1);
      // axios.post(this.$store.getters.getDocumentBuild, build)
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('paymentNote.name'),
        title: this.$t('generate'),
        action: this.$t('generate'),
        content: this.$t('base.confirm_delete',{
          action: this.$t('generate'),
          record: this.$t('paymentNote.name')
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getDocumentBuild,
          method: 'post',
          data: data1,
        }
      });
    },
    PoliciesDocumentRequest(){
      let policies_show = [
        'invoiceQueries_clientDocument',
        'makeClientDocumentPDF_clientDocument',
      ];
      let policies_edit = [
        'getFranchiseClientDocument_clientDocument',
        'invoiceQueries_clientDocument',
        'index_conceptMotive',
        'update_clientDocument',
      ];
      if (this.step=='tab-1') {
        policies_show = [...policies_show, 'getFranchiseClientDocument_clientDocument','index_employee','store_proofPayment',];
        this.policies.edit = this.getPermissionGroup({
          permission:policies_edit,
          mode:'all'
        });
      }
      if (this.step=='tab-2') {
        policies_show = [...policies_show, 'getClientDocumentContract_clientDocument',];
        this.policies.edit = false;
      }
      this.policies.show = this.getPermissionGroup({
        permission:policies_show,
        mode:'all'
      });
    },
  },
  mounted() {
    this.PoliciesDocumentRequest();
  },
};
