var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('franchise.active', 2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-check-double")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('franchise.inactive', 2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-ban")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-data-table',{staticClass:"elevation-0 pt-10",attrs:{"headers":_vm.headers,"items":_vm.companies,"C:mobile-breakpoint":"0","options":_vm.options,"footer-props":{
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': _vm.$t('perPage')
              },"server-items-length":_vm.totalValues,"loading":_vm.loadingFranchiseTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.step == 'tab-1',"disableNew":_vm.loadingFranchiseTable || _vm.loading,"title":_vm.step == 'tab-1'
                      ? _vm.titleActiveFranchises
                      : _vm.titleInactiveFranchises,"nameButton":_vm.$tc('base.new', 2)},on:{"newItem":function($event){return _vm.newFranchise('edit')}}})]},proxy:true},{key:"item.company_owned",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(item.company_owned ? _vm.$tc("yes", 0) : _vm.$tc("no", 0))+" ")])]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.step == 'tab-1')?_c('v-btn',_vm._g({attrs:{"icon":"","color":"gray"},on:{"click":function($event){return _vm.configClientService(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-cogs")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.configuration")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.step == 'tab-1')?_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editFranchise(item, 'preview')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("preview")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.step == 'tab-1')?_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editFranchise(item, 'edit')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.step == 'tab-1')?_c('v-btn',_vm._g({attrs:{"icon":"","delete":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("deactivate")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.step == 'tab-2')?_c('v-btn',_vm._g({attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.activeItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-recycle")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activate")))])])]}}],null,true)})],1)],1)]],2)],1),_c('modalConfig',{ref:"modalConfig",on:{"updateTable":function($event){return _vm.initialize()}}}),_c('FranchiseDialog',{ref:"franchiseDialog",on:{"update":_vm.initialize,"create":_vm.initialize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }