import ChildForms from './AddressContactForm.vue'
import servicesin from '@/views/Package/ServicesIn.vue'
import InspectionConfirm from './Confirmations/FranchiseInspection.vue'

export default {
  components:{
    ChildForms,
    servicein: servicesin,
    InspectionConfirm
  },
  data(){
    return {
      e1: 1,
      dni_type: 3,
      prefixes: [],
      client_types: [],
      genders: ['F', 'M'],
      items_country: [],  // List Countries
      items_state: [],    // List States
      subscriber_exist_checked: null,
      subscriber_exist_obj: {},
      subscribers: {},
      empty_subscribers: {
        avatar: '',
        type_client_id: '',
        prefix: '',
        dni: '',
        legal_denomination: '',
        firm_name: '',
        first_name: '',
        last_name: '',
        gender: '',
        birthdate: '',
        agent_ret: false,
        tax_rate: '',
        tax_sale: '',
        observations: ''
      },
      current_ndx: 0,           // Subscriber ID
      current_cntrct: 0,        // Contract ID
      current_tech_address: 0,  // Tech Address ID
      current_tech_contact: 0,  // Tech Contact ID
      current_admin_address: 0, // Admin. Address ID
      current_admin_contact: 0, // Admin. Contact ID
      current_legal_address: 0, // Legal Address ID
      current_legal_contact: 0, // Legal Contact ID
      is_firm: false,
      requests:[],
      filter: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalValues:0,
      totalValServ:0,
      dialog: false,
      loading: false,
      loadingTable: false,
      edit: false,
      max_length: 0,
      is_disabled: true,
      expand: false,
      date_menu: false,
      years_18_ago: new Date(Date.now() - 567648000000).toISOString().substr(0, 10),
      av_image: '',
      // Edit states
      edit1: false,
      editable_subscriber: {},
      edit2_a: false,
      edit2_b: false,
      editable_tech_address: {},
      editable_tech_contact: {},
      edit3_a: false,
      edit3_b: false,
      editable_admin_address: {},
      editable_admin_contact: {},
      edit4_a: false,
      edit4_b: false,
      editable_legal_address: {},
      editable_legal_contact: {},
      edit5: false,
      // Selects Loadings
      loadingSelTypeCl: false,
      loadingSelPrefix: false,
      // Current Lists of Municips. & Parishes
      tech_list_municipalities: [],  // Tech
      tech_list_parishes: [],        // Tech
      tech_list_sectors: [],         // Tech
      admin_list_municipalities: [], // Admin.
      admin_list_parishes: [],       // Admin.
      admin_list_sectors: [],        // Admin.
      legal_list_municipalities: [], // Legal
      legal_list_parishes: [],       // Legal
      legal_list_sectors: [],        // Legal
      object_reset: {},
      new_req_confirm: false,
      confirmLoading: false,
      tab: 'request',
      empty_contact: {
        first_name: '',
        last_name: '',
        prefix: '',
        dni: '',
        position: '',
        email: '',
        local_phone: '',
        cellphone: ''
      },
      franchise_contact: {},
      touched: false,
      discarding_item: null,
      limit_request: false,
      policies:{
        show:false,
        create:false,
        edit:false,
      },
      iva_items: process.env.VUE_APP_IVA_ITEMS,
      islr_items: process.env.VUE_APP_ISLR_ITEMS,
      loadingContactInfo: false,
    };
  },
  methods:{
    setTotalValues(evt){
      this.totalValServ = evt
    },
    search(data){
      this.filter = data;
      this.options.page = 1
      this.getRequests(true)
    },
    arrowBack(){
      this.filter = ''
      this.options.page = 1
      this.getRequests(false)
    },
    newRequest(){
      this.dialog = true;
      this.e1 == 1;
    },
    changeStep(operator){
      switch (operator) {
        case '+':
          if (this.e1 == 1) {
            // eslint-disable-next-line no-console
            // console.log('aqui toy');
            this.$refs.subscriber_form.validateForm();
          } else if (this.e1 == 2) {
            this.$validator.validate('tech_info.*')
              .then(valid => {
                if (valid) {
                  this.edit2_a && this.current_tech_address != 0 ?
                    this.updateAddress('tech') : this.saveAddress('tech')
                }
              })
              .catch(error => {
                  //
              })
          } else if (this.e1 == 3) {
            this.$validator.validate('admin_info.*')
              .then(valid => {
                if (valid) {
                  this.edit3_a && this.current_admin_address != 0 ?
                    this.updateAddress('admin') : this.saveAddress('admin')
                }
              })
              .catch(error => {
                  //
              })
          } else if (this.e1 == 4) {
            this.$validator.validate('legal_info.*')
              .then(valid => {
                if (valid) {
                  this.edit4_a && this.current_legal_address != 0 ?
                    this.updateAddress('legal') : this.saveAddress('legal')
                }
              })
              .catch(error => {
                  //
              })
          } else {
            this.e1 = Number(this.e1) + Number(1)
          }
          break
        case '-':
          if (this.e1 == 2 && !this.edit1) {
            this.edit1 = true;
          }
          this.$validator.reset()
          this.e1 = Number(this.e1) - Number(1)
          break
      }
    },
    finish(){
      if (!this.$store.getters.isFranchise) {
        this.$store.dispatch('confirmationValue',{
          template: 'requests',
          show: true,
          model: this.$tc('requests.requests',1),
          title: this.$t('base.complete'),
          action: this.$tc('completed',2),
          content: this.$t('base.confirm_completed'),
          type: 'info',
          confirm:{
            url: this.$store.getters.getContracts(this.current_cntrct),
            method: 'patch',
            data: {
              'status': 'service_request',
              'update_serv_status': 'requested'
            }
          },
          pos: 2
        })
      } else {
        // Open Inspection confirmation dialog
        this.$refs.inspection_confirm.open()
      }
    },
    createNewSector (parish_id, name) {
      if (typeof name === 'string') {
        return axios.post(this.$store.getters.getSectors(), {
          'parish_id': parish_id,
          'name': name
        })
      } else {
        return false
      }
    },
    async saveAddress (type) {
      this.loading = true
      let clone = this.switchAddressOrContact(type, 'address')
      Object.keys(clone).forEach(key => {
        clone[key] === '' ? delete clone[key] : ''
      })
      clone.contract_id = this.current_cntrct
      clone.type = type
      if (clone.sector_id.id) {
        clone.sector_id = clone.sector_id.id
      } else {
        let response = await this.createNewSector(clone.parish_id, clone.sector_id)
        if (response.data && response.data.sector && response.data.sector.id) {
          clone.sector_id = response.data.sector.id
        }
      }
      axios.post(this.$store.getters.getContractAddresses(), clone)
        .then(response => {
          if (response.data.success) {
            this.touched = true
            switch (this.e1) {
              case 2:
                this.current_tech_address = response.data.success.id
                this.$refs.childFormsTech._data.scope_address.sector_id = clone.sector_id
                this.editable_tech_address = Object.assign({}, this.$refs.childFormsTech._data.scope_address)
                this.tech_list_municipalities = this.$refs.childFormsTech._data.items_municipality
                this.tech_list_parishes = this.$refs.childFormsTech._data.items_parish
                this.tech_list_sectors = this.$refs.childFormsTech._data.items_sector
                this.edit2_a = true
                this.edit2_b ? this.updateContact(type) : this.saveContact(type)
                break
              case 3:
                this.current_admin_address = response.data.success.id
                this.$refs.childFormsAdmin._data.scope_address.sector_id = clone.sector_id
                this.editable_admin_address = Object.assign({}, this.$refs.childFormsAdmin._data.scope_address)
                this.admin_list_municipalities = this.$refs.childFormsAdmin._data.items_municipality
                this.admin_list_parishes = this.$refs.childFormsAdmin._data.items_parish
                this.admin_list_sectors = this.$refs.childFormsAdmin._data.items_sector
                this.edit3_a = true
                this.edit3_b ? this.updateContact(type) : this.saveContact(type)
                break
              case 4:
                this.current_legal_address = response.data.success.id
                this.$refs.childFormsLegal._data.scope_address.sector_id = clone.sector_id
                this.editable_legal_address = Object.assign({}, this.$refs.childFormsLegal._data.scope_address)
                this.legal_list_municipalities = this.$refs.childFormsLegal._data.items_municipality
                this.legal_list_parishes = this.$refs.childFormsLegal._data.items_parish
                this.legal_list_sectors = this.$refs.childFormsLegal._data.items_sector
                this.edit4_a = true
                this.edit4_b ? this.updateContact(type) : this.saveContact(type)
                break
            }
          } else {
            this.emitNotification('warning', 6000, 'base.something_wrong')
          }
        })
        .catch(err => {
          this.loading = false
          if(err.response && err.response.data && err.response.data.error && err.response.data.error.type){
            this.emitNotification('error', 6000, 'error422.'+err.response.data.error.type, this.$tc('subscribers.address'))
          }
        })
    },
    async updateAddress (type) {
      this.loading = true
      let flag = 0
      let address_id
      let action = false
      let clone = this.switchAddressOrContact(type, 'address')
      let compare = {}
      switch (type) {
        case 'tech':
          compare = Object.assign({}, this.editable_tech_address)
          address_id = this.current_tech_address
          !this.edit2_b && this.current_tech_contact == 0 ?
            action = true : ''
          break
        case 'admin':
          compare = Object.assign({}, this.editable_admin_address)
          address_id = this.current_admin_address
          !this.edit3_b && this.current_admin_contact == 0 ?
            action = true : ''
          break
        case 'legal':
          compare = Object.assign({}, this.editable_legal_address)
          address_id = this.current_legal_address
          !this.edit4_b && this.current_legal_contact == 0 ?
            action = true : ''
          break
      }
      for (var key in clone) {
        if (key == 'sector_id') {
          let current_sector_id
          compare[key].id ? current_sector_id = compare[key].id : current_sector_id = compare[key]
          if (clone[key].id != current_sector_id) {
            flag++
            break
          }
        } else if (clone[key] != compare[key]) {
          flag++
          break
        }
      }
      if (flag == 0 || this.current_ndx == 0) {
        if (action) {
          this.saveContact(type)
        } else {
          this.updateContact(type)
        }
      } else {
        delete clone.updated_at
        delete clone.geo_location
        if (clone.sector_id.id) {
          clone.sector_id = clone.sector_id.id
        } else {
          let response = await this.createNewSector(clone.parish_id, clone.sector_id)
          if (response.data && response.data.sector && response.data.sector.id) {
            clone.sector_id = response.data.sector.id
          }
        }
        axios.patch(this.$store.getters.getContractAddresses(address_id), clone)
          .then(response => {
            if (response.data.success) {
              this.touched = true
              switch (this.e1) {
                case 2:
                  this.editable_tech_address = Object.assign({}, this.$refs.childFormsTech._data.scope_address)
                  this.tech_list_municipalities = this.$refs.childFormsTech._data.items_municipality
                  this.tech_list_parishes = this.$refs.childFormsTech._data.items_parish
                  this.tech_list_sectors = this.$refs.childFormsTech._data.items_sector
                  break
                case 3:
                  this.editable_admin_address = Object.assign({}, this.$refs.childFormsAdmin._data.scope_address)
                  this.admin_list_municipalities = this.$refs.childFormsAdmin._data.items_municipality
                  this.admin_list_parishes = this.$refs.childFormsAdmin._data.items_parish
                  this.admin_list_sectors = this.$refs.childFormsAdmin._data.items_sector
                  break
                case 4:
                  this.editable_legal_address = Object.assign({}, this.$refs.childFormsLegal._data.scope_address)
                  this.legal_list_municipalities = this.$refs.childFormsLegal._data.items_municipality
                  this.legal_list_parishes = this.$refs.childFormsLegal._data.items_parish
                  this.legal_list_sectors = this.$refs.childFormsLegal._data.items_sector
                  break
              }
              this.loading = false
              this.emitNotification('success', 6000, 'success',
                {
                  model: this.$tc('subscribers.address',2),
                  action: this.$tc('updated',2)
                }, 2
              )
              this.updateContact(type)
            }
          })
          .catch(err => {
            //
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    saveFranchiseContact (signal) {
      let id = this.editable_tech_contact.id ? this.editable_tech_contact.id : null
      let method = !id ? 'post' : 'patch'
      let first_name = ''
      let last_name = ''
      if (this.subscribers.first_name && this.subscribers.last_name) {
        first_name = this.subscribers.first_name.toUpperCase()
        last_name = this.subscribers.last_name.toUpperCase()
      } else {
        first_name = this.subscribers.legal_denomination.toUpperCase()
        last_name = '...'
      }
      // eslint-disable-next-line no-console
      // console.log(first_name, last_name);
      let local_phone = this.franchise_contact.local_phone != '' ?
        this.franchise_contact.local_phone : null
      let send_request = true
      let compare = {}
      let payload = {}
      if (!id) {
        payload = {
          'contract_id': this.current_cntrct,
          'type': 'tech',
          'first_name': first_name,
          'last_name': last_name,
          'prefix': this.subscribers.prefix,
          'dni': this.subscribers.dni,
          'position': 'default',
          'email': this.franchise_contact.email,
          'local_phone': local_phone,
          'cellphone': this.franchise_contact.cellphone
        }
      } else {
        compare = {
          'first_name': first_name,
          'last_name': last_name,
          'prefix': this.subscribers.prefix,
          'dni': this.subscribers.dni,
          'email': this.franchise_contact.email,
          'local_phone': local_phone,
          'cellphone': this.franchise_contact.cellphone
        }
        let flag = 0
        Object.keys(compare).forEach(key => {
          if (compare[key] != this.editable_tech_contact[key]) {
            flag++
            if (key == 'dni') {
              payload['prefix'] = this.subscribers.prefix
            }
            payload[key] = compare[key]
          }
        })
        flag == 0 ? send_request = false : send_request = true
      }
      // eslint-disable-next-line no-console
      // console.log('payload',payload);
      // eslint-disable-next-line no-console
      // console.log('compare',compare);
      if (send_request) {
        this.loading = true
        axios({
          method: method,
          url: this.$store.getters.getContractContacts(id),
          data: payload
        })
          .then(response => {
            if (response.data.success) {
              this.touched = true
              Object.keys(payload).forEach(key => {
                this.editable_tech_contact[key] = payload[key]
              })
              if (response.data.success.id) {
                this.editable_tech_contact.id = response.data.success.id
                this.current_tech_contact = response.data.success.id
              }
              this.e1 = Number(this.e1) + Number(1)
              !id ? this.emitNotification('success', 6000, 'success',
                    {
                      model: this.$tc('subscribers.subscribers',1),
                      action: this.$tc('created',1)
                    }, 1
                  ):
                  this.emitNotification('success', 6000, 'success',
                    {
                      model: this.$tc('subscribers.subscribers',1),
                      action: this.$tc('updated',1)
                    }, 1
                  )
            }
          })
          .catch(err => {
            //
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        if (signal) {
          // this.emitNotification('success', 6000, 'success',
          //   {
          //     model: this.$tc('subscribers.subscribers',1),
          //     action: this.$tc('updated',1)
          //   }, 1
          // )
        }
        this.e1 = Number(this.e1) + Number(1)
      }
    },
    saveContact (type) {
      if (this.$store.getters.isFranchise) {
        this.e1 = Number(this.e1) + Number(1)
        this.loading = false
        return false
      }
      this.loading = true
      let clone = this.switchAddressOrContact(type, 'contact')
      Object.keys(clone).forEach(key => {
        clone[key] === '' ? delete clone[key] : ''
      })
      clone.contract_id = this.current_cntrct
      clone.type = type
      axios.post(this.$store.getters.getContractContacts(), clone)
        .then(response => {
          if (response.data.success) {
            this.touched = true
            switch (this.e1) {
              case 2:
                this.current_tech_contact = response.data.success.id
                this.editable_tech_contact = Object.assign({}, this.$refs.childFormsTech._data.scope_contact)
                this.edit2_b = true
                this.e1 = 3
                break
              case 3:
                this.current_admin_contact = response.data.success.id
                this.editable_admin_contact = Object.assign({}, this.$refs.childFormsAdmin._data.scope_contact)
                this.edit3_b = true
                this.e1 = 4
                break
              case 4:
                this.current_legal_contact = response.data.success.id
                this.editable_legal_contact = Object.assign({}, this.$refs.childFormsLegal._data.scope_contact)
                this.edit4_b = true
                this.e1 = 5
                break
            }
          } else {
            this.emitNotification('warning', 6000, 'base.something_wrong')
          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.error && err.response.data.error.type){
            this.emitNotification('error', 6000, 'error422.'+err.response.data.error.type, this.$tc('subscribers.contact'))
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateContact (type) {
      this.loading = true
      let flag = 0
      let contact_id
      let clone = this.switchAddressOrContact(type, 'contact')
      let compare = {}
      switch (type) {
        case 'tech':
          compare = Object.assign({}, this.editable_tech_contact)
          contact_id = this.current_tech_contact
          break
        case 'admin':
          compare = Object.assign({}, this.editable_admin_contact)
          contact_id = this.current_admin_contact
          break
        case 'legal':
          compare = Object.assign({}, this.editable_legal_contact)
          contact_id = this.current_legal_contact
          break
      }
      for (var key in clone) {
        if (clone[key] != compare[key]) {
          flag++
          break
        }
      }
      if (flag == 0 || this.current_ndx == 0) {
        this.loading = false
        this.e1 = Number(this.e1) + Number(1)
      } else {
        axios.patch(this.$store.getters.getContractContacts(contact_id), clone)
          .then(response => {
            if (response.data.success) {
              this.touched = true
              switch (this.e1) {
                case 2:
                  this.editable_tech_contact = Object.assign({}, this.$refs.childFormsTech._data.scope_contact)
                  break
                case 3:
                  this.editable_admin_contact = Object.assign({}, this.$refs.childFormsAdmin._data.scope_contact)
                  break
                case 4:
                  this.editable_legal_contact = Object.assign({}, this.$refs.childFormsLegal._data.scope_contact)
                  break
              }
              this.loading = false
              this.emitNotification('success', 6000, 'success',
                {
                  model: this.$tc('subscribers.contact',1),
                  action: this.$tc('updated',1)
                }, 1
              )
              this.e1 = Number(this.e1) + Number(1)
            }/* else {
              this.emitNotification('warning', 6000, 'base.something_wrong')
            }*/
          })
          .catch(err => {
            //
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    switchAddressOrContact (type1, type2) {
      let clone_address = {}
      let clone_contact = {}
      switch (type1) {
        case 'tech':
          clone_address = Object.assign({}, this.$refs.childFormsTech._data.scope_address)
          clone_contact = Object.assign({}, this.$refs.childFormsTech._data.scope_contact)
          break
        case 'admin':
          clone_address = Object.assign({}, this.$refs.childFormsAdmin._data.scope_address)
          clone_contact = Object.assign({}, this.$refs.childFormsAdmin._data.scope_contact)
          break
        case 'legal':
          clone_address = Object.assign({}, this.$refs.childFormsLegal._data.scope_address)
          clone_contact = Object.assign({}, this.$refs.childFormsLegal._data.scope_contact)
          break
        default:
          this.emitNotification('error', 6000, 'base.something_wrong')
          return false
      }
      if (type2 == 'address') {
        return clone_address
      } else {
        return clone_contact
      }
    },
    requestDetails (id) {
      const crypto = require('crypto');
      let usr = this.$store.getters.dataUser.pkUser
      let length = usr.toString().length
      let rndm1 = crypto.randomBytes(8).toString('hex')
      let rndm2 = rndm1.slice(0, -Number(length)) + usr
      let keyIv = this.$CryptoJS.enc.Hex.parse(rndm1 + rndm2)
      let encrypted = this.$CryptoJS.AES.encrypt(id.toString(), keyIv, {
        iv: keyIv,
        padding:this.$CryptoJS.pad.ZeroPadding
      }).toString()
      let path = rndm1 + encrypted
      this.$router.push({ path: `/request/${path}` })
    },
    async getRequests(is_filter){
      this.requests = []
      this.totalValues = 0
      this.loadingTable = true
      let ops = {
        params: {
          'mode': this.tab,
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'tbl': 'n',// Sent to indicate is to populate table
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        ops.params.filter = this.filter;
      }

      axios.get(this.$store.getters.getContracts(), ops)
        .then(response => {
          this.totalValues = response.data.success.total
          this.requests = response.data.success.data
        })
        .catch(err=>{
          //
        })
        .finally(()=>{
          this.loadingTable = false
          this.limit_request = false
        })
    },
    /*getSubscriberTypes(){
      this.loadingSelTypeCl = true
      axios.get(this.$store.getters.getTypeClient(),{
          params: {
            'paginate': 100,
            'page': 1,
            'select_only': true,
            'orderBy': 'name',
            'dirDesc': false,
            'active_only': true
          }
        })
        .then(response => {
          this.loadingSelTypeCl = false
          this.client_types = response.data.typeclient.data
        })
        .catch(err => {
          this.loadingSelTypeCl = false
          this.client_types = []
        })
    },*/
    close(){
      if (this.current_ndx != 0 && !this.edit) {
        this.emitNotification('warning', 6000, 'subscribers.incomplete_request')
      } else {
        this.setClear()
      }
    },
    minimize(){
      if (this.edit) {
        this.dialog = false
        this.setClear()
      } else {
        this.dialog = false
      }
    },
    setClear(){
      this.e1 = 1
      this.dropImg()
      this.edit = false
      this.edit1 = false
      this.edit2_a = false
      this.edit2_b = false
      this.edit3_a = false
      this.edit3_b = false
      this.edit4_a = false
      this.edit4_b = false
      this.edit5 = false
      this.loading = false
      if (this.touched) {
        this.getRequests()
        this.touched = false
      }
      this.dialog = false
      this.dni_type = 3
      this.is_disabled = true
      this.is_firm = false
      this.current_ndx = 0
      this.current_cntrct = 0
      this.av_image = ''
      this.subscribers = Object.assign({}, this.empty_subscribers)
      this.editable_tech_address = Object.assign({}, this.object_reset)
      this.editable_tech_contact = Object.assign({}, this.object_reset)
      this.editable_admin_address = Object.assign({}, this.object_reset)
      this.editable_admin_contact = Object.assign({}, this.object_reset)
      this.editable_legal_address = Object.assign({}, this.object_reset)
      this.editable_legal_contact = Object.assign({}, this.object_reset)
      this.editable_subscriber = Object.assign({}, this.object_reset)
      this.franchise_contact = Object.assign({}, this.empty_contact)
      this.discarding_item = null
      this.subscriber_exist_checked = null
      this.$validator.reset()
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    clickFile () {
      this.$refs.avtr.$refs.input.click()
    },
    dropImg () {
      this.av_image = ''
      this.subscribers.avatar = ''
    },
    displayPreview (e) {
      if (!['image/png','image/jpeg','image/jpg'].includes(e.type)) {
        this.dropImg()
        this.emitNotification('error', 6000, 'error422.avatar_must_valid_image', this.$t('subscribers.avatar'))
      } else if (e.size > 2097152) {
        this.dropImg()
        this.emitNotification('error', 6000, 'error422.avatar_max_2048_kb', this.$t('subscribers.avatar'))
      } else {
        const fr = new FileReader ()
        fr.readAsDataURL(e)
        fr.addEventListener('load', () => {
          this.av_image = fr.result
          this.subscribers.avatar = e
        })
      }
    },
    getListOf (type, id = null) {
      let keep_going = true
      let url = ''
      switch (type) {
        case 'country':
          this.loadingSelCountry = true
          url = this.$store.getters.getAllCountries
          break
        case 'state':
          this.loadingSelState = true
          url = this.$store.getters.getStates(id)
          break
        default:
          keep_going = false
          this.emitNotification('error', 6000, 'base.something_wrong')
          return false
      }
      if (keep_going) {
        axios
          .get(url)
          .then(response => {
            this.fillListData (type, true, response.data)
          })
          .catch(err => {
            let error = err.response
            if (err.response.data) {
              error = err.response.data.error
            }
            this.emitNotification('error', 6000, error ? error:'base.something_wrong')
            this.fillListData (type, false, null)
          })
      }
    },
    fillListData (type, is_success, list) {
      switch (type) {
        case 'country':
          is_success ? this.items_country = list.countries : this.items_country = []
          break
        case 'state':
          is_success ? this.items_state = list.states : this.items_state = []
          break
        default:
          this.emitNotification('error', 6000, 'base.something_wrong')
          break
      }
    },
    // Create a new Service Request for existing Subscriber, in another location
    newReqForExisting () {
      this.confirmLoading = true
      axios.post(this.$store.getters.getContracts(), {
        'subscriber_id': this.subscriber_exist_obj.id
      })
        .then(response => {
          if (response.data.success) {
            this.continueReg(response.data.success.id, this.subscriber_exist_obj.id)
          }
        })
        .catch(err => {
          this.confirmLoading = false
          this.cancelConfirm ()
          this.emitNotification('error', 6000, 'base.something_wrong')
        })
    },
    cancelConfirm () {
      this.subscribers = Object.assign({}, this.empty_subscribers)
      this.dni_type = 3
      this.max_length = 0
      this.new_req_confirm = false
      this.is_disabled = true
      this.av_image = ''
      this.$validator.reset()
      this.subscriber_exist_checked = null
      this.subscriber_exist_obj = Object.assign({}, this.object_reset)
    },
    continueReg (cntrct, ndx) {
      this.setClear()
      let type = false
      this.edit = true
      this.dialog = true
      this.loading = true
      axios.get(this.$store.getters.getSubscribers(ndx),{
          params: {
            'reqs': true
          }
        })
        .then(response => {
          if (response.data.success) {
            this.subscribers = response.data.success.subscriber;
            this.edit1 = true
            if (cntrct) {
              let contract = response.data.success.contracts.find(obj => {return obj.id === cntrct})
              if (contract.subscriber_id == ndx) {
                this.current_ndx = ndx
                this.current_cntrct = cntrct
                this.continueContractInfo(cntrct)
              }
            }
          } else {
            this.dialog = false
            this.emitNotification('error', 6000, 'base.something_wrong')
          }
        })
        .catch(error => {
          this.emitNotification('error', 6000, 'base.something_wrong')
        })
        .finally(()=>{
          // Reset newReqForExisting values if set to true
          this.loading = false
          this.confirmLoading ? this.confirmLoading = false : ''
          this.new_req_confirm ? this.getRequests(false) : ''
          this.new_req_confirm ? this.new_req_confirm = false : ''
        })
    },
    continueContractInfo(contract_id) {
      let params = {
        // 'status': JSON.stringify(['draft', 'requested'])
        'status': 'draft'
      }
      this.loadingContactInfo = true;
      axios
        .get(this.$store.getters.getContractAddrContactsServs(contract_id),{
          params: params
        })
        .then(response => {
          if (response.data && response.data.success) {
            Object.values(response.data.success.addresses).forEach(val => {
              switch (val.type) {
                case 'tech':
                  this.edit2_a = true
                  this.current_tech_address = val.id
                  this.editable_tech_address = val
                  if (val.geo_location) {
                    this.editable_tech_address.lat = val.geo_location.coordinates[1]
                    this.editable_tech_address.lon = val.geo_location.coordinates[0]
                  }
                  if (response.data.success.employee) {
                    this.editable_tech_address.employee_id = response.data.success.employee.employee_id
                  }
                  break
                case 'admin':
                  this.edit3_a = true
                  this.current_admin_address = val.id
                  this.editable_admin_address = val
                  if (val.geo_location) {
                    this.editable_admin_address.lat = val.geo_location.coordinates[1]
                    this.editable_admin_address.lon = val.geo_location.coordinates[0]
                  }
                  break
                case 'legal':
                  this.edit4_a = true
                  this.current_legal_address = val.id
                  this.editable_legal_address = val
                  if (val.geo_location) {
                    this.editable_legal_address.lat = val.geo_location.coordinates[1]
                    this.editable_legal_address.lon = val.geo_location.coordinates[0]
                  }
                  break
              }
            })
            Object.values(response.data.success.contacts).forEach(val => {
              switch (val.type) {
                case 'tech':
                  this.edit2_b = true
                  this.current_tech_contact = val.id
                  this.editable_tech_contact = val
                  this.editable_tech_contact.prefix = val.dni.substring(0,1)
                  this.editable_tech_contact.dni = parseInt(val.dni.substring(1,10), 10)
                  if (this.$store.getters.isFranchise) {
                    this.franchise_contact = {
                      'email': val.email,
                      'local_phone': val.local_phone,
                      'cellphone': val.cellphone
                    }
                    // eslint-disable-next-line no-console
                    // console.log('franchise_contact', this.franchise_contact);
                  }
                  break
                case 'admin':
                  this.edit3_b = true
                  this.current_admin_contact = val.id
                  this.editable_admin_contact = val
                  this.editable_admin_contact.prefix = val.dni.substring(0,1)
                  this.editable_admin_contact.dni = parseInt(val.dni.substring(1,10), 10)
                  break
                case 'legal':
                  this.edit4_b = true
                  this.current_legal_contact = val.id
                  this.editable_legal_contact = val
                  this.editable_legal_contact.prefix = val.dni.substring(0,1)
                  this.editable_legal_contact.dni = parseInt(val.dni.substring(1,10), 10)
                  break
              }
            })
            // Object.values(response.data.success.services).forEach(val => {})
            if (response.data.success.services.length > 0) {
              this.edit5 = true
            }
          }
        })
        .catch(error => {
          //
        })
        .finally(()=>{
          this.loadingContactInfo = false;
        })
    },
    finishRequest(with_inspection){
      this.setClear()
      this.getRequests(false)
      // Notify
      this.$store.dispatch('sendNotification',{
        text: 'notification.new_service_requested',
        user_id: 2,
        type: 'info'
      })
      if (this.$store.getters.isFranchise) {
        if (with_inspection) {
          if (this.getPermissionGroup({
            permission:'inspection',
            method:'r'
          })) {
            this.$router.push({ name: 'inspection' })
          }
        } else {
          if (this.getPermissionGroup({
            permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
            mode:'all'
          })) {
            this.$router.push({ name: 'RequestBillsFranchise' })
          }
        }
      }
    },
    loadingSignalFromChild (val) {
      this.loading = val
    },
    discard (item) {
      this.discarding_item = item
      this.$store.dispatch('confirmationValue',{
        template: 'discarding',
        show: true,
        model: this.$tc('requests.requests',1),
        title: this.$t('base.discard'),
        action: this.$tc('requests.discarded',1),
        content: this.$t('base.confirm_discard'),
        type: 'error',
        confirm:{
          url: this.$store.getters.get503Status,
          method: 'get'
        },
        pos: 2
      })
      // eslint-disable-next-line no-console
      // console.log(item)
    },
    /**
     * Function to receive the response from the server when a subscriber is created
     * @param {createResponse} data
     */
    createSubscriber(data){
      // eslint-disable-next-line no-console
      // console.log('subscriber create',data.response);
      this.subscribers = data.response;
      this.current_cntrct = data.cntrct;
      this.current_ndx = data.ndx;
      this.edit1 = true
      this.editable_subscriber = Object.assign({}, this.subscribers)
      this.sameSubscriber();
    },
    /**
     * Function to receive the response from the server when a subscriber is updated
     * @param {subscriber} data
     */
    updatedSubscriber(data){
      // eslint-disable-next-line no-console
      // console.log('subscriber create',data);
      this.subscribers = data;
      this.edit1 = true
      this.editable_subscriber = Object.assign({}, this.subscribers)
      this.sameSubscriber();
    },
    sameSubscriber(){
      if (this.$store.getters.isFranchise) {
        this.saveFranchiseContact(true)
      } else {
        this.e1 = Number(this.e1) + 1;
      }
      this.getRequests(false);
    },
    newContractExistingSubscriber(data, contract_id){
      this.subscribers = data;
      this.current_ndx = data.id
      this.current_cntrct = contract_id
      this.edit1 = true
      this.getRequests(false);
    },
    /**
     * Subscriber contact details when it is franchise
     * @param {franchise_contact} data
     */
    getFranchiseContact(data){
      this.franchise_contact = data;
    },
    emitNotification(style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      })
    },
    PoliciesRequest(){
      // policies show
      let policies_show = [
        'requestInfo_contract',
        'index_contractService',
      ];
      // policies create
      let policies_create = [
        'store_subscriber',
        'getByDoc_subscriber',
      ];
      // policies edit
      let policies_edit = [
        'show_subscriber',
        'contractAddrContactServ_contract',
      ];
      // policies commons
      let policiesCommons = [
        'index_typeClient',
        'index_employee',
        'index_servicePackage',
        'index_contractService',
        'store_sector',
        'store_contractAddress',
        'store_contractContact',
        'update_contract',
        'update_subscriber',
        'update_contractAddress',
        'update_contractContact',
        'destroy_contractService',
        'parishSectors_sector',
        'storeWithPack_contractService',
        'requestInfo_contract',
      ]
      const onlyIfNotFranchise = [
        'index_service',
        'index_serviceParam',
        'store_contractService',
        'store_contractServiceParam',
        'show_contractService',
        'show_contractServiceParam',
        'update_contractService',
        'update_contractServiceParam',
      ];
      if (!this.$store.getters.isFranchise) {
        policiesCommons = [...policiesCommons,...onlyIfNotFranchise];
      }
      policies_create = [...policies_create,...policiesCommons];
      policies_edit = [...policies_edit,...policiesCommons];
      // Tab values: request, completed, inactive
      if (this.tab == 'request') {
        this.policies.edit = this.getPermissionGroup({
          permission: policies_edit,
          mode:'all'
        });
      } else {
        this.policies.edit = false;
      }
      this.policies.show = this.getPermissionGroup({
        permission:policies_show,
        mode:'all'
      });
      this.policies.create = this.getPermissionGroup({
        permission: policies_create,
        mode:'all'
      });
    },
  },
  watch:{
    options: {
      handler () {
        if (!this.limit_request) {
          this.getRequests(this.expand)
        }
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = ''
        this.$validator.reset()
        this.options.page = 1
        this.getRequests(false)
      }
    },
    tab(val){
      this.PoliciesRequest();
      this.limit_request = true
      this.options.page = 1
      this.getRequests(this.expand)
    },
    is_firm (val) {
      this.subscribers.dni = ''
      this.subscribers.agent_ret = false
      this.subscribers.firm_name = ''
      if (val) {
        this.max_length = 9
      } else {
        this.max_length = 8
      }
    },
    date_menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    dialog (val) {
      if (val){
        /*if (this.client_types.length == 0){
          this.getSubscriberTypes()
        }*/
      }
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    e1 (val) {
      if (val == 2 && this.items_country.length == 0){
        this.getListOf('country')
        this.getListOf('state', 237)// Venezuela preselected case only
      }
    }
  },
  computed:{
    headers(){
      return [
        {
          text: this.$t('base.code'),
          align: 'center',
          value: 'cntrct',
          width: 86
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'doc',
          width: 100
        },
        {
          text: this.$t('subscribers.name'),
          align: 'left',
          value: 'legal_denomination'
        },
        {
          text: this.$t('subscribers.created_at'),
          align: 'center',
          value: 'created_at',
          width: 110
        },
        {
          text: this.$t('subscribers.status'),
          value: 'status'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'action',
          sortable: false,
          width: 120
        }
      ]
    },
    computedDateFormatted () {
      return this.formatDate(this.subscribers.birthdate)
    },
    computedIvaItems () {
      let items = []
      JSON.parse(this.iva_items).forEach(element => {
        items.push({
          text: element ? this.$toPercent(element) : "0%",
          value: element
        })
      })
      return items
    },
    computedIslrItems () {
      let items = []
      JSON.parse(this.islr_items).forEach(element => {
        items.push({
          text: element ? this.$toPercent(element) : "0%",
          value: element
        })
      })
      return items
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if (val) {
        if (this.$store.getters.getConfirmationTemplate == 'requests') {
          this.finishRequest()
        }
        if (this.$store.getters.getConfirmationTemplate == 'discarding') {
          setTimeout(() => {
            this.$store.dispatch('closeSnackbar')
            this.$awn.warning(this.$tc('no_flow', 0,
              [
                this.$t('service.'+this.discarding_item.status)
              ]
            ), {durations:{warning: 4000}})
          }, 140)
        }
      }
    })
  },
  mounted(){
    this.PoliciesRequest();
    this.subscribers = Object.assign({}, this.empty_subscribers)
  },
  beforeDestroy () {
    this.unwatch()
  }
}
