import SubscribersGroupsDialog from './SubscribersGroupsDialog.vue'
import CatalogFilter from '../../components/Catalogs/FilterCatalog.vue'

export default {
  name: "SubscribersGroups",
  components:{
    SubscribersGroupsDialog,
    CatalogFilter
  },
  data() {
    return {
      loadingTable:false,
      totalValues: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      subscribersGroups:[],
      copyGroups: '',
      modeNode: process.env.NODE_ENV,
      nodata: true,
      policies: {
        create: false,
        edit: false,
        delete: false
      },
      searchCode: '',
      searchVal: ''
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'SubscribersGroups'){
        this.getGroups()
      }
    })
    this.getPolicies()
  },
  watch: {
    options: {
      handler () {
        this.getGroups()
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('Taxes.name'),
          value: 'name',
          align: 'left'
        },
        {
          text: this.$t('description'),
          value: 'description.observations',
          sortable: false,
          align: 'left'
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
          width: 120
        }
      ]
    },
    computedDateFormatt () {
      return this.formatDate(this.today)
    }
  },
  methods:{
    getGroups(){
      this.nodata = true
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (this.searchCode !== '') {
        ops.params[this.searchCode] = this.searchVal
      }

      axios.get(this.$store.getters.catalogByCategory('subscriber_group'), ops)
        .then(response=>{
          if (response.data.success) {
            this.totalValues = response.data.success.total
            if (this.totalValues > 0) {
              this.nodata = false
              this.subscribersGroups = response.data.success.data
              this.copyGroups = JSON.stringify(this.subscribersGroups)
            }
          }
        }).catch(error=>{
          if (error.response) {
            // eslint-disable-next-line no-console
            console.error(error.response)
          }
        }).finally(()=>{
          this.loadingTable = false
        })
    },
    newItem(){
      if (this.policies.create) {
        let obj = {
          edit: false,
          subscribersGroup: Object.assign({}, {}),
        }
        this.$refs.subscriber_group_dialog.open(obj)
      }
    },
    editItem(item){
      if (this.policies.edit) {
        let obj = {
          edit: true,
          subscribersGroup: Object.assign({}, item),
        }
        this.$refs.subscriber_group_dialog.open(obj)
      }
    },
    deleteItem(item){
      if (this.policies.delete) {
        this.$store.dispatch('confirmationValue',{
          template: 'SubscribersGroups',
          show: true,
          model: this.$tc('roles.groups',1) + ' ' + item.name,
          title: this.$t('base.delete'),
          pre: 'success',
          action: this.$tc('deleted',1),
          content: this.$t('base.confirm_delete',{
            action: this.$t('base.delete'),
            record: item.name
          }),
          type: 'error',
          confirm:{
            url: this.$store.getters.catalogList(item.id),
            method: 'delete'
          },
          pos: 1
        })
      }
    },
    /**
     * Function to filter by name
     * @param {string||number} val
     * @param {string} code
     */
    searchBy (val, code) {
      if (val) {
        this.searchCode = code
        this.searchVal = val
        if (this.options.page != 1) {
          this.options.page = 1
        } else {
          this.getGroups()
        }
      }
    },
    eraseFilter () {
      this.searchCode = ''
      this.searchVal = ''
      if (this.options.page != 1) {
        this.options.page = 1
      } else {
        this.getGroups()
      }
    },
    getPolicies () {
      if (this.$store.getters.isAdmin) {
        this.policies = {
          create: true,
          edit: true,
          delete: true
        }
      } else {
        this.policies.create = this.getPermissionGroup({
          permission:[
            'storeSubscribersGroups_catalog'
          ],
          mode:'all'
        });
        this.policies.edit = this.getPermissionGroup({
          permission:[
            'updateSubscribersGroups_catalog'
          ],
          mode:'all'
        });
        this.policies.delete = this.getPermissionGroup({
          permission:[
            'destroySubscribersGroups_catalog'
          ],
          mode:'all'
        });
      }
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
