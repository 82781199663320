export default {
  name: 'secondary-filter',
  components: {},
  props: {
    /**
     * Class parent css
     */
    classParent: {
      type: String,
      default: ''
    },
    /**
     * Boolean that disables all fields
     */
    disableFields: {
      type: Boolean,
      default: false
    },
    /**
     * Boolean that clears the filter
     */
    filter_cleared: {
      type: Boolean,
      default: false
    },
    /**
     * It is a Boolean and if it is true, displays the Select-One
     */
    showSelOne: {
      type: Boolean,
      default: false
    },
    /**
     * Select-One Items
     */
    selOneItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    /**
     * Select-One ID
     */
    sel_one_id: {
      default: null
    },
    /**
     * Search Type for Select-One
     */
    search_type_one: {
      type: String,
      default: ''
    },
    /**
     * Select-One Label
     */
    selOneLabel: {
      type: String,
      default: function () {
        return this.$t('base.select')
      }
    },
    /**
     * It is a Boolean and if it is true, displays the Select-Two
     */
    showSelTwo: {
      type: Boolean,
      default: false
    },
    /**
     * Select-Two Items
     */
    selTwoItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    /**
     * Select-Two ID
     */
    sel_two_id: {
      default: null
    },
    /**
     * Search Type for Select-Two
     */
    search_type_two: {
      type: String,
      default: ''
    },
    /**
     * Select-Two Label
     */
    selTwoLabel: {
      type: String,
      default: function () {
        return this.$t('base.select')
      }
    },
    /**
     * It is a Boolean and if it is true, displays the Employee Search Component
     */
    showDatePickers: {
      type: Boolean,
      default: false
    },
    /**
     * Start Date
     */
    start_date: {
      default: null
    },
    /**
     * End Date
     */
    end_date: {
      default: null
    },
    /**
     * It is a Boolean and if it is true, displays the Employee Search Component
     */
    showEmployee: {
      type: Boolean,
      default: false
    },
    /**
     * Employee ID
     */
    employee_id: {
      type:[Number,String],
      default: null
    },
  },
  data() {
    return {
      selOneID: this.sel_one_id,
      selTwoID: this.sel_two_id,
      employeeID: this.employee_id,
      // Date Filter
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      date1: null,
      date2: null,
      menu1: false,
      menu2: false,
    }
  },
  computed: {
    computedDateFormatted1: {
      get () {
        return this.formatDate(this.date1)
      },
      set (val) {
        this.date1 = val
        if (!val) {
          this.computedDateFormatted2 = val
        }
      }
    },
    computedDateFormatted2: {
      get () {
        return this.formatDate(this.date2)
      },
      set (val) {
        this.date2 = val
      }
    }
  },
  methods: {
    clearDate2 () {
      this.menu1 = false
      this.computedDateFormatted2 = null
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    searchByDate () {
      this.menu2 = false
      this.$emit('datesSelected', this.date1, this.date2)
    }
  },
  mounted() {
    //
  },
  watch: {
    // Filter Cleared
    sel_one_id: {
      handler(val){
        this.selOneID = val
      },
      immediate: true
    },
    sel_two_id: {
      handler(val){
        this.selTwoID = val
      },
      immediate: true
    },
    start_date: {
      handler(val){
        this.computedDateFormatted1 = val
      },
      immediate: true
    },
    end_date: {
      handler(val){
        this.computedDateFormatted2 = val
      },
      immediate: true
    },
    employee_id: {
      handler(val){
        this.employeeID = val
      },
      immediate: true
    },
    filter_cleared (val) {
      if (val) {
        if (this.computedDateFormatted1) {
          this.computedDateFormatted1 = null
        }
        if (this.computedDateFormatted2) {
          this.computedDateFormatted2 = null
        }
      }
    },
    // Values changed
    selOneID (val) {
      this.$emit('selOneChanged', val, this.search_type_one)
    },
    selTwoID (val) {
      this.$emit('selTwoChanged', val, this.search_type_two)
    },
    employeeID (val) {
      this.$emit('employeeSelected', val, 'employee_id')
    },
    computedDateFormatted1 (val) {
      if (!val && !this.filter_cleared) {
        this.$emit('datesSelected', null, null)
        this.$refs.random_field.focus()
      }
    }
  }
}
