export default {
  data() {
    return {
      role: {
        name: "",
        description: "",
        groups: null,
        policies: []
      },
      dialog: false,
      fullscreen: false,
      loadingTable: false,
      loading: false,
      roles: [],
      groups: [],
      policies: [],
      policy_groups: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"]
      },
      totalValues: 0,
      view: false,
      edit: false,
      current_id: 0,
      unwatch: "",
      modeNode: process.env.NODE_ENV
    };
  },
  methods: {
    save() {
      this.$refs.form_roles.validate().then(success => {
        if (success) {
          this.loading = true;
          if (this.edit == false) {
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    close() {
      if (!this.view) {
        this.$refs.form_roles.reset();
      }
      this.dialog = false;
      this.view = false;
      this.edit = false;
      this.setClear();
      this.current_id = 0;
      this.fullscreen = false;
    },
    viewItem(item) {
      this.dialog = true;
      this.view = true;
      this.fullscreen = false;
      this.role.policies = item.policies;
    },
    newItem() {
      this.edit = false;
      this.fullscreen = true;
      this.dialog = true;
    },
    editItem(item) {
      this.role.name = item.name;
      this.role.description = item.description;
      this.role.groups = item.groups;
      // let new_policie = []
      // for(let policie of item.policies){
      //   new_policie.push(policie.id)
      // }
      this.role.policies = item.policies;
      this.current_id = item.id;
      this.edit = true;
      this.dialog = true;
      this.fullscreen = true;
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        model: this.$tc("roles.roles", 1),
        title: this.$t("base.delete"),
        action: this.$tc("deleted", 1),
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: item.name
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.rolesSources(item.id),
          method: "delete"
        }
      });
    },
    getRoles() {
      this.loadingTable = true;
      let ops = {
        params: {
          paginate: this.options.itemsPerPage,
          page: this.options.page
        }
      };
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0];
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0];
      }
      axios
        .get(this.$store.getters.rolesSources(), ops)
        .then(response => {
          this.loadingTable = false;
          this.roles = response.data.roles.data;
          this.totalValues = response.data.roles.total;
        })
        .catch(err => {
          this.loadingTable = false;
        });
    },
    getGroups() {
      axios
        .get(this.$store.getters.groupSources(), {
          params: {
            select_only: true
          }
        })
        .then(response => {
          this.groups = response.data.groups;
        });
    },
    create() {
      axios
        .post(this.$store.getters.rolesSources(), this.role)
        .then(response => {
          if (response.data.success === "roles_restored_updated") {
            this.close();
            const text = this.$tc("roles.roles", 1);
            this.pushNoty(text, "restored_updated", 1);
            this.getRoles();
          } else if (response.status == 200) {
            this.close();
            const text = this.$tc("roles.roles", 1);
            this.pushNoty(text, "created", 1);
            this.getRoles();
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      axios
        .put(this.$store.getters.rolesSources(this.current_id), this.role)
        .then(response => {
          if (response.status == 200) {
            this.close();
            const text = this.$tc("roles.roles", 1);
            this.pushNoty(text, "updated", 1);
            this.getRoles();
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setClear() {
      this.role = {
        name: "",
        description: "",
        groups: null,
        policies: []
      };
    },
    remove(item) {
      const index = this.role.policies.indexOf(item.id);
      if (index >= 0) this.role.policies.splice(index, 1);
    },
    receivedPolicies(val) {
      this.role.policies = val;
    },
    /**
     * Push success notification
     *
     * @param {string} model
     * @param {string} action
     * @param {number} pos
     * @param {number} time
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "name"
        },
        {
          text: this.$t("description"),
          value: "description",
          sortable: false
        },
        {
          text: this.$tc("roles.groups", 1),
          value: "group.name"
        },
        // {
        //   text: this.$tc('roles.permissions',2),
        //   value: 'policies_name',
        //   width: 280
        // },
        {
          text: this.$t("actions"),
          value: "action",
          sortable: false,
          align: "center",
          width: 140
        }
      ];
    },
    computedPolicies() {
      return this.policies.map(obj => {
        return {
          item: obj,
          disabled:
            obj.name == "impropely_named_policy" ||
            !this.$te("policy." + obj.name)
        };
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getRoles();
      },
      deep: true
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val) {
          this.getRoles();
        }
      }
    );
  },
  mounted() {
    this.getGroups();
  },
  beforeDestroy() {
    this.unwatch();
  }
};
