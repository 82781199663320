export default {
  name: 'TaxBillRateMenu',
  components: {},
  props: {
    rates: {
      type: Array,
      default: function() {
        return [];
      }
    },
    taxBillRate0: {
      type: Object,
      default: function() {
        return {
          currency_code: ''
        };
      }
    }
  },
  data() {
    return {
      //
    };
  },
  computed: {
    filteredTaxBillRates() {
      return this.rates.filter((rate) => {
        return rate.currency_code === this.taxBillRate0.currency_code/* && !rate.default*/
      });
    },
  },
  methods: {
    changeTaxBillRate (item) {
      this.$emit('taxBillRateChanged', item)
    },
    itemName(item) {
      if (this.$te("system." + item.name)) {
        return `${this.$t("system." + item.name)} (${item.symbol})`
      } else if(item.full_name) {
        return item.full_name;
      } else {
        return item.name;
      }
    }
  }
}
