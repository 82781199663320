//
import PreView from '../../components/HistoryBills/preview.vue';
import SubscriberSearch from '../../components/Subscriber/suscriberSearch.vue';

const ReturnObject = (value)=>{
  if(typeof(value)=='object'){
    return value;
  } else if(typeof(value)=='string' && value != 'null'){
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "Payments",
  components:{
    PreView: PreView,
    SubscriberSearch:SubscriberSearch,
  },
  data (){
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      is_filter: false,
      fullscreen:false,
      loadingTable:false,
      prospects_only:false,
      loadingSubscriber:false,
      its_filter_subscriber:false,
      show_doc:false,
      options:{
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      count:0,
      perPage: 10,
      totalRates:0,
      totalValues:0,
      relationIndex:-1,
      step: 'tab-1',
      of_type: 'payment_note',
      of_status: 'pending',
      paymentNotes:[],
      search:'',
      unwatch: '',
      symbol:'',
      subscriber_id:'',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
    };
  },
  computed: {
    headers(){
      let isInvoice = this.of_type == 'invoice'
      let headers = [
        {
          text: '#ID',
          value: 'id',
          align: 'right',
        },
        {
          text: this.$t('billing.elaboration_date'),
          value: 'issue_date',
          align: 'center',
        },
        {
          text: isInvoice ? this.$t('billing.invoice_number') : this.$t('billing.control_number'),
          value: isInvoice ? 'document_number' : 'control_number',
          align: 'center',
        },
        {
          text: this.$t('billing.description'),
          value: 'remark',
          align: 'center',
        },
        {
          text: this.$t('billing.state'),
          value: 'status',
          align: 'center',
        },
        {
          text: this.$tc('amount',1),
          value: 'amount',
          align: 'right'
        },
      ];
      if (this.show_doc) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'center'
          }
        );
      }
      return headers;
    },
    ComputeType(){
      return this.$t('document_control.types.'+this.of_type);
    },
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.ListDocument();
      }
    });
  },
  beforeDestroy() {
    this.unwatch()
  },
  watch: {
    options: {
      handler () {
        this.ListDocument();
      },
      deep: true,
    },
    step(){
      this.ListDocument();
    },
  },
  methods:{
    async ListDocument(){
      if (this.step =='tab-1') {
        this.of_type = 'payment_note';
        this.of_status='pending';
      } else if (this.step =='tab-2') {
        this.of_type = 'invoice';
        this.of_status ='';
      }
      if (( this.abonado != '') && this.is_filter) {
        this.options.page = 1;
      }
      let symbol_base = this.$store.getters.getBaseCurrency;
      // eslint-disable-next-line no-console
      // console.log('hola', symbol_base);
      let symbol ='';
      if (symbol_base != null) {
        for(var key in symbol_base){
          symbol = symbol_base[key].symbol;
        }
      } else {
        await this.getQueries();
        symbol = this.symbol;
      }
      this.loadingTable = true;
      let params={
        page:this.options.page,
        paginate:typeof(this.options.itemsPerPage)=='number' ? this.options.itemsPerPage : 10,
        sort_by: this.options.sortBy[0] || null,
        sort_desc: this.options.sortDesc[0] || null,
        type: this.of_type,
        status: this.of_status,
        subscriber_id:this.abonado,
        symbol:symbol,
      };
      this.$store.dispatch('DocList', params)
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('documents', response);
        this.paymentNotes = response.client_documents;
        this.totalValues = response.totalValues;
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error', error.response);
        }
      }).finally(()=>{
        this.loadingTable = false;
        this.is_filter = false;
      });
    },
    SearchSubscriber(item){
      // eslint-disable-next-line no-console
      // console.log('his',item);
      if (typeof(item) == 'object' && Object.prototype.hasOwnProperty.call(item, 'subscriber_id')) {
        this.abonado = item.subscriber_id;
        this.SearchFilter();
      }
    },
    SearchFilter(){
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.is_filter = true;
      this.ListDocument();
    },
    SoftEraseFilter(){
      this.options={page: 1, itemsPerPage:10, sortBy:['updated_at'],sortDesc:[true]};
      this.abonado = '';
      this.is_filter = false;
      this.ListDocument();
    },
    EraseFilter(){
      this.options={page: 1, itemsPerPage:10, sortBy:['updated_at'],sortDesc:[true]};
      this.abonado = '';
      this.$refs.subs_criber.clear2();
      this.is_filter = false;
      this.ListDocument();
    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      // console.log(item);
      let preview = null;
      if (item.type =='payment_note') {
        preview = false;
      } else if (item.type =='invoice') {
        preview = true;
      }
      axios.get(this.$store.getters.getClientDocument(item.id))
      .then((response)=>{
        if (response.status == 200) {
          // eslint-disable-next-line no-console
          // console.log(response.data);
          // Le pasamos el simbolo al modal para evitar una consulta
          response.data.symbol = this.symbol;
          this.$refs.pre_view.open(response.data, preview);
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      });
    },
    ShowDocument(){
      this.show_doc = this.getPermissionGroup({
        permission:[
          'show_clientDocument',
          'makeClientDocumentPDF_clientDocument',
          'makePDF_clientDocument',
          'sendClientDocumentEmail_clientDocument',
          'index_employee',
          'store_proofPayment'
        ],
        mode:'all'
      });
    },
    async getQueries(){
      await this.$store.dispatch('DocQuerys')
      .then(response =>{
        // eslint-disable-next-line no-console
        // console.log('Querys',response);
        this.symbol = response.symbol;
      })
      .catch(error =>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
  },
  mounted() {
    this.ShowDocument();
  },
};
