export default {
  name: "dialogSector",
  components: {},
  data() {
    return {
      dialogSector: false,
      loadingSector: false,
      disableSector: true,
      fullscreen: false,
      edit:false,
      make_copy:false,
      doCopy: false,
      copy: '',
      copySector:'',
      sector:{
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      },
      extra:{},
      modeNode: process.env.NODE_ENV,
      has_points: false,
    }
  },
  computed: {},
  methods: {
    openSector(editMode, item){
      this.edit = editMode;
      this.dialogSector = true;
      if (!editMode) {
        this.newSector();
      } else{
        let Sector = Object.assign({}, item);
        if (!Sector.extra) {
          this.has_points = false;
          Sector.extra = {
            description: '',
            points_available: 0,
            points_occupied: 0,
            points_remaining: 0,
            minimum_sales_goal: 0,
            maximum_capacity_alert: 0,
            has_points: false,
          }
        } else {
          this.has_points = Sector.extra.has_points;
        }
        this.copySector = JSON.stringify(Sector);
        this.sector = Object.assign({}, Sector);
        // eslint-disable-next-line no-console
        // console.log('sector', this.sector);
      }
    },
    openModal(){
      this.dialogSector = true;
    },
    close(){
      this.dialogSector = false;
      this.sector = {
        extra: {
          description: '',
          points_available: 0,
          points_occupied: 0,
          points_remaining: 0,
          minimum_sales_goal: 0,
          maximum_capacity_alert: 0,
          has_points: false,
        }
      };
      this.$refs.sector_filter.EraseFilter();
      this.make_copy = false;
      this.copy = '';
      setTimeout(()=> {
        this.$refs.form_sector.reset();
      }, 200);
    },
    newSector(){
      this.disableSector = true;
      this.loadingSector = false;
      this.has_points = false;
      this.sector = {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        extra: {
          description: '',
          points_available: 0,
          points_occupied: 0,
          points_remaining: 0,
          minimum_sales_goal: 0,
          maximum_capacity_alert: 0,
          has_points: false,
        }
      };
    },
    screen(val){
      this.fullscreen = val;
    },
    getItembyFilter(item, type){
      if (type == 'parish_id') {
        this.disableSector = false;
        if (item != null && item != undefined) {
          this.sector[type] = item;
        } else {
          this.disableSector = true;
        }
      }
      if (type == 'zone_id') {
        if (item != null && item != undefined) {
          this.sector[type] = item;
        } else {
          this.sector[type] = null;
        }
      }
      // eslint-disable-next-line no-console
      // console.log(this.sector);
    },
    save(){
      this.$refs.form_sector.validate()
      .then((success)=>{
        if (success) {
          // this.loadingSector = true;
          let sector = Object.assign({}, this.sector);
          let extra = this.sector.extra;
          sector.extra = JSON.stringify(extra);
          if(this.edit == false){
            this.create(sector);
          } else {
            this.validCloneByUpdate(sector);
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    create(sector){
      axios.post(this.$store.getters.getSectors(), sector)
      .then(response=>{
        const text = this.$t('sector') + ' ' + response.data.sector.name;
        this.$emit('createSector');
        this.pushNoty(text, "created", 1);
        this.getClone();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      }).finally(()=>{
        this.loadingSector = false;
      });
    },
    update(sector){
      axios.put(this.$store.getters.getSectors(this.sector.id), sector)
      .then(response=>{
        const text = this.$t('sector') + ' ' + response.data.sector.name;
        this.$emit('createSector');
        this.pushNoty(text, "updated", 1);
        this.getClone();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      }).finally(()=>{
        this.loadingSector = false;
      });
    },
    validCloneByUpdate(sector) {
      let copy = JSON.parse(this.copySector);
      if (copy.extra) {
        const extra_copy = copy.extra;
        copy.extra = JSON.stringify(extra_copy);
      }
      let origin = JSON.parse(JSON.stringify(sector));
      if (origin.extra) {
        const extra_origin = origin.extra;
        origin.extra = JSON.stringify(extra_origin);
      }
      let notSame = [];
      for (var key in origin) {
        if (key != "family_tree") {
          // eslint-disable-next-line no-console
          if (origin[key] != copy[key]) {
            notSame.push({ key: origin[key] != copy[key] });
          }
        }
      }
      // eslint-disable-next-line no-console
      setTimeout(() => {
        if (notSame.length && notSame.length > 0) {
          this.update(sector);
        } else {
          this.status204();
          this.loadingSector = false;
        }
      }, 5);
    },
    status204(){
      this.$awn.warning(this.$t("DSB.profile.nothing_to_change"), {
        durations: { warning: 4000 }
      });
      this.getClone();
    },
    NormalSave(){
      this.make_copy = false;
      this.doCopy = false;
      this.save();
    },
    UpdateAndCopy(){
      this.make_copy = true;
      this.doCopy = true;
      this.save();
    },
    getClone(){
      if (this.make_copy == false && this.doCopy == false) {
        this.close();
      }else{
        this.copy = JSON.stringify(this.sector);
        let clone = JSON.parse(this.copy);
        this.sector = {
          franchise_id: clone.franchise_id,
          parish_id: clone.parish_id,
          zone_id: clone.zone_id,
          extra: {
            description: '',
            points_available: 0,
            points_occupied: 0,
            points_remaining: 0,
            minimum_sales_goal: 0,
            maximum_capacity_alert: 0,
            has_points: false,
          }
        }
        this.has_points = false;
        this.edit = false;
        this.doCopy = false;
        this.$refs.form_sector.reset();
        this.$awn.info(this.$t('sectors.create_new_sector'), {durations:{info: 3400}});
      }
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
    changePointsOccupied(){
      const points_occupied = this.sector.extra.points_occupied;
      const points_available = this.sector.extra.points_available;
      let points_remaining = 0;
      if (points_occupied > 0 && points_available > 0) {
        points_remaining = points_available - points_occupied;
      } else{
        points_remaining = 0;
      }
      this.sector.extra.points_remaining = points_remaining;
      // eslint-disable-next-line no-console
      // console.log('extra',this.sector.extra);
    }
  },
  mounted() {},
  watch: {
    has_points(val){
      // eslint-disable-next-line no-console
      // console.log('has_points', val);
      this.sector.extra.has_points = val;
    },
    'sector.extra.points_occupied':{
      handler(){
        this.changePointsOccupied();
      },
      deep: true,
    },
    'sector.extra.points_available':{
      handler(){
        this.changePointsOccupied();
      },
      deep: true,
    },
  }
};
