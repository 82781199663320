import AddEditFormNational  from './FormNational.vue'
//import AddEditFormInternational  from './FormInternational.vue'

export default {
  name: "BankAccountsDialog",
  components:{
    AddEditFormNational
  },
  props: {
    permissions: {
      type: [Object],
      default: {}
    }
  },
  data (){
    return {
      national: null,
      international: null,
      loading: false,
      dialog: false,
      form_editing: false,
      national_banks: [],
      international_banks: [],
      loading_banks: false,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    //
  },
  computed:{
    computedNationalLabel () {
      let label = ''
      if (this.$i18n.locale == 'es') {
        label = this.$tc('banks.banks',2)+' '+this.$tc('banks.national',2)
      } else {
        label = this.$tc('banks.national',2)+' '+this.$tc('banks.banks',2)
      }
      return label
    },
    computedInternationalLabel () {
      let label = ''
      if (this.$i18n.locale == 'es') {
        label = this.$tc('banks.banks',2)+' '+this.$tc('banks.international',2)
      } else {
        label = this.$tc('banks.international',2)+' '+this.$tc('banks.banks',2)
      }
      return label
    }
  },
  methods:{
    open (item) {
      if (this.national_banks.length == 0 || this.international_banks.length == 0) {
        this.getBanks()
      }
      this.dialog = true
      if (Object.keys(item).length > 0 && item.constructor === Object) {
        this.form_editing = true
        this.national = true
        setTimeout(() => {
          if (this.$refs.account_form) {
            this.$refs.account_form.editing = true
            this.$refs.account_form.bank_account = JSON.parse(JSON.stringify(item))
          }
        }, 100)
      }
    },
    close (e, reload_table = false) {
      if (reload_table) {
        this.$emit('reloadTable')
      }
      this.dialog = false
      this.form_editing = false
      setTimeout(() => {
        if (this.$refs.account_form) {
          this.$refs.account_form.editing = false
          this.$refs.account_form.bank_account = {}
          this.$refs.account_form.$validator.reset()
        }
      }, 100)
      this.national = null
      this.international = null
    },
    saveForm () {
      this.$refs.account_form.save()
    },
    loadingBtn (val) {
      this.loading = val
    },
    getBanks () {
      this.loading_banks = true
      axios.get(this.$store.getters.catalogByCategory('bank'))
        .then(response => {
          if (response.data.success) {
            response.data.success.filter(bank => {
              if (bank.description.currency_code == 'VES') {
                this.national_banks.push(bank)
              } else {
                this.international_banks.push(bank)
              }
            })
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => {
          this.loading_banks = false
        })
    },
    setSelectedBank (e, is_national) {
      let bank = ''
      let list = this.international_banks
      if (is_national) {
        list = this.national_banks
      }
      bank = list.filter(bnk => {
        return bnk.id == e
      })
      setTimeout(() => {
        if (this.$refs.account_form && bank.length > 0) {
          this.$refs.account_form.selected_bank = bank[0]
        }
      }, 100)
    },
    backToSelecBank () {
      this.national = false
      this.international = false
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
