var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.documents,"footer-props":{
          'items-per-page-options': [5, 10, 50],
        },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.issue_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.issue_date).format(_vm.$store.getters.getDateFormat[0]))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("billing.status." + item.status))+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" "+_vm._s(_vm._f("formatAmount")(item.amount))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show_doc),expression:"show_doc"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.getClientDocument(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.view_details")))])])]}}],null,true)})],1)],1),_c('PreView',{ref:"pre_view"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }