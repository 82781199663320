import BankDialog from './Dialog.vue';
import CatalogFilter from '../../components/Catalogs/FilterCatalog.vue';

export default {
  name: 'AdjustmentMotives',
  components:{
    BankDialog,
    CatalogFilter
  },
  data() {
    return {
      loadingTable:false,
      totalValues: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      parent: {
        description: []
      },
      motives:[],
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'AdjustmentMotives'){
        this.options.page = 1
        this.getMotives()
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  watch: {
    options: {
      handler () {
        this.getMotives()
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('name'),
          value: 'name',
          sortable: false,
          align: 'left'
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          align: 'center',
          width: 20
        }
      ]
    }
  },
  methods:{
    getMotives(){
      this.loadingTable = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }

      axios.get(this.$store.getters.catalogByCategory('document_adjustment_motives'), ops)
        .then(response=>{
          if (response.data.success.data.length > 0) {
            this.parent = response.data.success.data[0]
            this.totalValues = this.parent.description.length
            delete this.parent.parent_id
            delete this.parent.created_at
            delete this.parent.updated_at
            delete this.parent.deleted_at
            this.parent.description.sort()
            this.motives = []
            this.parent.description.forEach((motive, i) => {
              this.motives.push({
                'name': motive,
                'index': i
              })
            })
          } else {
            this.motives = []
            this.parent = {
              description: []
            }
          }
        })
        .catch(error=>{
          if (error.response) {
            //
          }
        })
        .finally(()=>{
          this.loadingTable = false
        })
    },
    newItem(){
      this.$refs.motive_dialog.open()
    },
    deleteItem(item){
      let type = 'patch'
      let clone = JSON.parse(JSON.stringify(this.parent))
      clone.description.splice(item.index, 1)
      if (clone.description.length == 0) {
        type = 'delete'
      }
      this.$store.dispatch('confirmationValue',{
        template: 'AdjustmentMotives',
        show: true,
        model: this.$t('concept.motive'),
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.catalogList(clone.id),
          method: type,
          data: clone,
        },
        pos: 1
      })
    }
  },
  mounted(){
    //
  }
}
