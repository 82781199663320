export default {
  data() {
    return {
      position: {
        name: "",
        description: "",
        category: "position"
      },
      dialog: false,
      loading: false,
      loadingTable: false,
      positions: [],
      perPage: 10,
      options: {},
      totalValues: 0,
      edit: false,
      current_id: 0,
      unwatch: ""
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val) {
          this.getCatalog();
        }
      }
    );
  },
  methods: {
    save() {
      this.$refs.form_position.validate().then(success => {
        if (success) {
          this.loading = true;
          if (this.edit == false) {
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    close() {
      this.$refs.form_position.reset();
      this.dialog = false;
      this.edit = false;
      this.position = {
        name: "",
        description: "",
        category: "position"
      };
      this.current_id = 0;
    },
    newItem() {
      this.edit = false;
      this.dialog = true;
    },
    /**
     * Edit Function
     * @param {Array} item
     */
    editItem(item) {
      this.position.name = item.name;
      this.position.description = item.description;
      this.current_id = item.id;
      this.edit = true;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        model: this.$tc("actor.position", 1),
        title: this.$t("base.delete"),
        action: this.$tc("deleted", 1),
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: item.name
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.catalogList(item.id),
          method: "delete"
        }
      });
    },
    getCatalog() {
      this.loadingTable = true;
      axios
        .get(this.$store.getters.catalogByCategory("position"), {
          params: {
            page: this.options.page,
            paginate: this.options.itemsPerPage
          }
        })
        .then(response => {
          this.loadingTable = false;
          this.positions = response.data.success.data;
          this.totalValues = response.data.success.total;
        })
        .catch(err => {
          this.loadingTable = false;
        });
    },
    create() {
      axios
        .post(this.$store.getters.catalogList(), this.position)
        .then(response => {
          if (response.data.success == "catalog_restored_updated") {
            this.close();
            const text = this.$tc("actor.position", 1);
            this.pushNoty(text, "restored_updated", 1);
            this.getCatalog();
          } else {
            this.close();
            const text = this.$tc("actor.position", 1);
            this.pushNoty(text, "created", 1);
            this.getCatalog();
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      axios
        .put(this.$store.getters.catalogList(this.current_id), this.position)
        .then(response => {
          if (response.status == 200) {
            this.close();
            const text = this.$tc("actor.position", 1);
            this.pushNoty(text, "updated", 1);
            this.getCatalog();
          } else {
            this.dialog = false;
            this.$refs.form_position.reset();
            this.position = {
              name: "",
              description: ""
            };
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Push success notification
     *
     * @param {string} model
     * @param {string} action
     * @param {number} pos
     * @param {number} time
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        { text: this.$t("description"), value: "description" },
        {
          text: this.$t("actions"),
          value: "action",
          sortable: false,
          align: "right",
          width: 110
        }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getCatalog();
      },
      deep: true
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
    this.unwatch();
  }
};
