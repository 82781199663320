var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1 table-ellipsis",attrs:{"headers":_vm.headers,"items":_vm.groups,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":"","disableNew":_vm.loadingTable,"title":_vm.$tc('roles.groups', 2),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newItem}})]},proxy:true},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"item-text-ellipsis-2"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"item-text-ellipsis-2"},[_vm._v(" "+_vm._s(item.description))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"delete":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.delete")))])])]}}],null,true)})]],2)],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('toolbarDialog',{attrs:{"editMode":_vm.edit,"numNew":0,"title":_vm.$tc('roles.groups', 1)}}),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"form_group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"group-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","counter":"","maxlength":"190","error-messages":errors,"label":_vm.$t('name')},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"description","name":_vm.$t('description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"description","no-resize":"","rows":"3","error-messages":errors,"label":_vm.$t('description')},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}})]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.cancel")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1),_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.save")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.edit")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }