import EmployeeSearch from "@/components/Employees/EmployeeSearch.vue";
import FilePreview from "@/components/Utils/FilePreview.vue";
import moneyfield from "@/components/Utils/MoneyField.vue";
import orderbyfield from "@/components/Utils/OrderBy.vue";
import panelsroles from "@/components/Utils/PanelsRoles.vue";
import seepermits from "@/components/Utils/SeePermits.vue";
import verifypassword from "@/components/Utils/VerifyPassword.vue";
import "@/styles/styles.scss";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import OwlCarousel from "v-owl-carousel";
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import VueCryptojs from "vue-cryptojs";
import VueFusionCharts from "vue-fusioncharts";
import VueNativeSock from "vue-native-websocket";
import App from "./App.vue";
import CardParent from "./components/Dashboard/cardParent/CardParent.vue";
import CardSnippet from "./components/Dashboard/Cards/Card.vue";
import SectorFilter from "./components/Sectors/SectorFilter.vue";
import SubscriberForm from "./components/Subscriber/SubscriberForm.vue";
import DateDialog from "./components/Utils/DateDialog.vue";
import FabPercentCurrency from "./components/Utils/FabPercentCurrency.vue";
import FileLoad from "./components/Utils/FileLoad.vue";
import ToolbarDialog from "./components/Utils/ToolbarDialog.vue";
import ToolbarTable from "./components/Utils/ToolbarTable.vue";
import ExchangeRateSelect from './components/ExchangeRates/Selector/Autocomplete.vue';
import "./plugins/axiosDeclared.js";
import { i18n } from "./plugins/locale.js";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";
import VueFriendlyIframe from 'vue-friendly-iframe';

Vue.component("moneyfield", moneyfield);
Vue.component("filepreview", FilePreview);
Vue.component("orderbyfield", orderbyfield);
Vue.component("verifypassword", verifypassword);
Vue.component("EmployeeSearch", EmployeeSearch);
Vue.component("panelsroles", panelsroles);
Vue.component("seepermits", seepermits);
Vue.component("sectorFilter", SectorFilter);
Vue.component("toolbarTable", ToolbarTable);
Vue.component("toolbarDialog", ToolbarDialog);
Vue.component("FileLoad", FileLoad);
Vue.component("CardSnippet", CardSnippet);
Vue.component("CardParent", CardParent);
Vue.component("SubscriberForm", SubscriberForm);
Vue.component("FabPercentCurrency", FabPercentCurrency);
Vue.component("carousel", OwlCarousel);
Vue.component("DateDialog", DateDialog);
Vue.component("ExchangeRateSelect", ExchangeRateSelect);

var numeral = require("numeral");

// Currency global converter
Vue.filter("formatCurrency", function(value, place) {
  if (!value) return "";
  if (isNaN(value)) {
    return "";
  } else {
    value = parseFloat(value);
    if (
      store.getters.getBaseCurrency !== null &&
      store.getters.getBaseCurrency !== false
    ) {
      let formatted = [];
      if (Object.values(store.getters.getBaseCurrency)[0].rate) {
        value =
          value *
          parseFloat(Object.values(store.getters.getBaseCurrency)[0].rate);
        value = +(Math.round(value + "e+2") + "e-2");
      }
      value = numeral(value).format("0,0.00");
      formatted.push(
        Object.values(store.getters.getBaseCurrency)[0].symbol + " " + value
      );
      formatted.push(
        value + " " + Object.values(store.getters.getBaseCurrency)[0].icon
      );
      formatted.push(Object.values(store.getters.getBaseCurrency)[0].symbol);
      formatted.push(Object.values(store.getters.getBaseCurrency)[0].icon);
      return formatted[place];
    }
    return "";
  }
});

// Amounts/Number (with decimals) format
Vue.prototype.$formatAmount = Vue.filter("formatAmount", function(
  value,
  decimal_places = 2
) {
  let zeros = "";
  for (var i = 0; i < decimal_places; i++) {
    zeros += "0";
  }
  if (value === 0) return numeral(parseFloat(0)).format("0,0." + zeros);
  if (!value) return "";
  if (isNaN(value)) {
    return "";
  } else {
    return numeral(parseFloat(value)).format("0,0." + zeros);
  }
});

// Amounts/Number (with decimals) convert to percent
Vue.prototype.$toPercent = Vue.filter("toPercent", function(value) {
  if (!value) return "";
  if (isNaN(value)) {
    return "";
  } else {
    return parseFloat(value) * 100 + "%";
  }
});

// Count decimal places of given decimal number
Vue.prototype.$countDecimals = Vue.filter("countDecimals", function(value) {
  let default_decimals = 2
  if (!value) return default_decimals;
  if (isNaN(value)) {
    return default_decimals;
  } else {
    if(Math.floor(value) === value) return default_decimals;
    let decimal_part = value.toString().split(".")[1]
    return decimal_part ? decimal_part.length : default_decimals;
  }
});

Vue.use(VueFusionCharts, FusionCharts, Charts, Widgets, FusionTheme);
Vue.use(
  VueNativeSock,
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_SERVER_WEBSOCKET
    : process.env.VUE_APP_WEBSOCKET,
  { store: store, format: "json" }
);
Vue.use(VueCryptojs);
let options = {
  icons: {
    enabled: false
  },
  labels: {
    info: "",
    success: "",
    warning: "",
    alert: "",
    tip: ""
  },
  animationDuration: 400,
  position: "top-right"
};

Vue.use(VueAWN, options);
Vue.use(VueFriendlyIframe);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
