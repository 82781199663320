import AddEditForm  from './Form.vue'

export default {
  name: "ExchangeRatesDialog",
  components:{
    AddEditForm
  },
  props: {
    permissions: {
      type: [Object],
      default: {}
    }
  },
  data (){
    return {
      dialog2: false,
      loading2: false,
      edit: false,
      hide_btn: false,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    //
  },
  computed:{
    //
  },
  methods:{
    open (item = {}) {
      setTimeout(() => {
        if (Object.keys(item).length !== 0 && item.constructor === Object) {
          this.$refs.rates_form.edit = true
          this.$refs.rates_form.ex_rate = Object.assign({}, item)
          this.$refs.rates_form.compare_rate = Object.assign({}, item)
          this.$refs.rates_form.currency_code = item.currency_code
          this.$refs.rates_form.rate_decimal_precision = item.decimal_places ? item.decimal_places : 2;
          this.$refs.rates_form.is_default = item.default
          // decimal_places
        }
      }, 40)
      this.dialog2 = true
    },
    close(){
      this.dialog2 = false
    },
    close2(){
      this.$refs.rates_form.setClear2()
    },
    loadingBtn (val) {
      this.loading2 = val;
      this.$emit('disableBtn', val)
    },
    save2(){
      this.$refs.rates_form.saveUpdateRate()
    },
    saveEditSuccess () {
      this.$emit('saveEditSuccess')
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
