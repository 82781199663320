import selected from './SelectedService.vue'
import config from './ConfigService.vue'

export default {
  components:{
    'selected-serv':selected,
    'config-serv':config
  },
  props:{
    linked: {
      type:[Object, Number],
      required: true
    },
    mode: {
      type:String,
      required: true
    }
  },
  data(){
    return {
      sortBy:[],
      sortDesc:[],
      linkpk: null,
      services: [],
      // perPage: 10,
      // page: 1,
      totalValues: 0,
      dialog: true,
      servicesLoading: false,
      unwatch: ''
    }
  },
  computed:{
    computedService(){
      let services = []
      let packages = []
      this.services.forEach(m => {
        if(m.package == undefined || m.package == null){
          services.push({
            type:'service',
            id:m.id,
            name: m.service.parent != undefined ? `${m.service.parent.name} ${m.service.name}`:
            `${m.service.name}`,
            description: m.description,
            service_param:m.service_param
          })
        }
        else{
          let packInx = packages.findIndex(pack => pack.id == m.package_id)
          if(packInx == -1){
            packages.push({
              type:'package',
              id: m.package_id,
              name: m.package.name,
              description: m.package.description,
              services:[
                {
                  id:m.id,
                  name: m.service.parent != undefined ? `${m.service.parent.name} ${m.service.name}`:
                  `${m.service.name}`,
                  description: m.description,
                  service_param:m.service_param
                }
              ]
            })
          }
          else{
            packages[packInx].services.push({
              id:m.id,
              name: m.service.parent != undefined ? `${m.service.parent.name} ${m.service.name}`:
              `${m.service.name}`,
              description: m.description,
              service_param:m.service_param
            })
          }
        }
      })
      return services.concat(packages)
    },
    headers(){
      return this.mode == "package" ?
        [
          {
            text: this.$t('name',1),
            sortable: false ,
            value: 'service.name',
          },
          {
            text: this.$t('paymentNote.quantity'),
            sortable: false ,
            value: 'qty',
          },
          {
            text: this.$t('actions'),
            value: 'action',
            sortable: false ,
            align: 'right'
          }
        ]:
        [
          {
            text: this.$t('name',1),
            sortable: false,
            value: 'service.name',
          },
          {
            text: this.$t('paymentNote.quantity'),
            value: 'qty',
            sortable: false ,
            align: 'center'
          },
          {
            text: this.$tc('paymentNote.priceTotal',1),
            value: 'price',
            sortable: false ,
            align: 'right'
          },
          {
            text: this.$t('actions'),
            value: 'action',
            sortable: false,
            align: 'right'
          }
        ]
    },
    totalserv(){
      let total = (this.services.reduce(
        ( accumulator, currentValue ) =>
          accumulator + Number(currentValue.price),0)).toFixed(2)
      return total
    }
  },
  watch:{
    // sortDesc(){
    //   this.getServices()
    // },
    // perPage(){
    //   this.getServices()
    // },
    // page(){
    //   this.getServices()
    // }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val){
        this.getServices()
      }
    })
  },
  methods:{
    close(){
      this.$emit('back')
    },
    getServices(){
      this.servicesLoading = true
      let url = this.mode == "package" ?
        this.$store.getters.getPackageConfig():
        this.$store.getters.getContractServices()
      let param = {
        // page:this.page,
        paginate:'All',
      }
      param[this.mode == "package" ? "pack" : "contract"]  = this.linkpk
      this.mode != "package" ? param['draft_only'] = 'empty' : ''

      // if(this.sortDesc.length > 0){
      //   param['sortBy'] = this.sortBy[0]
      //   param['sortDesc'] = this.sortDesc[0]
      // }

      axios.get(url,{
        params:param
      })
      .then(response => {
        this.services = response.data.package
        // this.totalValues = response.data.package.total
        if(this.mode == "contract"){
          this.$emit('totalserv',this.services.length)
        }
      })
      .finally(()=>{
        this.servicesLoading = false
      })
    },
    removed(serv){
      let params = {}
      if(serv.type == 'package'){
        params.pack = serv.id
        params.contract = this.linkpk
      }
      this.$store.dispatch('confirmationValue',{
        show: true,
        title: this.$t('base.delete'),
        model:this.$t('service.config',{service:''}),
        action: this.$tc('deleted',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record:serv.name
        }),
        type: 'error',
        confirm:{
          url: this.mode == 'package' ?
            this.$store.getters.getPackageConfig(serv.id) :
            this.$store.getters.getContractServices(serv.id),
          method: 'delete',
          params: params
        },
        pos: 2
      })
    }
  },
  mounted(){
    this.linkpk = typeof(this.linked) == 'object' ? this.linked.id : this.linked
    this.getServices()
  },
  beforeDestroy () {
    this.unwatch()
  }
}
