 export default {
  name: "HistorySystemDialog",
  data() {
    return {
      history_dialog: false,
      fullscreen: false,
      no_data: false,
      logs: [],
      rate: ''
    }
  },
  methods:{
    open(item){
      this.history_dialog = true
      this.no_data = false
      this.rate = item
      this.getLogs()
    },
    close() {
      this.history_dialog = false
      this.fullscreen = false
      this.no_data = false
      this.logs = []
      this.rate = ''
    },
    decimalPlace(place){
      if (place) {
        if (typeof(place) == "number") {
          return place
        }
      } else if (this.rate.decimal_places){
        return this.rate.decimal_places;
      } else {
        return 2;
      }
    },
    getLogs () {
      axios.get(this.$store.getters.getActivity, {
        params:{
          log_name: 'ExchangeRate',
          subject_id: this.rate.id
        }
      })
        .then((response) => {
          setTimeout(() => {
            this.logs = response.data.logs
            if (this.logs.length == 0) {
              this.no_data = true
            }
          }, 400)
        })
    }
  }
}
