import CreateEditDialog from './Dialog.vue';
import HistoryDialog from './HistoryDialog.vue';

export default {
  name: "ExchangeRatesTable",
  components:{
    CreateEditDialog,
    HistoryDialog
  },
  props: {
    //
  },
  data (){
    return {
      no_data: false,
      ratesLoading: false,
      disableBtn: false,
      items2: [],
      nodata2: true,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      totalRates: 0,
      inactive_rates_only: false,
      permissions:{
        list_rates: false,
        create_rates: false,
        edit_rates: false,
        delete_rates: false,
        restore_rates: false,
      },
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'exchangeRate'){
        this.options.page = 1
        this.getExchangeRates()
      }
    })
  },
  mounted () {
    this.exRatesPolicies()
  },
  computed:{
    headers2() {
      return [
        {
          text: this.$t('name'),
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$tc('system.exchange_rate',1),
          align: 'center',
          sortable: false,
          value: 'rate',
          width: 140
        },
        {
          text: this.$t('system.icon'),
          align: 'center',
          sortable: false,
          value: 'icon',
          width: 20
        },
        {
          text: this.$t('date'),
          align: 'center',
          sortable: false,
          value: 'date',
          width: 110
        },
        {
          text: this.$t('Taxes.default'),
          align: 'center',
          sortable: false,
          value: 'default',
          width: 20
        },
        {
          text: this.$t('actions'),
          align: 'center',
          sortable: false ,
          value: 'action',
          width: 120
        }
      ]
    },
    title () {
      return this.inactive_rates_only ? 'inactive' : 'active'
    },
  },
  methods:{
    getExchangeRates(){
      let ops = {
        params: {
            'paginate': this.options.itemsPerPage,
            'page': this.options.page
          }
      }
      if (this.inactive_rates_only) {
        ops.params.inactive = true
      }
      this.ratesLoading = true
      axios
        .get(this.$store.getters.exchangeRates(), ops)
        .then(response => {
          if (response.data.success && response.data.success.data) {
            this.items2 = response.data.success.data
            this.totalRates = response.data.success.total
            this.totalRates > 0 ? this.nodata2 = false : this.nodata2 = true
          }
        })
        .catch(err=>{
          this.items2 = []
          this.totalRates = 0
          this.nodata2 = true
        })
        .finally(()=>{
          this.ratesLoading = false
        })
    },
    toggleInactiveRates(){
      this.items2 = []
      this.nodata2 = true
      this.inactive_rates_only = !this.inactive_rates_only
      this.options.page = 1
      this.getExchangeRates()
      this.$refs.create_edit_dialog.hide_btn = this.inactive_rates_only
    },
    openDialog (item = {}) {
      this.$refs.create_edit_dialog.open(item)
    },
    openHistory (item) {
      this.$refs.history_dialog.open(item)
    },
    deleteRestoreItem (item) {
      let title, action, content_action, type, method, url
      if (this.inactive_rates_only) {
        title = this.$t('activate')
        action = this.$tc('activated',2)
        content_action = this.$t('activate')
        type = 'success'
        method = 'patch'
        url = this.$store.getters.restoreExchangeRate(item.id)
      } else {
        title = this.$t('deactivate')
        action = this.$tc('deactivated',2)
        content_action = this.$t('deactivate')
        type = 'error'
        method = 'delete'
        url = this.$store.getters.exchangeRates(item.id)
      }
      this.$store.dispatch('confirmationValue',{
        template: 'exchangeRate',
        show: true,
        model: this.$tc('system.exchange_rate',1),
        title: title,
        pre: 'success',
        action: action,
        content: this.$t('base.confirm_delete',{
          action: content_action,
          record: item.name
        }),
        type: type,
        confirm:{
          url: url,
          method: method,
          data: {
            'reactivate': true
          }
        },
        pos: 2
      })
    },
    exRatesPolicies() {
      if (this.$store.getters.isAdmin){
        this.permissions = {
          list_rates: true,
          create_rates: true,
          edit_rates: true,
          delete_rates: true,
          restore_rates: true,
          list_codes: true,
          list_taxes: true,
          list_bank_accounts: true
        }
      } else {
        // Taxes
        this.permissions.list_taxes = this.getPermissionGroup({
          permission:[
            'index_taxes',
            'invoiceQueries_clientDocument'
          ],
          mode:'all'
        });
        // Exchange Rates
        this.permissions.list_rates = this.getPermissionGroup({
          permission:[
            'index_exchangeRate'
          ],
          mode:'all'
        });
        this.permissions.create_rates = this.getPermissionGroup({
          permission:[
            'index_exchangeRate',
            'store_exchangeRate',
          ],
          mode:'all'
        });
        this.permissions.edit_rates = this.getPermissionGroup({
          permission:[
            'index_exchangeRate',
            'update_exchangeRate',
          ],
          mode:'all'
        });
        this.permissions.delete_rates = this.getPermissionGroup({
          permission:[
            'index_exchangeRate',
            'destroy_exchangeRate',
          ],
          mode:'all'
        });
        this.permissions.restore_rates = this.getPermissionGroup({
          permission:[
            'index_exchangeRate',
            'restore_exchangeRate',
          ],
          mode:'all'
        });
      }
    },
    disableTableBtn(val){
      this.disableBtn = val;
    }
  },
  watch: {
    options: {
      handler () {
        this.getExchangeRates()
      },
      deep: true,
    },
  },
  beforeDestroy () {
    this.unwatch()
  }
}
