export let permission_es = {
  // acceptanceLetter
  'acceptanceLetter': 'Cartas de aceptación',
  'index_acceptanceLetter': 'Cartas de aceptación: Listar',
  'store_acceptanceLetter': 'Carta de aceptación: Crear',
  'show_acceptanceLetter': 'Carta de aceptación: Ver',
  'update_acceptanceLetter': 'Carta de aceptación: Actualizar',
  'destroy_acceptanceLetter': 'Carta de aceptación: Eliminar',
  'generatePDF_acceptanceLetter': 'Generar PDF de la carta de aceptación',
  'send_acceptanceLetter': 'Enviar carta de aceptación',
  // bankAccount
  'bankAccount': 'Cuentas Bancarias',
  'index_bankAccount': 'Cuenta Bancaria: Listar',
  'store_bankAccount': 'Cuenta Bancaria: Crear',
  'show_bankAccount': 'Cuenta Bancaria: Ver',
  'update_bankAccount': 'Cuenta Bancaria: Actualizar',
  'destroy_bankAccount': 'Cuenta Bancaria: Eliminar',
  // catalog
  'catalog': 'Configuración General',
  /* ---- Subscribers Groups ---- */
  'indexSubscribersGroups_catalog': 'Grupos de Abonados: Listar',
  'storeSubscribersGroups_catalog': 'Grupo de Abonados: Crear',
  'showSubscribersGroups_catalog': 'Grupo de Abonados: Ver',
  'updateSubscribersGroups_catalog': 'Grupo de Abonados: Actualizar',
  'destroySubscribersGroups_catalog': 'Grupo de Abonados: Eliminar',
  /* ---- Corporate Identity ---- */
  'indexCorporateIdentity_catalog': 'Identidad Corporativa: Listar',
  'storeCorporateIdentity_catalog': 'Identidad Corporativa: Crear',
  'updateCorporateIdentity_catalog': 'Identidad Corporativa: Actualizar',
  'destroyCorporateIdentity_catalog': 'Identidad Corporativa: Eliminar',
  // CDS
  'all_subscribers_cds': 'Todos los Abonados (CDS)',
  // clientDocument
  'clientDocument': 'Documentos del cliente',
  'clientDocumentTypes_clientDocument': 'Listar tipos de documentos|Tipos de documentos: cotizacion, nota de cobro, nota de credito, factura',
  'clientDocumentStatus_clientDocument': 'Listar estados de documentos|Estados de documentos: pendiente, pagado, cancelado, acptado, anulado, replicada',
  'invoiceQueries_clientDocument': 'Datos esenciales para facturación|Listas asociadas a documentos: Bancos, moneda base, tasas de cambio, tipos de conceptos, lista de impuestos, tipos de cliente',
  'invoiceableContracts_clientDocument': 'Fact. Busqueda de Contratos facturables|Devuelve los contratos los que se les puede generar un documento',
  'getHeader_clientDocument': 'Número de control para crear documento|Método que obtiene el consecutivo de un número de control para un documento a crear',
  'clientDocumentHeader_clientDocument': 'Fact. Nuevo número de control para crear documento| Método usado para solicitar un nuevo número de control y devuelve el consecutivo correspondiente',
  'getDates_clientDocument': 'Fechas asociadas a un documento|Obtiene las fechas de creación, vencimiento y corte asociadas a un documento',
  'index_clientDocument': 'Fact. Documentos: Listar',
  'store_clientDocument': 'Fact. Documentos: Crear',
  'clientDocumentConcepts_clientDocument': 'Asociar conceptos a un nuevo documento|Guarda y asocia los conceptos durante la creación de un nuevo documento',
  'show_clientDocument': 'Fact. Documentos: Ver',
  'update_clientDocument': 'Fact. Documentos: Actualizar',
  'clientDocumentConceptsUpdate_clientDocument': 'Actualizar conceptos asociados a un documento|Actualiza los conceptos al actualizar el documento correspondiente',
  'getControlNumber_clientDocument': 'Número de control de un documento|Obtiene el número de control de un documento seleccionado',
  'checkEquipmentMaterials_clientDocument': 'Actualiza conceptos tipo material/equipo asociados a un documento|Actualiza los conceptos por compra de materiales o equipos al actualizar el documento correspondiente',
  'getClientDocument_clientDocument': 'Fact. Cotización o una nota de cobro abonado corporativo|Para una empresa, crea o devuelve una Cotización o una nota de cobro',
  'getFranchiseClientDocument_clientDocument': 'Cotización o una nota de cobro de Unidad de Negocio|Para una Unidad de Negocio, crea o devuelve una Cotización o una nota de cobro',
  'getContractsByParameters_clientDocument': 'Fact. Solicitudes: Filtro de contratos|Filtra los contratos usando los parámetros recibidos',
  'getClientDocumentContract_clientDocument': 'Documento asociado a un contrato|Devuelve el documento asociado a un contrato según las características buscadas',
  'createFromContract_clientDocument': 'Nota de cobro en estado pendiente|Devuelve una nota de cobro en estado pendiente haciendo uso de los conceptos asociados al contrato',
  'createFromDocument_clientDocument': 'Replica de documento|Replica un documento en base a un documento similar tomando en cuenta características como cambio del número de control y cambio de fechas',
  'getAmounts_clientDocument': 'Calculo de montos|Calcula los montos asociados a un documento y devuelve',
  'makeClientDocumentPDF_clientDocument': 'Validar antes de Generar un PDF|Valida datos asociados al contrato, documento y usuario antes de generar un PDF del documentos seleccionado',
  'makePDF_clientDocument': 'Generar un PDF del documentos seleccionado|Genera un PDF luego de calcular y organizar los datos a imprimir en ese archivo',
  'sendClientDocumentEmail_clientDocument': 'Validar antes de Enviar correo electrónico|Valida datos asociados al contrato, documento y usuario antes de enviar por correo electrónico un PDF del documento seleccionado',
  'sendEmail_clientDocument': 'Enviar documento',
  'sendEmailMassively_clientDocument': 'Envio masivo de documentos',
  'access_clientDocument': 'Documentos: Acceso a módulo de facturación.',
  // clientDocumentControl
  'clientDocumentControl': 'Números de Control de Documentos del cliente',
  'index_clientDocumentControl': 'Números Control de Documentos: Listar',
  'store_clientDocumentControl': 'Número de Control de Documentos: Crear',
  'show_clientDocumentControl': 'Número de Control de Documentos: Ver',
  'update_clientDocumentControl': 'Número de Control de Documentos: Actualizar',
  'destroy_clientDocumentControl': 'Número de Control de Documentos: Eliminar',
  // concept
  'concept': 'Conceptos',
  'conceptTypes_concept': 'Fact. Conceptos: Listar tipos',
  'index_concept': 'Fact. Conceptos: Listar',
  'store_concept': 'Fact. Conceptos: Crear',
  'show_concept': 'Fact. Conceptos: Ver',
  'update_concept': 'Fact. Conceptos: Actualizar',
  'destroy_concept': 'Fact. Conceptos: Eliminar',
  // conceptMotive
  'conceptMotive': 'Motivos de conceptos',
  'index_conceptMotive': 'Fact. Motivos de conceptos: Listar',
  'store_conceptMotive': 'Fact. Motivos de conceptos: Crear',
  'show_conceptMotive': 'Fact. Motivos de conceptos: Ver',
  'update_conceptMotive': 'Fact. Motivos de conceptos: Editar',
  'destroy_conceptMotive': 'Fact. Motivos de conceptos: Eliminar',
  // contractAddress
  'contractAddress': 'Direcciones del Contrato',
  'fiscal_contractAddress': 'Dirección del Contrato: Fiscal',
  'index_contractAddress': 'Direcciones del Contrato: Listar',
  'store_contractAddress': 'Dirección del Contrato: Añadir',
  'show_contractAddress': 'Dirección del Contrato: Ver',
  'update_contractAddress': 'Dirección del Contrato: Actualizar',
  'destroy_contractAddress': 'Dirección del Contrato: Eliminar',
  // contractContact
  'contractContact': 'Contactos del Contrato',
  'index_contractContact': 'Contactos del Contrato: Listar',
  'store_contractContact': 'Contacto del Contrato: Añadir',
  'show_contractContact': 'Contacto del Contrato: Ver',
  'update_contractContact': 'Contacto del Contrato: Actualizar',
  'destroy_contractContact': 'Contacto del Contrato: Eliminar',
  // contract
  'contract': 'Contratos',
  'index_contract': 'Contratos: Listar',
  'get_contract': 'Contratos del Abonado: Ver',
  'store_contract': 'Contrato: Añadir',
  'show_contract': 'Contrato: Ver',
  'update_contract': 'Contrato: Actualizar',
  'destroy_contract': 'Contrato: Eliminar',
  'contractAddrContactServ_contract': 'Ver Direcciones y Contactos de Contrato',
  'askingEmail_contract': 'Enviar correo a Contactos del Contrato',
  'requestInfo_contract': 'Ver detalles de Solicitud de Servicio en curso|Permite acceder a la vista de Solicitud de Servicio',
  'generateFeasibilityOrderPDF_contract': 'Generar PDF de Orden de Verificación de Factibilidad de Servicio',
  'sendFeasibilityOrderPDF_contract': 'Enviar Orden de Verificación de Factibilidad de Servicio',
  'changeBilling_contract': 'Cambiar tipo de Facturación de Contrato',
  'filterPerServiceTotals_contract': 'Contratos por Servicio: Totales',
  'filterPerServiceDetails_contract': 'Contratos por Servicio: Detalles',
  'filterPerServiceAmounts_contract': 'Montos por Servicio: Totales',
  'filterPerServiceAmountsDetails_contract': 'Montos por Servicio: Detalles',
  'getAllPerService_contract': 'Contratos por Servicio: TODOS',
  'unrestrictedSubscriberSearch_contract': 'Búsqueda irrestricta de Abonados',
  // contractInstalledHardware
  'contractInstalledHardware': 'Hardware instalado por Contrato',
  'index_contractInstalledHardware': 'Hardware instalado en servicios: Listar',
  'store_contractInstalledHardware': 'Hardware instalado en un servicio: Añadir',
  'show_contractInstalledHardware': 'Hardware instalado en un servicio: Ver',
  'update_contractInstalledHardware': 'Hardware instalado en un servicio: Actualizar',
  'destroy_contractInstalledHardware': 'Hardware instalado en un servicio: Eliminar',
  // contractService
  'contractService': 'Servicios de un Contrato',
  'index_contractService': 'Servicios de Contrato: Listar',
  'store_contractService': 'Servicio de Contrato: Añadir',
  'show_contractService': 'Servicio de Contrato: Ver',
  'update_contractService': 'Servicio de Contrato: Actualizar',
  'destroy_contractService': 'Servicio de Contrato: Eliminar',
  'storeWithPack_contractService': 'Añadir Paquete a un Contrato',
  'editWan_contractService': 'Servicio de Contrato: Editar configuraciones',
  'viewServicePrice_contractService': 'Servicio de Contrato: Ver Precio',
  'overrideStatus_contractService': 'Servicio de Contrato: Predominar cambio de estado',
  'overrideService_contractService': 'Servicio de Contrato: Predominar Cambio Serv./Paq.',
  // contractServiceParam
  'contractServiceParam': 'Parametros de un Servicio en el Contrato',
  'index_contractServiceParam': 'Parámetros de un servicio en el Contrato: Listar',
  'store_contractServiceParam': 'Parámetros de un servicio en el Contrato: Añadir',
  'show_contractServiceParam': 'Parámetro de un servicio en el Contrato: Ver',
  'update_contractServiceParam': 'Parámetros de un servicio en el Contrato: Actualizar',
  'destroy_contractServiceParam': 'Parámetros de un servicio en el Contrato: Eliminar',
  // dashboard
  'dashboard': 'Tablero',
  'viewCards_dashboard': 'Ver Tarjetas del Tablero',
  // equipmentMaterials
  'equipmentMaterials': 'Materiales y Equipos',
  'index_equipmentMaterials': 'Materiales y/o Equipos: Listar',
  'store_equipmentMaterials': 'Material y/o Equipo: Crear',
  'show_equipmentMaterials': 'Material y/o Equipo: Ver',
  'update_equipmentMaterials': 'Material y/o Equipo: Actualizar',
  'destroy_equipmentMaterials': 'Material y/o Equipo: Eliminar',
  // employee
  'employee': 'Empleados',
  'employeeTypes_employee': 'Listar los tipos de empleado',
  'index_employee': 'Empleados: Listar',
  'store_employee': 'Empleados: Crear',
  'show_employee': 'Empleado: Ver',
  'update_employee': 'Empleado: Actualizar',
  'destroy_employee': 'Empleado: Eliminar',
  'attachContracts_employee': 'Asignar contrato a un Empleado',
  'unattachContracts_employee': 'Denegar contrato a un Empleado',
  'getAttachedContracts_employee': 'Lista los contratos de un Empleado',
  'getUnattachedContracts_employee': 'Lista los contratos no asignados a un Empleado',
  'listAllForPayment_employee': 'Listar a todos los Cobradores al registrar Pagos',
  'changeExternalCollector_employee': 'Cambiar Cobrador Externo',
  'assign_contracts': 'Asignar contratos',
  'deassign_contracts': 'Designación de contratos',
  // exchangeRate
  'exchangeRate': 'Tasas de Cambio',
  'index_exchangeRate': 'Tasas de Cambio: Listar',
  'store_exchangeRate': 'Tasa de Cambio: Crear',
  'show_exchangeRate': 'Tasa de Cambio: Ver',
  'update_exchangeRate': 'Tasa de Cambio: Actualizar',
  'destroy_exchangeRate': 'Tasa de Cambio: Desactivar',
  'restore_exchangeRate': 'Tasa de Cambio: Activar',
  'listAll_exchangeRate': 'Tasas de Cambio: Listar Todos',
  'currencyCodes_exchangeRate': 'Listar códigos de Moneda',
  // feasibilityNetwork
  'feasibilityNetwork': 'Informe de Factibilidad de Redes',
  'index_feasibilityNetwork': 'Informes de Factibilidad de Redes: Listar',
  'store_feasibilityNetwork': 'Informe de Factibilidad de Redes: Crear',
  'show_feasibilityNetwork': 'Informe de Factibilidad de Redes: Ver',
  'update_feasibilityNetwork': 'Informe de Factibilidad de Redes: Actualizar',
  'destroy_feasibilityNetwork': 'Informe de Factibilidad de Redes: Eliminar',
  'list_feasibilityNetwork': 'Informes de Factibilidad de Redes activos o culminados: Listar',
  'changestatus_feasibilityNetwork': 'Informe de Factibilidad de Redes: Aprobar',
  'infeasibleService_feasibilityNetwork': 'Informe de Factibilidad de Redes: Denegar',
  // feasibilityReport
  'feasibilityReport': 'Informes de Factibilidad de Fibra',
  'index_feasibilityReport': 'Informes de Factibilidad de Fibra: Listar',
  'store_feasibilityReport': 'Informe de Factibilidad de Fibra: Crear',
  'show_feasibilityReport': 'Informe de Factibilidad de Fibra: Ver',
  'update_feasibilityReport': 'Informe de Factibilidad de Fibra: Actualizar',
  'destroy_feasibilityReport': 'Informe de Factibilidad de Fibra: Eliminar',
  'list_feasibilityReport': 'Informes de Factibilidad de Fibra activos o culminados: Listar',
  'changestatus_feasibilityReport': 'Informe de Factibilidad de Fibra: Aprobar',
  'infeasibleService_feasibilityReport': 'Informe de Factibilidad de Fibra: Denegar',
  // franchise
  'franchise': 'Unidad de Negocio',
  'index_franchise': 'Unidades de Negocio: Listar',
  'store_franchise': 'Unidad de Negocio: Crear',
  'update_franchise': 'Unidad de Negocio: Actualizar',
  'destroy_franchise': 'Unidad de Negocio: Eliminar',
  'activateFranchise_franchise': 'Unidad de Negocio: Activar',
  'configurateFranchise_franchise': 'Unidad de Negocio: Configuración|Agregar/Editar paquetes y tipos de suscriptores por Unidad de Negocio',
  // inspection
  'inspection': 'Inspecciones',
  'index_inspection': 'Inspecciones: Listar',
  'store_inspection': 'Inspección: Crear',
  'show_inspection': 'Inspección: Ver',
  'update_inspection': 'Inspección: Actualizar',
  'destroy_inspection': 'Inspección: Eliminar',
  // moneyReceived
  'moneyReceived': 'Dinero Recibido',
  'index_moneyReceived': 'Dinero Recibido: Listar',
  'store_moneyReceived': 'Dinero Recibido: Crear',
  'show_moneyReceived': 'Dinero Recibido: Ver',
  'update_moneyReceived': 'Dinero Recibido: Actualizar',
  'destroy_moneyReceived': 'Dinero Recibido: Eliminar',
  'changeStatus_moneyReceived': 'Dinero Recibido: Cambiar Estado',
  'pending_moneyReceived': 'Dinero Recibido: Ver Pendientes',
  'processed_moneyReceived': 'Dinero Recibido: Ver Procesadas',
  'consolidated_moneyReceived': 'Dinero Recibido: Ver Consolidadas',
  'rejected_moneyReceived': 'Dinero Recibido: Ver Rechazadas',
  // oldBillingHistory
  'oldBillingHistory': 'Historial de Facturación Antigua',
  'index_oldBillingHistory': 'Historial de Facturación Antigua: Listar',
  'store_oldBillingHistory': 'Historial de Facturación Antigua: Crear',
  'show_oldBillingHistory': 'Historial de Facturación Antigua: Ver',
  'update_oldBillingHistory': 'Historial de Facturación Antigua: Actualizar',
  'destroy_oldBillingHistory': 'Historial de Facturación Antigua: Eliminar',
  // proofPayment
  'proofPayment': 'Pagos',
  'index_proofPayment': 'Lista de Pagos',
  'store_proofPayment': 'Crear/realizar un Pago',
  'getInvoice_proofPayment': 'Validar datos y crear factura|Valida los datos asociados al pago de una nota de cobro, verifica si es un pago parcial o completo. Genera los conceptos de abono o pago y los asocia a la nota de cobro y en caso de ser un pago completo, hace el llamado al método de creación de la factura',
  'createInvoice_proofPayment': 'Crea una factura cuando se paga una nota de cobro',
  'createAdvancedDocument_proofPayment': 'Crear nota de cobro adelantada|En base a la nota de cobro pagada, busca o crea una nota de cobro correspondiente al siguiente mes de facturación, a la cual se le realizará el pago por adelantado',
  'createServiceInstallation_proofPayment': 'Crea una orden de instalación de servicios|Crea una orden de instalación de servicios en estado borrador y el PDF de esta orden',
  'sendInstallationOrderPDF_proofPayment': 'Envia orden de instalación de servicios|Envia un correo electrónico con la orden de instalación de servicios',
  // Reports
  'report': 'Reportes',
  'module_base_permissions': 'Permisos base del módulo',
  'getSalesBook_report': 'Reporte: Libro de Ventas',
  'getVoidList_report': 'Reporte: Listado de Anulaciones',
  'incomeByDate_report': 'Reporte: Ingreso General por Fecha',
  'incomeByCollector_report': 'Reporte: Ingreso General por Cobrador',
  'incomeByConcept_report': 'Reporte: Resumen de Ingresos por Concepto',
  'dailyIncome_report': 'Reporte: Ingreso Diario',
  'incomeBySector_report': 'Reporte: Ingresos por Sector',
  'incomeByConceptGroupBy_report': 'Reporte: Ingresos agrupados por Servicio',
  'collectionList_report': 'Reporte: Listado de Cobranza',
  'billingDetailByConcept_report': 'Reporte: Detalle de Facturación por Concepto',
  'dailyCashRegister_report': 'Cuadre diario de Caja (Interno)',
  'dailyCashRegisterExternal_report': 'Cuadre diario de Caja (Externo)',
  // sector
  'sector': 'Sectores',
  'index_sector': 'Sector: Listar',
  'store_sector': 'Sector: Crear',
  'show_sector': 'Sector: Ver',
  'update_sector': 'Sector: Actualizar',
  'destroy_sector': 'Sector: Eliminar',
  'parishSectors_sector': 'Sectores por Parroquia: Listar',
  // service
  'service': 'Servicios',
  'index_service': 'Servicios: Listar',
  'store_service': 'Servicio: Crear',
  'show_service': 'Servicio: Ver',
  'update_service': 'Servicio: Actualizar',
  'destroy_service': 'Servicio: Eliminar',
  // serviceInstallation
  'serviceInstallation': 'Instalación de Servicios',
  'index_serviceInstallation': 'Instalaciones de Servicio: Listar',
  'store_serviceInstallation': 'Instalación de Servicio: Crear',
  'show_serviceInstallation': 'Instalación de Servicio: Ver',
  'update_serviceInstallation': 'Instalación de Servicio: Actualizar',
  'destroy_serviceInstallation': 'Instalación de Servicio: Eliminar',
  'addExtension_serviceInstallation': 'Añadir extensión del periodo de pruebas',
  'generateDeliveryCertificatePDF_serviceInstallation': 'Generar acta de entrega de servicios y equipos',
  'generateAcceptanceLetterPDF_serviceInstallation': 'Generar carta de aceptación de servicios y equipos',
  'getDeliveryCertificate_serviceInstallation': 'Consultar archivo de acta de entrega de servicios y equipos',
  'getAcceptanceLetter_serviceInstallation': 'Consultar archivo de carta de aceptación de servicios y equipos',
  'sendDelivery_serviceInstallation': 'Enviar acta de entrega de servicios y equipos',
  'sendLetter_serviceInstallation': 'Enviar carta de aceptación de servicios y equipos por correo',
  'indexActivationPending_serviceInstallation': 'Instalaciones de Servicio pendientes por activar: Listar',
  'indexAllActivationPending_serviceInstallation': 'Instalaciones de Servicio pendientes por activar: Listar TODAS',
  'filterAllTotals_serviceInstallation': 'Instalaciones de Servicio por Fecha: Totales',
  'getAllInstallations_serviceInstallation': 'Instalaciones de Servicio por Fecha: TODAS',
  'inputInstallationDate_serviceInstallation': 'Instalación de Servicio: Modificar Fecha de Instalación',
  'inputBillingStartDate_serviceInstallation': 'Instalación de Servicio: Fecha de Inicio de Facturación',
  // servicePackageConfig
  'servicePackageConfig': 'Configuración de Paquete de Servicios',
  'index_servicePackageConfig': 'Servicios de un Paquete: Listar',
  'store_servicePackageConfig': 'Servicio de un Paquete: Añadir',
  'show_servicePackageConfig': 'Servicio de un Paquete: Ver',
  'update_servicePackageConfig': 'Servicio de un Paquete: Actualizar',
  'destroy_servicePackageConfig': 'Servicio de un Paquete: Eliminar',
  // servicePackage
  'servicePackage': 'Paquete de Servicios',
  'index_servicePackage': 'Paquetes de Servicios: Listar',
  'store_servicePackage': 'Paquete de Servicios: Crear',
  'show_servicePackage': 'Paquete de Servicios: Ver',
  'update_servicePackage': 'Paquete de Servicios: Actualizar',
  'destroy_servicePackage': 'Paquete de Servicios: Eliminar',
  // serviceParamConfig
  'serviceParamConfig': 'Configuración de Parámetros de un Servicio',
  'index_serviceParamConfig': 'Parámetros de un Servicio en el Paquete: Listar',
  'store_serviceParamConfig': 'Parámetro de un Servicio en el Paquete: Crear',
  'show_serviceParamConfig': 'Parámetro de un Servicio en el Paquete: Ver',
  'update_serviceParamConfig': 'Parámetro de un Servicio en el Paquete: Actualizar',
  'destroy_serviceParamConfig': 'Parámetro de un Servicio en el Paquete: Eliminar',
  // serviceParam
  'serviceParam': 'Parámetros de un Servicio',
  'index_serviceParam': 'Parámetros de un Servicio: Listar',
  'store_serviceParam': 'Parámetro de un Servicio: Crear',
  'show_serviceParam': 'Parámetro de un Servicio: Ver',
  'update_serviceParam': 'Parámetro de un Servicio: Actualizar',
  'destroy_serviceParam': 'Parámetro de un Servicio: Eliminar',
  // subscriber
  'subscriber': 'Abonados',
  'getByDoc_subscriber': 'Ver Abonado por su CI o RIF',
  'full_subscriber': 'Ver información completa del Abonado',
  'index_subscriber': 'Abonados: Listar',
  'store_subscriber': 'Abonado: Crear',
  'show_subscriber': 'Abonado: Ver',
  'update_subscriber': 'Abonado: Actualizar',
  'destroy_subscriber': 'Abonado: Eliminar',
  'changeProspectCondition_subscriber': 'Abonado: Cambiar condición Prospecto',
  'inject_old_subscriber': 'Abonado: Migrar',
  'editDni_subscriber': 'Abonado: Editar ID o RIF',
  'editCode_subscriber': 'Abonado: Editar N° Abonado',
  'filterAll_subscriber': 'Abonados: Filtrar Todos',
  'allCDS_subscriber': 'Abonados: Ver Todos CDS',
  'getOverdueCutoff_subscriber': 'Deuda Vencida y Candidatos a Corte: Listar',
  'allOverdueCutoff_subscriber': 'Deuda Vencida y Candidatos a Corte: Listar Todos',
  'getDebtors_subscriber': 'Deuda Total por Abonado: Listar',
  'allDebtors_subscriber': 'Deuda Total por Abonado: Listar Todos',
  'exportListAll_subscriber': 'Abonados: Exportar Todos XLS',
  'viewBilling_subscriber': 'Abonado: Ver detalles de Facturación',
  'overwriteBalance_subscriber': 'Abonado: Modificar Saldo',
  'setGroupID_subscriber': 'Abonado: Establecer Grupo',
  'togglePermanentlyRetired_subscriber': 'Abonado: Retirar definitivamente',
  // subscriberFileLibrary
  'subscriberFileLibrary': 'Librería de Archivos del Abonado',
  'index_subscriberFileLibrary': 'Archivos del Abonado: Listar',
  'store_subscriberFileLibrary': 'Archivo del Abonado: Crear',
  'show_subscriberFileLibrary': 'Archivo del Abonado: Ver',
  'update_subscriberFileLibrary': 'Archivo del Abonado: Actualizar',
  'destroy_subscriberFileLibrary': 'Archivo del Abonado: Eliminar',
  // taxes
  'taxes': 'Impuestos',
  'index_taxes': 'Impuestos: Listar',
  'store_taxes': 'Impuesto: Crear',
  'show_taxes': 'Impuesto: Ver',
  'update_taxes': 'Impuesto: Actualizar',
  'destroy_taxes': 'Impuesto: Eliminar',
  // typeClient
  'typeClient': 'Tipos de Cliente',
  'index_typeClient': 'Tipos de Cliente: Listar',
  'store_typeClient': 'Tipo de Cliente: Crear',
  'show_typeClient': 'Tipo de Cliente: Ver',
  'update_typeClient': 'Tipo de Cliente: Actualizar',
  'destroy_typeClient': 'Tipo de Cliente: Eliminar',
  'getExonerated_typeClient': 'Tipo de Cliente "Exonerado": Listar',
  // voidedNumber
  'voidedNumber': 'Números de Control Anulados',
  'index_voidedNumber': 'Números de Control Anulados: Listar',
  'store_voidedNumber': 'Número de Control: Anular',
  'destroy_voidedNumber': 'Número de Control: Restaurar',
  // Zones
  'zone': 'Zonas',
  'index_zone': 'Zona: Listar',
  'store_zone': 'Zona: Crear',
  'show_zone': 'Zona: Ver',
  'update_zone': 'Zona: Actualizar',
  'destroy_zone': 'Zona: Eliminar',
}
