<template>
  <v-dialog
    v-model="dialogDebt"
    scrollable
    max-width="680px"
    transition="dialog-transition"
    @click:outside="closeDebt"
  >
    <v-card>
      <toolbarDialog
        :showOnlyTitle="'otro'"
        :title="$tc('subscribers.subscriber_debt', 1)"
      />
      <v-card-text>
        <v-row no-gutters class="mt-n5">
          <v-col
            cols="12"
            v-for="(item, i) in contracts"
            :key="'contracts-card-' + i"
            class="contratc-item"
          >
            <v-row no-gutters>
              <v-col cols="12" class="mb-3">
                <v-card
                  outlined
                  class="elevation-0 step-inner mx-auto card-outlined"
                >
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white darker">
                        {{ $tc("contracts.contracts", 1) }}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-0 px-0 pb-2">
                      <v-row>
                        <v-col cols="12" sm="2" class="py-0">
                          ID: {{ item.id }}
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                          Code: {{ item.code }}
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                          <template v-if="item.franchise">
                            {{ item.franchise.full_name }}
                          </template>
                          <template v-else>
                            {{ $t("subscribers.corporate") }}
                          </template>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0 text-right">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <strong class="red--text" v-on="on">
                                <span v-if="!$vuetify.breakpoint.smAndUp">Total: </span>
                                {{ currency_symbol }}
                                {{ (item.total * rateValue) | formatAmount(decimal_places) }}
                              </strong>
                            </template>
                            <span> {{ $t('subscribers.total_debt_contract') }} </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="items-thead">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    :sm="i === 0 ? 2 : i === 1 ? 4 : 3"
                    v-for="(itemHeader, i) in headers"
                    :key="'header-' + i"
                  >
                    {{ itemHeader.text }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                v-for="(itemDoc, i) in item.client_documents"
                :key="'client_documents-' + i"
                class="items-tbody"
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="2" :data-label="headers[0].text">
                    {{ itemDoc.id }}
                  </v-col>
                  <v-col cols="12" sm="4" :data-label="headers[1].text">
                    {{ formatDate(itemDoc.due_date) }}
                  </v-col>
                  <v-col cols="12" sm="3" :data-label="headers[2].text">
                    {{ formatDate(itemDoc.cutoff_date) }}
                  </v-col>
                  <v-col cols="12" sm="3" :data-label="headers[3].text">
                    {{ currency_symbol }}
                    {{ (itemDoc.amount * rateValue) | formatAmount(decimal_places) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <pre> {{contracts}} </pre> -->
      </v-card-text>
      <v-card-actions>
        <div class="pl-4">
          <strong>
            Total:
            <span class="red--text">
              {{ currency_symbol }} {{ total | formatAmount(decimal_places) }}
            </span>
          </strong>
        </div>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="!fullscreen"
          text
          cancel
          :disabled="loadingDebt"
          @click="closeDebt"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">
            {{ $t("close") }}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "debtDialog",
  components: {},
  props: {
    currency_symbol: {
      type: String,
      default: ""
    },
    decimal_places: {
      type: Number,
      default: 2
    },
    rateValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogDebt: false,
      loadingDebt: false,
      disableDebt: false,
      fullscreen: false,
      total: 0,
      contracts: []
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          value: "id",
          text: "N°",
          align: "center"
        },
        {
          value: "due_date",
          text: this.$t("billing.date_due"),
          align: "center"
        },
        {
          value: "cutoff_date",
          text: this.$t("billing.date_cutoff"),
          align: "center"
        },
        {
          value: "amount",
          text: this.$t("Payments.amount"),
          align: "center"
        }
      ];
      return headers;
    }
  },
  methods: {
    openDebt(data) {
      this.dialogDebt = true;
      if (data.contracts) {
        let contracts = data.contracts;
        contracts.map(contract => {
          let total_contract = contract.client_documents.reduce(
            (total, doc) => total + Number(doc.amount),
            0
          );
          contract.total = total_contract;
        });
        this.contracts = contracts;
        this.total = data.total_debt;
      }
      // eslint-disable-next-line no-console
      // console.log(data);
    },
    closeDebt() {
      this.dialogDebt = false;
      this.contracts = [];
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
::v-deep {
  .items {
    &-thead {
      color: rgba(black, 0.6);
      font-weight: bold;
      padding: 4px;
      @at-root {
        .theme--dark & {
          color: rgba(white, 0.7);
        }
      }
      @media (max-width: 599px) {
        display: none;
      }
      .row [class^="col"] {
        padding: 2px;
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
    &-tbody {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 4px;
      div[data-label] {
        &:not(:first-child) {
          @media (min-width: 600px) {
            text-align: center;
          }
        }

        @media (max-width: 599px) {
          display: flex;
          justify-content: space-between;
          &:before {
            content: attr(data-label);
            float: left;
            font-weight: 600;
            color: rgba(black, 0.87);
            font-size: 14px;
            @at-root {
              .theme--dark & {
                color: rgba(white, 0.7);
              }
            }
          }
        }
      }
    }
  }
}
</style>
