<template>
  <div :class="classParent" :style="styleParent">
    <v-row no-gutters
      v-if="total_corporate > 0 && total_franchise > 0">
      <template v-for="(item, i) in subTotals">
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : ''"
          :key="'total-card-' + i"
          class="total-card"
          v-if="item.show"
        >
          <v-card tile class="elevation-0">
            <v-list-item-content class="text-center py-0">
              <v-list-item-title class="title title-main">
                {{ $t(item.title) }}
              </v-list-item-title>
              <v-list-item-subtitle class="red--text">
                {{ currency_symbol }}
                {{ (item.total * rateValue) | formatAmount(decimal_places) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-card tile class="elevation-0 py-1">
      <div
        class="d-flex title mx-2"
        :class="
          $vuetify.breakpoint.xs ? 'flex-column align-center' : 'flex-row justify-end'
        "
      >
        <div class="font-weight-black pr-1">Total:</div>
        <div class="red--text">
          {{ currency_symbol }}
          {{ (total * rateValue) | formatAmount(decimal_places) }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ShowTotal",
  components: {},
  props: {
    // Class parent css
    classParent: {
      type: String,
      default: ""
    },
    // Class parent css
    styleParent: {
      type: String,
      default: ""
    },
    currency_symbol: {
      type: String,
      default: ""
    },
    total: {
      type: Number,
      default: 0
    },
    total_corporate: {
      type: Number,
      default: 0
    },
    total_franchise: {
      type: Number,
      default: 0
    },
    decimal_places: {
      type: Number,
      default: 2
    },
    rateValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      subTotals: [
        {
          title: "table.corporate",
          total: this.total_corporate,
          show: this.total_corporate > 0
        },
        {
          title: "table.massives",
          total: this.total_franchise,
          show: this.total_franchise > 0
        }
      ],
      franchise_id:
        this.$store.getters.getFranchiseID == null
          ? 0
          : Number(this.$store.getters.getFranchiseID)
    };
  },
  methods: {},
  watch: {},
  created() {},
  mounted() {}
};
</script>
