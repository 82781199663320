var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('active',1))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-check")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('overrided',3))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-ban")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.DocumentControl,"footer-props":{
                      'items-per-page-options': [5, 10, 50]
                    },"search":_vm.search,"loading":_vm.loadingTable,"items-per-page":_vm.perPage,"server-items-length":_vm.totalValues,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":!_vm.expand && _vm.step =='tab-1' && _vm.policies.create,"disableNew":_vm.loadingTable,"title":_vm.$t('document_control.document_control'),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newDocumentControl}}),_c('v-row',{staticClass:"filter-generics",class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-1' :'ma-0 pa-0'},[_c('v-col',{staticClass:"py-0 mb-md-0",attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-row',{staticClass:"filter-generics-row",attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.franchise_id==0),expression:"franchise_id==0"}],staticClass:"generics-select"},[_c('v-select',{attrs:{"name":"type","items":_vm.selectedDocumentTypes,"disabled":_vm.loadingTable,"loading":_vm.loading_doc_type,"item-text":"text","item-value":"value","clear-icon":"fas fa-times","prepend-icon":"fas fa-file-invoice","label":_vm.$t('document_control.type'),"data-vv-as":_vm.$t('document_control.type')},on:{"change":_vm.SearchFilter},model:{value:(_vm.searches.type),callback:function ($$v) {_vm.$set(_vm.searches, "type", $$v)},expression:"searches.type"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.franchise_id==0),expression:"franchise_id==0"}],staticClass:"generics-select"},[_c('v-select',{attrs:{"name":"franchise_id","items":_vm.ListFranchises,"disabled":_vm.loadingTable,"loading":_vm.loading_franchise,"item-text":"name","item-value":"id","clearable":"","clear-icon":"fas fa-times","prepend-icon":"fas fa-building","label":_vm.$t('document_control.franchise'),"data-vv-as":_vm.$t('document_control.franchise')},on:{"change":_vm.SearchFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : '')+" ")]}},{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : '')+" ")]}}]),model:{value:(_vm.searches.franchise_id),callback:function ($$v) {_vm.$set(_vm.searches, "franchise_id", $$v)},expression:"searches.franchise_id"}})],1),_c('v-col',{staticClass:"generics-select"},[_c('EmployeeSearch',{ref:"employee_filter",attrs:{"disabled_field":_vm.loadingTable,"ClassField":"''","prepend_icon_field":"fas fa-user-tie"},on:{"change":_vm.SearchEmployee,"clearing":_vm.EraseFilter},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1)],1)],1),_c('v-col',{staticClass:"py-0 mb-6 mb-md-0 filter-button",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"filterErase justify-content-between",attrs:{"text":"","small":""},on:{"click":_vm.EraseFilter}},on),[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndUp),expression:"!$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t('clean'))+" ")]),_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("fas fa-filter")]),_c('v-icon',{staticClass:"filterIconEraser",attrs:{"small":"","color":"grey darken-1 filterErase py-0 mx-0"}},[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('clean')))])])],1)],1)]},proxy:true},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('document_control.types.'+item.type))+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.step =='tab-1' && _vm.policies.edit),expression:"step =='tab-1' && policies.edit"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.step =='tab-1' && _vm.franchise_id==0 && _vm.policies.override),expression:"step =='tab-1' && franchise_id==0 && policies.override"}],attrs:{"delete":"","icon":""},on:{"click":function($event){return _vm.Override(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ban")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.override')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.step =='tab-2' && _vm.franchise_id==0 && _vm.policies.reset),expression:"step =='tab-2' && franchise_id==0 && policies.reset"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.ResetDoc(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-toggle-on ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('reset')))])])]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('DialogDocControl',{ref:"documen_control",on:{"updateList":_vm.updateList}}),_c('DialogOverride',{ref:"override_doc",on:{"updateList":_vm.updateList}}),_c('DialogResetDoc',{ref:"reset_doc",on:{"updateList":_vm.updateList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }