<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    hide-overlay
    content-class="preview-company"
  >
    <v-app>
      <v-navigation-drawer
        v-model="drawerPreview"
        :mini-variant="
          $vuetify.breakpoint.name === 'xs' ? (miniPreview = true) : miniPreview
        "
        stateless
        clipped
        width="250"
        mini-variant-width="70"
        preview
      >
        <v-list class="cursor-disabled">
          <v-list-item-group :color="company.color1">
            <v-list-item link>
              <v-list-item-action
                ><v-icon>fas fa-tachometer-alt</v-icon></v-list-item-action
              >
              <v-list-item-content
                ><v-list-item-title>{{
                  $t("DSB.menu.dashboard")
                }}</v-list-item-title></v-list-item-content
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        dark
        :color="company.color1"
        fixed
        dense
        clipped-left
        elevate-on-scroll
        class="main-toolbar pl-0"
      >
        <!-- app -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon
              dark
              v-on="on"
              class="circle"
              @click.stop="drawerPreview = !drawerPreview"
            ></v-app-bar-nav-icon>
          </template>
          <span v-if="drawerPreview">{{ $t("base.hide_menu") }}</span>
          <span v-else>{{ $t("base.show_menu") }}</span>
        </v-tooltip>
        <v-toolbar-title class="pl-0 subtitle-1 text-uppercase">
          <img
            :src="company.head_logo == '' ? company.logo : company.head_logo"
            :alt="company.name_company"
            height="38px"
            align="absmiddle"
          />
          <span
            class="comp_name only-preview"
            :style="{
              'background-color': company.color1 + '!important'
            }"
            >{{ company.name_company }}
          </span>
        </v-toolbar-title>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              dark
              v-on="on"
              class="ma-0 hidden-xs-only circle"
              @click.stop="miniPreview = !miniPreview"
            >
              <v-icon v-if="miniPreview" class="pl-1"
                >fas fa-chevron-right</v-icon
              >
              <v-icon v-else class="pr-1">fas fa-chevron-left</v-icon>
            </v-btn>
          </template>
          <span v-if="miniPreview">{{ $t("base.restore_menu") }}</span>
          <span v-else>{{ $t("base.condensate_menu") }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-toolbar-items class="cursor-disabled">
          <v-btn text>
            <v-icon class="pr-1">fas fa-bell</v-icon>
          </v-btn>

          <v-btn text>
            <v-icon left>fas fa-user</v-icon>
            <span v-show="$vuetify.breakpoint.smAndUp">{{ company.name }}</span>
          </v-btn>

          <v-btn text>
            <v-icon class="ma-0 mr-2">fas fa-globe</v-icon><span>ES</span>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <v-content class="pl-0 pb-10">
        <v-container class="fill-height" fluid pa-0>
          <v-row
            no-gutters
            class="pr-6 pt-0 pb-5"
            :class="{
              open: drawerPreview && !miniPreview,
              mini: drawerPreview && miniPreview,
              closed: !drawerPreview
            }"
          >
            <v-col
              cols="12"
              md="6"
              :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
            >
              <v-card
                outlined
                class="elevation-0 step-inner mx-auto card-outlined"
                :style="{
                  'border-color': company.color1 + '!important'
                }"
              >
                <v-card-title>
                  <div
                    class="title"
                    :style="{
                      color: company.color1 + '!important',
                      'caret-color': company.color1
                    }"
                  >
                    <span class="px-1 white">
                      {{ $t("company.basic_data") }}
                    </span>
                  </div>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-container class="pt-1 pb-2">
                    <v-row>
                      <v-col md="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, i) in [
                                  {
                                    title: $t('company.name_company'),
                                    content: company.name_company
                                  },
                                  {
                                    title: $t('company.identification'),
                                    content: company.identification
                                  },
                                  {
                                    title: $t('company.object_company'),
                                    content: company.object_company
                                  },
                                  // { title: 'Logo', content: company.logo},
                                  {
                                    title: $t('company.telephone'),
                                    content: company.telephone
                                  },
                                  {
                                    title: $t('company.email'),
                                    content: company.email
                                  }
                                ]"
                                :key="i"
                              >
                                <td>{{ item.title }}</td>
                                <td>{{ item.content }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("company.printable_logo") }}
                                </td>
                                <td>
                                  <img
                                    :src="
                                      av_image == ''
                                        ? company.logo
                                        : require(`@/assets/default.png`)
                                    "
                                    :alt="company.name_company"
                                    height="38px"
                                    align="absmiddle"
                                    class="grey lighten-1"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-3' : ''"
            >
              <v-card
                outlined
                class="elevation-0 step-inner mx-auto card-outlined"
                :style="{
                  'border-color': company.color1 + '!important'
                }"
              >
                <v-card-title>
                  <div
                    class="title"
                    :style="{
                      color: company.color1 + '!important',
                      'caret-color': company.color1
                    }"
                  >
                    <span class="px-1 white">
                      {{ $t("company.legal_data") }}
                    </span>
                  </div>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-container class="pt-1 pb-2">
                    <v-row>
                      <v-col md="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, i) in [
                                  {
                                    title: $t('company.legal_representative'),
                                    content: company.legal_representative
                                  },
                                  {
                                    title: $t(
                                      'company.name_institution_registration'
                                    ),
                                    content:
                                      company.name_institution_registration
                                  },
                                  {
                                    title: $t('company.register_number'),
                                    content: company.register_number
                                  },
                                  {
                                    title: $t('company.volume'),
                                    content: company.volume
                                  },
                                  {
                                    title: $t('company.folio'),
                                    content: company.folio
                                  },
                                  {
                                    title: $t('company.date_register'),
                                    content: company.date_register
                                  }
                                  // { title: $t('company.firm'), content: company.firm},
                                  // { title: $t('company.seal'), content: company.seal},
                                ]"
                                :key="i"
                              >
                                <td>{{ item.title }}</td>
                                <td>{{ item.content }}</td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("company.firm") }}
                                </td>
                                <td>
                                  <img
                                    :src="
                                      company.firm
                                        ? company.firm
                                        : require(`@/assets/default.png`)
                                    "
                                    :alt="company.name_company"
                                    height="38px"
                                    align="absmiddle"
                                    class="grey lighten-1"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("company.seal") }}
                                </td>
                                <td>
                                  <img
                                    :src="
                                      company.seal
                                        ? company.seal
                                        : require(`@/assets/default.png`)
                                    "
                                    :alt="company.name_company"
                                    height="38px"
                                    align="absmiddle"
                                    class="grey lighten-1"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
            >
              <v-card
                outlined
                class="elevation-0 step-inner mx-auto card-outlined"
                :style="{
                  'border-color': company.color1 + '!important'
                }"
              >
                <v-card-title>
                  <div
                    class="title"
                    :style="{
                      color: company.color1 + '!important',
                      'caret-color': company.color1
                    }"
                  >
                    <span class="px-1 white">
                      {{ $t("company.geographical_information") }}
                    </span>
                  </div>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-container class="pt-1 pb-2">
                    <v-row>
                      <v-col md="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr v-show="!load_countries">
                                <td>
                                  {{ $t("company.country") }}
                                </td>
                                <td>
                                  {{
                                    items_country[company.country - 1]
                                      ? items_country[company.country - 1].name
                                      : ""
                                  }}
                                </td>
                              </tr>
                              <tr v-show="!load_states">
                                <td>
                                  {{ $t("company.state") }}
                                </td>
                                <td>
                                  <span
                                    v-for="(item, index) in items_state"
                                    :key="index"
                                  >
                                    <template v-if="item.id == company.state">{{
                                      item.name
                                    }}</template>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("company.fiscal_address") }}
                                </td>
                                <td>
                                  {{ company.fiscal_address }}
                                </td>
                              </tr>
                              <tr v-if="company.geo_location">
                                <td>
                                  {{ $tc("contracts.coordinates", 2) }}
                                </td>
                                <td>
                                  <a
                                    :style="{
                                      color: company.color1
                                    }"
                                    :href="
                                      `https://www.google.com/maps/@${company.latitude},${company.longitude},20z`
                                    "
                                    target="_blank"
                                    ><strong
                                      >{{ company.latitude }},
                                      {{ company.longitude }}</strong
                                    ></a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-3' : ''"
            >
              <v-card
                outlined
                class="elevation-0 step-inner mx-auto card-outlined"
                :style="{
                  'border-color': company.color1 + '!important'
                }"
              >
                <v-card-title>
                  <div
                    class="title"
                    :style="{
                      color: company.color1 + '!important',
                      'caret-color': company.color1
                    }"
                  >
                    <span class="px-1 white">
                      {{ $t("company.system_administrator") }}
                    </span>
                  </div>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-container class="pt-1 pb-2">
                    <v-row>
                      <v-col md="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, i) in [
                                  {
                                    title: $t('company.name'),
                                    content: company.name
                                  },
                                  {
                                    title: $t('company.email_admin'),
                                    content: company.email_admin
                                  }
                                ]"
                                :key="i"
                              >
                                <td>{{ item.title }}</td>
                                <td>{{ item.content }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card
                outlined
                class="elevation-0 step-inner mx-auto card-outlined"
                :style="{
                  'border-color': company.color1 + '!important'
                }"
              >
                <v-card-title>
                  <div
                    class="title"
                    :style="{
                      color: company.color1 + '!important',
                      'caret-color': company.color1
                    }"
                  >
                    <span class="px-1 white">
                      {{ $t("company.social_networking") }}
                    </span>
                  </div>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-container class="pt-1 pb-2">
                    <v-row>
                      <v-col md="6">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, i) in [
                                  {
                                    title: 'Facebook',
                                    content: company.facebook
                                  },
                                  {
                                    title: 'Twitter',
                                    content: company.twitter
                                  }
                                ]"
                                :key="i"
                              >
                                <td>{{ item.title }}</td>
                                <td>{{ item.content }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col md="6">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, i) in [
                                  {
                                    title: 'Instagram',
                                    content: company.instagram
                                  },
                                  {
                                    title: 'Linkedin',
                                    content: company.linkedin
                                  }
                                ]"
                                :key="i"
                              >
                                <td>{{ item.title }}</td>
                                <td>{{ item.content }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Floating button to close dialog -->
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :color="company.color1"
                  @click="close"
                  fab
                  dark
                  fixed
                  bottom
                  right
                  small
                  class="mb-6 mr-1"
                >
                  <v-icon small>fas fa-times</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("close") }} {{ $t("preview") }}</span>
            </v-tooltip>
            <!-- End -Floating button to close dialog -->
          </v-row>
        </v-container>
      </v-content>
      <v-footer app :color="company.color1">
        <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog-preview",
  props: {
    dataCompany: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    }
  },
  data() {
    return {
      dialog: false,
      drawerPreview: true,
      miniPreview: false,
      load_countries: false,
      load_states: true,
      items_country: [],
      items_state: [],
      company: {},
      av_image: "",
      av_head_logo: "",
      modeNode: process.env.NODE_ENV
    };
  },
  methods: {
    open(data) {
      this.company = data;
      if (this.modeNode == "development") {
        // eslint-disable-next-line no-console
        console.log(
          "Development Mode",
          `\nFirm: ${!data.firm ? !data.firm : data.firm}`,
          `\nSeal: ${!data.seal ? !data.seal : data.seal}`
        );
      }
      this.drawerPreview = true;
      this.miniPreview = false;
      this.getCountries();
      this.getStates();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.company = {};
    },
    getCountries() {
      this.load_countries = true;
      axios
        .get(this.$store.getters.getAllCountries)
        .then(response => {
          this.items_country = response.data.countries;
        })
        .catch(err => {
          let error = err.response;
          if (error.data) {
            error = error.data.error;
          }
          this.items_country = [];
        })
        .finally(() => {
          this.load_countries = false;
        });
    },
    getStates() {
      if (this.company.country) {
        this.load_states = true;
        axios
          .get(this.$store.getters.getStates(this.company.country))
          .then(response => {
            this.items_state = response.data.states;
          })
          .catch(err => {
            if (err) {
              //
            }
            this.items_state = [];
          })
          .finally(() => {
            this.load_states = false;
          });
      }
    }
  }
};
</script>
