import { VSelect, VCheckbox,VTextField } from 'vuetify/lib'
import Datepicker from "@/components/Utils/Datepicker.vue"
import Autocomplete from "@/components/Utils/Autocomplete.vue"
import EmployeeSearch from '@/components/Employees/EmployeeSearch.vue';

export default {
  components:{
    VCheckbox,
    VTextField,
    VSelect,
    datepicker:Datepicker,
    autocomplete:Autocomplete,
    employee:EmployeeSearch
  },
  data() {
    return {
      typeReport: null,
      sendConsult: {},
      disabled: true,
      loadingExcel: false,
      loadingPdf: false,
      // Currencies
      currencyCode: null,
      loadingCurrencies: false,
      currencyCodeItems: [],
      current_month: new Date().getMonth() + 1,
      current_year: new Date().getFullYear(),
      taxBillRate: {},
      isTaxBillRate: false,
      minTaxBillRateDate: '2021-01-01',
      minBookSaleDate: '2021-01-01',
      months_list: [
        {
          text: this.$t('months.january'),
          value:1,
        },
        {
          text: this.$t('months.february'),
          value:2,
        },
        {
          text: this.$t('months.march'),
          value:3,
        },
        {
          text: this.$t('months.april'),
          value:4,
        },
        {
          text: this.$t('months.may'),
          value:5,
        },
        {
          text: this.$t('months.june'),
          value:6,
        },
        {
          text: this.$t('months.july'),
          value:7,
        },
        {
          text: this.$t('months.august'),
          value:8,
        },
        {
          text: this.$t('months.september'),
          value:9,
        },
        {
          text: this.$t('months.october'),
          value:10,
        },
        {
          text: this.$t('months.november'),
          value:11,
        },
        {
          text: this.$t('months.december'),
          value:12,
        }
      ]
    }
  },
  methods: {
    getTaxBillRate () {
      axios.get(this.$store.getters.taxBillRate)
        .then((res)=>{
          if (res.data && res.data.success) {
            this.taxBillRate = res.data.success
            this.getFirstClientDocumentWithTaxBillRate()
          }
        })
    },
    getFirstClientDocumentWithTaxBillRate () {
      axios.get(this.$store.getters.getFirstClientDocumentWithTaxBillRate)
        .then((res)=>{
          if (res.data && res.data.date) {
            this.minTaxBillRateDate = res.data.date
          }
        })
    },
    getCurrencyCodes(){
      this.loadingRates = true;
      axios.get(this.$store.getters.getCurrencyCodes)
        .then((response)=>{
          this.currencyCodeItems = response.data.success
          let base_code = response.data.success.find(x =>
            x.default === true
          )
          this.sendConsult.currency_code = base_code.currency_code
          this.currencyCode = base_code.currency_code
        })
        .finally(()=>{
          this.loadingRates = false
        })
    },
    changeTypeReport(){
      let clone = JSON.parse(JSON.stringify(this.typeReport))
      if(clone !== null){
        clone = clone.fields
        for(let i = 0; i < clone.length;i++){
          if(clone[i].sendConsult !== undefined) {
            let consult = clone[i].sendConsult
            Object.keys(consult).forEach((key) =>{
              clone[i][key] = this.sendConsult[consult[key]]
            })
            this.$set(this.typeReport.fields,i,clone[i])
          }
        }
      }
    },
    getDinamicName(name){
      name = name.split('.')
      let dinamicName = name[0]
      Object.values(this.sendConsult).forEach(element=>{
        if (element) {
          dinamicName += ` ${element}`
        }
      })
      dinamicName += `.${name[1]}`
      return dinamicName
    },
    getGeneric(){
      this.$validator.validateAll().then(() =>{
        if(!this.errors.any()){
          if(this.typeReport.excel !== undefined){
            this.loadingExcel = true
          }
          else{
            this.loadingPdf = true
          }
          axios(this.typeReport.consult)
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', this.getDinamicName(this.typeReport.filename))
              document.body.appendChild(link)
              link.click()
            })
            .catch((error) => {
              if (error.response && error.response.status == 403) {
                this.$awn.alert(this.$tc('error422.forbidden', 0, []), {durations:{warning: 6000}})
              }
              if (error.response && error.response.status == 404) {
                let file_name = this.typeReport.filename.split('.')[0]
                this.$awn.alert(this.$tc('reports.no_results', 0, [file_name]), {durations:{warning: 6000}})
                setTimeout(() => {
                  this.$store.dispatch('closeSnackbar')
                }, 140)
              }
            })
            .finally(() => {
              this.loadingExcel = false
              this.loadingPdf = false
              this.sendConsult.franchise = ''
            })
        }
      })
    }
  },
  computed:{
    computedShowTaxRateSwitch () {
      const should_show = this.typeReport && this.typeReport.id === 1 &&
        this.taxBillRate && Object.keys(this.taxBillRate).length !== 0 &&
        Object.getPrototypeOf(this.taxBillRate) === Object.prototype
      if (!should_show) {
        this.isTaxBillRate = false
      }
      return should_show
    },
    reportsType(){
      return [
        {
          id:1,
          show: true,
          excel: this.getGeneric,
          name: this.$t('reports.ledger'),
          fields: this.ledger,
          filename:`${this.$t('reports.ledger')}.xlsx`,
          consult: {
            url:this.$store.getters.getSalesBookReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:2,
          show: true,
          excel: this.getGeneric,
          name: this.$t('reports.void_list'),
          fields: this.void_list,
          filename:`${this.$t('reports.void_list')}.xlsx`,
          consult: {
            url:this.$store.getters.getVoidListReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:3,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.billing_detail_by_concept'),
          fields: this.billing_detail_by_concept,
          filename:`${this.$t('reports.billing_detail_by_concept')}.pdf`,
          consult: {
            url:this.$store.getters.getBillingDetailByConceptReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:4,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.daily_income'),
          fields: this.daily_income,
          filename:`${this.$t('reports.daily_income')}.pdf`,
          consult: {
            url:this.$store.getters.getDailyIncomeReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:5,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.general_income_by_date'),
          fields: this.ledger,
          filename:`${this.$t('reports.general_income_by_date')}.pdf`,
          consult: {
            url:this.$store.getters.getIncomeByDateReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:6,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.general_income_by_collector'),
          fields: this.collector,
          filename:`${this.$t('reports.general_income_by_collector')}.pdf`,
          consult: {
            url:this.$store.getters.getIncomeByCollectorReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:7,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.general_income_by_concept'),
          fields: this.concept,
          filename:`${this.$t('reports.general_income_by_concept')}.pdf`,
          consult: {
            url:this.$store.getters.getIncomeByConceptReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:8,
          show: true,
          excel: this.getGeneric,
          name: this.$t('reports.income_by_sector'),
          fields: this.income_by_sector,
          filename:`${this.$t('reports.income_by_sector')}.xlsx`,
          consult: {
            url:this.$store.getters.getIncomeBySectorReport,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
         {
           id:9,
           show: process.env.NODE_ENV == 'development',
           excel: this.getGeneric,
           name: this.$t('reports.income_by_concept_group_by'),
           fields: this.income_by_concept_group_by,
           filename:`${this.$t('reports.income_by_concept_group_by')}.xlsx`,
           consult: {
             url:this.$store.getters.getIncomeByConceptGroupByReport,
             type:'get',
             params:this.sendConsult,
             responseType:'blob'
           }
         },
        {
          id:10,
          show: true,
          pdf: this.getGeneric,
          name: this.$t('reports.collection_list'),
          fields: this.collection_list,
          filename:`${this.$t('reports.collection_list')}.pdf`,
          consult: {
            url:this.$store.getters.getCollectionList,
            type:'get',
            params:this.sendConsult,
            responseType:'blob'
          }
        },
        {
          id:11,
          show: process.env.NODE_ENV == 'development',
          excel: this.getGeneric,
          name: this.$t('reports.daily_cash_register'),
          fields: this.daily_cash_register,
          filename:`${this.$t('reports.daily_cash_register')}.xlsx`,
          consult: {
             url:this.$store.getters.getDailyCashRegister,
             type:'get',
             params:this.sendConsult,
             responseType:'blob'
           }
        },
        {
          id:12,
          show: process.env.NODE_ENV == 'development',
          excel: this.getGeneric,
          name: this.$t('reports.daily_cash_register_external'),
          fields: this.daily_cash_register_external,
          filename:`${this.$t('reports.daily_cash_register_external')}.xlsx`,
          consult: {
             url:this.$store.getters.getDailyCashRegisterExternal,
             type:'get',
             params:this.sendConsult,
             responseType:'blob'
           }
        },
      ].filter(item => item.show)
    },
    ledger() {
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          min: this.minBookSaleDate,
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },
        {
          name:'end_date',
          fieldName:'end_date',
          label:this.$t('concept.finish_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('end_date'),
          'data-vv-as':this.$t('concept.finish_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
          sendConsult:{
            min:'start_date'
          }
        },
        {
          name:'employee',
          fieldName:'employee',
          label:this.$tc('employees.employee',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"legal_denomination[0][0]",
          api:{
            url:this.$store.getters.getEmployees(),
            method:'get',
            params:{paginate: 10000}
          },
          responseVal:'employees',
          noPagination:false,
          icon: 'fas fa-users'
        },
        {
          name:'franchise',
          fieldName:'franchise',
          label:this.$tc('franchise.contractor',2),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"full_name",
          api:{
            url:this.$store.getters.getFranchises(),
            method:'get',
            params:{paginate: 10, select_only: 1}
          },
          responseVal:'franchises',
          noPagination:false,
          icon: 'fas fa-building',
          hide: this.$store.getters.isFranchise
        }
      ]
    },
    void_list(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },
        {
          name:'end_date',
          fieldName:'end_date',
          label:this.$t('concept.finish_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('end_date'),
          'data-vv-as':this.$t('concept.finish_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
          sendConsult:{
            min:'start_date'
          }
        },
        {
          name:'contract_id',
          fieldName:'contract_id',
          label:this.$t('contracts.id'),
          type:'v-text-field',
          'v-validate':{},
        },
      ]
    },
    billing_detail_by_concept(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },
        {
          name:'end_date',
          fieldName:'end_date',
          label:this.$t('concept.finish_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('end_date'),
          'data-vv-as':this.$t('concept.finish_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
          sendConsult:{
            min:'start_date'
          }
        },
        {
          name:'employee_id',
          fieldName:'employee_id',
          label:this.$tc('employees.employee',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"legal_denomination[0][0]",
          api:{
            url:this.$store.getters.getEmployees(),
            method:'get',
            params:{paginate: 15}
          },
          responseVal:'employees',
          noPagination:false,
          icon: 'fas fa-users'
        },
        // {
        //   name:'service',
        //   fieldName:'service',
        //   label:this.$t('service'),
        //   type:"autocomplete",
        //   'v-validate':{},
        //   "item-value":"id",
        //   "item-text":"name",
        //   api:{
        //     url:this.$store.getters.getService(),
        //     method:'get',
        //     params:{paginate: 15}
        //   },
        //   responseVal:'service',
        //   noPagination:true,
        //   icon: 'fas fa-map'
        // },
      ]
    },
    daily_income(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },
        {
          name:'end_date',
          fieldName:'end_date',
          label:this.$t('concept.finish_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('end_date'),
          'data-vv-as':this.$t('concept.finish_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
          sendConsult:{
            min:'start_date'
          }
        },
        {
          name:'employee_id',
          fieldName:'employee_id',
          label:this.$tc('employees.employee',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"legal_denomination[0][0]",
          api:{
            url:this.$store.getters.getEmployees(),
            method:'get',
            params:{paginate: 15}
          },
          responseVal:'employees',
          noPagination:false,
          icon: 'fas fa-users'
        },
      ]
    },
    income_by_sector(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },
        {
          name:'end_date',
          fieldName:'end_date',
          label:this.$t('concept.finish_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('end_date'),
          'data-vv-as':this.$t('concept.finish_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
          sendConsult:{
            min:'start_date'
          }
        },
        /***{
          name:'company',
          fieldName:'company',
          label:this.$tc('company.company',1),
          type:'v-text-field',
          'v-validate':{},
        },
        ***/
        {
          name:'sector',
          fieldName:'sector',
          label:this.$t('sector'),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"name",
          api:{
            url:this.$store.getters.getSectors(),
            method:'get',
            params:{select_only: 1}
          },
          responseVal:'sectors',
          noPagination:true,
          icon: 'fas fa-map'
        },
        {
          name:'employee',
          fieldName:'employee',
          label:this.$tc('employees.employee',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"legal_denomination[0][0]",
          api:{
            url:this.$store.getters.getEmployees(),
            method:'get',
            params:{paginate: 15}
          },
          responseVal:'employees',
          noPagination:false,
          icon: 'fas fa-users'
        },
      ]
    },
     income_by_concept_group_by(){
       return [
         {
           name:'start_date',
           fieldName:'start_date',
           label:this.$t('concept.start_date'),
           type:'datepicker',
           'v-validate':{
             'required':true
           },
           'error-messages':this.errors.collect('start_date'),
           'data-vv-as':this.$t('concept.start_date'),
           max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
         },
         {
           name:'end_date',
           fieldName:'end_date',
           label:this.$t('concept.finish_date'),
           type:'datepicker',
           'v-validate':{
             'required':true
           },
           'error-messages':this.errors.collect('end_date'),
           'data-vv-as':this.$t('concept.finish_date'),
           max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
           sendConsult:{
             min:'start_date'
           }
         },
       ]
     },
     daily_cash_register(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },

      ]
    },
    daily_cash_register_external(){
      return [
        {
          name:'start_date',
          fieldName:'start_date',
          label:this.$t('concept.start_date'),
          type:'datepicker',
          'v-validate':{
            'required':true
          },
          'error-messages':this.errors.collect('start_date'),
          'data-vv-as':this.$t('concept.start_date'),
          max:new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        },

      ]
    },
    collection_list(){
      return [
        {
          name:'month',
          fieldName:'month',
          label:this.$t('month'),
          type:'v-select',
          'v-validate':{
            'required':true
          },
          'data-vv-as':this.$t('month'),
          items: this.months_list,
          icon: 'fas fa-calendar-alt'
        },
        {
          name:'year',
          fieldName:'year',
          label:this.$t('paymentNote.year'),
          type:'v-select',
          'v-validate':{
            'required':true
          },
          'data-vv-as':this.$t('paymentNote.year'),
          items:Array(this.current_year - (this.current_year - 10)).fill('').map((v, idx) => this.current_year - idx),
          icon: 'fas fa-calendar'
        },
        {
          name:'sector',
          fieldName:'sector',
          label:this.$t('sector'),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"name",
          api:{
            url:this.$store.getters.getSectors(),
            method:'get',
            params:{select_only: 1}
          },
          responseVal:'sectors',
          noPagination:true,
          icon: 'fas fa-map'
        },
        {
          name:'employee',
          fieldName:'employee',
          label:this.$tc('employees.employee',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"legal_denomination[0][0]",
          api:{
            url:this.$store.getters.getEmployees(),
            method:'get',
            params:{paginate: 10000}
          },
          responseVal:'employees',
          noPagination:false,
          icon: 'fas fa-users'
        },
        {
          name:'franchise',
          fieldName:'franchise',
          label:this.$tc('franchise.contractor',2),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"full_name",
          api:{
            url:this.$store.getters.getFranchises(),
            method:'get',
            params:{paginate: 10, select_only: 1}
          },
          responseVal:'franchises',
          noPagination:false,
          icon: 'fas fa-building',
          hide: this.$store.getters.isFranchise
        },
      ]
    },
    collector(){
      return [
      {
          name:'month',
          fieldName:'month',
          label:this.$t('month'),
          type:'v-select',
          'v-validate':{},
          items: this.months_list,
          icon: 'fas fa-calendar-alt'
        },
        {
          name:'year',
          fieldName:'year',
          label:this.$t('paymentNote.year'),
          type:'v-select',
          'v-validate':{},
          items:Array(this.current_year - (this.current_year - 10)).fill('').map((v, idx) => this.current_year - idx),
          icon: 'fas fa-calendar'
        },
        {
          name:'employee_id',
          fieldName:'employee_id',
          label:this.$tc('employees.employee',1),
          type:'employee',
          'v-validate':{},
          prepend_icon_field: 'fas fa-users'
        },
      ]
    },
    concept(){
      return[
        {
          name:'month',
          fieldName:'month',
          label:this.$t('month'),
          type:'v-select',
          'v-validate':{},
          items: this.months_list,
          icon: 'fas fa-calendar-alt'
        },
        {
          name:'year',
          fieldName:'year',
          label:this.$t('paymentNote.year'),
          type:'v-select',
          'v-validate':{},
          items:Array(this.current_year - (this.current_year - 10)).fill('').map((v, idx) => this.current_year - idx),
          icon: 'fas fa-calendar'
        },
        {
          name:'concept',
          fieldName:'concept',
          label:this.$tc('concept.name',1),
          type:"autocomplete",
          'v-validate':{},
          "item-value":"id",
          "item-text":"name",
          api:{
            url:this.$store.getters.getConceptMotives(),
            method:'get',
            params:{paginate: 15}
          },
          responseVal:'motives',
          noPagination:true
        },
      ]
    }
  },
  watch:{
    isTaxBillRate (val) {
      const defaut_currency = this.currencyCodeItems.find(x => x.default === true)
      if (val) {
        this.currencyCode = this.taxBillRate
        this.minBookSaleDate = this.minTaxBillRateDate
      } else {
        this.currencyCode = defaut_currency
        this.minBookSaleDate = '2020-01-01'
      }
      this.typeReport = this.reportsType.find(rt => rt.id == 1)
      this.$set(this.sendConsult, 'is_tax_bill_rate', val)
    },
    currencyCode(val){
      if (typeof(val) == 'object') {
        this.sendConsult.currency_code = val.currency_code
      } else {
        this.sendConsult.currency_code = val
      }
      if(this.typeReport != null && this.typeReport.consult != undefined){
        this.$set(this.typeReport.consult.params, 'currency_code', this.sendConsult.currency_code)
      }
    },
    typeReport(val){
      if(val !== null && val !== ''){
        this.sendConsult = val.fields.reduce((acc,element) => (acc[element.fieldName] = '',acc), {})
        if (typeof(this.currencyCode) == 'object') {
          this.sendConsult.currency_code = this.currencyCode.currency_code
        } else {
          this.sendConsult.currency_code = this.currencyCode
        }

        if ('year' in this.sendConsult) {
          this.sendConsult.year = this.current_year
        }
        if ('month' in this.sendConsult) {
          this.sendConsult.month = this.current_month
        }
      }
    },
    sendConsult:{
      deep:true,
      immediate:true,
      handler(val){
        if(this.typeReport != null && this.typeReport.consult != undefined){
          this.$set(this.typeReport.consult,'params',{...this.typeReport.consult.params,...val})
        }
        this.changeTypeReport()
      }
    }
  },
  mounted(){
    this.getCurrencyCodes()
    this.getTaxBillRate()
  }
}
