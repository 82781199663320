export const en_veeValidate = {
  code: "en",
  attributes: {
    filter: "", //Campo Buscar (global)
    base: {
      cellphone: "cellphone"
    },
    company: {
      company: "company",
      name_company: "name",
      identification: "ID",
      object_company: "company's object",
      country: "country",
      state: "state",
      name_institution_registration: "registration institution's name",
      register_number: "regitration number",
      volume: "volume",
      folio: "foil",
      date_register: "regitration date",
      legal_representative: "legal representative",
      firm: "firm",
      fiscal_address: "fiscal address",
      latitude: "latitude",
      longitude: "longitude",
      telephone: "telephone",
      email: "email",
      seal: "seal",
      name: "administrator's username",
      dbname: "Name of Data Base",
      email_admin: "administrator's email",
      password: "administrator's password",
      logo: "logo",
      head_logo: "logo",
      branch_office: "branch office"
    },
    login: {
      email: "email",
      password: "password"
    },
    register: {
      name: "name",
      email: "email",
      password: "password",
      password_confirmation: "" /* it is left empty to avoid duplicate error */
    },
    recovery: {
      email: "email"
    },
    user_data: {
      name: "name",
      email: "email"
    },
    user_pass: {
      current_password: "current password",
      password: "password",
      password_confirmation: "" /* it is left empty to avoid duplicate error */
    },
    system: {
      name: "name",
      symbol: "symbol",
      icon: "icon",
      exchange_rate: "exchange rate",
      url: "URL",
      decimal_places: "decimal places"
    },
    subscribers: {
      client_type: "subscriber type",
      prefix: "prefix",
      dni: "DNI",
      first_name: "name",
      last_name: "lastname",
      firm_name: "sole proprietorship",
      legal_denomination: "legal denomination",
      gender: "gender",
      birthdate: "birthdate",
      agent_ret: "agent",
      tax_rate: "tax",
      observations: "observations",
      position: "position",
      code: "code"
    },
    contracts: {
      sector: "sector",
      tower: "torre",
      address: "address",
      ref_point: "ref. point",
      city: "city",
      municipality: "municipality",
      parish: "parish"
    },
    typeClient: {
      name: "name",
      description: "description"
    }
  },
  names: {
    payment_date: 'Payment date',
  },
  messages: {
    dontallownegative: () => {
      return "The field must be a positive number and greater than zero.";
    },
    dontallowzero: () => {
      return "The field must be greater than 0.";
    },
    ratenotzero: () => {
      return `The rate must be greater than zero (0)`;
    },
    fieldnotzero: (field) => {
      return `The ${field} must be greater than zero (0)`;
    },
    maxmoney: (field, args) => {
      return `The field ${field} must be less than or equal to ${args[0]}`;
    },
    percentValue: field => {
      return `The field ${field} is a percentage, its value must be between 0 and 100`;
    },
    passConfirm: field => {
      return `The field ${field} confirmation does not match`;
    }
  }
};
