var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pt-8":"","px-8":"","px-sm-5":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto pa-0",attrs:{"cols":"12","sm":"10"}},[_c('v-data-table',{staticClass:"elevation-1 installs-totals-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.totals,"hide-default-header":_vm.nodata,"footer-props":{
            'items-per-page-options': [10, 50, 100, 500, 1000]
          },"options":_vm.options,"loading":_vm.loadingTable,"height":"calc(100vh - 283px )","fixed-header":_vm.$vuetify.breakpoint.smAndUp},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.computedTableTitle,"showArrowBack":_vm.computedShowArrowBack},on:{"arrowBack":function($event){return _vm.getTotals(true)}}}),_c('v-row',{staticClass:"mt-n1 mb-2 px-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-0 ml-auto mr-0 mr-sm-4",attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"date1_input",staticClass:"date-picker",attrs:{"clearable":"","dense":"","label":_vm.$t('start_date'),"hint":_vm.$store.getters.getDateFormat[0],"persistent-hint":"","prepend-icon":"fas fa-calendar-day","readonly":""},model:{value:(_vm.computedDateFormatted1),callback:function ($$v) {_vm.computedDateFormatted1=$$v},expression:"computedDateFormatted1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"min":"2020-01-01","max":_vm.today},on:{"input":_vm.clearDate2},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-picker",attrs:{"clearable":"","dense":"","label":_vm.$t('end_date'),"hint":_vm.$store.getters.getDateFormat[0],"persistent-hint":"","prepend-icon":"fas fa-calendar-day","readonly":"","disabled":_vm.date1 == '' || _vm.date1 == null},model:{value:(_vm.computedDateFormatted2),callback:function ($$v) {_vm.computedDateFormatted2=$$v},expression:"computedDateFormatted2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"min":_vm.date1 ? _vm.date1 : '2020-01-01',"max":_vm.today},on:{"input":_vm.searchByDate},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}})],1)],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('b',_vm._g({staticClass:"body-0 text-uppercase",class:item.title.includes('divider') ? 'grey--text' : ''},item.from && item.to ? on : ''),[_vm._v(" "+_vm._s(_vm.$te('table.'+item.title) ? _vm.$tc('table.'+item.title,1) : item.title.includes('divider') ? _vm.dividerTitle(item) : item.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('from_to',{ start: _vm.$moment(item.from).format(_vm.$store.getters.getDateFormat[0]), end: _vm.$moment(item.to).format(_vm.$store.getters.getDateFormat[0]) }))+" ")])])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.filter && item.filter.frn && _vm.policies.get_all)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","disabled":item.total == '-'},on:{"click":function($event){item.total != '-' ? _vm.getTotalsByFranchise(item) : ''}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])]):_vm._e()]}}],null,true)}),_c('v-btn',{attrs:{"fab":"","elevation":"5","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.changeToPercentBtn}},[(!_vm.inPercent)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-percent")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-undo-alt")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }