import jsondialog from './JsonConfiguration.vue'

export default {
  components:{
    jsondialog:jsondialog
  },
  props: {
    activation_pending: {
      default: false,
      type: Boolean
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'service_installation'){
        this.dialog = false
        this.$emit('changeModel')
      }
    })

    this.policies.input_billing_start_date = this.getPermissionGroup({
      permission: ['inputBillingStartDate_serviceInstallation'],
      mode: 'all'
    });
  },
  beforeDestroy () {
    this.unwatch()
  },
  computed:{
    computedInitDateFormatted () {
      return this.formatDate(this.service.init_trial_period)
    },
    computedEndDateFormatted () {
      return this.formatDate(this.service.end_trial_period)
    },
    computedInstallationDate () {
      return this.formatDate(this.service.installation_date)
    },
    computedBillingStartDate () {
      return this.formatDate(this.service.billing_start_date)
    },
    computedExtraCondition () {
      if (this.$store.getters.isFranchise) {
        if (this.computedInstallationDate) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    computedShowActions () {
      if (/*this.$store.getters.isFranchise && */this.step != 1) {
        return false
      } else {
        return true
      }
    },
    computedApprovedByNetwork () {
      if (this.loadingInstallation || this.completion_disabled) {
        return true
      } else {
        return this.service.approved_by_network
      }
    },
    computedApprovedByFiber () {
      if (this.loadingInstallation) {
        return true
      } else {
        return this.service.approved_by_fiber
      }
    }
  },
  data:() => ({
    details: [],
    loadServer: false,
    date_menu_init: false,
    date_menu_end: false,
    date_installation: false,
    date_billing: false,
    unwatch: '',
    step: '',
    tab: 'services',
    list: false,
    dialog: false,
    edit: false,
    clone:{},
    service : {
      'init_trial_period': '',
      'end_trial_period': '',
      'observations': '',
      'installation_date': '',
      'billing_start_date': ''
    },
    extension : {
      'init_trial_period':'',
      'end_trial_period':''
    },
    loading: false,
    loadingService: false,
    loadingMaterial: false,
    loadingInstallation: false,
    loadingFile: false,
    loadingOrder: false,
    serviceConfig: [],
    materialService: [],
    loaded_file: false,
    loaded_file2: false,
    file: {},
    file2: {},
    found_file2: true,
    letter_file_exists: false,
    has_temp_file: false,
    contract: {},
    today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
    min_date: null,
    max_date: null,
    required_configs: [],
    filled_configs: [],
    completion_disabled: true,
    policies: {
      input_billing_start_date: false
    }
  }),
  watch:{
    'service.init_trial_period':{
      handler(){
        if(!this.loadServer)
          this.service.end_trial_period = ''
        else
          this.loadServer = false
        this.$validator.reset()
      }
    },
    dialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        this.edit = false
        this.file = {}
        this.loaded_file = false
        this.file2 = {}
        this.loaded_file2 = false
        this.found_file2 = true
        this.contract = {}
        this.letter_file_exists = false
        this.has_temp_file = false
        setTimeout(() => {
          this.service = {}
        }, 200)
      }
    },
    serviceConfig: {
      handler(val) {
        this.filled_configs = []
        this.required_configs = []
        val.forEach(elm => {
          if (elm.wan_config) {
            elm.wan_config.forEach(conf => {
              Object.keys(conf).forEach((key) => {
                if (conf[key]) {
                  this.filled_configs.push(key)
                }
              })
            })
          }

          if (elm.service) {
            let parent_configs = []
            if (elm.service.parent && elm.service.parent.configurations) {
              elm.service.parent.configurations.forEach(p_conf => {
                if (p_conf.required) {
                  this.required_configs.push(this.toSnakeCase(p_conf.name))
                }
              })
            }
            if (elm.service.configurations) {
              elm.service.configurations.forEach(c_conf => {
                if (c_conf.required) {
                  this.required_configs.push(this.toSnakeCase(c_conf.name))
                }
              })
            }
          }
        })

        let count = 0
        this.required_configs.forEach(x => {
          if (this.filled_configs.includes(x)) {
            count++
          }
        })
        if (count == this.required_configs.length) {
          this.completion_disabled = false
        } else {
          this.completion_disabled = true
        }
      },
      deep: true
    }
  },
  methods:{
    toSnakeCase (val) {
      return val/*.toLowerCase()*/.replace(' ', '_')
    },
    editMode(val){
      this.edit = val
      if (!val) {
        this.$validator.reset()
      }
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    assignModel(evt){
      let modelUpdated = ""
      switch (evt.field){
        case 'wan_config':
        case 'lan_config':
          modelUpdated = 'serviceConfig'
          break;
        case 'config':
          modelUpdated = 'materialService'
          break;
      }
      let index = this[modelUpdated].findIndex(m => m.id === evt.model.id)
      if(index > -1){
        this.$set(this[modelUpdated],index,evt.model)
      }
    },
    approve(mode){
      let url, msg, title, action, type
      this.clone = Object.assign({}, this.service)
      url = this.$store.getters.getServiceInstallation(this.service.id)
      title = this.$t('base.approve')
      action = this.$tc('approved',2)
      type = 'success'
      switch (mode) {
        case 'network':
          msg = this.$t('base.confirm_config_serv')
          this.clone['approved_by_network'] = true
          break
        case 'fiber':
          msg = this.$t('base.confirm_installation')
          this.clone['approved_by_fiber'] = true
          break
        case 'start_trial':
          msg = this.$t('base.confirm_trial',{
            'start':this.computedInitDateFormatted,
            'end':this.computedEndDateFormatted
          })
          this.clone['status'] = 'on_test'
          break
        case 'sent_letter':
          msg = this.$t('base.confirm_send_install')
          this.clone['status'] = 'letter_sent'
          break
        case 'approve':
          msg = this.$t('base.confirm_approved_installation')
          this.clone['status'] = 'approved'
          break
      }
      this.$store.dispatch('confirmationValue',{
        template: 'service_installation',
        show: true,
        model: mode == 'start_trial' ? this.$tc('service_install.init_date',1) : mode == 'sent_letter' ?
          this.$tc('service_sale.acceptance_letter',1)
          :this.$tc('service_install.service_installation',1),
        title: title,
        action: action,
        content: msg,
        type: type,
        confirm:{
          url: url,
          method: 'put',
          data: this.clone
        },
        pos: mode == 'start_trial' ? 1 : 2
      })
    },
    async open(item,tab) {
      axios
        .get(this.$store.getters.catalogByCategory('service_installation_dates_range'))
        .then(res => {
          const formattedNow = new Date().toLocaleString('se-SE', {timeZone: 'America/Caracas'})
          const d1 = new Date(formattedNow)
          const d2 = new Date(formattedNow)
          this.min_date = new Date(
            d1.setDate(d1.getDate() - Number(res.data.success[0].description.days_before))
          ).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)

          this.max_date = new Date(
            d2.setDate(d2.getDate() + Number(res.data.success[0].description.days_after))
          ).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
        })
      this.contract = item.contract
      this.step = tab
      this.dialog = true
      this.tab = 'services'
      if (!this.$store.getters.isFranchiseAdmin || this.$store.getters.isFranchiseAdmin) {
        this.getServiceContract(item.contract_id)
        this.getServiceMaterial(item.contract_id)
      }
      this.getServiceInstallation(item.id)
      if (!this.activation_pending) {
        this.loadPDF(item.contract_id, 'installation_order')
        if (this.step == 4) {
          this.loadPDF(item.contract_id, 'signed_service_equipment_acceptance_letter')
        }
      }
    },
    postService(){
      this.$validator.validateAll()
      .then(() =>{
        if (this.errors.all()==''){
          this.loading = true
          let payload = JSON.parse(JSON.stringify(this.service))
          if (!this.policies.input_billing_start_date) {
            payload.billing_start_date = null
          }
          axios.put(this.$store.getters.getServiceInstallation(this.service.id), payload)
          .then(response => {
            this.edit = false
            this.$store.dispatch('pushNotification', {
              snackbar: true,
              style: 'success',
              timeout: 6000,
              notifText: 'success',
              notifPos: 2,
              extra: {
                  model: this.$tc(`service_install.service_installation`,1),
                  action: this.$tc('updated', 2)
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    addExtension(){
      axios.post(this.$store.getters.addServiceInstallationExtension(this.service.id),this.extension)
      .then(response => {
      })
    },
    loadPDF (contract_id, file_nm) {
      let type = file_nm == 'installation_order' ? 1 : 2
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
              'contract_id': contract_id,
              'name': file_nm
          }
        })
        .then(response => {
          if (response.data.files) {
            if (response.data.files.data.length > 0) {
              if (type == 1) {
                this.file = response.data.files.data[0]
                this.loaded_file = true
              } else {
                this.file2 = response.data.files.data[0]
                this.loaded_file2 = true
                this.letter_file_exists = true
                this.found_file2 = true
              }
            } else {
              if (type == 1) {
                this.file.file_name = 'installation_order'
                this.loaded_file = true
              } else {
                this.file2.file_name = 'signed_service_equipment_acceptance_letter'
                this.loaded_file2 = true
                this.letter_file_exists = false
                this.found_file2 = false
              }
            }
          }
        })
        .catch(err => {
          if (type == 1) {
            this.file = {}
            this.loaded_file = false
          } else {
            this.file2 = {}
            this.loaded_file2 = false
          }
        })
        .finally(()=>{
          //
        })
    },
    async getServiceContract(contract){
      this.loadingService = true
      let response = await axios.get(this.$store.getters.getContractServices(),{
        params:{
          contract: contract,
          paginate:'All'
        }
      })
      this.serviceConfig = response.data.package
      this.loadingService = false
    },
    async getServiceMaterial(contract){
      this.loadingMaterial = true
      let response = await axios.get(this.$store.getters.getContractInstalledHardware(),{
        params:{
          contract_id: contract,
          paginate:'All'
        }
      })
      this.materialService = response.data.hardware
      this.loadingMaterial = false
    },
    async getServiceInstallation(service){
      this.loadingInstallation = true
      let response = await axios.get(this.$store.getters.getServiceInstallation(service))
      this.loadServer = true
      this.service = response.data.services
      this.loadingInstallation = false
    },
    validateFile (e) {
      if (e) {
        if (!['image/png','image/jpeg','image/jpg','application/pdf'].includes(e.type)) {
          this.$awn.alert(this.$tc('error422.file_path_must_type', 0, []), {durations:{alert: 6000}})
          this.clearInput()
        } else if (e.size > 4194304) {
          this.$awn.alert(this.$tc('error422.file_path_max_4096_kb', 0, []), {durations:{alert: 6000}})
          this.clearInput()
        } else {
          this.file2.file = e
          this.has_temp_file = true
        }
      }
    },
    clearInput () {
      this.has_temp_file = false
      this.$refs.signed_letter.reset()
      this.$refs.signed_letter.$refs.input.value = ''
    },
    loadFile () {
      this.loadingFile = true
      let form_data = new FormData()
      let id = null
      form_data.append('subscriber_id', this.contract.subscriber_id)
      form_data.append('contract_id', this.contract.id)
      form_data.append('file_name', 'signed_service_equipment_acceptance_letter')
      form_data.append('file_path', this.file2.file)
      if (this.letter_file_exists) {
        id = this.file2.id
        form_data.append('_method', 'PATCH')
      }
      axios.post(this.$store.getters.getSubscriberFilesLibrary(id), form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success) {
            this.clearInput()
            this.found_file2 = true
            this.letter_file_exists = true
            this.file2 = response.data.success
            this.$awn.success(
              this.$tc('success', 1,
                {
                  model: this.$tc('file_library.file',1),
                  action: this.$tc('loaded',1)
                }
              )
            , {durations:{alert: 6000}})
          } else {
            this.$awn.alert(this.$tc('base.something_wrong', 0, []), {durations:{alert: 6000}})
          }
        })
        .catch((err) => {
          this.$awn.alert(this.$tc('base.something_wrong', 0, []), {durations:{alert: 6000}})
        })
        .finally(() => {
          this.loadingFile = false
        })
    },
    forceGenerateInstallationOrder () {
      this.loadingOrder = true
      axios.get(this.$store.getters.generateInstallOrder(this.$store.getters.companyInfo.id, this.contract.id))
        .then(response => {
          this.loadPDF(this.contract.id, 'installation_order')
          this.$awn.success(
            this.$tc('success', 2, {
              model: this.$t('service_install.installation_order'),
              action: this.$tc('generated',2)
            }),
            { durations: { success: 4000 } }
          )
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .finally(() => {
          this.loadingOrder = false
        })
    }
  }
}
