import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard';
import { store } from './store'
import Vuetify from '@/plugins/vuetify'
import emitNotification from './plugins/axiosDeclared';
// Components
import Company from './views/Company/Company.vue'
import Franchise from './views/Franchise/Franchise.vue'
import Dashboard from './views/Dashboard.vue'
import EmailVerify from './components/layouts/EmailVerify.vue'
import EmailResend from './components/layouts/EmailResend.vue'
import Home from './views/Home.vue'
import PageNotFound from './components/errors/PageNotFound.vue'
import MaintenanceMode from './components/errors/MaintenanceMode.vue'
import PassReset from './components/layouts/PassReset.vue'
import Concept from './views/Concept/Concept.vue';
import AdjustmentMotives from './views/AdjustmentMotives/Table.vue';
import BillingDocuments from './components/paymentNote/paymentNote.vue';
import TypeClient from './views/TypeClient/TypeClient.vue'
import Service from './views/Service/Service.vue'
import Package from './views/Package/Package.vue'
import FeasibilityReport from './views/FeasibilityReport/FeasibilityReport.vue'
import FeasibilityReportNetwork from './views/FeasibilityReport/FeasibilityReportNetwork.vue'
import Inspection from './views/Inspection/Inspection.vue'
import Roles from './views/Roles/Roles.vue'
import Groups from './views/Groups/Groups.vue'
// import Policies from './views/Policies/Policies.vue'
import Actors from './views/Actors/Actors.vue'
import System from './views/System/System.vue'
import Requests from './views/Requests/Requests.vue'
import RequestInfo from './views/Requests/RequestInfo.vue'
import Subscribers from './views/Subscribers/Subscribers.vue'
import FilterSubscribersTotals from './views/Subscribers/FilterAllTotals.vue'
import FilterSubscribersDetails from './views/Subscribers/FilterAllDetails.vue'
import AllSubscribersCDS from './views/CDS/AllSubscribers.vue'
import MigrateSubscriber from './views/SubscribersMigrate/Migrate.vue'
import SubscribersGroups from './views/SubscribersGroups/SubscribersGroups.vue'
import Position from './views/Position/Position.vue'
import NotificationsList from './views/Notifications/NotificationsList.vue'
import DocumentControl from './views/DocumentControl/DocumentControl.vue'
import HistoryBills from './views/HistoryBills/HistoryBills.vue';
import MaterialsAndEquipment from './views/MaterialsAndEquipment/MaterialsAndEquipment.vue';
import Payments from './views/Payments/payments.vue';
import ServiceInstallation from './views/ServiceInstallation/ServiceInstallation.vue'
import IndependantServiceActivation from './views/ServiceInstallation/ServiceInstallation.vue'
import FilterInstallationsTotals from './views/Reports/InstallationsPerDate/Totals.vue';
import RequestBills from './views/RequestBills/requestBills.vue';
import RequestBillsFranchise from './views/requestBillsFranchise/request.vue';
import PaymentsReceived from './views/PaymentsReceived/PaymentsReceived.vue';
import Employees from './views/Employees/Employees.vue';
import EmployeeContracts from './views/EmployeeContracts/EmployeeContracts.vue';
import Sectors from './views/Sectors/Sectors.vue';
import Zones from './views/Zones/Zones.vue';
import ExchangeRates from './components/ExchangeRates/Table.vue';
import MoneyReceived from './views/MoneyReceived/MoneyReceived.vue';
// Catalogs
import Banks from './views/Banks/banks.vue';
import ServicesTypes from './views/ServicesType/ServicesTypes.vue';
import Identity from './views/CorporateIdentity/Identity.vue';
import Taxes from './views/Taxes/Taxes.vue';
import BankAccounts from './components/BankAccounts/Table.vue';
// Reports
import Reports from './views/Reports/Reports.vue';
import ContractsPerService from './views/Reports/ContractsPerService/Totals.vue';
import ContractsPerServiceDetails from './views/Reports/ContractsPerService/FilterDetails.vue';
import AmountsPerService from './views/Reports/AmountsPerService/Totals.vue';
import DebtPerSubscriber from './views/Reports/DebtPerSubscriber/DebtPerSubscriber.vue';
// Others
import Errors from './components/errors/errors.vue';
import AppAtlas from './views/AppAtlas/Embed.vue';

Vue.use(Router);

// Only Authenticated Users can access
const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuth) {
    next();
  } else {
    next('/');
  }
}

// Authenticated Users can't access
const isAuthenticated = (to, from, next) => {
  if (!store.getters.isAuth) {
    next()
  } else {
    next('/dashboard')
  }
}

// Email confirmation isn't checked, then check it
const ifVerified = (to, from, next) => {
  // Restore body overflow if hidden
  document.querySelector('html').style.overflow == 'hidden' ?
    document.querySelector('html').style.overflow = '':''
  if (store.getters.isVerified) {
    next()
  } else {
    if (store.getters.isVerified === null) {
      axios
        .get(store.getters.checkEmailUrl(localStorage.getItem('id')))
        .then(() => {
          store.dispatch('setVerifiedEmail', true)
          next()
        }).catch(() => {
          store.dispatch('setVerifiedEmail', false)
          next('/unverified')
        })
    } else {
      next('/unverified')
    }
  }
}

// Email confirmation has already been checked, get its value
const isVerified = (to, from, next) => {
  if (store.getters.isVerified) {
    next('/dashboard')
  } else {
    next()
  }
}

// Only Super Admin User can access
const userRoot = (to, from, next) => {
  if (store.getters.isRoot) {
    next()
  } else {
    next(from.path)
  }
}

// Super Admin User can't access
const notRoot = (to, from, next) => {
  if (!store.getters.isRoot) {
    next()
  } else {
    next(from.path)
  }
}

// Only Company Admin User can access
const userAdmin = (to, from, next) => {
  if (store.getters.isAdmin) {
    next()
  } else {
    next(from.path)
  }
}

// Company Admin User can't access
const notAdmin = (to, from, next) => {
  if (!store.getters.isAdmin) {
    next()
  } else {
    next(from.path)
  }
}

// Check if a DB has been selected, or is zero -> (indicating User is Super Admin)
const dbSelected = (to, from, next) => {
  if (store.getters.dbSelected !== null) {
    if (Number.isInteger(Number(store.getters.dbSelected)) || Number(store.getters.dbSelected) == 0) {
      next()
    } else {
      next('/error')
    }
  } else {
    next('/')
  }
}

// Check if a base Currency has been set
const ifBaseCurrency = (to, from, next) => {
  next()
  if (!store.getters.isRoot) {
    if (store.getters.getBaseCurrency === null) {
      store.dispatch('baseCurrency')
        .then((response) => {
          if (response === false) {
            if (!store.getters.isRoot) {
              next('/system')
            }
          } else {
            next()
          }
        }).catch((error) => {
          // console.log(error)
        })
    } else if (store.getters.getBaseCurrency === false) {
      if (to.name != 'dashboard') {
        next('/system')
      } else {
        setTimeout(function() {
          next('/system')
        }, 20);
      }
    } else if (typeof(store.getters.getBaseCurrency) == 'object') {
      next()
    } else {
      next('/error')//Check fallback
    }
  }
}

// Only Franchise User can access
const userFranchise = (to, from, next) => {
  if (store.getters.isFranchise) {
    next()
  } else {
    next(from.path)
  }
}

// Franchise User can't access
const notFranchise = (to, from, next) => {
  if (!store.getters.isFranchise) {
    next()
  } else {
    next(from.path)
  }
}

// Only Franchise Admin can access
const adminFranchise = (to, from, next) => {
  if (store.getters.isFranchiseAdmin) {
    next()
  } else {
    next(from.path)
  }
}

const userOrAdminFranchise = (to, from, next) => {
  if (store.getters.isFranchise||store.getters.isFranchiseAdmin) {
    next()
  } else {
    next(from.path)
  }
}

// Franchise Admin can't access
const notAdminFranchise = (to, from, next) => {
  if (!store.getters.isFranchiseAdmin) {
    next()
  } else {
    next(from.path)
  }
}

// Only Company or Franchise Admins can access
const anyAdmin = (to, from, next) => {
  if (store.getters.isAdmin || store.getters.isFranchiseAdmin) {
    next()
  } else {
    next(from.path)
  }
}

// For Maintenance Mode (503) check
const not503 = (to, from, next) => {
  if (store.getters.is503) {
    next('/maintenance')
  } else {
    next()
  }
}

// Only if Maintenance Mode (503)
const only503 = (to, from, next) => {
  if (store.getters.is503) {
    next()
  } else {
    next('/')
  }
}

let router =  new Router({
  // mode: 'history',
  routes: [
    {
      path: '*',
      name: 'NotFound404',
      component: Errors,
      props: {
        titleField: 'base.error_404',
        textField: 'ErrorPage.description_404' ,
        iconField:'fa fa-exclamation-triangle',
        NumberField: 404,
        classParent:'error-page-404',
      },
      beforeEnter: multiguard([not503])
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: multiguard([not503])
    },
    {
      path: '/403',
      name: '403',
      component: Errors,
      props: {
        titleField: 'base.error_403',
        textField: 'ErrorPage.description_403' ,
        iconField:'fas fa-ban',
        NumberField: 403,
        classParent:'error-page-403',
      },
      beforeEnter: multiguard([not503,ifAuthenticated])
    },
    {
      path: '/maintenance',
      name: '503',
      component: Errors,
      props: {
        appBarfield: true,
        titleField: 'base.error_503',
        textField: 'ErrorPage.description_503',
        iconField:'fa fa-tools',
        NumberField: 503,
        classParent:'error-page-503',
        classAppBar:'mode503',
      },
      beforeEnter: multiguard([only503])
    },
    {
      path: '/emailverify/(success|verified|succeeded|confirmed)/',
      name: 'emailverify',
      component: EmailVerify,
      beforeEnter: multiguard([not503])
    },
    {
      path: '/emailverify/error/:token([a-zA-Z\\d+/=]{40})',
      name: 'emailverifyerror',
      component: EmailVerify,
      beforeEnter: multiguard([not503])
    },
    {
      path: '/password-reset/:token([a-zA-Z\\d]{60})',
      name: 'passreset',
      component: PassReset,
      beforeEnter: multiguard([not503, isAuthenticated])
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      beforeEnter: multiguard([not503])
    },
    {
      path: '/dashboard',
      component: Dashboard,
      beforeEnter: multiguard([ifAuthenticated, dbSelected]),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('./components/WelcomeDSB.vue'),
          beforeEnter: multiguard([ifVerified, ifBaseCurrency])
        },
        {
          path: '/zones',
          name: 'zones',
          component: Zones,
          meta:{
            permission:['index_zone', 'index_franchise',],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, notFranchise, notAdminFranchise])
        },
        {
          path: '/zones-franchise',
          name: 'zones-franchise',
          component: Zones,
          meta:{
            permission:['index_zone', 'index_franchise',],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, userOrAdminFranchise])
        },
        {
          path: '/sectors',
          name: 'sectors',
          component: Sectors,
          meta:{
            permission:['index_sector', 'index_zone', 'index_franchise',],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, notFranchise, notAdminFranchise])
        },
        {
          path: '/sectors-franchise',
          name: 'sectors-franchise',
          component: Sectors,
          meta:{
            permission:['index_sector', 'index_zone', 'index_franchise',],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, userOrAdminFranchise])
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./components/Users/Profile.vue'),
          beforeEnter: multiguard([ifVerified, ifBaseCurrency])
        },
        {
          path: '/unverified',
          name: 'unverified',
          component: EmailResend,
          beforeEnter: isVerified
        },
        {
          path: '/company',
          name: 'company',
          component: Company,
          beforeEnter: multiguard([userRoot, notAdmin, ifVerified])
        },
        {
          path: '/franchise',
          name: 'franchise',
          component: Franchise,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/serviceSale',
          name: 'serviceSale',
          meta:{
            permission:'acceptanceLetter',
            method:'r'
          },
          component: () => import('./views/ServiceSale/ServiceSale.vue'),
          beforeEnter: multiguard([notAdmin, notRoot, notAdminFranchise, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
            path: '/typeClient',
            name: 'typeClient',
            component: TypeClient,
            meta:{
              permission:'typeClient',
              method:'r'
            },
            beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/feasibilityFibra',
          name: 'feasibilityFibra',
          meta:{
            permission:'feasibilityReport',
            method:'r'
          },
          component: FeasibilityReport,
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/feasibilityNetwork',
          name: 'feasibilityNetwork',
          meta:{
            permission:'feasibilityNetwork',
            method:'r'
          },
          component: FeasibilityReportNetwork,
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/inspection',
          name: 'inspection',
          meta:{
            permission:'inspection',
            method:'r'
          },
          component: Inspection,
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency, userFranchise])
        },
        {
          path: '/requests',
          name: 'requests',
          component: Requests,
          meta:{
            permission:'contract',
            method:'r'
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/request/:token([a-zA-Z\\d+/=]{40})',
          name: 'request',
          meta:{
            permission:['requestInfo_contract','index_contractService'],
            mode:'all'
          },
          component: RequestInfo,
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/all-subscribers',
          name: 'AllSubscribersCDS',
          component: AllSubscribersCDS,
          meta:{
            permission: ['allCDS_subscriber'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/subscribers',
          name: 'subscribers',
          component: Subscribers,
          meta:{
            permission:'subscriber',
            method:'r'
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/filter-subscribers',
          name: 'filterSubscribersTotals',
          component: FilterSubscribersTotals,
          meta:{
            permission: ['filterAll_subscriber'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/filter-details',
          name: 'filterSubscribersDetails',
          component: FilterSubscribersDetails,
          meta:{
            permission: ['filterAll_subscriber'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/contracts-per-service',
          name: 'filterContractsPerService',
          component: ContractsPerService,
          meta:{
            permission: ['filterPerServiceTotals_contract'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/contracts-per-service-details',
          name: 'filterContractsPerServiceDetails',
          component: ContractsPerServiceDetails,
          meta:{
            permission: ['filterPerServiceDetails_contract'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/amounts-per-service',
          name: 'filterAmountsPerService',
          component: AmountsPerService,
          meta:{
            permission: ['filterPerServiceAmounts_contract'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/debt-per-subscriber',
          name: 'debtPerSubscriber',
          component: DebtPerSubscriber,
          meta:{
            // permission: ['getOverdueCutoff_subscriber'],
            permission: ['getDebtors_subscriber'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/migrate-subscriber',
          name: 'migrateSubscriber',
          component: MigrateSubscriber,
          meta:{
            permission:['inject_old_subscriber'],
            mode:'only'
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/subscribersGroups',
          name: 'subscribersGroups',
          component: SubscribersGroups,
          meta:{
            permission:['indexSubscribersGroups_catalog'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notFranchise, ifVerified, ifBaseCurrency])
        },
        {
            path: '/service',
            name: 'service',
            meta:{
              permission:'service',
              method:'r'
            },
            component: Service,
            beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
            path: '/package',
            name: 'package',
            component: Package,
            meta:{
              permission:'servicePackage',
              method:'r'
            },
            beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
            path: '/actors',
            name: 'actors',
            component: Actors,
            beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
            path: '/franchise-user',
            name: 'franchise_user',
            component: Actors,
            beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, adminFranchise])
        },
        {
          path: '/concept',
          name: 'concept',
          component: Concept,
          meta:{
            permission:'concept',
            method:'r'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/concept-franchise',
          name: 'concept-franchise',
          component: Concept,
          meta:{
            permission:'concept',
            method:'r'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userFranchise, notAdminFranchise])
        },
        {
          path: '/adjustmen-motives',
          name: 'adjustmenMotives',
          component: AdjustmentMotives,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/documentControl',
          name: 'documentControl',
          component: DocumentControl,
          meta:{
            permission:['index_clientDocumentControl','index_voidedNumber','index_employee','clientDocumentTypes_clientDocument','index_franchise', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdminFranchise, notFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/documentControl-franchise',
          name: 'documentControl-franchise',
          component: DocumentControl,
          meta:{
            permission:['index_clientDocumentControl','index_voidedNumber','index_employee','clientDocumentTypes_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userOrAdminFranchise])
        },
        {
          path: '/historyBills',
          name: 'historyBills',
          component: HistoryBills,
          meta:{
            permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/historyBills-franchise',
          name: 'historyBills-franchise',
          component: HistoryBills,
          meta:{
            permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userFranchise, notAdminFranchise])
        },
        {
          path: '/paymentsReceived',
          name: 'paymentsReceived',
          component: PaymentsReceived,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/paymentsReceived-franchise',
          name: 'paymentsReceived-franchise',
          component: PaymentsReceived,
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, adminFranchise])
        },
        {
          path: '/billingDocuments',
          name: 'billingDocuments',
          component: BillingDocuments,
          meta:{
            permission:['invoiceQueries_clientDocument', 'clientDocumentHeader_clientDocument',
              'invoiceableContracts_clientDocument','store_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/billingDocuments-franchise',
          name: 'billingDocuments-franchise',
          component: BillingDocuments,
          meta:{
            permission:['invoiceQueries_clientDocument', 'clientDocumentHeader_clientDocument',
              'invoiceableContracts_clientDocument','store_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userFranchise, notAdminFranchise])
        },
        {
          path: '/payments',
          name: 'payments',
          component: Payments,
          meta:{
            permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/payments-franchise',
          name: 'payments-franchise',
          component: Payments,
          meta:{
            permission:['invoiceQueries_clientDocument', 'index_clientDocument', 'access_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userFranchise, notAdminFranchise])
        },
        {
          path: '/banks',
          name: 'banks',
          component: Banks,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/servicesTypes',
          name: 'servicesTypes',
          component: ServicesTypes,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/requestBills',
          name: 'requestBills',
          component: RequestBills,
          meta:{
            permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, notFranchise])
        },
        {
          path: '/requestBills-franchise',
          name: 'RequestBillsFranchise',
          component: RequestBillsFranchise,
          meta:{
            permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
            mode:'all',
          },
          beforeEnter: multiguard([notRoot, notAdmin, ifVerified, ifBaseCurrency, userFranchise, notAdminFranchise])
        },
        {
          path: '/moneyReceived',
          name: 'moneyReceived',
          component: MoneyReceived,
          meta:{
            permission:['index_moneyReceived'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, ifBaseCurrency])
        },
        {
          path: '/materialsAndEquipment',
          name: 'materialsAndEquipment',
          component: MaterialsAndEquipment,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/employees',
          name: 'employees',
          component: Employees,
          meta:{
            permission:['index_employee','index_franchise','employeeTypes_employee'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, notFranchise, notAdminFranchise])
        },
        {
          path: '/employees-franchise',
          name: 'employees-franchise',
          component: Employees,
          meta:{
            permission:['index_employee','index_franchise','employeeTypes_employee'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, userOrAdminFranchise])
        },
        {
          path: '/employeeContracts',
          name: 'employeeContracts',
          component: EmployeeContracts,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/groups',
          name: 'groups',
          component: Groups,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/serviceInstallation',
          name: 'serviceInstallation',
          component: ServiceInstallation,
          meta:{
            permission:'serviceInstallation',
            method:'r'
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/service-activation',
          name: 'serviceActivation',
          component: IndependantServiceActivation,
          props: { activation_pending: true },
          meta:{
            permission:['indexActivationPending_serviceInstallation', 'indexAllActivationPending_serviceInstallation'],
            method:'only'
          },
          beforeEnter: multiguard([notRoot, notAdmin,/* adminFranchise,*/ ifVerified, ifBaseCurrency])
        },
        {
          path: '/filter-installations',
          name: 'filterInstallationsTotals',
          component: FilterInstallationsTotals,
          meta:{
            permission: ['filterAllTotals_serviceInstallation'],
            mode:'only',
          },
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        /*{
          path: '/permissions',
          name: 'permissions',
          component: Policies,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },*/
        {
          path: '/roles',
          name: 'roles',
          component: Roles,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/taxes',
          name: 'taxes',
          component: Taxes,
          meta:{
            permission:['index_taxes'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/bank-accounts',
          name: 'bankAccounts',
          component: BankAccounts,
          meta:{
            permission:['index_bankAccount'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/identity',
          name: 'identity',
          component: Identity,
          meta:{
            permission:['indexCorporateIdentity_catalog'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/position',
          name: 'position',
          component: Position,
          beforeEnter: multiguard([notRoot, userAdmin, ifVerified, ifBaseCurrency])
        },
        {
          path: '/reports',
          name: 'reports',
          component: Reports,
          meta:{
            permission:['index_employee','index_conceptMotive','index_sector','index_franchise','currencyCodes_exchangeRate'],
            mode:'all'
          },
          beforeEnter: multiguard([notRoot, ifVerified, ifBaseCurrency])
        },
        {
          path: '/system',
          name: 'system',
          component: System,
          beforeEnter: multiguard([notRoot, userAdmin])
        },
        {
          path: '/exchange-rates',
          name: 'exchangeRates',
          component: ExchangeRates,
          beforeEnter: multiguard([notRoot, notAdminFranchise, ifVerified, ifBaseCurrency])
        },
        {
          path: '/appatlas',
          // path: '/appatlas/:token([a-zA-Z\\d]{200})',
          name: 'appatlas',
          component: AppAtlas,
          beforeEnter: multiguard([notRoot, notAdmin, notAdminFranchise, ifVerified, ifAuthenticated])
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: NotificationsList,
          beforeEnter: multiguard([ifVerified, notRoot])/* notRoot -> System Admin. can't access (can be enabled for v2.0) */
        }
      ]
    }
  ]
})

router.afterEach((to, from) => {
  if(to.name == "home" && to.name == "NotFound404") {
    Vuetify.framework.theme.dark = false
  }
  else{
    let mode = false
    if (store.getters.dataUser.stUser && store.getters.dataUser.stUser.dark_mode) {
      mode = store.getters.dataUser.stUser.dark_mode
    }
    Vuetify.framework.theme.dark = mode
  }
})

router.beforeEach((to, from, next) => {
  if(to.meta['permission'] !== undefined &&
     !store.getters.isRoot &&
     !store.getters.isAdmin &&
     !store.getters.isFranchiseAdmin &&
     !store.getters.getPoliciesGroup({...to.meta})
  ){
    emitNotification('error', 6000, 'error422.forbidden');
    next('/403')
  } else {
    next()
  }
})

export default router
