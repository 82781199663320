//
export default {
  name: "EmployeeContracts",
  components:{},
  props:['employeeProp'],
  data (){
    return {
      edit: false,
      expand: false,
      dialog1: false,
      ShowEmployeeSearch:false,
      disabledAssing:true,
      show_select:false,
      loading: false,
      loadingAttach:false,
      loadingTable:false,
      loadingEmployees:false,
      is_filter:false,
      its_filter_employee:false,
      fullscreen:false,
      options:{
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      searches:{
        contract_id:'',
        doc:'',
        legal_denomination:'',
        employee_id:'',
        franchise_id:'',
      },
      contracts:[],
      employees:[],
      contractAttach:[],
      step : 'tab-1',
      contractURL:'attached',
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      unwatch: '',
      search:'',
      searchEmployee:'',
      searchClient:'',
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  computed: {
    headers(){
      let headers = [
        {
          text: this.$tc('base.code',3),
          align: 'left',
          value: 'id',
          width: 86
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'subscriber.doc',
          width: 100
        },
        {
          text: this.$t('subscribers.legal_denomination'),
          value: 'subscriber.legal_denomination'
        }
      ];
      if (!this.$store.getters.isFranchise/* || this.step == 'tab-2'*/) {
        headers.push(
          {
            text: this.$t('employees.franchise'),
            value: 'franchise.name_franchise',
            align: 'left'
          }
        );
      }
      headers.push(
        {
          text: this.$t('Taxes.lastUpdate'),
          value: 'updated_at',
          align: 'center',
          width: 180
        }
      );
      return headers
    },
    ComputeContracts(){
      return Object.entries(this.contractAttach).length;
    }
  },
  methods: {
    List(){
      this.loadingTable = true;
      this.contracts =[];
      this.contractAttach =[];
      if (( this.searches.contract_id != '' || this.searches.doc != '' || this.searches.legal_denomination != ''  ||
            this.searches.employee_id != '' || this.searches.franchise_id != '') && this.is_filter)
      {
        this.options.page = 1;
      }
      if (this.step =='tab-1') {
        this.contractURL = 'attached';
      } else if (this.step =='tab-2') {
        this.contractURL = 'unattached';
      }
      // eslint-disable-next-line no-console
      // console.log(this.searches);
      axios.get(this.$store.getters.getEmployeesContracts(this.contractURL),
      {
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          contract_id:this.searches.contract_id,
          doc:this.searches.doc,
          legal_denomination:this.searches.legal_denomination,
          employee_id:this.searches.employee_id,
          franchise_id:this.franchise_id,
        }
      }
      ).then(response=>{
        let contracts =[];
        if (this.step =='tab-1') {
          contracts = response.data.attached_contracts.data;
          this.totalValues = response.data.attached_contracts.total;
        } else if (this.step =='tab-2') {
          contracts = response.data.unattached_contracts.data;
          this.totalValues = response.data.unattached_contracts.total;
        }

        contracts.map(contract=>{
          contract.legal_denomination = contract.subscriber.legal_denomination;
          contract.doc = contract.subscriber.doc;
        });

        this.contracts = contracts;
        this.disabledAssing = true;
        // attached_contracts unattached_contracts
        // eslint-disable-next-line no-console
        // console.log(this.contracts);
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        }
      }).finally(()=>{
        this.loadingTable = false;
        this.is_filter = false;
      });
    },
    SearchFilter(){
      this.is_filter = true;
      if (this.searches.employee_id !='') {
        this.show_select = true;
      } else {
        this.show_select = false;
      }
      this.options={sortBy:['updated_at'],sortDesc:[true]};
    },
    AutoFilter(){
      if(this.employeeProp != undefined){
        // eslint-disable-next-line no-console
        // console.log('llego',this.employeeProp);
        this.ShowEmployeeSearch = true;
        this.searches.employee_id = this.employeeProp.id;
        this.SearchFilter();
      } else {
        this.ShowEmployeeSearch = false;
      }
    },
    EraseFilter(){
      this.disabledAssing = true;
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      // eslint-disable-next-line no-console
      // console.log('employeeProp', this.employeeProp)
      if(this.employeeProp != undefined){
        this.searches={dni:'',active:'',type:'',franchise_id:''};
        this.searches.employee_id = this.employeeProp.id;
        this.SearchFilter();
      } else if ((this.searches.doc != '' || this.searches.legal_denomination != '') && this.employeeProp == undefined){
        // eslint-disable-next-line no-console
        // console.log('contracts')
        this.searches.doc = null;
        this.searches.legal_denomination = null;
        this.SearchFilter();
      } else {
        // eslint-disable-next-line no-console
        // console.log('all')
        this.show_select = false;
        this.searches={dni:'',active:'',type:'',franchise_id:'',employee_id:''};
        this.List();
      }
      // eslint-disable-next-line no-console
      // console.log('Eraser', this.disabledAssing);
    },
    AddContract(){
      // eslint-disable-next-line no-console
      // console.log('Attach',this.contractAttach);
      if (this.step == 'tab-2') {
        this.AttachUnattachContract({
          employee_id:this.searches.employee_id,
          contracts: Object.assign({}, this.contractAttach),
          type:'attach'
        });
      } else {
        this.AttachUnattachContract({
          employee_id:this.searches.employee_id,
          contracts: Object.assign({}, this.contractAttach),
          type:'unattach'
        });
      }
    },
    AttachUnattachContract(item){
      let count = Object.entries(item.contracts).length;
      // eslint-disable-next-line no-console
      // console.log(count,item.type);
      let action ='', pre = '';
      if (count == 1) {
        pre = 'success';
        action = item.type == 'attach'? this.$tc('assigned2',1) : this.$tc('denied2',1);
      } else {
        pre = 'success2';
        action = item.type == 'attach'? this.$tc('assigned2',2) : this.$tc('denied2',2);
      }
      if (Object.prototype.hasOwnProperty.call(item, 'employee_id') && Object.prototype.hasOwnProperty.call(item, 'contracts')) {
        this.$store.dispatch('confirmationValue',{
          show: true,
          pre: pre,
          model: count ==1 ? this.$tc('contracts.contracts',1) : this.$tc('contracts.contracts',2),
          title: this.step == 'tab-2' ? this.$t('assign') : this.$t('contracts.deny'),
          action: action,
          content: this.$t('base.confirm_delete',{
            action: this.step == 'tab-2' ? this.$t('assign') : this.$t('contracts.deny'),
            record: count ==1 ? count +' '+this.$tc('contracts.contracts',1) : count +' '+this.$tc('contracts.contracts',2)
          }),
          type: 'info',
          confirm:{
            url: this.$store.getters.getEmployeesContracts(item.type),
            method: 'post',
            data: item,
          },
          pos:1
        });
      }
    },
    employeeID(item){
      this.searches.employee_id = item.id;
      this.SearchFilter();
    },
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
    step(){
      this.List();
      // eslint-disable-next-line no-console
      // console.log('step');
    },
    searchClient(val){
      // Regex que detecta si es una cedula, puede incluir o no el prefijo
      const dni = /^([\s['vVgGeEjJ']?\d{2,10})/; // /^([\s\D{1}]?\d{3,10})/
      const words = /^[a-zA-Z\s]/; //  /^(?:\w+\s?){2}/
      // eslint-disable-next-line no-console
      // console.log(val, dni.test(val), words.test(val), val.length)
      if (val != undefined) {
        if (dni.test(val)) {
          this.searches.doc = val;
          this.searches.legal_denomination = '';
          // this.SearchFilter();
        } else if (words.test(val) && val.length >2 ){
          this.searches.doc = '';
          this.searches.legal_denomination = val;
          // this.SearchFilter();
        } else if (val == ''){
          this.searches.doc = null;
          this.searches.legal_denomination = null;
          this.options.page = 1;
        }
      }
    },
    contractAttach(val){
      if (Object.entries(val).length !=0 && this.searches.employee_id !='') {
        this.disabledAssing = false;
      }else {
        this.disabledAssing = true;
      }
    },
  },
  mounted() {
    this.AutoFilter();
  },
};
