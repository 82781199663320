//
export default {
  name: "SubscriberSearch",
  props:{
    disableField:{
      type: Boolean,
      default: false,
    }
  },
  components:{
  },
  data (){
    return {
      loadingSubscriber:false,
      paramClient:{
        doc:'',
        legal_denomination:'',
      },
      SubscribersList:[],
      searchSubscriber:'',
      Subscriber:'',
    };
  },
  computed: {
    unrestrictedSearch () {
      return this.getPermissionGroup({
        permission: ["unrestrictedSubscriberSearch_contract"],
        mode: "all"
      });
    },
    SelectSubscriber () {
      return this.SubscribersList.map(contract => {
        return {
          'subscriber_id': contract.subscriber.id,
          'text': contract.doc +' - '+ contract.legal_denomination +' - '+this.$tc('contracts.contracts',1) +' - '+ ("0000000"+contract.id).slice(-6),
          'contract': contract,
        };
      });
    },
  },
  watch: {
    searchSubscriber(val){
      if (val != undefined && val.length >2) {
        // Regex que detecta si es una cedula, puede incluir o no el prefijo
        const dni = /^([\s['vVgGeEjJ']?\d{3,10})/; // /^([\s\D{1}]?\d{3,10})/
        const words = /^(?:\w+\s?){2}/;
        if (dni.test(val)) {
          this.paramClient.doc = val;
          this.paramClient.legal_denomination = '';
          this.getSubscribers();
        } else if (words.test(val)){
          this.paramClient.doc = '';
          this.paramClient.legal_denomination = val;
          this.getSubscribers();
        }
      }
    },
  },
  methods:{
    getSubscribers(){
      let url = this.unrestrictedSearch ?
                  this.$store.getters.unrestrictedSubscriberSearch :
                  this.$store.getters.getClientDocumentContracts
      
      this.loadingSubscriber = true;
      const paginate = this.paramClient.doc.length > 5 || this.paramClient.legal_denomination.length > 5
        ? 100
        : 10;

      axios.get(url,
        {
          params:{
            page: 1,
            paginate,
            doc: this.paramClient.doc,
            legal_denomination: this.paramClient.legal_denomination,
          }
        }
      ).then(response=>{
        response.data.contracts.data.map(contract=>{
          contract.legal_denomination = contract.subscriber.legal_denomination;
          contract.doc = contract.subscriber.doc;
        });
        // eslint-disable-next-line no-console
        this.SubscribersList = response.data.contracts.data;
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error(error.response);
        }
      }).finally(()=>{
        this.loadingSubscriber = false;
        this.paramClient={doc:'',legal_denomination:'',};
      });
    },
    TakeSubscriber(){
      // eslint-disable-next-line no-console
      // console.log(this.Subscriber);
      this.$emit('subscriber', this.Subscriber);
    },
    clear(){
      // eslint-disable-next-line no-console
      // console.log('clear');
      this.Subscriber = '';
      this.SubscribersList = [];
      this.loadingSubscriber = false;
      this.paramClient={doc:'',legal_denomination:'',};
      this.$emit('clearing');
    },
    clear2(){
      this.Subscriber = '';
      this.SubscribersList = [];
      this.loadingSubscriber = false;
      this.paramClient={doc:'',legal_denomination:'',};
    }
  },
};
