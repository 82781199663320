import DialogSector from './DialogSector.vue';

export default {
  name: "sectors",
  components: {
    DialogSector
  },
  data() {
    return {
      loading: false,
      loadingTable:false,
      edit: false,
      sectors:[],
      searchBy:{
        by_name:'',
        parish_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      Permissions:{
        edit: false,
        create: false,
        delete: false
      },
      // Paged
      options:{
        page: 1,
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      perPage: 10,
      totalValues:0,
      // Others
      unwatch: '',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      step: 'tab-1',
    }
  },
  computed: {
    headers(){
      let headers = [
        {
          text: this.$t('name'),
          value:'name',
          align: 'left',
        },
      ];
      if (this.Permissions.delete || this.Permissions.edit) {
        headers.push(
          {
            text: this.Permissions.edit ? this.$t('actions') : this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'right'
          }
        );
      }
      return headers;
    }
  },
  methods: {
    List(){
      this.loadingTable = true;
      if (this.step =='tab-1') {
        this.searchBy.status = 1;
      } else if (this.step =='tab-2') {
        this.searchBy.status = 0;
      }
      axios.get(this.$store.getters.getSectors(),
      {
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
          orderBy: this.options.sortBy[0] || null,
          dirDesc: this.options.sortDesc[0] || null,
          name:this.searchBy.by_name,
          parish_id:this.searchBy.parish_id,
          franchise_id: this.searchBy.franchise_id,
          zone_id: this.searchBy.zone_id,
          get_all: true,
          status: this.searchBy.status
        }
      }
      ).then(response=>{
        // eslint-disable-next-line no-console
        // console.log(response.data.sectors);
        this.sectors = response.data.sectors.data;
        this.totalValues = response.data.sectors.total;
      }).catch(error=>{
        if (error.response && this.modeNode == 'development') {
          // eslint-disable-next-line no-console
          console.log(error.response);
        }
      }).finally(()=>{
        if (this.modeNode == 'development') {
          setTimeout(() => {
            this.loadingTable = false;
          }, 1500);
        } else {
          this.loadingTable = false;
        }
      })
    },
    filterBy(item, type){
      this.options.page = 1;
      this.searchBy[type] = item;
      // eslint-disable-next-line no-console
      // console.log('searchBy',this.searchBy);
      this.List();
    },
    newItem(){
      this.$refs.dialog_sector.openSector(false);
    },
    editItem(item){
      this.loadingTable = true;
      axios.get(this.$store.getters.getSectors(item.id))
      .then(response=>{
        // eslint-disable-next-line no-console
        // console.log(response.data.success);
        this.$refs.dialog_sector.openSector(true, response.data.success);
      }).catch(error=>{
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(()=>{
        this.loadingTable = false;
      })
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        template: 'Sectors',
        show: true,
        model: this.$t('sector')+' '+item.name,
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getSectors(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    activateItem (item) {
      this.activateDeactivateItem(item,this.$t('activate'),this.$tc('activated',0), true);
    },
    deactivateItem (item) {
      this.activateDeactivateItem(item,this.$t('deactivate'),this.$tc('deactivated',0), false);
    },
    activateDeactivateItem (item, main_text, text, active) {
      let sector = Object.assign({}, item);
      sector.is_active = active;
      // eslint-disable-next-line no-console
      // console.log('sector', sector);
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('sector'),
        title: main_text,
        action: text,
        content: this.$t('base.confirm_delete',{
          action: main_text,
          record: sector.name
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getSectors(sector.id),
          method: 'put',
          data: sector
        }
      });
    },
    eraseFilter(){
      this.searchBy = {
        by_name: '',
        parish_id : null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      };
      // eslint-disable-next-line no-console
      // console.log(this.searchBy);
      this.List();
    },
    PermissionsSectors(){
      if (this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin){
        this.Permissions={ edit: true, create: true, delete: true};
      } else {
        this.Permissions.create = this.getPermissionGroup({
          permission:[
            'index_sector',
            'store_sector',
          ],
          mode:'all'
        });
        this.Permissions.edit = this.getPermissionGroup({
          permission:[
            'index_sector',
            'show_sector',
            'update_sector',
          ],
          mode:'all'
        });
        this.Permissions.delete = this.getPermissionGroup({
          permission:[
            'index_sector',
            'destroy_sector',
          ],
          mode:'all'
        });
      }
    }
  },
  mounted() {
    // this.List();
    this.PermissionsSectors();
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
    step(){
      this.List();
    }
  }
};
