var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","scrollable":"","fullscreen":_vm.fullscreen},on:{"click:outside":_vm.close},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dense":"","tile":""}},[_c('v-toolbar-title',[_c('span',{class:_vm.fullscreen
              ? 'subtitle-1 font-weight-bold py-1'
              : 'headline font-weight-bold'},[(!_vm.edit)?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.$tc("base.new", 0))))+" ")]):_c('span',[_vm._v(" "+_vm._s(("" + (_vm.$t("base.edit"))))+" ")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.$tc("employees.employee", 1))+" ")])])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","fab":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_c('v-icon',[(_vm.fullscreen)?[_vm._v("fa-compress-arrows-alt")]:[_vm._v("fa-expand-arrows-alt")]],2)],1),(_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()],1)],1),_c('v-card-text',{staticStyle:{"margin-top":"-25px !important"}},[_c('v-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"form_employee",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"employee-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card',{staticClass:"card-outlined",staticStyle:{"margin-bottom":"20px"},attrs:{"outlined":"","height":"100%"}},[_c('v-card-title',[_c('div',{staticClass:"title primary--text"},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("personal_info"))+" ")])])]),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('ValidationProvider',{attrs:{"vid":"prefix","name":_vm.$tc('subscribers.prefix', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.prefixes,"name":"prefix","prepend-icon":"fas fa-list","error-messages":errors,"label":_vm.$tc('subscribers.prefix', 1),"loading":_vm.loadingSelPrefix},on:{"change":function($event){return _vm.ChangePrefix(_vm.employee.prefix, true)}},model:{value:(_vm.employee.prefix),callback:function ($$v) {_vm.$set(_vm.employee, "prefix", $$v)},expression:"employee.prefix"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"vid":"doc","name":!_vm.is_firm
                          ? _vm.$tc('subscribers.dni', _vm.dni_type)
                          : _vm.$tc('subscribers.dni', 1),"rules":"required|min:6|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"doc","counter":"","maxlength":"9","prepend-icon":"fas fa-address-card","error-messages":errors,"label":!_vm.is_firm
                            ? _vm.$tc('subscribers.dni', _vm.dni_type)
                            : _vm.$tc('subscribers.dni', 1)},on:{"keydown":_vm.onlyDigits},model:{value:(_vm.employee.dni),callback:function ($$v) {_vm.$set(_vm.employee, "dni", $$v)},expression:"employee.dni"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'yes'),expression:"showFullName == 'yes'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-checkbox',{staticClass:"small-label",attrs:{"dense":"","hide-details":"","label":_vm.$t('subscribers.is_firm'),"color":"primary"},on:{"change":function($event){return _vm.ChangeIs_Firm(_vm.is_firm, true)}},model:{value:(_vm.is_firm),callback:function ($$v) {_vm.is_firm=$$v},expression:"is_firm"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'yes'),expression:"showFullName == 'yes'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"first_name","name":_vm.$t('employees.first_name'),"rules":{
                        required: _vm.Valitate_names,
                        regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"first_name","prepend-icon":"fas fa-user","error-messages":errors,"label":_vm.$t('employees.first_name')},on:{"keydown":_vm.onlyLetters},model:{value:(_vm.employee.first_name),callback:function ($$v) {_vm.$set(_vm.employee, "first_name", $$v)},expression:"employee.first_name"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'yes'),expression:"showFullName == 'yes'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"last_name","name":_vm.$t('employees.last_name'),"rules":{
                        required: _vm.Valitate_names,
                        regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"last_name","prepend-icon":"fas fa-user","error-messages":errors,"label":_vm.$t('employees.last_name')},on:{"keydown":_vm.onlyLetters},model:{value:(_vm.employee.last_name),callback:function ($$v) {_vm.$set(_vm.employee, "last_name", $$v)},expression:"employee.last_name"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'no' || _vm.is_firm),expression:"showFullName == 'no' || is_firm"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"legal_denomination","name":_vm.is_firm
                          ? _vm.$t('employees.firm_name')
                          : _vm.$t('employees.legal_denomination'),"rules":{
                        required: _vm.Valitate_legal_denomination || _vm.is_firm,
                        regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"legal_denomination","prepend-icon":"fas fa-user","error-messages":errors,"label":_vm.is_firm
                            ? _vm.$t('employees.firm_name')
                            : _vm.$t('employees.legal_denomination')},on:{"keydown":_vm.onlyLetters},model:{value:(_vm.employee.legal_denomination),callback:function ($$v) {_vm.$set(_vm.employee, "legal_denomination", $$v)},expression:"employee.legal_denomination"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName != 'other'),expression:"showFullName != 'other'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('employees.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","prepend-icon":"fas fa-envelope","error-messages":errors,"label":_vm.$t('employees.email')},model:{value:(_vm.employee.email),callback:function ($$v) {_vm.$set(_vm.employee, "email", $$v)},expression:"employee.email"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName != 'other'),expression:"showFullName != 'other'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"local_phone","name":_vm.$t('employees.local_phone'),"rules":"required|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"local_phone","counter":"","maxlength":"11","prepend-icon":"fas fa-phone fa-rotate-180","error-messages":errors,"label":_vm.$t('employees.local_phone')},on:{"keydown":_vm.onlyDigits},model:{value:(_vm.employee.local_phone),callback:function ($$v) {_vm.$set(_vm.employee, "local_phone", $$v)},expression:"employee.local_phone"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName != 'other'),expression:"showFullName != 'other'"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"cellphone","name":_vm.$t('employees.cellphone'),"rules":"required|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"cellphone","counter":"","maxlength":"11","prepend-icon":"fas fa-mobile-alt","error-messages":errors,"label":_vm.$t('employees.cellphone')},on:{"keydown":_vm.onlyDigits},model:{value:(_vm.employee.cellphone),callback:function ($$v) {_vm.$set(_vm.employee, "cellphone", $$v)},expression:"employee.cellphone"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'yes' && !_vm.is_firm),expression:"showFullName == 'yes' && !is_firm"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"gender","name":_vm.$t('employees.gender'),"rules":{ required: _vm.Valitate_names && !_vm.is_firm }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"gender","prepend-icon":"fas fa-venus-mars","items":_vm.genders,"error-messages":errors,"label":_vm.$t('employees.gender')},model:{value:(_vm.employee.gender),callback:function ($$v) {_vm.$set(_vm.employee, "gender", $$v)},expression:"employee.gender"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName == 'yes' && !_vm.is_firm),expression:"showFullName == 'yes' && !is_firm"}],attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-dialog',{ref:"dialog_date",attrs:{"return-value":_vm.employee.birthdate,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.employee, "birthdate", $event)},"update:return-value":function($event){return _vm.$set(_vm.employee, "birthdate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('ValidationProvider',{attrs:{"vid":"birthdate","name":_vm.$t('employees.birthdate'),"rules":{
                            required: _vm.Valitate_names && !_vm.is_firm
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"name":"birthdate","error-messages":errors,"label":_vm.$t('employees.birthdate'),"hint":_vm.$store.getters.getDateFormat[0],"prepend-icon":"fas fa-calendar","persistent-hint":"","readonly":""},model:{value:(_vm.computedDateFormattedBirthdate),callback:function ($$v) {_vm.computedDateFormattedBirthdate=$$v},expression:"computedDateFormattedBirthdate"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dialog_birthdate),callback:function ($$v) {_vm.dialog_birthdate=$$v},expression:"dialog_birthdate"}},[_c('v-date-picker',{ref:"picker",attrs:{"scrollable":"","min":"1920-01-01","max":_vm.years_18_ago},on:{"input":function($event){_vm.dialog_date = false;
                          _vm.$refs.dialog_date.save(_vm.employee.birthdate);}},model:{value:(_vm.employee.birthdate),callback:function ($$v) {_vm.$set(_vm.employee, "birthdate", $$v)},expression:"employee.birthdate"}})],1)],1)],1)],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullName != 'other'),expression:"showFullName != 'other'"}],staticClass:"card-outlined",staticStyle:{"margin-bottom":"20px"},attrs:{"outlined":"","height":"100%"}},[_c('v-card-title',[_c('div',{staticClass:"title primary--text"},[_c('span',{staticClass:"px-1 white"},[_vm._v(" "+_vm._s(_vm.$t("work_info"))+" ")])])]),_c('v-card-text',{staticClass:"pb-2"},[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[(_vm.franchise_id == 0)?_c('v-col',{staticClass:"pr-5",attrs:{"cols":"12","sm":"8","md":"8"}},[(_vm.franchise_id == 0)?_c('v-autocomplete',{attrs:{"name":"franchise_id","items":_vm.ListFranchises,"item-text":"name","item-value":"id","clearable":"","clear-icon":"fas fa-times","prepend-icon":"fas fa-building","label":_vm.$t('employees.franchise'),"data-vv-as":_vm.$t('employees.franchise')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : "")+" ")]}},{key:"selection",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : "")+" ")]}}],null,true),model:{value:(_vm.employee.franchise_id),callback:function ($$v) {_vm.$set(_vm.employee, "franchise_id", $$v)},expression:"employee.franchise_id"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"vid":"type","name":_vm.$t('employees.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"type","prepend-icon":"fas fa-briefcase","items":_vm.selectEmplyeesTypes,"label":_vm.$t('employees.type'),"error-messages":errors,"disabled":_vm.employee.is_external_collector},model:{value:(_vm.employee.employee_type),callback:function ($$v) {_vm.$set(_vm.employee, "employee_type", $$v)},expression:"employee.employee_type"}})]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(!_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.cancel")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e(),_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.save")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.edit")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }