import './axiosDeclared.js';
// # API REST URL
export let apiUrl = process.env.NODE_ENV === 'production' ?
    process.env.VUE_APP_SERVER_API_URL : process.env.VUE_APP_API_URL

export default {
  getters: {
    get503Status: (state) => {
      return apiUrl + 'ping'
    },
    getUserUrl: (state) => {
      return apiUrl + 'user'
    },
    checkIfRoot: (state) => {
      return apiUrl + 'user/wurzel'
    },
    checkIfAdmin: (state) => {
      return apiUrl + 'user/woyang'
    },
    checkIfFranchise: (state) => {
      return apiUrl + 'user/anapa'
    },
    getUserInfo: (state) => {
      return apiUrl + 'user/info'
    },
    getUserPolicies: (state) => {
      return apiUrl + 'user/policies'
    },
    getUserSettings: (state) => {
      return apiUrl + 'user/settings'
    },
    regUserUrl: (state) => {
      return apiUrl + 'register'
    },

    logInUrl: (state) => {
      return apiUrl + 'login'
    },

    logOutUrl: (state) => {
      return apiUrl + 'logout'
    },

    updateUserUrl: (state) => {
      return apiUrl + 'update'
    },

    delUserUrl: (state) => (id) => {
      return apiUrl + 'delete/' + id
    },

    // Email verification URLs
    checkEmailUrl: (state) => (id) => {
      return apiUrl + 'email/check/' + id
    },

    resendEmailUrl: (state) => (id) => {
      return apiUrl + 'email/resend/' + id
    },

    // Password Reset URLs
    passRequestUrl: (state) => {
      return apiUrl + 'password/request'
    },

    passFindUrl: (state) => (token) => {
      return apiUrl + 'password/find/' + token
    },

    passResetUrl: (state) => {
      return apiUrl + 'password/reset'
    },

    // Activity Log
    getActivity: (state) => {
      return apiUrl + 'activity'
    },

    // Subscriber Routes
    getPrefixes: (state) => {
      return apiUrl + 'subscriber/doc-prefixes'
    },
    getSubscriberByDoc: (state) => (val) => {
      return apiUrl + `subscriber/${val['prefix']}/${val['ced']}`
    },
    getFullSubscriberInfo: (state) => (val) => {
      return apiUrl + `subscriber/${val['prefix']}/${val['ced']}/full`
    },
    getAllSubscribersCDS: (state) => (pk) => {
      return apiUrl + 'subscriber/all-cds'
    },
    getSubscribers: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'subscriber/' + pk + '/' :
        apiUrl + 'subscriber/'
    },
    changeSubscriberCondition: (state) => (pk) => {
      return apiUrl + 'subscriber/' + pk + '/prospect-condition/change/'
    },
    getSubscriberFilesLibrary: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'subscriber_file_library/' + pk + '/' :
        apiUrl + 'subscriber_file_library/'
    },
    resendSubscriberEmailUrl: (state) => (cmpny, id) => {
      return apiUrl + 'subscriber/email/resend/' + cmpny + '/' + id
    },
    checkSubscriberEmailUrl: (state) => (cmpny, id) => {
      return apiUrl + 'subscriber/email/check/' + cmpny + '/' + id
    },
    unrestrictedSubscriberSearch: (state) => {
      return apiUrl + 'unrestricted-subscriber-search'
    },
    subscriberWalletMovements: (state) => (pk) => {
      return apiUrl + 'subscriber/' + pk + '/wallet/movements/'
    },
    subscriberOverwriteBalance: (state) => (pk) => {
      return apiUrl + 'subscriber/' + pk + '/balance/overwrite/'
    },
    togglePermanentlyRetired: (state) => (pk) => {
      return apiUrl + 'subscriber/' + pk + '/permanently-retired/'
    },

    // Old Billing History
    getOldBillingHistory: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'subscriber/old-billing-history/' + pk + '/' :
        apiUrl + 'subscriber/old-billing-history/'
    },
    oldBillingHistoryBulkLoad: (state) => (subscriber_id, contract_code) => {
      return apiUrl + `subscriber/old-billing-history/bulk-load/${subscriber_id}/${contract_code}/`
    },

    // Request Details
    getRequestDetails: (state) => {
      return apiUrl + 'contract/request/info/'
    },

    // Contract Routes
    getContracts: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract/' + pk + '/' :
        apiUrl + 'contract/'
    },
    getContractAddresses: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract_address/' + pk + '/' :
        apiUrl + 'contract_address/'
    },
    getContractContacts: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract_contact/' + pk + '/' :
        apiUrl + 'contract_contact/'
    },
    getContractServiceStatuses: (state) => {
      return apiUrl + 'contract_service/list_statuses/'
    },
    overrideContractServiceStatus: (state) => (ids_array) => {
      return apiUrl + 'contract_service/override_status/['+ ids_array +']/'
    },
    overrideContractService: (state) => (ids_array) => {
      return apiUrl + 'contract_service/override_service/['+ ids_array +']/'
    },
    getContractServices: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract_service/' + pk + '/' :
        apiUrl + 'contract_service/'
    },
    getContractAddrContactsServs: (state) => (pk) => {
      return apiUrl + 'contract/' + pk + '/full/'
    },
    checkContractCode: (state) => (code, subscriber_id) => {
      return subscriber_id ?
        apiUrl + 'contract/check-code/' + code + '/' + subscriber_id + '/' :
        apiUrl + 'contract/check-code/' + code + '/'
    },

    // Contract's Statuses
    getContractStatuses: (state) => {
      return apiUrl + 'contract/status/list/'
    },

    getContractServiceParams: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract_service_param/' + pk + '/' :
        apiUrl + 'contract_service_param/'
    },
    getContractPackage: (state) => (pack) => {
      return apiUrl +
        `contract_package/${pack.contract}/${pack.pack}/`
    },
    getContractsByParameters: (state) => {
      return apiUrl + 'client_document/contracts/get/'
    },

    // Activate when migrated Subscriber
    migrateContractAndServices: (state) => (contract_id) => {
      return apiUrl + `migrate-subscriber/${contract_id}/`
    },

    // Filter All Contracts by multiple conditions - Totals
    filterAllContractsTotals: (state) => (pk) => {
      return apiUrl + 'contract/filter/totals/'
    },
    // Filter All Contracts by multiple conditions - Details
    filterAllContractsDetails: (state) => (pk) => {
      return apiUrl + 'contract/filter/details/'
    },
    // Filter Contracts per Service - Totals
    filterContractsPerServiceTotals: (state) => {
      return apiUrl + 'contract/filter/per-service/totals/'
    },
    // Filter Contracts per Service - Details
    filterContractsPerServiceDetails: (state) => {
      return apiUrl + 'contract/filter/per-service/details/'
    },
    // Filter Contracts per Service Amounts - Totals
    filterContractsPerServiceAmounts: (state) => {
      return apiUrl + 'contract/filter/per-service/amounts/'
    },
    // Filter Debt per Subscriber
    filterDebtPerSubscriber: (state) => {
      return apiUrl + 'subscriber/debtors/'
      // Changed at the customer's request on December 17, 2021
      // return apiUrl + 'subscriber/overdue-and-cutoff/'
    },
    // Change billing type
    getChangeBillingType: (state) => (id,type) => {
      return apiUrl +
        `contract/change-billing/${id}/${type}/`;
    },
    // Send an Asking Email to Contract Contacts
    sendAskingEmail: (state) => {
      return apiUrl + 'contract/asking/email/'
    },

    getContractInstalledHardware: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'contract_installed_hardware/' + pk + '/' :
        apiUrl + 'contract_installed_hardware/'
    },
    // Services Routes
    getCorpServices: (state) => {
      return apiUrl + 'corporate_service/list/'
    },
    getCorpServiceParams: (state) => (id) => {
      return apiUrl + `corporate_service/id/${id}/params`
    },
    getService: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'service/' + pk + '/' :
        apiUrl + 'service/'
    },
    getParam: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'serviceparams/' + pk + '/' :
        apiUrl + 'serviceparams/'
    },
    getServiceConfigTypes: (state) => {
      return apiUrl + 'service-config-types'
    },

    // Packages Routes
    getPackage: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'servicepackage/' + pk + '/' :
        apiUrl + 'servicepackage/'
    },
    getPackageConfig: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'servicepackageconfig/' + pk + '/' :
        apiUrl + 'servicepackageconfig/'
    },
    getParamConfig: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'serviceparamconfig/' + pk + '/' :
        apiUrl + 'serviceparamconfig/'
    },

    // Companies Routes
    getCompanies: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'company/' + pk + '/' :
        apiUrl + 'company/'
    },

    activateCompany: (state) => (pk) => {
      return apiUrl + 'company/activate/' + pk
    },

    getUserCompanies: (state) => {
      return apiUrl + 'user/companies'
    },
    getCompanyConn: (state) => (pk) => {
      return apiUrl + 'company/' + pk + '/conn'
    },
    listUserDefaultableCompanies: (state) => (pk) => {
      return apiUrl + 'company/list/defaultable/' + pk
    },
    setUserDefaultCompany: (state) => (pk) => {
      return apiUrl + 'company/set/' + pk + '/default'
    },
    getCompanySettings: (state) => {
      return apiUrl + 'company/settings'
    },

    // Generic Routes
    getAllCountries: (state) => {
      return apiUrl + 'countries/'
    },
    getStates: (state) => (id) => {
      return apiUrl + `states/${id}/`
    },
    getCities: (state) => (id) => {
      return apiUrl + `cities/${id}/`
    },
    getMunicipalities: (state) => (id) => {
      return apiUrl + `municipalities/${id}/`
    },
    getParishes: (state) => (id) => {
      return apiUrl + `parishes/${id}/`
    },
    getSectorsByParish: (state) => (id) => {
      return apiUrl + `sectors/${id}/`
    },
    getSectors: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'sector/' + pk + '/' :
        apiUrl + 'sector/'
    },
    getZones: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'zone/' + pk + '/' :
        apiUrl + 'zone/'
    },

    // Roles Routes
    groupSources: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'groups/' + pk + '/' :
        apiUrl + 'groups/'
    },
    policiesSources: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'policies/' + pk + '/' :
        apiUrl + 'policies/'
    },
    rolesSources: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'roles/' + pk + '/' :
        apiUrl + 'roles/'
    },
    policyGroups: (state) => {
      return apiUrl + 'policy-groups/'
    },

    // Actor Routes
    actorsSources: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'actors/' + pk + '/' :
        apiUrl + 'actors/'
    },

    actorImageSources: (state) => (pk) => {
      return apiUrl + 'actor/image/' + pk + '/'
    },

    actorIndiviualPoliciesSources: (state) => (pk) =>{
      return apiUrl + 'actor/policies/' + pk + '/'
    },

    getActorsCount: (state) => {
        return apiUrl + 'actorsCount/'
    },

    // Catalog Routes
    catalogList: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'catalog/' + pk + '/' :
        apiUrl + 'catalog/'
    },
    catalogByCategory: (state) => (category) => {
      return apiUrl + 'catalog/category/' + category + '/'
    },
    // Bank Accounts (Catalog)
    getBankAccounts: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'catalog/bank-account/' + pk + '/' :
        apiUrl + 'catalog/bank-account/'
    },

    // Exchange Rates Routes
    exchangeRates: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'exchange_rate/' + pk + '/' :
        apiUrl + 'exchange_rate/'
    },
    restoreExchangeRate: (state) => (pk) => {
      return apiUrl + 'exchange_rate/' + pk + '/restore/'
    },
    taxBillRate: (state) => {
      return apiUrl + 'exchange_rate/tax_bill/'
    },
    getCurrencyCodes: (state) => {
      return apiUrl + 'company_currency_codes'
    },

    //Type Clients
    getTypeClient: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'typeClient/' + pk + '/' :
        apiUrl + 'typeClient/'
    },
    getSubTypeClient: (state) => (pk) => {
        return pk != undefined ?
          apiUrl + 'subTypeClient/' + pk + '/' :
          apiUrl + 'subTypeClient/'
      },

    //FeasibilityReport
    getFeasibilities: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'feasibilityReport/' + pk + '/' :
        apiUrl + 'feasibilityReport/'
    },
    getFeasibilitiesReport: (state) => (mode) => {
      return apiUrl + 'feasibilityReports/'+mode+'/'
    },
    getFeasibilitiesStatus: (state) => (pk) => {
      return apiUrl + 'feasibilityReport/status/' + pk + '/'
    },
    setFeasibilitiesStatus: (state) => (pk) => {
      return apiUrl + 'feasibilityReport/changestatus/' + pk + '/'
    },
    setInfeasibleService: (state) => (pk) => {
      return apiUrl + 'feasibilityReport/infeasibleservice/' + pk + '/'
    },

    // FeasibilityNetwork
    getFeasibilitiesNetwork: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'feasibilityNetwork/' + pk + '/' :
        apiUrl + 'feasibilityNetwork/'
    },
    getFeasibilitiesNetworkMode: (state) => (mode) => {
      return apiUrl + 'feasibilityNetworkReports/'+mode+'/'
    },
    getFeasibilitiesNetworkStatus: (state) => (pk) => {
      return apiUrl + 'feasibilityNetwork/status/' + pk + '/'
    },
    setFeasibilitiesNetworkStatus: (state) => (pk) => {
      return apiUrl + 'feasibilityNetwork/changestatus/' + pk + '/'
    },
    getInfeasibleNetworkService: (state) => (pk) => {
      return apiUrl + 'feasibilityNetwork/infeasibleservice/' + pk + '/'
    },

    // Inspection Routes
    getInspections: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'inspection/' + pk + '/' :
        apiUrl + 'inspection/'
    },

    // Acceptance Routes
    getAcceptanceLetter: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'acceptanceLetter/' + pk + '/' :
        apiUrl + 'acceptanceLetter/'
    },
    sendAcceptanceLetterPDF: (state) => (pk) => {
      return apiUrl + 'acceptanceLetter/send/'+ pk +'/'
    },
    getAcceptanceLetterStatus: (state) => (pk) => {
      return apiUrl + 'acceptanceLetter/status/' + pk + '/'
    },
    getAcceptanceLetterInfeasibleService: (state) => (pk) => {
      return apiUrl + 'acceptanceLetter/infeasibleservice/' + pk + '/'
    },

    // Franchises Routes
    getFranchises: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'franchise/' + pk + '/' :
        apiUrl + 'franchise/'
    },

    activateFranchise: (state) => (pk) => {
      return apiUrl + 'franchise/activate/' + pk
    },

    configurateFranchise: (state) => (pk) => {
      return apiUrl + 'franchise/configuration/' + pk
    },

    // Notifications Routes
    getNotification: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'notification/' + pk + '/' :
        apiUrl + 'notification/'
    },
    markAsReadNotification: (state) => (pk) => {
      return apiUrl + 'notification/' + pk + '/mark-as-read'
    },
    getNotifications: (state) => {
      return apiUrl + 'notification/total'
    },
    broadcastNotification: (state) => {
      return apiUrl + 'notification/broadcast'
    },

    // Concepts Routes
    getConcepts: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'concept/' + pk + '/' :
        apiUrl + 'concept/'
    },

    getConceptMotives: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'concept_motive/' + pk + '/' :
        apiUrl + 'concept_motive/'
    },

    getConceptTypes: (state) => {
      return apiUrl + 'concept_types'
    },

    // Client Document Routes
    getDocumentControl:(state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'client_document_control/' + pk + '/' :
        apiUrl + 'client_document_control/';
    },
    getDocumentControlOverrided:(state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'voided_number/' + pk + '/' :
        apiUrl + 'voided_number/';
    },
    getDocumentPDF: (state) => (pk) => {
        return apiUrl + 'client_document/pdf/'+ pk + '/'
    },
    getDocumentBuild:(state) => {
      return apiUrl + 'client_document/build'
    },
    getDocumentBuildFranchise:(state) => {
      return apiUrl + 'client_document/franchise/build'
    },
    getDocumentControlTypes: (state) => {
      return apiUrl + 'client_document_types';
    },
    getClientDocumentHeader: (state) => {
      return apiUrl + 'client_document_header';
    },
    getClientDocumentHeaderByContract: (state) => {
      return apiUrl + 'client_document_header/invoice';
    },
    getClientDocumentContract: (state) => {
      return apiUrl + 'client_document_contract';
    },
    getClientDocumentStatus: (state) => {
      return apiUrl + 'client_document_status';
    },
    getFirstClientDocumentWithTaxBillRate: (state) => {
      return apiUrl + 'client_document/first-tax-bill-rate';
    },
    getClientDocument: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'client_document/' + pk + '/' :
        apiUrl + 'client_document/'
    },
    sendClientDocumentEmail: (state) => (pk) => {
      return apiUrl + 'client_document/send/'+ pk +'/'
    },
    getClientDocumentContracts: (state) => {
      return apiUrl + 'client_document/contracts/invoiceables';
    },
    getClientDocumentReplicate: (state) => (pk) => {
      return apiUrl + 'client_document_create/document/'+pk+'/';
    },

    // Materials and Equipment
    getMaterialsEquipment:(state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'equipment_materials/' + pk + '/' :
        apiUrl + 'equipment_materials/';
    },

    // Taxes
    getTaxes: (state) => {
      return apiUrl + 'catalog/category/tax/';
    },
    getTaxValues: (state) => (category) => {
      return apiUrl + 'catalog/category/'+category+'/';
    },
    getQueries: (state) => {
      return apiUrl + 'invoice_queries';
    },

    //Dashboard
    getChartFranchisePDF: (state) => {
      return apiUrl + 'dashboard/chartFranchisePDF';
    },
    getDataRequests: (state) => {
      return apiUrl + 'dashboard/getDataRequests/';
    },

    // Service Installation
    getServiceInstallation: (state) => (pk) => {
      return pk != undefined ?
        apiUrl + 'service-installation/' + pk + '/' :
        apiUrl + 'service-installation/'
    },
    addServiceInstallationExtension: (state) => (pk) =>  {
      return apiUrl + 'service-installation/add-extension/' + pk + '/'
    },
    getServiceInstallationDeliveryCertificate: (state) => (pk) => {
      return apiUrl + 'service-installation/delivery-certificate/' + pk + '/'
    },
    getServiceInstallationAcceptanceLetter: (state) => (pk) => {
      return apiUrl + 'service-installation/acceptance-letter/' + pk + '/'
    },
    getServiceInstallationActivationPending: (state) => {
      return apiUrl + 'service-installation/activation-pending/'
    },
    // Filter Installations per Time Period - Totals
    filterInstallationsPerDate: (state) => {
      return apiUrl + 'service-installation/filter-totals/'
    },

    // Payemnts
    getPayments:(state) => (pk)=>{
      return pk != undefined ?
        apiUrl + 'proof_payment/' + pk + '/' :
        apiUrl + 'proof_payment/'
    },
    generateInstallOrder:(state) => (company_id, contract_id)=>{
      return apiUrl + 'proof_payment/generate/' + company_id + '/' + contract_id + '/'
    },

    // Employee
    getEmployees:(state) => (pk)=>{
      return pk != undefined ?
        apiUrl + 'employee/' + pk + '/' :
        apiUrl + 'employee/'
    },
    getEmployeesTypes: (state) => {
      return apiUrl + 'employee_types';
    },
    getEmployeesContracts: (state) => (url)=>{
      return apiUrl + 'employee/contracts/'+url;
    },
    changeExternalCollector: (state) => (pk) => {
      return apiUrl + 'employee/external-collector/' + pk;
    },
    // Reports
    // Sales Book Report
    getSalesBookReport: (state) =>{
      return apiUrl + 'reports/sales-book';
    },
    // Void List Report
    getVoidListReport: (state) =>{
      return apiUrl + 'reports/void-list';
    },
    // Billing Detail By Concept
    getBillingDetailByConceptReport: (state) =>{
      return apiUrl + 'reports/billing-detail-by-concept';
    },
    // Daily income
    getDailyIncomeReport: (state) => {
      return apiUrl + 'reports/daily-income';
    },
    // Income by date Report
    getIncomeByDateReport: (state) =>{
      return apiUrl + 'reports/income-by-date';
    },
    // Income by date Report
    getIncomeByCollectorReport: (state) =>{
      return apiUrl + 'reports/income-by-collector';
    },
    // Income by date Report
    getIncomeByConceptReport: (state) =>{
      return apiUrl + 'reports/income-by-concept';
    },
    // Income by sector Report
    getIncomeBySectorReport: (state) =>{
      return apiUrl + 'reports/income-by-sector';
    },
    // Daily Cash Register Report
    getDailyCashRegister: (state) =>{
      return apiUrl + 'reports/daily-cash-register';
    },
    // Daily Cash Register Report
    getDailyCashRegisterExternal: (state) =>{
      return apiUrl + 'reports/daily-cash-register-external';
    },
    // Income by Concept Group BY Report
    getIncomeByConceptGroupByReport: (state) =>{
      return apiUrl + 'reports/income-by-concept-group-by';
    },
    // Collection List
    getCollectionList: (state) =>{
      return apiUrl + 'reports/collection-list';
    },
    // Money Received
    getMoneyReceived:(state) => (pk)=>{
      return pk != undefined ?
        apiUrl + 'money-received/' + pk + '/' :
        apiUrl + 'money-received/'
    },
    changeMoneyReceivedStatus: (state) => (pk) => {
      return apiUrl + 'money-received/' + pk + '/change-status'
    },
  }
}
