import { es } from './es.js'
import { en } from './en.js'

import en_validation from 'vee-validate/dist/locale/en';
import es_validation from 'vee-validate/dist/locale/es';

// messages
es.validation.messages = Object.assign(
  es.validation.messages,
  es_validation.messages
);
en.validation.messages = Object.assign(
  en.validation.messages,
  en_validation.messages
);

// attributes
es.validation.attributes = Object.assign(
  es.validation.attributes,
  es_validation.attributes
);
en.validation.attributes = Object.assign(
  en.validation.attributes,
  en_validation.attributes
);


export const translations = {
  'es': es,
  'en': en
}
