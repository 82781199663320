//
export default {
  name: "DialogOverride",
  components:{
  },
  data (){
    return {
      loading_override:false,
      dialog_override:false,
      type_doc:'',
      doc_control:{},
      type_override:'all',
      initial_control_number:'',
      final_control_number:'',
      modeNode:process.env.NODE_ENV,
    };
  },
  computed: {
    computeType(){
      return this.$t(this.type_doc);
    },
    computeText(){
      return this.$t('base.confirm_delete',{
        action: this.$t('base.override') + ' '+
          this.$t('betw',{
            start: this.doc_control.initial_control_number,
            end: this.doc_control.final_control_number,
          }) +
          ' '+ this.$t('of'),
        record: this.$t(this.type_doc),
      });
    }
  },
  methods: {
    open(item){
      this.dialog_override = true;
      // eslint-disable-next-line no-console
      // console.log(item);
      this.type_doc = 'document_control.types.'+item.type;
      this.doc_control = item;
      this.initial_control_number = item.initial_control_number;
      this.final_control_number = item.final_control_number;
    },
    close_override(){
      this.dialog_override = false;
      this.initial_control_number = '';
      this.final_control_number = '';
      this.type_override = 'all';
      this.type_doc = '';
      this.doc_control = {
        description:'',
      };
      this.$refs.form_override.reset();
    },
    save_override(){
      axios.delete( this.$store.getters.getDocumentControl(this.doc_control.id))
      .then(()=>{
        this.$emit('updateList');
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifText: 'success',
          notifPos: 2,
          extra: {
            model: this.$t(this.type_doc),
            action: this.$tc('overrided',1)
          }
        });
        this.close_override();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
    save_override_Rang(){
      this.$refs.form_override.validate()
      .then((success) =>{
        if (success){
          // eslint-disable-next-line no-console
          // console.log(this.initial_control_number, this.final_control_number);
          // axios.post( this.$store.getters.getDocumentControl(this.doc_control.id),{
          axios.post( this.$store.getters.getDocumentControlOverrided(),{
            id:this.doc_control.id,
            serial_control:this.doc_control.serial_control,
            initial_control_number:this.initial_control_number,
            final_control_number: this.type_override=='rang' ? this.final_control_number : '',
            description:this.doc_control.description,
            type:this.doc_control.type,
          })
          .then(response=>{
            if (response.status == 200) {
              // eslint-disable-next-line no-console
              // console.log(response);
              this.$emit('updateList');
              this.$store.dispatch('pushNotification', {
                snackbar:true,
                style: 'success',
                timeout: 6000,
                notifText: 'success',
                notifPos: 2,
                extra: {
                  model: this.$t(this.type_doc),
                  action: this.$tc('overrided',1)
                }
              });
            }
          }).catch(error=>{
            if (error.response) {
              // eslint-disable-next-line no-console
              // console.log(error.response.data);
            }
          }).finally(()=>{
            this.close_override();
          });
        }
      });
    },
    OnlyNumbers(e){
      // 'left arrow', 'up arrow', 'right arrow', 'down arrow',
      const arrowsKeyCodes = [37, 38, 39, 40];
      // 'delete', 'Tab','supr', 'enter', 'end', 'home'
      const SpecialKeyCodes = [8, 9, 46, 13, 35, 36];
      const NumberCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const keyBoardCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
      var keyCode = e.keyCode ? e.keyCode : e.which;
      // var key = e.key;
      // var bad = /^[0-9]*$/i;
      // eslint-disable-next-line no-console
      let arr =[...arrowsKeyCodes,...SpecialKeyCodes,...NumberCodes,...keyBoardCodes];
      if (arr.indexOf(keyCode) < 0) {
        e.preventDefault();
      }
    },
  },
};
