export default {
  name: "AddEditFormNational",
  data (){
    return {
      loading: false,
      loadingSelPrefix: false,
      prefixes: [],
      prefix: null,
      loadingFranchise: false,
      franchises: [],
      date_menu: false,
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      empty_account: {
        'name': '',
        'description': {
          printable: false
        }
      },
      items: [
        {
          'text': this.$t('banks.account'),
          'value': 'account'
        },
        {
          'text': this.$t('banks.mobile'),
          'value': 'mobile'
        },
        {
          'text': this.$t('both'),
          'value': 'both'
        }
      ],
      bank_account: {},
      selected_bank: {
        'name': '',
        'description': {}
      },
      account_types: [
        {
          'value': 'savings'
        },
        {
          'value': 'current'
        },
      ],
      editing: false,
      max_length: null,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    this.clear()
  },
  computed:{
    computedItems () {
      return Object.values(this.items)
    },
    computedDateFormatted () {
      return this.formatDate(this.bank_account.invoice_date)
    },
    computedAmount: {
      get () {
        let amount = 0
        if (this.bank_account && this.bank_account.invoice_amount) {
          amount = this.bank_account.invoice_amount
        }
        return parseFloat(amount)
      },
      set (newVal) {
        this.bank_account.invoice_amount = newVal
      }
    }
  },
  methods:{
    clear () {
      this.bank_account = Object.assign({}, this.empty_account)
    },
    clear2 () {
      this.bank_account = {
        'name': this.bank_account.name,
        'description': {
          printable: false
        }
      }
      this.$validator.reset()
      this.loadPrefixes()
      this.getFranchises()
    },
    prefixChange (e, dni) {
      this.bank_account.description.account_holder_dni = dni
      let maxlength = 0
      switch (e) {
        case 'V':
        case 'E':
          maxlength = 8
          break
        case 'J':
        case 'G':
          maxlength = 9
          break
      }
      setTimeout(() => {
        this.max_length = maxlength
        if (!dni) {
          this.$refs.dni_field.focus()
        }
      }, 100)
    },
    save () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$emit('loadingBtn', true)

          let clone = JSON.parse(JSON.stringify(this.bank_account))

          if (clone.description.account_number) {
            clone.description.account_number =
              this.selected_bank.description.value + clone.description.account_number
          }

          clone.parent_id = this.selected_bank.id
          clone.prefix = this.prefix

          if (!this.editing) {// Store (POST)
            axios.post(this.$store.getters.getBankAccounts(), clone)
              .then(response =>{
                if (response && response.data && response.data.success) {
                  this.formSuccess()
                }
              })
              .catch((err)=>{
                // eslint-disable-next-line no-console
                // console.log(err)
                this.$emit('loadingBtn', false)
              })
          } else {// Update (PUT)
            axios.put(this.$store.getters.getBankAccounts(clone.id), clone)
              .then(response =>{
                if (response.status == 200) {
                  this.formSuccess()
                } else {
                  this.$emit('loadingBtn', false)
                }
              })
              .catch((err)=>{
                // eslint-disable-next-line no-console
                console.log(err)
                this.$emit('loadingBtn', false)
              })
          }
        }
      })
    },
    formSuccess () {
      this.clear()
      this.$validator.reset()
      this.$emit('loadingBtn', false)
      this.$emit('closeDialog', '', true)
      let action = this.editing ? this.$tc('updated',2) : this.$tc('created',2)
      this.$awn.success(
        this.$tc('success', 2,
          {
            model: this.$tc('banks.bank_account',1),
            action: action
          }
        )
      , {durations:{alert: 4000}})
    },
    loadPrefixes () {
      this.loadingSelPrefix = true
      axios.get(this.$store.getters.getPrefixes)
        .then(response => {
          this.loadingSelPrefix = false
          this.prefixes = response.data.prefixes
        })
        .catch(err => {
          this.prefixes = []
        })
        .finally(() => {
          this.loadingSelPrefix = false
        })
    },
    getFranchises(){
      this.loadingFranchise = true;
      axios.get(this.$store.getters.getFranchises()
      ).then((response)=>{
        if (response.data.franchises.data) {
          let list_franchises = []
          response.data.franchises.data.forEach(element => {
            list_franchises.push({
              id: element.id,
              name: element.full_name,
            })
          })
          this.franchises =  list_franchises
        }
      })
      .catch((err)=>{
        //
      })
      .finally(()=>{
        this.loadingFranchise = false
      })
    }
  },
  watch: {
    editing (val) {
      if (val) {
        this.loadPrefixes()
        this.getFranchises()

        this.selected_bank = this.bank_account.bank
        if (this.bank_account.description.account_number) {
          this.bank_account.description.account_number = this.bank_account.description.account_number.substring(4,20)
        }

        this.prefix = this.bank_account.description.account_holder_dni.substring(0,1)
        let dni = this.bank_account.description.account_holder_dni.substring(1,10)
        if (this.prefix == 'V' || this.prefix == 'E') {
          dni = Number(dni)
        }
        this.prefixChange(this.prefix, dni)
      }
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
