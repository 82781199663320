<template>
  <v-dialog
    v-model="details_dialog"
    persistent
    scrollable
    fullscreen
    hide-overlay
    no-click-animation
    >
    <v-card tile>
        <v-toolbar dense tile
          max-height="48"
          class="requests-modal-toolbar elevation-4"
          style="z-index: 1;"
        >
          <v-toolbar-title>
            <span class="subtitle-1 font-weight-bold py-1">
              <span>
                {{$tc('inspection.inspection',2)}}
              </span>
            </span>
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-toolbar-items>
            <v-btn text @click="details_dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row :style="{ 
              'position': !$vuetify.breakpoint.smAndDown ? 'relative': ''
            }">
              <v-col cols="12" md="7" class="py-0">
                <v-card outlined
                  class="elevation-0 step-inner mx-auto card-outlined"
                  min-height="271"
                >
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$tc('inspection.inspection',2)}}
                        {{ 
                          inspection.status != undefined && 
                          inspection.status != 'active' ? 
                            $t(`feasibility.${inspection.status}`) : ''
                        }}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pa-0">
                      <v-row class="pt-2">
                        <v-col cols="12" sm="7" md="6" lg="8" class="py-0 pr-4">
                          <v-text-field
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="inspection.elaborated_by"
                            v-validate="'required'"
                            name="feasibility.elaboration_by"
                            :error-messages="errors.collect('feasibility.elaboration_by')"
                            :label="$t('feasibility.elaboration_by')"
                            :data-vv-as="$t('feasibility.elaboration_by')"
                            prepend-icon="fas fa-user-tie"
                            class="px-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="10" sm="5" md="6" lg="4" class="py-0 pr-4">
                          <v-menu
                            v-model="date_menu_elaboration"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :disabled="tab == 'tab-3' || !edit"
                                v-model="computedElaborationDateFormatted"
                                v-validate="'required'"
                                name="feasibility.elaboration_date"
                                :error-messages="errors.collect('feasibility.elaboration_date')"
                                :label="$t('feasibility.elaboration_date')"
                                :data-vv-as="$t('feasibility.elaboration_date')"
                                prepend-icon="fas fa-calendar"
                                readonly
                                persistent-hint
                                :hint="$store.getters.getDateFormat[0]"
                                v-on="on"
                                class="px-0"
                              ></v-text-field>
                            </template>
                            <v-date-picker ref="picker"
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="inspection.elaboration_date"
                              :min="request_date ? request_date : '1920-01-01'"
                              :max="today"
                              @input="date_menu_elaboration = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="pb-0 pr-4">
                          <v-textarea auto-grow
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="inspection.observations"
                            name="feasibility_report.observation"
                            :label="$t('subscribers.observations')"
                            prepend-icon="fas fa-clipboard"
                            class="pb-0 px-0 text-center"
                            row-height="26"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" class="py-0" :class="{'order-first': $vuetify.breakpoint.smAndDown }">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$tc('requests.requests',1)}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <filepreview
                          v-if="loaded_file"
                          :file="file"
                          class="mx-auto"
                        ></filepreview>
                        <v-skeleton-loader
                          v-else
                          class="preview-skeleton my-3 mx-auto"
                          width="156"
                          height="224"
                          type="card-avatar"
                        ></v-skeleton-loader>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn 
            color="success"
            v-if="tab == 'tab-2' && !edit"
            @click="feasible('feasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.feasible')}}
            </span>
            <v-icon small>fas fa-clipboard-check</v-icon>
          </v-btn>
          <v-btn 
            color="error"
            v-if="tab == 'tab-2' && !edit"
            class="ml-4 mr-12"
            @click="feasible('infeasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.infeasible')}}
            </span>
            <v-icon small>fas fa-file-excel</v-icon>
          </v-btn>
          <v-btn v-if="edit && tab != 'tab-1'" color="info" text @click="editMode(false)">
            <v-icon small>fa fa-arrow-left</v-icon>
            <span v-show="$vuetify.breakpoint.mdAndUp" 
              class="ml-3">
              {{$t('back')}}
            </span>
          </v-btn>
          <v-btn 
            color="info"
            v-if="tab != 'tab-3'"
            @click="edit?save():editMode(true)"
            :loading="loading"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t(`base.${edit ? 'save' : 'edit'}`)}}
            </span>
            <v-icon small v-text="`fas fa-${edit ? 'save' : 'edit'}`"></v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<style type="text/css">
  .alt-img-label {
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
  }
</style>

<script>
  export default {
  name: 'InspectionDialog',
  data() {
    return {
      unwatch: '',
      loading: false,
      details_dialog: false,
      date_menu_elaboration: false,
      date_menu_inspection: false,
      tab: '',
      inspection: {
        id: 0,
        contract_id: null,
        elaborated_by: '',
        elaboration_date: '',
        observations: '',
        status: ''
      },
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      request_date: null,
      file: {},
      edit: true,
      loaded_file: false
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if (val) {
        this.loading = false
        this.details_dialog = false
        if (this.$store.getters.getConfirmationTemplate == 'infeasible') {
          this.$emit('created')
        }
        if (this.$store.getters.getConfirmationTemplate == 'feasible') {
          if (this.getPermissionGroup({
            permission:['invoiceQueries_clientDocument', 'getContractsByParameters_clientDocument'],
            mode:'all'
          })) {
            this.$router.push({ name: 'RequestBillsFranchise' })
          } else {
            this.$emit('created')
          }
        }
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  computed:{
    computedElaborationDateFormatted () {
      return this.formatDate(this.inspection.elaboration_date)
    }
  },
  methods:{
    editMode(val){
      this.edit = val
      if (!val) {
        this.$validator.reset()
      }
    },
    feasible(mode){
      let url, msg, title, action, type
      url = this.$store.getters.getInspections(this.inspection.id)
      if (mode == 'feasible') {
        msg = this.$t('inspection.confirm_feasible')
        title = this.$t('base.approve')
        action = this.$tc('approved',2)
        type = 'success'
      } else {
        msg = this.$t('inspection.confirm_infeasible')
        title = this.$t('base.deny')
        action = this.$tc('denied',2)
        type = 'error'
      }

      this.$store.dispatch('confirmationValue',{
        template: mode,
        show: true,
        model: this.$tc('inspection.inspection',2),
        title: title,
        action: action,
        content: msg,
        type: type,
        confirm:{
          url: url,
          method: 'patch',
          data: {
            'status': mode
          }
        },
        pos: 2
      })
    },
    open(contract, tab){
      if (contract.created_at) {
        this.request_date = new Date(contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      if (contract.contract && contract.contract.created_at) {
        this.request_date = new Date(contract.contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      this.tab = tab
      if(this.tab == 'tab-1'){
        this.inspection = {
          id: contract.inspection.id,
          contract_id: contract.id,
          elaborated_by: '',
          elaboration_date: '',
          observations: '',
          status: 'active'
        }
        this.edit = true
      }
      else{
        this.inspection = {
          id: contract.inspection.id,
          contract_id: contract.id,
          elaborated_by: contract.inspection.elaborated_by,
          elaboration_date: contract.inspection.elaboration_date,
          observations: contract.inspection.observations,
          status: contract.inspection.status
        }
      }
      this.$validator.reset()
      this.tab != 'tab-1' ? this.edit = false : ''
      this.loadPDF()
      this.details_dialog = true
    },
    save() {
      this.$validator.validateAll().then((valid)=>{
        if(valid){
          this.loading = true
          axios.patch(this.$store.getters.getInspections(this.inspection.id), this.inspection)
            .then(response => {
              if (response.status == 200) {
                this.$store.dispatch('pushNotification', {
                  snackbar:true,
                  style: 'success',
                  timeout: 6000,
                  notifText: 'success',
                  notifPos: 2,
                  extra: {
                    model: this.$tc('inspection.inspection',2),
                    action: this.tab == 'tab-1' ? this.$tc('created', 2) :
                      this.$tc('updated',2) 
                  }
                })
                if(this.tab == 'tab-1'){
                  this.details_dialog = false
                }
                else{
                  this.edit = false
                }
                this.$emit('created')
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    loadPDF () {
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
              'contract_id': this.inspection.contract_id,
              'name': 'inspection_order'
          }
        })
        .then(response => {
          if (response.data.files) {
            if (response.data.files.data.length > 0) {
              this.file = response.data.files.data[0]
              this.loaded_file = true
            } else {
              this.file.file_name = 'inspection_order'
              this.loaded_file = true
            }
          }
        })
        .catch(err => {
          this.file = {}
        })
        .finally(()=>{
          //
        })
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    }
  },
  watch:{
    details_dialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        setTimeout(() => {
          this.file = {}
          this.loaded_file = false
          this.request_date = null
        }, 200)
      }
    }
  }
};
</script>
