<template>
  <v-container fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="py-4">
          <v-card-text class="text-center pt-2">
            <v-flex xs12>
              <i class="fas fa-envelope fa-6x grey--text"></i>
            </v-flex>
            <v-layout row wrap align-top justify-center mt-4 mb-2 px-1
              :class="{'pr-4': this.$vuetify.breakpoint.smOnly, 'pr-5': this.$vuetify.breakpoint.mdAndUp}"
            >
              <v-flex xs12 sm2 pt-2 :class="{'mb-3': this.$vuetify.breakpoint.xsOnly}">
                <v-icon medium color="warning">fas fa-exclamation-triangle</v-icon>
              </v-flex>
              <v-flex xs12 sm10>
                <p class="text-xs-justify subheading" v-html="$t('emailverify.unverified')"></p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center py-3 pb-4">
            <v-btn dark
              :loading="loading"
              C:disabled="loading"
              color="warning lighten-1"
              @click="resendEmail"
            >
              <v-icon class="mr-2">fas fa-undo-alt</v-icon>
              {{ $t("emailverify.resend") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script type="text/javascript">
export default {
  name: 'email-resend',
  data () {
    return {
      loading: false
    }
  },
  beforeCreate() {
    if (this.$store.getters.isVerified === null) {
      axios.get(this.$store.getters.checkEmailUrl(localStorage.getItem('id')))
        .then((response) => {
          this.$store.dispatch('setVerifiedEmail', true)
          this.$router.push({ name: 'dashboard' })
        }).catch(() => {
          this.$store.dispatch('setVerifiedEmail', false)
        })
    }
  },
  methods: {
    resendEmail(){
      this.loading = true
      this.$store.dispatch('resendEmailVerif', '')
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.$store.dispatch('pushNotification', {
              snackbar:true,
              style:'success',
              mode:null,
              timeout:4000,
              notifText:response.data.success,
              extra:null
            })
            if (response.data.already) {
              this.$store.dispatch('setVerifiedEmail', true)
              this.$router.push({ name: 'dashboard' })
            }
          }
        }).catch((error) => {
          //
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
