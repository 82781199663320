<template>
  <v-dialog
    v-model="details_dialog"
    persistent
    scrollable
    fullscreen
    hide-overlay
    no-click-animation
    >
    <v-card tile>
        <v-toolbar dense tile
          max-height="48"
          class="requests-modal-toolbar elevation-4"
          style="z-index: 1;"
        >
          <v-toolbar-title>
            <span class="subtitle-1 font-weight-bold py-1">
              <span>
                {{$t('feasibilityReport.fibra')}}
              </span>
            </span>
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-toolbar-items>
            <v-btn text @click="details_dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row :style="{ 
              'position': !$vuetify.breakpoint.smAndDown ? 'relative': ''
            }">
              <v-col cols="12" md="7" class="py-0">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$t('feasibilityReport.report')}}
                        {{ 
                          feasibility_report.status != undefined && 
                          feasibility_report.status != 'active' ? 
                            $t(`feasibility.${feasibility_report.status}`) : ''
                        }}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <v-col cols="12" sm="7" md="7" lg="5" class="py-1 pl-0 order-1">
                          <v-text-field
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report.version"
                            v-validate="'required|max:190'"
                            name="feasibility_report.version"
                            :error-messages="errors.collect('feasibility_report.version')"
                            :label="$t('feasibility.version')"
                            :data-vv-as="$t('feasibility.version')"
                            prepend-icon="fas fa-code-branch"
                            class="px-0"
                            counter
                            maxlength="190"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="7" md="6" lg="8" class="py-1 pl-0 order-2">
                          <v-text-field
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report.elaboration_by"
                            v-validate="'required|max:190'"
                            name="feasibility.elaboration_by"
                            :error-messages="errors.collect('feasibility.elaboration_by')"
                            :label="$t('feasibility.elaboration_by')"
                            :data-vv-as="$t('feasibility.elaboration_by')"
                            prepend-icon="fas fa-user-tie"
                            class="px-0"
                            counter
                            maxlength="190"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5" md="6" lg="4" class="py-1 pl-0 order-3">
                          <v-menu
                            v-model="date_menu_elaboration"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                          >
                           <!--  class="col order-last" -->
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :disabled="tab == 'tab-3' || !edit"
                                v-model="computedElaborationDateFormatted"
                                v-validate="'required'"
                                name="feasibility.elaboration_date"
                                :error-messages="errors.collect('feasibility.elaboration_date')"
                                :label="$t('feasibility.elaboration_date')"
                                :data-vv-as="$t('feasibility.elaboration_date')"
                                prepend-icon="fas fa-calendar"
                                readonly
                                persistent-hint
                                :hint="$store.getters.getDateFormat[0]"
                                v-on="on"
                                class="px-0"
                              ></v-text-field>
                            </template>
                            <v-date-picker ref="picker"
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="feasibility_report.elaboration_date"
                              :min="request_date ? request_date : '1920-01-01'"
                              :max="today"
                              @input="date_menu_elaboration = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="7" md="6" lg="8" class="py-1 pl-0 order-4">
                          <v-text-field
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report.inspection_staff"
                            v-validate="'required|max:190'"
                            name="feasibility.inspection_staff"
                            :error-messages="errors.collect('feasibility.inspection_staff')"
                            :label="$t('feasibility.inspection_staff')"
                            :data-vv-as="$t('feasibility.inspection_staff')"
                            prepend-icon="fa fa-id-badge"
                            class="px-0"
                            counter
                            maxlength="190"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5" md="6" lg="4" class="py-1 pl-0 order-5">
                          <v-menu
                            v-model="date_menu_inspection"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :disabled="tab == 'tab-3' || !edit"
                                v-model="computedInspectionDateFormatted"
                                v-validate="'required'"
                                name="feasibility_report.inspection_date"
                                :error-messages="errors.collect('feasibility_report.inspection_date')"
                                :label="$t('feasibility.inspection_date')"
                                :data-vv-as="$t('feasibility.inspection_date')"
                                prepend-icon="fas fa-calendar"
                                readonly
                                persistent-hint
                                :hint="$store.getters.getDateFormat[0]"
                                v-on="on"
                                class="px-0"
                              ></v-text-field>
                            </template>
                            <v-date-picker ref="picker"
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="feasibility_report.inspection_date"
                              :min="request_date ? request_date : '1920-01-01'"
                              :max="today"
                              @input="date_menu_inspection = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="py-1 pl-0 order-6">
                          <v-textarea auto-grow
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report.objective"
                            v-validate="'required'"
                            name="feasibility.objective"
                            :error-messages="errors.collect('feasibility.objective')"
                            :label="$t('feasibility.objective')"
                            :data-vv-as="$t('feasibility.objective')"
                            prepend-icon="fas fa-check-double"
                            rows="2"
                            class="px-0"
                            counter
                            maxlength="300"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="py-1 pl-0 order-7">
                          <v-textarea auto-grow
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report.observation"
                            v-validate="'required'"
                            name="feasibility_report.observation"
                            :error-messages="errors.collect('feasibility_report.observation')"
                            :label="$t('feasibility.observation')"
                            :data-vv-as="$t('feasibility.observation')"
                            prepend-icon="fas fa-clipboard"
                            rows="3"
                            class="px-0 text-center"
                            counter
                            maxlength="500"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="py-1 pl-0 order-8">
                          <v-file-input
                            v-if="tab != 'tab-3'"
                            accept="image/*"
                            v-validate="'image'"
                            :data-vv-as="feasibility_report.image" 
                            :label="$t('feasibilityReport.image')"
                            :name="$t('feasibilityReport.image')"
                            :error-messages="errors.collect('feasibilityReport.image')"
                            :disabled="tab == 'tab-3' || !edit"
                            data-vv-scope="feasibility_report"
                            ref="imgF"
                            class="px-0"
                            @change="loadImage"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12" md="12" class="row pt-0 pb-2 order-last">
                          <p v-if="tab == 'tab-3' && feasibility_report.image"
                            class="alt-img-label mb-2 pl-8"
                          >
                            {{ $t('feasibilityReport.image') }}
                          </p>
                          <img
                            :src="av_image == '' ? feasibility_report.image : av_image"
                            class="mx-auto py-0"
                            width="80%"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" class="py-0" :class="{'order-first': $vuetify.breakpoint.smAndDown }">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$tc('requests.requests',1)}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <filepreview
                          v-if="loaded_file"
                          :file="file"
                          class="mx-auto"
                        ></filepreview>
                        <v-skeleton-loader
                          v-else
                          class="preview-skeleton my-3 mx-auto"
                          width="156"
                          height="224"
                          type="card-avatar"
                        ></v-skeleton-loader>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" offset-md="7" class="pt-0 pb-6" :style="{'position': !$vuetify.breakpoint.smAndDown ? 'absolute': '', 'top': '20rem'}">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined" :height="computedHeight1">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$t('feasibilityReport.work_to_realize')}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <v-btn block text color="primary" 
                            v-if="tab != 'tab-3' && edit"
                            dark @click="addWork(j)" class="pr-0"> 
                              <v-icon class="ml-auto mr-2">
                                fas fa-plus
                              </v-icon>
                          </v-btn>
                          <template v-for="(item,j) in feasibility_report.works">
                            <v-textarea auto-grow
                              :key="j"
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="item.description"
                              v-validate="'required'"
                              :name="`feasibility_report.description-${j}`"
                              :error-messages="errors.collect(`feasibility_report.description-${j}`)"
                              :label="$t('feasibilityReport.description')"
                              :data-vv-as="$t('feasibilityReport.description')"
                              prepend-icon="fas fa-clipboard"
                              :append-icon="tab != 'tab-3' && edit ? 'fas fa-times' : ''"
                              @click:append="removeWork(j,item.id)" 
                              v-show="j || ( !j && feasibility_report.works.length > 0)"
                              rows="2"
                              class="ml-1 mr-3"
                            ></v-textarea>
                            <v-divider :key="j+'a'"
                              v-if="Number(j)+1 < feasibility_report.works.length"
                              class="primary lighten-5 mb-4"
                            ></v-divider>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined" :height="computedHeight2">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$t('feasibilityReport.descriptive_memory')}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <v-btn block text color="primary" 
                            v-if="tab != 'tab-3' && edit"
                            dark @click="addMemory(i)" class="pr-0"> 
                            <v-icon class="ml-auto mr-2">
                              fas fa-plus
                            </v-icon>
                          </v-btn>
                          <span v-for="(item,i) in feasibility_report.descriptives" :key="i">
                            <v-textarea auto-grow
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="item.title"
                              v-validate="'required'"
                              :name="`item.title-${i}`"
                              :error-messages="errors.collect(`item.title-${i}`)"
                              :label="$t('feasibilityReport.title_report')"
                              :data-vv-as="$t('feasibilityReport.title_report')"
                              prepend-icon="fas fa-clipboard"
                              :append-icon="tab != 'tab-3' && edit ? 'fas fa-times' : ''"
                              @click:append="removeMemory(i,item.id)" 
                              v-show="i || ( !i && feasibility_report.descriptives.length > 0)"
                              rows="1"
                              class="ml-1 mr-3"
                            ></v-textarea>
                            <v-textarea auto-grow
                              :disabled="tab == 'tab-3' || !edit"
                              v-model="item.description"
                              v-validate="'required'"
                              :name="`item.description--${i}`"
                              :error-messages="errors.collect(`item.description--${i}`)"
                              :label="$t('feasibilityReport.description')"
                              :data-vv-as="$t('feasibilityReport.description')"
                              prepend-icon="fas fa-clipboard"
                              rows="2"
                              class="ml-1 mr-3"
                            ></v-textarea>
                            <v-divider :key="i+'a'"
                              v-if="Number(i)+1<feasibility_report.descriptives.length"
                              class="primary lighten-5 mb-4"
                            ></v-divider>
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn 
            color="success"
            v-if="tab == 'tab-2' && !edit"
            @click="feasible('feasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.feasible')}}
            </span>
            <v-icon small>fas fa-clipboard-check</v-icon>
          </v-btn>
          <v-btn 
            color="error"
            v-if="tab == 'tab-2' && !edit"
            class="ml-4 mr-12"
            @click="feasible('infeasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.infeasible')}}
            </span>
            <v-icon small>fas fa-file-excel</v-icon>
          </v-btn>
          <v-btn v-if="edit && tab != 'tab-1'" color="info" text @click="editMode(false)">
            <v-icon small>fa fa-arrow-left</v-icon>
            <span v-show="$vuetify.breakpoint.mdAndUp" 
              class="ml-3">
              {{$t('back')}}
            </span>
          </v-btn>
          <v-btn 
            color="info"
            v-if="tab != 'tab-3'"
            @click="edit?save():editMode(true)"
            :loading="loading"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t(`base.${edit ? 'save' : 'edit'}`)}}
            </span>
            <v-icon small v-text="`fas fa-${edit ? 'save' : 'edit'}`"></v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<style type="text/css">
  .alt-img-label {
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
  }
</style>

<script>
  export default {
  name: "FeasinilityDialogForm",
  data() {
    return {
      //item: ''
      unwatch: '',
      loading: false,
      details_dialog: false,
      date_menu_elaboration: false,
      date_menu_inspection: false,
      i: '',
      j: '',
      tab: '',
      feasibility_report: {
        contract_id:null,
        version: '',
        elaboration_by: '',
        elaboration_date: '',
        inspection_staff: '',
        inspection_date: '',
        objective: '',
        observation: '',
        descriptives: [],
        works: [],
        image: null
      },
      status: '',
      av_image: '',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      request_date: null,
      file: {},
      edit: true,
      loaded_file: false
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if (val && this.$store.getters.getConfirmationTemplate == 'feasibilityDialog') {
        this.loading = false
        this.details_dialog = false
        this.$emit('created')
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  computed:{
    computedElaborationDateFormatted () {
      return this.formatDate(this.feasibility_report.elaboration_date)
    },
    computedInspectionDateFormatted () {
      return this.formatDate(this.feasibility_report.inspection_date)
    },
    computedHeight1 () {
      return !this.edit ? this.feasibility_report.works.length == 0 ? 59 : '' : ''
    },
    computedHeight2 () {
      return !this.edit ? this.feasibility_report.descriptives.length == 0 ? 59 : '' : ''
    }
  },
  methods:{
    editMode(val){
      this.edit = val
      if (!val) {
        this.$validator.reset()
      }
    },
    feasible(mode){
      let url, msg, title, action, type
      if (mode == 'feasible') {
        url = this.$store.getters.setFeasibilitiesStatus(this.feasibility_report.id)
        msg = this.$t('base.confirm_feasible')
        title = this.$t('base.approve')
        action = this.$tc('approved',2)
        type = 'success'
      } else {
        url = this.$store.getters.setInfeasibleService(this.feasibility_report.id)        
        msg = this.$t('base.confirm_infeasible')
        title = this.$t('base.deny')
        action = this.$tc('denied',2)
        type = 'error'
      }

      this.$store.dispatch('confirmationValue',{
        template: 'feasibilityDialog',
        show: true,
        model: this.$tc('feasibilityReport.feasibility',1),
        title: title,
        action: action,
        content: msg,
        type: type,
        confirm:{
          url: url,
          method: 'get'
        },
        pos: 2
      })
    },
    async open(contract, tab){
      if (contract.created_at) {
        this.request_date = new Date(contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      if (contract.contract && contract.contract.created_at) {
        this.request_date = new Date(contract.contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      this.tab = tab
      if(this.tab == "tab-1"){
        this.feasibility_report = {
          contract_id:contract.id,
          version: '',
          elaboration_by: '',
          elaboration_date: '',
          inspection_staff: '',
          inspection_date: '',
          objective: '',
          observation: '',
          descriptives: [],
          works: [],
          image: null
        }
        this.$validator.reset()
        this.edit = true
        this.av_image = ''
      }
      else{
        let response = await axios.get(this.$store.getters.getFeasibilities(contract.feasibility))
        if(response.status == "200"){
          this.feasibility_report = JSON.parse(JSON.stringify(response.data['feasibilityReport']))
          this.$set(this.feasibility_report,'descriptives',response.data['feasibilityDescriptiveMemory'])
          this.$set(this.feasibility_report,'works',response.data['feasibilityWorkToRealize'])
          this.av_image = this.feasibility_report.image_url
        }
      }
      if (Object.entries(this.file).length === 0 && this.file.constructor === Object) {
        this.loadPDF()
      }
      this.$refs['imgF'] ? this.$refs['imgF'].reset() : ''
      this.tab != "tab-1" ? this.edit = false : ''
      this.details_dialog = true
    },
    addMemory(index) {
      this.$validator.validateAll()
      .then(()=>{
        if(!this.errors.any()){
          this.feasibility_report.descriptives.push({ title: '', description: ''});
        }
      })
    },
    addWork(index) {
      this.$validator.validateAll()
      .then(()=>{
        if(!this.errors.any()){
          this.feasibility_report.works.push({ description: ''});
        }
      })
    },
    async removeMemory(index,item) {
      if(item != undefined){
        if(this.feasibility_report['removeDescriptives'] == null){
          this.feasibility_report['removeDescriptives'] = []
        }
        this.feasibility_report['removeDescriptives'].push(item)
      }
      this.feasibility_report.descriptives.splice(index, 1)
    },
    async removeWork(index,item) {
      if(item != undefined){
        if(this.feasibility_report['removeWorks'] == null){
          this.feasibility_report['removeWorks'] = []
        }
        this.feasibility_report['removeWorks'].push(item)
      }
      this.feasibility_report.works.splice(index, 1)
    },
    save() {
      this.$validator.validateAll().then((valid)=>{
        if(valid){
          this.loading = true
          let form_data = new FormData()  
          for (let key in this.feasibility_report) {
            if (key == 'image') {
              form_data.append('image', this.feasibility_report.image)
              if(key == 'image' && (this.feasibility_report.image == null || 
                String(this.av_image).indexOf('http') != -1)){
                  form_data.delete('image')
              }
            }
            else{
              if(key == 'works' || key == 'descriptives' || key == 'removeWorks' || key == 'removeDescriptives'){
                form_data.append(key,JSON.stringify(this.feasibility_report[key]))
              }
              else{
                form_data.append(key,this.feasibility_report[key])
              }
            }
          }
          

          if(this.feasibility_report.id != undefined){
            form_data.append('_method', 'PUT');
          }
          axios({
            url: this.$store.getters.getFeasibilities(this.feasibility_report.id),
            method: 'post',
            data: form_data
          })
          .then(response => {
           this.$store.dispatch('pushNotification', {
              snackbar:true,
              style: 'success',
              timeout: 6000,
              notifText: 'success',
              notifPos: 1,
              extra: {
                model: this.$t('feasibilityReport.fibra'),
                action: this.feasibility_report.id == undefined ? this.$tc('created', 1) :
                  this.$tc('updated',1) 
              }
            })
            if(this.feasibility_report.id == undefined){
              this.details_dialog = false
            }
            else{
              this.edit = false
            }
            this.$emit('created')
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    loadImage(e) {
      if (e && e.size > 0) {
        if (!['image/png','image/jpeg','image/jpg'].includes(e.type)) {
          this.dropImg()
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'error',
            timeout: 6000,
            notifText: 'error422.image_must_valid_image',
          })
        } else if (e.size > 2097152) {
          this.dropImg()
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'error',
            timeout: 6000,
            notifText: 'error422.image_max_2048_kb',
          })
        } else {
          const fr = new FileReader()
          fr.readAsDataURL(e)
          fr.addEventListener('load', () => {
            this.av_image = fr.result
            this.feasibility_report.image = e
          })
        }
      }         
    },
    dropImg () {
      this.av_image = ''
      this.feasibility_report.image = null
      this.$refs['imgF'].reset()
    },
    loadPDF () {
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
              'contract_id': this.feasibility_report.contract_id,
              'name': 'feasibility_order'
          }
        })
        .then(response => {
          if (response.data.files) {
            if (response.data.files.data.length > 0) {
              this.file = response.data.files.data[0]
              this.loaded_file = true
            } else {
              this.file.file_name = 'feasibility_order'
              this.loaded_file = true
            }
          }
        })
        .catch(err => {
          this.file = {}
        })
        .finally(()=>{
          //
        })
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    }
  },
  watch:{
    details_dialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        setTimeout(() => {
          this.file = {}
          this.loaded_file = false
          this.request_date = null
        }, 200)
      }
    }
  }
};
</script>
