
export default {
  components: {
    //
  },
  data() {
    return {
      base_url: 'https://dev.corpvnet.com/appatlas',
      policies: {
        xyz: false
      },
    }
  },
  methods: {
    embedPolicies() {
      this.policies.xyz = this.getPermissionGroup({
        permission: [''],
        mode: 'all'
      })
    }
  },
  watch: {
    //
  },
  computed: {
    //
  },
  created() {
    this.embedPolicies()
  },
  beforeDestroy() {
    //
  },
  mounted() {
    //
  }
};
