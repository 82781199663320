import ConceptDialog from "../Concept/ConceptDialog.vue";
import HeaderDoc from "./headerDoc.vue";
// import Payments       from './payments.vue';
// Functions return always object or null
const ReturnObject = value => {
  if (typeof value == "object") {
    return value;
  } else if (typeof value == "string" && value != "null") {
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "EditDocument",
  components: {
    ConceptDialog,
    HeaderDoc
    // Payments,
  },
  data() {
    return {
      its_banks: false,
      its_edit: true,
      dialog1: false,
      loading: false,
      sortDesc: true,
      fullscreen: false,
      its_preview: true,
      loadingTable: true,
      loadingExchangeRates: false,
      UT: 0,
      ISRL: 0,
      tax_rate: 0,
      tax_sale: 0,
      IVA: [],
      concepts: [],
      searchFor: {},
      subscriber: {},
      baseCurrency: {},
      ExchangeRates: {},
      exchange_rate: {},
      client_document: {},
      ItemsConcept: [
        { type: "credit" },
        { type: "credit" },
        { type: "credit" }
      ],
      ItemPago: [{}, {}, {}],
      symbol: "",
      decimal_places2: 2,
      editedIndex: "",
      date_start: "",
      date_end: "",
      min_day1: "",
      min_day2: "",
      max_day1: "",
      max_day2: "",
      num_month_1: [],
      num_month_2: [],
      sortBy: "updated_at",
      Permissions: {
        edit: false,
        create: false,
        delete: false
      },
      listID: [],
      idExchangeRate: ''
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("paymentNote.quantity"),
          value: "quantity",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("paymentNote.description"),
          value: "name",
          sortable: false
        },
        {
          text: this.$t("paymentNote.priceUnit"),
          value: "price",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("paymentNote.priceTotal"),
          value: "total",
          align: "right",
          sortable: false
        }
      ];
      if (
        !this.its_edit &&
        (this.Permissions.edit || this.Permissions.delete)
      ) {
        const title =
          this.Permissions.edit && this.Permissions.delete
            ? this.$t("actions")
            : this.$t("action");
        headers.push({
          text: title,
          value: "action",
          sortable: false,
          align: "right"
        });
      }
      return headers;
    },
    computeTypeDoc() {
      return this.$t("document_control.types." + this.client_document.type);
    },
    computeStatusDoc() {
      return this.$t("document_control.status." + this.client_document.status);
    },
    base_currency_name: {
      get() {
        return Object.getOwnPropertyNames(
          this.$store.getters.getBaseCurrency
        )[0];
      },
      set() {}
    },
    selectExchangeRates() {
      return this.ExchangeRates.sort((a, b) =>
        a.name === this.base_currency_name ? -1 : a.name > b.name ? 1 : -1
      );
    },
    computedDateFormatt() {
      let day = this.getFormatDate(this.today);
      this.client_document.issue_date = this.today;
      return day;
    }
  },
  methods: {
    // Dialog config
    open(item, its_preview) {
      this.$store.dispatch("DocQuerys").then(response => {
        // eslint-disable-next-line no-console
        // console.log(response);
        this.ISRL = response.ISRL;
        this.UT = response.UT;
        this.IVA = response.IVA;
        this.baseCurrency = response.baseCurrency;
        this.symbol = response.symbol;
        this.ExchangeRates = response.ExchangeRates;
        this.client_document = item.client_document;
        if (item.client_document.send_date == null) {
          this.its_edit = false;
        } else {
          this.its_edit = true;
        }
        item.client_document.concepts
          ? (this.concepts = item.client_document.concepts)
          : "";
        for (var i = 0; i < this.concepts.length; i++) {
          if (this.concepts[i].amount == 0) {
            this.emitNotification(
              "warning",
              6000,
              "error422.concept_amount_invalid"
            );
          }
        }
        this.subscriber = item.subscriber;
        this.its_preview = its_preview;
        this.date_start = this.client_document.start_date;
        this.date_end = this.client_document.end_date;
        let exchange_rate = ReturnObject(item.client_document.exchange_rate);
        // Si tiene Tasa de cambio con valor distinto de 1
        if (
          exchange_rate != null &&
          exchange_rate != undefined &&
          exchange_rate.rate != 1
        ) {
          exchange_rate.id = exchange_rate.id + "xx";
          exchange_rate.name =
            exchange_rate.name +
            " " +
            this.$moment(exchange_rate.updated_at).format(
              this.$store.getters.getDateFormat[0]
            );
          this.ExchangeRates.push(exchange_rate);
        }
        this.idExchangeRate = this.defaultExchangeRate();
        this.getExchangeRateDoc(exchange_rate, response.Banks);
      });
    },
    close() {
      this.dialog1 = false;
      this.its_banks = false;
      this.concepts = [];
      this.subscriber = [];
      this.client_document = [];
      this.$emit("close", false);
    },
    update() {
      this.loading = true
      let model = this.client_document.remark;
      axios
        .put(
          this.$store.getters.getClientDocument(this.client_document.id),
          this.client_document,
          {
            params: {
              listID: JSON.stringify(this.listID)
            }
          }
        )
        .then(response => {
          this.$emit("UPdateDoc");
          if (response.status == 200) {
            this.$emit("UpdateDoc");
            this.close();
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: model,
                action: this.$tc("updated", 2)
              }
            });
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log('Error',error.response);
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    // Functions for Format Date
    getFormatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    // Function for assing exchange rate
    getExchangeRateDoc(item, Banks) {
      // eslint-disable-next-line no-console
      // console.log('fatal',item, Banks);
      this.$store
        .dispatch("ExchangeRate", {
          exchange_rate: item,
          base_currency: this.baseCurrency
        })
        .then(response => {
          if (Object.prototype.hasOwnProperty.call(response, "currency_code")) {
            let name = response.exchange_rate.name;
            let idEx = response.id;
            // eslint-disable-next-line no-console
            // console.log('response', response);
            if (this.client_document.type != "price_quotation") {
              this.Banks = Banks.filter(bank => {
                return bank.currency_code == response.currency_code;
              });
            } else {
              this.Banks = [];
            }
            this.its_banks = true;
            this.exchange_rate = response.exchange_rate;
            this.symbol = response.symbol;
            if (name == this.base_currency_name && typeof idEx == "number") {
              this.searchFor.exchangeRate = idEx + "x";
            } else {
              this.searchFor.exchangeRate = idEx;
            }
            this.dialog1 = true;
            this.getNumberDays();
          }
        });
    },
    ChangeExchangeRates(item) {
      // eslint-disable-next-line no-console
      // console.log('changeRate',item);
      this.symbol = item.symbol;
      this.searchFor.exchangeRate = item.id;
      this.decimal_places2 = item.decimal_places || 2;
      let clone = Object.assign({}, item);
      if (!Number.isInteger(clone.id)) {
        clone.id = Number(clone.id.replace(/\D/g, ""));
      }
      this.client_document.exchange_rate = JSON.stringify(clone);
      this.calculations();
    },
    getNumberDays() {
      // eslint-disable-next-line no-console
      // console.log('dates',this.date_start,this.date_end);
      let start_day = "";
      let end_day = "";
      this.$store
        .dispatch("NumberBillableDays", {
          start: this.date_start,
          end: this.date_end
        })
        .then(response => {
          if (
            Object.prototype.hasOwnProperty.call(response, "num_month_1") &&
            Object.prototype.hasOwnProperty.call(response, "num_month_2")
          ) {
            // eslint-disable-next-line no-console
            // console.log(response);
            start_day = response.start_day;
            end_day = response.end_day;
            this.num_month_1 = response.num_month_1;
            this.num_month_2 = response.num_month_2;
            this.number_days = response.number_days;
            this.min_day1 = response.min_day1;
            this.min_day2 = response.min_day2;
            this.max_day1 = response.max_day1;
            this.max_day2 = response.max_day2;
            if (this.number_days > 31) {
              this.date_end = response.max_day2;
              this.getNumberDays();
            } else {
              this.calculations();
            }
          } else {
            start_day = this.$moment(this.date_start, "YYYY-MM-DD");
            end_day = this.$moment(this.date_end, "YYYY-MM-DD");
            this.number_days = end_day.diff(start_day, "days") + 1;
          }
        });
    },
    calculations() {
      // Rate of document
      let find_rate = this.ExchangeRates.find(
        rate => rate.id == this.searchFor.exchangeRate // currency_code
      );
      let rate = 1;
      if (find_rate) {
        rate = Number(find_rate.rate);
      }
      // Function for Calculations
      this.$store
        .dispatch("ClientDocCalculations", {
          doc: this.client_document,
          concepts: this.concepts,
          rate: rate,
          tax_rate: this.subscriber.tax_rate, // RetIVA
          tax_sale: this.subscriber.tax_sale, // RetISRL
          num_month_1: this.num_month_1,
          num_month_2: this.num_month_2,
          IVA: this.IVA,
          UT: this.UT,
          ISRL: this.ISRL
        })
        .then(response => {
          if (typeof response == "object" && response != null) {
            // eslint-disable-next-line no-console
            // console.log('concepts',response);
            this.ItemsConcept = response.ItemsConcept;
            if (response.doc.exchange_rate) {
              this.decimal_places2 = JSON.parse(
                response.doc.exchange_rate
              ).decimal_places;
            } else {
              this.decimal_places2 = 2;
            }
            this.ItemPago = response.ItemPago;
          } else {
            this.ItemsConcept = [];
            this.ItemPago = [];
            this.emitNotification("warning", 6000, "base.leastOneConcept");
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          // console.log('hola',err);
          this.ItemsConcept = [];
          this.ItemPago = [];
          this.emitNotification("error", 6000, "base.something_wrong");
          setTimeout(() => {
            this.close();
          }, 14);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    emitNotification(style, timeout, notifText, extra, pos) {
      this.$store.dispatch("pushNotification", {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      });
    },
    /*
     * Funciones relacionadas con coneptos
     */
    newConcept() {
      let exchange_rate = null;
      if (this.client_document.exchange_rate != null) {
        exchange_rate = ReturnObject(this.client_document.exchange_rate);
      } else {
        exchange_rate = ReturnObject(this.baseCurrency);
      }
      this.$refs.concept_dialog.open({
        edit: false,
        exchange_rate: exchange_rate
      });
    },
    createConcept(item) {
      this.concepts.push(item);
      this.loadingTable = true;
      this.calculations();
    },
    editConcept(item) {
      // eslint-disable-next-line no-console
      // console.log('edit',item);
      this.editedIndex = this.concepts.indexOf(item);
      let exchange_rate = null;
      if (this.client_document.exchange_rate != null) {
        exchange_rate = ReturnObject(this.client_document.exchange_rate);
      } else {
        exchange_rate = ReturnObject(this.baseCurrency);
      }
      let concept = Object.assign({}, item);
      concept.amount = item.amount;
      // eslint-disable-next-line no-console
      // console.log('edit',concept);
      this.$refs.concept_dialog.open({
        concept: concept,
        edit: true,
        exchange_rate: exchange_rate
      });
    },
    updateConcept(item) {
      const documents_count = Number(item.documents_count);
      const listID = [...this.listID];
      if (documents_count > 1) {
        listID.push(item.id);
        this.listID = listID;
      }
      this.loadingTable = true;
      Object.assign(this.concepts[this.editedIndex], item);
      this.calculations();
    },
    deleteConcept(item) {
      this.loadingTable = true;
      this.editedIndex = this.concepts.indexOf(item);
      this.concepts.splice(this.editedIndex, 1);
      this.client_document.concepts = this.concepts;
      this.calculations();
    },
    deleteAndCreate(item) {
      /** Se crea una copia de la lista de conceptos */
      const concepts = [...this.concepts];
      const conceptID = item.id;
      /** Creamos el nuevo concepto a partir del anterior */
      const newConcept = {...item};
      delete newConcept.pivot;
      delete newConcept.documents_count;
      delete newConcept.id;
      /** Buscamos la posicion del concepto en la lista de conceptos */
      this.editedIndex = concepts.findIndex(concept => concept.id === conceptID);
      /** Se elimina el concepto existente */
      this.concepts.splice(this.editedIndex, 1);
      /** Se crea el mismo concepto pero sin Id, pivot y document_count */
      this.concepts.push(newConcept);
      /** Actualizamos la lista de conceptos en el documento */
      this.client_document.concepts = this.concepts;
      this.calculations();
    },
    getPayments(item) {
      // eslint-disable-next-line no-console
      console.log(item);
    },
    /**
     * Funcion para determinar si el usuario tiene los permisos
     */
    PermissionsConcepts() {
      if (this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin) {
        this.Permissions = {
          edit: true,
          create: true,
          delete: true
        };
      } else {
        this.Permissions.create = this.getPermissionGroup({
          permission: ["store_concept"],
          mode: "all"
        });
        this.Permissions.edit = this.getPermissionGroup({
          permission: ["update_concept"],
          mode: "all"
        });
        this.Permissions.delete = this.getPermissionGroup({
          permission: ["destroy_concept"],
          mode: "all"
        });
      }
    },
    /**
     * Funcion para obtener el id de la tasa de cambio del documento
     * Si no existe, se devuelve la tasa de cambio base
     * @returns {string | number} ID de la tasa de cambio
     */
    defaultExchangeRate() {
      /** Si el documento no tiene tasa de cambio se retorna el id
       * de la tasa de cambio de la moneda base, ya que supongo que el documento
       * se encuentra en moneda base
       */
      if (this.client_document.exchange_rate === null ||
          this.client_document.exchange_rate === undefined) {
        return this.baseCurrency.id;
      }
      const exchange_rate = ReturnObject(this.client_document.exchange_rate);
      if (exchange_rate.id) {
        return exchange_rate.id;
      } else {
        return this.baseCurrency.id;
      }
    }
  },
  mounted() {
    this.PermissionsConcepts();
  }
};
