export default {
  name: "CardParent",
  props: {
    // Class parent css
    classParent: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      carousel: false,
      loading: {
        s_active: false,
        r_progress: false,
        i_perdate: false,
        i_corporate: false,
      },
      permissions: {
        s_active: false,
        r_progress: false,
        i_perdate: false,
        view_cards: false
      },
      constructNow: false,
      itemCards: [],
      corp_identity: [],
      responsive_carousel: {},
      total: 0
    };
  },
  computed: {
    //
  },
  methods: {
    /**
     * Get Active Subscribers
     */
    getActiveSubscribers() {
      if (this.permissions.s_active) {
        this.loading.s_active = true;
        let ops = {
          params: {
            get_all: true
          }
        };
        axios
          .get(this.$store.getters.getSubscribers(), ops)
          .then(response => {
            this.pushItemCard(
              "fas fa-user-check",
              "#529f45",
              "",
              response.data.success.total,
              "dashboard.cards.subscribers_active",
              "subscribers_active"
            );
            // eslint-disable-next-line no-console
            // console.log('subscriber_active', response.data.success);
          })
          .catch(error => {
            if (error.response) {
              // eslint-disable-next-line no-console
              console.log("error", error.response);
            }
          })
          .finally(() => {
            this.loading.s_active = false;
          });
      } else {
        // eslint-disable-next-line no-console
        // console.log("failure get active Subscribers");
      }
    },
    /**
     * Get total requests in progress
     */
    getTotalRequestsInProgress() {
      if (this.permissions.r_progress) {
        this.loading.r_progress = true;
        axios
          .get(this.$store.getters.getDataRequests)
          .then(response => {
            this.pushItemCard(
              "fas fa-tools",
              "#e6af44",
              "",
              response.data.request,
              "dashboard.cards.requests_in_progress",
              "requests_in_progress"
            );
            // eslint-disable-next-line no-console
            // console.log("get Total Requests In Progress", response.data);
          })
          .catch(error => {
            if (error.response) {
              // eslint-disable-next-line no-console
              console.log("error", error.response);
            }
          })
          .finally(() => {
            this.loading.r_progress = false;
          });
      } else {
        // eslint-disable-next-line no-console
        // console.log("failure get Total Requests In Progress");
      }
    },
    /**
     *  Get installations per date
     */
    getInstallationsPerDate() {
      if (this.permissions.i_perdate) {
        this.loading.i_perdate = true;
        axios
          .get(this.$store.getters.filterInstallationsPerDate)
          .then(response => {
            response.data.map(d => {
              if (d.title == "this_month") {
                this.pushItemCard(
                  "fas fa-wifi",
                  "#2b9ea2",
                  "",
                  d.completed,
                  "dashboard.cards.installations_completed",
                  "installations_completed"
                );
              } else if (d.title == "totals") {
                this.pushItemCard(
                  "fas fa-user-clock",
                  "#E57373",
                  "",
                  d.pending,
                  "dashboard.cards.installations_pending",
                  "installations_pending"
                );
              }
            });
          })
          .catch(error => {
            if (error.response) {
              // eslint-disable-next-line no-console
              console.log("error", error.response);
            }
          })
          .finally(() => {
            this.loading.i_perdate = false;
          });
      } else {
        // eslint-disable-next-line no-console
        // console.log("failure get Installations Per Date");
      }
    },
    getCorporateIdentity(){
      this.loading.i_corporate = true;
      const url = this.$store.getters.catalogByCategory('corporate_identity');
      axios.get(url)
      .then(response=>{
        if (response.data.success) {
          this.corp_identity = response.data.success;
        }
      })
      .catch(error => {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response);
        }
      }).finally(()=>{
        this.loading.i_corporate = false;
      });
    },
    findIdentity(type, text){
      const found = this.corp_identity.find(identity=>{
        return identity.description.type == type
      });
      if (found) {
        return found.name;
      } else {
        return text;
      }
    },
    /**
     * Push item on array cards
     *
     * @param {string} iconCard
     * @param {string} colorIconCard
     * @param {string} titleCard1
     * @param {string} titleCard2
     * @param {string} descriptionCard
     * @param {string} typeCard
     */
    pushItemCard(
      iconCard,
      colorIconCard,
      titleCard1,
      titleCard2,
      descriptionCard,
      typeCard
    ) {
      const total = typeof titleCard2 == "number" ? titleCard2 : 0;
      const ItemCard = {
        iconCard: iconCard,
        colorIconCard: colorIconCard,
        titleCard1: titleCard1,
        titleCard2: total,
        descriptionCard: descriptionCard,
        typeCard: typeCard
      };
      this.itemCards.push(ItemCard);
      this.total = this.itemCards.length;
      if (this.total == 2) {
        this.responsive_carousel[425] = { items: 2, center: false };
      } else if (this.total == 3) {
        this.responsive_carousel[960] = { items: 3, center: false };
      } else if (this.total >= 4) {
        this.responsive_carousel[1264] = { items: 4, center: false };
      }
    },
    /**
     * Get permissions by Cards
     */
    permissionsCards() {
      this.responsive_carousel = {};
      if (this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin) {
        this.permissions = {
          s_active: true,
          r_progress: true,
          i_perdate: true,
          view_cards: true
        };
      } else {
        // Get permissions by active subscribers
        this.permissions.s_active = this.getPermissionGroup({
          permission: ["index_subscriber", "viewCards_dashboard"],
          mode: "all"
        });
        // Get permissions by request in progress
        this.permissions.r_progress = this.getPermissionGroup({
          permission: ["index_subscriber", "viewCards_dashboard"],
          mode: "all"
        });
        // Get permissions by filter all totals service installation
        this.permissions.i_perdate = this.getPermissionGroup({
          permission: [
            "filterAllTotals_serviceInstallation",
            "viewCards_dashboard"
          ],
          mode: "all"
        });
        // Get permissions by view this cards
        this.permissions.view_cards = this.getPermissionGroup({
          permission: ["viewCards_dashboard"],
          mode: "all"
        });
      }
      if (!this.$store.getters.isRoot && this.permissions.view_cards) {
        this.getCorporateIdentity()
      }
      this.getActiveSubscribers();
      this.getTotalRequestsInProgress();
      this.getInstallationsPerDate();
    }
  },
  mounted() {
    this.permissionsCards();
  },
  watch: {
    //
  }
};
