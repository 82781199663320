var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11","lg":"9"}},[[_c('v-data-table',{staticClass:"elevation-1 exchange-rates-table",attrs:{"headers":_vm.headers2,"items":_vm.items2,"mobile-breakpoint":0,"hide-default-header":_vm.nodata2,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"options":_vm.options,"server-items-length":_vm.totalRates,"loading":_vm.ratesLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pl-0",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "es" ? ((_vm.$tc("system.exchange_rate", 2)) + " " + (_vm.$tc( "franchise." + _vm.title, 2 ))) : ((_vm.$tc("franchise." + _vm.title, 2)) + " " + (_vm.$tc( "system.exchange_rate", 2 ))))+" ")]),_c('div',{staticClass:"flex-grow-1"}),(_vm.permissions.create_rates)?_c('v-btn',{attrs:{"text":"","fab":!_vm.$vuetify.breakpoint.mdAndUp,"disabled":_vm.ratesLoading || _vm.inactive_rates_only},on:{"click":_vm.openDialog}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-4 pt-1"},[_vm._v(" "+_vm._s(_vm.$tc("base.new",2))+" ")]),_c('v-icon',[_vm._v("fa-plus")])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","small":"","disabled":_vm.ratesLoading},on:{"click":_vm.toggleInactiveRates}},on),[(_vm.inactive_rates_only)?_c('v-icon',[_vm._v("fas fa-angle-double-left")]):_c('v-icon',[_vm._v("fas fa-angle-double-right")])],1)]}}])},[(_vm.inactive_rates_only)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("view") + " " + _vm.$tc("franchise.active", 2))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("view") + " " + _vm.$tc("franchise.inactive", 2)))])])],1)]},proxy:true},{key:"body",fn:function(ref){
          var items = ref.items;
return [(!_vm.nodata2)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c('v-layout',{attrs:{"justify-start":"","d-inline":""}},[_vm._v(" "+_vm._s(item.symbol)+" ")]),_c('v-layout',{attrs:{"justify-end":"","d-inline":"","float-right":""}},[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.rate,item.decimal_places))+" ")])],1),_c('td',[_c('v-layout',{attrs:{"justify-center":""}},[(item.currency_code == 'BTC')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" fab fa-"+_vm._s(item.icon)+" ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-"+_vm._s(item.icon)+" ")])],1)],1),_c('td',[_c('v-layout',{staticClass:"text-center",attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(item.date ? _vm.$moment(item.date).format(_vm.$store.getters.getDateFormat[0]) : '- - - -')+" ")])],1),_c('td',[_c('v-layout',{staticClass:"text-center",attrs:{"justify-center":""}},[_c('v-checkbox',{staticClass:"employee-external-checkbox ma-0 pa-0 cursor-disabled",attrs:{"hide-details":"","ripple":false,"color":"success"},model:{value:(item.default),callback:function ($$v) {_vm.$set(item, "default", $$v)},expression:"item.default"}})],1)],1),_c('td',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","disabled":_vm.ratesLoading || _vm.disableBtn},on:{"click":function($event){return _vm.openHistory(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-history")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("billing.history")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(
                            !_vm.inactive_rates_only && _vm.permissions.edit_rates
                          )?_c('v-btn',_vm._g({attrs:{"icon":"","edit":"","disabled":_vm.ratesLoading || _vm.disableBtn},on:{"click":function($event){return _vm.openDialog(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [(
                            _vm.inactive_rates_only
                              ? _vm.permissions.restore_rates
                              : _vm.permissions.delete_rates
                          )?_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.inactive_rates_only ? 'success' : '',"delete":!_vm.inactive_rates_only,"disabled":_vm.ratesLoading || _vm.disableBtn},on:{"click":function($event){return _vm.deleteRestoreItem(item)}}},on),[(!_vm.inactive_rates_only)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ban")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-recycle")])],1):_vm._e()]}}],null,true)},[(!_vm.inactive_rates_only)?_c('span',[_vm._v(_vm._s(_vm.$t("deactivate")))]):_c('span',[_vm._v(_vm._s(_vm.$t("activate")))])])],1)],1)])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center grey--text"},[_vm._v(_vm._s(_vm.$t("no_data")))])])])]}}])})]],2)],1),_c('HistoryDialog',{ref:"history_dialog"}),(_vm.permissions.create_rates || _vm.permissions.edit_rates)?_c('CreateEditDialog',{ref:"create_edit_dialog",attrs:{"permissions":_vm.permissions},on:{"disableBtn":_vm.disableTableBtn,"saveEditSuccess":_vm.getExchangeRates}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }