//
import DialogDocControl from './DialogDocControl.vue';
import DialogOverride from './DialogOverride.vue';
import DialogResetDoc from './DialogResetDoc.vue';
export default {
  name: "DocumentControl",
  components:{
    DialogDocControl,
    DialogOverride,
    DialogResetDoc,
  },
  data (){
    return {
      loadingTable: false,
      loading_employees: false,
      loading_doc_type: false,
      loading_franchise: false,
      expand: false,
      is_filter: true,
      options:{
        sortBy: ['updated_at'],
        sortDesc: [true]
      },
      searches:{
        type: 'invoice',
        franchise_id: '',
        doc: '',
      },
      documents: [],
      TypeDocument: [],
      ListFranchises: [],
      ListEmployees: [],
      DocumentControl: [],
      relationIndex: -1,
      count: 0,
      perPage: 10,
      totalValues: 0,
      step: 'tab-1',
      index: '',
      editedIndex: '',
      unwatch:  '',
      search: '',
      employee: '',
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      policies:{
        create: false,
        edit: false,
        override: false,
        reset: false,
      }
    };
  },
  mounted() {
    this.PoliciesDocument();
    this.getDocumentTypes();
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
    step(val){
      this.PoliciesDocument();
      this.DocumentControl = []
      this.options.page != 1 ? this.options.page = 1 : this.List()
    }
  },
  computed: {
    headers(){
      let isTab1 = this.step == 'tab-1'
      let isInvoice = this.searches.type == 'invoice'
      let headers = [
        {
          text: isTab1 ? this.$t('document_control.serial_document') : this.$t('document_control.serial_control'),
          value: isTab1 ? 'serial_document' : 'serial_control',
          align: isInvoice ? 'center' : ' d-none',
        },
        {
          text: isTab1 && isInvoice ? this.$t('document_control.initial_document_number') : this.$t('document_control.initial_control_number'),
          value: isTab1 && isInvoice ? 'initial_document_number' : 'initial_control_number',
          align: 'center',
        },
        {
          text: isTab1 && isInvoice ? this.$t('document_control.final_document_number') : this.$t('document_control.final_control_number'),
          value: isTab1 && isInvoice ? 'final_document_number' : 'final_control_number',
          align: isTab1 && !isInvoice ? ' d-none' : 'center',
        },
        {
          text: this.$t('document_control.type'),
          value: 'type',
          align: 'center',
        },
      ];
      if (isTab1 && isInvoice) {
        if (this.franchise_id == 0) {
          headers.push(
            {
              text: this.$t('document_control.franchise'),
              value: 'franchise_name',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$tc('employees.employee', 1),
              value: 'legal_denomination',
              sortable: false,
              align: 'center',
            },);
        } else {
          headers.push(
            {
              text: this.$tc('employees.employee', 1),
              value: 'legal_denomination',
              sortable: false,
              align: 'center',
            },
          );
        }
      }
      if (this.step == 'tab-1' || this.step == 'tab-2' && this.franchise_id == 0) {
        headers.push(
          {
            text: this.step == 'tab-1' && this.policies.edit && this.policies.override ? this.$t('actions'):this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'center',
            width: 120
          }
        );
      }
      return headers;
    },
    selectedDocumentTypes(){
      return this.TypeDocument.map(types => {
        return {
          'value': types.value,
          'text': this.$t(types.text)
        };
      });
    },
  },
  methods:{
    List(){
      let url = '';
      if (this.step == 'tab-1') {
        url = this.$store.getters.getDocumentControl();
      } else if (this.step == 'tab-2') {
        url = this.$store.getters.getDocumentControlOverrided();
      }

      this.loadingTable = true;
      axios.get(url,
      {
        params:{
          page: this.options.page,
          paginate: this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          type: this.searches.type,
          franchise_id: this.franchise_id == 0 ? this.searches.franchise_id : this.franchise_id,
          doc: this.searches.doc,
        }
      }
      ).then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('estoy aqui',response.data);
        let client_document_control = [];
        if (this.step == 'tab-1') {
          client_document_control = response.data.client_document_control.data.filter(doc=>{
            return doc.status != 'voided' && doc.status != 'finished';
          });
          this.totalValues = response.data.client_document_control.total;
        } else if (this.step =='tab-2') {
          client_document_control = response.data.voided_numbers.data;
          this.totalValues = response.data.voided_numbers.total;
        }
        client_document_control.map(doc=>{
          if (doc.employee != null && doc.employee != undefined) {
            let dni = doc.employee.doc.charAt(0);
            let legal_denomination = '';
            let obj = JSON.parse(doc.employee.legal_denomination[1]);
            if (dni=='J'|| dni=='G') {
              legal_denomination = obj.denomination;
            } else if (dni=='V'|| dni=='E'){
              if (obj.firm_name != null && obj.firm_name != undefined) {
                legal_denomination = obj.firm_name;
              } else {
                legal_denomination = obj.first_name +' '+ obj.last_name;
              }
            }
            doc.legal_denomination = legal_denomination.substr(0, 20);
          } else {
            doc.legal_denomination = '';
          }
        });
        // eslint-disable-next-line no-console
        // console.log('Documentos', client_document_control);
        this.documents = client_document_control;
        this.count++;
        if (this.count == 1 && this.franchise_id == 0) {
          this.getFranchis();
        } else {
          this.getListDocument();
        }
      }).catch((error)=>{
        this.loadingTable = false;
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      }).finally(()=>{
        this.is_filter = false;
      });
    },
    ListOverrided(){
      axios.get(this.$store.getters.getDocumentControlOverrided(),
      {
        params:{
          page: this.options.page,
          paginate: this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          type: this.searches.type,
          // franchise_id: this.franchise_id == 0 ? this.searches.franchise_id : this.franchise_id,
          // doc:this.searches.doc,
        }
      }
      ).then(response=>{
        if (response) {
          // eslint-disable-next-line no-console
          // console.log(response.data.voided_numbers.data);
        }
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
    newDocumentControl(){
      let obj = {
        edit:false,
        docControl:Object.assign({}, {}),
        TypeDocument:this.TypeDocument,
        ListFranchises: this.ListFranchises,
      };
      this.$refs.documen_control.open(obj);
    },
    editItem(item){
      let obj = {
        edit:true,
        docControl:Object.assign({}, item),
        TypeDocument:this.TypeDocument,
        ListFranchises: this.ListFranchises,
      };
      this.$refs.documen_control.open(obj);
    },
    deleteItem (item) {
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('document_control.document'),
        title: this.$t('base.override'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.override'),
          record: item.serial_document
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getDocumentControl(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    getFranchis(){
      // eslint-disable-next-line no-console
      if (this.franchise_id == 0) {
        this.loading_franchise = true;
        axios.get(this.$store.getters.getFranchises()
        ).then((response)=>{
          // eslint-disable-next-line no-console
          // console.log('res franchise',response.data.franchises.data);
          if (response.data.franchises.data) {
            let ListFranchises=[];
            response.data.franchises.data.forEach(element => {
              ListFranchises.push({
                id: element.id,
                name: element.full_name
              });
            });
            this.loading_franchise = false;
            this.ListFranchises =  ListFranchises;
            // console.log('Franquicias', this.ListFranchises);
            this.getListDocument();
          }
        }).catch((error)=>{
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log('Franchise Error',error.response.data);
          }
        });
      }
    },
    getDocumentTypes(){
      this.loading_doc_type = true;
      axios.get(this.$store.getters.getDocumentControlTypes
      // axios.get(this.api_url + 'client_document_types'
      ).then((response)=>{
        if (response.data.client_document_types) {
          let TypeDocument = [];
          response.data.client_document_types.forEach(element => {
            TypeDocument.push({
              value: element,
              text: 'document_control.types.'+element,
              type: element.type,
            });
          });
          this.TypeDocument = TypeDocument;
        }
      }).catch((error)=>{
        if (error.response) {
          // console.log('Actor Error',error.response.data);
        }
      }).finally(()=>{
        this.loading_doc_type = false;
      });
    },
    getListDocument(){
      let DocumentControl = this.documents;
      for (let kdoc = 0; kdoc < DocumentControl.length; kdoc++) {
        let franchise_id = DocumentControl[kdoc].franchise_id;
        let type = DocumentControl[kdoc].type;
        var result2  = this.ListFranchises.filter(function(o){return o.id == franchise_id;} );
        var result3  = this.selectedDocumentTypes.filter(function(o){return o.value == type;} );
        if(result2 !='' && this.franchise_id ==0){
          DocumentControl[kdoc].franchise_name = result2[0].name;
        } else {DocumentControl[kdoc].franchise_name = '';}
        if(result3 !=''){
          DocumentControl[kdoc].type_name = result3[0].text;
        } else {DocumentControl[kdoc].type_name = '';}
      }
      this.loadingTable = false;
      this.DocumentControl = DocumentControl;
      // eslint-disable-next-line no-console
      // console.log('Documentos',this.DocumentControl);
    },
    updateList(){
      this.options = {
        page: 1,
        sortBy:['updated_at'],
        sortDesc:[true]
      };
      this.List();
    },
    SearchFilter(){
      let wasPage1 = this.options.page == 1
      this.options = {
        page: 1,
        sortBy:['updated_at'],
        sortDesc:[true]
      };
      this.is_filter = true;
      if (wasPage1) {
        this.List()
      }
    },
    EraseFilter(){
      this.options = {sortBy:['updated_at'],sortDesc:[true]};
      this.searches = {
        type: 'invoice',
        franchise_id: '',
        doc: ''
      };
      this.$refs.employee_filter.clear2();
      this.List();
    },
    SearchEmployee(item){
      if (item != null && item != undefined) {
        this.searches.doc = item.doc;
        this.SearchFilter();
      }
    },
    Override(item){
      let obj = Object.assign({}, item);
      this.$refs.override_doc.open(obj);
    },
    ResetDoc(item){
      let obj = Object.assign({}, item);
      this.$refs.reset_doc.open_reset(obj);
    },
    PoliciesDocument(){
      if (!this.$store.getters.isAdmin && !this.$store.getters.isFranchiseAdmin) {
        this.policies={create:false,edit:false,override:false,reset:false,};
        let policies = [
          'index_clientDocumentControl',
          'index_voidedNumber',
          'index_employee',
          'clientDocumentTypes_clientDocument',
        ];
        if (this.franchise_id == 0) {
          policies.push('index_franchise');
        }
        const policies_create   = [...policies,'store_clientDocumentControl'];
        const policies_update   = [...policies,'update_clientDocumentControl'];
        const policies_override = [...policies,'store_voidedNumber'];
        const policies_reset    = [...policies,'destroy_voidedNumber'];
        // eslint-disable-next-line no-console
        if (this.step=='tab-1') {
          this.policies.create = this.getPermissionGroup({
            permission:policies_create,
            mode:'all'
          });
          this.policies.override = this.getPermissionGroup({
            permission:policies_override,
            mode:'all'
          });
          this.policies.edit = this.getPermissionGroup({
            permission:policies_update,
            mode:'all'
          });
        }
        if (this.step=='tab-2') {
          this.policies.reset = this.getPermissionGroup({
            permission:policies_reset,
            mode:'all'
          });
        }
      } else if (this.$store.getters.isAdmin) {
        this.policies={create:true,edit:true,override:true,reset:true,};
      } else if(this.$store.getters.isFranchiseAdmin){
        this.policies={create:true,edit:true,override:false,reset:false,};
      }
      // eslint-disable-next-line no-console
    },
  },
};
