export default {
  name: "AddEditRateForm",
  props: {
    //
  },
  data() {
    return {
      edit: false,
      compare_rate: {
        default: false,
        date: "",
        name: "",
        rate: "",
        symbol: "",
        icon: "",
        currency_code: "",
        url: ""
      },
      ex_rate: {
        default: false,
        date: "",
        name: "",
        rate: "",
        symbol: "",
        icon: "",
        currency_code: "",
        url: ""
      },
      currencies: [],
      loadingCurrencies: false,
      rate_decimal_precision: 2,
      currency_code: null,
      current_id: 0,
      is_default: false,
      date_menu: false,
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      unwatch: ""
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        if (val && this.$store.getters.getConfirmationTemplate == "abc") {
          //
        }
      }
    );
  },
  mounted() {
    this.ex_rate.date = this.today
    this.getAllCurrencies();
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.ex_rate.date)
    },
    computedRate: {
      get() {
        let ex_rt = 0;
        if (this.ex_rate && this.ex_rate.rate) {
          ex_rt = this.ex_rate.rate;
        }
        return parseFloat(ex_rt);
      },
      set(newVal) {
        this.ex_rate.rate = newVal;
      }
    }
  },
  methods: {
    getAllCurrencies() {
      this.loadingCurrencies = true;
      axios
        .get(this.$store.getters.catalogByCategory("currency"))
        .then(response => {
          if (response.data.success) {
            this.currencies = response.data.success;
          }
        })
        .finally(() => {
          this.loadingCurrencies = false;
        });
    },
    fillPreset2(code) {
      let selected = this.currencies.filter(currency => {
        return currency.description.currency_code == code;
      });
      setTimeout(() => {
        this.ex_rate.symbol = selected[0].description.symbol;
        this.ex_rate.icon = selected[0].description.icon;
        this.ex_rate.currency_code = selected[0].description.currency_code;
      }, 200);
    },
    saveUpdateRate() {
      this.$refs.form_exchange_rate.validate().then(success => {
        if (success) {
          this.ex_rate.default = this.is_default// Assign default value
          if (this.edit) {
            let equal = true;
            Object.keys(this.ex_rate).forEach(key => {
              if (this.ex_rate[key] !== this.compare_rate[key]) {
                equal = false;
              }
            });
            if (equal) {
              this.$awn.warning(this.$t("DSB.profile.nothing_to_change"), {
                durations: { warning: 4000 }
              });
              return false;
            }
          }
          this.$emit("loadingBtn", true);
          let payload = this.ex_rate;
          if (payload.url == "") {
            delete payload.url;
          }
          payload.decimal_places = this.rate_decimal_precision;
          axios({
            url: this.$store.getters.exchangeRates(payload.id),
            method: this.edit ? "patch" : "post",
            data: payload
          })
            .then(response => {
              if (response.status == 200 && response.data.success) {
                let action = "";
                if (response.data.success == "new_rate_created") {
                  action = this.$tc("created", 2);
                }
                if (response.data.success == "rate_restored_updated") {
                  action = this.$tc("restored_updated", 2);
                }
                this.$awn.success(
                  this.$tc("success", 2, {
                    model: this.$tc("system.exchange_rate", 1),
                    action: action
                  }),
                  { durations: { alert: 4000 } }
                );
                this.setClear2();
                this.$emit("loadingBtn", false);
                this.$emit("saveEditSuccess");
              }
            })
            .catch(err => {
              if (err.response) {
                this.$emit("loadingBtn", false);
              }
            })
            .finally(() => {
              // Not working for $emit
            });
        }
      });
    },
    setClear2() {
      this.$refs.form_exchange_rate.reset();
      this.current_id = 0;
      this.currency_code = null;
      this.rate_decimal_precision = 2;
      setTimeout(() => {
        this.ex_rate = {
          default: false,
          date: "",
          name: "",
          rate: "",
          symbol: "",
          icon: "",
          currency_code: "",
          url: ""
        };
        this.edit = false;
      }, 400);
      this.$emit("closeDialog");
    },
    setDecimalPlaces (decimal_places) {
      this.rate_decimal_precision = decimal_places
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    }
  },
  watch: {
    //
  },
  beforeDestroy() {
    this.unwatch();
  }
};
