<template>
  <!-- New Contract for existing Subscriber -->
  <v-dialog
    v-model="dialog_new_contract"
    max-width="500px"
    persistent
  >
    <v-card :class="{'d-none': sheet }">
      <toolbarDialog
        :title="`${$tc('contracts.contracts', 1)}`"
        titleSize="title"
        @close="cancelNewContract"
      />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="pa-0 pt-2 text-center">
              <span v-if="is_code" v-html="$tc('subscribers.subscriber_code_already_exists', 2, ['('+subscriber_exist.doc+')<br>'+subscriber_exist.legal_denomination])"></span>
              <span v-else v-html="$tc('subscribers.subscriber_already_exists', 1, [subscriber_exist.legal_denomination])"></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel
          :disabled="loading_dialog || loading_fields"
          @click="cancelNewContract"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{$t('base.cancel')}}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
         <v-bottom-sheet
            v-model="sheet"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                v-bind="attrs"
                v-on="on"
                @click="sheetContinue = !sheetContinue"
              >
                <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
                  {{$t('base.accept')}}
                </span>
                <v-icon small>fas fa-check</v-icon>
              </v-btn>
            </template>
            <v-sheet
              class="text-center"
              height="200px"
            >
              <div class="text-sheet">
                <h3 class="red--text mb-2" v-if="sheetContinue">
                  <v-icon color="red">fas fa-exclamation-triangle</v-icon>
                  <br>
                  {{ $t('subscribers.verify_subscriber_information')}}
                </h3>
                <div v-if="!sheetContinue">
                  <span v-if="is_code" v-html="$tc('subscribers.subscriber_code_already_exists', 2, ['('+subscriber_exist.doc+')<br>'+subscriber_exist.legal_denomination])"></span>
                  <span v-else v-html="$tc('subscribers.subscriber_already_exists', 2, [subscriber_exist.legal_denomination])"></span>
                </div>
              </div>
              <div class="mt-3">
                <v-btn
                  color="green"
                  dark
                  v-if="sheetContinue"
                  @click="sheetContinue = !sheetContinue"
                >
                  <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
                    {{$t('base.accept')}}
                  </span>
                  <v-icon small>fas fa-check</v-icon>
                </v-btn>

                <template v-else>
                  <v-btn
                    color="info"
                    :loading="loading_dialog || loading_fields"
                    @click="newReqForExisting"
                    class="mx-3"
                  >
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
                      {{$t('base.confirm')}}
                    </span>
                    <v-icon small>fas fa-check</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    cancel
                    @click="sheet = !sheet, sheetContinue = false"
                    class="mx-3"
                  >
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
                      {{$t('base.cancel')}}
                    </span>
                    <v-icon small>fas fa-times</v-icon>
                  </v-btn>
                </template>
              </div>
            </v-sheet>
          </v-bottom-sheet>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'existing-Subscriber',
  components: {},
  props: {
    loading_fields:{
      type: Boolean,
      default: false,
    },
    // If the subscriber is migrated
    isMigrateSubscriber: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      sheet: false,
      sheetContinue: false,
      dialog_new_contract: false,
      loading_dialog: false,
      subscriber_exist: {},
      modeNode: process.env.NODE_ENV,
      is_code: false
    }
  },
  computed:{},
  methods:{
    open_dialog(data, is_code = false){
      if (data.id) {
        this.subscriber_exist = data;
        this.dialog_new_contract = true;
        if (is_code) {
          this.is_code = true
        }
      }
    },
    close_dialog(val){
      if (!this.loading_dialog && !this.loading_fields) {
        this.subscriber_exist = {};
        this.dialog_new_contract = false;
      }
      if (val) {
        this.subscriber_exist = {};
        this.dialog_new_contract = false;
        this.is_code = false
      }
    },
    cancelNewContract(){
      if (!this.loading_dialog && !this.loading_fields) {
        this.$emit('cancelNewContract');
      }
      this.close_dialog(true);
    },
    // Create a new Service Request for existing Subscriber, in another location
    newReqForExisting () {
      this.loading_dialog = true
      let params = {
        'subscriber_id': this.subscriber_exist.id
      }
      if (this.isMigrateSubscriber) {
        params.migrating = this.isMigrateSubscriber;
        params.code = this.subscriber_exist.code;
      }
      axios.post(this.$store.getters.getContracts(), params)
      .then(response => {
        this.$emit('contract', response.data.success);
        this.close_dialog(true);
      })
      .catch(err => {
        if (err.response && this.modeNode == 'development') {
          // eslint-disable-next-line no-console
          console.log('Error', err.response);
        }
      })
      .finally(()=>{
        if (this.modeNode == 'development') {
          setTimeout(() => {
            this.$emit('loading', false);
            this.loading_dialog = false;
          }, 3500);
        } else {
          this.loading_dialog = false
          this.$emit('loading', false);
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .v-bottom-sheet {
      .v-sheet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 960px) {
          height: initial !important;
          padding-bottom: 10px;
        }
      }
    }
  }
</style>
