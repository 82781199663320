import AddEditDialog from './Dialog.vue'

export default {
  name: "BankAccountsTable",
  components:{
    AddEditDialog
  },
  data (){
    return {
      no_data: false,
      loadingTable: false,
      bank_accounts: [],
      totalValues: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      permissions: {
        list: false,
        create: false,
        edit: false,
        delete: false
      },
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'bankAccount'){
        this.options.page = 1
        this.getAccounts()
      }
    })
  },
  mounted () {
    this.bankAccountPolicies()
  },
  computed:{
    headers(){
      let headers = [
        {
          text: this.$tc('banks.banks',1),
          value:'bank.name',
          align: 'left',
        },
        {
          text: this.$t('type'),
          value:'name',
          align: 'left',
          width: 140
        },
        {
          text: this.$t('banks.account_holder'),
          value:'description.account_holder_dni',
          align: 'center',
          width: 170
        }
      ]
      if (this.permissions.edit || this.permissions.delete) {
        headers.push(
          {
            text: this.$t('actions'),
            value:'action',
            align: 'center',
            sortable: false,
            width: 90
          }
        )
      }
      return headers
    }
  },
  methods:{
    getAccounts () {
      this.bank_accounts = []
      this.loadingTable = true
      this.no_data = false

      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'subscriber_id': this.subscriber_id,
          'code': this.contract_code
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }

      if (this.$store.getters.isAdmin) {
        ops.params.get_all = true
      }

      axios.get(this.$store.getters.getBankAccounts(), ops)
        .then(response =>{
          if (response.data.success) {
            this.bank_accounts = response.data.success.data
            this.totalValues = response.data.success.total
          }
          if (this.totalValues == 0 || response.data.empty) {
            this.no_data = true
          }
        })
        .catch((err)=>{
          // eslint-disable-next-line no-console
          console.log(err)
        }).finally(()=>{
          this.loadingTable = false
        })
    },
    openDialog (item = {}) {
      this.$refs.account_dialog.open(item)
    },
    deleteItem (item) {
      this.$store.dispatch('confirmationValue',{
        template: 'bankAccount',
        show: true,
        model: this.$tc('banks.bank_account',1),
        title: this.$t('base.delete'),
        action: this.$tc('deleted',2),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.bank.name+' ('+this.$t('banks.'+item.name)+')'+' '+this.$t('of')+' '+item.description.account_holder_dni
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getBankAccounts(item.id),
          method: 'delete',
        },
        pos: 2
      });
    },
    bankAccountPolicies () {
      if (this.$store.getters.isAdmin){
        this.permissions={
          list: true,
          create: true,
          edit: true,
          delete: true,
          restore: true,
          list_codes: true,
          list_taxes: true,
          list_bank_accounts: true
        }
      } else {
        this.permissions.list = this.getPermissionGroup({
          permission:[
            'index_bankAccount'
          ],
          mode:'all'
        });
        this.permissions.create = this.getPermissionGroup({
          permission:[
            'store_bankAccount',
          ],
          mode:'all'
        });
        this.permissions.edit = this.getPermissionGroup({
          permission:[
            'index_taxes',
            'update_bankAccount',
          ],
          mode:'all'
        });
        this.permissions.delete = this.getPermissionGroup({
          permission:[
            'index_bankAccount',
            'destroy_bankAccount',
          ],
          mode:'all'
        });
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getAccounts()
      },
      deep: true,
    },
  },
  beforeDestroy () {
    this.unwatch()
  }
}
