<template>
  <div :class="classParent">
    <v-toolbar flat class="pl-0">
      <v-toolbar-title class="d-flex">
        <v-tooltip
          v-if="expand || showArrowBack"
          :top="showArrowBack"
          :bottom="expand"
        >
          <template v-slot:activator="{ on }">
            <v-btn text fab small
              v-on="on"
              @click="arrowBack"
              class="mr-2"
            >
              <v-icon small>fas fa-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>{{ expand ? $t('close') : $t('back')}}</span>
        </v-tooltip>
        <div v-if="!expand" class="toolbar-table-title">
          {{title}}
        </div>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-expand-x-transition>
        <v-card flat
          v-show="expand"
          height="40"
          :width="$vuetify.breakpoint.smAndDown ? '75%': $vuetify.breakpoint.lgAndUp ? '30%':'40%'"
          class="hidden-search"
        >
          <v-card-text class="pa-0">
            <ValidationObserver ref="form_search_OnList" v-slot="{ }">
              <v-form
                class="form-search-OnList"
                @submit.prevent="searchStart()"
              >
                <v-row>
                  <v-col cols="12" class="pt-1 pb-0 py-5">
                    <v-text-field
                      v-if="!searchWithValidation"
                      v-model="search"
                      name="search"
                      dark
                      single-line
                      ref="search"
                      :disabled="disableSearch"
                      :autocomplete=" autocompleteOff ? 'off' : 'on' "
                      :label="$t('base.search')"
                    ></v-text-field>
                    <ValidationProvider
                      v-if="searchWithValidation"
                      v-slot="{ errors }"
                      vid="search"
                      :name="$t('base.search')"
                      rules="required|min:3"
                    >
                      <v-text-field
                        v-model="filter"
                        name="search"
                        dark
                        single-line
                        ref="search"
                        :disabled="disableSearch"
                        :autocomplete=" autocompleteOff ? 'off' : 'on' "
                        :error-messages="errors"
                        :label="$t('base.search')"
                        v-on:keyup.enter="searchStart()"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-expand-x-transition>
      <v-btn
        text
        v-show="!expand && showNew"
        :disabled="disableNew"
        @click="newItem"
        :color="colorButton"
        :fab="!$vuetify.breakpoint.mdAndUp"
      >
        <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3 pt-1">
          {{ nameButton }}
        </span>
        <v-icon>{{iconButton}}</v-icon>
      </v-btn>
      <!-- Search -->
      <v-btn
        v-if="showSearch"
        text fab small
        @click="btnSearch"
        >
        <v-icon>fas fa-search</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>


<style scoped>
  .toolbar-table-title{
    font-size: 1.5rem !important;
    font-weight: bold;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
    line-height: 2.5rem;
    text-transform: uppercase;
  }
</style>

<script>
export default {
  name: "toolbar-table",
  components: {},
  props:{
    /**
    * Class parent css
    */
    classParent:{
      type:String,
      default:''
    },
    /**
    * Showing or hiding the button Search
    */
    showSearch:{
      type:Boolean,
      default:false,
    },
    /**
    * Showing or hiding the input field With Validation
    */
    searchWithValidation:{
      type:Boolean,
      default:false,
    },
    /**
    * Showing or hiding the button right
    */
    autocompleteOff:{
      type:Boolean,
      default: false
    },
    showNew:{
      type:Boolean,
      default: false,
    },
    /**
    * Showing or hiding the button left
    */
    showArrowBack:{
      type:Boolean,
      default: false,
    },
    /**
    * Enable and disable the button right
    */
    disableNew:{
      type: Boolean,
      default: false,
    },
    /**
    * Enable and disable input search
    */
    disableSearch:{
      type: Boolean,
      default: false,
    },
    /**
    * Adding a title to the toolbar
    */
    title:{
      type:String,
      default:'',
    },
    /**
    * Adding a name to the Button
    */
    nameButton:{
      type:String,
      default: '',
    },
    /**
    * Change icon to the Button
    */
    iconButton:{
      type:String,
      default: 'fas fa-plus',
    },
    /**
    * Change the name of the action that the button emits
    */
    emitButton:{
      type:String,
      default: 'newItem',
    },
    /**
    * Change the color of the Button
    */
    colorButton:{
      type:String,
      default: '',
    },
    /**
    * Change the minimum number of characters in the search field
    */
    minVal:{
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      expand: false,
      search:'',
      filter:''
    }
  },
  computed: {},
  methods: {
    newItem(){
      this.$emit(this.emitButton);
    },
    arrowBack(){
      this.expand = false
      this.search = '';
      this.$emit('arrowBack', false);
      if (this.searchWithValidation) {
        this.filter = '';
        this.$refs.form_search_OnList.reset();
      }
    },
    expandIt(){
      this.expand = true;
      setTimeout(() => {
        this.$refs.search.focus();
      }, 400);
    },
    searchStart(){
      this.$refs.form_search_OnList.validate()
      .then((success)=>{
        if (success) {
          this.$emit('search', this.filter);
        }
      });
    },
    btnSearch(){
      if (this.searchWithValidation) {
        if (this.expand) {
          this.searchStart();
        } else {
          this.expandIt();
        }
      } else {
        this.expand=!this.expand;
        if (this.expand) {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 400);
        }
      }
    }
  },
  mounted() {},
  watch: {
    search(val){
      if (val != null && val != undefined && val.length > this.minVal) {
        this.$emit('search',val);
      }
    }
  }
};
</script>
