<template>
  <v-card outlined class="card-outlined ma-0">
    <v-card-text>
      <v-row no-gutters
        class="d-flex justify-center"
        v-if="subscriber.id !== 0"
      >
        <v-col class="d-flex flex-column">
          <span> {{ $t("paymentNote.count") }}: {{ subscriber.id }} </span>
          <span
            >{{
              subscriber.person_type == "natural"
                ? $tc("subscribers.dni", 0)
                : $tc("subscribers.dni", 1)
            }}: {{ subscriber.doc }}
          </span>
          <span
            >{{ $t("paymentNote.subscriber") }}:
            {{ subscriber.legal_denomination }}
          </span>
        </v-col>
        <v-col class="d-flex flex-column">
          <span v-show="subscriber.tax_rate != null">
            RetIVA: {{ subscriber.tax_rate }}
          </span>
          <span v-show="subscriber.tax_sale != null">
            RetISRL: {{ subscriber.tax_sale }}
          </span>
          <span
            >{{ $t("paymentNote.daysCredit") }}: {{ subscriber.credit_days }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="employee_id && employee_id !== ''">
        <v-col cols="12" style="color: red;">
          {{$t('Payments.pay_all')}}
        </v-col>
        <v-col class="font-weight-bold"> {{$t('document_control.document_number')}}: {{ header.document_number }} </v-col>
        <v-col class="font-weight-bold"> {{$t('document_control.control_number')}}: {{ header.control_number }} </v-col>
        <v-col class="font-weight-bold"> {{$t('document_control.serial_control')}}: {{ header.serial_control }} </v-col>
        <v-col class="font-weight-bold"> {{$t('document_control.serial_document')}}: {{ header.serial_document }} </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "HeaderPayments",
  props: {
    employee_id:{
      type: [String, Number],
      default: ''
    },
    contract_id:{
      type: [String, Number],
      default: ''
    },
    subscriber: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    }
  },
  data() {
    return {
      actor_id: localStorage.getItem("id"),
      header: {
        control_number: 0,
        document_number: 0,
        serial_control: "",
        serial_document: "",
      },
    };
  },
  computed: {},
  methods: {
    getClientDocumentHeaderByContract(employee_id) {
      axios
        .post(this.$store.getters.getClientDocumentHeaderByContract, {
          contract_id: this.contract_id,
          employee_id,
        })
        .then((response) => {
          // eslint-disable-next-line no-console
          // console.log('document header', response.data);
          this.header = response.data;
        })
        .catch((error) => {
          if (error.response) {
            // eslint-disable-next-line no-console
            console.log("Error", error.response);
          }
        });
    },
  },
  watch: {
    employee_id(val) {
      if (val != "" && val != null) {
        this.getClientDocumentHeaderByContract(val);
      }
    },
  },
  mounted() {},
};
</script>
