<template>
  <div :class="classParent">
    <ValidationObserver ref="form_address" v-slot="{ handleSubmit }">
      <v-form class="address-form" @submit.prevent="handleSubmit(submit)">
        <v-row v-if="address.type == 'admin' && permissions.fiscal_address">
          <v-col cols="12"
            class="py-0"
          >
            <v-switch hide-details dense
              v-model="uses_fiscal"
              label="Utiliza dirección fiscal"
              color="primary"
              class="float-right mt-0 pa-0 small-label"
            ></v-switch>
          </v-col>
          <v-col cols="12"
            v-if="uses_fiscal"
            class="py-0"
          >
            <ValidationProvider
              v-slot="{ errors }"
              vid="dirección fiscal"
              name="dirección fiscal"
              rules="required"
            >
              <v-text-field
                v-model="address.fiscal_address"
                name="dirección fiscal"
                counter
                maxlength="190"
                class="input-uppercase mt-0"
                prepend-icon="fas fa-map-marker-alt"
                :label="$tc('address',1) + ' fiscal'"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="py-0"
            v-if="showEmployee || address.type == 'tech'"
          >
            <ValidationProvider
              v-slot="{ errors }"
              vid="employee_field"
              :name="$tc('employees.employee', 1)"
              :rules="{ required: showEmployee || address.type == 'tech' }"
            >
              <EmployeeSearch
                v-model="address.employee_id"
                name="employee_field"
                :ClassField="''"
                :disabled_field="loadingAddress"
                :prepend_icon_field="'fas fa-id-card-alt'"
                :mountedList="false"
                :onlyID="true"
                :error-messages="errors"
                ref="employee_search"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="country"
              :name="$t('country')"
              rules="required"
            >
              <v-autocomplete
                v-model="address.country_id"
                name="country"
                :items="countries"
                item-text="name"
                item-value="id"
                prepend-icon="fas fa-list"
                disabled
                :loading="load.countries || loadingForm || loadingAddress"
                :label="$t('country')"
                :error-messages="errors"
                @change="getListOf('states', address.country_id)"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="state"
              :name="$t('state')"
              rules="required"
            >
              <v-autocomplete
                v-model="address.state_id"
                name="state"
                :items="states"
                :loading="load.states || loadingForm || loadingAddress"
                :disabled="show.states || loadingForm || loadingAddress"
                item-text="name"
                item-value="id"
                prepend-icon="fas fa-list"
                :label="$t('state')"
                :error-messages="errors"
                @change="getListOf('municipalities', address.state_id)"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="municipality"
              :name="$t('municipality')"
              rules="required"
            >
              <v-autocomplete
                v-model="address.municipality_id"
                name="municipality"
                :items="municipalities"
                :loading="load.municipalities || loadingForm || loadingAddress"
                :disabled="show.municipalities || loadingForm || loadingAddress"
                item-text="name"
                item-value="id"
                prepend-icon="fas fa-list"
                :label="$t('municipality')"
                :error-messages="errors"
                @change="getListOf('parishes', address.municipality_id)"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="parish"
              :name="$t('parish')"
              rules="required"
            >
              <v-autocomplete
                v-model="address.parish_id"
                name="parish"
                :items="parishes"
                :loading="load.parishes || loadingForm || loadingAddress"
                :disabled="show.parishes || loadingForm || loadingAddress"
                item-text="name"
                item-value="id"
                clearable
                clear-icon="fas fa-times"
                prepend-icon="fas fa-list"
                :label="$t('parish')"
                :error-messages="errors"
                @change="getListOf('sectors', address.parish_id)"
                @click:clear="clearParish()"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="sector"
              :name="$t('sector')"
              rules="required"
            >
              <v-autocomplete
                v-model="address.sector_id"
                name="sector"
                :items="sectors"
                :loading="load.sectors || loadingForm || loadingAddress"
                :disabled="show.sectors || loadingForm || loadingAddress"
                item-text="name"
                item-value="id"
                prepend-icon="fas fa-list"
                :label="$t('sector')"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field
              v-model="address.tower"
              name="tower"
              counter
              maxlength="190"
              class="input-uppercase"
              prepend-icon="fas fa-building"
              :disabled="loadingForm || loadingAddress"
              :loading="loadingForm || loadingAddress"
              :label="$tc('contracts.tower', 2)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="address"
              :name="$t('address')"
              rules="required"
            >
              <v-text-field
                v-model="address.address"
                name="address"
                counter
                maxlength="190"
                class="input-uppercase"
                prepend-icon="fas fa-map-marker-alt"
                :disabled="loadingForm || loadingAddress"
                :loading="loadingForm || loadingAddress"
                :label="$tc('address',1)"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              vid="ref_point"
              :name="$t('contracts.ref_point')"
              rules="min:6"
            >
              <v-text-field
                v-model="address.ref_point"
                name="ref_point"
                counter
                maxlength="190"
                class="input-uppercase"
                prepend-icon="fas fa-map-pin"
                :disabled="loadingForm || loadingAddress"
                :loading="loadingForm || loadingAddress"
                :label="$t('contracts.ref_point')"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <v-subheader class="custom-subheader">
              {{ $tc("contracts.coordinates", 2) }}
            </v-subheader>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="latitude"
                  :name="$t('company.latitude')"
                  :rules="{
                    required: validLocalization,
                    regex: /^[-]?((([0-8]?[0-9])\.(\d+))|90(\.0+)?)$/
                  }"
                >
                  <v-text-field
                    v-model="address.lat"
                    name="latitude"
                    prepend-icon="fas fa-location-arrow"
                    :disabled="loadingForm || loadingAddress"
                    :loading="loadingForm || loadingAddress"
                    :messages="[$t('example') + ` 8.1487`]"
                    :label="$t('company.latitude')"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="longitude"
                  :name="$t('company.longitude')"
                  :rules="{
                    required: validLocalization,
                    regex: /^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+))|180(\.0+)?)$/
                  }"
                >
                  <v-text-field
                    v-model="address.lon"
                    name="longitude"
                    prepend-icon="fas fa-location-arrow fa-rotate-270"
                    :disabled="loadingForm || loadingAddress"
                    :loading="loadingForm || loadingAddress"
                    :messages="[$t('example') + ` -71.4410`]"
                    :label="$t('company.longitude')"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "address-subscriber",
  components: {},
  props: {
    /**
     * Class parent css
     */
    classParent: {
      type: String,
      default: ""
    },
    /**
     * Data Contact
     */
    same_as_previous: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    /**
     * Loadding all fields
     */
    loadingAddress: {
      type: Boolean,
      default: false
    },
    /**
     * Check if form by update or create
     */
    editMode: {
      type: Boolean,
      default: false
    },
    /**
     * Show Search Employee
     */
    showEmployee: {
      type: Boolean,
      default: false
    },
    /**
     * Contract Id
     */
    contractId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uses_fiscal: false,
      permissions: {
        fiscal_address: false
      },
      show: {
        countries: false,
        states: false,
        municipalities: false,
        parishes: false,
        sectors: false
      },
      load: {
        countries: false,
        states: false,
        municipalities: false,
        parishes: false,
        sectors: false
      },
      loadingForm: false,
      init_address: false,
      countries: [],
      country_id: 237,
      states: [],
      municipalities: [],
      parishes: [],
      sectors: [],
      modeNode: process.env.NODE_ENV,
      address: {
        country_id: 237
      },
      copy_address: "",
      validLocalization: false,
    };
  },
  computed: {},
  methods: {
    getPermissions() {
        this.permissions.fiscal_address = this.getPermissionGroup({
          permission: ['fiscal_contractAddress'],
          mode: 'all'
        });
    },
    validateForm() {
      this.$refs.form_address.validate().then(success => {
        if (success) {
          if (this.edit == false) {
            this.createAddress();
          } else {
            this.validCloneByUpdate();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
          this.$emit("AddressError");
        }
      });
    },
    resetForm() {
      // eslint-disable-next-line no-console
      // console.log('Reset Validation Form')
      this.$refs.form_address.reset();
      if (this.showEmployee || this.address.type == "tech") {
        this.$refs.employee_search.clear2();
      }
    },
    validCloneByUpdate() {
      const copy = JSON.parse(this.copy_address);
      const origin = JSON.parse(JSON.stringify(this.address));
      let notSame = [];
      for (var key in origin) {
        if (key != "sector" && key != "geo_location") {
          // eslint-disable-next-line no-console
          if (origin[key] != copy[key]) {
            notSame.push({ key: origin[key] != copy[key] });
          }
        }
      }
      setTimeout(() => {
        if (notSame.length && notSame.length > 0) {
          this.updateAddress();
        } else {
          this.$emit("address", this.address, "same");
          this.$awn.closeToasts();
          this.$awn.warning(this.$t("DSB.profile.nothing_to_change"), {
            durations: { warning: 4000 }
          });
        }
      }, 5);
    },
    createAddress() {
      const url = this.$store.getters.getContractAddresses();
      this.loadingForm = true;
      this.$emit("loading", true);
      axios
        .post(url, this.address)
        .then(response => {
          if (response.status == 200) {
            this.$emit("address", this.address, "update");
            this.$emit(
              "notify",
              this.$tc("created", 1),
              this.$tc("subscribers.address", 2)
            );
            this.pushNoty("subscribers.address", "created");
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loadingForm = false;
            }, 1500);
          } else {
            this.loadingForm = false;
            this.$emit("loading", false);
          }
        });
    },
    updateAddress() {
      this.$awn.closeToasts();
      const url = this.$store.getters.getContractAddresses(this.address.id);
      this.loadingForm = true;
      this.$emit("loading", true);
      axios
        .patch(url, this.address, { params: { no_geo_validate: "aragon" } })
        .then(response => {
          if (response.status == 204) {
            //
          }
          if (response.status == 200) {
            this.$emit("address", this.address, "update");
            this.$emit(
              "notify",
              this.$tc("updated", 1),
              this.$tc("subscribers.address", 2)
            );
            this.pushNoty("subscribers.address", "updated");
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.$emit("loading", false);
              this.loadingForm = false;
            }, 1500);
          } else {
            this.loadingForm = false;
            this.$emit("loading", false);
          }
        });
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: this.$tc(model, pos),
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
    getAddress() {
      // eslint-disable-next-line no-console
      // console.log('address', this.same_as_previous)
      if (this.same_as_previous) {
        this.init_address = true;
        this.address = Object.assign({}, this.same_as_previous);
        if (this.address.fiscal_address) {
          this.uses_fiscal = true
        }
        // Geolocation
        if (this.same_as_previous.geo_location) {
          this.address.lat = this.same_as_previous.geo_location.coordinates[1];
          this.address.lon = this.same_as_previous.geo_location.coordinates[0];
        } else {
          this.address.lat = null;
          this.address.lon = null;
        }
        // Sector
        const sector = this.same_as_previous.sector;
        if (sector) {
          this.validLocalization = sector.franchise_id ? false : true;
        } else {
          this.validLocalization = false;
        }
        // Copy Address
        this.copy_address = JSON.stringify(Object.assign(this.address));
        // Get Lists
        this.getListOf("municipalities", this.same_as_previous.state_id);
        this.getListOf("parishes", this.same_as_previous.municipality_id);
        this.getListOf("sectors", this.same_as_previous.parish_id);
        setTimeout(() => {
          this.init_address = false;
        }, 500);
      } else if (!this.editMode) {
        this.address.contract_id = this.contractId;
        if (!this.$store.getters.isFranchise) {
          this.validLocalization = true;
        } else {
          this.validLocalization = false;
        }
      }
    },
    getListOf(type, id = null) {
      let type_search = type;
      let url = "";
      let getList = false;
      if (type_search) {
        if (type_search == "countries") {
          this.show = {
            countries: true,
            states: true,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.countries = true;
          this.address.state_id = null;
          this.address.municipality_id = null;
          this.address.parish_id = null;
          this.address.sector_id = null;
          getList = true;
          url = this.$store.getters.getAllCountries;
        } else if (type_search == "states" && id != null && id != undefined) {
          this.show = {
            countries: false,
            states: true,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.states = true;
          this.address.municipality_id = null;
          this.address.parish_id = null;
          this.address.sector_id = null;
          getList = true;
          url = this.$store.getters.getStates(id);
        } else if (
          type_search == "municipalities" &&
          id != null &&
          id != undefined
        ) {
          this.show = {
            countries: false,
            states: false,
            municipalities: true,
            parishes: true,
            sectors: true
          };
          this.load.municipalities = true;
          this.address.municipality_id = null;
          this.address.parish_id = null;
          this.address.sector_id = null;
          getList = true;
          url = this.$store.getters.getMunicipalities(id);
        } else if (type_search == "parishes" && id != null && id != undefined) {
          this.show = {
            countries: false,
            states: false,
            municipalities: false,
            parishes: true,
            sectors: true
          };
          this.load.parishes = true;
          this.address.parish_id = null;
          this.address.sector_id = null;
          getList = true;
          url = this.$store.getters.getParishes(id);
        } else if (type_search == "sectors" && id != null && id != undefined) {
          this.load.sectors = true;
          this.address.sector_id = null;
          getList = true;
          url = this.$store.getters.getSectorsByParish(id);
        } else {
          getList = false;
        }
        // Get List
        if (getList) {
          // this.printAll();
          this.List(url, type_search);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log("Not type", type_search);
      }
    },
    List(url, type_search) {
      axios
        .get(url, {
          params: {
            get_all: true
          }
        })
        .then(response => {
          // eslint-disable-next-line no-console
          // console.log(type_search,response.data[type_search]);
          this[type_search] = response.data[type_search];
          if (
            this.same_as_previous != undefined &&
            this.same_as_previous != null &&
            this.init_address
          ) {
            this.address.country_id = Number(this.same_as_previous.country_id);
            this.address.state_id = Number(this.same_as_previous.state_id);
            this.address.municipality_id = Number(
              this.same_as_previous.municipality_id
            );
            this.address.parish_id = Number(this.same_as_previous.parish_id);
            this.address.sector_id = Number(this.same_as_previous.sector_id);
          }
        })
        .catch(error => {
          this[type_search] = [];
          // eslint-disable-next-line no-console
          console.log(error);
        })
        .finally(() => {
          this.show[type_search] = false;
          this.load[type_search] = false;
        });
    },
    clearParish() {
      this.address.sector_id = null;
    }
  },
  watch: {
    uses_fiscal (val) {
      if (!val) {
        this.address.fiscal_address = null
      }
    }
  },
  mounted() {
    this.getPermissions()
    this.getListOf("countries");
    this.getListOf("states", this.same_as_previous.country_id);
    this.getAddress();
  }
};
</script>
