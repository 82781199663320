var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.computeTitle}})]},proxy:true},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" "+_vm._s(_vm._f("formatAmount")(item.amount))+" ")]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format(_vm.$store.getters.getDateFormat[2]))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.ShowPaymen(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])]}}],null,true)})]],2)],1),_c('PreView',{ref:"pre_view"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }