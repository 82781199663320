import FeasibilityDialogForm from './FeasibilityDialog.vue'

export default {
  name: 'FeasibilityReport',
  components:{
    FeasibilityDialogForm
  },
  data(){
    return {
      contracts:[],
      totalValues: 0,
      items: [],
      loading: false,
      loadingTable: false,
      nodata: true,
      edit: false,
      expand: false,
      tab_val: 'tab-1' ,
      filter: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      dialog_overlay: false,
      policies:{
        show:false,
      }
    };
  },
  methods:{
    expandIt(){
      this.expand = true
      let obj = this
      setTimeout(function() {
        obj.$refs.search.focus()
      }, 400)
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.options.page = 1 
            this.getFeasibilities(true)
          }
        })
        .catch(error => {
          //
        })
    },
    async getFeasibilities(is_filter){
      this.loadingTable = true
      this.contracts = []
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage, 
          'page': this.options.page,
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        let validation = await this.$validator.validate('filter', this.filter)
        if (validation) {
          ops.params.filter = this.filter
        } else {
          this.loadingTable = false
          this.options.page = 1
          return false
        }
      }

      let url = this.tab_val == 'tab-1' ? this.$store.getters.getFeasibilities() :
        this.tab_val == 'tab-2' ? this.$store.getters.getFeasibilitiesReport('active') : 
         this.$store.getters.getFeasibilitiesReport('inactive')
 
      axios.get(url, ops)
        .then(response => {
          this.loadingTable = false
          this.nodata = false
          let self = response.data
          this.contracts = self[this.tab_val == 'tab-1' ? 'contracts' :
          this.tab_val == 'tab-2' ? 'reportsactive' : 
           'reportsclosed'].data
          this.totalValues = self[this.tab_val == 'tab-1' ? 'contracts' :
            this.tab_val == 'tab-2' ? 'reportsactive' : 
            'reportsclosed'].total
        })
        .catch(err=>{
          this.loadingTable = false
          this.contracts = []
          this.totalValues = 0
          this.nodata = true
        })
    },
    viewDetails(id){
      this.$refs.details_dialog.open(id,this.tab_val)
    },
    close(){
      this.setClear()
    },
    setClear(){
      this.loading = false
      this.$validator.reset()
    },
    PoliciesOpticalFiber(){
      let policies = [
        'index_subscriberFileLibrary',
      ];
      if (this.tab_val == 'tab-1') {
        policies.push('store_feasibilityReport');
      }
      if (this.tab_val == 'tab-2') {
        policies.push('show_feasibilityReport');
        policies.push('update_feasibilityReport');
        policies.push('changestatus_feasibilityReport');
        policies.push('infeasibleService_feasibilityReport');
      }
      if (this.tab_val == 'tab-3') {
        policies.push('show_feasibilityReport');
      }
      this.policies.show = this.getPermissionGroup({
        permission:policies,
        mode:'all'
      });
    },
  },
  watch:{
    tab_val(){
      this.PoliciesOpticalFiber();
      this.expand = false;
      this.options.page = 1;
      this.getFeasibilities(this.expand);
    },
    options: {
      handler () {
        this.getFeasibilities(this.expand);
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = '';
        this.$validator.reset();
        this.options.page = 1;
        this.getFeasibilities(false);
      }
    }
  },
  computed:{
    headers(){
      let headers = []
      this.tab_val == 'tab-1' ?
        headers = [
          {
            text: this.$tc('base.code', 3),
            align: 'left',
            value: 'id',
            width: 86
          },
          {
            text: this.$t('company.identification'),
            align: 'left',
            value: 'doc',
            width: 140
          },
          { 
            text: this.$t('subscribers.legal_denomination'), 
            value: 'legal_denomination',
          }
        ]
        :
        headers = [
          {
            text: this.$tc('base.code', 3),
            align: 'left',
            value: 'contract_id',
            width: 86
          },
          {
            text: this.$t('company.identification'),
            align: 'left',
            value: 'contract.subscriber.doc',
            width: 140
          },
          { 
            text: this.$t('subscribers.legal_denomination'), 
            value: 'contract.subscriber.legal_denomination',
          }
        ]

      if(this.tab_val == 'tab-3'){
        headers.push({
          text: this.$t('state'),
          value: 'status'
        });
      }
      if (this.policies.show) {
        headers.push({ 
          text: this.$t('action'), 
          value: 'action', 
          sortable: false ,
          align: 'right',
          width: 120
        });
      }
      return headers;
    } 
  },
  mounted() {
    this.PoliciesOpticalFiber();
  },
};
