export default {
  name: "BroadcastNotificationForm",
  props: {
    //
  },
  data (){
    return {
      disable_select_franchises: false,
      disable_select_users: false,
      force_enable_select_users: false,
      franchises_list: [],
      users_list: [],
      loadingFranchises: false,
      loadingUsers: false,
      empty_notification: {
        type: 'upgrade',
        text: '',
        to_everybody: false,
        franchises_ids: [],
        users_ids: []
      },
      new_notification: {
        type: 'upgrade',
        text: '',
        to_everybody: false,
        franchises_ids: [],
        users_ids: []
      },
      searchFranchise: null,
      searchUser: null,
      unwatch: '',
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'abc'){
        //
      }
    })
  },
  mounted () {
    //
  },
  computed: {
    //
  },
  methods: {
    loadLists () {
      this.clear()
      this.getFranchises()
      this.getUsers()
    },
    getFranchises (filter = null) {
      this.franchises_list = []
      this.loadingFranchises = true

      let ops = {
        params:{
          page: 1,
          paginate: 0,
          select_only: 1
        }
      }

      if (filter) {
        ops.params.search = filter
      }

      axios.get(this.$store.getters.getFranchises(), ops)
        .then(response => {
          this.franchises_list = response.data.franchises.data;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
        .finally(() => {
          this.loadingFranchises = false;
        });
    },
    getUsers (filter = null, by_franchises = null) {
      this.users_list = []
      this.loadingUsers = true

      let ops = {
        params:{
          page: 1,
          paginate: 10,
          sort_by: 'id'
        }
      }

      if (filter) {
        ops.params.email = filter
      }

      if (by_franchises) {
        ops.params.franchises_ids = by_franchises
      }

      axios.get(this.$store.getters.actorsSources(), ops)
        .then(response => {
          if (response.data.success.total > 0) {
            this.users_list = response.data.success.data.map(actor => {
              return {
                value: actor.user.id,
                text: `${actor.user.email} (${actor.user.name})`
              };
            });
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    filterUsersByFranchise () {
      this.force_enable_select_users = !this.force_enable_select_users
      if (this.force_enable_select_users) {
        this.getUsers(null, this.new_notification.franchises_ids)
      }
    },
    broadcastNotification () {
      this.$refs.form_broadcast.validate()
        .then(async(valid) => {
          if (valid) {
            this.$emit('loading', true)
            await axios.post(this.$store.getters.broadcastNotification, this.new_notification)
              .then((response) => {
                if (response.data && response.data.receptor_count) {
                  let count = response.data.receptor_count
                  let pos = count == 1 ? 1 : 2
                  this.$awn.success(this.$tc('base.broadcasted', pos, [count]), {durations:{alert: 40000}})
                  this.clear()
                  this.$emit('notificationSent')
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err)
              })
              .finally(() => {
                this.$emit('loading', false)
              })
          }
        })
    },
    clearUsersSelect () {
      this.force_enable_select_users = false
      this.new_notification.users_ids = []
    },
    clear () {
      this.force_enable_select_users = false
      this.new_notification = Object.assign({}, this.empty_notification)
      this.$refs.form_broadcast.reset()
    }
  },
  watch: {
    force_enable_select_users (val) {
      if (!val) {
        this.new_notification.users_ids = []
      }
    },
    'new_notification.to_everybody': {
      handler (val) {
        this.disable_select_franchises = val
        this.disable_select_users = val
      }
    },
    'new_notification.franchises_ids': {
      handler (val) {
        if (val.length > 0) {
          this.disable_select_users = true
        } else {
          if (!this.new_notification.to_everybody) {
            this.disable_select_users = false
          }
        }
      }
    },
    'new_notification.users_ids': {
      handler (val) {
        if (val.length > 0) {
          if (!this.force_enable_select_users) {
            this.disable_select_franchises = true
          }
        } else {
          if (!this.new_notification.to_everybody) {
            this.disable_select_franchises = false
          }
        }
      }
    },
    disable_select_franchises (val) {
      if (val) {
        this.new_notification.franchises_ids = []
      }
    },
    disable_select_users (val) {
      if (val) {
        this.new_notification.users_ids = []
      }
    },
    searchFranchise (val) {
      if (this.loadingFranchises) return
      if (val && val.length > 2) this.getFranchises(val)
    },
    searchUser (val) {
      if (this.loadingUsers) return
      if (val && val.length > 2) this.getUsers(val)
    }
  },
  beforeDestroy () {
    this.clear()
    this.unwatch()
  }
}
