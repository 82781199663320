import './axiosDeclared.js'
import {i18n} from './locale.js'
import Vue from 'vue'

export default {
  state:{
    show_confirmation: false,
    confirmation_title: '',
    confirmation_pre: 'success',
    confirmation_position: 1,
    confirmation_model: '',
    confirmation_action: '',
    confirmation_type: '',
    confirmation_content: '',
    // Confirmation acceptance
    confirmation_acceptance: {},
    confirmation_finished: false,
    confirmation_loading: false,
    confirmation_template: null
  },
  getters:{
    getConfirmationDisplay: (state) => {
      return state.show_confirmation
    },
    getConfirmationTitle: (state) => {
      return state.confirmation_title
    },
    getConfirmationPre: (state) => {
        return state.confirmation_pre
    },
    getConfirmationPosition: (state) => {
        return state.confirmation_position
    },
    getConfirmationModel: (state) => {
        return state.confirmation_model
    },
    getConfirmationFinished: (state) => {
      return state.confirmation_finished
    },
    getConfirmationAction: (state) => {
      return state.confirmation_action
    },
    getConfirmationContent: (state) => {
      return state.confirmation_content
    },
    getConfirmationType: (state) => {
      return state.confirmation_type
    },
    getConfirmationAcceptance: (state) => {
      return state.confirmation_acceptance
    },
    getConfirmationLoading: (state) => {
      return state.confirmation_loading
    },
    getConfirmationTemplate: (state) => {
      return state.confirmation_template
    }
  },
  mutations:{
    confirmationValue(state,value){
      state.show_confirmation = value.show
      if(typeof(value) == 'boolean' && !value){
        setTimeout(()=>{// Delays the new snakbar a few milliseconds
          state.confirmation_pre = 'success'
          state.confirmation_position = 1
          state.confirmation_model = ''
          state.confirmation_title = ''
          state.confirmation_action = ''
          state.confirmation_type = ''
          state.confirmation_content = ''
          state.confirmation_acceptance = {}
          state.confirmation_finished = false
          state.confirmation_template = null
        },400)
      }
      else{
        state.confirmation_title = value.title
        state.confirmation_pre = value.pre != undefined ? value.pre : 'success'
        state.confirmation_position = value.pos
        state.confirmation_model = value.model
        state.confirmation_action = value.action
        state.confirmation_type = value.type
        state.confirmation_content = value.content
        state.confirmation_acceptance = value.confirm
        state.confirmation_finished = false
        state.confirmation_template = value.template
      }
    },
    confirmationLoading(state,value){
      state.confirmation_loading = value
    },
    confirmationFinished(state,value){
      state.confirmation_finished = value
    },
  },
  actions:{
    confirmationValue({commit},value){
      commit('confirmationLoading',false)
      commit('confirmationValue',value)
    },
    forceConfirmationFinish({commit}){
      commit('confirmationFinished',true)
      commit('confirmationValue',false)
    },
    acceptConfirmation({commit,dispatch,getters}){
      commit('confirmationLoading',true)
      let confirmpromise = async () => {
        let response = await axios(getters.getConfirmationAcceptance)
        if(response.status == 200){
          commit('confirmationFinished',true)
          // New
          Vue.prototype.$awn.success(i18n.tc(
            getters.getConfirmationPre,
            getters.getConfirmationPosition,
            {
              model: getters.getConfirmationModel,
              action: getters.getConfirmationAction
            }
          ),
          {durations:{success: 4000}})
          // Old
          /*dispatch('pushNotification', {
            snackbar: true,
            style: 'success',
            mode: null,
            timeout: 4000,
            notifText: getters.getConfirmationPre,
            notifPos: getters.getConfirmationPosition,
            extra: {
              model: getters.getConfirmationModel,
              action: getters.getConfirmationAction
            }
          })*/
        }
        commit('confirmationValue',false)
      }
      confirmpromise().catch((err) => {
        commit('confirmationValue',false)
      })
    }
  }
}
