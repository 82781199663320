<template>
  <v-dialog
    v-model="dialog_force"
    scrollable
    :fullscreen="fullscreen"
    transition="dialog-transition"
  >
    <v-card tile>
      <toolbarDialog
        v-if="dialog_force"
        :showOnlyTitle="'otro'"
        :title="title"
        :screen="fullscreen"
        @close="close"
      />
      <v-card-text class="pt-2 pb-0 px-3">
        <toolbarTable
          showNew
          :nameButton="$t('base.select')"
          iconButton="fas fa-exchange-alt"
          colorButton="primary"
          C:title="servPack"
          @newItem="newItem"
        />
        <v-container fluid py-0>
          <v-row>
            <v-col cols="12" sm="8" md="6" class="py-0 mx-auto mx-lg-0">
              <div
                class="headline font-weight-black"
                style="background: #f7f7f7;border:1px solid transparent;"
              >
                {{
                  details.type == "package"
                    ? $t("package.current")
                    : $t("service.current")
                }}
              </div>
              <ServiceCard v-if="dialog_force" :item="details" :elevation="3" />
            </v-col>
            <v-col cols="12" sm="8" md="6" class="pt-6 pt-md-0 mx-auto mx-lg-0" v-if="showService">
              <div
                class="headline font-weight-black"
                style="background: #f7f7f7;border:1px solid transparent;"
              >
                {{
                  details.type == "package"
                    ? $t("package.new")
                    : $t("service.new")
                }}
              </div>
              <ServiceCard :item="newServPack" :elevation="3" />
            </v-col>
          </v-row>
          <!-- <pre>{{ newServPack }}</pre> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="!fullscreen"
          text
          cancel
          @click="close"
          :disabled="loading"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
            $t("base.cancel")
          }}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save :loading="loading" :disabled="!showService" @click="save">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
            $t("base.change")
          }}</span>
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
      <!--  -->
      <SelectedService
        v-if="dialog_force"
        ref="selectedserv"
        sendPack
        onlyConfig
        :servPack_id="servPack_id"
        :serv_or_pack="details.type"
        @selectedServPack="selectedServPack"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import ServiceCard from "./ServiceCard.vue";
import SelectedService from "../Package/SelectedService.vue";
export default {
  name: "force-change-service-or-package",
  components: {
    ServiceCard,
    SelectedService
  },
  props: {
    contract_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialog_force: false,
      showService: false,
      loading: false,
      fullscreen: true,
      details: {},
      newServPack: {},
      servPack_id: ""
    };
  },
  computed: {
    servPack() {
      return this.details.type == "package"
        ? this.$tc("package.package", 2)
        : this.$tc("service.service", 1);
    },
    title() {
      if (this.details.type) {
        const pack =
          this.details.type == "package"
            ? this.$tc("package.package", 2)
            : this.$tc("service.service", 1);
        return this.$t("base.change") + " " + pack;
      } else {
        return "";
      }
    }
  },
  methods: {
    open(data) {
      // eslint-disable-next-line no-console
      // console.log("data", data);
      this.servPack_id = data.service_id ? data.service_id : data.id;
      this.details = data;
      this.dialog_force = true;
      this.fullscreen = true;
    },
    close() {
      this.showService = false;
      this.fullscreen = false;
      this.dialog_force = false;
      this.newServPack = {};
      this.details = {};
    },
    newItem() {
      this.$refs.selectedserv.open(this.details.type, this.contract_id);
    },
    selectedServPack(data) {
      this.showService = true;
      // eslint-disable-next-line no-console
      // console.log("selected", data);
      data.type = this.details.type == "package" ? "package" : "service";
      data.status = "activation_in_progress";
      if (data.type == "package") {
        let price = 0;
        data.service.map(service => {
          let name = service.parent != undefined
              ? `${service.service.parent.name} ${service.service.name}`
              : `${service.service.name}`;
          service.name = name;
          service.service_param.map(servParam => {
            servParam.qty = Number(servParam.qty);
            let servPrice =
              Number(servParam.qty) * Number(servParam.param.price);
            servParam.price = servPrice;
            price += servPrice;
          });
        });
        data.services = data.service;
        data.price = price;
      } else {
        let name = data.parent != undefined
              ? `${data.parent.name} ${data.name}`
              : `${data.name}`;
        data.name = name;
      }
      setTimeout(() => {
        this.newServPack = data;
      }, 100);
    },
    save() {
      let ids = 0
      if (this.details.type == 'package') {
        ids = this.details.services.map(a => a.id)
      } else {
        ids = [this.details.id]
      }

      this.loading = true
      axios.put(this.$store.getters.overrideContractService(ids), this.newServPack)
        .then((response)=>{
          if(response.data.success){
            // Launch AWN
            this.$awn.success(
              this.$tc('success', 1,
                {
                  model: this.$tc('service.service',1),
                  action: this.$tc('changed',1)
                }
              )
            , {durations:{alert: 4000}})
            // Refresh Service Cards
            this.$emit('updateServPack')
            // Close ForceChange dialog
            this.close()
          }
        }).catch((err)=>{
          // eslint-disable-next-line no-console
          console.log(err)
        }).finally(()=>{
          this.loading = false
        })
    }
  }
};
</script>
