var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classParent},[(_vm.permissions.setGroup)?_c('SubscribersGroupsBtn',{key:_vm.group_key,attrs:{"group":_vm.subscriber.group,"fabNudged":_vm.fab_nudged},on:{"update:group":function($event){return _vm.$set(_vm.subscriber, "group", $event)},"setGroupIdTwo":_vm.setSubscribersGroupsID}}):_vm._e(),_c('ValidationObserver',{ref:"form_subscriber",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"subscriber-form",attrs:{"data-vv-scope":"subscriber"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',{staticClass:"subscriber-row-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"subscriber-avatar",attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',_vm._g({staticClass:"custom-badge",attrs:{"overlap":"","right":"","color":"error darken-2","value":_vm.av_image != ''}},on),[_c('v-btn',{attrs:{"slot":"badge","icon":"","x-small":"","dark":"","size":"1"},on:{"click":_vm.dropImg},slot:"badge"},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" fas fa-times ")])],1),_c('v-avatar',{staticClass:"pointer",attrs:{"size":"100","tile":_vm.av_image != ''},on:{"click":_vm.clickFile}},[_c('img',{attrs:{"src":_vm.av_image != ''
                        ? _vm.av_image
                        : _vm.same_as_previous &&
                          _vm.same_as_previous.gender &&
                          _vm.same_as_previous.gender == 'F'
                        ? require("@/assets/avatar_female.png")
                        : require("@/assets/avatar_male.png"),"alt":"Avatar"}})])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("attach"))+" "+_vm._s(_vm.$t("image"))+" ")])]),_c('v-file-input',{ref:"avtr",staticClass:"d-none",attrs:{"dense":"","accept":"image/*"},on:{"change":_vm.displayPreview}})],1),_c('v-col',{staticClass:"subscriber-type",attrs:{"cols":"12","sm":"9"}},[_c('v-row',{staticClass:"subscriber-typeclient-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":_vm.showCode ? '5' : '6',"md":_vm.showCode ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"client_type","name":_vm.$t('DSB.menu.type_client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{style:(_vm.$vuetify.breakpoint.width > 600
                      ? 'max-width: 189px;'
                      : 'max-width: 100%;'),attrs:{"items":_vm.client_types,"name":"client_type","prepend-icon":"fas fa-list","item-text":"name","item-value":"id","menu-props":"auto","clearable":_vm.modeNode == 'development',"label":_vm.$t('DSB.menu.type_client'),"error-messages":errors,"disabled":_vm.loadingTypeClient || _vm.loading,"loading":_vm.loadingTypeClient || _vm.loading},model:{value:(_vm.subscriber.type_client_id),callback:function ($$v) {_vm.$set(_vm.subscriber, "type_client_id", $$v)},expression:"subscriber.type_client_id"}})]}}],null,true)})],1),(_vm.showCode)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{ref:"subscriber_code",attrs:{"vid":"code","name":_vm.$tc('subscribers.code', 1),"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"subscribers.code","prepend-icon":"fas fa-id-card","label":_vm.$tc('subscribers.code', 1),"error-messages":errors,"disabled":_vm.loading || (_vm.editMode && !_vm.permissions.editCode),"loading":_vm.loading},on:{"focus":function($event){_vm.comparisonCode = _vm.subscriber.code},"blur":_vm.checkIfCodeExists},model:{value:(_vm.subscriber.code),callback:function ($$v) {_vm.$set(_vm.subscriber, "code", $$v)},expression:"subscriber.code"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0)?_c('v-col',{attrs:{"cols":"12","sm":_vm.showCode ? '3' : '6',"md":_vm.showCode ? '4' : '6'}},[_c('v-checkbox',{staticClass:"small-label float-right",attrs:{"dense":"","hide-details":"","label":_vm.$t('subscribers.is_firm'),"color":"primary","disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.is_firm),callback:function ($$v) {_vm.is_firm=$$v},expression:"is_firm"}})],1):_vm._e()],1),_c('v-row',{staticClass:"subscriber-typeclient-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"subscriber-dni"},[_c('ValidationProvider',{attrs:{"vid":"prefix","name":_vm.$tc('subscribers.prefix', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{ref:"subscriber_prefix",staticClass:"susbcriber-prefix",staticStyle:{"max-width":"189px"},attrs:{"items":_vm.prefixes,"name":"prefix","prepend-icon":"fas fa-list","menu-props":"auto","clearable":_vm.modeNode == 'development',"label":_vm.$tc('subscribers.prefix', 1),"error-messages":errors,"disabled":_vm.loading || _vm.computedPrefix || _vm.validCode,"loading":_vm.loadingPrefix || _vm.loading},on:{"change":_vm.prefixChange},model:{value:(_vm.subscriber.prefix),callback:function ($$v) {_vm.$set(_vm.subscriber, "prefix", $$v)},expression:"subscriber.prefix"}})]}}],null,true)}),_c('ValidationProvider',{ref:"subscriber_dni",attrs:{"vid":"dni","name":!_vm.is_firm
                    ? _vm.$tc('subscribers.dni', _vm.dni_type)
                    : _vm.$tc('subscribers.dni', 1),"rules":{
                  required: true,
                  numeric: true,
                  min: _vm.dni_type == 1 || _vm.is_firm ? 6 : 5,
                  max: _vm.max_length
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"dni","prepend-icon":"fas fa-address-card","counter":"","maxlength":_vm.max_length,"label":!_vm.is_firm
                      ? _vm.$tc('subscribers.dni', _vm.dni_type)
                      : _vm.$tc('subscribers.dni', 1),"error-messages":errors,"disabled":_vm.loading || _vm.computedPrefix || !_vm.subscriber.prefix || _vm.validCode,"loading":_vm.loading},on:{"focus":_vm.focusDNI,"blur":_vm.subscriberExists},model:{value:(_vm.subscriber.dni),callback:function ($$v) {_vm.$set(_vm.subscriber, "dni", $$v)},expression:"subscriber.dni"}})]}}],null,true)})],1)],1)],1)],1),_c('v-row',{staticClass:"subscriber-row-2",attrs:{"no-gutters":""}},[(_vm.dni_type == 1)?_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"legal_denomination","name":_vm.$t('subscribers.legal_denomination'),"rules":{
              required: true,
              regex: /^[0-9A-Za-zÁÉÍÓÚÑáéíóúñ.&,@+'`_\- ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{key:"legal_denomination",attrs:{"name":"legal_denomination","prepend-icon":"fas fa-user","counter":"","maxlength":"140","label":_vm.$t('subscribers.legal_denomination'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.subscriber.legal_denomination),callback:function ($$v) {_vm.$set(_vm.subscriber, "legal_denomination", $$v)},expression:"subscriber.legal_denomination"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0 && _vm.is_firm)?_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"firm_name","name":_vm.$t('subscribers.firm_name'),"rules":{
              required: _vm.is_firm,
              regex: _vm.is_firm ? /^[0-9A-Za-zÁÉÍÓÚÑáéíóúñ.&,@+'`_\- ]+$/ : ''
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{key:"firm_name",attrs:{"name":"firm_name","prepend-icon":"fas fa-file-alt","counter":"","maxlength":"140","label":_vm.$t('subscribers.firm_name'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.subscriber.firm_name),callback:function ($$v) {_vm.$set(_vm.subscriber, "firm_name", $$v)},expression:"subscriber.firm_name"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0)?_c('v-col',{staticClass:"d-flex"},[(_vm.dni_type == 0 && _vm.is_firm)?_c('div',{staticClass:"pa-0 pt-5 font-weight-bold text-right",attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.$t("of"))+": ")]):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"first_name","name":_vm.$t('subscribers.first_name'),"rules":{
              required: true,
              regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{key:"first_name",attrs:{"name":"first_name","prepend-icon":"fas fa-user","counter":"","maxlength":"40","label":_vm.$t('subscribers.first_name'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateForm()}},model:{value:(_vm.subscriber.first_name),callback:function ($$v) {_vm.$set(_vm.subscriber, "first_name", $$v)},expression:"subscriber.first_name"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0)?_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"last_name","name":_vm.$t('subscribers.last_name'),"rules":{
              required: true,
              regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{key:"last_name",attrs:{"name":"last_name","prepend-icon":"fas fa-user","counter":"","maxlength":"40","label":_vm.$t('subscribers.last_name'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateForm()}},model:{value:(_vm.subscriber.last_name),callback:function ($$v) {_vm.$set(_vm.subscriber, "last_name", $$v)},expression:"subscriber.last_name"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0 && !_vm.is_firm)?_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"gender","name":_vm.$t('subscribers.gender'),"rules":{
              required: _vm.dni_type == 0 && !_vm.is_firm
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{key:"gender",staticClass:"subscriber-gender",attrs:{"items":_vm.genders,"name":"gender","prepend-icon":"fas fa-venus-mars","menu-props":"auto","label":_vm.$t('subscribers.gender'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.subscriber.gender),callback:function ($$v) {_vm.$set(_vm.subscriber, "gender", $$v)},expression:"subscriber.gender"}})]}}],null,true)})],1):_vm._e(),(_vm.dni_type == 0 && !_vm.is_firm)?_c('v-col',[_c('v-dialog',{ref:"dialog_date_menu",attrs:{"return-value":_vm.subscriber.birthdate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.subscriber, "birthdate", $event)},"update:return-value":function($event){return _vm.$set(_vm.subscriber, "birthdate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('ValidationProvider',{staticClass:"d-block",attrs:{"vid":"birthdate","name":_vm.$tc('subscribers.birthdate', 1),"rules":{
                  required: _vm.dni_type == 0 && !_vm.is_firm
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"name":"birthdate","prepend-icon":"fas fa-calendar","readonly":"","persistent-hint":"","hint":_vm.$store.getters.getDateFormat[0],"label":_vm.$tc('subscribers.birthdate', 1),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1920-01-01","max":_vm.years_18_ago},on:{"input":function($event){_vm.date_menu = false;
                _vm.$refs.dialog_date_menu.save(_vm.subscriber.birthdate);}},model:{value:(_vm.subscriber.birthdate),callback:function ($$v) {_vm.$set(_vm.subscriber, "birthdate", $$v)},expression:"subscriber.birthdate"}})],1)],1):_vm._e()],1),(_vm.$store.getters.isFranchise && _vm.showContacFields)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"vid":"telephone","name":_vm.$t('phone'),"rules":{
              digits: 11
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"telephone","prepend-icon":"fas fa-phone fa-rotate-180","counter":"","maxlength":"11","label":_vm.$t('phone'),"error-messages":errors},model:{value:(_vm.franchise_contact.local_phone),callback:function ($$v) {_vm.$set(_vm.franchise_contact, "local_phone", $$v)},expression:"franchise_contact.local_phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"vid":"cellphone","name":_vm.$t('cellphone'),"rules":"required|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"cellphone","prepend-icon":"fas fa-mobile-alt","counter":"","maxlength":"11","label":_vm.$t('cellphone'),"error-messages":errors},model:{value:(_vm.franchise_contact.cellphone),callback:function ($$v) {_vm.$set(_vm.franchise_contact, "cellphone", $$v)},expression:"franchise_contact.cellphone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('base.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","prepend-icon":"fas fa-envelope","counter":"","maxlength":"140","label":_vm.$t('base.email'),"error-messages":errors},model:{value:(_vm.franchise_contact.email),callback:function ($$v) {_vm.$set(_vm.franchise_contact, "email", $$v)},expression:"franchise_contact.email"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.dni_type == 1 || _vm.is_firm),expression:"dni_type == 1 || is_firm"}],staticClass:"subscriber-row-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"subscriber-agent-ret",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-switch',{staticClass:"small-label",attrs:{"dense":"","label":_vm.$t('subscribers.agent_ret'),"color":"primary","hide-details":"","disabled":_vm.dni_type != 1 && !_vm.is_firm},on:{"change":function($event){return _vm.agentChange(_vm.subscriber.agent_ret)}},model:{value:(_vm.subscriber.agent_ret),callback:function ($$v) {_vm.$set(_vm.subscriber, "agent_ret", $$v)},expression:"subscriber.agent_ret"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.subscriber.agent_ret && (_vm.dni_type == 1 || _vm.is_firm)),expression:"subscriber.agent_ret && (dni_type == 1 || is_firm)"}],staticClass:"py-0",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"vid":"tax_rate","name":_vm.$t('subscribers.tax_rate'),"rules":_vm.showValidTaxRate ? 'dontallowzero' :''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{ref:"tax_rate",attrs:{"items":_vm.computedIvaItems,"name":"tax_rate","prepend-icon":"fas fa-coins","label":_vm.$t('subscribers.tax_rate'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading,"clearable":_vm.modeNode == 'development'},on:{"change":_vm.ValidAllTax},model:{value:(_vm.subscriber.tax_rate),callback:function ($$v) {_vm.$set(_vm.subscriber, "tax_rate", $$v)},expression:"subscriber.tax_rate"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.subscriber.agent_ret && (_vm.dni_type == 1 || _vm.is_firm)),expression:"subscriber.agent_ret && (dni_type == 1 || is_firm)"}],staticClass:"py-0",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"vid":"tax_sale","name":_vm.$t('subscribers.tax_sale'),"rules":{ 'dontallowzero': _vm.showValidTaxSale }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{ref:"tax_sale",attrs:{"items":_vm.computedIslrItems,"name":"tax_sale","prepend-icon":"fas fa-coins","label":_vm.$t('subscribers.tax_sale'),"error-messages":errors,"disabled":_vm.loading,"loading":_vm.loading,"clearable":_vm.modeNode == 'development'},on:{"change":_vm.ValidAllTax},model:{value:(_vm.subscriber.tax_sale),callback:function ($$v) {_vm.$set(_vm.subscriber, "tax_sale", $$v)},expression:"subscriber.tax_sale"}})]}}],null,true)})],1)],1),(_vm.modeNode == 'development')?_c('v-row',{staticClass:"subscriber-row-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',[_vm._v("IVA: "+_vm._s(_vm.subscriber.tax_rate))])]),_c('v-col',[_c('div',[_vm._v("ISRL: "+_vm._s(_vm.subscriber.tax_sale))])]),_c('v-col',[_c('div',[_vm._v("agent_ret: "+_vm._s(_vm.subscriber.agent_ret))])]),_c('v-col',[_c('div',[_vm._v("showValidTaxRate: "+_vm._s(_vm.showValidTaxRate))])]),_c('v-col',[_c('div',[_vm._v("showValidTaxSale: "+_vm._s(_vm.showValidTaxSale))])]),_c('v-col',[_c('div',[_vm._v("isFranchise: "+_vm._s(_vm.$store.getters.isFranchise))])])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"caption font-weight-bold mt-7 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('subscribers.contact_medium'))+" ")]),_c('ValidationProvider',{attrs:{"vid":"contact_medium","name":_vm.$t('subscribers.contact_medium'),"rules":{ 'required': true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0 small-label",attrs:{"dense":"","row":"","name":"subscribers.contact_medium","data-vv-as":_vm.$t('subscribers.contact_medium'),"error-messages":errors},model:{value:(_vm.subscriber.contact_medium),callback:function ($$v) {_vm.$set(_vm.subscriber, "contact_medium", $$v)},expression:"subscriber.contact_medium"}},_vm._l((_vm.$t('subscribers.contact_mediums')),function(item,i){return _c('v-radio',{key:("cm-" + i),attrs:{"label":item,"value":i}})}),1)]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pt-md-6",attrs:{"cols":"7","md":"6"}},[(_vm.subscriber.contact_medium === 'others')?_c('ValidationProvider',{attrs:{"vid":"contact_medium","name":_vm.$t('subscribers.contact_mediums.others'),"rules":{ 'required': _vm.subscriber.contact_medium === 'others' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"","maxlength":"40","placeholder":_vm.$t('subscribers.contact_mediums.others'),"error-messages":errors},model:{value:(_vm.others_contact_medium),callback:function ($$v) {_vm.others_contact_medium=$$v},expression:"others_contact_medium"}})]}}],null,true)}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-4 pb-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"observations","no-resize":"","rows":"3","label":_vm.$t('subscribers.observations'),"data-vv-as":_vm.$t('subscribers.observations'),"disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.subscriber.observations),callback:function ($$v) {_vm.$set(_vm.subscriber, "observations", $$v)},expression:"subscriber.observations"}})],1)],1)],1)]}}])}),_c('ExistingSubscriber',{ref:"existing_subscriber",attrs:{"isMigrateSubscriber":_vm.isMigrate,"loading_fields":_vm.loading},on:{"cancelNewContract":_vm.cancelNewContract,"contract":_vm.NewContract}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }