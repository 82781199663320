export default {
  name: "Taxes",
  components:{},
  data() {
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      fullscreen:false,
      loadingTable:false,
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      tax:{
        category:'tax',
        description:{},
      },
      taxes:[],
      index: '',
      editedIndex:'',
      unwatch: '',
      search:'',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      permissions: {
        list: false,
        create: false,
        edit: false,
        delete: false
      }
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'Taxes'){
        this.getQuerys();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.getQuerys();
      },
      deep: true,
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('Taxes.name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('Taxes.value'),
          value: 'description.value',
          align: 'center'
        },
        {
          text: this.$t('Taxes.unit'),
          value: 'description.unit',
          align: 'center'
        },
        {
          text: this.$t('subscribers.updated_at'),
          value: 'updated_at',
          align: 'center',
          width: 120
        },
        {
          text: this.$t('action'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
          width: 130
        }
      ];
    },
    computedTaxCategories () {
      return [
        {value: 'tax', text:'Tax (I.V.A.)'},
        {value: 'constant_islr', text:'I.S.R.L.'},
        {value: 'constant_ut', text:'U.T.'},
        {value: 'other_taxes', text: this.$t('Taxes.other_taxes') },
      ]
    },
    computedDateFormatt () {
      return this.formatDate(this.today);
    },
    valueRules(){
      return [
        v => (v > 0) || this.$t('validation.positive_number'),
      ];
    }
  },
  methods:{
    getQuerys(){
      axios.get(this.$store.getters.getQueries)
      .then(response=>{
        this.taxes = response.data.catalog_elements.filter(tax =>{
          return ['tax', 'constant_ut', 'constant_islr', 'other_taxes'].includes(tax.category)
        });
        if (response.data.error) {
          const text = 'error422.'+response.data.error.error[0];
          this.$awn.alert(this.$tc(text, 0, []), {durations:{warning: 6000}})
        }
        // eslint-disable-next-line no-console
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      });
    },
    newItem(){
      let name = ['Valor agregado','Renta', 'Patrimonio', 'Proporcional','Progresivo', 'Regresivo'];
      let unit = ['%','$','#','@','&'];
      this.dialog1 = true;
      this.edit = false;
      this.tax = {
        // category:this.categoryTax[Math.floor(Math.random()*this.categoryTax.length)].value,
        // name: name[Math.floor(Math.random()*name.length)],
        description:{
          value: 0
          // value:Math.floor(Math.random() * 25),
          // unit:unit[Math.floor(Math.random()*unit.length)],
          // default:!Math.round(Math.random()),
        },
      };
    },
    editItem(item){
      this.dialog1 = true;
      this.edit = true;
      this.tax = {
        id:item.id,
        name:item.name,
        category:item.category,
        description: item.description,
      };
      // eslint-disable-next-line no-console
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        template: 'Taxes',
        show: true,
        model: this.$tc('Taxes.tax',1)+' '+item.name,
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.catalogList(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    close(){
      this.dialog1 = false;
      this.edit = false;
      this.tax = {
        category:'tax',
        description:{
          value: 0
        },
      };
      this.$refs.form_taxes.reset();
    },
    save(){
      this.$refs.form_taxes.validate()
      .then((success)=>{
        if (success) {
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
        }
      });
    },
    create(){
      axios.post(this.$store.getters.catalogList(), this.tax)
      .then((response)=>{
        // eslint-disable-next-line no-console
        if (response.status == 200) {
          const text = this.$tc('Taxes.tax',1)+' '+response.data.success.name;
          this.pushNoty(text, 'created',1)
          this.close();
          this.getQuerys();
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loading = false;
      });
    },
    update(){
      let nameTax = this.tax.name;
      axios.put(this.$store.getters.catalogList(this.tax.id), this.tax)
      .then((response)=>{
        if (response.status == 200) {
          const text = this.$tc('Taxes.tax',1)+' '+nameTax;
          this.pushNoty(text, 'updated',1)
          this.close();
          this.getQuerys();
        }
        // AJ 21/05/20 -> If nothing changes, why close the modal?
        /*if (response.status == 204) {
          this.close();
        }*/
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loading = false;
      });
    },
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    formatAmount(amount){
      if (!amount) return null;
      return Number(amount).toFixed(2);
    },
    taxesPolicies() {
      if (this.$store.getters.isAdmin/* || this.$store.getters.isFranchiseAdmin*/){
        this.permissions={
          list: true,
          create: true,
          edit: true,
          delete: true
        }
      } else {
        this.permissions.list = this.getPermissionGroup({
          permission:[
            'index_taxes'
          ],
          mode:'all'
        });
        this.permissions.create = this.getPermissionGroup({
          permission:[
            'index_taxes',
            'store_taxes',
          ],
          mode:'all'
        });
        this.permissions.edit = this.getPermissionGroup({
          permission:[
            'index_taxes',
            'update_taxes',
          ],
          mode:'all'
        });
        this.permissions.delete = this.getPermissionGroup({
          permission:[
            'index_taxes',
            'destroy_taxes',
          ],
          mode:'all'
        });
      }
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  mounted() {
    this.taxesPolicies()
    this.getQuerys()
  },
};
