import DialogSubscriber from '../../components/Subscriber/DialogSubscriber.vue';
import contactsandaddress from '../Requests/ContactsInfo.vue';
import simpleservice from '../Requests/ServiceInfo.vue';
import files from './FilesLibrary.vue';
import AddService from '../Package/SelectedService.vue';
import SubscriberDoc from '../Subscribers/SubscriberDoc.vue'
import WalletMovements from '../../components/Subscriber/Wallet/Movements.vue';

export default {
  components:{
    'files-library': files,
    'simpleservice': simpleservice,
    'contactsandaddress': contactsandaddress,
    DialogSubscriber,
    AddService,
    SubscriberDoc,
    WalletMovements
  },
  props: {
    editable: {
      type:Boolean,
      default: false
    }
  },
  data(){
    return {
      details_dialog: false,
      contracts: {},
      franchise_name: null,
      loading: false,
      subscriber_details: [],
      dialog_overlay: false,
      hidden1: true,
      // Edit Subscriber Info Modal
      edit_subscriber: false,
      subscriber: [],
      av_image: '',
      client_types: [],
      loadingSelTypeCl: false,
      prefixes: [],
      loadingSelPrefix: false,
      dni_type: 3,
      is_firm: false,
      max_length: 0,
      genders: ['F', 'M'],
      date_menu: false,
      years_18_ago: new Date(Date.now() - 567648000000).toISOString().substr(0, 10),
      has_files: false,
      payment_type:[
        {value:'prepaid', text:this.$t('paymentForm.types.prepaid')},
        {value:'postpaid', text:this.$t('paymentForm.types.postpaid')},
      ],
      expanded_contract: null,
      old_billing_type: null,
      unwatch: '',
      policies: {
        show: false,
        edit: false,
        payment_type: false,
        view_files: false,
        view_billing: false,
        add_service: false
      },
      balance_hovered: false,
      modeNode: process.env.NODE_ENV,
      iva_items: process.env.VUE_APP_IVA_ITEMS,
      islr_items: process.env.VUE_APP_ISLR_ITEMS,
    };
  },
  methods:{
    openWallet () {
      this.$refs.wallet_movements.open_dialog(this.subscriber_details.id, this.subscriber_details.balance)
    },
    viewDetails (id) {
      this.details_dialog = true
      if (!this.subscriber_details || this.subscriber_details.id != id) {
        this.has_files = false
        this.dialog_overlay = true
        setTimeout(() =>
          axios.get(this.$store.getters.getSubscribers(id), {
              params: {
                subs: true,
                is_filter: this.$router.currentRoute.name == 'filterSubscribersDetails' ||
                           this.$router.currentRoute.name == 'filterContractsPerServiceDetails' ||
                           this.$router.currentRoute.name == 'AllSubscribersCDS'
              }
            })
            .then(response => {
              if (response.data.success) {
                response.data.success.subscriber.files_count > 0 ? this.has_files = true : ''
                this.subscriber_details = response.data.success.subscriber
                // this.subscriber_details.prefix = response.data.success.subscriber.doc.substring(0,1)
                // this.subscriber_details.dni = response.data.success.subscriber.doc.substring(1,10)
                this.contracts = response.data.success.contracts;
                this.franchise_name = response.data.success.franchise_name;
                this.dialog_overlay = false;
              } else {
                this.details_dialog = false;
                this.dialog_overlay = false;
                this.$store.dispatch('pushNotification', {
                  snackbar:true,
                  style:'error',
                  mode:null,
                  timeout:6000,
                  notifText:'base.something_wrong',
                  extra:null
                })
              }
            })
            .catch(err => {
              this.details_dialog = false
              this.dialog_overlay = false
            })
        , 400)
      }
    },
    close () {
      this.setClear()
    },
    setClear () {
      this.loading = false
      this.details_dialog = false
      this.old_billing_type = null
      this.expanded_contract = null
      this.$validator.reset()
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    updateSubscriber (data) {
      // eslint-disable-next-line no-console
      // console.log('actualizo al padre', data)
      this.subscriber_details = JSON.parse(data);
    },
    emitNotification (style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      })
    },
    change_payment_type(item){
      let contractnum = item.id.toString().length != 5 ?("00000" + item.id).slice(-5) :item.id;
      this.$store.dispatch('confirmationValue',{
        template: 'billing_type',
        show: true,
        model: this.$tc('paymentForm.paymentForm',2),
        title: this.$t('base.change'),
        action: this.$tc('updated',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.change')+' '+this.$tc('the',3)+' '+this.$tc('paymentForm.paymentForm',2) +' '+this.$t('of2')+' '+this.$tc('contracts.contracts',1)+' '+contractnum,
          record: this.$t('paymentForm.types.'+item.type),
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getChangeBillingType(item.id,item.type),
          method: 'patch',
        },
        pos:2
      });
    },
    setOldBillingType (index, type) {
      if (this.expanded_contract === null) {
        this.expanded_contract = index
        this.old_billing_type = type
        this.contracts[index].payment_type = this.old_billing_type
      } else if (this.expanded_contract != index) {
        this.contracts[this.expanded_contract].payment_type = this.old_billing_type
        this.expanded_contract = index
        this.old_billing_type = type
        this.contracts[index].payment_type = this.old_billing_type
      } else if (this.expanded_contract == index) {
        this.contracts[index].payment_type = this.old_billing_type
        this.expanded_contract = null
        this.old_billing_type = null
      }
    },
    updateTechAddress (contract_id, address) {
      let tech_contract = this.contracts.find(c => c.id === contract_id)
      if (tech_contract) {
        tech_contract.tech_address[0].address = address
      }
    },
    PoliciesSubscriber(){
      this.policies.show = this.getPermissionGroup({
        permission:[
          'show_subscriber',
          'index_contractContact',
          'index_contractAddress',
          'index_contractService',
        ],
        mode:'all'
      });
      this.policies.edit = this.getPermissionGroup({
        permission:[
          'index_typeClient',
          'update_subscriber',
        ],
        mode:'all'
      });
      this.policies.payment_type = this.getPermissionGroup({
        permission:[
          'changeBilling_contract',
        ],
        mode:'all'
      });
      this.policies.view_files = this.getPermissionGroup({
        permission:[
          'index_subscriberFileLibrary',
        ],
        mode:'all'
      });
      this.policies.view_billing = this.getPermissionGroup({
        permission:[
          'viewBilling_subscriber',
        ],
        mode:'all'
      });
      this.policies.add_service = this.getPermissionGroup({
        permission:[
          'store_contractService',
        ],
        mode:'all'
      });
    },
    balanceChanged (balance) {
      this.subscriber_details.balance = balance
    },
    openDialog(){
      this.$refs.dialog_subscriber.open_dialog(this.subscriber_details);
    },
    updateContract (contract) {
      let contract_index = this.contracts.findIndex(cntrct=>{
        return cntrct.id == contract.id;
      })
      if (contract_index !== undefined && this.contracts[contract_index] !== undefined) {
        this.contracts[contract_index].status = contract.status
        this.contracts[contract_index].active = contract.active
        this.$emit('refreshSubscribersList')
      }
    },
    newService(contract_id) {
      this.$refs.select_service.open('contract', contract_id);
    },
    forcedAddCompleted (contract_id) {
      let reference = 'simple_service' + contract_id
      this.$refs[reference][0].getServices()
    }
  },
  watch:{
    details_dialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        this.is_firm = false
        if (this.contracts[this.expanded_contract]) {
          this.contracts[this.expanded_contract].payment_type = this.old_billing_type
        }
      }
    },
  },
  computed:{},
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        if (this.$store.getters.getConfirmationTemplate == 'billing_type') {
          this.old_billing_type = this.contracts[this.expanded_contract].payment_type
        }
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    this.PoliciesSubscriber();
  },
}
