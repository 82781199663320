export default {
  data(){
    return {
      initialized: false,
      nodata: true,
      totals:[],
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      inPercent: false,
      displaySymbol: '',
      options: {
        page: 1,
        itemsPerPage: -1
      },
      unwatch: '',
      policies:{
        get_rates: false,
        get_all: false
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      speed_dial: false,
      ex_rates: [],
      loadingRates: false,
      specific_franchise: {
        id: 0,
        name: ''
      },
      copy_totals:'',
      // Date Filter
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      date1: null,
      date2: null,
      menu1: false,
      menu2: false,
      filtering_by_dates: false,
    }
  },
  methods:{
    getTotalsByFranchise (item) {
      this.specific_franchise.id = item.filter.frn
      this.specific_franchise.name = item.title
      this.getTotals()
    },
    getTotals (clear_filters = false) {
      if (clear_filters) {
        this.clearFilters()
      }

      this.totals = []
      this.loadingTable = true

      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
        }
      }

      if (this.specific_franchise.id && this.specific_franchise.name) {
        ops.params.franchise_id = this.specific_franchise.id
      }

      if (this.date1 && this.date2) {
        this.filtering_by_dates = true
        ops.params.start_date = this.date1
        ops.params.end_date = this.date2
      }

      axios.get(this.$store.getters.filterInstallationsPerDate, ops)
        .then(response => {
          this.nodata = false
          let totals = response.data
          this.totals = totals.sort((a, b) => (a.order > b.order) ? 1 : -1)
          this.copy_totals = JSON.stringify(this.totals)
          if (this.inPercent) {
            this.inPercent = false
            this.changeToPercentBtn()
          }
        })
        .catch(err=>{
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
        })
    },
    dividerTitle (item) {
      let val = ''
      switch (item.title) {
        case 'divider':
          val = ''
          break
        case 'divider_dates':
          val = this.$t('from_to',{
            start: this.$moment(item.from).format(this.$store.getters.getDateFormat[0]),
            end: this.$moment(item.to).format(this.$store.getters.getDateFormat[0])
          })
          break
        case 'divider_franchise':
          val = this.$tc('franchise.company',1)
          break
      }
      return val
    },
    changeToPercentBtn(){
      this.inPercent = !this.inPercent
      if (this.inPercent) {
        this.totals.map(val=>{
          if (typeof(val.total) == 'number' && val.total > 0) {
            const total = Number(val.total);
            let pending = val.pending;
            let completed = val.completed;
            var percent1 = ((pending/total)*100).toFixed(2);
            val.pending = percent1+'%';
            var percent2 = ((completed/total)*100).toFixed(2);
            val.completed = percent2+'%';
          }
        })
      } else {
        this.totals = JSON.parse(this.copy_totals);
      }
    },
    searchByDate () {
      this.menu2 = false
      this.getTotals()
    },
    clearFilters () {
      this.filtering_by_dates = false
      this.computedDateFormatted1 = null
      this.computedDateFormatted2 = null
      this.specific_franchise = {
        id: 0,
        name: ''
      }
    },
    clearDate2 () {
      this.menu1 = false
      this.computedDateFormatted2 = null
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    PoliciesFilter () {
      this.policies.get_all = this.getPermissionGroup({
        permission:[
          'getAllInstallations_serviceInstallation'
        ],
        mode:'all'
      });
    },
  },
  watch:{
    options: {
      handler () {
        this.getTotals()
      },
      deep: true,
    }
  },
  computed:{
    computedTableTitle () {
      if (this.specific_franchise.name) {
        return this.specific_franchise.name
      } else {
        return this.$tc('DSB.menu.installs_per_date',2)
      }
    },
    computedShowArrowBack: {
      get () {
        if ((this.specific_franchise.id && this.specific_franchise.name) || this.filtering_by_dates) {
          return true
        } else {
          return false
        }
      },
      set () {
        //
      }
    },
    headers () {
      let headers = [
        {
          text: '',
          value: 'title',
          sortable: false ,
          align: 'left',
        },
        {
          text: this.$i18n.locale === 'es' ? 'Total Gral.' : 'Gen. Total',
          value: 'total',
          sortable: false ,
          align: 'center',
          width: 120
        },
        {
          text: this.$tc('inspection.awaiting',2),
          value: 'pending',
          sortable: false ,
          align: 'center',
          width: 120
        },
        {
          text: this.$tc('requests.completed',2),
          value: 'completed',
          sortable: false ,
          align: 'center',
          width: 120
        }
      ]
      if (this.policies.get_all && !this.$store.getters.isFranchise) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false,
            align: 'center',
            width: 80
          }
        )
      }
      return headers
    },
    computedDateFormatted1: {
      get () {
        return this.formatDate(this.date1)
      },
      set (val) {
        this.date1 = val
        if (!val) {
          this.computedDateFormatted2 = val
        }
      }
    },
    computedDateFormatted2: {
      get () {
        return this.formatDate(this.date2)
      },
      set (val) {
        this.date2 = val
      }
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
    this.PoliciesFilter()
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    //
  },
}
