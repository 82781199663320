import dialinstalation from "./DialogServiceInstalation.vue"

export default {
  components:{
    dialinstalation:dialinstalation
  },
  props: {
    activation_pending: {
      default: false,
      type: Boolean
    }
  },
  data(){
    return {
      options: {
        page: 1,
        itemsPerPage: 10
      },
      filter: '',
      services: [],
      totalValues: 0,
      tableLoading: false,
      expand: false,
      step: 'tab-1',
      deliveryCertificate: '',
      acceptanceLetter: '',
      installation_status: 'draft'
    }
  },
  methods:{
    expandIt(){
      this.expand = true
      let obj = this
      setTimeout(function() {
        obj.$refs.search.focus()
      }, 400)
    },
    async getServices(is_filter){
      this.services = []
      this.tableLoading = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
          'step': this.step[this.step.length-1]
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        let validation = await this.$validator.validate('filter', this.filter)
        if (validation) {
          ops.params.filter = this.filter
        } else {
          this.tableLoading = false
          this.options.page = 1
          return false
        }
      }
      let url = this.activation_pending ?
        this.$store.getters.getServiceInstallationActivationPending :
        this.$store.getters.getServiceInstallation()
      axios.get(url, ops)
        .then(response => {
          this.services = response.data.services.data
          this.totalValues = response.data.services.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.options.page = 1
            this.getServices(true)
          }
        })
        .catch(error => {
            // console.log(error)
        })
    },
    getDeliveryCertificate(pk){
      axios.get(this.$store.getters.getServiceInstallationDeliveryCertificate(pk))
      .then(response => {
        this.deliveryCertificate = response.library
      })
    },
    getAcceptanceLetter(pk){
      axios.get(this.$store.getters.getServiceInstallationAcceptanceLetter(pk))
      .then(response => {
        this.acceptanceLetter = response.library
      })
    },
    activateFranchiseService (item) {
      if (item.approved_by_network) {
        item['status'] = 'approved'
        this.$store.dispatch('confirmationValue',{
          template: 'service_installation',
          show: true,
          model: this.$tc('service_install.service_installation',1),
          title: this.$t('activate'),
          action: this.$tc('activated',2),
          content: this.$t('base.confirm_approved_installation'),
          type: 'success',
          confirm:{
            url: this.$store.getters.getServiceInstallation(item.id),
            method: 'put',
            data: item
          },
          pos: 2
        })
      } else {
        this.$awn.warning(this.$tc('service_install.awaiting_config', 0, []), {durations:{warning: 4000}})
      }
    }
  },
  computed:{
    headers(){
      let headers = []
      headers = [
        {
          text: this.$tc('base.code',3),
          align: 'center',
          value: 'contract.id',
          width: 86
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'contract.subscriber.doc',
          width: 140
        },
        {
          text: this.$t('subscribers.legal_denomination'),
          value: 'contract.subscriber.legal_denomination',
        }
      ]

      if(this.activation_pending && !this.$store.getters.isFranchise){
        headers.push({
          text: this.$t('document_control.franchise'),
          value: 'contract.franchise.full_name'
        })
      }

      if(this.$store.getters.isFranchise && this.step[this.step.length-1] == 2){
        headers.push({
          text: this.$t('state'),
          value: 'approved_by_network',
          align: 'left',
          width: 100
        })
      }

      headers.push({
        text: this.$t('Taxes.lastUpdate'),
        align: 'center',
        value: 'updated_at',
        width: 160
      })

      headers.push({
        text: this.$t('actions'),
        value: 'action',
        sortable: false ,
        align: this.step[this.step.length-1] != 2 ? 'center' : 'right',
        width: this.$store.getters.isFranchise && this.step[this.step.length-1] == 2 ? 110 : 80
      })

      return headers
    }
  },
  mounted(){
    //
  },
  watch:{
    options: {
      handler () {
        this.getServices(this.expand)
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = ''
        this.$validator.reset()
        this.options.page = 1
        this.getServices(false)
      }
    },
    step(val){
      switch (val) {
        case 'tab-1':
          this.installation_status  = 'draft'
          break
        case 'tab-2':
          this.installation_status  = 'installed'
          break
        case 'tab-3':
          this.installation_status = 'on_test'
          break
        case 'tab-4':
          this.installation_status = 'letter_sent'
          break
      }
      this.getServices(false)
    },
    $route (to, from){
      if (from.name == 'serviceActivation' || from.name == 'serviceInstallation') {
        this.options.page = 1
        this.getServices(false)
      }
    }
  }
}
