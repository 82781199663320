import Vue from 'vue'
import Vuex from 'vuex'
import routers from './plugins/backroutes.js'
import user from './plugins/user.js'
import notifications from './plugins/notifications.js'
import ws_notifications from './plugins/websocket_notifications.js'
import websocket from './plugins/websocket.js'
import confirmations from './plugins/confirmations.js'
import notification_modal from './plugins/notification_modal.js'
import utils from './plugins/utils.js'
import clientDocument from './plugins/clientDocument.js';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    route: routers,
    user:user,
    notifications:notifications,
    ws_notifications:ws_notifications,
    notification_modal:notification_modal,
    confirmations:confirmations,
    utils:utils,
    websocket: websocket,
    clientDocument:clientDocument,
  },
  state: {
    
  },
  getters: {
    
  },
  mutations: {
    
  },
  actions: {
    
  }
})
