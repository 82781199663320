var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"sticky","top":"2rem"}},[_c('v-card',{staticClass:"elevation-0 step-inner card-outlined",attrs:{"outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"title primary--text"},[_c('span',{staticClass:"px-1 white"},[_vm._v(_vm._s(_vm.$t("preview")))])])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"py-1 px-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-app-bar',{attrs:{"dark":"","color":_vm.company.color1}},[_c('v-app-bar-nav-icon'),(_vm.company.head_logo)?_c('v-avatar',{staticClass:"mr-2",attrs:{"tile":""}},[_c('img',{attrs:{"src":_vm.av_head_logo != ''
                        ? _vm.av_head_logo
                        : _vm.company.head_logo != null
                        ? _vm.company.head_logo
                        : '',"alt":_vm.company.name_company}})]):_c('v-avatar',{staticClass:"avatar-border mx-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"large":""}},on),[_vm._v("fa-camera")])]}}])},[_c('span',[_vm._v("Logo")])])],1),_c('v-toolbar-title',{staticClass:"text-uppercase"},[(_vm.company.name_company)?_c('span',[_vm._v(_vm._s(_vm.company.name_company))]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.$t("company.name_company")))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("company.name_company")))])])],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[(_vm.company.name)?_c('v-btn',{staticClass:"pl-2 pr-1",attrs:{"text":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-user")]),_vm._v(" "+_vm._s(_vm.company.name)+" ")],1):_vm._e()],1)],1),_c('v-row',{staticClass:"pr-3"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","sm":"4","md":"5","lg":"4"}},[_c('v-list',{staticClass:"pa-1 pt-4",attrs:{"rounded":"","width":_vm.$vuetify.breakpoint.mdAndUp ? '100%' : '60',"elevation":"1","height":"100%"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l(([
                          {
                            text: 'DSB.menu.company',
                            icon: 'fas fa-building'
                          },
                          {
                            text: 'DSB.menu.dashboard',
                            icon: 'fas fa-tachometer-alt'
                          }
                        ]),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',{staticClass:"mx-auto ml-md-n1 mr-md-1"},[_c('v-icon',{staticClass:"mx-auto mx-sm-0",attrs:{"small":""},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}],domProps:{"textContent":_vm._s(_vm.$t(item.text))}})],1)],1)}),1)],1)],1),_c('v-col',{staticClass:"pt-0",staticStyle:{"min-height":"20rem","max-height":"calc(80vh - 120px)","overflow":"hidden auto"}},[_c('v-list',{staticClass:"mt-1 mb-4"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('company.firm'))}}),(!_vm.company.firm)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("not_assigned")))]):_vm._e()],1),(_vm.company.firm)?_c('v-list-item-avatar',{staticClass:"my-0",attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.av_firm != ''
                              ? _vm.av_firm
                              : _vm.company.firm != null
                              ? _vm.company.firm
                              : ''}})],1):_vm._e()],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('company.seal'))}}),(!_vm.company.firm)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("not_assigned")))]):_vm._e()],1),(_vm.company.seal)?_c('v-list-item-avatar',{staticClass:"my-0",attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.av_seal != ''
                              ? _vm.av_seal
                              : _vm.company.seal != null
                              ? _vm.company.seal
                              : ''}})],1):_vm._e()],1),_c('v-divider'),(!_vm.company.logo)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('company.printable_logo'))}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("not_assigned"))+" ")])],1)],1):_c('div',{staticClass:"document-preview mt-3 mb-4 mx-auto"},[_c('span',{staticClass:"v-label font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("company.printable_logo"))+" ")]),_c('div',{staticClass:"head-logo pa-2 pb-0"},[_c('v-img',{attrs:{"contain":"","src":_vm.av_image != ''
                              ? _vm.av_image
                              : _vm.company.logo != null
                              ? _vm.company.logo
                              : '',"max-width":_vm.$vuetify.breakpoint.xsOnly
                              ? '47%'
                              : _vm.$vuetify.breakpoint.mdAndUp
                              ? '44%'
                              : '60%',"max-height":"99%"}}),_c('p',{staticClass:"blur black--text mb-0"},[_vm._v(" Lorem ipsum dolor sit amet.. ")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }