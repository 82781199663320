import SideMenu from './SideMenu.vue'
import LangsBtn from '@/locale/LangsBtn.vue'
import WsNotification from '../Notifications/Notification.vue'
import NotificationModal from '../layouts/NotificationModal.vue'

export default {
  components: {
    SideMenu,
    LangsBtn,
    WsNotification,
    NotificationModal
  },
  name: 'toolbar',
  computed:{
    company_selection:{
      get(){
        return this.$store.getters.getDiaCompany
      },
      set(newValue) {
        this.$store.dispatch('diaCompany',newValue)
      }
    },
    isAuthAndDBselected:{
      get(){
        return this.$store.getters.isAuth && this.$store.getters.dbSelected !== null
      },
      set(newValue) {
        // console.log(newValue)
      }
    },
    dialogin:{
      get(){
        return this.$store.getters.getDialogin
      },
      set(newValue) {
        this.$store.dispatch('diaLogin',newValue)
      }
    },
    diarecov:{
      get(){
        return this.$store.getters.getDiarecov
      },
      set(newValue){
        return this.$store.dispatch('diaRecov',newValue)
      }
    },
    notifications() {
      // const default_not = {'id':0,'text':this.$t('show_more')}
      let nots = this.$store.getters.getAllNotifications
      // if(nots.length>0){
      //   nots.push(default_not)
      // }
      return nots
    }
  },
  data () {
    return {
      dev_mode: process.env.NODE_ENV == 'development',
      drawer: true,
      mini: false,
      dark: false,
      logo: this.$store.getters.primaryColor,
      icon: 'fas fa-chevron-left',
      title: process.env.VUE_APP_CLIENT,
      // diaregis: false,
      loading1: false,
      // loading2: false,
      loading3: false,
      company: {
        company: ""
      },
      companies_items: [],
      account_items: [
        {
          icon: 'fas fa-user-cog',
          href: '#',
          title: 'DSB.settings',
          route: { name: 'profile' }
        },
        {
          icon: 'fas fa-sign-out-alt',
          href: '#',
          title: 'DSB.logout',
          route: { name: 'logout' },
          action: {
            'click': this.logoutConfirmation
          }
        }
      ],
      login: {
        email: this.$store.getters.rmmbrdUsr ? this.$store.getters.rmmbrdUsr : process.env.NODE_ENV == 'development' ? 'platgousuario1@viginet.com.ve' : '',
        password: process.env.NODE_ENV == 'development' ? 'abcd1234' : '',
        remember: this.$store.getters.rmmbrdUsr ? true : false
      },
      show1: false,
      /* register: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      show2: false,
      show3: false,*/
      recovery: {
        email: ''
      },
      confirm_logout: false,
      darkMode: this.$store.getters.dataUser && this.$store.getters.dataUser.stUser && this.$store.getters.dataUser.stUser.dark_mode ? this.$store.getters.dataUser.stUser.dark_mode : false,
      notification_title:'',
      notification_type:'',
      notification_content:'',
      notification_show:false
    }
  },
  methods: {
    toggleDark(isDark) {
      if (typeof (isDark) != 'boolean') {
        this.darkMode = !this.darkMode
        isDark = this.darkMode
      }
      let data = {'dark_mode':isDark}
      this.$store.dispatch('toggleSettings', data)
    },
    changeModal() {
      this.$store.dispatch('closedialogin')
      setTimeout(() => { this.diaregis = true }, 160)
    },
    changeModal2() {
      this.$store.dispatch('closedialogin')
      setTimeout(() => { this.$store.dispatch('diaRecov',true) }, 160)
    },
    async stablishConn(conn, company, logo, col1/*, col2*/, adm, company_dni, fiscal_address, franchise, tm_out)
    {
      let cmpny = { id: conn, name: company, logo: logo, dni: company_dni, address: fiscal_address }
      localStorage.setItem('nkhokwe', conn)
      window.axios.defaults.headers['nkhokwe'] = conn
      if (adm === true) {
        this.$store.dispatch('setUserAdmin', adm)
      }
      if (franchise && franchise.franchise_id !== null) {
        this.$store.dispatch('connFranchise', franchise)
      }
      await this.$store.dispatch('searchUserPolicies')
      this.$store.dispatch('diaCompany', false)
      this.$store.dispatch('setDBSelected', conn)
      this.$store.dispatch('setPrimaryColor', col1)
      this.$store.dispatch('setCompanyInfo', cmpny)
      this.$store.dispatch('baseCurrency')
      this.$router.push({ name: 'dashboard' })
      this.$store.dispatch('getNotifications')
      this.logo = true
      setTimeout(() => {
        this.$awn.info(this.$tc('conected_to', 0, ['<b>'+company+'</b>']), {durations:{info: 3400}})
        // this.emitNotification(true, 'info', null, 4000, 'conected_to', '<b>'+company+'</b>')
      }, tm_out)
    },
    logIn() {
      this.$validator.validate('login.*').then((valid) => {
        if (valid) {
          this.loading1 = true
          this.$store.dispatch('loginToken', {
            user: this.login
          })
            .then((response) => {
              if (response.data.success) {
                if (response.data.success.companies) {// User's Companies
                  let default_company_id = 0,
                      default_company_nm = '',
                      default_company_lg,
                      default_company_c1,
                      default_company_c2,
                      default_company_ad,
                      default_company_dni,
                      default_company_address,
                      default_franchise,
                      companies = response.data.success.companies
                  if (companies.length == 1) {
                    if (default_company_id == 0) {
                        default_company_id = companies[0].id
                        default_company_nm = companies[0].name_company
                        default_company_lg = companies[0].logo
                        default_company_c1 = companies[0].color1
                        // default_company_c2 = obj.color2
                        default_company_ad = companies[0].pivot.is_admin
                        default_company_dni = companies[0].identification
                        default_company_address = companies[0].fiscal_address
                        default_franchise = companies[0].franchise
                    }
                  } else {
                    Object.values(response.data.success.companies).forEach(function (obj) {
                      if (obj.pivot.is_default && default_company_id == 0) {
                        default_company_id = obj.id
                        default_company_nm = obj.name_company
                        default_company_lg = obj.logo
                        default_company_c1 = obj.color1
                        // default_company_c2 = obj.color2
                        default_company_ad = obj.pivot.is_admin
                        default_company_dni = obj.identification
                        default_company_address = obj.fiscal_address
                        default_franchise = obj.franchise
                      }
                    })
                  }
                  if (default_company_id != 0) {
                    // Conect to default company
                    this.stablishConn(default_company_id,
                                      default_company_nm,
                                      default_company_lg,
                                      default_company_c1,
                                      // default_company_c2,
                                      default_company_ad,
                                      default_company_dni,
                                      default_company_address,
                                      default_franchise,
                                      700)
                  } else {
                    // Ir a la selección de empresas
                    if (this.$store.getters.dbSelected === null) {
                      this.$store.dispatch('diaCompany', true)
                      this.company.company = ''
                      this.companies_items = response.data.success.companies
                    }
                  }
                } else {
                  this.$router.push({ name: 'dashboard' })
                }
                this.$awn.success(this.$tc('base.success_auth', 0, []))
                // this.emitNotification(true, 'success', null, 4000, 'base.success_auth')
                this.clearLoginModal()
              }
              this.loading1 = false
            }).catch((error) => {
              this.loading1 = false
            })
        }
      }).catch((error) => {
        //
      })
    },
    logoutConfirmation() {
      this.confirm_logout = true
    },
    logout() {
      this.$store.dispatch('clearSettings')
      this.logo = false
      this.confirm_logout = false
      if (this.$router.currentRoute.name != 'home') {
        this.$router.push({ name: 'home' })
      }
      this.$vuetify.theme.dark = false
      this.$store.dispatch('diaLogin', true)
      this.$store.dispatch('diaCompany', false)
      this.$store.dispatch('logoutToken').then((response) => {
        if (response.data.success) {
          this.$awn.warning(this.$tc(response.data.success, 0, []), {durations:{warning: 3700}})
        }
      }).catch((/* error */) => {
        this.$awn.warning(this.$tc('base.logged_out', 0, []))
      })
      this.$store.dispatch('setPrimaryColor', '#0a57a5')
      this.$store.dispatch('clearNotifications')
      delete window.axios.defaults.headers['Authorization']
      delete window.axios.defaults.headers['nkhokwe']
      localStorage.removeItem('id')
      localStorage.removeItem('name')
      localStorage.removeItem('token')
      localStorage.removeItem('ext_token')
      localStorage.removeItem('is_anapa')
      localStorage.removeItem('anapa')
      localStorage.removeItem('anapa_woyang')
      localStorage.removeItem('wurzel')
      localStorage.removeItem('woyang')
      localStorage.removeItem('mtundu')
      localStorage.removeItem('nkhokwe')
      localStorage.removeItem('zokonda')
      localStorage.removeItem('policies')
      localStorage.removeItem('policiesMn')
      localStorage.removeItem('corpu')
      if (this.$store.getters.is503) {
        this.$router.push({ name: '503' })
      }
    },
    /* regUser() {
      this.$validator.validate('register.*').then((valid) => {
        if (valid) {
          this.loading2 = true
          this.$store.dispatch('regisUser', {
            user: this.register
          })
            .then((response) => {
              switch (response.status) {
                case 200: {
                  if (response.data.success) {
                    this.diaregis = false
                    this.$router.push({ name: 'dashboard' })
                    this.emitNotification(true, 'success', null, 4000, 'base.success_reg')
                    this.clearRegisterModal()
                  } else {
                    // console.log(response.data)
                    this.emitNotification(true, 'warning', null, 6000, 'base.something_wrong')
                  }
                  break
                }
                default: {
                  this.emitNotification(true, 'error', null, 6000, response)
                }
              }
              this.loading2 = false
            }).catch((error) => {
              // console.log(error)
            })
        }
      }).catch((error) => {
        // console.log(error)
      })
    }, */
    passRecovery() {
      this.$validator.validate('recovery.*').then((valid) => {
        if (valid) {
          this.loading3 = true
          axios
            .post(this.$store.getters.passRequestUrl, {
              email: this.recovery.email
            })
            .then((response) => {
              if (response.data.success) {
                this.emitNotification(true, 'success', null, 4000, response.data.success)
              } else {
                this.emitNotification(true, 'warning', null, 6000, 'something_wrong')
              }
              this.clearRecoveryModal()
            }).catch((error) => {
              this.loading3 = false
            })
        }
      }).catch((error) => {
        // console.log(error)
      })
    },
    clearLoginModal() {
      this.loading1 = false
      this.$store.dispatch('closedialogin')
      this.login.email = this.$store.getters.rmmbrdUsr ? this.$store.getters.rmmbrdUsr : this.dev_mode ? 'platgousuario1@viginet.com.ve' : ''
      this.login.password = this.dev_mode ? 'abcd1234' : ''
      this.$validator.reset()
    },
    /* clearRegisterModal: function () {
      this.loading2 = false
      this.diaregis = false
      var self = this
      Object.keys(this.register).forEach(function (key) {
        self.register[key] = ''
      })
      this.$validator.reset()
    }, */
    clearRecoveryModal() {
      this.loading3 = false
      this.$store.dispatch('closediarecov')
      this.recovery.email = ''
      this.$validator.reset()
    },
    emitNotification(snackbar, style, mode, timeout, notifText, extra) {
      this.$store.dispatch('pushNotification', {
        snackbar:snackbar,
        style:style,
        mode:mode,
        timeout:timeout,
        notifText:notifText,
        extra:extra
      })
    },
    getUserActiveCompanies(){
      axios
        .get(this.$store.getters.getUserCompanies)
        .then(response => {
          this.companies_items = response.data
        })
        .catch(error => {
          // console.log(error)
        })
    },
    getCurrentCompanyStyleInfo(id){
      axios
        .get(this.$store.getters.getCompanies(id))
        .then(response => {
          if (response.data.success) {
            let cmpny = {
              id: id,
              name: response.data.success.name_company,
              logo: response.data.success.logo,
              dni: response.data.success.identification,
              address: response.data.success.fiscal_address
            }
            this.$store.dispatch('setPrimaryColor', response.data.success.color1)
            this.$store.dispatch('setCompanyInfo', cmpny)
          }
        })
        .catch(error => {
          // console.log(error)
        })
    },
    selectCompany() {
      this.$validator.validate('company.*').then((valid) => {
        if (valid) {
          axios
            .get(this.$store.getters.getCompanyConn(this.company.company))
            .then(response => {
              if (response.data.success) {
                this.stablishConn(response.data.success.id,
                                  response.data.success.nm,
                                  response.data.success.lg,
                                  response.data.success.c1,
                                  // response.data.success.c2,
                                  response.data.success.ad,
                                  response.data.success.dni,
                                  response.data.success.address,
                                  response.data.success.franchise,
                                  100)
              }
            })
            .catch(err => {
              this.emitNotification(true, 'error', null, 4000, err.response.data.error)
            })
        }
      }).catch((error) => {
        // console.log(error)
      })
    },
    showNot(id){
      const not = this.$store.getters.filterNotification(id)
      this.$store.dispatch('notificationModal',{
        title: '',
        type: not.type,
        text: not.text
      })
      this.$store.dispatch('markAsReadNotification',not).then( () => {
        this.$store.dispatch('getNotifications')
      })
    },
  },
  watch: {
    mini(newIcon) { // (.., oldIcon)
      this.icon = !newIcon ? 'fas fa-chevron-left' : 'fas fa-chevron-left fa-rotate-180'
      this.$store.dispatch('miniTooltips', newIcon)
    },
    $route (to, from){
      this.$store.dispatch('SetRouterFom',from.path);
      if (this.$store.getters.is503 && this.$store.getters.isAuth) {
        this.logout()
      }
    }
  },
  filters: {
    slice (value, start, end) {
      return value.slice(start, end)
    }
  },
  beforeCreate() {
    // Retrieve User's Info
    if (this.$store.getters.isAuth) {
      this.$store.dispatch('getUserInfo')
      if (this.$store.getters.dataUser && this.$store.getters.dataUser.stUser === null) {
        this.$store.dispatch('getUserSettings')
      }
    }
    // Find out if user is Root
    if (this.$store.getters.isAuth && this.$store.getters.isRoot === null) {
      this.$store.dispatch('setUserRoot')
    }
    // Set Date format according to system lang
    if (this.$store.getters.getDateFormat === null) {
      this.$store.dispatch('changeDateFormat')
    }
    // Set Number format according to system lang
    if (this.$store.getters.getNumFormat === null) {
      this.$store.dispatch('changeNumFormat')
    }
  },
  created() {
    this.unwatch = this.$store.watch(() => this.$store.getters.dataUser.stUser, val => {
      if (val) {
        this.darkMode = val.dark_mode
        this.$vuetify.theme.dark = val.dark_mode
      }
    })
    // Get company info for styling
    if (this.$store.getters.isAuth && (this.$store.getters.dbSelected !== null || this.$store.getters.dbSelected != 0)  && !this.$store.getters.isRoot) {
      this.getCurrentCompanyStyleInfo(this.$store.getters.dbSelected)
      this.$store.dispatch('searchUserPolicies')
      this.$store.dispatch('getNotifications')
    }
  },
  mounted() {
    // If necessary, show User's access Companies
    if (this.$store.getters.isAuth && this.$store.getters.dbSelected === null) {
      this.$store.dispatch('diaCompany', true)
      this.getUserActiveCompanies()
    }
  }
}
