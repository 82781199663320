import dialservice from './DialogService.vue'
import servicespecs from './ServiceSpecs.vue'
import dialparam from './DialogParameters.vue'
import dialconfig from './DialogConfigs.vue'

export default {
  components:{
    dialservice,
    servicespecs,
    dialparam,
    dialconfig
  },
  data(){
    return {
      sortBy:[],
      sortDesc:[],
      services: [],
      perPage: 10,
      page: 1,
      totalValues: 0,
      loadingServiceTable: false,
      unwatch: '',
      filter: '',
      expand:false
    }
  },
  methods:{
    expandIt(){
      if(this.expand){
        this.expand = false
        this.filter = ""
        this.getService()
      }
      else{
        this.expand = true
        let obj = this
        setTimeout(function() {
          obj.$refs.search.focus()
        }, 400)
      }
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.page = 1 
            this.getService()
          }
        })
        .catch(error => {
            // console.log(error)
        })
    },
    getService(){
      this.loadingServiceTable = true
      let param = {
        page:this.page,
        paginate:this.perPage,
      }
      if(this.filter != '' && this.filter != undefined){
        param['search'] = this.filter
      }

      if(this.sortDesc.length > 0){
        param['sortBy'] = this.sortBy[0]
        param['sortDesc'] = this.sortDesc[0]
      }

      axios.get(this.$store.getters.getService(),{
        params:param
      })
      .then(response => {
        this.services = response.data.service.data
        this.totalValues = response.data.service.total
        this.loadingServiceTable = false
      })
    },
    removed(serv){
      this.$store.dispatch('confirmationValue',{
        show: true,
        title: this.$t('base.delete'),
        model:this.$tc('service.service',1),
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record:serv.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getService(serv.id),
          method: 'delete'
        }
      })
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val){
        this.getService()
      }
    })
  },
  watch:{
    sortDesc(){
      this.getService()
    },
    perPage(){
      this.getService()
    },
    page(){
      this.getService()
    }
  },
  computed:{
    headers(){
      return [
        {
          text: this.$t('code'),
          align: 'left',
          value: 'code',
        },
        {
          text: this.$t('name'),
          align: 'left',
          value: 'name',
        },
        { 
          text: this.$t('description'), 
          value: 'description'
        },
        { 
          text: this.$tc('active',0), 
          value: 'active',
          width: 120
        },
        { 
          text: this.$t('actions'), 
          value: 'action', 
          sortable: false ,
          align: 'center',
          width: 214
        }
      ]
    } 
  },
  mounted(){
    this.getService()
  },
  beforeDestroy () {
    this.unwatch()
  }
}
