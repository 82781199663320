var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pt-8":"","px-8":"","px-sm-5":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto pa-0",attrs:{"cols":"12","sm":_vm.rateConverted ? 11 : 10}},[_c('v-data-table',{staticClass:"elevation-1 amount-totals-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.totals,"hide-default-header":_vm.nodata,"footer-props":{
            'items-per-page-options': [10, 50, 100, 500, 1000]
          },"options":_vm.options,"loading":_vm.loadingTable,"height":"calc(100vh - 215px )","fixed-header":_vm.$vuetify.breakpoint.smAndUp},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.computedTableTitle,"showArrowBack":_vm.specific_franchise.name ? true : false},on:{"arrowBack":function($event){return _vm.getTotals()}}})]},proxy:true},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('b',{staticClass:"body-0 text-uppercase",class:item.title.includes('divider_') ? 'grey--text' : ''},[_vm._v(" "+_vm._s(_vm.$te('table.'+item.title) ? _vm.$tc('table.'+item.title,1) : item.title.includes('divider_') ? _vm.dividerTitle(item.title) : item.title)+" ")])]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('p',_vm._g({staticClass:"mb-0 pr-4 text-right"},on),[_vm._v(" "+_vm._s(item.amount ? _vm.base_currency_symbol : '')+" "),(item.amount != 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.amount,_vm.base_currency_decimal_places))+" ")]):_c('span',[_vm._v("0")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('contracts.contracts',2))+": "+_vm._s(item.total))])])]}},{key:"item.in_request.amount",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('p',_vm._g({staticClass:"mb-0 pr-4 text-right"},on),[_vm._v(" "+_vm._s(item.in_request && item.in_request.amount && !_vm.inPercent ? _vm.base_currency_symbol : '')+" "),(item.in_request)?_c('span',[(item.in_request.amount != 0)?_c('span',[(!_vm.inPercent)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.in_request.amount,_vm.base_currency_decimal_places))+" ")]):_c('span',[_vm._v(_vm._s(item.in_request.amount))])]):_c('span',[_vm._v("0")])]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('contracts.contracts',2))+": "+_vm._s(item.in_request && item.in_request.total ? item.in_request.total : 0)+" ")])])]}},{key:"item.active.amount",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('p',_vm._g({staticClass:"mb-0 pr-4 text-right"},on),[_vm._v(" "+_vm._s(item.active && item.active.amount && !_vm.inPercent ? _vm.base_currency_symbol : '')+" "),(item.active)?_c('span',[(item.active.amount != 0)?_c('span',[(!_vm.inPercent)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(item.active.amount,_vm.base_currency_decimal_places))+" ")]):_c('span',[_vm._v(_vm._s(item.active.amount))])]):_c('span',[_vm._v("0")])]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('contracts.contracts',2))+": "+_vm._s(item.active && item.active.total ? item.active.total : 0)+" ")])])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.filter && item.filter.frn && _vm.policies.get_all)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","disabled":!item.total},on:{"click":function($event){return _vm.getTotals(item.filter.frn, item.title)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])]):_vm._e()]}}],null,true)}),_c('fab-percent-currency',{attrs:{"baseCurrencySymbol":_vm.base_currency_symbol,"showCurrencies":_vm.policies.get_rates},on:{"changeToPercent":_vm.changeToPercentBtn,"changeToRate":_vm.changeToRate}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }