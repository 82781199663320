export const error422_es = {
  // Notificaciones
  user_id_must_exist: 'El Usuario no existe',
  policies_must_exist: 'Los permisos deben existir',
  // General
  email_must_exist: 'El correo electrónico no está registrado',
  // Sistema
  category_already_registered: 'La Moneda Base ya ha sido establecida',
  category_name_combination_already_registered: 'El nombre ya existe dentro de la categoría',
  name_max_40: 'El nombre no puede tener más de 40 caracteres',
  rate_must_decimal: 'La tasa debe ser un número decimal',
  rate_greater_than_0: 'La tasa debe ser mayor que cero',
  rate_required: 'La tasa debe ser mayor que cero',
  symbol_max_6: 'El símbolo no debe exceder 6 caracteres',
  category_name_combination_required: 'El campo nombre es requerido',
  // Usuario
  name_required: 'El nombre es obligatorio',
  name_4_14: 'El nombre debe tener entre 4 y 14 caracteres',
  email_required: 'El correo electrónico es obligatorio',
  email_must_valid: 'El correo debe ser una dirección válida',
  email_max_140: 'El correo no puede tener más de 140 caracteres',
  email_already_registered: 'El correo electrónico ya existe',
  password_required: 'La contraseña es obligatoria',
  password_min_8: 'La contraseña debe tener al menos 8 caracteres',
  password_without_spaces: 'La contraseña no puede contener espacios',
  confirmation_must_match: 'La confirmación de la contraseña no coincide',
  current_password_mismatch: 'La contraseña actual no coincide',
  // Abonado
  prefix_required: 'Seleccione un prefijo',
  dni_required: 'El documento de identificación es obligatorio',
  dni_digits_3_9: 'El documento de identificación debe tener entre 3 y 9 dígitos',
  dni_digits_6_9: 'El documento de identificación debe tener entre 6 y 9 dígitos',
  doc_already_registered: 'El documento de identificación ya está registrado',
  legal_denomination_required: 'La razón social es obligatoria',
  legal_denomination_4_140: 'La razón social debe tener entre 4 y 140 caracteres',
  legal_denomination_invalid_format: 'La razón social solo puede contener letras',
  legal_denomination_must_json: 'Formato inválido para razón social',
  birthdate_invalid_date: 'La fecha de nacimiento debe ser una fecha válida',
  birthdate_required_person_type_natural: 'La fecha de nacimiento es obligatoria para Persona Natural',
  gender_required_person_type_natural: 'El género es obligatorio para Persona Natural',
  tax_rate_required_agent_ret_1: 'La tasa de impuesto es obligatoria para Persona Jurídica',
  is_firm_required_person_type_natural: 'Indique si es Firma Personal',
  tax_rate_invalid_format: 'Formato inválido para la tasa de impuesto',
  tax_rate_greater_than_0: 'La tasa de impuesto debe ser mayor que cero',
  avatar_must_valid_image: '{0} debe ser JPG, JPEG o PNG',
  avatar_max_2048_kb: '{0} no debe pesar mas de 2MB',
  type_client_id_required: 'El tipo de Abonado es obligatorio',
  type_client_id_must_number: 'El tipo de Abonado es obligatorio',
  agent_ret_must_boolean: 'Agente de Retención inválido',
  contact_medium_required: 'El medio de contacto es obligatorio',
  observations_must_string: 'Las observaciones deben ser una cadena',
  subscriber_already_active: 'El Abonado ya está activo',
  no_valid_contracts: 'La condicion del Abonado no ha cambiado, debido a que tiene solicitudes en proceso',
  old_subscriber_num_already_registered: 'El número de Abonado ya está registrado',
  code_required_migrating_true: 'El número de Abonado es obligatorio',
  // Librería
  contract_id_must_integer: 'La ID del contrato es inválida',
  subscriber_id_must_exist: 'La ID del abonado es inválida',
  subscriber_id_required: 'La ID del abonado es obligatoria',
  file_name_required: 'El nombre del archivo es obligatorio',
  file_path_required: 'El archivo es obligatorio',
  file_path_must_type: 'El archivo debe ser JPG, JPEG, PNG o PDF',
  failed_to_upload: 'Error al cargar el Archivo',
  file_path_max_4096_kb: 'El archivo no debe pesar mas de 4MB',
  file_must_type: 'El tipo de archivo seleccionado, es inválido|El tipo de alguno de los archivos seleccionados, es inválido',
  file_max_4096_kb: 'El archivo seleccionado es demasiado pesado (Max: 4MB)|Alguno de los archivos seleccionados es demasiado pesado (Max: 4MB)',
  // Groups
  name_already_registered: 'El nombre ingresado ya existe',
  // Contratos
  contract_id_required: 'La ID del contrato no se ha especificado',
  contract_id_must_exist: 'La ID del contrato no existe',
  type_required: 'El Tipo no se ha especificado',
  type_is_invalid: 'El Tipo es inválido',
  type_tech_already_registered: '{1} Tech. ya existe',
  type_admin_already_registered: '{1} Admin. ya existe',
  type_legal_already_registered: '{1} Legal ya existe',
  parish_id_required: 'La parroquia es obligatoria',
  parish_id_must_number: 'El código de parroquia es inválido',
  parish_id_must_exist: 'La parroquia no existe',
  parish_name_must_string: 'El nombre de parroquia debe ser una cadena',
  parish_name_min_3: 'El nombre de parroquia debe tener al menos 3 caracteres',
  sector_id_required: 'El sector es obligatorio',
  sector_id_must_number: 'El código de sector es inválido',
  sector_id_must_exist: 'El sector no existe',
  tower_max_190: 'La torre no debe tener más de 190 caracteres',
  tower_must_string: 'La torre debe ser una cadena',
  address_required: 'La dirección es obligatoria',
  fiscal_address_max_190: 'La dirección fiscal no debe tener un nombre con mas de 190 caracteres',
  ref_point_max_190: 'El punto de referencia no debe tener un nombre con mas de 190 caracteres',
  ref_point_must_string: 'El punto de referencia debe ser una cadena',
  lat_required: 'La latitud es obligatoria',
  lat_required_lon_present: 'La latitud es obligatoria junto con la longitud',
  lat_invalid_format: 'Formato inválido para latitud',
  lon_required: 'La longitud es obligatoria',
  lon_required_lat_present: 'La longitud es obligatoria junto con la latitud',
  lon_invalid_format: 'Formato inválido para longitud',
  first_name_required: 'El nombre es obligatorio',
  first_name_3_40: 'El nombre debe tener entre 3 y 40 caracteres',
  first_name_invalid_format: 'Formato inválido para nombre',
  last_name_required: 'El apellido es obligatorio',
  last_name_3_40: 'El apellido debe tener entre 3 y 40 caracteres',
  last_name_invalid_format: 'Formato inválido para apellido',
  doc_must_10: 'El DNI debe constar de 10 caracteres',
  position_required: 'El cargo es obligatorio',
  cellphone_required: 'El celular es obligatorio',
  cellphone_already_registered: 'El celular ya está registrado',
  cellphone_must_11_digits: 'El celular debe tener 11 dígitos',
  local_phone_must_11_digits: 'El teléfono debe tener 11 dígitos',
  phone_min_11: 'El teléfono debe tener 11 dígitos',
  price_required: 'El precio es obligatorio',
  employee_id_required_type_tech: 'El empleado es obligatorio',
  employee_id_must_number: 'No ha seleccionado un empleado',
  employee_id_must_exist: 'El empleado seleccionado no existe',
  // Actor
  dni_already_registered: 'Cédula ya registrada',
  personal_mail_already_registered: 'Correo personal ya registrado',
  // Type Client
  has_franchise_active: 'Está activo en una Unidad de Negocio',
  description_required: 'La descripción es obligatoria',
  id_cannot_exist: 'Hay {1} asociadas a este registro|Hay {1} asociados a este registro',
  // Servicios
  status_is_invalid: 'Estado inválido',
  price_must_decimal: 'El precio debe ser un número decimal',
  price_greater_than_0: 'El precio debe ser mayor que cero',
  qty_invalid_format: 'Formato inválido para la cantidad',
  qty_required: 'La cantidad es obligatoria',
  code_already_registered: 'El código de Servicio ya existe',
  package_with_contract: 'El paquete sólo puede desactivarse',
  service_assigned_to_subscriber: 'El servicio tiene Abonados asociados',
  measure_required: 'El campo Unidad de medida es obligatorio',
  service_has_specifications: 'El Servicio tiene Especificaciones',
  // Carta de Aceptación
  letter_does_not_exist: 'Carta de aceptación no existe',
  // Empresa
  name_company_already_registered: 'El nombre de la empresa ya fue registrado',
  logo_required: 'El logo es obligatorio',
  logo_must_valid_image: 'El logo debe ser JPG, JPEG o PNG',
  firm_must_valid_image: 'La firma debe ser JPG, JPEG o PNG',
  seal_must_valid_image: 'El sello debe ser JPG, JPEG o PNG',
  name_company_required: 'El nombre de la empresa es obligatorio',
  object_company_max_190: 'El objeto de la empresa no debe tener mas de 190 caracteres',
  identification_required: 'El ID o RIF es obligatorio',
  country_must_number: 'El código de país es inválido',
  state_must_number: 'El código de estado es inválido',
  name_institution_registration_required: 'El nombre de la institución de registro es obligatorio',
  name_institution_registration_max_190: 'El nombre de la institución de registro no debe tener mas de 190 caracteres',
  register_number_required: 'El númerio de registro es obligatorio',
  date_register_required: 'La fecha de registro es obligatoria',
  legal_representative_required: 'El nombre del representante legal es obligatorio',
  fiscal_address_required: 'La dirección es obligatoria',
  latitude_required_longitude_present: 'La latitud es obligatoria junto con la longitud',
  latitude_invalid_format: 'Formato inválido para la latitud',
  longitude_required_latitude_present: 'La longitud es obligatoria junto con la latitud',
  longitude_invalid_format: 'Formato inválido para la longitud',
  telephone_required: 'El teléfono es obligatorio',
  telephone_must_11_digits: 'El teléfono debe tener 11 dígitos',
  name_4_20: 'El nombre debe tener entre 4 y 20 caracteres',
  email_admin_required: 'El correo electrónico del administrador es obligatorio',
  email_admin_must_valid: 'El correo del administrador debe ser una dirección válida',
  email_admin_already_registered: 'El correo electrónico del administrador ya existe',
  // Franquicia
  name_franchise_required: 'El nombre de la Unidad de Negocio es obligatorio',
  branch_office_must_string: 'La sucursal debe ser una cadena',
  // Concept
  start_date_invalid_date: 'Debe verificar la Fecha de Inicio',
  end_date_invalid_date: 'Debe verificar la fecha de Finalización',
  amount_must_number: 'El monto del concepto debe ser numérico',
  quantity_must_number: 'La cantidad del concepto debe ser numérica',
  is_percentage_boolean: 'El valor de "es porcentaje" no es correcta',
  concept_motive_id_required: 'Debe introducir un motivo de concepto',
  concept_does_not_exist: 'El concepto no existe',
  concept_used: 'El concepto está siendo usado',
  concept_amount_invalid: 'Verifique que el monto de los conceptos sea válido',
  concept_motive_invalid: 'El motivo del concepto no es válidos',
  document_adjustment_motives_not_found: 'No se encontraron motivos de ajuste de conceptos',
  // Permisos
  name_min_3: 'El nombre debe tener al menos 3 caracteres',
  name_invalid_format: 'Formato inválido para el nombre',
  // Document Control
  initial_document_number_greater_than_0: 'Número inicial de documento debe ser mayor a cero',
  initial_control_number_greater_than_0: 'Número inicial de control debe ser mayor a cero',
  final_document_number_greater_than_0: 'Número final de documento debe ser mayor al número inicial de documento',
  final_control_number_greater_than_0: 'Número final de control debe ser mayor al número inicial de control',
  final_must_greater_initial: 'Los números de documento y de control finales, deben ser mayores a los numeros iniciales',
  set_numbers_voided: 'En el conjunto de números a registrar se encontraron valores anulados',
  invalid_document_number_set: 'Los números de inicio o final fueron usados para otra factura o recibo, cambielos e intentelo de nuevo',
  document_client_not_editable: 'El documento no puede ser editado',
  document_has_childs: 'No se puede anular porque hay otros documentos asociados',
  document_is_not_acceptable: 'El contrato no está en la etapa correcta para aceptar este documento',
  document_control_already_exists: 'El registro de control para este tipo de documento ya existe',
  serial_document_must_string: 'La serie del documento debe ser una cadena',
  initial_document_number_must_number: 'El número inicial de documento debe ser un número',
  final_document_number_must_number: 'El número final de documento debe ser un número',
  serial_control_must_string: 'La serie de control debe ser una cadena',
  initial_control_number_must_number: 'El número inicial de control debe ser un número',
  final_control_number_must_number: 'El número final de control debe ser un número',
  payment_note_already_paid: 'La nota de cobro ya fue pagada',
  client_document_already_exist: 'El documento ya existe',
  payment_note_already_exist: 'La nota de cobro ya existe',
  client_document_control_voided: 'El número para el control de documentos fue anulado',
  numbers_already_voided: 'Algunos de los números ya están anulados',
  no_numbers_void: 'No existen números de control a eliminar. Verifique que los números de control existen y no estén siendo usados',
  control_number_not_found:'Número de control no encontrado',
  need_more_specific_values: 'El rango dado abarca varios registros de Control de Documentos. Limítelo a solo uno.',
  // Material and Equipment
  no_equipmentmaterials_found: 'No se encontraron materiales o equipos',
  no_change_found: 'No hay cambios para guardar',
  equipmentmaterials_does_not_exist: 'No existe este material o equipo',
  serial_required_type_equipment: 'El serial del equipo es requerido',
  // Taxes
  category_required: 'Es necesario que seleccione una categoría',
  // Client Document
  employee_contract_not_found: 'Este contrato debe asignarse a un empleado, antes de realizar esta acción',
  control_number_finished: 'Los números de control asignados han finalizado',
  forbidden: 'No tiene los permisos necesarios',
  price_quotation_not_found: 'No se encontró una cotización en estado aceptada',
  register_does_not_exist: 'El registro no existe',
  client_document_types_not_found: 'Tipos de documentos del cliente no encontrados',
  client_document_status_not_found: 'Estados de documentos del cliente no encontrados',
  concept_types_not_found: 'Los tipos de Concepto no fueron encontrados',
  client_document_names_not_found: 'Nombres de los documentos del cliente no fueron encontrados',
  base_currency_not_found: 'Moneda base no encontrada',
  tax_not_found: 'No se han registrado impuestos',
  constant_ut_not_found: 'Constante UT no encontrada',
  constant_islr_not_found: 'Constante ISLR no encontrada',
  bank_not_found: 'No se han registrado bancos',
  exchange_rate_not_found: 'Tasas de cambio no encontradas',
  document_control_number_not_found: 'El número de control de documentos no fue encontrado',
  type_not_found: 'Tipo de documento no encontrado',
  only_price_quotation: 'Solo puede trabajar con Cotizaciones para este contrato en este momento',
  contract_status_invalid: 'Estado del contrato no es válido para ese documento',
  contract_not_found: 'Contrato no encontrado',
  services_not_found: 'Servicios no encontrados',
  concepts_not_found: 'Conceptos no encontrados',
  client_document_not_found: 'Documento del cliente no encontrado',
  voided_number_fail: 'Falló la anulación del documento, por favor intente nuevamente',
  actor_not_found: 'Usuario no encontrado',
  currency_not_found: 'Moneda no encontrada',
  document_type_invalid: 'El tipo de documento es inválido',
  contract_status_is_invalid: 'El estado del contrato es inválido',
  contract_status_required: 'El estado del contrato es requerido',
  document_status_is_invalid: 'El estado del documento es inválido',
  document_status_required: 'El estado del documento es requerido',
  document_type_is_invalid: 'El tipo de documento es inválido',
  document_type_required: 'El tipo de documento es requerido',
  was_send_must_boolean: 'Fue enviado debe ser un valor booleano',
  client_document_invalid: 'El documento seleccionado no es válido',
  description_must_json: 'Valor inválido',
  // Informe de Factibilidad
  version_required: 'La versión es obligatoria',
  elaboration_by_required: 'Elaborado por es obligatorio',
  elaboration_date_required: 'La fecha de elaboración es obligatoria',
  inspection_staff_required: 'Personal de inspección es obligatorio',
  inspection_date_required: 'La fecha de inspección es obligatoria',
  objective_required: 'El objectivo es obligatorio',
  observation_required: 'La observación es obligatoria',
  image_must_valid_image: 'La imagen debe ser JPG, JPEG o PNG',
  image_max_2048_kb: 'La imagen no debe pesar mas de 2MB',
  // Empleados
  employee_types_not_found: 'Tipos de empleado no encontrado',
  employee_type_already_registered: 'Ya existe un Analista de Cobranza registrado',
  franchise_not_found: 'Unidad de Negocio no encontrada',
  employee_not_found: 'Empleado no encontrado',
  some_contracts_unassigned: 'Algunos contratos no fueron asignados',
  some_contracts_not_found: 'Algunos contratos no fueron encontrados',
  employee_id_required: 'Debe seleccionar al cobrador que recibió el pago',
  employee_contracts_not_found: 'No se encontraron contratos asignados al empleado',
  multiple_employee_contracts_found: 'Se encontró mas de un contrato asignado al mismo tipo de empleado',
  employee_contract_address_not_found: 'No se encontró la dirección asociada al contrato del empleado',
  // Pagos
  months_advance_greater_than_0: 'Pagos a adelantar debe ser mayor a 0',
  exceed_max_payment_advanced: 'Meses a pagar exceden el monto máximo por adelantado',
  amount_paid_invalid: 'Verifique que el monto sea válido',
  amounts_different: 'El monto total a pagar no es válido',
  exchange_rate_required: 'La tasa de cambio es requerida',
  version_max_190: 'El campo versión no debe tener mas de 190 caracteres',
  elaboration_by_max_190:`El campo 'elaborado por' no debe tener mas de 190 caracteres`,
  inspection_staff_max_190: 'El campo Personal de inspección no debe tener mas de 190 caracteres',
  invalid_billing_type: 'Tipo de forma pago no válido',
  tax_bill_rate_required: 'La Tasa Fiscal es obligatoria',
  // Roles
  description_max_190: 'El campo descripción no debe tener mas de 190 caracteres',
  policies_required: 'El rol debe tener al menos un permiso asignado',
  // Sectores
  sector_name_already_registered: 'Ya existe un sector con el mismo nombre en la misma parroquia',
  sector_has_addresses: 'El sector está asociado a un abonado',
  sector_name_max_190: 'Un sector no debe tener un nombre con mas de 190 caracteres',
  sector_has_zones: 'El sector esta asociado a una zona',
  // Zonas
  zone_name_max_190: 'Una zona no debe tener un nombre con mas de 190 caracteres',
  // Carga Masiva de Historial de Facturación Antigua
  error_on_row: 'Error en la fila {0}',
  invoice_number_required: 'El Número de Factura es obligatorio',
  invoice_number_already_registered: 'El Número de Factura ya existe',
  invoice_date_required: 'La fecha de la Factura es obligatoria',
  invoice_date_invalid_date: 'El formato de la fecha es inválido',
  invoice_amount_required: 'El monto de la Factura es obligatorio',
  invoice_amount_must_number: 'El formato del monto es inválido',
  invoice_amount_greater_than_0: 'El monto debe ser mayor que cero',
  invoice_description_required: 'La descripción es obligatoria',
  // Banks (Catalogs)
  bank_has_accounts: 'El Banco tiene cuentas asociadas.',
  service_type_has_services: 'El Tipo de Servicio tiene Servicios asociados',
  // Money Received
  reception_date_required: 'La fecha de recepción del dinero es requerida',
  status_required: 'El estado del dinero recibido es obligatorio',
  source_required: 'El medio de recepción es obligatorio',
  currency_code_required: 'Debe seleccionar una moneda',
  user_id_required: 'Debe seleccionar un abonado',
}
