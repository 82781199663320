<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn fab
          v-on="on"
          x-small
          outlined
          absolute
          top
          right
          color="primary"
          class="floating-action"
          :class="fabNudged ? 'subscribers-groups-fab-nudged' : ''"
          @click="btnClicked"
        >
          <v-icon>fas fa-user-friends</v-icon>
        </v-btn>
      </template>
      <span>{{ $tc('roles.groups',1) }}<span v-if="group">: <b>{{ group.name }}</b></span>
        <span v-else>: <b>{{ $tc('base.none',1) }}</b></span>
      </span>
    </v-tooltip>
    <SelectorDialog
      ref="group_select_dialog"
      :group="group"
      @setGroupIdOne="setGroupID"
    />
  </div>
</template>

<script type="text/javascript">
import Dialog from './Dialog.vue'

export default {
  name: "subscribers-groups-fab",
  components: {
    SelectorDialog: Dialog
  },
  props: {
    // Class parent CSS
    classParent: {
      type: String,
      default: ""
    },
    group: {
      type: Object,
      default: function () {
        return {
          id: 0,
          name: this.$tc('base.none', 1)
        }
      }
    },
    // The Subscribers Group FAB must be nudged down
    fabNudged: {
      type: Boolean,
      default: false
    }/*,
    policies: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    }*/
  },
  data() {
    return {
      //
    }
  },
  created () {
    //
  },
  mounted () {
    //
  },
  computed:{
    //
  },
  methods: {
    btnClicked () {
      this.$refs.group_select_dialog.open()
    },
    setGroupID (group_obj) {
      this.$emit('setGroupIdTwo', group_obj)
    }
  }
}
</script>