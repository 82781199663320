<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="620"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        <span class="headline font-weight-bold primary--text">
          {{
            service.service_root_id == null
              ? $t(service.id != "" ? "service.edit" : "service.new")
              : $t(
                  service.id != ""
                    ? "service.edit_attribute"
                    : "service.new_attribute"
                )
          }}
        </span>
      </v-card-title>
      <v-card-text class="pb-1">
        <v-container fluid style="padding-top: 0 !important">
          <ValidationObserver ref="form_service" v-slot="{ handleSubmit }">
            <v-form
              class="service-form"
              @submit.prevent="handleSubmit(submit)"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="12" :sm="service.id != '' && !service.service_root_id ? 2 : 12"
                  v-if="service.id != ''"
                  class="py-0"
                >
                  <v-switch dense
                    v-model="service.active"
                    :label="$tc('active', 0)"
                    :disabled="!service.configurations"
                    color="primary"
                    class="small-label"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="10" v-if="!service.service_root_id" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="service_type"
                    :name="$t('service.service_type')"
                    rules="required"
                  >
                    <v-select
                      v-model="service.service_type"
                      :items="service_types"
                      item-text="name"
                      item-value="id"
                      name="role"
                      prepend-icon="fas fa-layer-group"
                      :label="$t('service.service_type')"
                      :loading="loading_types"
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  v-if="!service.configurations && service.id != ''"
                  class="py-0"
                >
                  <v-alert dense outlined type="info" class="body-2">
                    {{ $t("service.no_configs") }}
                  </v-alert>
                </v-col>
                <v-col cols="12" xs="12" sm="4" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="code"
                    :name="$t('code')"
                    rules="required|max:190"
                  >
                    <v-text-field
                      v-model="service.code"
                      name="code"
                      @keyup.enter="save"
                      :error-messages="errors"
                      :label="$t('code')"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" xs="12" sm="8" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="name"
                    :name="$t('name')"
                    rules="required|max:190"
                  >
                    <v-text-field
                      v-model="service.name"
                      name="name"
                      @keyup.enter="save"
                      :error-messages="errors"
                      :label="$t('name')"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea hide-details
                    v-model="service.description"
                    name="description"
                    rows="3"
                    :label="$t('description')"
                    class="pt-1"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="info" text @click="close">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">
            {{ $t("base.cancel") }}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="save">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">
            {{ $t("base.save") }}
          </span>
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "dialservice",
  data() {
    return {
      dialog: false,
      service: {
        id: "",
        service_root_id: null,
        active: true,
        code: "",
        name: "",
        description: ""
      },
      loading: false,
      service_types: [],
      loading_types: false
    };
  },
  methods: {
    open(value, root) {
      this.service =
        value != undefined
          ? Object.assign({}, value)
          : {
              id: "",
              active: false,
              service_root_id: root != undefined ? root : null,
              code: "",
              name: "",
              description: ""
            };
      this.loading = false;
      this.dialog = true;
      this.getServiceTypes()
    },
    close() {
      this.dialog = false;
      this.$refs.form_service.reset();
    },
    save() {
      this.$refs.form_service.validate()
      .then((success) => {
        if (success) {
          this.loading = true;
          if (this.service.id == "") {
            this.create();
          } else {
            this.update();
          }
        }
      });
    },
    create() {
      axios
        .post(this.$store.getters.getService(), this.service)
        .then(response => {
          this.$emit("serviceSaved");
          let modelService =
            this.service.service_root_id == null
              ? this.$tc("service.service", 1)
              : this.$tc("service.attributes", 2);
          let position = this.service.service_root_id == null ? 1 : 2;
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "success",
            timeout: 4000,
            notifText: "success",
            notifPos: position,
            extra: {
              model: modelService,
              action: this.$tc("created", position)
            }
          });
          this.close()
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      axios
        .put(this.$store.getters.getService(this.service.id), this.service)
        .then(response => {
          if (response.status == 200) {
            this.$emit("serviceSaved");
            let modelService =
              this.service.service_root_id == null
                ? this.$tc("service.service", 1)
                : this.$tc("service.attributes", 2);
            let position = this.service.service_root_id == null ? 1 : 2;
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: position,
              extra: {
                model: modelService,
                action: this.$tc("updated", position)
              }
            });
            this.close()
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getServiceTypes () {
      if (this.service_types.length == 0) {
        this.loading_types = true
        axios.get(this.$store.getters.catalogByCategory('service_type'))
          .then(response => {
            if (response.data.success) {
              this.service_types = response.data.success
            }
          })
          .finally(() => {
            this.loading_types = false;
          })
      }
    }
  }
};
</script>
