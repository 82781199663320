//
export default {
  name: "DialogResetDoc",
  components:{
  },
  data (){
    return {
      loading_reset_doc:false,
      dialog_reset_doc:false,
      type_doc:'',
      doc_control:{},
    };
  },
  computed: {
    computeTypeDoc(){
      return this.$t(this.type_doc);
    },
  },
  methods: {
    open_reset(item){
      this.dialog_reset_doc = true;
      this.type_doc = 'document_control.types.'+item.type;
      this.doc_control = item;
    },
    close_reset_doc(){
      this.dialog_reset_doc = false;
      this.type_doc = '';
      this.doc_control = {};
    },
    save_reset_doc(){
      axios.delete( this.$store.getters.getDocumentControlOverrided(this.doc_control.id))
      .then(()=>{
        this.$emit('updateList');
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifText: 'success',
          notifPos: 2,
          extra: {
            model: this.$t(this.type_doc),
            action: this.$tc('restored',2) 
          }
        });
        this.close_reset_doc();
      }).catch(error=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
  },
};