export default {
  name:'dialparam',
  data(){
    return {
      sortBy:[],
      sortDesc:[],
      mode: 1,
      dialog: false,
      param: {
        'id':'',
        'name':'',
        'service_id':null,
        'price':'',
        'measure':'',
        'upper_limit':'',
        'lower_limit':''
      },
      perPage: 10,
      page: 1,
      totalValues: 0,
      params: [],
      loading: false,
      service: '',
      rate_decimal_precision: 2,
      unwatch: '',
      upperValidation: {
        decimal:2,
        'dontallownegative':true
      },
      lowerValidation: {
        decimal:2,
        'dontallownegative':true
      }
    }
  },
  computed:{
    headers(){
      return [
        {
          text: this.$t('name'),
          align: 'left',
          value: 'name',
        },
        {
          text: this.$t('paymentNote.priceUnit'),
          align: 'right',
          value: 'price',
        },
        {
          text: this.$t('measure'),
          value: 'measure'
        },
        {
          text: this.$t('lower_limit'),
          align: 'right',
          value: 'lower_limit'
        },
        {
          text: this.$t('upper_limit'),
          align: 'right',
          value: 'upper_limit'
        },
        {
          text: this.$t('actions'),
          value: 'action',
          sortable: false ,
          align: 'right'
        }
      ]
    },
    computedPrice: {
      get () {
        let price = 0
        if (this.param && this.param.price) {
          price = this.param.price
        }
        // return price.toFixed(2)
        return parseFloat(price)
      },
      set (newVal) {
        this.param.price = newVal
      }
    }
  },
  watch:{
    sortDesc(){
      this.getParams()
    },
    perPage(){
      this.getParams()
    },
    page(){
      this.getParams()
    },
    'param.lower_limit':{
      handler(val){
        if(val != "" && typeof(val) == 'string'){
          this.upperValidation = {
            'min_value': val,
            'decimal': 2,
            'dontallownegative':true
          }
        }
      }
    }
  },
  methods: {
    open(serv){
      this.service = serv
      this.getParams()
      this.mode = 1
      this.dialog = true
    },
    async getParams(){
      let param = {
        page:this.page,
        paginate:this.perPage,
        service: this.service.id
      }
      if(this.sortDesc.length > 0){
        param['sortBy'] = this.sortBy[0]
        param['sortDesc'] = this.sortDesc[0]
      }
      let params = await axios.get(this.$store.getters.getParam(),{
        params:param
      })
      this.params = params.data.param.data
      this.totalValues = params.data.param.total
    },
    changeMode(number, value) {
      if(number != 1){
        this.param = value != undefined ? Object.assign({}, value) :
        {
          'id':'',
          'name':'',
          'service_id':this.service.id,
          'price':'',
          'measure':'',
          'upper_limit':'',
          'lower_limit':''
        }
        if (value && value.price) {
          this.rate_decimal_precision = this.$countDecimals(value.price)
        }
        this.loading = false
      } else {
        this.rate_decimal_precision = 2
        this.$refs.form_parameters.reset();
      }
      this.mode = number
    },
    close(){
      this.dialog = false
      this.service = ''
      this.params = []
    },
    save(){
      this.$refs.form_parameters.validate()
      .then((success) =>{
        if (success){
          this.loading = true
          if(this.param.id == ''){
            this.create();
          }
          else{
            this.update();
          }
        }
      })
    },
    create(){
      axios.post(this.$store.getters.getParam(),this.param)
      .then(response => {
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifText: 'success',
          notifPos: 1,
          extra: {
            model: this.$tc('service.params',2),
            action: this.$tc('created',1)
          }
        })
        this.getParams()
        this.mode = 1
        this.loading = false
      })
      .catch((error)=>{
        if (error.response.status == 422) {
          const err = error.response.data;
          if (err.error) {
            // eslint-disable-next-line no-console
            // console.log(err);
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    update(){
      axios.put(
        this.$store.getters.getParam(
          this.param.id),
          this.param
        )
      .then(response => {
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifText: 'success',
          notifPos: 1,
          extra: {
            model: this.$tc('service.params',2),
            action: this.$tc('updated',1)
          }
        })
        this.loading = false
        this.getParams()
        this.mode = 1
      })
      .finally(()=>{
        this.loading = false
      })
    },
    removed(param){
      this.$store.dispatch('confirmationValue',{
        show: true,
        title: this.$t('base.delete'),
        model:this.$tc('service.params',2),
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record:param.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getParam(param.id),
          method: 'delete'
        }
      })
    },
    formatPrice (price) {
      let decimals = this.$countDecimals(price)
      return this.$formatAmount(price, decimals)
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val && this.service != ''){
        this.getParams()
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  }
}
