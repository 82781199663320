<template>
  <div>
    <v-row
      class="py-0 mb-6 mb-md-0"
      :class="{ 'pt-3': fullscreen ? true : false }"
      justify="center"
      v-if="!its_preview"
    >
      <v-col cols="12" class="py-0">
        <v-row class="">
          <v-col cols="12" class="pt-0">
            <HeaderPayments
              :contract_id="contract_id"
              :employee_id="employee_id_copy"
              :subscriber="subscriber"
            ></HeaderPayments>
          </v-col>
          <v-col cols="6" sm="6" md="3" class="pb-0">
            <p class="title text-center">
              {{ $t("employees.payment_collector") }}
            </p>
          </v-col>
          <v-col cols="6" sm="6" md="3" class="pb-0">
            <ValidationObserver
              ref="payment_employee"
              v-slot="{ handleSubmit }"
            >
              <v-form
                class="payment-employee"
                @submit.prevent="handleSubmit(submit)"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="employee_field"
                  :name="$tc('employees.employee', 1)"
                  rules="required"
                >
                  <EmployeeSearch
                    v-model="employee_id_copy"
                    ref="employee_search"
                    name="employee_field"
                    :mountedList="false"
                    :onlyID="true"
                    :error-messages="errors"
                    :disabled_field="there_are_payments"
                    :payment_collector="true"
                  />
                </ValidationProvider>
              </v-form>
            </ValidationObserver>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto pb-0">
            <ExchangeRateSelect
              v-model="exchangeRate"
              :rates="ExchangeRates2"
              :base="BaseCurrency"
              :classInput="'my-0 pa-0'"
              :labelTag="$tc('system.exchange_rate', 1)"
              :disableField="there_are_payments"
              :prependIcon="$vuetify.icons.values.currencyexchange"
              showIcon
              showAppendOuterIcon
              :hideDetails="there_are_payments"
              :idExchangeRate="computedExchangeRateId"
              :taxBillRate1="taxBillRate2"
              ref="exchange_rate"
              @setTaxBillRate1="setTaxBillRate2"
              @change="ChangeExchangeRates($event)"
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- Help to Development -->
      <v-col
        cols="12"
        v-if="modeNode == 'development'"
        style="display: contents;"
      >
        <PaymentDev
          :its_preview="its_preview"
          :there_are_payments="there_are_payments"
          :decimal_places="decimal_places5"
          :rate_base="rate_base"
          :rate_new="rate_new"
          :formatRound="formatRound"
          :totals="Totals"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="modeNode == 'development'"
        style="display: contents;"
      >
        <v-card outlined class="card-outlined" style="margin-bottom: 0px">
          <v-card-title>
            <div class="title primary--text">
              <span class="px-1 white">
                Development
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <!-- <pre>{{ItemsPayments}}</pre> -->
            <v-btn block @click="PrintALl" :disabled="ItemsPayments.length == 0"
              >Pagos</v-btn
            >
            <v-radio-group
              row
              v-if="modeNode == 'development'"
              v-model="test2"
              @change="Balance"
            >
              <v-radio
                color="primary"
                :value="WithRetention"
              >
                <template v-slot:label>
                  Con Ret: {{ WithRetention | formatAmount(decimal_places5) }}
                </template>
              </v-radio>
              <v-radio
                color="primary"
                :value="WithOutRetention"
              >
                <template v-slot:label>
                  Sin Ret: {{ WithOutRetention | formatAmount(decimal_places5) }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Payments -->
      <v-col cols="12">
        <v-card
          outlined
          class="card-outlined"
          style="margin-bottom: -10px;margin-top: 0px"
        >
          <v-card-title>
            <div class="title primary--text">
              <span class="px-1 white">
                {{ $t("Payments.payment_recieved") }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="d-flex justify-center">
              <v-col>
                <!-- v-show="!there_are_payments && Totals[0].rest > 0.01 " -->
                <v-btn
                  block
                  text
                  dark
                  color="primary"
                  v-show="!there_are_payments && formatRound"
                  class="pr-2"
                  @click="newPayItem"
                >
                  <v-icon class="ml-auto">fas fa-plus</v-icon>
                </v-btn>
                <v-data-table
                  :headers="headers3"
                  :items="ItemsPayments"
                  hide-default-footer
                  dense
                  class="elevation-1 mx-0"
                >
                  <template v-slot:[`item.parent_id`]="{ item }">
                    <div :class="item.parent_id">
                      {{ $t("Payments.type." + item.parent_id) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bank`]="{ item }">
                    <div
                      class="text-truncate"
                      style="max-width: 220px; margin-right: -28px;"
                    >
                      {{ item.bank }}
                    </div>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <div :class="item.amount">
                      {{ symbol }}
                      {{ item.amount | formatAmount(decimal_places5) }}
                    </div>
                  </template>
                  <template
                    v-slot:[`item.action`]="{ item }"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          edit
                          v-show="!there_are_payments"
                          @click="editPayItem(item)"
                          icon
                          v-on="on"
                        >
                          <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("base.edit") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          delete
                          v-show="!there_are_payments"
                          @click="deletePayItem(item)"
                          icon
                          v-on="on"
                        >
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("base.delete") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Totals -->
      <v-col cols="12">
        <v-card
          outlined
          class="card-outlined"
          style="margin-top: 15px;"
        >
          <v-card-title>
            <div class="title primary--text">
              <span class="px-1 white">
                Total
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="d-flex justify-center">
              <v-col>
                <v-data-table
                  :headers="headers2"
                  :items="Totals"
                  hide-default-footer
                  dense
                  class="elevation-1 mx-0"
                >
                  <template v-slot:[`item.totalPay`]="{ item }">
                    {{ symbol }}
                    {{ item.totalPay | formatAmount(decimal_places5) }}
                  </template>
                  <template v-slot:[`item.totalRecibe`]="{ item }">
                    {{ symbol }}
                    {{ item.totalRecibe | formatAmount(decimal_places5) }}
                  </template>
                  <template v-slot:[`item.rest`]="{ item }">
                    <div :class="item.rest < 0 ? 'red--text' : ''">
                      {{ symbol }}
                      {{ item.rest | formatAmount(decimal_places5) }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Additional Lines -->
      <v-col cols="12">
        <v-card
          outlined
          class="card-outlined"
          style="margin-bottom: 10px; margin-top: 6px;"
        >
          <v-card-title>
            <div class="title primary--text">
              <span class="px-1 white">
                {{ $t('additional_info') }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters class="d-flex justify-center">
              <v-col cols="12" class="pa-0">
                <v-text-field dense outlined hide-details
                  v-for="(key, i) in Object.keys(lines)"
                  :key="key"
                  v-model="lines[key]"
                  :placeholder="`${$t('line')} ${i + 1}`"
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Dialog add payment -->
    <v-dialog
      v-model="dialog2"
      max-width="500px"
      transition="dialog-transition"
      @click:outside="close2"
      scrollable
    >
      <v-card tile>
        <v-toolbar dense tile>
          <v-toolbar-title>
            <span
              :class="
                fullscreen
                  ? 'subtitle-1 font-weight-bold py-1'
                  : 'headline font-weight-bold'
              "
            >
              <template>
                {{ $t("Payments.payment_method") }}
              </template>
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="fullscreen" text cancel @click="close2">
              <v-icon small>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <ValidationObserver ref="form_payments" v-slot="{ handleSubmit }">
            <v-form
              class="payments-form"
              @submit.prevent="handleSubmit(submit)"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="parent_id"
                :name="$t('Payments.payment_method')"
                rules="required"
              >
                <v-radio-group
                  v-model="pay.parent_id"
                  name="parent_id"
                  row
                  class="small-label"
                  :error-messages="errors"
                >
                  <template slot="prepend">
                    <span
                      class="caption font-weight-bold"
                      style="width:100px;padding-top: 2px;"
                    >
                      {{ $t("Payments.payment_method") }}:
                    </span>
                  </template>
                  <v-radio
                    color="primary"
                    :label="$t('Payments.type.cash')"
                    value="cash"
                  ></v-radio>
                  <v-radio
                    v-show="ListBanks.length != 0"
                    color="primary"
                    :label="$t('Payments.type.transfer')"
                    value="transfer"
                  ></v-radio>
                  <v-radio
                    v-show="ListBanks.length != 0"
                    color="primary"
                    :label="$t('Payments.type.card')"
                    value="card"
                  ></v-radio>
                </v-radio-group>
              </ValidationProvider>

              <!-- Help to Development -->
              <v-radio-group
                row
                v-if="modeNode == 'development'"
                v-model="test"
                @change="ChangeAmount"
                class="d-flex justify-space-around flex-ms-column"
              >
                <v-radio
                  v-show="modeNode == 'development'"
                  color="primary"
                  label="Total"
                  value="1"
                ></v-radio>
                <v-radio
                  v-show="modeNode == 'development'"
                  color="primary"
                  label="Mitad"
                  value="2"
                ></v-radio>
                <v-radio
                  v-show="modeNode == 'development'"
                  color="primary"
                  label="Tercio"
                  value="3"
                ></v-radio>
                <v-radio
                  v-show="modeNode == 'development'"
                  color="primary"
                  label="Cuarto"
                  value="4"
                ></v-radio>
              </v-radio-group>

              <ValidationProvider
                v-slot="{ errors }"
                vid="amount"
                :name="$t('concept.amount')"
                :rules="{
                  maxmoney: editPay
                    ? [MaxAmountEdit, decimal_places5]
                    : [MaxAmount, decimal_places5],
                  dontallownegative: true,
                  min_value: `1e-${decimal_places5 + 1}`
                }"
              >
                <moneyfield
                  v-model="pay.amount"
                  ref="moneyfield"
                  name="amount"
                  labelfield="concept.amount"
                  :error-messages="errors"
                  :innericonfield="symbol"
                  :nosymbol="true"
                  :iconfield="symbol"
                  :decimalprecision="decimal_places5"
                >
                </moneyfield>
              </ValidationProvider>

              <ValidationProvider
                v-show="pay.parent_id != 'cash' && ListBanks.length != 0"
                v-slot="{ errors }"
                vid="ref"
                :name="$t('Payments.ref')"
                :rules="payValidation"
              >
                <v-text-field
                  v-model="pay.ref"
                  name="ref"
                  :error-messages="errors"
                  :label="$t('Payments.ref')"
                  class="ma-0 pa-0"
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                v-show="pay.parent_id != 'cash' && ListBanks.length != 0"
                v-slot="{ errors }"
                vid="bank"
                :name="$t('Payments.bank')"
                :rules="payValidation"
              >
                <v-select
                  v-model="pay.bank_id"
                  name="bank"
                  return-object
                  dense
                  class="ma-0 pa-0"
                  :items="ListBanks"
                  :error-messages="errors"
                  :label="$t('Payments.bank')"
                >
                </v-select>
              </ValidationProvider>

              <v-text-field
                v-show="pay.parent_id != 'cash' && ListBanks.length != 0"
                v-model="pay.profit_code"
                name="profit_code"
                label="Profit"
                class="ma-0 pa-0"
              >
              </v-text-field>

              <DateDialog
                v-if="dialog2"
                v-model="pay.payment_date"
                nameTag="payment_date"
                showHint
                :disabled="!permissions.edit_payment_date"
                :labelTag="$t('validation.names.payment_date')"
                :type-date-tag="'date'"
                ref="date_dialog"
              />

              <span v-show="ListBanks.length == 0" class="span-text-alert">
                {{ $t("Payments.not_banks") }}
              </span>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text cancel @click="close2">
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
              $t("base.close")
            }}</span>
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
          <v-btn save @click="savePay">
            <template>
              <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
                $t("base.save")
              }}</span>
              <v-icon small>fas fa-save</v-icon>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.span-text-alert {
  color: #f44336cc;
}
</style>

<script>
import HeaderPayments from './HeaderPayments.vue';
import PaymentDev from './PaymentDev.vue';

const round = (value, decimals) => {
  if (typeof value == "number") {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  } else {
    return Number(Math.round(Number(value) + "e" + decimals) + "e-" + decimals);
  }
};

const roundMax = (value, decimals) => {
  let num = Number(value);
  let decimal_places = Number(decimals);
  return Number(num.toFixed(decimal_places));
};

const minValue = (value, min, decimals) => {
  if (Math.abs(value) < min) {
    return value.toFixed(decimals);
  } else {
    return value;
  }
};

const ReturnObject = value => {
  if (typeof value == "object") {
    return value;
  } else if (typeof value == "string" && value != "null") {
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "Payments",
  components: {
    HeaderPayments,
    PaymentDev
  },
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    its_preview: {
      type: Boolean,
      default: false
    },
    there_are_payments: {
      type: Boolean,
      default: false
    },
    Amount: {
      type: [Number, String],
      default: 0
    },
    employee_id: {
      type: [Number, String],
      default: ""
    },
    Banks: {
      type: [Object, Array, String],
      default: ""
    },
    // List of Payments
    ItemsPay: {
      type: [Object, Array, String],
      default: ""
    },
    decimal_places4: {
      type: [Number],
      default: 2
    },
    // List of all Exchange Rates
    ExchangeRates2: {
      type: [Object, Array]
    },
    // Exchange Rates of Document
    ExchangeRateDoc: {
      type: [Object, Array, String]
    },
    // Moneda base
    BaseCurrency: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    },
    taxBillRate2: {
      type: Object,
      default: function() {
        return {};
      }
    },
    AllTotals2: {
      type: [Object, Array, String]
    },
    contract_id:{
      type: [String, Number],
      default: ''
    },
    subscriber: {
      type: Object,
      default: function() {
        return { id: 0 };
      }
    }
  },
  data() {
    return {
      dialog2: false,
      mode: "total",
      employee_id_copy: this.employee_id,
      payments: {
        cash: [],
        transfer: [],
        card: []
      },
      pay: {
        ref: "",
        bank_id: "",
        amount: "",
        parent_id: ""
      },
      itemCash: 0,
      itemCard: 0,
      itemtransfer: 0,
      ItemsPayments: [],
      Totals: [{ totalPay: 0, totalRecibe: 0, rest: 0 }],
      MaxAmount: 0,
      MaxAmountEdit: 0,
      payValidation: {
        required: false
      },
      editPay: false,
      exchangeRate: "",
      ListBanks: [],
      rate_base: 1,
      rate_new: 1,
      symbol: "",
      NewExchangeRate: "",
      test: "1",
      test2: 0,
      WithOutRetention: 0,
      WithRetention: 0,
      modeNode: process.env.NODE_ENV,
      lines: {
        line1: '',
        line2: '',
        line3: '',
        line4: ''
      },
      decimal_places5: this.decimal_places4,
      permissions: {
        edit_payment_date: false
      }
    };
  },
  computed: {
    computedExchangeRateId () {
      let id = this.BaseCurrency.id
      if (this.NewExchangeRate.id) {
        if (typeof this.NewExchangeRate.id !== 'number') {
          id = parseInt(this.NewExchangeRate.id.match(/\d/g).join(''))
        } else {
          id = this.NewExchangeRate.id
        }
        if (this.NewExchangeRate.name == this.BaseCurrency.name) {
          id = `${id}x`
        }
      }

      return id
    },
    headers2() {
      return [
        {
          text: this.$t("paymentNote.totalPay"),
          value: "totalPay",
          align: "right",
          sortable: false
        },
        {
          text: this.$tc("Payments.name", 2),
          value: "totalRecibe",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("Payments.balance"),
          value: "rest",
          align: "right",
          sortable: false
        }
      ];
    },
    headers3() {
      let headers3 = [
        {
          text: this.$t("Payments.mode"),
          value: "parent_id",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("Payments.bank"),
          value: "bank",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("Payments.ref"),
          value: "ref",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("concept.amount"),
          value: "amount",
          align: "right",
          sortable: false
        }
      ];
      if (!this.there_are_payments) {
        headers3.push({
          text: this.$t("actions"),
          value: "action",
          sortable: false,
          align: "center"
        });
      }
      return headers3;
    },
    formatRound() {
      let num = Number(this.Totals[0].rest);
      let decimal_places = Number(this.decimal_places5);
      return (
        Number(num.toFixed(decimal_places)) > Number(`1e-${decimal_places + 1}`)
      );
    },
    defaultExchangeRate() {
      const exchange_rates = [...this.ExchangeRates2];
      const found = exchange_rates.find(rate => rate.default === true);
      if (found) {
        return found.id;
      } else {
        return this.BaseCurrency.id;
      }
    }
  },
  methods: {
    start() {
      if (this.there_are_payments) {
        let rate =
          this.ExchangeRateDoc.rate != undefined
            ? Number(this.ExchangeRateDoc.rate)
            : 1;
        if (
          this.ItemsPay.length != 0 &&
          this.ItemsPay != null &&
          this.ItemsPay != undefined
        ) {
          this.ItemsPayments = this.ItemsPay;
          this.ItemsPayments.map(pay => {
            pay.amount = pay.amount * rate;
          });
        }
      }
      // eslint-disable-next-line no-console
      // console.log('Totales',this.Totals);
      if (this.ExchangeRateDoc != null) {
        this.exchangeRate = this.ExchangeRateDoc.id;
        this.NewExchangeRate = this.ExchangeRateDoc;
        this.symbol = this.ExchangeRateDoc.symbol;
        this.rate_base =
          this.ExchangeRateDoc.rate != undefined
            ? Number(this.ExchangeRateDoc.rate)
            : 1;
        this.rate_new =
          this.ExchangeRateDoc.rate != undefined
            ? Number(this.ExchangeRateDoc.rate)
            : 1;
        this.test2 = this.AllTotals2.Total * this.rate_base;
        this.WithRetention = this.AllTotals2.Total * this.rate_base;
        this.WithOutRetention = this.AllTotals2.TotalWithOutR * this.rate_base;
        this.ChangeExchangeRates(this.ExchangeRateDoc);
      }
      this.Balance();
    },
    Balance() {
      let total = 0;
      let rate = Number(this.rate_new) / Number(this.rate_base);
      this.ItemsPayments.map(pay => {
        pay.amount = Number(pay.amount) * rate;
        total = total + pay.amount;
      });
      this.payments.cash = this.ItemsPayments.filter(function(type) {
        return type.parent_id === "cash";
      });
      this.payments.transfer = this.ItemsPayments.filter(function(type) {
        return type.parent_id === "transfer";
      });
      this.payments.card = this.ItemsPayments.filter(function(type) {
        return type.parent_id === "card";
      });
      this.payments.external = this.ItemsPayments.filter(function(type) {
        return type.parent_id === "external";
      });
      this.Totals[0].totalRecibe = total;
      if (this.there_are_payments) {
        this.Totals[0].totalPay = Number(this.test2) * rate + total;
      } else {
        this.Totals[0].totalPay = Number(this.test2) * rate;
      }
      let resto = this.Totals[0].totalPay - total;
      if (resto > -0.01) {
        this.Totals[0].rest = minValue(resto, 1e-3, 4);
      } else {
        this.Totals[0].rest = (0).toFixed(2);
      }
      this.MaxAmount = roundMax(resto, this.decimal_places5);
      this.$emit("Payments", {
        payments: this.payments,
        totalRecibe: total,
        totalRest: resto,
        totalPay: this.Totals[0].totalPay,
        employee_id: this.employee_id_copy,
        NewExchangeRate: this.NewExchangeRate,
        additionalLines: this.lines
      });
      this.test2 = this.test2 * rate;
      this.WithRetention = this.WithRetention * rate;
      this.WithOutRetention = this.WithOutRetention * rate;
      this.rate_base = this.rate_new;
      // eslint-disable-next-line no-console
      // console.log('test2',this.test2);
    },
    newPayItem() {
      this.editPay = false;
      this.dialog2 = true;
      if (this.Totals[0].rest > -0.001) {
        this.pay.amount = this.Totals[0].rest;
      } else {
        this.pay.amount = 0;
      }
      this.pay.payment_date = new Date()
        .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
        .substr(0, 10);
      this.pay.exchange_rate = this.NewExchangeRate;
    },
    editPayItem(item) {
      this.MaxAmountEdit = this.MaxAmount + item.amount;
      this.editedIndex = this.ItemsPayments.indexOf(item);
      this.editPay = true;
      this.dialog2 = true;
      this.pay = Object.assign({}, item);
    },
    createPay() {
      if (typeof this.pay == "object") {
        // eslint-disable-next-line no-console
        // console.log('Create',this.pay);
        this.ItemsPayments.push({
          parent_id: this.pay.parent_id,
          bank_id: this.pay.bank_id != null ? this.pay.bank_id.value : "",
          bank: this.pay.bank_id != null ? this.pay.bank_id.text : "",
          amount: this.pay.amount,
          ref: this.pay.ref,
          exchange_rate: this.pay.exchange_rate,
          payment_date: this.pay.payment_date,
          profit_code: this.pay.profit_code
        });
      }
    },
    updatePay() {
      let UPItem = {};
      if (typeof this.pay == "object") {
        UPItem = {
          parent_id: this.pay.parent_id,
          bank_id: this.pay.parent_id != "cash" ? this.pay.bank_id.value : "",
          bank: this.pay.parent_id != "cash" ? this.pay.bank_id.text : "",
          amount: this.pay.amount,
          ref: this.pay.parent_id != "cash" ? this.pay.ref : "",
          exchange_rate: this.pay.exchange_rate,
          payment_date: this.pay.payment_date,
          profit_code: this.pay.profit_code
        };
        // eslint-disable-next-line no-console
        // console.log('Up pay',UPItem, typeof(UPItem));
        Object.assign(this.ItemsPayments[this.editedIndex], UPItem);
      }
    },
    deletePayItem(item) {
      this.editedIndex = this.ItemsPayments.indexOf(item);
      this.ItemsPayments.splice(this.editedIndex, 1);
      this.Balance();
    },
    savePay() {
      this.$refs.form_payments.validate().then(success => {
        // eslint-disable-next-line no-console
        // console.log(this.errors.all());
        if (success) {
          if (this.editPay) {
            this.updatePay();
          } else {
            this.createPay();
          }
          this.ResetPayments();
          this.Balance();
        }
      });
    },
    close2() {
      this.dialog2 = false;
      this.ist_total = false;
      // eslint-disable-next-line vue/no-mutating-props
      this.there_are_payments = false;
      this.pay = { ref: "", bank_id: "", amount: "", parent_id: "" };
      this.editedIndex = "";
      this.test = "1";
      this.$refs.payment_employee.reset();
    },
    employeeValidate() {
      this.$refs.payment_employee.validate().then(success => {
        if (success) {
          this.$emit("DoPayments", this.employee_id_copy);
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    setTaxBillRate2 (taxBillRate) {
      this.$emit('setTaxBillRate2', taxBillRate)
    },
    ChangeExchangeRates(item) {
      //
      item.decimal_places =
        item.decimal_places != undefined && item.decimal_places != null
          ? Number(item.decimal_places)
          : 2;
      // Adjust the decimal places according to the currency
      this.decimal_places5 = item.decimal_places;
      // Delete the list every time you change currency
      if (!this.there_are_payments) {
        this.ItemsPayments = [];
      }
      // If the list of Banks has 0 items, by default select cash payments
      if (this.ListBanks.length == 0) {
        this.pay.parent_id = "cash";
      }
      let currency_code = item.currency_code;
      this.rate_new = Number(item.rate);
      this.symbol = item.symbol;
      this.NewExchangeRate = item;
      // Filter the list of banks according to the code of the selected currency
      this.ListBanks = this.Banks.filter(bank => {
        return bank.currency_code == currency_code;
      });
      this.Balance();
    },
    FindExchange(id) {
      let Exchange = this.ExchangeRates2.find(rate => {
        return rate.id == id;
      });
      return Exchange;
    },
    ChangeAmount() {
      this.pay.amount = this.Totals[0].rest / Number(this.test);
    },
    PrintALl() {
      // eslint-disable-next-line no-console
      console.log(this.ItemsPayments, this.ItemsPayments.length == 0);
    },
    PermissionsPayments() {
      this.permissions.edit_payment_date = true;
    },
    async ResetPayments() {
      await this.Balance();
      this.editPay = false;
      this.dialog2 = false;
      await this.close2();
    }
  },
  watch: {
    "pay.parent_id": {
      handler(val) {
        if (val != "" && typeof val == "string") {
          if (val != "cash") {
            this.payValidation = {
              required: true
            };
          } else {
            this.payValidation = {
              required: false
            };
          }
        }
      }
    }
  },
  mounted() {
    this.start();
    this.PermissionsPayments();
    // eslint-disable-next-line no-console
    // console.log('ExchangeRateDoc', this.ExchangeRateDoc);
    // eslint-disable-next-line no-console
    // console.log('ExchangeRates2', this.ExchangeRates2);
  }
};
</script>
