var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1 notif-table",attrs:{"headers":_vm.headers,"items":_vm.notifications,"mobile-breakpoint":0,"items-per-page":_vm.perPage,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.showNotification($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.$store.getters.isAdmin,"disableNew":_vm.loadingTable,"title":_vm.$tc('base.notification',1),"nameButton":_vm.$tc('base.new',2)},on:{"newItem":_vm.openBroadcast}})]},proxy:true},(_vm.totalValues > 0)?{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name,class:{
                  notReadMsg: item.read && !_vm.$vuetify.theme.dark,
                  notReadMsgDark: !item.read && _vm.$vuetify.theme.dark,
                  readMsgDark: item.read && _vm.$vuetify.theme.dark
                },on:{"click":function($event){return _vm.showNotification(item)}}},[_c('td',[_c('span',{class:{
                    'pl-2':true,
                    'font-weight-bold':!item.read
                  }},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.type == 'info' ? 'info_alt' : 
                            item.type == 'alert' ? 'error' :
                            item.type == 'tip' ? 'gray' :
                            item.type == 'upgrade' ? '#10ff00' :
                            item.type}},on),[_vm._v(" fas fa-"+_vm._s(_vm.getIcon(item.type))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('base.'+item.type))+" ")])])],1)]),_c('td',[_c('span',{class:{
                    'font-weight-bold':!item.read
                  }},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])]),_c('td',[_c('span',{class:{
                    'text-center':true,
                    'font-weight-bold':!item.read
                  }},[_vm._v(" "+_vm._s(item.created_at ? _vm.$moment(item.created_at).format(_vm.$store.getters.getDateFormat[0]): '- - - -')+" ")])])])}),0)]}}:null],null,true)})]],2)],1),(_vm.$store.getters.isAdmin)?_c('BroadcastNotificationDialog',{ref:"broadcast_notification_dialog"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }