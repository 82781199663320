import './FilterCatalog.scss';

export default {
  name: "catalog-filter",
  components: {},
  props:{
    /**
    * Class parent css
    */
    classParent:{
      type:String,
      default:''
    },
    /**
    * It is a Boolean and if it is true, displays the SEARCH field
    */
    showSearch:{
      type:Boolean,
      default:false,
    },
    /**
    * It is a Boolean and if it is true, displays the CURRENCY field
    */
    showCurrencies:{
      type:Boolean,
      default:false,
    },
    /**
    * It is a Boolean and if it is true, send the CURRENCIES ARRAY
    */
    sendCurrencies:{
      type:Boolean,
      default:false,
    },
    /**
    * It is a Boolean and if it is true, displays the COUNTRY field
    */
    showCountries:{
      type:Boolean,
      default:false,
    },
    /**
    * It is a Boolean and if it is true, send the COUNTRIES ARRAY
    */
    sendCountries:{
      type:Boolean,
      default:false,
    },
    /**
    * Boolean that disables all fields
    */
    disableFields:{
      type: Boolean,
      default: false,
    },
    /**
    * Change the name of the action that the button emits
    */
    emitSearch:{
      type:String,
      default: 'search',
    },
    /**
    * Change the delay time to output the search field
    */
    timeDelay:{
      type: Number,
      default: 500
    },
    /**
    * Change the minimum number of characters in the search field
    */
    minVal:{
      type: Number,
      default: 2
    },
  },
  data() {
    return {
      isErase: false,
      show:{
        currencies: false,
        countries: false,
      },
      load:{
        currencies: false,
        countries: false,
      },
      search: '',
      currency_code: '',
      country_id:'',
      currencies: [],
      countries: [],
      modeNode: process.env.NODE_ENV,
    }
  },
  computed: {},
  methods: {
    /**
     * Get All Currencies
     */
    getCurrencies(){
      if (this.showCurrencies || this.sendCurrencies) {
        this.load.currencies = true;
        axios.get(this.$store.getters.catalogByCategory('currency'))
        .then(response => {
          if (response.data.success) {
            this.currencies = response.data.success;
            // eslint-disable-next-line no-console
            this.$emit('currencies', this.currencies, 'currencies');
          }
        }).catch(error=>{
          this.currencies = [];
          // eslint-disable-next-line no-console
          console.error(error.response);
        }).finally(()=>{
          if (this.modeNode == 'development') {
            setTimeout(() => {
              this.load.currencies = false;
            }, 1500);
          } else {
            this.load.currencies = false;
          }
        });
      }
    },
    /**
     * Get All Countries
     */
    getCountries() {
      if (this.showCountries || this.sendCountries) {
        this.load.countries = true;
        axios.get(this.$store.getters.getAllCountries)
        .then(response => {
          this.countries = response.data.countries;
          this.$emit('countries', this.countries, 'countries');
        })
        .catch(error => {
          this.countries = [];
          // eslint-disable-next-line no-console
          console.error(error.response);
        }).finally(()=>{
          if (this.modeNode == 'development') {
            setTimeout(() => {
              this.load.countries = false;
            }, 1500);
          } else {
            this.load.countries = false;
          }
        });
      }
    },
    /**
     * Erase Filter
     */
    EraseFilter(){
      this.isErase = true;
      this.currency_code = null;
      this.country_id = null;
      this.search = '';
      this.$emit('eraseFilter', '');
      setTimeout(() => {
        this.isErase = false;
      }, 100);
    }
  },
  watch: {
    currency_code(val){
      if (!this.isErase) {
        this.$emit('currency_code', val, 'currency_code');
      }
    },
    country_id(val){
      if (!this.isErase) {
        this.$emit('country_id', val, 'country_id');
      }
    },
    search(val){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (val != null && val != undefined && val.length > this.minVal || val == '') {
          this.$emit(this.emitSearch, val, this.emitSearch);
        }
      }, this.timeDelay);
    },
  },
  mounted() {
    this.getCurrencies();
    this.getCountries();
  }
}
