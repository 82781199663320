export default {
  state:{
    notification:{
      text: '',
      user_id: null,
      group: ''
    },
    notifications: [],
    total: 0,
    last_read: {}
  },
  getters:{
    getAllNotifications: (state) => {
      return state.notifications
    },
    filterNotification: (state) => (id) => {
      return state.notifications.filter( not => {
        return not.id === id
      })[0] || []
    },
    getTotalNotifications: (state) => {
      return state.total
    },
    notificationReaded: (state) => {
      return state.last_read
    }
  },
  mutations:{
    ['NOTIFICATION'] (state,notification){
      state.notification = notification
    },
    ['ALL_NOTIFICATIONS'] (state,notifications){
      state.notifications = notifications
    },
    ['TOTAL_NOTIFICATIONS'] (state,total){
      state.total = total
    },
    ['MARK_NOTIFICATION_AS_READ'] (state, id){
      state.notifications = state.notifications.filter( not => {
        return not.id !== id
      }) || state.notifications
    },
    ['SEND_READED'] (state, not){
      state.last_read = not
    }
  },
  actions:{
    sendNotification({ getters, commit }, notification){
      return axios
        .post(getters.getNotification(), notification)
        .then(function (response) {
          if (response.data.success) {
            commit('NOTIFICATION', response.data.success)
            return response.data.success
          }
        })
        .catch(function (err) {
          return false
        })
    },
    getNotifications({ getters, commit, dispatch }){
      return axios
        .get(getters.getNotification(), {
          params: {
            read: false
          }
        })
        .then(function (response) {
          if (response.data.success) {
            dispatch('getAllNotifications')
            commit('ALL_NOTIFICATIONS', response.data.success.data)
            return response.data.success.data
          }
        })
        .catch(function (err) {
          return false
        })
    },
    markAsReadNotification({ getters, commit }, not){
      return axios
        .post(getters.markAsReadNotification(not.id))
        .then(function (response) {
          if (response.data.success) {
            commit('SEND_READED', not)
            commit('MARK_NOTIFICATION_AS_READ', not.id)
            return response.data.success
          }
        })
        .catch(function (err) {
          return false
        })
    },
    getAllNotifications({ getters, commit }){
      return axios
        .get(getters.getNotifications)
        .then(function (response) {
          if (response.data) {
            commit('TOTAL_NOTIFICATIONS', response.data.success)
            return response.data.success
          }
        })
        .catch(function (err) {
          return false
        })
    },
    clearNotifications({ commit }){
      commit('ALL_NOTIFICATIONS', [])
    },
    setClearNotifications({ commit }){
      commit('ALL_NOTIFICATIONS', [])
      commit('NOTIFICATION', {
        text: '',
        user_id: null,
        group: ''
      })
      commit('TOTAL_NOTIFICATIONS', 0)
      commit('SEND_READED', {})
    }
  }
}