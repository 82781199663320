export default {
  data() {
    return {
      group: {
        name: "",
        description: ""
      },
      dialog: false,
      loading: false,
      loadingTable: false,
      groups: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"]
      },
      totalValues: 0,
      edit: false,
      current_id: 0,
      unwatch: ""
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val) {
          this.getGroups();
        }
      }
    );
  },
  methods: {
    save() {
      this.$refs.form_group.validate().then(success => {
        if (success) {
          this.loading = true;
          if (this.edit == false) {
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    close() {
      this.$refs.form_group.reset();
      this.dialog = false;
      this.edit = false;
      this.group = {
        name: "",
        description: ""
      };
      this.current_id = 0;
    },
    newItem() {
      this.edit = false;
      this.dialog = true;
    },
    editItem(item) {
      this.group.name = item.name;
      this.group.description = item.description;
      this.current_id = item.id;
      this.edit = true;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        model: this.$tc("roles.groups", 1),
        title: this.$t("base.delete"),
        action: this.$tc("deleted", 1),
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: item.name
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.groupSources(item.id),
          method: "delete"
        }
      });
    },
    getGroups() {
      this.loadingTable = true;
      let ops = {
        params: {
          paginate: this.options.itemsPerPage,
          page: this.options.page
        }
      };
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0];
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0];
      }
      axios
        .get(this.$store.getters.groupSources(), ops)
        .then(response => {
          this.groups = response.data.groups.data;
          this.totalValues = response.data.groups.total;
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    create() {
      axios
        .post(this.$store.getters.groupSources(), this.group)
        .then(response => {
          if (response.data.success === "groups_restored_updated") {
            this.close();
            const text = this.$tc("roles.groups", 1);
            this.pushNoty(text, "restored_updated", 1);
            this.getGroups();
          } else if (response.status == 200) {
            this.close();
            const text = this.$tc("roles.groups", 1);
            this.pushNoty(text, "created", 1);
            this.getGroups();
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      axios
        .put(this.$store.getters.groupSources(this.current_id), this.group)
        .then(response => {
          if (response.status == 200) {
            this.close();
            const text = this.$tc("roles.groups", 1);
            this.pushNoty(text, "updated", 1);
            this.getGroups();
          }
        })
        .catch(err => {
          //
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Push success notification
     *
     * @param {string} model
     * @param {string} action
     * @param {number} pos
     * @param {number} time
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
          align: "left"
        },
        {
          text: this.$t("description"),
          value: "description",
          sortable: false
        },
        {
          text: this.$t("actions"),
          value: "action",
          sortable: false,
          align: "right",
          width: 120
        }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getGroups();
      },
      deep: true
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
    this.unwatch();
  }
};
