<template>
  <div class="section-roles">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!loadingGroups"
          color="info_alt"
          text
          dark
          small
          fixed
          top
          right
          fab
          class="mt-10"
          v-bind="attrs"
          v-on="on"
          @click.stop="col_help = !col_help"
          >
          <v-icon>fas fa-info-circle</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('base.help') }} </span>
    </v-tooltip>

    <v-row no-gutters>
      <v-col v-if="loadingGroups" cols="12" class="text-center pa-0 pb-1">
        <v-progress-circular
          :size="40"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <span class="caption primary--text pl-3">
          {{$t('base.loading')}}...
        </span>
      </v-col>
      <template v-else>
        <v-col v-if="col_help" cols="12" md="4" class="pt-8 pt-md-0 pb-12 pb-md-0">
          <span class="content-label pl-1 primary--text">
            {{ $t('base.help') }}
          </span>
          <div class="content-border">
            <seepermits/>
          </div>
        </v-col>
        <v-col cols="12" :md="col_help ? 4 : 6" class="section-expansion-panels py-0">
          <span class="content-label pl-1 primary--text">
            {{ $tc('roles.permissions', 2) }}
          </span>
          <div class="content-border">
            <v-expansion-panels
              v-model="panel"
            >
              <!-- multiple -->
              <v-expansion-panel
                v-for="(group,i) in groups"
                :key="i"
              >
                <v-expansion-panel-header @click="ListPolicies(group.name, i)">
                  {{ $tc('policy.'+group.name) }}
                  <template v-slot:actions>
                    <v-icon size="12" class="ml-4">
                      fas fa-arrow-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- <pre>{{group.chidrens}}</pre> -->
                  <!-- <v-col v-if="loadingPerGroup[i]" cols="12" class="text-center pa-0">
                    <v-progress-circular
                      :size="26"
                      :width="3"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <br>
                    <span class="caption primary--text pl-3">
                      {{$t('base.loading')}}...
                    </span>
                  </v-col> -->
                  <v-data-table
                    v-model="selected"
                    :items="group.chidrens"
                    :headers="headersGroup"
                    show-select
                    dense
                    hide-default-footer
                    class="elevation-1"
                    :footer-props="{
                      'disable-pagination': true,
                      'items-per-page-options': [200]
                    }"
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      {{ $te('policy.'+item.name) ?$tc('policy.'+item.name, 1) :$t('policy.translation_not_found') }}
                    </template>
                    <!-- <template v-slot:item="props">
                      <tr>
                        <td><v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox></td>
                        <td>{{ $te('policy.'+props.item.name) ?$tc('policy.'+props.item.name, 1) :$t('policy.translation_not_found') }}</td>
                      </tr>
                    </template> -->
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col cols="12" :md="col_help ? 4 : 6"
          class="sections-chips pt-8 pt-md-0 pb-12 pb-md-0"
          >
          <span class="content-label pl-1 primary--text">
            {{ $tc('base.selected', 2) }}
          </span>
          <div class="content-border" :class="$vuetify.breakpoint.smAndDown ? 'mobile-height' : ''">
            <v-chip
              v-for="(item, i) in selected"
              :key="item.name"
              class="ma-1"
              close
              color="info"
              outlined
              @click:close="item.status=false;selected.splice(i, 1)"
              small
              >
              <span text>
                {{ $te('policy.'+item.name) ?$tc('policy.'+item.name, 1) :$t('policy.translation_not_found') }}
              </span>
            </v-chip>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>

export default {
  props:{
    RolePolicies:{
      type:[Object, Array],
      default: function () {
        return {}
      }
    },
    actorPolicies:{
      type:[Object, Array],
      default: function () {
        return {}
      }
    },
    actorPoliciesSee:{
      default:false,
      type:Boolean
    },
  },
  data() {
    return {
      col_help: false,
      alliroles: true,
      panel: [],
      groups:[],
      policiesSelected:[],
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
      loadingGroups: true,
      loadingPerGroup: [],
      modeNode:process.env.NODE_ENV,
    }
  },
  computed: {
    headersGroup(){
      return [
        {
          text: this.$t('name'),
          value:'name',
          align: 'left',
          sortable: false,
        },
      ]
    }
  },
  methods:{
    allItems () {
      this.panel = [...Array(this.items.length).keys()].map((k, i) => i);
      // eslint-disable-next-line no-console
    },
    // Reset the panel
    noneItems () {
      this.panel = []
    },
    openPanelRoles(){
      let policies = this.RolePolicies
      let actorPolicies = this.actorPolicies
      // eslint-disable-next-line no-console
      if (policies.length != 0 && !this.actorPoliciesSee) {
        this.selected = policies;
        // this.AllPolicies();
      } else if(this.actorPoliciesSee){
        this.selected = actorPolicies;
      }
    },
    policyGroups(){
      this.loadingGroups = true
      axios.get(this.$store.getters.policyGroups)
        .then(response=>{
          let list =[];
          response.data.map(g=>{
            this.groups.push({name:g,chidrens:[]});
          });
        }).catch(error=>{
          // eslint-disable-next-line no-console
          // console.log(error);
        }).finally(()=>{
          this.loadingGroups = false
        });
    },
    ListPolicies(group, index){
      let nameGroup = group;
      // eslint-disable-next-line no-console
      let ops = null
      if (group !== undefined) {
        ops = {
          params: {
            'per_group': nameGroup
          }
        }
      }
      let findGroup = this.groups.findIndex(g=>g.name == nameGroup);
      let size = this.groups[findGroup]['chidrens'];
      if (size.length==0) {
        this.loadingPerGroup[index] = true
        axios.get(this.$store.getters.policiesSources(), ops)
          .then(response => {
            let policiesByGroup = [];
            this.AddChildrensToGroup(response.data.policies, findGroup);
          }).catch(error=>{
            // eslint-disable-next-line no-console
            // console.log(error.response);
          }).finally(()=>{
            this.loadingPerGroup[index] = false
          });
      }
    },
    AddChildrensToGroup(items, findGroup){
      let policies = Object.assign({},items);
      let children = [];
      let RolePolicies =  this.RolePolicies;
      // eslint-disable-next-line no-console
      if (!this.actorPoliciesSee) {
        for (const key in policies) {
          policies[key].status = false;
          children.push(policies[key])
        }
        this.groups[findGroup]['chidrens']=children;
      } else if (this.actorPoliciesSee) {
        for (var i in policies) {
          var igual=false;
          for (var j in RolePolicies) {
            if(policies[i]['id'] == RolePolicies[j]['id']) {
              igual=true;
            }
          }
          if(!igual)children.push(policies[i]);
        }
        this.groups[findGroup]['chidrens']=children;
      }
    },
    ListOfPolicies(){
      let listGroup = this.groups;
      let list = []
      listGroup.map(p=>{
        if (p.chidrens.length > 0) {
          p.chidrens.map(child=>{
            if (child.status) {
              list.push(child);
            }
          })
        }
      });
      this.policiesSelected = list;
    },
    AllPolicies(){
      let ops = null
      axios.get(this.$store.getters.policiesSources(), ops)
      .then(response => {
        let FilterPolicies = [];
        let data = response.data.policies;
        // eslint-disable-next-line no-console
        // console.log(data);
        this.RolePolicies.forEach(p => {
          let found = data.find(element => element.id == p);
          this.selected.push(found);
        });
      })
    },
  },
  watch: {
    selected: {
      handler(val){
        let policies= [];
        val.forEach(element => {
          policies.push(element.id);
        });
        // eslint-disable-next-line no-console
        // console.log(typeof(policies));
        this.$emit('SendPolices', policies);
      },
      deep: true,
    },
  },
  mounted(){
    this.openPanelRoles();
    this.policyGroups();
  },
}
</script>


<style lang="scss">
  .section-roles {
    --cut: 20rem;
    min-height: calc( 100vh - var(--cut) );

    .content-label {
      $size-label: 0.9rem;
      font-size: $size-label;
      line-height: $size-label*1.1;
    }

    [class*="col"] {
      padding: 0.5rem 0;
      .content-border {
        border-radius: 6px;
        border: 1px solid var(--v-info-base);
        width: calc(100% - 4px);
        margin: auto;
        max-height: calc( 100vh - var(--cut) );
        height: 100%; // min-height: 100%;
        overflow: auto;
        &.mobile-height {
          min-height: calc( 100vh - var(--cut) );
        }
      }
    }

    .section-expansion-panels {
      display: block;
      .theme--dark .v-expansion-panel button {
          color: white;
      }
      .v-expansion-panel {
        button {
            color: rgba(black, 0.7);
        }
        &.v-expansion-panel--active{
          button {
            color: var(--v-info-base) !important;
            box-shadow: inset 0px 0px 4px var(--v-info-base);
            font-weight: bold;
          }
        }
      }
    }

    .sections-chips {
      .content-border {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
         align-content: flex-start;
        .v-chip{
          flex: 1;
          min-width: calc( 100% / 3);
          border: 1px solid;
          padding-right: 0px;
          .v-chip__content {
            width: 100%;
            overflow: hidden;
            span[text] {
              max-width: calc(100% - 20px);
              color: rgba(black, 0.7);
              overflow: hidden;
              text-overflow: ellipsis;
            }
            button {
              margin-left: auto;
              margin-right: 0
            }
          }

          &.theme--dark  {
            .v-chip__content {
              span[text] {
                color: white;
              }
            }
          }
        }
      }
    }
  }
</style>
