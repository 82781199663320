export default {
  name: 'confirmations',
  data () {
    return {
      loading_del: false
    }
  },
  computed:{
    dinamicWidth(){
      let value = 400
      let wid = Math.round(
        `${this.$store.getters.getConfirmationTitle} ${this.$store.getters.getConfirmationModel}`.length/22
      )
      value += 120*wid
      return value
    },
    show_confirmation:{
      get(){
        return this.$store.getters.getConfirmationDisplay
      },
      set(newValue){
        this.$store.dispatch('confirmationValue',newValue)
      }
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('confirmationValue', false)
    },
    confirmAction () {
      this.$store.dispatch('acceptConfirmation')
    }
  }
}
