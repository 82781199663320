import ConceptDialog from "../Concept/ConceptDialog.vue";
import SubscriberSearch from "../Subscriber/suscriberSearch.vue";

const ReturnJson = value => {
  if (typeof value == "object") {
    return JSON.stringify(value);
  } else if (typeof value == "string" && value != "null") {
    return value;
  } else {
    return null;
  }
};
const ReturnObject = value => {
  if (typeof value == "object") {
    return value;
  } else if (typeof value == "string" && value != "null") {
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "paymentNotes",
  components: {
    ConceptDialog,
    SubscriberSearch: SubscriberSearch
  },
  props: ["contractProp"],
  data() {
    return {
      date: {
        start: false,
        end: false
      },
      disable_save: true,
      date_modal_start: false,
      date_modal_end: false,
      its_edit: false,
      edit: false,
      expand: false,
      fullscreen: false,
      loading: false,
      prospects_only: false,
      loadingDocumentTypes: true,
      loadingContracts: false,
      loadingExchangeRates: false,
      loadingTable: false,
      disabledContracts: true,
      disabledExchangeRates: true,
      companyInfo: this.$store.getters.companyInfo,
      concepts: [],
      ItemPago: [],
      ItemsConcept: [],
      contracts: [],
      TypeDocument: [],
      subscribersAll: [],
      ExchangeRates: [],
      baseCurrency: {},
      options: {},
      document_header: {},
      client_documents: {},
      subscriber_details: {},
      searchFor: {
        type: "payment_note",
        contract: ""
      },
      BillsDocument: {
        concepts: {},
        status: "pending",
        tax_rate: 0,
        tax_sale: 0
      },
      paramClient: {
        doc: "",
        legal_denomination: ""
      },
      editedIndex: "",
      editedItem: "",
      TypeDoc: "",
      billsName: "",
      date_start: "",
      date_end: "",
      number_days: "",
      number_days_month: "",
      TotalBase: "",
      TotalIva: "",
      Total: "",
      unwatch: "",
      ISRL: "",
      IVA: {},
      UT: "",
      symbol: "",
      decimal_places: 2,
      perPage: 10,
      min_day1: "",
      min_day2: "",
      max_day1: "",
      max_day2: "",
      num_month_1: [],
      num_month_2: [],
      today: new Date()
        .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
        .substr(0, 10),
      actor_id: localStorage.getItem("id")
    };
  },
  computed: {
    baseCurrencyId () {
      let val = 0
      if (this.baseCurrency.id) {
        val = this.baseCurrency.id
      }
      return val
    },
    headers() {
      return [
        {
          text: this.$t("paymentNote.quantity"),
          value: "quantity",
          sortable: false,
          align: "center",
          width: 40
        },
        {
          text: this.$t("paymentNote.description"),
          sortable: false,
          value: "name",
          width: 400
        },
        {
          text: this.$t("paymentNote.priceUnit"),
          value: "price",
          sortable: false,
          align: "right",
          width: 200
        },
        {
          text: this.$t("paymentNote.priceTotal"),
          value: "total",
          sortable: false,
          align: "right",
          width: 200
        },
        {
          text: this.$t("actions"),
          value: "action",
          sortable: false,
          align: "right",
          width: 110
        }
      ];
    },
    selectedDocumentTypes() {
      return this.TypeDocument.map(types => {
        return {
          value: types.value,
          text: this.$t(types.text)
        };
      });
    },
    selectExchangeRates() {
      return this.ExchangeRates.sort((a, b) =>
        a.name === this.base_currency_name ? -1 : a.name > b.name ? 1 : -1
      );
    },
    ReportTranslate() {
      return [this.$t("service." + this.subscriber_details.status)];
    },
    computeBillsName() {
      return this.$t(this.billsName);
    },
    computedDateFormatt() {
      let day = this.formatDate(this.today);
      this.BillsDocument.issue_date = this.today;
      return day;
    },
    computedDateFormattedStart() {
      let day = this.formatDate(this.date_start);
      this.BillsDocument.start_date = this.date_start;
      return day;
    },
    computedDateFormattedEnd() {
      let day = this.formatDate(this.date_end);
      this.BillsDocument.end_date = this.date_end;
      return day;
    },
    base_currency_name: {
      get() {
        return Object.getOwnPropertyNames(
          this.$store.getters.getBaseCurrency
        )[0];
      },
      set() {}
    },
    its_franchise: {
      get() {
        return this.$store.getters.isFranchise;
      },
      set() {}
    }
  },
  methods: {
    BillsTotal() {
      let find_rate = this.ExchangeRates.find(
        rate => rate.id == this.searchFor.exchangeRate // currency_code
      );
      let rate = 1;
      if (find_rate) {
        rate = Number(find_rate.rate);
      }
      // eslint-disable-next-line no-console
      // console.log('rate',this.concepts);
      // New funtion
      this.$store
        .dispatch("ClientDocCalculations", {
          doc: this.BillsDocument,
          concepts: this.concepts,
          rate: rate,
          tax_rate: this.BillsDocument.tax_rate, // RetIVA
          tax_sale: this.BillsDocument.tax_sale, // RetISRL
          num_month_1: this.num_month_1,
          num_month_2: this.num_month_2,
          IVA: this.IVA,
          UT: this.UT,
          ISRL: this.ISRL
        })
        .then(response => {
          // eslint-disable-next-line no-console
          if (
            response != null &&
            typeof response == "object" &&
            Object.prototype.hasOwnProperty.call(response, "concepts")
          ) {
            this.BillsDocument.concepts = response.concepts;
            this.ItemsConcept = response.ItemsConcept;
            this.ItemPago = response.ItemPago;
            this.disable_save = false;
          } else {
            this.disable_save = true;
            this.ItemsConcept = [];
            this.BillsDocument.concepts = [];
            this.ItemPago = [];
          }
        });
    },
    createItem(item) {
      this.concepts.push(item);
      this.BillsTotal();
    },
    editItem(item) {
      this.edit = true;
      this.editedIndex = this.concepts.indexOf(item);
      let exchange_rate = null;
      if (this.BillsDocument.exchange_rate != null) {
        exchange_rate = ReturnObject(this.BillsDocument.exchange_rate);
      } else {
        exchange_rate = ReturnObject(this.baseCurrency);
      }
      this.$refs.concept_dialog.open({
        concept: Object.assign({}, item),
        edit: true,
        exchange_rate: exchange_rate
      });
    },
    updateItem(item) {
      // eslint-disable-next-line no-console
      // console.log('recibe',item);
      Object.assign(this.concepts[this.editedIndex], item);
      // eslint-disable-next-line no-console
      // console.log(this.concepts);
      this.BillsTotal();
    },
    deleteItem(item) {
      this.editedIndex = this.concepts.indexOf(item);
      this.concepts.splice(this.editedIndex, 1);
      if (this.concepts.length == 0) {
        this.concepts = [];
      }
      this.BillsDocument.concepts = this.concepts;
      // eslint-disable-next-line no-console
      this.BillsTotal();
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    EraseFilter() {
      // eslint-disable-next-line no-console
      this.subscriber_details = {};
    },
    ChangeSubsCriber(item) {
      let subcriber = {};
      if (item != null && item != "" && typeof item == "object") {
        if (Object.prototype.hasOwnProperty.call(item, "contract")) {
          subcriber = item.contract.subscriber;
          this.BillsDocument.contract_id = item.contract.id;
        } else {
          subcriber = item;
        }
      }
      // eslint-disable-next-line no-console
      // console.log('item',item);
      if (
        typeof subcriber == "object" &&
        Object.prototype.hasOwnProperty.call(subcriber, "id")
      ) {
        let tax_rate =
          subcriber.tax_rate != "" && subcriber.tax_rate != null
            ? Number(item.tax_rate)
            : 0;
        let tax_sale =
          subcriber.tax_sale != "" && subcriber.tax_sale != null
            ? Number(item.tax_sale)
            : 0;
        this.subscriber_details = Object.assign({}, subcriber);
        this.BillsDocument.client_id = subcriber.id;
        this.BillsDocument.tax_rate = tax_rate;
        this.BillsDocument.tax_sale = tax_sale;
        this.disabledExchangeRates = false;
        // eslint-disable-next-line no-console
        // console.log(subcriber);
      }
    },
    /**
     * Client Document Header
     *
     * @public
     */
    ClientDocumentHeader() {
      this.BillsDocument.type = this.searchFor.type;
      this.billsName = "document_control.types." + this.searchFor.type;
      axios
        .post(this.$store.getters.getClientDocumentHeader, {
          actor_id: this.actor_id,
          type: this.searchFor.type
        })
        .then(response => {
          // eslint-disable-next-line no-console
          // console.log('document header', response.data);
          this.document_header = response.data;
          this.BillsDocument.control_number = response.data.control_number;
        })
        .catch(error => {
          if (error.response) {
            this.BillsDocument.control_number = "";
            // eslint-disable-next-line no-console
            // console.log('Error', error.response);
            this.document_header = {
              serial_document: "",
              control_number: ""
            };
          }
        });
    },
    getQueries() {
      this.ExchangeRates = [];
      this.loadingDocumentTypes = true;
      this.$store
        .dispatch("DocQuerys")
        .then(response => {
          // eslint-disable-next-line no-console
          // console.clear();
          this.baseCurrency = response.baseCurrency;
          this.ExchangeRates = response.ExchangeRates;
          this.TypeDocument = response.TypeDocument.filter(type => {
            if (this.its_franchise == true || this.its_franchise == "true") {
              return type.value != "invoice" && type.value != "price_quotation";
            } else {
              return type.value != "invoice";
            }
          });
          this.searchFor.exchangeRate = response.search_for_exchange;
          this.symbol = response.symbol;
          this.BillsDocument.currency_id = response.currency_id;
          this.UT = response.UT;
          this.IVA = response.IVA;
          this.ISRL = response.ISRL;
          this.getContractProp();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          // console.log("Error", error.response);
        })
        .finally(() => {
          this.loadingDocumentTypes = false;
        });
    },
    ChangeExchangeRates(item) {
      const clean = item.match(/\d/g).join('')
      // eslint-disable-next-line no-console
      // console.log('changeRate',item);
      let found = this.ExchangeRates.find(rate =>
        rate.name !== this.base_currency_name
          ? rate.id == clean
          : rate.id == `${clean}x`
      );
      this.symbol = found.symbol;
      this.decimal_places = found.decimal_places;
      let clone = Object.assign({}, found);
      if (!Number.isInteger(clone.id)) {
        clone.id = Number(clone.id.replace(/\D/g, ""));
      }
      this.BillsDocument.exchange_rate = JSON.stringify(clone);
      this.BillsTotal();
    },
    getEndDayMonth() {
      let date = new Date();
      if (this.its_edit == true) {
        date = new Date(date.getFullYear(), date.getMonth(), 1);
      }
      // var first_Day = new Date(date.getFullYear(), date.getMonth(), 1);
      let last_Day = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      if (this.date_start == "") {
        this.date_start = date
          .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
          .substr(0, 10);
      }
      if (this.date_end == "") {
        this.date_end = last_Day
          .toLocaleString("fr-CA", { timeZone: "America/Caracas" })
          .substr(0, 10);
      }
      this.getNumberDays();
    },
    getNumberDays() {
      this.$store
        .dispatch("NumberBillableDays", {
          start: this.date_start,
          end: this.date_end
        })
        .then(response => {
          if (
            Object.prototype.hasOwnProperty.call(response, "num_month_1") &&
            Object.prototype.hasOwnProperty.call(response, "num_month_2")
          ) {
            this.num_month_1 = response.num_month_1;
            this.num_month_2 = response.num_month_2;
            this.number_days = response.number_days;
            this.min_day1 = response.min_day1;
            this.min_day2 = response.min_day2;
            this.max_day1 = response.max_day1;
            this.max_day2 = response.max_day2;
            if (this.number_days > 31) {
              this.date_end = response.max_day2;
              this.getNumberDays();
            } else {
              this.BillsTotal();
            }
          }
        });
    },
    getContractProp() {
      if (this.contractProp != undefined) {
        // eslint-disable-next-line no-console
        this.searchFor.contract = this.contractProp.id;
        this.its_edit = true;
        this.ItemPago = [];
        this.ItemsConcept = [];
        this.BillsDocument = [];
        this.searchFor.type = "price_quotation";
        this.billsName = "document_control.types." + "price_quotation";
        this.BillsDocument.status = "pending";
        let build = {
          type: this.its_franchise ? "payment_note" : "price_quotation",
          status: "pending",
          contract_id: this.contractProp.id
        };
        // eslint-disable-next-line no-console
        this.buildDocument(build);
      } else {
        this.BillsDocument.exchange_rate = this.baseCurrency;
        this.BillsDocument.currency = this.baseCurrency;
        // eslint-disable-next-line no-console
        this.its_edit = false;
        this.getEndDayMonth();
        this.ClientDocumentHeader();
      }
    },
    buildDocument(build) {
      this.loadingTable = true;
      // eslint-disable-next-line no-console
      let url = this.its_franchise
        ? this.$store.getters.getDocumentBuildFranchise
        : this.$store.getters.getDocumentBuild;
      axios
        .post(url, build)
        .then(response => {
          // eslint-disable-next-line no-console
          // console.log('data build',response.data);
          this.date_start = response.data.client_document.start_date;
          this.date_end = response.data.client_document.end_date;
          this.getNumberDays();
          let client_document = response.data.client_document;
          let subscriber = response.data.subscriber;
          this.document_header.control_number = client_document.control_number;
          this.BillsDocument = client_document;
          // La magia para que agregue a la lista la tasa del documento
          let baseCurrencyDoc = this.ReturnExchangeRate(
            client_document.exchange_rate
          );
          if (baseCurrencyDoc != null && baseCurrencyDoc.rate != 1) {
            // eslint-disable-next-line no-console
            baseCurrencyDoc.id = `${baseCurrencyDoc.id}x`;
            baseCurrencyDoc.name =
              baseCurrencyDoc.name +
              " " +
              this.$moment(baseCurrencyDoc.updated_at).format(
                this.$store.getters.getDateFormat[0]
              );
            this.ExchangeRates.push(baseCurrencyDoc);
          }

          this.ChangeSubsCriber(subscriber);
          this.BillsDocument.start_date = this.date_start;
          this.BillsDocument.end_date = this.date_end;
          this.BillsDocument.tax_rate = subscriber.tax_rate;
          this.BillsDocument.tax_sale = subscriber.tax_sale;
          // eslint-disable-next-line no-console
          this.concepts = client_document.concepts;
          if (this.contractProp != undefined) {
            for (var i = 0; i < this.concepts.length; i++) {
              if (this.concepts[i].amount == 0) {
                this.$emit("Validate", false);
                this.emitNotification(
                  "warning",
                  6000,
                  "error422.concept_amount_invalid"
                );
              }
            }
          }
          // eslint-disable-next-line no-console
          // console.log('exchange_rate',response.data.client_document.exchange_rate);
          this.$store
            .dispatch("ExchangeRate", {
              exchange_rate: response.data.client_document.exchange_rate,
              base_currency: this.baseCurrency
            })
            .then(response => {
              if (
                Object.prototype.hasOwnProperty.call(response, "currency_code")
              ) {
                let name = response.exchange_rate.name;
                let idEx = response.id;
                // eslint-disable-next-line no-console
                // console.log('response',response);
                this.symbol = response.exchange_rate.symbol;
                if (response.exchange_rate.decimal_places) {
                  this.decimal_places = response.exchange_rate.decimal_places;
                }
                if (
                  name == this.base_currency_name &&
                  typeof idEx == "number"
                ) {
                  this.searchFor.exchangeRate = idEx + "x";
                } else {
                  this.searchFor.exchangeRate = idEx;
                }
                // eslint-disable-next-line no-console
                // console.log('search',this.searchFor.exchangeRate);
                this.BillsDocument.exchange_rate = JSON.stringify(
                  response.exchange_rate
                );
                // eslint-disable-next-line no-console
                // console.log(this.concepts);
                this.disabledExchangeRates = false;
                this.$emit("Doc", this.BillsDocument.id);
                this.BillsTotal();
              }
            });
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error.response);
            this.$emit("Error", error.response);
          }
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    ConceptDialog() {
      this.edit = false;
      let exchange_rate = null;
      if (
        this.BillsDocument.exchange_rate != null &&
        this.BillsDocument.exchange_rate != undefined
      ) {
        exchange_rate = ReturnObject(this.BillsDocument.exchange_rate);
      } else {
        exchange_rate = ReturnObject(this.baseCurrency);
      }
      // eslint-disable-next-line no-console
      // console.log(exchange_rate);
      this.$refs.concept_dialog.open({
        edit: false,
        exchange_rate: exchange_rate
      });
    },
    emitNotification(style, timeout, notifText, extra, pos) {
      this.$store.dispatch("pushNotification", {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      });
    },
    cancel() {
      this.$refs.subs_criber.clear2();
      this.concepts = [];
      this.ItemsConcept = [];
      this.ItemPago = [];
      this.document_header = [];
      this.subscriber_details = [];
      this.searchFor = {
        type: "payment_note",
        contract: "",
        exchangeRate: 1 + "x"
      };
      this.date_start = "";
      this.date_end = "";
      this.getEndDayMonth();
      this.prospects_only = false;
      this.BillsDocument = {
        concepts: {},
        status: "pending",
        tax_rate: 0,
        tax_sale: 0,
        exchange_rate: ReturnObject(this.baseCurrency),
        currency: ReturnObject(this.baseCurrency),
        currency_id: 1
      };
      this.disabledExchangeRates = true;
      this.getContractProp();
    },
    saveBills() {
      if (this.BillsDocument.control_number) {
        if (this.subscriber_details.doc) {
          this.BillsDocument.remark =
            this.computeBillsName +
            " - " +
            this.subscriber_details.doc +
            " - " +
            this.document_header.control_number;
          if (this.BillsDocument.contract_id) {
            if (this.BillsDocument.concepts != "") {
              if (this.its_edit) {
                this.updateDoc();
              } else {
                this.save();
              }
            } else {
              this.emitNotification(
                "error",
                6000,
                "document_control.errors.not_found_concepts"
              );
            }
          } else {
            this.emitNotification(
              "error",
              6000,
              "document_control.errors.not_found_contract"
            );
          }
        } else {
          this.emitNotification(
            "error",
            6000,
            "document_control.errors.not_found_dni"
          );
        }
      } else {
        this.emitNotification(
          "error",
          6000,
          "document_control.errors.not_found_control_number"
        );
      }
    },
    save() {
      this.loading = true;
      axios
        .post(this.$store.getters.getClientDocument(), this.BillsDocument)
        .then(response => {
          if (response.status == 200) {
            // eslint-disable-next-line no-console
            // console.log('Save document',response.data.client_document);
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: response.data.client_document.remark,
                action: this.$tc("created", 2)
              }
            });
            if (this.contractProp == undefined) {
              this.cancel();
            }
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log('Error',error.response);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDoc() {
      // eslint-disable-next-line no-console
      // console.log(this.BillsDocument);
      this.loading = true;
      axios
        .put(
          this.$store.getters.getClientDocument(this.BillsDocument.id),
          this.BillsDocument
        )
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 2,
              extra: {
                model: this.BillsDocument.remark,
                action: this.$tc("updated", 2)
              }
            });
          }
          if (this.contractProp != undefined) {
            this.$emit("Validate", true);
          }
        })
        .catch(error => {
          if (error.response) {
            if (this.contractProp != undefined) {
              this.$emit("Validate", false);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ReturnExchangeRate(exchange_rate) {
      // eslint-disable-next-line no-console
      if (exchange_rate != null && exchange_rate != undefined) {
        return ReturnObject(exchange_rate);
      } else {
        return null;
      }
    }
  },
  created() {},
  mounted() {
    this.getQueries();
  }
};
