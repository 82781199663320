<template>
  <div>
    <!-- <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info_alt"
          text
          dark
          small
          fixed
          top
          right
          fab
          class="mt-10"
          v-bind="attrs"
          v-on="on"
          @click.stop="dialog_see = true"
          >
          <v-icon>fas fa-info-circle</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('base.help') }} </span>
    </v-tooltip> -->

    <!-- <v-dialog v-model="dialog_see" max-width="600" scrollable >

      <v-card class="see-permits">

        <v-toolbar dense tile class="pr-0">
          <v-toolbar-title>
            <span class="subtitle-1 font-weight-bold py-1">
              {{ $t('policy.view_policy') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>

          <v-col v-for="(item_policies, i) in api_policies"
            :key="i" :id='`item-policies-${i}`'
            class="py-0 col-policies"  @click="selectItem(`item-policies-${i}`)"
            >
            <v-list dense class="py-0">
              <v-btn text block color="primary" class="justify-start">
                {{ $t(`policy.${item_policies.module}`) }}
              </v-btn>
              <template v-if="show_item_policies == `item-policies-${i}`">
                <span aria-selected="false" v-for="(item, j) in item_policies.actions" :key="j">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-text="$t(`policy.${item.name}`)"></v-list-item-title>
                      <v-list-item-subtitle
                        v-for="(item_descriptions, k) in item.descriptions" :key="k">
                        {{ $tc(`policy.${item_descriptions.name}`) }}
                        <span v-for="(item_d, n) in item_descriptions.items" :key="n"
                          class="pl-5 d-block">
                          {{ $tc('policy.'+item_d) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </template>
            </v-list>
          </v-col>

        </v-card-text>

        <v-card-actions>
            <v-btn text cancel @click="backPolicies()" v-if=" show_item_policies != '' ">
              <v-icon small>fas fa-arrow-left</v-icon>
              <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-5">{{$t('back')}}</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text cancel @click="backPolicies(), dialog_see = false">
              <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('close')}}</span>
              <v-icon small>fas fa-times</v-icon>
            </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog> -->

    <v-card class="see-permits">
      <v-card-text>
        <v-btn cancel x-small depressed @click="backPolicies()" v-if=" show_item_policies != '' " btn-back>
          <v-icon small>fas fa-arrow-left</v-icon>
          <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-5">{{$t('back')}}</span>
        </v-btn>

        <v-col v-for="(item_policies, i) in api_policies"
          :key="i" :id='`item-policies-${i}`'
          class="py-0 col-policies"  @click="selectItem(`item-policies-${i}`)"
          >
          <v-list dense class="py-0">
            <v-btn text block color="primary">
              {{ $t(`policy.${item_policies.module}`) }}
            </v-btn>
            <template v-if="show_item_policies == `item-policies-${i}`">
              <span aria-selected="false" v-for="(item, j) in item_policies.actions" :key="j">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t(`policy.${item.name}`)"></v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item_descriptions, k) in item.descriptions" :key="k">
                      {{ $tc(`policy.${item_descriptions.name}`) }}
                      <span v-for="(item_d, n) in item_descriptions.items" :key="n"
                        class="pl-5 d-block">
                        {{ $tc('policy.'+item_d) }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </template>
          </v-list>
        </v-col>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'SeePermits',
    data () {
      return {
        dialog_see: false,
        show_item_policies: false,
        api_policies: [
          {
            module: 'dashboard',
            actions: [
              {
                name: 'list',
                descriptions: [{name: 'index_subscriber' }]
              }
            ],
          },
          //
          {
            module: 'employee',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_employee'},
                  { name: 'employeeTypes_employee'},
                  {
                    name: 'only_corporate_user_or_admin',
                    items: [
                      'index_franchise'
                    ]
                  }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_employee'},
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'update_employee'}
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_employee'}
                ]
              },
              {
                name: 'assign_contracts',
                descriptions: [
                  { name: 'getAttachedContracts_employee'},
                  { name: 'attachContracts_employee'}
                ]
              },
              {
                name: 'deassign_contracts',
                descriptions: [
                  { name: 'getUnattachedContracts_employee'},
                  { name: 'unattachContracts_employee'},
                ]
              },
            ],
          },
          //
          {
            module: 'subscriber' ,
            actions: [
              {
                name: 'list',
                descriptions: [
                  {name: 'index_subscriber' }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_subscriber' },
                  { name: 'index_contractContact' },
                  { name: 'index_contractAddress' },
                  { name: 'index_contractService' },
                ]
              },
              {
                name: 'change_contracts_billing_type',
                descriptions: [
                  { name: 'changeBilling_contract' },
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'index_typeClient' },
                  { name: 'update_subscriber' },
                ]
              },
            ],
          },
          //
          {
            module: 'requests',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_contract' }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'requestInfo_contract' },
                  { name: 'index_contractService' }
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_subscriber' },
                  { name: 'getByDoc_subscriber' },
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'show_subscriber' },
                  { name: 'contractAddrContactServ_contract' },
                ]
              },
              {
                name: 'commons_for_create_edit',
                descriptions: [
                  { name: 'index_typeClient' },
                  { name: 'index_employee' },
                  { name: 'index_servicePackage' },
                  { name: 'index_contractService' },
                  { name: 'store_sector' },
                  { name: 'store_contractAddress' },
                  { name: 'store_contractContact' },
                  { name: 'update_contract' },
                  { name: 'update_subscriber' },
                  { name: 'update_contractAddress' },
                  { name: 'update_contractContact' },
                  { name: 'destroy_contractService' },
                  { name: 'parishSectors_sector' },
                  { name: 'storeWithPack_contractService' },
                ]
              },
              {
                name: 'only_corporate_subscriber',
                descriptions: [
                  { name: 'index_service' },
                  { name: 'index_serviceParam' },
                  { name: 'store_contractService' },
                  { name: 'store_contractServiceParam' },
                  { name: 'show_contractService' },
                  { name: 'show_contractServiceParam' },
                  { name: 'update_contractService' },
                  { name: 'update_contractServiceParam' },
                ]
              },
            ],
          },
          //
          {
            module: 'fiber_feasibility_reports',
            actions: [
              {
                name: 'list_pendign_request',
                descriptions: [
                  { name: 'index_feasibilityReport' }
                ]
              },
              {
                name: 'list_reports_active_closed',
                descriptions: [
                  { name: 'list_feasibilityReport' }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'index_subscriberFileLibrary' },
                  { name: 'show_feasibilityReport' },
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_feasibilityReport' }
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'update_feasibilityReport' }
                ]
              },
              {
                name: 'report_approve',
                descriptions: [
                  { name: 'changestatus_feasibilityReport' }
                ]
              },
              {
                name: 'report_deny',
                descriptions: [
                  { name: 'infeasibleService_feasibilityReport' }
                ]
              },
            ],
          },
          //
          {
            module: 'network_feasibility_reports',
            actions: [
              {
                name: 'list_pendign_request',
                descriptions: [
                  { name: 'index_feasibilityNetwork' }
                ]
              },
              {
                name: 'list_reports_active_closed',
                descriptions: [
                  { name : 'list_feasibilityNetwork' }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'index_subscriberFileLibrary'},
                  { name: 'show_feasibilityNetwork'},
                  { name: 'show_equipmentMaterials'},
                  { name: 'add_equipment_materials',
                    items: [
                      'index_contractService',
                      'index_equipmentMaterials',
                      'show_equipmentMaterials',
                    ]
                  },
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_feasibilityNetwork' }
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'update_feasibilityNetwork' }
                ]
              },
              {
                name: 'report_approve',
                descriptions: [
                  { name: 'changestatus_feasibilityNetwork' }
                ]
              },
              {
                name: 'report_deny',
                descriptions: [
                  { name: 'infeasibleService_feasibilityNetwork' }
                ]
              },
            ],
          },
          //
          {
            module: 'services_sale',
            actions: [
              {
                name: 'list_pendign_request',
                descriptions: [
                  { name:'index_acceptanceLetter'},
                ]
              },
              {
                name: 'awaiting_request',
                descriptions: [
                  { name: 'show',
                    items: [
                      'invoiceQueries_clientDocument',
                      'index_conceptMotive',
                      'getClientDocument_clientDocument',
                    ]
                  },
                  { name: 'update_document',
                    items: [
                      'update_clientDocument',
                      'makeClientDocumentPDF_clientDocument',
                    ]
                  },
                  { name: 'service_proposal',
                    items: [
                      'index_subscriberFileLibrary',
                      'store_subscriberFileLibrary',
                    ]
                  },
                  { name: 'send_mail',
                    items: [
                      'askingEmail_contract',
                    ]
                  },
                ]
              },
              {
                name: 'request_approve',
                descriptions: [
                  { name: 'store_acceptanceLetter'},
                  { name: 'send_acceptanceLetter'},
                ]
              },
              {
                name: 'acceptance_letter_send',
                descriptions: [
                  { name: 'show_contract'},
                  { name: 'changeProspectCondition_subscriber'},
                ]
              },
            ],
          },
          //
          // {
          //   module: 'billing',
          //   actions: [
          //     {
          //       name: 'All billing views',
          //     },
          //   ],
          // },
          //
          {
            module: 'billing_request',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'invoiceQueries_clientDocument'},
                  { name: 'getClientDocumentContract_clientDocument'},
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'getClientDocumentContract_clientDocument'},
                  { name: 'only_franchise_subscriber',
                    items: [
                      'getFranchiseClientDocument_clientDocument',
                    ]
                  },
                ]
              },
              {
                name: 'edit_Only_by_franchise_subscriber',
                descriptions: [
                  { name: 'index_conceptMotive'},
                  { name: 'update_clientDocument'},
                ]
              },
              {
                name: 'pdf',
                descriptions: [
                  { name: 'makeClientDocumentPDF_clientDocument'},
                  { name: 'makePDF_clientDocument'},
                  { name: 'sendClientDocumentEmail_clientDocument'},
                ]
              },
              {
                name: 'document_search_by_id_or_legal_name',
                descriptions: [
                  { name: 'invoiceableContracts_clientDocument'},
                ]
              },
              {
                name: 'generate_payment_note',
                descriptions: [
                  { name: 'getClientDocument_clientDocument'},
                ]
              },
              {
                name: 'voided',
                descriptions: [
                  { name: 'update_clientDocument'},
                ]
              },
              {
                name: 'create_payment',
                descriptions: [
                  { name: 'index_employee'},
                  { name: 'store_proofPayment'},
                ]
              },
            ],
          },
          //
          {
            module: 'payment_reconciliation',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'invoiceQueries_clientDocument'},
                  { name: 'index_clientDocument'},
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_clientDocument'},
                ]
              },
              {
                name: 'pdf',
                descriptions: [
                  { name: 'makeClientDocumentPDF_clientDocument'},
                  { name: 'makePDF_clientDocument'},
                  { name: 'sendClientDocumentEmail_clientDocument'},
                ]
              },
              {
                name: 'document_search_by_id_or_legal_name',
                descriptions: [
                  { name: 'invoiceableContracts_clientDocument'},
                ]
              },
              {
                name: 'search_employee',
                descriptions: [
                  { name: 'index_employee'},
                ]
              },
              {
                name: 'create_payment',
                descriptions: [
                  { name: 'store_proofPayment'},
                ]
              },
            ],
          },
          //
          {
            module: 'management',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'invoiceQueries_clientDocument'},
                  { name: 'index_clientDocument'},
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_clientDocument'},
                ]
              },
              {
                name: 'pdf',
                descriptions: [
                  { name: 'makeClientDocumentPDF_clientDocument'},
                  { name: 'makePDF_clientDocument'},
                  { name: 'sendClientDocumentEmail_clientDocument'},
                ]
              },
              {
                name: 'document_search_by_id_or_legal_name',
                descriptions: [
                  { name: 'invoiceableContracts_clientDocument'},
                ]
              },
              {
                name: 'search_employee',
                descriptions: [
                  { name: 'index_employee'},
                ]
              },
              {
                name: 'voided',
                descriptions: [
                  { name: 'update_clientDocument'},
                ]
              },
              {
                name: 'replicated',
                descriptions: [
                  { name: 'createFromDocument_clientDocument'},
                ]
              },
              {
                name: 'generate_payment_note',
                descriptions: [
                  { name: 'getClientDocument_clientDocument'},
                ]
              },
            ],
          },
          //
          {
            module: 'documents',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_conceptMotive'},
                  { name: 'invoiceQueries_clientDocument'},
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_clientDocument'},
                ]
              },
              {
                name: 'new_control_number_to_create_document',
                descriptions: [
                  { name: 'clientDocumentHeader_clientDocument'},
                ]
              },
              {
                name: 'document_search_by_id_or_legal_name',
                descriptions: [
                  { name: 'invoiceableContracts_clientDocument'},
                ]
              },
            ],
          },
          //
          {
            module: 'concepts',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'invoiceQueries_clientDocument'},
                  { name: 'index_concept'},
                  { name: 'index_conceptMotive'},
                ]
              },
              {
                name: 'crud',
                descriptions: [
                  { name: 'store_conceptMotive'},
                  { name: 'show_conceptMotive'},
                  { name: 'update_conceptMotive'},
                  { name: 'destroy_conceptMotive'},
                ]
              },
            ],
          },
          //
          {
            module: 'service_instalation',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_serviceInstallation'},
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'index_contractService'},
                  { name: 'index_contractInstalledHardware'},
                  { name: 'show_serviceInstallation'},
                  { name: 'index_subscriberFileLibrary'},
                ]
              },
              {
                name: 'update_approve_service_installation_approve_start_of_the_trial_period',
                descriptions: [
                  { name: 'update_serviceInstallation'},
                ]
              },
              {
                name: 'acceptance_letter',
                descriptions: [
                  { name: 'store_subscriberFileLibrary'},
                ]
              },
            ],
          },
          //
          {
            module: 'voidedNumber',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_voidedNumber'},
                ]
              },
               {
                name: 'voided',
                descriptions: [
                  { name: 'store_voidedNumber'}
                ]
              },
              {
                name: 'restore',
                descriptions: [
                  { name: 'destroy_voidedNumber'}
                ]
              }
            ],
          },
          //
          {
            module: 'report',
            actions: [
              {
                name: 'module_base_permissions',
                descriptions: [
                  { name: 'index_conceptMotive'},
                  { name: 'get_contract'},
                  { name: 'index_employee'},
                  { name: 'currencyCodes_exchangeRate'},
                  { name: 'index_franchise'},
                  { name: 'index_sector'}
                ]
              },
              {
                name: 'getSalesBook_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'getSalesBook_report'}
                ]
              },
              {
                name: 'getVoidList_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'getVoidList_report'}
                ]
              },
              {
                name: 'incomeByDate_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'incomeByDate_report'}
                ]
              },
              {
                name: 'incomeByCollector_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'incomeByCollector_report'}
                ]
              },
              {
                name: 'incomeByConcept_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'incomeByConcept_report'}
                ]
              },
              {
                name: 'dailyIncome_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'dailyIncome_report'}
                ]
              },
              {
                name: 'incomeBySector_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'incomeBySector_report'}
                ]
              },
              {
                name: 'incomeByConceptGroupBy_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'incomeByConceptGroupBy_report'}
                ]
              },
              {
                name: 'collectionList_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'collectionList_report'}
                ]
              },
              {
                name: 'billingDetailByConcept_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'billingDetailByConcept_report'}
                ]
              },
              {
                name: 'dailyCashRegister_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'dailyCashRegister_report'}
                ]
              },
              {
                name: 'dailyCashRegisterExternal_report',
                descriptions: [
                  { name: 'module_base_permissions'},
                  { name: 'dailyCashRegisterExternal_report'}
                ]
              }
            ],
          },
          //
          {
            module: 'sector',
            actions: [
               {
                name: 'list',
                descriptions: [
                  { name: 'index_sector'},
                  { name: 'index_zone'},
                  {
                    name: 'only_corporate_user_or_admin',
                    items: [
                      'index_franchise'
                    ]
                  }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_sector'},
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_sector'},
                  { name: 'parishSectors_sector'}
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'show_sector'},
                  { name: 'update_sector'},
                  { name: 'parishSectors_sector'}
                ]
              },
              {
                name: 'delete',
                descriptions: [
                  { name: 'destroy_sector'},
                ]
              },
            ],
          },
          //
          {
            module: 'zone',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_zone'},
                  {
                    name: 'only_corporate_user_or_admin',
                    items: [
                      'index_franchise'
                    ]
                  }
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_zone'},
                ]
              },
              {
                name: 'create',
                descriptions: [
                  { name: 'store_zone'}
                ]
              },
              {
                name: 'update',
                descriptions: [
                  { name: 'update_zone'}
                ]
              },
              {
                name: 'delete',
                descriptions: [
                  { name: 'destroy_zone'},
                ]
              },
              // {
              //   name: 'crud',
              //   descriptions: [
              //     { name: 'index_zone'},
              //     { name: 'store_zone'},
              //     { name: 'show_zone'},
              //     { name: 'update_zone'},
              //     { name: 'destroy_zone'}                ]
              // }
            ],
          },
          {
            module: 'all_subscribers_cds',
            actions: [
              {
                name: 'list',
                descriptions: [
                  { name: 'index_contractAddress'},
                  { name: 'index_contractContact'},
                  { name: 'index_contractService'},
                  { name: 'index_service'},
                  { name: 'index_servicePackage'},
                  { name: 'index_subscriberFileLibrary'},
                  { name: 'index_typeClient'},
                  { name: 'index_franchise'},
                ]
              },
              {
                name: 'show',
                descriptions: [
                  { name: 'show_subscriber'},
                  { name: 'allCDS_subscriber'},
                ]
              },
            ],
          },
          /*
          {
            module: '',
            actions: [
              {
                name: '',
                descriptions: [
                  { name: ''},
                  { name: ''},
                  { name: ''},
                  { name: '',
                    items: [
                      '',
                      '',
                      '',
                    ]
                  },
                ]
              },
            ],
          },
          */
        ],
      }
    },
    methods:{
      selectItem(item){
        this.show_item_policies = item
        this.apiPolicies(item)
      },
      backPolicies() {
        this.show_item_policies = ''
        this.apiPolicies()
      },
      apiPolicies(item) {
        const items_api_policies = document.querySelectorAll('.col-policies')
        if (items_api_policies !== undefined ) {
          items_api_policies.forEach( function(item_policies) {
            if ( item !== undefined ) {
              item_policies.id == item ?  item_policies.classList.remove("d-none") : item_policies.classList.add("d-none")
            }
            else {
              item_policies.classList.remove("d-none")
            }
          });
        }
      }
    }
  }
</script>

<style lang="scss">
  .see-permits {
    width: 100%;
    button {
      width: 100%;
      .v-btn__content {
        width: 100%;
        display: block;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &[btn-back] {
        position: sticky;
        top: 0;
        z-index: 1
      }
    }
  }
</style>
