export let permission_en = {
  // acceptanceLetter
  'acceptanceLetter': 'Acceptance Letters',
  'index_acceptanceLetter': 'Acceptance Letters: List',
  'store_acceptanceLetter': 'Acceptance Letter: Create',
  'show_acceptanceLetter': 'Acceptance Letter: View',
  'update_acceptanceLetter': 'Acceptance Letter: Update',
  'destroy_acceptanceLetter': 'Acceptance Letter: Delete',
  'generatePDF_acceptanceLetter': 'Generate PDF of the Acceptance Letter',
  'send_acceptanceLetter': 'Send Acceptance lLetter',
  // bankAccount
  'bankAccount': 'Bank Accounts',
  'index_bankAccount': 'Bank Account: List',
  'store_bankAccount': 'Bank Account: Create',
  'show_bankAccount': 'Bank Account: View',
  'update_bankAccount': 'Bank Account: Update',
  'destroy_bankAccount': 'Bank Account: Delete',
  // catalog
  'catalog': 'General Settings',
  /* ---- Subscribers Groups ---- */
  'indexSubscribersGroups_catalog': 'Subscriber\'s Groups: List',
  'storeSubscribersGroups_catalog': 'Subscriber\'s Group: Create',
  'showSubscribersGroups_catalog': 'Subscriber\'s Group: View',
  'updateSubscribersGroups_catalog': 'Subscriber\'s Group: Update',
  'destroySubscribersGroups_catalog': 'Subscriber\'s Group: Delete',
  /* ---- Corporate Identity ---- */
  'indexCorporateIdentity_catalog': 'Corporate Identity: List',
  'storeCorporateIdentity_catalog': 'Corporate Identity: Create',
  'updateCorporateIdentity_catalog': 'Corporate Identity: Update',
  'destroyCorporateIdentity_catalog': 'Corporate Identity: Delete',
  // CDS
  'all_subscribers_cds': 'All Subscribers (CDS)',
  // clientDocument
  'clientDocument': 'Client Documents',
  'clientDocumentTypes_clientDocument': 'List types of documents|Types of documents: quote, collection note, credit note, invoice',
  'clientDocumentStatus_clientDocument': 'List document statuses|Document statuses: pending, paid, canceled, accepted, canceled, replicated',
  'invoiceQueries_clientDocument': 'Essential data for billing|Lists associated with documents: Banks, base currency, exchange rates, concept types, tax list, customer types',
  'invoiceableContracts_clientDocument': 'Fact. Search of billable contracts|Returns the contracts which can be generated a document',
  'getHeader_clientDocument': 'Control number to create document|Method that obtains the consecutive number of control for a document to create',
  'clientDocumentHeader_clientDocument': 'Fact. New control number to create document|Method used to request a new control number and returns the corresponding consecutive ',
  'getDates_clientDocument': 'Dates associated with a document|Get the creation, expiration and cut dates associated with a document',
  'index_clientDocument': 'Fact. Documents: List ',
  'store_clientDocument': 'Fact. Documents: Create',
  'clientDocumentConcepts_clientDocument': 'Associate concepts to a new document|Saves and associates the concepts during the creation of a new document',
  'show_clientDocument': 'Fact. Documents: View',
  'update_clientDocument': 'Fact. Documents: Update',
  'clientDocumentConceptsUpdate_clientDocument': 'Update concepts associated with a document|Updates concepts when updating the corresponding document',
  'getControlNumber_clientDocument': 'Control number of a document|Gets the control number of a selected document',
  'checkEquipmentMaterials_clientDocument': 'Updates material/equipment type concepts associated with a document|Updates the concepts for the purchase of materials or equipment when updating the corresponding document',
  'getClientDocument_clientDocument': 'Fact. Quote or a corporate paid billing note|For a company, create or return a Quote or a billing note',
  'getFranchiseClientDocument_clientDocument': 'Quote or a collection note paid Business Unit|For a Business Unit, create or return a Quote or a collection note',
  'getContractsByParameters_clientDocument': 'Fact. Requests: Contract filter | Filter contracts using received parameters',
  'getClientDocumentContract_clientDocument': 'Document associated with a contract|Returns the document associated with a contract according to the characteristics searched',
  'createFromContract_clientDocument': 'Collection note in pending state|Returns a collection note in pending state using the concepts associated with the contract',
  'createFromDocument_clientDocument': 'Document replication|Replicates a document based on a similar document taking into account characteristics such as change of control number and change of dates',
  'getAmounts_clientDocument': 'Calculation of amounts|Calculates the amounts associated with a document and returns',
  'makeClientDocumentPDF_clientDocument': 'Validate before Generating a PDF|Validate data associated with the contract, document and user before generating a PDF of the selected document',
  'makePDF_clientDocument': 'Generate a PDF of the selected document|Generate a PDF after calculating and organizing the data to be printed in that file',
  'sendClientDocumentEmail_clientDocument': 'Validate before sending email|Validate data associated with the contract, document and user before sending a PDF of the selected document by email',
  'sendEmail_clientDocument': 'Send document',
  'sendEmailMassively_clientDocument': 'Mass sending of documents',
  'access_clientDocument': 'Documents: Access to billing module.',
  // clientDocumentControl
  'clientDocumentControl': 'Client Document\'s Control',
  'index_clientDocumentControl': 'Document control numbers: List',
  'store_clientDocumentControl': 'Document control numbers: Create',
  'show_clientDocumentControl': 'Document control numbers: View',
  'update_clientDocumentControl': 'Document control numbers: Update',
  'destroy_clientDocumentControl': 'Document control numbers: Delete',
  // concept
  'concept': 'Concepts',
  'conceptTypes_concept': 'Fact. Concepts: List types',
  'index_concept': 'Fact. Concepts: List ',
  'store_concept': 'Fact. Concepts: Create ',
  'show_concept': 'Fact. Concepts: View ',
  'update_concept': 'Fact. Concepts: Update ',
  'destroy_concept': 'Fact. Concepts: Delete ',
  // conceptMotive
  'conceptMotive': 'Concept Motives',
  'index_conceptMotive': 'Fact. Concept reasons: List ',
  'store_conceptMotive': 'Fact. Concept motives: Create ',
  'show_conceptMotive': 'Fact. Concept motives: View ',
  'update_conceptMotive': 'Fact. Concept reasons: Edit ',
  'destroy_conceptMotive': 'Fact. Concept reasons: Delete ',
  // contractAddress
  'contractAddress': 'Contract\'s Addresses',
  'fiscal_contractAddress': 'Contract\'s Address: Fiscal',
  'index_contractAddress': 'Contract\'s Addresses: List',
  'store_contractAddress': 'Contract\'s Address: Create',
  'show_contractAddress': 'Contract\'s Address: View',
  'update_contractAddress': 'Contract\'s Address: Update',
  'destroy_contractAddress': 'Contract\'s Address: Remove',
  // contractContact
  'contractContact': 'Contract\'s Contacts',
  'index_contractContact': 'Contract\'s Contacts: List',
  'store_contractContact': 'Contract\'s Contact: Create',
  'show_contractContact': ' Contract\'s Contact: View',
  'update_contractContact': 'Contract\'s Contact: Update',
  'destroy_contractContact': 'Contract\'s Contact: Remove',
  // contract
  'contract': 'Contract',
  'index_contract': 'Contracts: List',
  'get_contract': 'View Subscriber\'s Contracts',
  'store_contract': 'Contract: Create',
  'show_contract': 'Contract: View',
  'update_contract': 'Contract: Update',
  'destroy_contract': 'Contract: Remove',
  'contractAddrContactServ_contract': 'View Contract\'s Addresses & Contacts',
  'askingEmail_contract': 'Send Email to Contract Contacts',
  'requestInfo_contract': 'View a Service Request in progress full details|Allows access to the Request Status view',
  'generateFeasibilityOrderPDF_contract': 'Generate PDF of the Feasibility Order',
  'sendFeasibilityOrderPDF_contract': 'Send Feasibility Order',
  'changeBilling_contract': 'Change Contract\'s Billing type',
  'filterPerServiceTotals_contract': 'Contracts per Service: Totals',
  'filterPerServiceDetails_contract': 'Contracts per Service: Details',
  'filterPerServiceAmounts_contract': 'Amounts per Service: Totals',
  'filterPerServiceAmountsDetails_contract': 'Amounts per Service: Details',
  'getAllPerService_contract': 'Contracts per Service: ALL',
  'unrestrictedSubscriberSearch_contract': 'Unrestricted Subscriber Search',
  // contractInstalledHardware
  'contractInstalledHardware': 'Contract Installed Hardware',
  'index_contractInstalledHardware': 'Hardware Installed for a service: List',
  'store_contractInstalledHardware': 'Hardware installed in a service: Add',
  'show_contractInstalledHardware': 'Hardware installed in a service: View',
  'update_contractInstalledHardware': 'Hardware installed in a service: Update',
  'destroy_contractInstalledHardware': 'Hardware installed in a service: Remove',
  // contractService
  'contractService': 'Contract Service',
  'index_contractService': 'Contract Service: List',
  'store_contractService': 'Contract Service: Add',
  'show_contractService': 'Contract Service: View',
  'update_contractService': 'Contract Service: Update',
  'destroy_contractService': 'Contract Service: Remove',
  'storeWithPack_contractService': 'Add package to a Contract',
  'editWan_contractService': 'Contract Service: Edit configs',
  'viewServicePrice_contractService': 'Contract Service: View Price',
  'overrideStatus_contractService': 'Contract Service: Override Status',
  'overrideService_contractService': 'Contract Service: Override Change Serv./Pack',
  // contractServiceParam
  'contractServiceParam': 'Contract\'s Service Parameters',
  'index_contractServiceParam': 'Parameters of a service in the contract: List',
  'store_contractServiceParam': 'Parameters of a service in the contract: Add',
  'show_contractServiceParam': 'Parameter of a service in the contract: View',
  'update_contractServiceParam': 'Parameters of a service in the contract: Update',
  'destroy_contractServiceParam': 'Parameters from a service in the contract: Remove',
  // dashboard
  'dashboard': 'Dashboard',
  'viewCards_dashboard': 'View Dashboard Cards',
  // equipmentMaterials
  'equipmentMaterials': 'Materials or equipment',
  'index_equipmentMaterials': 'Materials or equipment: List',
  'store_equipmentMaterials': 'Materials or equipment: Create',
  'show_equipmentMaterials': 'Material or equipment: View',
  'update_equipmentMaterials': 'Material or equipment: Update',
  'destroy_equipmentMaterials': 'Material or equipment: Remove',
  // employee
  'employee': 'Employee',
  'employeeTypes_employee': 'List the types of employee',
  'index_employee': 'Employees: List',
  'store_employee': 'Employees: Create',
  'show_employee': 'Employee: View',
  'update_employee': 'Employee: Update',
  'destroy_employee': 'Employee: Delete',
  'attachContracts_employee': 'Assign contract to an employee',
  'unattachContracts_employee': 'Deny an employee a contract',
  'getAttachedContracts_employee': 'List an employee\'s contracts',
  'getUnattachedContracts_employee': 'List contracts not assigned to an employee',
  'listAllForPayment_employee': 'List all Collectors when registering payments',
  'changeExternalCollector_employee': 'Change External Collector',
  'assign_contracts': 'Assign Contracts',
  'deassign_contracts': 'Deassign Contracts',
  // exchangeRate
  'exchangeRate': 'Tasas de Cambio',
  'index_exchangeRate': 'Exchange Rates: List',
  'store_exchangeRate': 'Exchange Rate: Create',
  'show_exchangeRate': 'Exchange Rate: View',
  'update_exchangeRate': 'Exchange Rate: Update',
  'destroy_exchangeRate': 'Exchange Rate: Deactivate',
  'restore_exchangeRate': 'Exchange Rate: Activate',
  'listAll_exchangeRate': 'Exchange Rates: List All',
  'currencyCodes_exchangeRate': 'List Currency codes',
  // feasibilityNetwork
  'feasibilityNetwork': 'Network Feasibility Reports',
  'index_feasibilityNetwork': 'Network Feasibility Reports: List',
  'store_feasibilityNetwork': 'Network Feasibility Report: Create',
  'show_feasibilityNetwork': 'Network Feasibility Report: View',
  'update_feasibilityNetwork': 'Network Feasibility Report: Update',
  'destroy_feasibilityNetwork': 'Network Feasibility Report: Delete',
  'list_feasibilityNetwork': 'List active or completed Network Feasibility Reports',
  'changestatus_feasibilityNetwork': 'Network Feasibility Report: Approve',
  'infeasibleService_feasibilityNetwork': 'Network Feasibility Report: Deny',
  // feasibilityReport
  'feasibilityReport': 'Fiber Feasibility Reports',
  'index_feasibilityReport': 'Fiber Feasibility Reports: List',
  'store_feasibilityReport': 'Fiber Feasibility Report: Create',
  'show_feasibilityReport': 'Fiber Feasibility Report: View',
  'update_feasibilityReport': 'Fiber Feasibility Report: Update',
  'destroy_feasibilityReport': 'Fiber Feasibility Report: Delete',
  'list_feasibilityReport': 'List active or completed Fiber Feasibility Reports',
  'changestatus_feasibilityReport': 'Fiber Feasibility Report: Approve',
  'infeasibleService_feasibilityReport': 'Fiber Feasibility Report: Deny',
  // franchise
  'franchise': 'Franchise',
  'index_franchise': 'Franchise: List',
  'store_franchise': 'Franchise: Create',
  'update_franchise': 'Franchise: Update',
  'destroy_franchise': 'Franchise: Remove',
  'activateFranchise_franchise': 'Franchise: Activate',
  'configurateFranchise_franchise': 'Franchise: Configurate|Add/Edit package and subscribers types to franchises',
  // inspection
  'inspection': 'Inspections',
  'index_inspection': 'Inspections: List',
  'store_inspection': 'Inspection: Create',
  'show_inspection': 'Inspection: View',
  'update_inspection': 'Inspection: Update',
  'destroy_inspection': 'Inspection: Remove',
  // moneyReceived
  'moneyReceived': 'Money Received',
  'index_moneyReceived': 'Money Received: List',
  'store_moneyReceived': 'Money Received: Create',
  'show_moneyReceived': 'Money Received: View',
  'update_moneyReceived': 'Money Received: Update',
  'destroy_moneyReceived': 'Money Received: Remove',
  'changeStatus_moneyReceived': 'Money Received: Change Status',
  'pending_moneyReceived': 'Money Received: See Pending',
  'processed_moneyReceived': 'Money Received: See Processed',
  'consolidated_moneyReceived': 'Money Received: See Consolidated',
  'rejected_moneyReceived': 'Money Received: See Rejected',
  // oldBillingHistory
  'oldBillingHistory': 'Old Billing History',
  'index_oldBillingHistory': 'Old Billing History: List',
  'store_oldBillingHistory': 'Old Billing History: Create',
  'show_oldBillingHistory': 'Old Billing History: View',
  'update_oldBillingHistory': 'Old Billing History: Update',
  'destroy_oldBillingHistory': 'Old Billing History: Remove',
  // proofPayment
  'proofPayment': 'Payments',
  'index_proofPayment': 'List of Payments',
  'store_proofPayment': 'Create / make a Payment',
  'getInvoice_proofPayment': 'Validate data and create invoice | Validates the data associated with the payment of a collection note, verifies if it is a partial or full payment. It generates the credit or payment concepts and associates them with the collection note and in case of being a complete payment, calls the invoice creation method ',
  'createInvoice_proofPayment': 'Create an invoice when a collection note is paid',
  'createAdvancedDocument_proofPayment': 'Create advance collection note | Based on the payment note paid, find or create a collection note corresponding to the next billing month, to which the advance payment will be made',
  'createServiceInstallation_proofPayment': 'Create a service installation order | Create a service installation order in draft state and the PDF of this order',
  'sendInstallationOrderPDF_proofPayment': 'Send service installation order | Send an email with the service installation order',
  // Reports
  'report': 'Reports',
  'module_base_permissions': 'Module base permissions',
  'getSalesBook_report': 'Report: Sales Book',
  'getVoidList_report': 'Report: Void List',
  'incomeByDate_report': 'Report: General Income by Date',
  'incomeByCollector_report': 'Report: General Income by Collector',
  'incomeByConcept_report': 'Report: General Income by Concepts',
  'dailyIncome_report': 'Report: Daily Income',
  'incomeBySector_report': 'Report: Income by Sector',
  'incomeByConceptGroupBy_report': 'Report: Income Grouped by Services',
  'collectionList_report': 'Report: Collection List',
  'billingDetailByConcept_report': 'Report: Billing Detail by Concept',
  'dailyCashRegister_report': 'Daily Cash Register (Internal)',
  'dailyCashRegisterExternal_report': 'Daily Cash Register (External)',
  // sector
  'sector': 'Sector',
  'index_sector': 'Sector: List',
  'store_sector': 'Sector: Create',
  'show_sector': 'Sector: View',
  'update_sector': 'Sector: Update',
  'destroy_sector': 'Sector: Remove',
  'parishSectors_sector': 'Sectors by Parish: List',
  // service
  'service': 'Services',
  'index_service': 'Services: List',
  'store_service': 'Service: Create',
  'show_service': 'Service: View',
  'update_service': 'Service: Update',
  'destroy_service': 'Service: Remove',
  // serviceInstallation
  'serviceInstallation': 'Services installation',
  'index_serviceInstallation': 'Service Installation: List',
  'store_serviceInstallation': 'Service Installation: Create',
  'show_serviceInstallation': 'Service Installation: View',
  'update_serviceInstallation': 'Service Installation: Update',
  'destroy_serviceInstallation': 'Service Installation: Remove',
  'addExtension_serviceInstallation': 'Add test period extension',
  'generateDeliveryCertificatePDF_serviceInstallation': 'Generate service and equipment delivery certificate',
  'generateAcceptanceLetterPDF_serviceInstallation': 'Generate service and equipment acceptance letter',
  'getDeliveryCertificate_serviceInstallation': 'Consult service and equipment delivery certificate file',
  'getAcceptanceLetter_serviceInstallation': 'Consult service and equipment acceptance letter file',
  'sendDelivery_serviceInstallation': 'Send service and equipment delivery certificate',
  'sendLetter_serviceInstallation': 'Send letter of acceptance of services and equipment by mail',
  'indexActivationPending_serviceInstallation': 'Service Installation pending for activation: List',
  'indexAllActivationPending_serviceInstallation': 'Service Installation pending for activation: List ALL',
  'filterAllTotals_serviceInstallation': 'Service Installation by Date: Totals',
  'getAllInstallations_serviceInstallation': 'Service Installation by Date: ALL',
  'inputInstallationDate_serviceInstallation': 'Service Installation: Input Installation date',
  'inputBillingStartDate_serviceInstallation': 'Service Installation: Input billing start date',
  // servicePackageConfig
  'servicePackageConfig': 'Service package config',
  'index_servicePackageConfig': 'List services to a package',
  'store_servicePackageConfig': 'Add services to a package',
  'show_servicePackageConfig': 'View package service',
  'update_servicePackageConfig': 'Update service of a package',
  'destroy_servicePackageConfig': 'Remove service from a package',
  // servicePackage
  'servicePackage': 'Service Package',
  'index_servicePackage': 'Service Packages: List',
  'store_servicePackage': 'Service Package: Create',
  'show_servicePackage': 'Service Package: View',
  'update_servicePackage': 'Service Package: Update',
  'destroy_servicePackage': 'Service Package: Remove',
  // serviceParamConfig
  'serviceParamConfig': 'Service Param Config',
  'index_serviceParamConfig': 'List parameters of a service in the package',
  'store_serviceParamConfig': 'Create parameters of a service in the package',
  'show_serviceParamConfig': 'View parameters of a service in the package',
  'update_serviceParamConfig': 'Update parameters of a service in the package',
  'destroy_serviceParamConfig': 'Remove parameters from a service in the package',
  // serviceParam
  'serviceParam': 'Parameters of a service',
  'index_serviceParam': 'Parameters of a service: List',
  'store_serviceParam': 'Parameters of a service: Create',
  'show_serviceParam': 'Parameter of a service: View',
  'update_serviceParam': 'Parameter of a service: Update',
  'destroy_serviceParam': 'Parameter from a service: Remove',
  // subscriber
  'subscriber': 'Subscribers',
  'getByDoc_subscriber': 'Retrieve Subscriber\'s info by DNI',
  'full_subscriber': 'Retrieve Subscriber\'s full info',
  'index_subscriber': 'Subscribers: List',
  'store_subscriber': 'Subscriber: Create',
  'show_subscriber': 'Subscriber: View',
  'update_subscriber': 'Subscriber: Update',
  'destroy_subscriber': 'Subscriber: Remove',
  'changeProspectCondition_subscriber': 'Subscriber: Change Prospect Condition',
  'inject_old_subscriber': 'Subscriber: Migrate',
  'editDni_subscriber': 'Subscriber: Edit DNI',
  'editCode_subscriber': 'Subscriber: Edit Subscriber Number',
  'filterAll_subscriber': 'Subscribers: Filter All',
  'allCDS_subscriber': 'Subscribers: View All CDS',
  'getOverdueCutoff_subscriber': 'Overdue Debt and Cutoff Candidates: List',
  'allOverdueCutoff_subscriber': 'Overdue Debt and Cutoff Candidates: List All',
  'getDebtors_subscriber': 'Total Debt per Subscriber: List',
  'allDebtors_subscriber': 'Total Debt per Subscriber: List All',
  'exportListAll_subscriber': 'Subscribers: Export All XLS',
  'viewBilling_subscriber': 'Subscriber: View billing details',
  'overwriteBalance_subscriber': 'Subscriber: Overwrite Balance',
  'setGroupID_subscriber': 'Subscriber: Set Group',
  'togglePermanentlyRetired_subscriber': 'Subscriber: Retire Permanently',
  // subscriberFileLibrary
  'subscriberFileLibrary': 'Subscriber\'s File Library',
  'index_subscriberFileLibrary': 'Subscriber\'s Files: List',
  'store_subscriberFileLibrary': 'Subscriber\'s File: Create',
  'show_subscriberFileLibrary': 'Subscriber\'s File: View',
  'update_subscriberFileLibrary': 'Subscriber\'s File: Update',
  'destroy_subscriberFileLibrary': 'Subscriber\'s File: Remove',
  // taxes
  'taxes': 'Taxes',
  'index_taxes': 'Taxes: List',
  'store_taxes': 'Tax: Create',
  'show_taxes': 'Tax: View',
  'update_taxes': 'Tax: Update',
  'destroy_taxes': 'Tax: Remove',
  // typeClient
  'typeClient': 'Client Types',
  'index_typeClient': 'Client Types: List',
  'store_typeClient': 'Client Type: Create',
  'show_typeClient': 'Client Type: View',
  'update_typeClient': 'Client Type: Update',
  'destroy_typeClient': 'Client Type: Remove',
  'getExonerated_typeClient': 'Client Type "Exonerated": List',
  // voidedNumber
  'voidedNumber': 'Voided Number',
  'index_voidedNumber': 'Voided Control Numbers: List',
  'store_voidedNumber': 'Control Number: Void',
  'destroy_voidedNumber': 'Control Number: Restore',
  // Zones
  'zone': 'Zone',
  'index_zone': 'Zone: List',
  'store_zone': 'Zone: Create',
  'show_zone': 'Zone: View',
  'update_zone': 'Zone: Update',
  'destroy_zone': 'Zone: Remove',
}
