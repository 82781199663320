<template>
  <v-dialog
    :value="dialogOpen"
    persistent
    width="444px"
  >
    <v-card>
      <toolbarDialog
        :title="`${$tc('base.change')} ${$t('date')}`"
        titleSize="title"
        :showOnlyTitle="'yes'"
      />
      <v-card-text class="pa-sm-12 pb-sm-10">
        <DateDialog
          v-model="computedDate"
          nameTag="billing_start_date"
          prepend-icon="fas fa-calendar"
          class-input="pa-0 ma-0"
          :labelTag="computedLabel"
          :type-date-tag="'date'"
          :min="type === 'billing' ? today : ''"
          :max="type === 'installation' ? today : ''"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text cancel @click="dialogClose">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="d-online-block mt-1 mr-3">
            {{ $t('base.close') }}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>

        <v-btn save
          :loading="loadingBtn"
          :disabled="computedDisabled"
          @click="$emit('saveNewDate', original_date)"
        >
          <template>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="d-online-block mt-1 mr-5">
              {{ $t('base.save') }}
            </span>
            <v-icon small>fas fa-save</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'date-modal',
  props: {
    dialogOpen: {
      default: false,
      type: Boolean
    },
    date: {
      default: '',
      type: String
    },
    type: {
      default: '',
      type: String
    },
    loadingBtn: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      original_date: '',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
    }
  },
  computed: {
    computedDate: {
      get () {
        return this.formatDate(this.date)
      },
      set (val) {
        this.original_date = val
      }
    },
    computedLabel () {
      return this.type === 'billing'
        ? this.$t('service_install.billing_starts_at')
        : this.$t('service_install.installation_date')
    },
    computedDisabled () {
      return this.formatDate(this.original_date) === this.formatDate(this.date)
    }
  },
  methods: {
    formatDate (date) {
      return this.$moment(date).format('YYYY-MM-DD') + ' 00:00:00'
    },
    dialogClose() {
      this.$emit('closeDateModal', false);
    }
  },
  watch: {
    date: {
      handler (val) {
        this.original_date = this.formatDate(val)
      },
      immediate: true
    }
  }
};
</script>
