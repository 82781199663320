<template>
  <v-dialog persistent
    v-model="dialogStatus"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card tile>
      <toolbarDialog
        :showOnlyTitle="'yes'"
        :title="$t('money_received.money_received')"
        @close="close"
      />
      <v-card-text>
        <v-container fluid>
          <ValidationObserver ref="form_money_status" v-slot="{}">
            <v-form class="zone-form" @submit.prevent="save">
              <v-row no-gutters class="d-flex justify-center">
                <v-col cols="12" sm="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="status"
                    :name="$t('employees.status')"
                    rules="required"
                  >
                    <v-select
                      v-model="moneyReceived.status"
                      name="status"
                      dense
                      class="ma-0 pa-0"
                      :items="selectedStatus"
                      :item-disabled="checkItemDisabled"
                      :label="$t('employees.status')"
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="moneyReceived.status === 'rejected'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="status"
                    :name="$t('money_received.rejection_concept')"
                    rules="required"
                  >
                    <v-select dense
                      v-model="rejectionConcept"
                      name="status"
                      class="ma-0 mt-4"
                      :items="rejectionConcepts"
                      item-text="name"
                      item-value="id"
                      :label="$t('money_received.rejection_concept')"
                      :loading="loadingConcepts"
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="details"
                    :name="$tc('money_received.details', 2)"
                    rules="required"
                  >
                    <v-textarea
                      v-model="moneyReceived.details"
                      name="details"
                      :label="$tc('money_received.details', 2)"
                      :error-messages="errors"
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <!-- Close button -->
        <v-btn
          v-if="!fullscreen"
          text
          cancel
          :disabled="loadingStatus"
          @click="close"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
            $t("base.cancel")
          }}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <!-- Save or Update -->
        <v-btn
          save
          :loading="loadingStatus"
          :disabled="loadingConcepts || computedDisabled"
          @click="save"
        >
          <template>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">
              {{ $t("base.update") }}
            </span>
            <v-icon small>fas fa-edit</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangeStatusMoney",
  props: {
    /**
     * Status Items
     */
    status: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      dialogStatus: false,
      loadingConcepts: false,
      loadingStatus: false,
      fullscreen: false,
      moneyReceived: {},
      rejectionConcept: null,
      rejectionConcepts: [],
      statusCheck: '',
    };
  },
  computed: {
    selectedStatus() {
      return this.status.map(status => {
        return {
          value: status.value,
          text: this.$t(status.text)
        };
      });
    },
    computedDisabled () {
      return this.moneyReceived.status === 'rejected' && !this.rejectionConcept
    }
  },
  watch: {
    'moneyReceived.status': {
      handler (val) {
        if (val === 'rejected') {
          this.getRejectionConcepts()
        }
      },
      immediate: false
    }
  },
  methods: {
    openDialog(item) {
      this.moneyReceived = Object.assign({}, item);
      this.moneyReceived.exchange_rate = JSON.stringify(item.exchange_rate);
      this.statusCheck = item.status;
      this.moneyReceived.status = '';
      this.dialogStatus = true;
    },
    close() {
      this.$refs.form_money_status.reset();
      this.moneyReceived = {};
      this.dialogStatus = false;
      this.rejectionConcept = null
    },
    checkItemDisabled(item){
      return (item.value === this.statusCheck)
    },
    save() {
      this.$refs.form_money_status.validate().then(success => {
        if (success) {
          let moneyReceived = Object.assign({}, this.moneyReceived);
          let extra = this.moneyReceived.extra;
          moneyReceived.extra = JSON.stringify(extra);
          this.update(moneyReceived);
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    update(moneyReceived) {
      this.loadingStatus = true;
      let payload = {
        'status': moneyReceived.status,
        'details': moneyReceived.details
      }
      if (moneyReceived.status === 'rejected') {
        payload.rejection_concept_id = this.rejectionConcept
        payload.rejection_concept = this.rejectionConcepts.find(x => x.id === this.rejectionConcept).name
      }
      axios
        .patch(
          this.$store.getters.changeMoneyReceivedStatus(moneyReceived.id),
          payload
        )
        .then(response => {
          if (response.status == 200) {
            this.$emit("ChangeStatus");
            const text = this.$t("money_received.money_received");
            this.pushNoty(text, "updated", 1);
            this.close();
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.log(error.response);
          }
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    getRejectionConcepts () {
      this.loadingConcepts = true
      axios.get(this.$store.getters.catalogByCategory('rejection_concepts'))
        .then(res => {
          this.rejectionConcepts = res.data.success
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
        .finally(() => {
          this.loadingConcepts = false
        })
    },
    /**
     * Funcion de notificaciones
     * @param {*} model
     * @param {*} action
     * @param {Number} pos
     * @param {Number} time Tiempo de la notificaion
     */
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    }
  },
  mounted() {
    //
  }
};
</script>
