import SubscriberDetails from './SubscriberDetails.vue'

export default {
  components:{
    SubscriberDetails
  },
  data(){
    return {
      initial: true,
      prospects_only: true,
      inactive_only: false,
      permanently_retired: false,
      subscribers:[],
      totalValues: 0,
      loading: false,
      tableLoading: false,
      nodata: true,
      expand: false,
      filter: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      current_tab: 'tab-1',
      unwatch: '',
      policies:{
        show:false,
        edit:false,
        payment_type:false,
        retire_permanently: false
      },
      modeNode: process.env.NODE_ENV,
    };
  },
  methods:{
    expandIt () {
      this.expand = true
      let obj = this
      setTimeout(function() {
        obj.$refs.search.focus()
      }, 400)
    },
    search () {
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.options.page = 1
            this.getSubscribers(true, this.prospects_only, this.inactive_only, this.permanently_retired)
          }
        })
        .catch(error => {
            //
        })
    },
    tabChange (val) {
      this.current_tab = val
      this.expand = false
      this.subscribers = []
      this.totalValues = 0
      this.options.page = 1

      switch (val) {
        case 'tab-1':
          this.prospects_only = true
          this.inactive_only = false
          this.permanently_retired = false
          break
        case 'tab-2':
          this.prospects_only = false
          this.inactive_only = false
          this.permanently_retired = false
          break
        case 'tab-3':
          this.prospects_only = false
          this.inactive_only = true
          this.permanently_retired = false
          break
        case 'tab-4':
          this.prospects_only = false
          this.inactive_only = false
          this.permanently_retired = true
          break
      }

      if (!this.initial) {
        setTimeout(() =>
          this.getSubscribers(false, this.prospects_only, this.inactive_only, this.permanently_retired)
        , 140)
      }
    },
    async getSubscribers (is_filter, prospects_only, inactive_only, permanently_retired) {
      this.tableLoading = true
      let ops = {
        params: {
            'paginate': this.options.itemsPerPage,
            'page': this.options.page,
            'tbl': 'n',// Sent to indicate is to populate table
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        let validation = await this.$validator.validate('filter', this.filter)
        if (validation) {
          ops.params.filter = this.filter
        } else {
          this.tableLoading = false
          this.options.page = 1
          return false
        }
      }
      if (prospects_only) {
        ops.params.prospects_only = true
      }
      if (inactive_only) {
        ops.params.inactive_only = true
      }
      if (permanently_retired) {
        ops.params.permanently_retired = true
      }

      axios.get(this.$store.getters.getSubscribers(), ops)
        .then(response => {
          this.nodata = false
          this.subscribers = response.data.success.data
          this.totalValues = response.data.success.total
        })
        .catch(err=>{
          this.subscribers = []
          this.totalValues = 0
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.tableLoading = false
          this.initial ? this.initial = false : ''
        })
    },
    viewDetails (id) {
      this.$refs.subscriber_dialog.viewDetails(id)
    },
    retirePermanently (item) {
      this.$store.dispatch('confirmationValue',{
        template: 'RetirePermanently',
        show: true,
        model: this.$tc('subscribers.subscribers', 1),
        title: this.$t('subscribers.retire_permanently'),
        pre: 'success',
        action: this.$tc('subscribers.retired', 0),
        content: this.$t('base.confirm_delete',{
          action: this.$t('subscribers.retire_permanently'),
          record: item.doc
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.togglePermanentlyRetired(item.id),
          method: 'patch',
          data: {
            'permanently_retired': 1
          }
        },
        pos: 1
      });
    },
    revertRetire (item) {
      this.$store.dispatch('confirmationValue',{
        template: 'RetirePermanently',
        show: true,
        model: this.$tc('subscribers.subscribers', 1),
        title: this.$t('subscribers.revert_to_inactive'),
        pre: 'success',
        action: this.$tc('subscribers.reverted', 2),
        content: this.$t('base.confirm_delete',{
          action: this.$t('subscribers.revert_to_inactive'),
          record: item.doc
        }),
        type: 'warning',
        confirm:{
          url: this.$store.getters.togglePermanentlyRetired(item.id),
          method: 'patch',
          data: {
            'permanently_retired': 0
          }
        },
        pos: 1
      });
    },
    PoliciesSubscriber(){
      this.policies.show = this.getPermissionGroup({
        permission:[
          'show_subscriber',
          'index_contractContact',
          'index_contractAddress',
          'index_contractService',
        ],
        mode:'all'
      });
      this.policies.edit = this.getPermissionGroup({
        permission:[
          'index_typeClient',
          'update_subscriber',
        ],
        mode:'all'
      });
      this.policies.payment_type = this.getPermissionGroup({
        permission:[
          'changeBilling_contract',
        ],
        mode:'all'
      });
      this.policies.retire_permanently = this.getPermissionGroup({
        permission:[
          'togglePermanentlyRetired_subscriber',
        ],
        mode:'all'
      });
    },
  },
  watch:{
    options: {
      handler () {
        this.getSubscribers(this.expand, this.prospects_only, this.inactive_only, this.permanently_retired)
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = ''
        this.$validator.reset()
        this.options.page = 1
        this.getSubscribers(false, this.prospects_only, this.inactive_only, this.permanently_retired)
      }
    },
  },
  computed:{
    computedShowRetireBtn () {
      return this.current_tab === 'tab-3' && this.policies.retire_permanently
    },
    computedShowRevertRetireBtn () {
      return this.current_tab === 'tab-4' && this.policies.retire_permanently
    },
    headers () {
      let headers = [
        {
          text: this.$t('base.code'),
          align: 'center',
          value: 'id',
          width: 86
        },
        {
          text: this.$tc('subscribers.code',2),
          align: 'left',
          value: 'code',
          width: 110
        },
        {
          text: this.$tc('subscribers.dni',2),
          align: 'left',
          value: 'doc',
          width: 120
        },
        {
          text: this.$t('subscribers.name'),
          align: 'left',
          value: 'legal_denomination',
        },
        {
          text: this.$t('subscribers.status'),
          value: 'is_prospect',
          width: 120
        },
      ];
      if (this.policies.show || this.policies.retire_permanently) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false ,
            align: 'right',
            width: 120
          }
        );
      }
      return headers;
    }
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'RetirePermanently'){
        if (this.options.page !== 1) {
          this.options.page = 1
        } else {
          this.getSubscribers(this.expand, this.prospects_only, this.inactive_only, this.permanently_retired)
        }
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    this.PoliciesSubscriber();
  },
}
