<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="540"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        <span class="headline font-weight-bold primary--text">
          {{
            $t(material.id != undefined ? 'feasibilityReport.equip.edit' : 'feasibilityReport.equip.new')
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-autocomplete
              v-model="material.equipment_materials_id"
              :items="materials"
              item-value="id"
              item-text="name"
              v-validate="'required'"
              :name="`item.equipment`"
              :search-input.sync="search"
              :error-messages="errors.collect(`item.equipment`)"
              :placeholder="$t('writeSearch',{
                model:$t('feasibilityReport.equipmentmaterials')
              })"
              :label="$t('feasibilityReport.equipmentmaterials')"
              :data-vv-as="$t('feasibilityReport.equipmentmaterials')"
              prepend-icon="fas fa-box-open"
              class="col-12 px-0 pb-0 order-1"
              @input="getSelectedInfo"
            >
              <template slot="append">
                <v-icon small>fas fa-search</v-icon>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                      search == null || search == '' ?$t('writeSearch',{
                        model:$t('feasibilityReport.equipmentmaterials')
                      }) :
                      $t('no_data')
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-row v-if="Object.keys(selected_mat_eq).length !== 0 && selected_mat_eq.constructor === Object" class="mb-3">
              <v-col cols="12" sm="6" class="py-0">
                <span v-if="selected_mat_eq.hasOwnProperty('brand') && selected_mat_eq.brand">
                  <b v-text="$t('MaterialEquipment.brand')"></b>:
                  {{ selected_mat_eq.brand }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <span v-if="selected_mat_eq.hasOwnProperty('model') && selected_mat_eq.model">
                  <b v-text="$t('MaterialEquipment.model')"></b>:
                  {{ selected_mat_eq.model }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <span v-if="selected_mat_eq.hasOwnProperty('serial') && selected_mat_eq.serial">
                  <div class="flex-grow-1"></div>
                  <b v-text="$t('MaterialEquipment.serial')"></b>:
                  {{ selected_mat_eq.serial }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <p v-if="selected_mat_eq.hasOwnProperty('description') && selected_mat_eq.description" class="mb-0">
                  <b v-text="$t('MaterialEquipment.description')"></b>:
                  {{ selected_mat_eq.description }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              v-model="material.contract_service_id"
              :items="services"
              item-value="id"
              item-text="service.name"
              v-validate="'required'"
              :name="`item.service`"
              :error-messages="errors.collect(`item.service`)"
              :label="$tc('service.service',1)"
              :data-vv-as="$tc('service.service',1)"
              prepend-icon="fas fa-server"
              class="col-12 px-0 order-1"
            ></v-select>
          </v-col>
          <v-col cols="8" class="py-0">
            <v-text-field
              v-model="material.quantity"
              @keyup.enter="save"
              v-validate="'required|numeric|dontallownegative|dontallowzero'"
              name="item.quantity"
              :error-messages="errors.collect('item.quantity')"
              :label="$t('feasibilityReport.quantity')"
              :data-vv-as="$t('feasibilityReport.quantity')"
              prepend-icon="fas fa-balance-scale"
              class="col-12 px-0 order-1"
            >
              <template v-slot:append-outer>
                <small class="mt-3 text-uppercase font-italic">
                  {{ computedMeasurement }}
                </small>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="info" text @click="close">
          <span v-show="$vuetify.breakpoint.mdAndUp"
            class="mr-5">
            {{$t('base.cancel')}}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="save">
          <span v-show="$vuetify.breakpoint.mdAndUp"
            class="mr-5">
            {{$t('base.save')}}
          </span>
          <v-icon small>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      dialog: false,
      loading: false,
      material: {
        quantity: '',
        equipment_materials_id: null,
        contract_service_id: null
      },
      materialObj: {},
      materials: [],
      services: [],
      index: '',
      search: '',
      focus: false,
      selected_mat_eq: {}
    }
  },
  computed: {
    computedMeasurement () {
      if (this.selected_mat_eq.measurement) {
        return this.selected_mat_eq.measurement
      }
      return null
    }
  },
  methods:{
    close(){
      this.dialog = false
      this.selected_mat_eq = {}
      this.materials = []
    },
    async open(item, index, contract){
      this.dialog = true
      this.index = index
      this.search = ''
      if(item == undefined){
        this.material = {
          quantity: '',
          equipment_materials_id: null,
          contract_service_id: null
        }
        this.$validator.reset()
        // this.getMaterial()
      }
      else{
        this.material = item
        this.materialObj = await axios.get(this.$store.getters.getMaterialsEquipment(this.material.equipment_materials_id))
        this.materialObj = this.materialObj.data.equipmentMaterials
        this.selected_mat_eq = this.materialObj
        this.materials = [this.materialObj]
      }
      let services = await axios.get(this.$store.getters.getContractServices(),{
        params:{
          contract: contract,
          paginate: 'All'
        }
      })
      this.services = services.data['package']
    },
    getMaterial(){
      this.loadingTable = true
      axios.get(this.$store.getters.getMaterialsEquipment(), {
          params:{
            page: 1,
            paginate: 10,
            name: this.search
          }
        })
        .then((response)=>{
          if(this.search == null || this.search.length == 0){
            this.materials = []
          }
          else{
            this.materials = response.data.equipment_materials.data
          }
        })
        .finally(()=>{
          this.loadingTable = false
        })
    },
    save(){
      this.loading = true
      this.$validator.validateAll()
      .then(()=>{
        if(!this.errors.any()){
          let val = JSON.parse(JSON.stringify(this.material))
          if(this.index != undefined){
            val = {
              index: this.index,
              material: JSON.parse(JSON.stringify(this.material))
            }
          }
          this.$emit('material',val)
          this.close()
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    getSelectedInfo(e) {
      if (this.material.equipment_materials_id && this.material.equipment_materials_id != this.selected_mat_eq.id) {
        let val = null
        val = this.materials.find(x=> x.id == this.material.equipment_materials_id)
        if (val) {
          this.selected_mat_eq = val
        }
      }
    }
  },
  watch:{
    search(val){
      if(val != null && val.length > 0 && (val.length%3 == 0 || val.length == 1)){
        this.getMaterial()
      }
      if(val == null || val.length == 0){
        this.materials = []
      }
    }
  }
}
</script>
