var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.document.type == 'invoice' ? '800px': '400px',"scrollable":"","fullscreen":_vm.document.type == 'invoice' ? _vm.fullscreen: false},on:{"click:outside":_vm.close},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{staticClass:"pr-0",attrs:{"dense":"","tile":""}},[_c('v-toolbar-title',[_c('span',{class:_vm.fullscreen?
            'subtitle-1 font-weight-bold py-1'
            :'headline font-weight-bold'},[(!_vm.edit)?[_vm._v(" "+_vm._s(((_vm.$tc('base.new',0)) + " " + (_vm.$tc('document_control.document',1))))+" ")]:[_vm._v(" "+_vm._s(((_vm.$t('base.edit')) + " " + (_vm.$tc('document_control.document',1))))+" ")]],2)]),_c('v-spacer'),(_vm.document.type == 'invoice')?_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","fab":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_c('v-icon',[(_vm.fullscreen)?[_vm._v("fa-compress-arrows-alt")]:[_vm._v("fa-expand-arrows-alt")]],2)],1),(_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e()],1):_vm._e()],1),_c('v-card-text',{staticClass:"pt-4x mt-n6 px-2 sd-flex align-center"},[_c('v-container',{attrs:{"fluid":"","pt-10":_vm.fullscreen}},[_c('ValidationObserver',{ref:"form_doc_control",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"doc_control-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{class:{'py-0': !_vm.fullscreen, 'pl-2': true},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6',"md":_vm.document.type != 'invoice'? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"type","name":_vm.$t('document_control.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"type","items":_vm.selectedDocumentTypes,"item-text":"text","item-value":"value","disabled":_vm.franchise_id!=0,"prepend-icon":"fas fa-file-invoice","error-messages":errors,"label":_vm.$t('document_control.type')},on:{"change":_vm.ChangeType},model:{value:(_vm.document.type),callback:function ($$v) {_vm.$set(_vm.document, "type", $$v)},expression:"document.type"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.franchise_id==0 && _vm.document.type == 'invoice'),expression:"franchise_id==0 && document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen, 'pl-2': true},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('v-autocomplete',{attrs:{"name":"franchise_id","items":_vm.ListFranchises,"item-text":"name","item-value":"id","return-object":"","clearable":"","clear-icon":"fas fa-times","prepend-icon":"fas fa-building","label":_vm.$t('document_control.franchise')},on:{"change":function($event){return _vm.ChangeFranchise(_vm.document.franchise)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : '')+" ")]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch ? ("- " + (item.branch)) : '')+" ")]}}],null,true),model:{value:(_vm.document.franchise),callback:function ($$v) {_vm.$set(_vm.document, "franchise", $$v)},expression:"document.franchise"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen, 'pl-2': true},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('EmployeeSearch',{ref:"employee_search",attrs:{"ClassField":'mx-1',"mountedList":false,"onlyID":true,"prepend_icon_field":"fas fa-user-tie"},model:{value:(_vm.document.employee_id),callback:function ($$v) {_vm.$set(_vm.document, "employee_id", $$v)},expression:"document.employee_id"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"serial_document","name":_vm.$t('document_control.serial_document'),"rules":_vm.SerialValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"serial_document","error-messages":errors,"label":_vm.$t('document_control.serial_document')},model:{value:(_vm.document.serial_document),callback:function ($$v) {_vm.$set(_vm.document, "serial_document", $$v)},expression:"document.serial_document"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"initial_document_number","name":_vm.$t('document_control.initial_document_number'),"rules":_vm.TypeValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"initial_document_number","type":'Number',"error-messages":errors,"label":_vm.$t('document_control.initial_document_number')},on:{"keydown":_vm.noNegative},model:{value:(_vm.document.initial_document_number),callback:function ($$v) {_vm.$set(_vm.document, "initial_document_number", $$v)},expression:"document.initial_document_number"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"final_document_number","name":_vm.$t('document_control.final_document_number'),"rules":_vm.TypeValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"final_document_number","type":'Number',"error-messages":errors,"label":_vm.$t('document_control.final_document_number')},on:{"keydown":_vm.noNegative},model:{value:(_vm.document.final_document_number),callback:function ($$v) {_vm.$set(_vm.document, "final_document_number", $$v)},expression:"document.final_document_number"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"serial_control","name":_vm.$t('document_control.serial_control'),"rules":_vm.SerialValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"serial_control","error-messages":errors,"label":_vm.$t('document_control.serial_control')},model:{value:(_vm.document.serial_control),callback:function ($$v) {_vm.$set(_vm.document, "serial_control", $$v)},expression:"document.serial_control"}})]}}],null,true)})],1),_c('v-col',{class:{'py-0': !_vm.fullscreen},attrs:{"cols":"12","sm":_vm.document.type != 'invoice' ? '12': _vm.fullscreen ? '4' : '6'}},[_c('ValidationProvider',{attrs:{"vid":"initial_control_number","name":_vm.$t('document_control.initial_control_number'),"rules":_vm.ControlValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"initial_control_number","type":'Number',"error-messages":errors,"label":_vm.$t('document_control.initial_control_number')},on:{"keydown":_vm.noNegative},model:{value:(_vm.document.initial_control_number),callback:function ($$v) {_vm.$set(_vm.document, "initial_control_number", $$v)},expression:"document.initial_control_number"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.document.type == 'invoice'),expression:"document.type == 'invoice'"}],class:{'py-0': !_vm.fullscreen}},[_c('ValidationProvider',{attrs:{"vid":"final_control_number","name":_vm.$t('document_control.final_control_number'),"rules":_vm.TypeValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"final_control_number","type":'Number',"error-messages":errors,"label":_vm.$t('document_control.final_control_number')},on:{"keydown":_vm.noNegative},model:{value:(_vm.document.final_control_number),callback:function ($$v) {_vm.$set(_vm.document, "final_control_number", $$v)},expression:"document.final_control_number"}})]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(!_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.cancel')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e(),_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.save')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('base.edit')))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }