export default {
  data() {
    return {
      dialog_configService: false,
      loading: false,
      validPrice: true,
      onlyConfig: false,
      mode: "package",
      errorPrice:"",
      service: {
        id: "",
        name: "",
        service_root_id: "",
        description: "",
        active: "",
        code: ""
      },
      configService: {
        id: null,
        qty: 1,
        service_id: null,
        package_id: null
      },
      configParamService: []
      // params:[]
    };
  },
  computed: {
    PriceService() {
      let priceServ =
        this.configParamService.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.total),
          0
        ) * this.configService.qty;
      // if(this.mode == 'contract'){
      //   this.$set(this.configService,'price',priceServ)
      // }
      return priceServ.toFixed(2);
    }
  },
  methods: {
    close() {
      this.validPrice = true;
      this.onlyConfig = false;
      this.errorPrice = "";
      this.dialog_configService = false;
    },
    save() {
      this.$refs.form_config_service.validate()
      .then(success => {
        if (success) {
          if (!this.onlyConfig) {
            this.saveServPack();
          } else {
            let configService = {...this.configService};
            let service_param = [];
            this.configParamService.map(config=>{
              let obj = {
                qty: config.qty,
                param:config
              }
              service_param.push(obj)
            })
            configService.service_param = service_param;
            setTimeout(() => {
              this.$emit("configServPack", configService);
              this.dialog_configService = false;
            }, 10);
          }
        }
      });
    },
    saveServPack(){
      this.loading = true;
      // if(this.mode == 'package'){
      axios({
        method: this.configService.id != null ? "put" : "post",
        url:
          this.mode == "package"
            ? this.configService.id != null
              ? this.$store.getters.getPackageConfig(this.configService.id)
              : this.$store.getters.getPackageConfig()
            : this.configService.id != null
            ? this.$store.getters.getContractServices(this.configService.id)
            : this.$store.getters.getContractServices(),
        data: this.configService
      })
        .then(response => {
          this.$emit("packageSaved");
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "success",
            timeout: 4000,
            notifText: "success",
            notifPos: 2,
            extra: {
              model: this.$t("service.config", {
                service: this.service.name
              }),
              action:
                this.configService.id != null
                  ? this.$tc("updated", 2)
                  : this.$tc("created", 2)
            }
          });
          Promise.all(
            this.configParamService.map(m => {
              let dataParam = {
                id: m.id,
                qty: m.qty,
                service_param_id: m.service_param_id
              };
              dataParam[
                this.mode == "package"
                  ? "package_config_id"
                  : "contract_service_id"
              ] = response.data.package.id;
              return axios({
                method: m.id != "" && m.id != null ? "put" : "post",
                url:
                  this.mode == "package"
                    ? m.id != null && m.id != ""
                      ? this.$store.getters.getParamConfig(m.id)
                      : this.$store.getters.getParamConfig()
                    : m.id != null && m.id != ""
                    ? this.$store.getters.getContractServiceParams(m.id)
                    : this.$store.getters.getContractServiceParams(),
                data: dataParam
              });
            })
          ).then(() => {
            this.dialog_configService = false;
            this.$emit("configService", this.configService.contract_id);
          });
        })
        .catch(error=>{
          if (error.response) {
            if (error.response.data) {
              const err = error.response.data.error;
              if (err.price) {
                this.errorPrice = err.price[0];
              } else {
                this.errorPrice = "";
              }

            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },
    changePrice(index) {
      this.configParamService[index].total = (
        this.configParamService[index].qty *
        this.configParamService[index].price
      ).toFixed(2);
      // Calc total orice of config service
      let Total = 0;
      this.configParamService.map(m => {
        Total += Number(m.total);
        // eslint-disable-next-line no-console
        // console.log(m.id, Number(m.price),m.qty, Number(m.total));
      });
      this.configService.price = Total;
    },
    /**
     * Function to open the service configuration dialog
     *
     * onlyConfig is a variable that is passed to the component
     * so that it does not save the configuration, but sends it to the parent component
     *
     * @param {Object} service
     * @param {Object} configService
     * @param {String} mode
     * @param {Boolean} onlyConfig
     */
    open(service, configService, mode, onlyConfig = false) {
      this.loading = false;
      this.onlyConfig = onlyConfig;
      this.mode = onlyConfig ? "contract" : mode;
      if (service != undefined) {
        this.service = service;
        this.configService = {
          id: null,
          qty: 1,
          service_id: this.service.id,
          price: 0
        };
        this.configService[
          this.mode == "package" ? "package_id" : "contract_id"
        ] = configService;
        if (this.mode == "contract") {
          this.$set(this.configService, "price", 0);
          this.$set(this.configService, "status", "draft");
        }
        this.getParams();
        this.dialog_configService = true;
      } else {
        this.getConfig(configService);
      }
      this.$validator.reset();
    },
    async getConfig(configService) {
      let config = await axios({
        method: "get",
        url:
          this.mode == "package"
            ? this.$store.getters.getPackageConfig(configService)
            : this.$store.getters.getContractServices(configService)
      });
      config = config.data.package;
      this.service = config.service;
      this.configService = {
        id: config.id,
        qty: Number(config.qty),
        service_id: config.service_id,
      };
      if (this.mode == "contract") {
        this.configService["price"] = Number(config.price).toFixed(2);
      }
      this.configService[
        this.mode == "package" ? "package_id" : "contract_id"
      ] = config[this.mode == "package" ? "package_id" : "contract_id"];

      this.configParamService = config.service_param.map(m => {
        return {
          id: m.id,
          qty: Number(m.qty).toFixed(2),
          service_param_id: m.service_param_id,
          lower_limit: m.param.lower_limit,
          measure: m.param.measure,
          name: m.param.name,
          price: Number(m.param.price).toFixed(2),
          upper_limit: m.param.upper_limit,
          total: (Number(m.qty) * Number(m.param.price)).toFixed(2)
        };
      });
      this.dialog_configService = true;
    },
    async getParams() {
      this.configParamService = [];
      let params = await axios.get(this.$store.getters.getParam(), {
        params: {
          paginate: "All",
          service: this.service.id
        }
      });
      this.configParamService = params.data.param.map(m => {
        return {
          id: "",
          qty: 0,
          service_param_id: m.id,
          lower_limit: m.lower_limit,
          measure: m.measure,
          name: m.name,
          price: Number(m.price).toFixed(2),
          upper_limit: m.upper_limit,
          total: 0
        };
      });
    }
  },
  watch: {
    "configService.price":{
      immediate:true,
      deep: true,
      handler(val){
        if (val == "" || val == undefined || val == null || val == 0 ) {
          this.validPrice = false;
        } else {
          this.validPrice = true;
        }
      }
    }
  }
};
