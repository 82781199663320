import { permission_en } from './en/permission_en.js';
import { en_veeValidate } from "./en/en_veeValidate.js";
import { error422_en } from './en/error422';

export const en = {
  line: 'Line',
  additional_info: 'Additional Info',
  no_flow: 'The flow hasn\'t been defined. Status: {0}',
  code: 'Code',
  close: 'Close',
  password_conditions: {
    cap: 'Min. an uppercase letter',
    low: 'Min. a lowercase letter',
    num: 'Min. a number',
    char: 'Min. a special character',
    long: 'Min. length 8'
  },
  configs: 'Configurations',
  download: 'Download',
  view: 'View',
  generate: 'Generate',
  minimize: 'Minimize',
  active: 'Active|Active|(Active)',
  back: 'Back',
  is_required: 'Is required',
  row: 'row|rows',
  writeSearch: 'Write to find {model}',
  usermanagement: 'User Management',
  refprice: 'Reference price',
  inactive: 'Inactive|Inactive|(Inactive)',
  no_data: 'No data available',
  measure: 'Measure unit',
  measure_info: 'For the measure unit you can use a word in singular or plural. It is also possible to add both, separated by a forward slash (/), as long as the first word is singular and the second plural. I.e.: channel/channels',
  employee_search_info: 'To start the Employee search, you must type the first three (3) letters of the name.',
  lower_limit: 'Lower limit',
  upper_limit: 'Upper limit',
  unauthorized: 'Unauthorized',
  logged_out: 'You have been succesfully logged out!',
  safety_log_out: 'Something went wrong. You have been logged out!',
  conected_to: 'Connected to: {0}',
  connection_refused: 'Connection Refused',
  name_only_letters: 'The name may only contain letters',
  telephone_must_11_digits: 'Phone number must have 11 digits',
  logo_must_valid_image: 'The logo must be JPG, JPEG or PNG',
  name_alpha_dash: 'The name may only contain letters, numbers, dashes and underscores',
  ced_digits_3_8: 'The ID must be between 3 and 8 digits',
  doc_alpha_num: 'The ID may only contain numbers',
  doc_must_10: 'The ID must have 10 characters',
  too_many_attempts: 'Too many Attempts',
  name: 'Name',
  description: 'Description',
  perPage: 'Rows per page',
  orderBy: 'Order by',
  action: 'Action',
  actions: 'Actions',
  success: 'The {model} was {action} succesfully!|The {model} was {action} succesfully!',
  success2: 'The {model} were {action} succesfully!|The {model} were {action} succesfully!',
  saved: 'saved|saved',
  created: 'created|created',
  updated: 'updated|updated',
  deleted: 'deleted|deleted',
  canceled: 'canceled|canceled',
  overrided: 'overrided|overrided|overrided|overrided',
  accepted: 'accepted|accepted',
  generated: 'generated|generated',
  installed: 'Installed|Installed|Installed|Installed',
  configured: 'Configured|Configured|Configured|Configured',
  paid: 'paid',
  restored_updated: 'restored & updated|restored & updated',
  completed: 'completed|completed',
  stablished: 'stablished|stablished',
  approved: 'approved|approved',
  changed: 'changed|changed',
  denied: 'denied|denied',
  denied2: 'Denied|Denied',
  restored: 'restored|restored',
  sent: 'sent|sent',
  obj_not_found: 'No {0} found|No {0} found',
  dni: 'DNI',
  first_name: 'First Name',
  last_name: 'Last Name',
  assign: 'Assignc',
  disallow: 'Disallow',
  assigned: 'assigned|assigned',
  assigned2: 'Assigned|Assigned',
  deallocated: 'deallocated|deallocated',
  deallocated2: 'Deallocated|Deallocated',
  unassigned: 'unassigned|unassigned',
  replicated: 'replicated|replicated',
  not_assigned: 'Not_assigned...!',
  not_found: 'Not found|not found|Not found|not found',
  preview: 'Preview',
  birthday: 'Birthdate',
  address: 'Address|Addresses',
  phone: 'Phone',
  cellphone: 'Cellphone',
  image: 'image',
  yes: 'Yes|YES|yes',
  no: 'No|NO|no',
  attach: 'Attach',
  personal_info: 'Personal Info',
  business_info: 'Business Info',
  work_info: 'Work info',
  social_net_info: 'Social Network Info',
  of: 'Of',
  of2: 'of',
  and: 'and',
  or: 'or',
  to: 'to|to',
  by: 'By|by|per',
  in: 'In|in',
  both: 'Both',
  request_field: 'You must enter the requested data',
  example: 'e.g.',
  activate: 'Activate',
  activated: 'Activated|activated|activated',
  deactivate: 'Deactivate',
  reactivate: 'Reactivate',
  reset: 'Reset',
  process: 'Process',
  deactivated: 'Deactivated|deactivated|deactivated',
  enabled: 'Enabled|enabled|enabled',
  disabled: 'Disabled|disabled|disabled',
  network_error: 'Network Error',
  try_again: 'Error! Try again',
  avatar: 'Avatar',
  the: 'The|The|the|the',
  the_2: 'The|The|the|the',
  service_does_not_exist: 'The servicio doesn\'t exists',
  quantity: 'Quantity',
  amount: 'Amount|Amounts',
  country: 'Country',
  state: 'State',
  city: 'City',
  municipality: 'Municipality',
  parish: 'Parish',
  sector: 'Sector',
  content: 'Content',
  type: 'Type',
  show_more: 'Show More',
  date: 'Date',
  notice: 'Notice',
  attached: 'Attached|Attached|attached|attached',
  were: 'Were|were',
  load: 'load|Load|load',
  loaded: 'loaded|loaded',
  replace: 'Replace|replace',
  replaced: 'Replaced|Replaced|replaced|replaced',
  clean: 'Clean',
  filter: 'Filter',
  welcome: 'Welcome|Welcome',
  update: 'Update',
  expand: 'Expand',
  from_to: 'From {start} to {end}',
  please_wait: 'Wait a moment please while the page loads',
  lack_of_settings: 'You haven\'t set up mail sending credentials',
  incomplete_message: 'Email content missing',
  email_auto_verification: 'Email auto verification',
  email_auto_verification_msg: 'By enabling this option, all email records that require the sending of a verification message (e.g.: Users), will be inserted in BD with the field <i>\'email_verified_at\'</i> filled with the same registration date. The email verification messages <b>won\'t</b> be sent.',
  prorated_auto_month: 'Automatic month fraction',
  prorated_auto_month_msg: 'Generate automatic monthly fraction?|By enabling this option, once the Installation of a Service is registered. A Payment Note will be generated with the fractional amount of the cost of the monthly payment, this will be according to the number of days remaining in the month.',
  dear: 'Dear|Dear|Dear|Dear',
  clients: 'client|clients',
  wallet: 'Wallet',
  replicate: 'Replicate',
  control_numbers: 'control numbers',
  betw: 'the control numbers between {start} and {end}',
  printable: 'Printable',
  readonly: 'Read only',
  all: 'all|all|all|all',
  locations: 'Locations',
  start_date: "Start Date",
  end_date: "End Date",
  instalation:"Instalation|Instalations",
  corporate_identity:'Corporate Identity',
  force_state:'Force state',
  debt: 'Debt|Debts',
  validation: {
    locale: en_veeValidate.code,
    name: en_veeValidate.code,
    code: en_veeValidate.code,
    attributes: en_veeValidate.attributes,
    names: en_veeValidate.names,
    messages: en_veeValidate.messages
  },
  error422: error422_en,
  base: {
    none: 'None|None',
    configuration: 'Configuration',
    welcome_to: 'Welcome to',
    condensate_menu: 'Condensate Menu',
    code: 'Code',
    restore_menu: 'Restore Menu',
    hide_menu: 'Hide Menu',
    show_menu: 'Show Menu',
    send: 'Send',
    save: 'Save',
    complete: 'Complete',
    approve: 'Approve',
    deny: 'Deny',
    sign_in: 'sign in',
    change: 'Change',
    changes: 'Changes',
    cancel: 'Cancel',
    login: 'Login',
    register: 'Register|Register',
    name: 'Name',
    email: 'Email',
    features: 'Características',
    forgot: 'Forgot',
    confirm: 'Confirm',
    password: 'Password',
    pwd: '{0} password',
    remember: 'Remember me',
    goto: 'Go to',
    success_auth: 'Successfully authenticated user!',
    logout: 'Logout',
    confirm_logout: 'Are you sure you want to Logout?',
    logged_out: '* You have been succesfully logged out!',
    something_wrong: 'Something went wrong! Try again',
    leastOneConcept: 'A document must have at least one debit type concept',
    success_reg: 'Registration successful. Welcome!',
    lng_changed_to: 'Language changed to: {0}',
    this_lang: '<b>English</b>',
    about_page: 'About...',
    error_401: 'Unauthorized',
    error_403: 'Forbidden',
    error_404: 'Page Not Found',
    error_500: 'Server error',
    error_503: 'Maintenance Mode',
    password_recovery: 'Password Recovery',
    password_reset: 'Password Reset',
    wait: 'Wait',
    loading: 'Loading',
    enable: 'Enable',
    disable: 'Disable',
    select: 'Select',
    selected: 'Selected|Selected',
    a: 'a',
    edit: 'Edit',
    add: 'Add|add',
    delete: 'Delete',
    // Start - Confirmations
    confirm_delete: 'Are you sure you want to {action}: {record}?',
    confirm_reset: `<span> Are you sure about <b>{action}</b> the control numbers for this <b style="text-transform: uppercase;">{record}</b>? </span>`,
    confirm_completed: 'By pressing the confirm button, you will not be able to edit the information entered in the tabs.<br><br>Do you want to complete the Request?',
    confirm_discard: 'By pressing the confirm button, the Request process will stop and cannot be resumed.<br><br>Do you want to discard the Request?',
    confirm_trial: 'Pressing the "Confirm" button will begin the trial period {start} to {end}. <br> <br> Do you want to confirm the start of the test period?',
    confirm_default: 'Are you sure to set the company <b>"{company}"</b> as default access to User: <b>{user}</b>?<br><br><i class="fas fa-exclamation-triangle"></i> This action cannot be undone',
    confirm_feasible: 'By pressing the confirm button, you will not be able to continue editing the information entered in the Feasibility Report.<br><br>Do you want to approve the feasibility of the Service Request?',
    confirm_infeasible: 'By pressing the confirm button, you will be marking the Service Request as <b>Infeasible</b>, and you won\'t be able to continue editing the information entered in the Feasibility Report.<br><br>Do you want to deny the feasibility of the Service Request?',
    confirm_send_acceptance_letter: 'Before pressing the "Confirm" button, you must be sure that Subscriber Prospect <b>accepted</b> the Price Quotation and the Commercial Service Proposal.<br><br>Do you want to <b>send</b> the Acceptance Letter?',
    confirm_config_serv: 'By pressing the "Confirm" button, you cannot continue editing the information entered. <br> <br> Do you want to approve the Settings?',
    confirm_installation : 'By pressing the "Confirm" button, you cannot continue editing the information entered. <br> <br> Do you want to confirm the installation of the service?',
    confirm_send_install: 'Pressing the "Confirm" button will end the testing period and send an email with the letter of acceptance. <br> <br> Do you want to <b> send </b> the Letter of Acceptance?',
    confirm_approved_installation: 'By pressing the "Confirm" button, the Request process will be completed. <br> <br> Do you want to <b> start </b> the regular billing period?',
    confirm_subscriber_migration: 'By pressing the "Confirm" button, the Subscriber Registration will complete.<br><br>Do you want to <b>complete</b> the registration?',
    // End - Confirmations
    new: 'New|New|New|New',
    continue: 'Continue',
    next: 'Next',
    prev: 'Previous',
    search: 'Search',
    accept: 'Accept',
    view_details: 'View Details',
    hide_details: 'Hide Details',
    discard: 'Discard',
    notification: 'Notifications|Notification',
    // Notification Types
    info: 'Info',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
    alert: 'Error',
    tip: 'Regular',
    access: 'Access|Access',
    default_access: 'Default Access|Default Access: {0}',
    pay: 'Pay',
    close: 'Close',
    override: 'Override',
    update: 'Update',
    upgrade: 'Upgrade',
    message: 'Message',
    help: 'Help',
    broadcasted: 'Notification sent to: <b>{0}</b> user|Notification sent to: <b>{0}</b> users'
  },
  emailverify: {
    unverified: 'In order to activate your account, your email address must be verified.<br><br>An email has been sent to your registered email address. Click on the <b>Verify Email Address</b> button.<br><br>If you can\'t find the message in your Inbox, check your Spam folder, or click here to resend: ',
    success: 'Your email has successfully been verified',
    expired: 'The verification URL has expired.<br>{0}|Your email couldn\'t be verified.<br>{0}',
    request_resend: 'Log in to request a new verification message',
    request_resend_logged: 'Click here to request a new verification message: ',
    resend: 'Resend',
    already_verified_email: 'The email address has already been verified',
    notification_resubmitted: 'The email verification message has been resubmitted',
    not_verified: 'You must verify your email'
  },
  feasibility:{
    identification: 'Identification',
    contracts: 'Contracts',
    active_reports: 'Active Reports',
    closed_reports: 'Closed Reports',
    version: 'Version',
    elaboration_by: 'Elaboration by',
    elaboration_date: 'Elaboration date',
    inspection_staff: 'Inspection staff',
    inspection_date: 'Inspection date',
    objective: 'Objective',
    feasible: 'Feasible',
    infeasible: 'Infeasible',
    observation: 'Observation'
  },
  password_reset: {
    reset_link_sent: 'A password reset link was sent to your email address',
    invalid_token: 'This password reset token is invalid',
    token_expired: 'This password reset token has expired',
    email_not_found: 'The email address is not registered',
    re_request: 'You can make a new password recovery request, by clicking here: ',
    succeeded: 'Password reset succeeded! You can <b>Login</b> now'
  },
  DSB: {
    welcome: 'Welcome to the DSB',
    settings: 'Settings',
    logout: 'Logout',
    subheader: {
      administrative: 'Administrative',
      operative: 'Operative',
      gral_config: 'General Configuration',
      taxes: 'Taxes',
      bank_account:'Bank Accounts',
    },
    menu: {
      dashboard: 'Dashboard',
      account: 'Account',
      about: 'About',
      night_mode: 'Night mode',
      turn_on: 'Turn on',
      turn_off: 'Turn off',
      test: 'Test',
      company: 'Company',
      type_client: 'Client Type',
      subscribers: 'Subscribers',
      all_subscribers: 'All Subscribers',
      filter_subscribers: 'Contracts / Subscriber',//Filter Subscribers
      migrate_subscriber: 'Migrate Subscriber',
      requests: 'Requests',
      requestsHistory: 'Request History',
      feasibilityreport: 'Feasibility Report',
      billing: 'Billing',
      management: 'Management',
      contracts_per_service: 'Contracts per Service',
      amounts_per_service: 'Amounts per Service',
      installs_per_date: 'Installations by Date|Installations by Date',
      debt_per_subscriber:'Debt per Subscriber',
      adjustment_motives: 'Adjustment Motives',
    },
    profile: {
      profile: 'Profile',
      security: 'Security',
      current_password: 'Current password',
      new: 'New',
      confirm_new: 'Confirm new',
      current_equal_new: 'New password must be diferent from the current one',
      nothing_to_change: 'There is nothing to change'
    }
  },
  company: {
    active: 'Active',
    inactive: 'Inactive',
    basic_data: 'Basic Data',
    legal_data: 'Legal Data',
    geographical_information: 'Geographical Information',
    system_administrator: 'System Administrator',
    social_networking: 'Social Networking',
    register: 'Register Company',
    update: 'Update Company',
    confirm_delete: 'Are you sure you want to delete this item?',
    is_active: 'Active',
    company: 'Companies|Company',
    new: 'New Company',
    name_company: 'Company Name',
    identification: 'ID',
    object_company: 'Company\'s Object',
    country: 'Country',
    state: 'State',
    name_institution_registration: 'Registration Institution\'s Name',
    register_number: 'Register Number',
    volume: 'Volume',
    folio: 'Foil(s)',
    date_register: 'Registration Date',
    legal_representative: 'Legal Representative',
    firm: 'Firm',
    fiscal_address: 'Fiscal Address',
    latitude: 'Latitude (Decimal coordinates)',
    longitude: 'Longitude (Decimal coordinates)',
    head_logo: 'System Logo',
    printable_logo: 'Printable Logo',
    telephone: 'Telephone',
    email: 'E-mail',
    seal: 'Seal',
    name: 'Administrator\'s Username',
    dbname: 'Data Base Name',
    email_admin: 'Administrator\'s E-mail',
    password: 'Administrator\'s Password',
    primary_color: 'Primary Color',
    secondary_color: 'Secondary Color',
    default_access_company: 'Default Access Company',
    default_company_set_to: 'Default Access set to: {0}',
    no_default_selected: 'You haven\'t selected any Company'
  },
  service_sale:{
    service_sale: 'Service Sale',
    acceptance_letter: 'Acceptance Letter',
    considerations: 'Considerations',
    quotation: 'Quotation',
    proposal: 'Proposal|Service Proposal',
    awaiting: 'Awaiting Requests',
    approve: 'Requests to Approve',
    sent: 'Acceptance Letter Sent',
    send_to_client: 'Send to Subscriber'
  },
  service_install:{
    service_installation: 'Service Installation',
    pending: 'Services to Install',
    activation_pending: 'Services to Activate',
    test: 'In Trial Period',
    letter_sent: 'Letter Sent',
    installed: 'To Start Testing',
    materialConfig: '{name} Material config',
    serviceConfig: '{name} Service config',
    lanConfig: '{name} LAN config',
    new: 'New {service}',
    installation_done: 'Installation done',
    config_done: 'Config done',
    init_date: 'Start of the trial period',
    expasion_date: 'Expand trial period',
    end_date: 'End of the trial period',
    start_trial: 'Start the trial period',
    sent: 'Send Acceptance Letter',
    approve: 'Customer Approved',
    installation_order: 'Installation Order',
    missing_config: 'Service configuration missing',
    awaiting_config: 'Awaiting configuration by the Company',
    installation_date: 'Instalation date',
    billing_starts_at: 'Billing starts at',
    billing_start: 'date',
  },
  franchise: {
    contractor: 'Business Units|Business Unit',
    active: 'Active|Active',
    customers: 'Customers',
    inactive: 'Inactive|Inactive',
    basic_data: 'Basic Data',
    legal_data: 'Legal Data',
    geographical_information: 'Geographical Information',
    system_administrator: 'System Administrator',
    social_networking: 'Social Networking',
    register: 'Register Business Unit',
    update: 'Update Business Unit',
    confirm_delete: 'Are you sure you want to delete this item?',
    is_active: 'Active',
    company: 'Business Units|Business Unit',
    new: 'New Business Unit',
    name_company: 'Business Unit\'s Name',
    identification: 'ID',
    object_company: 'Business Unit\'s Object',
    country: 'Country',
    state: 'State',
    name_institution_registration: 'Registration Institution\'s Name',
    register_number: 'Register Number',
    volume: 'Volume',
    folio: 'Foil',
    date_register: 'Registration Date',
    legal_representative: 'Legal Representative',
    firm: 'Handwritten Signature',
    fiscal_address: 'Fiscal Address',
    latitude: 'Latitude (Decimal Coordinates)',
    longitude: 'Longitude (Decimal Coordinates)',
    telephone: 'Telephone',
    email: 'E-mail',
    name: 'Administrator\'s Username',
    email_admin: 'Administrator\'s E-mail',
    password: 'Administrator\'s Password',
    default_access_company: 'Default Access Business Unit',
    default_company_set_to: 'Default access set to: {0}',
    no_default_selected: 'You haven\'t selected any Business Unit',
    total: 'Total|Totals',
    company_owned: 'Company owned|Own',
    branch_office: 'Branch Office'
  },
  concept: {
    concepts: 'Concepts',
    name: 'Concept|Concepts',
    motive: 'Motive',
    amount: 'Amount',
    quantity: 'Quantity',
    start_date: 'Start Date',
    finish_date: 'End Date',
    type: 'Type',
    types:{
      debit: 'Debit',
      credit: 'Credit',
      discount: 'Discount',
      annulment: 'Annulment',
      exoneration: 'Exoneration',
      retention: 'Retention',
    },
    tax: 'Tax',
    its_percentage: 'It\'s percentage',
    its_one_time: 'it\'s only once time',
    person_type: 'Person Type',
    client_type: 'Client Type',
    service: 'Service',
    sector: 'Sector',
    additional_data: 'Additional Data',
    applied_to: 'Applied To',
    error_saving: 'Error saving concept',
    amount_changing_alert: 'The concept <b>{name}</b> is linked to <b>{count}</b> documents.<br>By changing the amount <b>{symbol}{value}</b>, the total amount of All the documents associated with this concept, will be modified.<br>You can <b>{btn1}</b> concept or <b>{btn2}</b> the amount and alter all documents related to this Concept. '
  },
  paymentNote:{
    address: 'Address',
    bill: 'Bill',
    billingPeriod: 'Billing period',
    concept: 'Concept',
    count: 'Count',
    dateIssue: 'Date of issue',
    daysCredit: 'Credit days',
    description: 'Description',
    name: 'Payment Note',
    numberDays: 'Number of days billed',
    numberId: 'Id Number',
    numberSerial: 'Serial Number',
    payment: 'Payments received',
    priceUnit: 'Unit price',
    priceTotal: 'Total price',
    quantity: 'Quantity',
    rowsPerPageText: 'Rows per page',
    subscriber: 'Subscriber',
    taxbase: 'Tax base',
    totalPay: 'Total to pay',
    totalWithOutDiscount: 'Total without discounts',
    totalWithOutCredits: 'Total without credits',
    totalWithOutCP: 'Total without credits/payments',
    totalWithOutRet: 'Total without Withholdings',
    withholdingIVA: 'Withholding I.V.A.',
    withholdingISRL: 'Withholding I.S.R.L.',
    withholdingSocial: 'social contribution withholding',
    withholdingStamp: 'Tax stamp withholding',
    year: 'Year',
  },
  typeClient: {
    action: 'Action',
    create: 'Create',
    cancel: 'Cancel',
    company: 'Company',
    client: 'Client',
    description: 'Description',
    editTypeClient: 'Edit Type Client',
    ediSubTypeClient: 'Edit Sub Type Client',
    editFeature: 'Edit Feature',
    estate: 'Estate',
    feature: 'Feature',
    is_active: 'Active',
    is_franchise: 'Business Unit',
    features: 'Features',
    newType: 'New Type',
    newSubType: 'New Sub Type',
    newFeature: 'New Feature',
    name: 'Name',
    save: 'Save',
    searchFeature: 'Search Feature',
    subTypeClient: 'Sub Type Client',
    title: 'Clients Types',
    typeClient: 'Type Client',
    no_types_assigned: 'No Client Types assigned'
  },
  feasibilityReport: {
    report: 'Report',
    feasibility : 'Feasibility',
    title : 'Feasibility Report',
    network: "Network Report",
    fibra: "Fibra Report",
    description: 'Description',
    action: 'Action',
    save: 'Save',
    cancel: 'Cancel',
    new : 'New Report',
    version : 'Version',
    image: 'Image',
    elaboration_by: 'Elaboration by',
    elaboration_date: 'Elaboration date',
    inspection_date: 'Inspection date',
    inspection_staff: 'Inspection staff',
    objective: 'Objective',
    observation: 'Observation',
    descriptive_memory: 'Descriptive memory',
    work_to_realize: 'Work to do',
    title_report: 'Title',
    equipmentmaterials: 'Materials or Equipment',
    equip:{
      'new': 'New Material/Equipment',
      'edit': 'Edit Material/Equipment',
      'delete': 'Delete Material/Equipment'
    },
    provider: 'Provider',
    quantity: 'Quantity'
  },
  roles: {
    groups: 'Group|Groups',
    roles: 'Role|Roles',
    permissions: 'Permission|Permissions',
    no_data: 'No Data on Database',
    individual_permissions: 'Individual Permissions',
  },
  service: {
    service : 'Service|Services',
    serviceto: 'Service to the {model} {value}|Services to the {model} {value}',
    add: 'Add service',
    new: 'New service',
    current:'Current service',
    edit: 'Edit service',
    delete: 'Delete service',
    select_service: 'Select service',
    attributes: 'Service specs|Sevice specification',
    new_attribute: 'New specificatiom',
    edit_attribute: 'Edit specificatiom',
    delete_attribute: 'Delete specificatiom',
    params: 'Params|Param',
    paramto: 'Param to {serv}',
    new_param: 'New param',
    edit_param: 'Edit param',
    delete_param: 'Delete param',
    select: 'Select',
    config: 'Service settings {service}',
    no_configs: 'To activate this Service, you must first add configurations to it',
    no_configs_preloaded: 'The Service has no preloaded settings',
    //Statuses
    draft: 'Draft',
    service_request: 'Service Request',
    feasible_service: 'Feasible',
    infeasible_service: 'Infeasible',
    awaiting_acceptance: 'Awaiting Acceptance',
    generate_charge_note: 'To Generate Payment Note',
    payment_pending: 'Payment Pending',
    awaiting_installation: 'Awaiting Installation',
    installed: 'Installed',
    active: 'Active',
    inactive: 'Inactive',
    migrating: 'Draft',
    // Other
    service_details: 'Service Details',
    no_service_selected: 'No Services selected',
    // Requests Timeline
    service_requested: 'Service Request',
    feasibility_report: 'Feasibility Report',
    service_sale: 'Service Sale',
    billing: 'Billing',
    installation: 'Installation',
    // Status Disks
    pending: 'Pending',
    completed: 'Completed',
    in_progress: 'In Progress',
    warning: 'Warning',
    error: 'Error',
    configs: {
      // Groups
      general: 'General',
      eq_config: 'Eq. Config.',
      eq_data: 'Eq. Data',
      extra: 'Additional',
      // Types
      free: 'Free',
      alpha: 'Alpha Numeric',
      string: 'String',
      integer: 'Integer',
      float: 'Float',
      url: 'URL',
      ipv4: 'IPv4',
      ipv6: 'IPv6',
      block_ipv4: 'IPv4 Block',
      block_ipv6: 'IPv6 Block',
      segment_ipv4: 'IPv4 Segment',
      segment_ipv6: 'IPv6 Segment',
    },
    contract_statuses: 'Contract Statuses',
    service_type:'Service type',
    service_types:'Service types',
    statuses: {
      draft: 'Draft',
      requested: 'Requested',
      activation_in_progress: 'Activation in Progress',
      trial_period: 'Trial Period',
      active: 'Active',
      voluntary_suspension_in_progress: 'Voluntary Suspension in Progress',
      suspension_by_cut_in_progress: 'Suspension by Cut in Progress',
      retired: 'Retired',
      voluntarily_suspended: 'Voluntarily Suspended',
      suspended_by_cut: 'Suspended by Cut'
    }
  },
  package:{
    package: 'Packages|Package',
    new: 'New package',
    edit: 'Edit package',
    delete: 'Delete package',
    no_service: 'To activate this package, you must first add services to it',
    package_does_not_exist: 'The Package doesn\'t exist',
    package_config_does_not_exist: 'The Package config. doesn\'t exist',
    package_added_for_contract: 'The package was added succesfully!',
    service_added_for_contract: 'The service was added succesfully!',
    package_already_exists: 'The package has already been added',
    service_already_exists: 'The service has already been added',
    no_packages_assigned: 'No services assigned',
    current:'Current package',
  },
  actor: {
    actor: 'User|Users',
    position: 'Position|Positions',
    sign: 'Sign',
    seal: 'Seal',
    personal_mail: 'Personal Mail',
    no_actors_found: 'No Users found',
    avatar: 'The user image',
  },
  system: {
    currency: 'Currency',
    preset_currency: 'Preset Currency',
    currency_code: 'Currency Code',
    base_currency: 'System Base Currency',
    set_currency: 'Set Up',
    symbol: 'Symbol',
    icon: 'Icon',
    currency_stablished: 'Base Currency Stablished',
    exchange_rate: 'Exchange Rate|Exchange Rates',
    new_rate: 'New Rate',
    edit_rate: 'Edit Rate',
    // url_optional: 'The URL is optional',
    must_set_base_currency: 'Base Currency hasn\'t been set',
    equal_base: 'Equivalent to 1 ',
    base_currency_notice: 'Once you have established the System\'s <b>Base Currency</b>, you won\'t be able to change it or modify it.<br><br>Are you sure you want to set <b>{0} ({1})</b> as the Base Currency?',
    decimal_places: 'Decimal Places',
    add_decimal_place: 'Add Decimal Place',
    remove_decimal_place: 'Remove Decimal Place',
    global_billing_mode: 'System Billing Mode',
    global_billing_mode_msg: 'Switch System Billing Mode|By selecting the <b>Internal</b> (Int.) Billing option, the system will automatically run the billing process every month.<br><br>When selecting the <b>External</b> (Ext.) billing option, The system <u>will not</u> automatically generate the Monthly Payment Notes. You will have to rely on an external system for this task. ',
    global_subscriber_wallet_mode_msg: 'By enabling this option, a <b>Wallet</b> is activated for each Subscriber, and its respective balance will be displayed on the Subscriber\'s file, where its movements can also be viewed.<br><br>With this option enabled, the <i>Monthly Billing</i> process will verify the balance of each Subscriber and if it\'s enough to cover the amount of the <u>monthly payment</u> of their Services, this will be <b>discounted of the Balance</b> and the corresponding <u>Invoice</u> will be generated.',
    // Currency's Names
    pab: 'Panamanian balboa',
    btc: 'Bitcoin',
    ves: 'Venezuelan bolívar soberano',
    bob: 'Boliviano',
    usd: 'Dollar',
    cad: 'Canadian Dollar',
    eur: 'Euro',
    uah: 'Ukrainian hryvnia',
    pyg: 'Paraguayan guaraní',
    gbp: 'Pound sterling',
    itl: 'Italian lira',
    ils: 'Israeli new shekel',
    ars: 'Argentine peso',
    clp: 'Chilean peso',
    cop: 'Colombian peso',
    cup: 'Cuban peso',
    dop: 'Dominican peso',
    mxn: 'Mexican peso',
    uyu: 'Uruguayan peso',
    ptr: 'Petro',
    brl: 'Brazilian real',
    inr: 'Indian rupee',
    rub: 'Russian ruble',
    pen: 'Peruvian sol',
    kzt: 'Kazakhstani tenge',
    krw: 'South Korean won',
    cny: 'Chinese yuan',
    jpy: 'Japanese yen',
  },
  history_log: {
    date: 'Date',
    rate: 'Rate',
    exchange_rate_created: 'Exchange rate created',
    exchange_rate_deleted: 'Exchange rate deleted',
    exchange_rate_restored: 'Exchange rate restored',
    exchange_rate_updated: 'Exchange rate updated',
    unauthorized_update: 'Unauthorized update attempt',
    wan_config: 'Service settings',
    // old: 'Before',
    // attributes : 'After',
    before: 'Before',
    after: 'After',
    price: 'Price',
    contract_id: 'Contract id',
    service_id: 'Service id',
    package_id: 'Package id',
    qty: 'Quantity',
    contract_service_updated: 'Contract service updated',
    contract_service_created: 'Contract service created',
    // status
    status: 'Service status',
    old_status: 'Previous Service status',
    draft: 'Draft',
    requested: 'Requested',
    activation_in_progress: 'Activation in Progress',
    trial_period: 'Trial Period',
    active: 'Active',
    voluntary_suspension_in_progress: 'Voluntary Suspension in Progress',
    suspension_by_cut_in_progress: 'Suspension by Cut in Progress',
    retired: 'Retired',
    voluntarily_suspended: 'Voluntarily Suspended',
    suspended_by_cut: 'Suspended by Cut',
    motive: 'Motive',
    motive_details: 'Motive Details'
  },
  file_library: {
    file: 'File|Files',
    service_proposal: 'Service Proposal',
    feasibility_order: 'Service Feasibility Verification Order',
    inspection_order: 'Inspection Order',
    installation_order: 'Installation Order',
    price_quotation: 'Price Quotation',
    invoice: 'Invoice',
    invoice_sendable: 'Invoice',
    tax_bill: 'Tax Bill',
    bill: 'Bill',
    payment_note: 'Payment Note',
    credit_note: 'Credit Note',
    acceptance_letter: 'Acceptance Letter',
    signed_acceptance_letter: 'Signed Acceptance Letter',
    service_equipment_delivery_certificate: 'Service & Equipment Delivery Certificate',
    service_equipment_acceptance_letter: 'Service & Equipment Acceptance Letter',
    signed_service_equipment_acceptance_letter: 'Signed Service & Equipment Acceptance Letter',
    dni: 'Identification Document',
    rif: 'Tax Information Registry',
    legal_representative_dni: 'Legal Representative DNI',
    legal_representative_rif: 'Legal Representative Fiscal Registry',
    legal_person_rif: 'Legal Person Fiscal Registry',
    legal_person_registration_document: 'Legal Person Registration Document',
    last_registration_modification: 'Last Registration Modification',
    assembly_minute: 'Assembly Minute',
    documentation_warning: 'Please note that until you load the corresponding Documentation, the Subscriber status will not change from Subscriber Prospect to Active Subscriber.',
  },
  subscribers: {
    admin: 'Admin.',
    tech: 'Tech|Tech',
    address: 'Address',
    payment: 'Payment',
    contact: 'Contact|Contacts',
    info: 'Info.',
    type_client: 'Subscriber Type',
    subscribers: 'Subscriber|Subscribers',
    prospect: 'Prospect|Prospects',
    retired: 'Retired|Retired|Permanently Retired',
    retire_permanently: 'Retire Permanently',
    reverted: 'Reverted|Reverted|Reverted to Inactive',
    revert_to_inactive: 'Revert to Inactive',
    subscriber_prospects: 'Subscriber Prospects',// 404 backend response
    active_subscribers: 'Active Subscribers',// 404 backend response
    inactive_subscribers: 'Inactive Subscribers',// 404 backend response
    prefix: 'Prefix|Prefixes',
    dni: 'DNI|DNI|DNI',
    is_firm: 'Sole Proprietorships',
    firm_name: 'Sole Proprietorship Name',
    first_name: 'Name',
    last_name: 'Last Name',
    legal_denomination: 'Legal Denomination',
    gender: 'Gender',
    birthdate: 'Birthdate|Birthdate',
    agent_ret: 'Ret. Agent',
    tax_rate: 'Value added tax',
    tax_sale: 'Income tax',
    contact_medium: 'Contact Medium',
    contact_mediums: {
      whatsapp: 'WHATSAPP',
      call: 'CALL',
      email: 'EMAIL',
      others: 'OTHERS'
    },
    observations: 'Observations',
    status: 'Status',
    subscriber_details: 'Subscriber\'s Details',
    person_type: 'Person Type',
    natural: 'Natural|Natural|Natural',
    legal: 'Legal|Legal|Legal',
    is_prospect: 'Is Prospect?',
    created_at: 'Registered',
    updated_at: 'Last update',
    avatar: 'The Subscriber image',
    incomplete_request: 'The registration hasn\'t been completed',
    subscriber_already_exists: 'Existing Subscriber|There\'s already a Subscriber registered with the DNI number entered: <b>{0}</b>.<br><br>Do you want to create a new Service Request in a new Location for this Subscriber?',
    subscriber_code_already_exists: 'Existing Subscriber Number|There\'s already a Subscriber registered with the number entered: <b>{0}</b>.<br><br>Do you want to register a new Service in a new Location for this Subscriber?',
    verify_subscriber_information: 'Please verify the information before continuing',
    name: 'Subscriber\'s name',
    credit_days: 'Credit Days',
    documentation: 'Documentation',
    condition_changed: 'The Subscriber\' condition has changed from Prospect to Active',
    code: 'Subscriber Code|# Subscriber',
    dues:'Dues|dues',
    subscriber_debt:'Subscriber debt|Subscriber debt',
    overdue_debt:'Overdue debt',
    court_candidates:'Cutoff candidates',
    corporate:'Corporate',
    total_debt_contract:'Total debt of the Contract',
    groups: 'Subscribers Groups',
    wallet: 'Wallet',
    balance: 'Balance'
  },
  contracts: {
    id: 'Contract Id',
    contracts: 'Contract|Contracts',
    city: 'City',
    municipality: 'Municipality',
    parish: 'Parish',
    tower: 'TOWER|Tower',
    ref_point: 'Ref. Point',
    coordinates: 'Coordinate|Coordinates',
    no_contracts: 'The Subscriber doesn\'t have registered Contracts',
    tech_address_error: 'Tech address not found',
    same_as_previous: 'Same as Previous|Same as Previous',
    assigned: 'Assigned contract|Assigned contracts',
    unassigned: 'Unassigned contract|Unassigned contracts',
    deny_contracts: 'Deny contracts',
    deny: 'Deny',
  },
  requests: {
    requests: 'Request|Requests',
    expansion: 'Expansion|Expansions',
    reduction: 'Reduction|Reductions',
    repair: 'Repair|Repairs',
    voluntary_suspension: 'Voluntary Suspension|Voluntary Suspensions',
    completed: 'Completed|Completed',
    no_requests_found: 'No Requests found',
    discarded: 'Discarded|Discarded|Discarded|Discarded',
    status: 'Status Request',
    history: 'Request History',
    unrepeatable_info: 'The fields <i>cell phone number</i> and <i>email</i>, must not be the same for the <i>Technical</i>, <i>Administrative</i> and <i>Legal</i> Contacts',
    requires_inspection: 'Does this Service request require an Inspection?'
  },
  inspection: {
    inspection: 'Inspections|Inspection',
    awaiting: 'Awaiting|Awaiting',
    closed: 'Closed|Closed|Closed|Closed',
    confirm_feasible: 'By pressing the confirm button, you will not be able to continue editing the information entered in the Inspection.<br><br>Do you want to approve the feasibility of the Service Request?',
    confirm_infeasible: 'By pressing the confirm button, you will be marking the Service Request as <b>Infeasible</b>, and you won\'t be able to continue editing the information entered in the Inspection.<br><br>Do you want to deny the feasibility of the Service Request?',
  },
  about_paragraph_1: 'The "Plataforma de Gestión de Operaciones" (PLATGO) is an application software belonging to VIGINET, C.A. which allows to manage the frequent operations of the company with its corporate and massive clients.',
  about_paragraph_2: 'PLATGO is a software whose exclusive property belongs to VIGINET, C.A. and has been fully developed by the company Apushi Technology C.A.',
  about_paragraph_3: '© 2021. Intellectual Property of VIGINET, C.A. The logo, content and information hosted on the system are exclusive property of the company VIGINET, C.A. and may not be used without prior authorization from the owner.',
  document_control: {
    document: 'Document',
    documents: 'Documents',
    document_control: 'Documents Control',
    document_number: 'Documento N°',
    control_number: 'Control N°',
    serial_document: 'Serial document',
    initial_document_number: 'Initial document N°',
    final_document_number: 'Final document N°',
    serial_control: 'Serial control',
    initial_control_number: 'Initial control N°',
    final_control_number: 'Final control N°',
    type: 'Type',
    franchise: 'Business Unit',
    actor: 'User',
    types:{
      price_quotation: 'Price quotation',
      invoice: 'Invoice',
      bill: 'Bill',
      payment_note: 'Payment note',
      credit_note: 'Credit note',
    },
    status:{
      pending: 'Pending',
      paid: 'Paid',
      canceled: 'Vanceled',
      voided: 'Voided',
      accepted: 'Accepted',
      replicated: 'Replicated',
    },
    errors:{
      not_found_control_number: 'Not found Control Number',
      not_found_dni: 'Not found client identification number',
      not_found_concepts: 'Not found Concepts, you need add concepts',
      not_found_contract: 'Not found contract, you need select a contract',
    },
    change_header: 'Do you want to change the document control number?',
    change_dates: 'Do you want to update the dates associated with the document?',
    alert:`If you select 'All', you will not be able to create any document of this type`,
    alert2: 'Next you must indicate the control numbers you wish to voided from the selected {type}.',
    in_a_range: 'En un rango',
    only_one: 'Only one',
  },
  billing:{
    create: 'Create document',
    invoice_number: 'Invoice Number',
    control_number: 'Control number',
    control_series: 'Control series',
    description: 'Description',
    documents: 'Billing Documents',
    elaboration_date: 'Elaboration date',
    history: 'Record',
    name: 'Billing',
    management: 'Billing management',
    state: 'State',
    status:{
      draft: 'Draft',
      assigned: 'Assigned',
      accepted: 'Accepted',
      pending: 'Pending',
      sended: 'Sended',
      paid: 'Paid',
      canceled: 'Canceled',
      voided: 'Voided',
      replicated: 'Replicated',
    },
    invoice_date: 'Invoice Date',
    date_billind: 'Billing Period',
    date_issue: 'Date of issue',
    date_due: 'Expiration date',
    date_cutoff: 'Cutoff date',
    amount: 'Amount',
    tax_bill_rate_missing: 'Tax bill rate missing'
  },
  notification: {
    no_mail_settings: 'Mail sending has not been configured',
    new_service_requested: 'New Service Requested',
    new_installation_order: 'New Installation Order',
    activation_request_from_franchise: 'New Service Activation request from Business Unit',
    franchise_request_activated: 'Service request Approved for Activation',
  },
  policy: Object.assign({
    impropely_named_policy: '* Improperly Named Policy *',
    translation_not_found: '* Translation not Found *',
    add_actor: 'Add Actor',
    edit_actor: 'Edit Actor',
    add_subscriber: 'Add Subscriber',
    view_policy: 'View Policy',
    role_policy: 'Role Policy',
    // actions
    add_equipment_materials: 'Add equipment and/or materials',
    acceptance_letter: 'Letter of acceptance',
    awaiting_request: 'Awaiting request',
    acceptance_letter_send: 'Sending the acceptance letter',
    billing: 'Billing',
    billing_request: 'Billing request',
    change_contracts_billing_type: 'Change contract billing type',
    create : 'Create',
    concepts: 'Concepts',
    Crud: 'Create, Read, Update and Delete',
    condition_changed: "Subscriber's condition has changed from Prospect to Active",
    commons_for_create_edit: 'The common points for creating or editing',
    create_payment: 'Create payment',
    dashboard: 'Dashboard',
    delete: 'Delete',
    documents: 'Documents',
    document_search_by_id_or_legal_name: 'Document search by ID or legal name',
    edit_Only_by_franchise_subscriber: 'Edit (Franchise subscriber only)',
    fiber_feasibility_reports: 'Fiber feasibility reports',
    generate_payment_note: 'Generate collection note',
    list: 'List',
    list_pendign_request: 'List: Request pending',
    list_reports_active_closed: 'List: Active / Closed Reports',
    network_feasibility_reports: 'Network Feasibility Reports',
    new_control_number_to_create_document: 'New control number to create document',
    management: 'Management',
    only_corporate_subscriber: 'Corporate subscriber only',
    only_franchise_subscriber: 'Only by franchise subscriber',
    only_corporate_user_or_admin: 'Only corporate user or Admin',
    payment_reconciliation: 'Payment reconciliation',
    pdf: 'PDF',
    request: 'Request',
    report_approve: 'Report: Approve',
    report_deny: 'Report: Deny',
    requests: 'Applications',
    request_approve: 'Request for approval',
    replicated: 'Replicate',
    services_sale: 'Sale of services',
    service_proposal: 'Service proposal',
    service_instalation: 'Service installation',
    send_mail: 'Send mail',
    search_employee: 'Search Employee',
    show: 'Show',
    subscriber: 'Subscriber',
    update: 'Update',
    update_document: 'Update document',
    update_approve_service_installation_approve_start_of_the_trial_period: 'Update/Approve service installation/Approve start of trial period',
    voided: 'Voided',
    restore: 'Restore',
  },permission_en),
  month: 'Month',
  months:{
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  MaterialEquipment:{
    brand: 'Brand',
    description: 'Description',
    equipment: 'Equipment|Equipment',
    materials: 'Material|Materials',
    measurement: 'Measurement',
    measurement_unit: 'Measurement Unit',
    model: 'Model',
    name: 'Name',
    names: 'Materials And Equipment',
    serial: 'Serial',
    price: 'Price',
    type:{
      equipment: 'Equipment',
      material: 'Material',
      type: 'Type',
    },
  },
  Taxes:{
    tax: 'Tax|Taxes',
    name: 'Name',
    category: 'Category',
    value: 'Value',
    unit: 'Unit',
    default: 'Default',
    lastUpdate: 'Last update',
    other_taxes: 'Other taxes'
  },
  Payments:{
    reconciliation: 'Payment reconciliation',
    name: 'Payment|Payments',
    invoice: 'Invoice|Invoices',
    mode: 'Pay mode',
    received: 'Received|Received',
    payment_recieved: 'Payments received',
    type:{
      type: 'Payment type',
      partial: 'Payment partial',
      total: 'Full payment',
      cash: 'Cash',
      transfer: 'Transfer',
      card: 'Card',
      external: 'External'
    },
    cash_payment: 'Payment cash',
    transfer_payment: 'Payment transfer',
    card_payment: 'Payment card',
    amount: 'Amount',
    ref: 'N° Reference',
    balance: 'Balance',
    bank: 'Bank',
    payingTo: 'Paying to',
    payOf: 'Pay of',
    payment_method: 'Payment Method',
    not_banks: 'You have no registered banks for the selected exchange rate',
    pay_all: 'When fully paying the Collection note, an INVOICE will be created with the following Control numbers'
  },
  requestBills:{
    generate: 'Generate payment note',
    waitingPayment: 'Waiting for payment',
  },
  employees:{
    employee: 'Employee|Employee',
    employees: 'Employees',
    first_name: 'First Name',
    last_name: 'Last Name',
    firm_name: 'Sole Proprietorship Name',
    legal_denomination: 'Legal denomination',
    person_type: 'Person Type',
    name: 'Employee\'s Name',
    dni: 'DNI',
    birthdate: 'Birthdate',
    gender: 'Gender',
    type: 'Type',
    types:{
      collector: 'Collector',
      collection_analyst: 'Collection Analyst',
      seller: 'Seller',
      technician: 'Technician'
    },
    email: 'Email',
    local_phone: 'Local phone',
    cellphone: 'Cellphone',
    status: 'Status',
    active: 'Active',
    franchise: 'Business Unit',
    set_employee: 'Assign to Collector',
    payment_collector: 'Payment Collector',
  },
  humanResources:{
    humanResources: 'Human Resources'
  },
  reports:{
    reports: 'Reports',
    generate: 'Generate Reports',
    report_type: 'Report Type',
    ledger: 'Ledger',
    void_list: 'Void List',
    billing_detail_by_concept: 'Billing Detail by Concept',
    general_income_by_date: 'General Income by Date',
    general_income_by_collector: 'General Income by Collector',
    general_income_by_concept: 'Revenue Summary by Concept',
    daily_income: 'Daily income',
    income_by_sector: 'Income by Sector',
    daily_cash_register: 'Daily Cash Register (Int.)',
    daily_cash_register_external: 'Daily Cash Register (Ext.)',
    income_by_concept_group_by: 'Income by Services',
    collection_list: 'Collection List',
    no_results: 'No results for: <b>{0}</b>, match your search'
  },
  banks:{
    bank: 'Banks',
    banks: 'Bank|Banks',
    name: 'Name',
    currency_code: 'Currency Code',
    bank_account: 'Bank Account|Bank Accounts',
    national: 'National|National',
    international: 'International|International',
    account: 'Account',
    mobile: 'Mobile Payment',
    account_holder_dni: 'Account Holder DNI',
    account_holder: 'Account Holder Name',
    account_number: 'Account Number',
    account_type: 'Account Type',
    account_types: {
      savings: 'Savings',
      current: 'Current'
    },
    to_print_in_payment_note: 'To Print in Payment Note',
  },
  paymentForm:{
    paymentForm: 'Billing type|billing type',
    types:{
      prepaid: 'Prepaid',
      postpaid: 'Postpaid',
    },
  },
  ErrorPage:{
    description_403: 'You do not have the necessary permissions to continue, contact the administrator for more information.',
    description_404: '',
    description_503: 'The service is temporarily unavailable, sorry for the inconvenience.',
  },
  sectors:{
    sectors: 'sector|sectors|Sectors',
    create_new_sector:'Create your new sector',
    points_available:'Points available',
    points_occupied:'Points occupied',
    points_remaining:'Points remaining',
    minimum_sales_goal:'Minimum sales goal',
    maximum_capacity_alert:'Maximum capacity alert'
  },
  zones:{
    zone: 'zone|Zone',
    zones: 'zones|Zones',
  },
  table: {
    totals: 'Totals',
    contracts: 'Total Contracts',
    massives: 'Massives',
    corporate: 'Corporate',
    seven_days: '7 Days',
    this_month: 'This Month',
    last_month: 'Last Month',
    prev_last_month: 'Prev. Last Month',
    sixty_days: '60 Days',
  },
  dashboard:{
    cards:{
      subscribers_active: 'Active subscribers',
      requests_in_progress: 'Requests in progress',
      installations_completed: 'Completed installations',
      installations_pending:'Pending installations'
    }
  },
  identity_labels:{
    subscribers_active: 'Active subscribers',
    requests_in_progress:'Requests in progress',
    installations_completed:'Completed installations',
    installations_pending: 'Pending installations',
    points_available:'Points available',
    points_occupied:'Points occupied',
    points_remaining:'Points remaining',
  },
  money_received:{
    money_received: 'Money received',
    money_receive: 'Money receive',
    reception_date: 'Reception date',
    reception_medium: 'Reception medium',
    profit_code: 'Profit Code',
    profit_number: 'Invoice Reference',
    details: 'Observations|Details',
    status: {
      pending: 'Pending',
      consolidated: 'Consolidated',
      processed: 'Processed',
      rejected: 'Rejected'
    },
    payment_methods: {
      cash: 'Cash',
      digital: 'Digital',
      mobile: 'mobile',
      tdc: 'TDC',
      tdd: 'TDD',
      transfer: 'transfer'
    },
    sources: {
      call: 'call',
      cds: 'CDS',
      email: 'Email',
      office: 'Office',
      sms: 'SMS',
      telegram: 'Telegram',
      web_page: 'Web page',
      whatsapp: 'Whatsapp'
    },
    rejection_concept: 'Rejection Concept',
    payment_support: 'Payment Support'
  }
};
