export default {
  props: [
    'subscriber_id'
  ],
  data(){
    return {
      dialog: false,
      loading: false,
      overlay: false,
      files: []
    }
  },
  computed:{
    //
  },
  methods:{
    close(){
      this.dialog = false
    },
    open ()  {
      this.loadFiles()
      this.dialog = true
    },
    loadFiles () {
      this.overlay = true
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
            subscriber_id: this.subscriber_id
          }
        })
        .then(response => {
          if (response.data.files) {
            this.files = response.data.files.data
          }
        })
        .catch(err => {
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'error',
            timeout: 6000,
            notifText: err
          })
        })
        .finally(()=>{
          this.overlay = false
        })
    }
  }
}
