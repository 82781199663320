import DialogEmployees from './DialogEmployees.vue';
import EmployeeContracts from '../EmployeeContracts/EmployeeContracts.vue';
import EmployeeFilter from '../../components/Employees/EmployeeFilter.vue';

export default {
  name: "Employees",
  components:{
    DialogEmployees,
    EmployeeContracts,
    EmployeeFilter
  },
  data (){
    return {
      selected_as_external: 0,
      dialogContracts:false,
      fullscreen:false,
      loadingTable:false,
      loading_type:false,
      loading_franchise:false,
      expand: false,
      is_filter:false,
      options:{
        page: 1,
        itemsPerPage: 10,
        sortBy:['legal_denomination[0]'],
        sortDesc:[false]
      },
      searches:{
        dni:'',
        active: true,
        type:'',
        franchise_id:'',
      },
      employeeContract:{},
      employee:{},
      employees:[],
      employee_types:[],
      ListFranchises:[],
      step : 'tab-1',
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      index: '',
      editedIndex:'',
      unwatch: '',
      search:'',
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      Permissions:{
        edit: false,
        create: false,
        attach: false
      },
    };
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  computed: {
    headers(){
      let headers =[
        {
          text: this.$t('company.identification'),
          value:'doc',
          align: 'left',
          width: 86
        },
        {
          text: this.$t('employees.legal_denomination'),
          value:'legal_denomination[0]',
          align: 'left'
        },
        {
          text: this.$t('employees.person_type'),
          value:'person_type',
          align: 'center',
          width: 140
        },
        {
          text: this.$t('employees.type'),
          value:'employee_type',
          align: 'center',
          width: 200
        }
      ];
      if (this.Permissions.change_external_collector) {
        headers.push(
          {
            text: 'Ext.',
            value:'is_external_collector',
            sortable: false,
            align: 'center',
            width: 60
          }
        );
      }
      if (this.Permissions.attach || this.Permissions.edit) {
        headers.push(
          {
            text: this.Permissions.edit ? this.$t('actions') : this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'right',
            width: 140
          }
        );
      }
      return headers;
    },
    selectEmployeeTypes(){
      return this.employee_types.map(type => {
        return {
          'value': type.value,
          'text': this.$tc(type.text,1)
        };
      });
    },
  },
  methods:{
    List(){
      this.loadingTable = true;
      if ((this.searches.dni != '' || this.searches.type != '' || this.searches.franchise_id != '') && this.is_filter) {
        this.options.page = 1;
      }
      if (this.step =='tab-1') {
        this.searches.active = true;
      } else if (this.step =='tab-2') {
        this.searches.active = false;
      }
      axios.get(this.$store.getters.getEmployees(),
        {
          params:{
            page:this.options.page,
            paginate:this.options.itemsPerPage,
            sort_by: this.options.sortBy[0] || null,
            sort_desc: this.options.sortDesc[0] || null,
            doc:this.searches.dni,
            legal_denomination:this.searches.legal_denomination,
            active:this.searches.active,
            employee_type:this.searches.type,
            franchise_id:this.searches.franchise_id,
          }
        }).then((response)=>{
          // eslint-disable-next-line no-console
          // console.log('employees',response.data.employees.data);
          this.employees = response.data.employees.data;
          this.totalValues = response.data.employees.total;
          if (this.selected_as_external == 0) {
            let external = this.employees.find(employee=>{
              return employee.is_external_collector == true;
            });
            if (external) {
              this.selected_as_external = external.id
            }
          }
        }).catch((error)=>{
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.error('Error',error.response);
          }
        }).finally(()=>{
          this.loadingTable = false;
          this.is_filter = false;
        });
    },
    getFranchises(){
      if (this.franchise_id == 0) {
        this.loading_franchise = true;
        axios.get(this.$store.getters.getFranchises()
        ).then((response)=>{
          // console.log(response.data.franchises.data);
          if (response.data.franchises.data) {
            let ListFranchises=[];
            response.data.franchises.data.forEach(element => {
              ListFranchises.push({
                id: element.id,
                name: element.name_franchise,
                branch: element.branch_office
              });
            });
            this.ListFranchises =  ListFranchises;
            this.loading_franchise = false;
          }
        }).catch((error)=>{
          if (error.response) {
            // console.log('Actor Error',error.response.data);
          }
        });
      }
    },
    ListEmployeesTypes(){
      this.loading_type = true;
      axios.get(this.$store.getters.getEmployeesTypes
      ).then(response=>{
        // eslint-disable-next-line no-console
        // console.log('types',response.data.employee_types);
        let employee_types = [];
        if (response.data.employee_types) {
          response.data.employee_types.forEach(type=>{
            employee_types.push({
              value:type,
              text:'employees.types.'+type,
            });
          });
        }
        this.employee_types = employee_types;
        this.loading_type = false;
        // eslint-disable-next-line no-console
        // console.log('types',this.employee_types);
      }).catch(error=>{
        if (error.response) {
          // console.log('Actor Error',error.response.data);
        }
      });
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$tc('employees.employee',1),
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.legal_denomination
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getEmployees(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    getContracts(){
      axios.get(this.$store.getters.getClientDocumentContracts)
      .then(response=>{
        response.data.contracts.data.map(contract=>{
          contract.legal_denomination = contract.subscriber.legal_denomination;
          contract.doc = contract.subscriber.doc;
        });
        // eslint-disable-next-line no-console
        // console.log(response.data.contracts.data);
      }).catch(error=>{
        // eslint-disable-next-line no-console
        // console.error(error.response);
      });
    },
    activateItem (item) {
      this.activateDeactivateItem(item,this.$t('activate'),this.$tc('activated',0), true);
    },
    deactivateItem (item) {
      this.activateDeactivateItem(item,this.$t('deactivate'),this.$tc('deactivated',0), false);
    },
    activateDeactivateItem (item, main_text, text, active) {
      let Employee = Object.assign({}, item);
      // eslint-disable-next-line no-console
      // Prefix
      var prefix = Employee.dni != null &&Employee.dni != undefined ? Employee.dni : '';
      if (typeof(prefix.charAt(0))=='string' && prefix!='') {
        Employee.prefix = prefix.charAt(0);
        Employee.dni = prefix.substring(1);
      } else {
        Employee.prefix = '';
        Employee.dni = prefix;
      }
      Employee.active = active;
      // eslint-disable-next-line no-console
      // console.log('employee', Employee);
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$tc('employees.employee',1),
        title: main_text,
        action: text,
        content: this.$t('base.confirm_delete',{
          action: main_text,
          record: Employee.legal_denomination[0][0]
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getEmployees(Employee.id),
          method: 'put',
          data:Employee
        }
      });
    },
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    newItem(){
      let obj ={
        Franchises: this.ListFranchises,
        EmployeesTypes:this.employee_types,
        employee: Object.assign({}, {is_firm:0,}),
        edit:false,
      };
      this.$refs.dialog_employees.open(obj);
    },
    editItem(item){
      // eslint-disable-next-line no-console
      // console.log('Item', item);
      this.editedIndex = this.employees.indexOf(item);
      let obj ={
        Franchises: this.ListFranchises,
        EmployeesTypes:this.employee_types,
        employee: Object.assign({}, item),
        edit:true,
      };
      this.$refs.dialog_employees.open(obj);
      // axios.get(this.$store.getters.getEmployees(item.id))
      // .then(response=>{
      //   // eslint-disable-next-line no-console
      //   console.log(response);
      // }).catch(error=>{
      //   // eslint-disable-next-line no-console
      //   console.log(error);
      // });
    },
    employeeContracts(item){
      // eslint-disable-next-line no-console
      // console.log('Item', item);
      this.employeeContract = item;
      this.dialogContracts = true;
    },
    close(){
      this.dialogContracts = false;
    },
    SearchEmployee(data){
      this.searches.legal_denomination = data.legal_denomination;
      this.searches.type = data.employee_type;
      this.searches.dni = data.dni;
      this.searches.franchise_id = data.franchise_id;
      this.SearchFilter();
    },
    SearchFilter(){
      this.is_filter = true;
      this.options = {page:1,sortBy:['updated_at'],sortDesc:[true]};
    },
    EraseFilter(){
      this.searches = {dni:'',active:'',type:'',franchise_id:''};
      this.options = {page:1,sortBy:['updated_at'],sortDesc:[true]};
    },
    changeExternalCollector (item) {
      this.loadingTable = true
      axios.patch(this.$store.getters.changeExternalCollector(item.id))
        .then(response=>{
          if (response.data.success) {
            this.selected_as_external = response.data.success.id
            this.List()
          }
        }).catch(error=>{
          this.loadingTable = false
        });
    },
    PermissionsEmployees(){
      if (this.$store.getters.isAdmin){
        this.Permissions={
          edit: true, create: true, attach: true, change_external_collector: true
        };
      } else {
        this.Permissions.create = this.getPermissionGroup({
          permission:[
            'index_employee',
            'store_employee',
          ],
          mode:'all'
        });
        this.Permissions.edit = this.getPermissionGroup({
          permission:[
            'index_employee',
            'update_employee',
          ],
          mode:'all'
        });
        this.Permissions.attach = this.getPermissionGroup({
          permission:[
            'getAttachedContracts_employee',
            'getUnattachedContracts_employee',
            'attachContracts_employee',
            'unattachContracts_employee'
          ],
          mode:'all'
        });
        this.Permissions.change_external_collector = this.getPermissionGroup({
          permission:[
            'changeExternalCollector_employee'
          ],
          mode:'all'
        });
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
    step(){
      this.searches = {dni:'',active:'',type:'',franchise_id:''}
      this.options = {page:1,sortBy:['updated_at'],sortDesc:[true]}
    }
  },
  mounted() {
    this.PermissionsEmployees();
    this.getFranchises();
    this.ListEmployeesTypes();
  },
};
