var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2 mb-6",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","color":"grey","icons-and-text":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"},on:{"click":function($event){return _vm.getTab(1)}}},[_vm._v(" "+_vm._s(_vm.$t("company.active"))+" "),_c('v-icon',[_vm._v("fas fa-check-double")])],1),_c('v-tab',{attrs:{"href":"#tab-2"},on:{"click":function($event){return _vm.getTab(2)}}},[_vm._v(" "+_vm._s(_vm.$t("company.inactive"))+" "),_c('v-icon',[_vm._v("fas fa-ban")])],1),_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-data-table',{staticClass:"elevation-1 pt-10",attrs:{"headers":_vm.headers,"items":_vm.companies,"sort-by":"id","page":_vm.page,"items-per-page":_vm.perPage,"footer-props":{
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': _vm.$t('perPage')
              },"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(_vm._s(_vm.$tc("company.company", 1)))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","fab":!_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){_vm.initDialogCompany((_vm.action = 'edit')), (_vm.dialog = true)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("company.new")))]),_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.showPreview(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("preview")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"delete":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("deactivate")))])])]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-data-table',{staticClass:"elevation-1 pt-10",attrs:{"headers":_vm.headers,"items":_vm.companiesDeactives,"sort-by":"id","page":_vm.page,"items-per-page":_vm.perPage,"footer-props":{
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': _vm.$t('perPage')
              },"server-items-length":_vm.totalValues,"loading":_vm.loadingTable},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(_vm._s(_vm.$tc("company.company", 1)))])],1)]},proxy:true},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.activeItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-recycle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("activate")))])])]}}],null,true)})],1)],1)]],2)],1),_c('DialogCompany',{ref:"dialog_company",on:{"cuCompany":_vm.initialize}}),_c('DialogPreview',{ref:"dialog_preview"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }