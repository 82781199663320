<template>
  <v-dialog
    ref="dialog_date"
    v-model="date_dialog"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-model="computedDate"
        :name="nameTag"
        :error-messages="errorMessages"
        :label="labelTag"
        :hint="showHint ? $store.getters.getDateFormat[0] : ''"
        :prepend-icon="prependIcon"
        :disabled="disabled"
        :dense="dense"
        :class="classInput"
        persistent-hint
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      scrollable
      :year-icon="yearIcon"
      :prev-icon="prevIcon"
      :next-icon="nextIcon"
      :value="date"
      :min="min"
      :max="max"
      :type="typeDateTag"
      :disabled="disabled"
      :landscape="landscape"
      @input="update"
      @change="changeDate"
    >
    </v-date-picker>
    <!-- year-icon -->
  </v-dialog>
</template>

<script>
export default {
  name: "date-dialog",
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    dense: {
      default: false,
      type: Boolean
    },
    landscape: {
      default: false,
      type: Boolean
    },
    showHint: {
      default: false,
      type: Boolean
    },
    value: {
      default: "",
      type: String
    },
    typeDateTag: {
      type: String,
      default: "date"
    },
    labelTag: {
      type: String,
      default: "date"
    },
    nameTag: {
      type: String,
      default: "date"
    },
    errorMessages: {
      default: ""
    },
    prependIcon: {
      type: String,
      default: ""
    },
    yearIcon: {
      type: String,
      default: ""
    },
    prevIcon: {
      type: String,
      default: "fas fa-chevron-left"
    },
    nextIcon: {
      type: String,
      default: "fas fa-chevron-right"
    },
    classInput: {
      type: String,
      default: ""
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },
  data() {
    return {
      date_dialog: false,
      date: this.value
    };
  },
  computed: {
    computedDate() {
      return this.formatDate(this.date);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    update(e) {
      // eslint-disable-next-line no-console
      // console.log('date', e);
      this.$refs.dialog_date.save(e);
      this.date_dialog = false;
      this.$emit("input", e);
    },
    changeDate() {
      this.$emit("changeDate");
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        // eslint-disable-next-line no-console
        // console.log('watch value', val);
      }
    },
    max: {
      immediate: true,
      handler() {
        // this.$emit('input','')
      }
    },
    min: {
      immediate: true,
      handler() {
        // this.$emit('input','')
      }
    }
  }
};
</script>
