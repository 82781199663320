<template>
  <v-dialog
    v-model="details_dialog"
    persistent
    scrollable
    fullscreen
    hide-overlay
    >
    <v-card tile>
        <v-toolbar dense tile
          max-height="48"
          class="requests-modal-toolbar elevation-4"
          style="z-index: 1;"
        >
          <v-toolbar-title>
            <span class="subtitle-1 font-weight-bold py-1">
              <span>
                {{$t('feasibilityReport.network')}}
              </span>
            </span>
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-toolbar-items>
            <v-btn text @click="details_dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row :style="{ 
              'position': !$vuetify.breakpoint.smAndDown ? 'relative': ''
            }">
              <v-col cols="12" md="7" class="py-0">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$t('feasibilityReport.report')}}
                        {{ 
                          feasibility_report_network.status != undefined && 
                          feasibility_report_network.status != 'active' ? 
                            $t(`feasibility.${feasibility_report_network.status}`) : ''
                        }}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <materialdial ref="materialdial" @material="addEquipmentMaterials"></materialdial>
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <v-col cols="12" sm="7" md="7" lg="5" class="py-1 pl-0 order-1">
                          <v-text-field
                            v-model="feasibility_report_network.version"
                            v-validate="'required|max:190'"
                            :disabled="tab == 'tab-3' || !edit"
                            name="feasibility_report_network.version"
                            :error-messages="errors.collect('feasibility_report_network.version')"
                            :label="$t('feasibility.version')"
                            :data-vv-as="$t('feasibility.version')"
                            prepend-icon="fas fa-code-branch"
                            class="px-0"
                            counter
                            maxlength="190"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="7" md="6" lg="8" class="py-1 pl-0 order-2">
                          <v-text-field
                            v-model="feasibility_report_network.elaboration_by"
                            v-validate="'required|max:190'"
                            :disabled="tab == 'tab-3' || !edit"
                            name="feasibility.elaboration_by"
                            :error-messages="errors.collect('feasibility.elaboration_by')"
                            :label="$t('feasibility.elaboration_by')"
                            :data-vv-as="$t('feasibility.elaboration_by')"
                            prepend-icon="fas fa-user-tie"
                            class="px-0"
                            counter
                            maxlength="190"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5" md="6" lg="4" class="py-1 pl-0 order-3">
                          <v-menu
                            v-model="date_menu_elaboration"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="computedDateFormatted"
                                v-validate="'required'"
                                :disabled="tab == 'tab-3' || !edit"
                                name="feasibility.elaboration_date"
                                :error-messages="errors.collect('feasibility.elaboration_date')"
                                :label="$t('feasibility.elaboration_date')"
                                :data-vv-as="$t('feasibility.elaboration_date')"
                                prepend-icon="fas fa-calendar"
                                readonly
                                persistent-hint
                                :hint="$store.getters.getDateFormat[0]"
                                v-on="on"
                                class="px-0"
                              ></v-text-field>
                            </template>
                            <v-date-picker ref="picker"
                              v-model="feasibility_report_network.elaboration_date"
                              :min="request_date ? request_date : '1920-01-01'"
                              :max="today"
                              @input="date_menu_elaboration = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="py-1 pl-0 order-4">
                          <v-textarea auto-grow
                            :disabled="tab == 'tab-3' || !edit"
                            v-model="feasibility_report_network.observation"
                            name="feasibility_report_network.observation"
                            :label="$t('feasibility.observation')"
                            prepend-icon="fas fa-clipboard"
                            row-height="30"
                            class="px-0 pb-1 text-center"
                            counter
                            maxlength="500"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                    
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" class="py-0" :class="{'order-first': $vuetify.breakpoint.smAndDown }">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$tc('requests.requests',1)}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <filepreview
                          v-if="loaded_file"
                          :file="file"
                          class="mx-auto"
                        ></filepreview>
                        <v-skeleton-loader
                          v-else
                          class="preview-skeleton my-3 mx-auto"
                          width="156"
                          height="224"
                          type="card-avatar"
                        ></v-skeleton-loader>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" offset-md="7" class="pt-0 pb-6" :style="{'position': !$vuetify.breakpoint.smAndDown ? 'absolute': '', 'top': '20rem'}">
                <v-card outlined class="elevation-0 step-inner mx-auto card-outlined" :height="computedHeight">
                  <v-card-title>
                    <div class="title primary--text">
                      <span class="px-1 white">
                        {{$t('feasibilityReport.equipmentmaterials')}}
                      </span>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-container class="pt-1 pb-2">
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <v-btn block text color="primary" 
                            v-if="tab != 'tab-3' && edit"
                            dark 
                            @click="$refs['materialdial'].open(undefined,undefined,feasibility_report_network.contract_id)" 
                            class="pr-0"> 
                              <v-icon class="ml-auto mr-2">
                                fas fa-plus
                              </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="compmaterial.length > 0" class="px-0 pb-0">
                          <span v-for="(item,i) in compmaterial" :key="i" class="py-1 my-12">
                            <v-chip
                              label
                              :disabled="tab == 'tab-3' || !edit"
                              :close="tab != 'tab-3' && edit"
                              @click="$refs['materialdial'].open(item,i,feasibility_report_network.contract_id)"
                              @click:close="removeEquipmentMaterials(i,item.id)"
                              chip-network
                            >
                              {{ item.name }}
                              {{ `${$t('concept.quantity')}: ${item.quantity}` }}
                            </v-chip>
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn 
            color="success"
            v-if="tab == 'tab-2' && !edit"
            @click="feasible('feasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.feasible')}}
            </span>
            <v-icon small>fas fa-clipboard-check</v-icon>
          </v-btn>
          <v-btn 
            color="error"
            v-if="tab == 'tab-2' && !edit"
            class="ml-4 mr-12"
            @click="feasible('infeasible')"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t('feasibility.infeasible')}}
            </span>
            <v-icon small>fas fa-file-excel</v-icon>
          </v-btn>
          <v-btn v-if="edit && tab != 'tab-1'" color="info" text @click="editMode(false)">
            <v-icon small>fa fa-arrow-left</v-icon>
            <span v-show="$vuetify.breakpoint.mdAndUp" 
              class="ml-3">
              {{$t('back')}}
            </span>
          </v-btn>
          <v-btn 
            color="info"
            v-if="tab != 'tab-3'"
            @click="edit?save():editMode(true)"
            :loading="loading"
          >
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
              {{$t(`base.${edit ? 'save' : 'edit'}`)}}
            </span>
            <v-icon small v-text="`fas fa-${edit ? 'save' : 'edit'}`"></v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<style lang="scss">
  .v-chip[chip-network]{
    width: 100%;
    margin-bottom: 0.4rem;
    .v-chip__content {
      width: 100%;
      i[role="button"] {
        margin-left: auto
      }
    }
  }
</style>

<script>
import materialdial from './FeasibilityMaterial.vue'
export default {
  name: "FeasibilityDialogForm",
  components:{
    materialdial:materialdial
  },
  data() {
    return {
      unwatch: '',
      loading: false,
      details_dialog: false,
      date_menu_elaboration: false,
      i: '',
      materials: [],
      feasibility_report_network: {
        contract_id: null,
        version: "",
        elaboration_by:"",
        elaboration_date:"",
        observation:"",
        equipmentmaterials:[],
      },
      tab: '',
      edit: true,
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      request_date: null,
      file: {},
      loaded_file: false
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.feasibility_report_network.elaboration_date)
    },
    compmaterial() {
      return this.feasibility_report_network.equipmentmaterials.map(material => {
        return Object.assign(material,{
          name: this.getMaterial(material.equipment_materials_id)
        })
      })
    },
    computedHeight () {
      return !this.edit ? this.compmaterial.length == 0 ? 59 : '' : ''
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val && this.$store.getters.getConfirmationTemplate == 'feasibilityNetworkDialog'){
        this.$emit('created')        
        this.loading = false
        this.details_dialog = false
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  methods:{
    editMode(val){
      this.edit = val
      if (!val) {
        this.$validator.reset()
      }
    },
    getMaterial(id){
      let request = new XMLHttpRequest()
      request.open('GET',
        this.$store.getters.getMaterialsEquipment(id),
        false
      )
      request.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('token'));
      request.setRequestHeader("nkhokwe", localStorage.getItem('nkhokwe'));      
      request.send()
      request = JSON.parse(request.response)
      return request.equipmentMaterials.name
    },
    feasible(mode){
      let url, msg, title, action, type
      if (mode == 'feasible') {
        url = this.$store.getters.setFeasibilitiesNetworkStatus(this.feasibility_report_network.id)
        msg = this.$t('base.confirm_feasible')
        title = this.$t('base.approve')
        action = this.$tc('approved',1)
        type = 'success'
      } else {
        url = this.$store.getters.getInfeasibleNetworkService(this.feasibility_report_network.id)        
        msg = this.$t('base.confirm_infeasible')
        title = this.$t('base.deny')
        action = this.$tc('denied',1)
        type = 'error'
      }

      this.$store.dispatch('confirmationValue',{
        template: 'feasibilityNetworkDialog',
        show: true,
        model: this.$tc('feasibilityReport.network',1),
        title: title,
        action: action,
        content: msg,
        type: type,
        confirm:{
          url: url,
          method: 'get'
        }
      })
    },
    async open(contract,tab){
      if (contract.created_at) {
        this.request_date = new Date(contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      if (contract.contract && contract.contract.created_at) {
        this.request_date = new Date(contract.contract.created_at).toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10)
      }
      this.tab = tab
      if(this.tab == "tab-1"){
        this.feasibility_report_network = {
          contract_id: contract.id,
          version: '',
          elaboration_by:'',
          elaboration_date:'',
          observation:'',
          equipmentmaterials:[],
        }
        this.$validator.reset()
        this.edit = true
      }
      else{
        let response = await axios.get(this.$store.getters.getFeasibilitiesNetwork(contract.feasibility))
        if(response.status == "200"){
          this.feasibility_report_network = JSON.parse(JSON.stringify(response.data['feasibilityNetwork']))
          this.$set(this.feasibility_report_network,'equipmentmaterials',response.data['feasibilityEquipmentMaterials'])
          this.edit = false
        }
      }
      if (Object.entries(this.file).length === 0 && this.file.constructor === Object) {
        this.loadPDF()
      }
      this.tab != "tab-1" ? this.edit = false : ''
      this.details_dialog = true
    },
    addEquipmentMaterials(evt) {
      if(evt.index != undefined){
        this.$set(this.feasibility_report_network.equipmentmaterials,evt.index,evt.material)
      }
      else{
        this.feasibility_report_network.equipmentmaterials.push(evt);
      }
    },
    async removeEquipmentMaterials(index,item) {
      if(item != undefined){
        if(this.feasibility_report_network['removeEquipament'] == null){
          this.feasibility_report_network['removeEquipament'] = []
        }
        this.feasibility_report_network['removeEquipament'].push(item)
      }
      this.feasibility_report_network.equipmentmaterials.splice(index, 1);
    },
    save() {
      this.$validator.validateAll().then((valid)=>{
        if(valid){
          this.loading = true
          let form_data = new FormData()  
          for (let key in this.feasibility_report_network) {
            if(key == 'equipmentmaterials' || key == "removeEquipament"){
              form_data.append(key,JSON.stringify(this.feasibility_report_network[key]))
            }
            else{
              form_data.append(key,this.feasibility_report_network[key])
            }
          }
          if(this.feasibility_report_network.id != undefined){
            form_data.append('_method', 'PUT');
          }
          axios({
            url: this.$store.getters.getFeasibilitiesNetwork(this.feasibility_report_network.id),
            method: 'post',
            data: form_data
          })
          .then(response => {
            this.$store.dispatch('pushNotification', {
              snackbar:true,
              style: 'success',
              timeout: 6000,
              notifText: 'success',
              notifPos: 1,
              extra: {
                model: this.$t('feasibilityReport.network'),
                action: this.feasibility_report_network.id == undefined ? this.$tc('created', 1) :
                  this.$tc('updated',1) 
              }
            })
            if(this.feasibility_report_network.id == undefined){
              this.details_dialog = false
            }
            else{
              this.edit = false
            }
            this.$emit('created')
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    loadPDF () {
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
              'contract_id': this.feasibility_report_network.contract_id,
              'name': 'feasibility_order'
          }
        })
        .then(response => {
          if (response.data.files) {
            if (response.data.files.data.length > 0) {
              this.file = response.data.files.data[0]
              this.loaded_file = true
            } else {
              this.file.file_name = 'feasibility_order'
              this.loaded_file = true
            }
          }
        })
        .catch(err => {
          this.file = {}
        })
        .finally(()=>{
          //
        })
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
  },
  watch:{
    details_dialog: function (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        setTimeout(() => {
          this.file = {}
          this.loaded_file = false
          this.request_date = null
        }, 200)
      }
    }
  }
};
</script>
