var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2 mb-6",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.type_clients,"sort-by":_vm.changeT ? 'name' : 'is_active',"loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.createdPolicies,"disableNew":_vm.tableLoading,"title":_vm.$t('typeClient.title'),"nameButton":_vm.$tc('base.new',0)},on:{"newItem":_vm.newItem}})]},proxy:true},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}]},[_vm._v(_vm._s(item.is_active ? _vm.$tc('active',0) : _vm.$tc('inactive',0)))]),_c('v-icon',{attrs:{"x-small":"","right":"","color":item.is_active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.is_active ? 'fa-check' : 'fa-times')+" ")])]}},{key:"item.is_franchise",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"x-small":"","right":"","color":item.is_franchise  ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.is_franchise ? 'fa-check' : 'fa-times')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('featuresTypeClient',{ref:"featuresTypeClient",on:{"updateTypeClient":function($event){return _vm.getTypeClient()}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.createdPolicies),expression:"createdPolicies"}],attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.featuresTypeClient.openDialog(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-list")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.features')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.updatePolicies),expression:"updatePolicies"}],attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.deletePolicies),expression:"deletePolicies"}],attrs:{"delete":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.delete')))])])]}}],null,true)})]],2)],1),(!_vm.changeT)?_c('modalNewEdit',{ref:"modalNewEdit",on:{"getTypeClient":function($event){return _vm.getTypeClient()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }