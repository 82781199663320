import modalConfig from "./modalConfig.vue";
import FranchiseDialog from "./FranchiseDialog.vue";
// import serviceInstallation from '../ServiceInstallation/ServiceInstallation.vue'

export default {
  components: {
    modalConfig,
    FranchiseDialog
    // serviceInstallation
  },
  data: () => ({
    showP: false,
    showPwd: false,
    loadingFranchiseTable: false,
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 10
    },
    totalValues: 0,
    mode: "hex",
    companies: [],
    companiesInactive: [],
    item: "",
    action: "",
    unwatch: "",
    step:'tab-1',
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("franchise.identification"),
          value: "identification",
          width: 100
        },
        {
          text: this.$t("franchise.name_company"),
          value: "name_franchise"
        },
        {
          text: this.$t("franchise.branch_office"),
          value: "branch_office"
        },
        {
          text: this.$tc("franchise.company_owned", 2),
          value: "company_owned",
          align: "left",
          width: 90
        },
        {
          text: this.step == 'tab-1' ? this.$t("actions") : this.$t("action"),
          value: "action",
          sortable: false,
          align: "center",
          width: 180
        }
      ];
    },
    titleActiveFranchises(){
      return this.$i18n.locale == 'es'
      ? `${this.$tc('franchise.contractor', 1)} ${this.$tc(
          'franchise.active',
          2
        )}`
      : `${this.$tc('franchise.active', 2)} ${this.$tc(
          'franchise.contractor',
          1
        )}`
    },
    titleInactiveFranchises(){
      return this.$i18n.locale == "es"
      ? `${this.$tc("franchise.contractor", 1)} ${this.$tc(
          "franchise.inactive",
          2
        )}`
      : `${this.$tc("franchise.inactive", 2)} ${this.$tc(
          "franchise.contractor",
          1
        )}`
    }
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    },
    step(){
      this.initialize();
    },
  },
  mounted() {
    //
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        if (
          typeof val == "boolean" &&
          val &&
          this.$store.getters.getConfirmationTemplate == "franchises"
        ) {
          this.initialize();
        }
      }
    );
  },
  methods: {
    initialize() {
      this.companies = [];
      this.loadingFranchiseTable = true;
      let ops = {};

      ops = {
        params: {
          paginate: this.options.itemsPerPage,
          page: this.options.page
        }
      };
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0];
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0];
      }

      axios
        .get(this.$store.getters.getFranchises(), ops)
        .then(response => {
          // eslint-disable-next-line no-console
          // console.log(response);
          const franchisesActives = response.data.franchises.data;
          const franchisesInactive = response.data.inactiveFranchises.data;
          const totalFranchisesActives = response.data.franchises.total;
          const totalFranchisesInactive = response.data.inactiveFranchises.total;
          if (this.step == 'tab-1') {
            this.companies = franchisesActives;
            this.totalValues = totalFranchisesActives;
          } else if (this.step == 'tab-2') {
            this.companies = franchisesInactive;
            this.totalValues = totalFranchisesInactive;
          }
        })
        .catch(err => {
          let error = err.response;
          if (err.response.data) {
            error = err.response.data.error;
          }
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "error",
            timeout: 6000,
            notifText: error
          });
        })
        .finally(() => {
          this.loadingFranchiseTable = false;
        });
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        template: "franchises",
        show: true,
        model: this.$tc("franchise.company", 2),
        title: this.$t("deactivate"),
        action: this.$tc("deactivated", 2),
        content: this.$t("base.confirm_delete", {
          action: this.$t("deactivate"),
          record: item.name_franchise
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.getFranchises(item.id),
          method: "delete"
        },
        pos: 2
      });
    },
    activeItem(item) {
      this.$store.dispatch("confirmationValue", {
        template: "franchises",
        show: true,
        model: this.$tc("franchise.company", 2),
        title: this.$t("activate"),
        action: this.$tc("activated", 2),
        content: this.$t("base.confirm_delete", {
          action: this.$tc("activate", 0),
          record: item.name_franchise
        }),
        type: "info",
        confirm: {
          url: this.$store.getters.activateFranchise(item.id),
          method: "patch"
        },
        pos: 2
      });
    },
    configClientService(item) {
      this.$refs.modalConfig.open(item.id, item.configuration);
    },
    newFranchise(action){
      this.$refs.franchiseDialog.newItem(action);
    },
    editFranchise(item, action){
      const index = this.companies.indexOf(item)
      this.$refs.franchiseDialog.editItem(item, action, index);
    },
  },
  beforeDestroy() {
    this.unwatch();
  }
};
