export default {
  name: "subscribers-groups-select-dialog",
  props: {
    group: {
      type: Object,
      default: function () {
        return {
          id: 0,
          name: this.$tc('base.none', 1)
        }
      }
    }
  },
  data (){
    return {
      dialog: false,
      loadingSelect: false,
      selected_group: {
        id: 0
      },
      groups: [
        {
          id: 0,
          name: this.$tc('base.none', 1)
        }
      ],
      is_edit: false
    };
  },
  created () {
    //
  },
  mounted () {
    //
  },
  computed:{
    //
  },
  methods:{
    open () {
      if (this.group && this.group.id) {
        this.is_edit = true
        this.selected_group = this.group
      }
      this.getGroups()
      setTimeout(() => {
        this.dialog = true
      }, 40)
    },
    close () {
      this.dialog = false
      this.is_edit = false
      setTimeout(() => {
        this.selected_group = {
          id: 0
        }
      }, 400)
    },
    setGroup () {
      this.$emit('setGroupIdOne', this.selected_group)
      this.close()
    },
    getGroups () {
      this.loadingSelect = true

      if (this.groups.length == 1) {
        axios.get(this.$store.getters.catalogByCategory('subscriber_group'))
          .then(response=>{
            if (response.data.success) {
              this.groups = response.data.success
            }
          }).catch(error=>{
            if (error.response) {
              // eslint-disable-next-line no-console
              console.error(error.response)
            }
          }).finally(()=>{
            this.loadingSelect = false
          })
      }
    }
  }
}
