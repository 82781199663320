<template>
  <v-container fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 text-center v-if="loading">
        <v-progress-circular
          :size="74"
          :width="4"
          color="primary"
          indeterminate
        >
          <span class="caption">{{ $t('base.wait') }}<span style="letter-spacing: 0.1px;">&hellip;</span></span>
        </v-progress-circular>
      </v-flex>
      <v-flex xs12 sm6 md5 lg4 v-if="success">
        <v-card class="pl-2 pr-3">
          <ValidationObserver ref="form_pass_reset" v-slot="{ handleSubmit }">
            <form class="pass-reset-form" @submit.prevent="handleSubmit(submit)">
              <v-card-title primary-title class="justify-center">
                <h3 class="headline mb-0">{{ $t("base.password_reset") }}</h3>
              </v-card-title>
              <v-card-text class="text-center pt-0">

                <!-- Registered Email -->
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="email"
                  :name="$t('base.email')"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="register.email"
                    name="email"
                    prepend-icon="fas fa-envelope"
                    autocomplete="off"
                    :label="$t('base.email')"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>

                <!-- New Password -->
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="password"
                  :name="$t('base.password')"
                  :rules="{
                        'required':true,
                        regex:/^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/
                      }"
                >
                  <v-text-field
                    v-model="register.password"
                    name="password"
                    prepend-icon="fas fa-lock"
                    ref="new_password"
                    counter
                    minlength="8"
                    maxlength="16"
                    :label="$t('base.password')"
                    :append-icon="show2 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                    :type="show2 ? 'text' : 'password'"
                    :error-messages="errors"
                    @click:append="show2 = !show2"
                    @focus.stop="showP=true"
                    @blur.stop="showP=false"
                  ></v-text-field>
                </ValidationProvider>

                <verifypassword
                  class="text-left"
                  v-show="showP"
                  ref="verify"
                  >
                </verifypassword>

                <!-- Confirm New Password -->
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="password_confirmation"
                  :name="$t('base.pwd', [$t('base.confirm')])"
                  :rules="{
                    required : true,
                    passConfirm: register.password
                  }"
                >
                  <v-text-field
                    v-model="register.password_confirmation"
                    name="password_confirmation"
                    prepend-icon="fas fa-lock"
                    counter
                    minlength="8"
                    maxlength="16"
                    :label="$t('base.pwd', [$t('base.confirm')])"
                    :error-messages="errors"
                    :append-icon="show3 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                    :type="show3 ? 'text' : 'password'"
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </ValidationProvider>

                <!-- Token -->
                <v-text-field
                  v-model="register.token"
                  type="hidden"
                  class="d-none"
                ></v-text-field>

              </v-card-text>
              <v-card-actions class="justify-center py-3 pb-5">
                <v-btn dark
                  :loading="loading2"
                  color="green lighten-1"
                  @click="resetPassword"
                >
                  {{ $t("base.send") }}
                </v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
          </v-card>
      </v-flex>
      <v-flex xs12 sm8 md6 v-if="error">
        <v-card class="py-4">
          <v-card-text class="text-center pt-2">
            <v-flex xs12>
              <i class="fas fa-lock fa-6x grey--text"></i>
            </v-flex>
            <v-layout row wrap align-top justify-center mt-4 mb-2 pr-3 py-2>
              <v-flex xs12 sm2>
                <v-icon medium color="red">fas fa-times</v-icon>
              </v-flex>
              <v-flex xs12 sm10>
                <h3>
                  {{ $t(error_message) }}<br><br>
                  {{ $t('password_reset.re_request') }}
                </h3>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center py-1 pb-4">
            <v-btn dark
              color="red lighten-1"
              @click="openRecovery"
            >
              {{ $t('base.password_recovery') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script type="text/javascript">
export default {
  name: 'find-token',
  data () {
    return {
      showP: false,
      loading: null,
      error: false,
      success: false,
      error_message: '',
      register: {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      },
      show2: false,
      show3: false,
      loading2: false
    }
  },
  methods: {
    openRecovery: function () {
      this.$store.dispatch('diaRecov', true)
    },
    findToken: function () {
      this.loading = true
      setTimeout(() => {
        axios.get(this.$store.getters.passFindUrl(this.$router.currentRoute.params.token))
          .then((response) => {
            this.loading = false
            if (response.data.success) {
              this.success = true
              this.register.token = this.$router.currentRoute.params.token
            } else {
              this.error = true
              this.emitNotification(true, 'warning', null, 6000, 'base.something_wrong')
            }
          }).catch((error) => {
            this.loading = false
            this.error = true
          })
      }, 400)
    },
    resetPassword(){
      this.$refs.form_pass_reset.validate()
      .then((success) => {
        if (success) {
          this.loading2 = true
          axios.patch(this.$store.getters.passResetUrl, this.register)
            .then((response) => {
              this.loading2 = false
              if (response.data.success) {
                this.success = false
                this.$router.push({ name: 'home' })
                this.emitNotification(true, 'success', null, 4000, response.data.success)
              } else {
                this.emitNotification(true, 'warning', null, 6000, 'base.something_wrong')
              }
            }).catch((error) => {
              this.loading2 = false
            })
        }
      }).catch((error) => {
        // console.log(error)
      })
    },
    emitNotification(snackbar, style, mode, timeout, notifText) {
      this.error_message = notifText
      this.$store.dispatch('pushNotification', {
        snackbar:snackbar,
        style:style,
        mode:mode,
        timeout:timeout,
        notifText:notifText,
        extra:null
      })
    }
  },
  mounted () {
    this.findToken()
  },
  watch: {
    'register.password':{
      handler(val){
        if(this.showP){
          this.$refs.verify.changePass(val)
        }
      }
    }
  },
}
</script>
