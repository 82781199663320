export default {
  props: {
    franchiseId: Number
  },
  data() {
    return {
      showP: false,
      showPwd: false,
      showPwdC: false,
      actor: {
        avatar: null,
        avatar_url: null,
        dni: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        birthday: '',
        position: '',
        sign: null,
        sign_url: null,
        seal: null,
        seal_url: null,
        address: '',
        phone: '',
        cellphone: '',
        personal_mail: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        role: '',
      },
      loadingSelPrefix: false,
      prefix: null,
      prefixes:[],
      comparison_policies:[],
      individual_policies:[],
      actor_policies:[],
      role_policies:[],
      policies:[],
      roles: [],
      dialog: false,
      edit: false,
      current_id: 0,
      positions: [],
      birth_menu: false,
      years_18_ago: new Date(Date.now() - 567648000000).toISOString().substr(0, 10),
      image_dialog:false,
      card: {
        src:'',
        title:'',
      },
      loading: false,
      loadingPolicies: false,
      av_image: '',
      item_to_delete: '',
      modeNode:process.env.NODE_ENV,
    }
  },
  watch:{
    birth_menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    dialog: function (val) {
      if (val) {
        this.loadPrefixes()
      }
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    'actor.password':{
      handler(val){
        if(this.showP){
          this.$refs.verify.changePass(val)
        }
      }
    }
  },
  methods: {
    close(){
      this.dialog = false
      this.edit = false
      this.clean_data()
    },
    save() {
      this.$refs.form_actor.validate().then(success => {
        if (success) {
          this.loading = true;
          if (this.edit == false) {
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    create(){
      let form_data = new FormData()
      for ( let key in this.actor ) {
        if(key=='sign' && this.actor.sign!=null){
          form_data.append('sign',this.actor.sign)
        }else if(key=='seal' && this.actor.seal!=null){
          form_data.append('seal',this.actor.seal)
        }else if(key=='avatar' && this.actor.avatar!=null){
          form_data.append('avatar',this.actor.avatar)
        }
        else if(key!='sign' && key!='seal' && key!='avatar'){
          form_data.append(key,this.actor[key])
        }
      }
      form_data.append('franchise_id',this.franchiseId)
      form_data.append('prefix',this.prefix)
      axios.post(this.$store.getters.actorsSources(),form_data)
      .then(response =>{
        if(response.status == 200){
          this.$store.dispatch('pushNotification', {
            snackbar:true,
            style: 'success',
            timeout: 6000,
            notifText: 'success',
            notifPos: 1,
            extra: {
              model: this.$tc('actor.actor',1),
              action: this.$tc('created',1)
            }
          })
          this.dialog = false
          this.loading = false
          this.clean_data()
          this.$emit('created',true)
        }
      })
      .catch(err=>{
        this.loading = false
      })
    },
    update(){
      let form_data = new FormData()
      for ( let key in this.actor ) {
        if(key=='sign' && (this.actor.sign!=null && typeof(this.actor.sign)!='string')){
          form_data.append('sign',this.actor.sign)
        }else if(key=='seal' && (this.actor.seal!=null && typeof(this.actor.seal)!='string')){
          form_data.append('seal',this.actor.seal)
        }
        else if(key=='avatar' && (this.actor.avatar!=null && typeof(this.actor.avatar)!='string')){
          form_data.append('avatar',this.actor.avatar)
        }
        else if(key!='sign' && key!='seal' && key!='avatar'){
          form_data.append(key,this.actor[key])
        }
      }
      form_data.append('franchise_id',this.franchiseId)
      form_data.append('prefix',this.prefix)
      form_data.append('_method', 'PUT')
      axios.post(this.$store.getters.actorsSources(this.current_id),form_data)
      .then(response =>{
        if(response.status == 200){
          this.$store.dispatch('pushNotification', {
            snackbar:true,
            style: 'success',
            timeout: 6000,
            notifText: 'success',
            notifPos: 1,
            extra: {
              model: this.$tc('actor.actor',1),
              action: this.$tc('updated',1)
            }
          })
          this.dialog = false
          this.loading = false
          this.clean_data()
          this.edit = false
          this.current_id = 0
          this.$emit('created',true)
        }
      })
      .catch(err=>{
        this.loading = false
      })
    },
    load_data(data){
      this.current_id = data.id;
      axios.get(this.$store.getters.actorsSources(data.id),{
        params:{
          franchise_id: this.franchiseId
        }
      })
        .then(response => {
          data = response.data.data
          this.prefix = data.dni.substring(0,1)
          this.actor = {
            dni: parseInt(data.dni.substring(1,10), 10),
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.user.email,
            password: '',
            password_confirmation: '',
            birthday: data.birthday,
            position: data.position,
            avatar: data.avatar,
            avatar_url: data.avatar_url,
            sign: data.sign,
            sign_url: data.sign_url,
            seal: data.seal,
            seal_url: data.seal_url,
            address: data.address,
            phone: data.phone,
            cellphone: data.cellphone,
            personal_mail: data.personal_mail,
            facebook: data.facebook == 'null' ? '':data.facebook,
            twitter: data.twitter == 'null' ? '':data.twitter,
            instagram: data.instagram == 'null' ? '':data.instagram,
            linkedin: data.linkedin == 'null' ? '':data.linkedin,
            role: data.role,
          };

          this.actor_policies = data.policies;
          this.role_policies = data.role_policies;
          // eslint-disable-next-line no-console
          this.individual_policies = data.policies.map(policies => {
            return policies.id;
          });
          this.comparison_policies = [].concat(this.individual_policies);
          this.av_image = data.avatar_url ? data.avatar_url : '';
          this.edit = true;
          this.dialog = true;
        })
    },
    clean_data(){
      this.$refs.form_actor.reset();
      this.prefix = null
      this.actor = {
        dni: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        birthday: '',
        position: '',
        avatar: null,
        avatar_url: null,
        sign: null,
        sign_url: null,
        seal: null,
        seal_url: null,
        address: '',
        phone: '',
        cellphone: '',
        personal_mail: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        role: '',
      }
      this.comparison_policies = [];
      this.individual_policies = [];
      this.role_policies = [];
      this.actor_policies=[];
      this.av_image = '';
      this.$refs['avtr'].reset()
      this.$refs['sign'].reset()
      this.$refs['seal'].reset()
    },
    getRoles(){
      axios.get(this.$store.getters.rolesSources(),{
        params:{
          paginate: 10,
          franchise_id: this.franchiseId,
          'select_only': true
        }
      })
      .then(response => {
        this.roles = response.data.roles
      })
    },
    getCatalog(){
      axios.get(this.$store.getters.catalogByCategory('position'))
      .then(response => {
        this.positions = response.data.success
      })
    },
    loadSign(e){
      if(e && e.size > 0 && !['image/png','image/jpeg','image/jpg'].includes(e.type)) {
        this.$refs['sign'].reset()
        this.$store.dispatch('pushNotification', {
          snackbar: true,
          style: 'error',
          timeout: 6000,
          notifText: 'error422.avatar_must_valid_image',
          extra: this.$t('actor.sign')
        })
      }
      else if (e && e.size > 2097152) {
        this.$refs['sign'].reset()
        this.$store.dispatch('pushNotification', {
          snackbar: true,
          style: 'error',
          timeout: 6000,
          notifText: 'error422.avatar_max_2048_kb',
          extra: this.$t('actor.sign')
        })
      }
      else if (e) {
        this.actor.sign = e
      }
    },
    loadSeal(e){
      if(e && e.size > 0 && !['image/png','image/jpeg','image/jpg'].includes(e.type)) {
        this.$refs['seal'].reset()
        this.$store.dispatch('pushNotification', {
          snackbar: true,
          style: 'error',
          timeout: 6000,
          notifText: 'error422.avatar_must_valid_image',
          extra: this.$t('actor.seal')
        })
      }
      else if (e && e.size > 2097152) {
        this.$refs['seal'].reset()
        this.$store.dispatch('pushNotification', {
          snackbar: true,
          style: 'error',
          timeout: 6000,
          notifText: 'error422.avatar_max_2048_kb',
          extra: this.$t('actor.seal')
        })
      }
      else if (e) {
        this.actor.seal = e
      }
    },
    deleteImage(name, model, pos){
      const url = this.franchiseId === 0 ? this.$store.getters.actorImageSources(this.current_id):
      this.$store.getters.actorImageSources(this.current_id)+'?franchise_id='+this.franchiseId
      this.item_to_delete = model
      let extra_data = {
        'name': name
      }
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('actor.'+model),
        title: this.$t('base.delete'),
        action: this.$tc('deleted',pos),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: this.$t('actor.'+model)
        }),
        type: 'error',
        confirm:{
          url: url,
          method: 'post',
          data: extra_data
        },
        pos: pos
      })
    },
    showImage(name){
      this.image_dialog = true
      this.card.src = name
    },
    clickFile () {
      this.$refs.avtr.$refs.input.click()
    },
    dropImg () {
      this.av_image = ''
      this.actor.avatar = null
    },
    displayPreview (e) {
      if (e && e.size > 0) {
        if (!['image/png','image/jpeg','image/jpg'].includes(e.type)) {
          this.dropImg()
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'error',
            timeout: 6000,
            notifText: 'error422.avatar_must_valid_image',
            extra: this.$t('actor.avatar')
          })
        } else if (e.size > 2097152) {
          this.dropImg()
          this.$store.dispatch('pushNotification', {
            snackbar: true,
            style: 'error',
            timeout: 6000,
            notifText: 'error422.avatar_max_2048_kb',
            extra: this.$t('actor.avatar')
          })
        } else {
          this.actor.avatar = e
          const fr = new FileReader ()
          fr.readAsDataURL(e)
          fr.addEventListener('load', () => {
            this.av_image = fr.result
          })
        }
      }
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date).format(this.$store.getters.getDateFormat[0])
    },
    getPolicies(){
      axios.get(this.$store.getters.policiesSources(),{
        params:{
          franchise_id: this.franchiseId
        }
      })
        .then(response => {
          this.policies = response.data.policies
        })
    },
    savePolicies(){
      this.loadingPolicies = true
      const data = { 'policies': this.individual_policies, 'franchise_id': this.franchiseId }
      axios.post(this.$store.getters.actorIndiviualPoliciesSources(this.current_id),data)
      .then(response => {
        let text = `${this.$tc('the_2',0)} ${this.$tc('roles.individual_permissions',1)} ${this.$tc('were',0)}
        ${this.$tc('attached',2)}`
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style: 'success',
          timeout: 6000,
          notifPos: 1,
          notifText: text
        })
        this.loadingPolicies = false
        this.comparison_policies = [].concat(this.individual_policies)
      })
      .catch((error) => {
        this.loadingPolicies = false
      })
    },
    remove (item) {
      const index = this.individual_policies.indexOf(item.id)
      // console.log(index)
      if (index >= 0) this.individual_policies.splice(index, 1)
    },
    receivedPolicies(item){
      this.individual_policies = item;
    },
    loadPrefixes() {
      if (this.prefixes.length == 0) {
        this.loadingSelPrefix = true
        axios.get(this.$store.getters.getPrefixes,{
          params: {
            'type': 'natural'
          }
        })
          .then(response => {
            this.loadingSelPrefix = false
            this.prefixes = response.data.prefixes
          })
          .catch(err => {
            this.loadingSelPrefix = false
            this.prefixes = []
          })
      }
    }
  },
  computed:{
    computedComparison (){
      // Sort arrays
      let arr1 = [].concat(this.comparison_policies).sort()
      let arr2 = [].concat(this.individual_policies).sort()
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false;
      // Check if all items exist and are in the same order
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      // Otherwise, return true
      return true
    },
    computedDateFormatted () {
      return this.formatDate(this.actor.birthday)
    },
  },
  mounted(){
    this.getCatalog()
    this.getRoles()
    this.getPolicies()
  }
}
