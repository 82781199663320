var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classParent},[_c('ValidationObserver',{ref:"form_contact",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"contact-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"first_name","name":_vm.$t('subscribers.first_name'),"rules":{
              required: true,
              min: 3,
              regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-uppercase",attrs:{"name":"first_name","prepend-icon":"fas fa-user","counter":"","maxlength":"40","label":_vm.$t('subscribers.first_name'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.first_name),callback:function ($$v) {_vm.$set(_vm.contact, "first_name", $$v)},expression:"contact.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"last_name","name":_vm.$t('subscribers.last_name'),"rules":{
              required: true,
              min: 3,
              regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-uppercase",attrs:{"name":"last_name","prepend-icon":"fas fa-user","counter":"","maxlength":"40","label":_vm.$t('subscribers.last_name'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.last_name),callback:function ($$v) {_vm.$set(_vm.contact, "last_name", $$v)},expression:"contact.last_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4","sm":"5"}},[_c('ValidationProvider',{attrs:{"vid":"prefix","name":_vm.$tc('subscribers.prefix', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.natural_prefixes,"name":"prefix","prepend-icon":"fas fa-list","menu-props":"auto","label":_vm.$tc('subscribers.prefix', 1),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingPrefixes || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingPrefixes || _vm.loadingForm},model:{value:(_vm.contact.prefix),callback:function ($$v) {_vm.$set(_vm.contact, "prefix", $$v)},expression:"contact.prefix"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8","sm":"7"}},[_c('ValidationProvider',{attrs:{"vid":"dni","name":_vm.$t('dni'),"rules":"required|numeric|min:6|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"small-inner-icon",attrs:{"name":"dni","counter":"","maxlength":"9","prepend-inner-icon":"fas fa-address-card","label":_vm.$t('dni'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.dni),callback:function ($$v) {_vm.$set(_vm.contact, "dni", $$v)},expression:"contact.dni"}})]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"position","name":_vm.$tc('actor.position'),"rules":{
              required: true,
              regex: /^[A-Za-zÁÉÍÓÚÑáéíóúñ. ]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"position","prepend-icon":"fas fa-sitemap","label":_vm.$tc('actor.position'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.position),callback:function ($$v) {_vm.$set(_vm.contact, "position", $$v)},expression:"contact.position"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"local_phone","name":_vm.$t('phone'),"rules":"numeric|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"local_phone","counter":"","maxlength":"11","prepend-icon":"fas fa-phone fa-rotate-180","label":_vm.$t('phone'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.local_phone),callback:function ($$v) {_vm.$set(_vm.contact, "local_phone", $$v)},expression:"contact.local_phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"cellphone","name":_vm.$t('cellphone'),"rules":"required|numeric|digits:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"cellphone","counter":"","maxlength":"11","prepend-icon":"fas fa-mobile-alt","label":_vm.$t('cellphone'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.cellphone),callback:function ($$v) {_vm.$set(_vm.contact, "cellphone", $$v)},expression:"contact.cellphone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('base.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","counter":"","maxlength":"140","prepend-icon":"fas fa-envelope","label":_vm.$t('base.email'),"error-messages":errors,"disabled":_vm.loadingContact || _vm.loadingForm,"loading":_vm.loadingContact || _vm.loadingForm},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }