import BroadcastNotificationDialog  from './Broadcast/Dialog.vue'

export default {
  name: "NotificationsTable",
  components:{
    BroadcastNotificationDialog
  },
  data() {
    return {
      loadingTable: false,
      notifications: [],
      perPage: 10,
      options: {},
      totalValues:0,
    }
  },
  computed:{
    headers(){
      return [
        {
          text: this.$t('type'),
          align: 'center',
          sortable: false,
          value: 'type',
          width: 58
        },
        {
          text: this.$t('content'),
          align: 'left',
          sortable: false,
          value: 'text'
        },
        {
          text: this.$t('date'),
          align: 'center',
          sortable: false,
          value: 'created_at',
          width: 100
        },
      ]
    },
    lastReaded(){
      return this.$store.getters.notificationReaded
    },
    newNotification(){
      return this.$store.getters.getAllNotifications
    }
  },
  methods:{
    openBroadcast () {
      this.$refs.broadcast_notification_dialog.open()
    },
    getNotifications () {
      this.loadingTable = true
      axios.get(this.$store.getters.getNotification(),{
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
        }
      })
      .then(response => {
        this.loadingTable = false
        this.notifications = response.data.success.data.map(notification => {
          return {
            id: notification.id,
            created_at: notification.created_at,
            type: notification.type,
            text: notification.text,
            read: notification.read
          }
        })
        this.totalValues = response.data.success.total
      })
      .catch(err=>{
        this.loadingTable = false
      })
    },
    showNotification (item) {
      this.loadingTable = true
      axios.get(this.$store.getters.getNotification(item.id))
      .then(response => {
        this.loadingTable = false
        const not = response.data.success
        this.$store.dispatch('notificationModal',{
          title: '',
          type: not.type,
          text: not.text
        })
        this.$store.dispatch('markAsReadNotification',not).then( () => {
          this.getNotifications()
          this.$store.dispatch('getNotifications')
        })
        this.markAsReadInTable(not)
      })
      .catch(err=>{
        this.loadingTable = false
        // console.log(err)
      })
    },
    getIcon (type) {
      let icon = ''
      switch (type) {
        case 'info':
          icon = 'info-circle'
          break
        case 'success':
          icon = 'check-circle'
          break
        case 'warning':
          icon = 'exclamation-triangle'
          break
        case 'alert':
          icon = 'exclamation-circle'
          break
        case 'tip':
          icon = 'inbox'
          break
        case 'upgrade':
          icon = 'highlighter'
          break
      }
      return icon
    },
    markAsReadInTable(not){
      this.notifications.filter(n => {
        if(n.id==not.id){
          n.read = true
        }
      })
    }
  },
  watch: {
    options: {
      handler () {
        this.getNotifications()
      },
      deep: true,
    },
    lastReaded(newValue){
      this.markAsReadInTable(newValue)
    },
    newNotification(){
      this.getNotifications()
    }
  },
}
