export default {
  data(){
    return {
      initialized: false,
      nodata: true,
      totals:[],
      details_dialog: false,
      items: [],
      contracts: {},
      loading: false,
      loadingTable: false,
      inPercent: false,
      rateConverted: false,
      displaySymbol: '',
      options: {
        page: 1,
        itemsPerPage: -1
      },
      unwatch: '',
      policies:{
        get_rates: false,
        get_all: false
      },
      modeNode:process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
      searchBy:{
        by_name:'',
        sector_id: null,
        franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
        status: 1,
      },
      specific_franchise: [],
      copy_totals:''
    }
  },
  methods:{
    getTotals (frn_id = 0, frn_name = '') {
      this.totals = []
      this.specific_franchise = []
      this.loadingTable = true

      if (this.rateConverted) {
        this.rateConverted = false
        this.displaySymbol = ''
      }
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage,
          'page': this.options.page,
        }
      }
      if (frn_id != 0 && frn_name != '') {
        this.specific_franchise.id = frn_id
        this.specific_franchise.name = frn_name
        ops.params.franchise_id = frn_id
      }

      axios.get(this.$store.getters.filterContractsPerServiceAmounts, ops)
        .then(response => {
          this.nodata = false
          let totals = response.data
          this.totals = totals.sort((a, b) => (a.order > b.order) ? 1 : -1)
          this.copy_totals = JSON.stringify(this.totals)
          if (this.inPercent) {
            this.inPercent = false
            this.changeToPercentBtn()
          }
        })
        .catch(err=>{
          if (err.response.status != 404) {
            this.nodata = true
          }
        })
        .finally(()=>{
          this.loadingTable = false
          this.initial ? this.initial = false : ''
        })
    },
    dividerTitle (divider_title) {
      let val = ''
      switch (divider_title) {
        case 'divider_serv':
          val = this.$tc('by',2) +' '+ this.$tc('service.service',2)
          break
        case 'divider_pack':
          val = this.$store.getters.isFranchise ?
            this.$tc('by',2) +' '+ this.$tc('service.service',2) :
            this.$tc('by',2) +' '+ this.$tc('package.package',1)
          break
        case 'divider_franchise':
          val = this.$tc('franchise.company',1)
          break
      }
      return val
    },
    changeToPercentBtn(){
      this.inPercent = !this.inPercent
      if (this.inPercent) {
        this.totals.map(val=>{
          if (typeof(val.amount) == 'number' && val.amount > 0) {
            const total = Number(val.amount);
            let req_amount = val.in_request.amount;
            let act_amount = val.active.amount;
            var percent1 = ((req_amount/total)*100).toFixed(2);
            val.in_request.amount = Number(percent1) > 0 ? percent1+'%' : 0;
            var percent2 = ((act_amount/total)*100).toFixed(2);
            val.active.amount = Number(percent2) > 0 ? percent2+'%' : 0;
          }
        })
      } else {
        this.totals = JSON.parse(this.copy_totals);
      }
    },
    changeToRate(rate, symbol){
      this.rateConverted = !this.rateConverted
      if (this.rateConverted) {
        this.displaySymbol = symbol
        this.totals.map(val=>{
          if (typeof(val.amount) == 'number' && val.amount > 0) {
            let amount = val.amount
            let req_amount = val.in_request.amount
            let act_amount = val.active.amount
            val.amount = amount * rate
            val.in_request.amount = req_amount * rate
            val.active.amount = act_amount * rate
          }
        })
      } else {
        this.displaySymbol = ''
        this.totals = JSON.parse(this.copy_totals);
      }
    },
    PoliciesFilter () {
      this.policies.get_rates = this.getPermissionGroup({
        permission:[
          'index_exchangeRate'
        ],
        mode:'all'
      });
      this.policies.get_all = this.getPermissionGroup({
        permission:[
          'getAllPerService_contract'
        ],
        mode:'all'
      });
    },
  },
  watch:{
    options: {
      handler () {
        this.getTotals()
      },
      deep: true,
    }/*,
    inPercent: {
      handler (val) {
        // eslint-disable-next-line no-console
        console.log(val)
      },
      deep: true,
    },
    rateConverted: {
      handler (val) {
        // eslint-disable-next-line no-console
        console.log(val)
      },
      deep: true,
    }*/
  },
  computed:{
    computedTableTitle () {
      if (this.specific_franchise.name) {
        return this.specific_franchise.name
      } else {
        return this.$tc('amount',2)+' '+this.$tc('by',2)+' '+this.$tc('service.service',1)
      }
    },
    headers () {
      let headers = [
        {
          text: '',
          value: 'title',
          sortable: false ,
          align: 'left',
        },
        {
          text: this.$tc('amount',2),
          value: 'amount',
          sortable: false ,
          align: 'center',
          width: this.rateConverted ? 200 : 140
        },
        {
          text: this.$tc('in',1)+' '+this.$tc('requests.requests',1),
          value: 'in_request.amount',
          sortable: false ,
          align: 'center',
          width: this.rateConverted ? 200 : 140
        },
        {
          text: this.$tc('active',1),
          value: 'active.amount',
          sortable: false ,
          align: 'center',
          width: this.rateConverted ? 200 : 140
        }
      ]
      if (this.policies.get_all && !this.$store.getters.isFranchise) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false,
            align: 'center',
            width: 80
          }
        )
      }
      return headers
    },
    base_currency_symbol: {
      get () {
        if (this.displaySymbol != '') {
          return this.displaySymbol
        } else {
          return Object.values(this.$store.getters.getBaseCurrency)[0].symbol
        }
      },
      set (val) {
        //
      }
    },
    base_currency_decimal_places: {
      get () {
        return Object.values(this.$store.getters.getBaseCurrency)[0].decimal_places
      },
      set (val) {
        //
      }
    },
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        //
      }
    })
    this.PoliciesFilter()
  },
  beforeDestroy () {
    this.unwatch()
  },
  mounted() {
    //
  },
}
