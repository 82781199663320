var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pt-8":"","px-auto":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto pa-0",attrs:{"cols":"12","sm":"11"}},[_c('v-data-table',{staticClass:"elevation-1 rotate-header-table mb-8",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.totals,"hide-default-header":_vm.nodata,"footer-props":{
          'items-per-page-options': [10, 50, 100, 500, 1000]
        },"options":_vm.options,"loading":_vm.loadingTable,"height":"calc(100vh - 215px )","fixed-header":_vm.$vuetify.breakpoint.smAndUp},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"title":_vm.computedTableTitle,"showArrowBack":_vm.specific_franchise.name ? true : false},on:{"arrowBack":function($event){return _vm.getTotals()}}})]},proxy:true},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('b',{staticClass:"body-0 text-uppercase",class:item.title.includes('divider_') ? 'grey--text' : '',attrs:{"divider":item.title.includes('divider_') ? 'section-'+item.title : item.parent,"open-section":item.title.includes('divider_') ? false : true}},[_vm._v(" "+_vm._s(_vm.$te('table.'+item.title) ? _vm.$tc('table.'+item.title,1) : item.title.includes('divider_') ? _vm.dividerTitle(item.title) : item.title)+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(!item.title.includes('divider_'))?_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","disabled":item.total == '-' || (!_vm.policies.view_details && item.filter && !item.filter.frn)},on:{"click":function($event){item.filter && item.filter.frn ?
                  _vm.getTotals(item.filter.frn, item.title) :
                  _vm.goToDetailedView(item.filter)}}},on),[(item.filter && item.filter.frn)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-right")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1):_c('v-btn',{attrs:{"icon":"","btn-item":item.title.includes('divider_') ? item.title : 'ss'},on:{"click":function($event){return _vm.showItemTable(item.title)}}},[_c('v-icon',{attrs:{"small":"","btn-item":item.title.includes('divider_') ? item.parent: ''}},[_vm._v(" fas fa-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.view_details')))])])]}}],null,true)}),_c('v-btn',{attrs:{"fab":"","elevation":"5","fixed":"","bottom":"","right":"","small":"","color":"primary"},on:{"click":_vm.changeToPercentBtn}},[(!_vm.inPercent)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-percent")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-undo-alt")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }