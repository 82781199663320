import DialogMoneyReceived from "./DialogMoneyReceived.vue";
import DialogChangeStatus from "./DialogChangeStatus.vue";
import SecondaryFilter from "../../components/SecondaryFilter/Filter.vue";

export default {
  name: "MoneyReceived",
  components: {
    SecondaryFilter,
    DialogMoneyReceived,
    DialogChangeStatus
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$tc("subscribers.code", 2),
          align: "left",
          value: "subscriber.code",
          width: 110
        },
        {
          text: this.$tc("subscribers.dni", 2),
          align: "left",
          value: "subscriber.doc",
          width: 110
        },
        {
          text: this.$tc("subscribers.subscribers",1),
          align: "left",
          value: "subscriber.legal_denomination"
        },
        {
          text: this.$t("money_received.reception_date"),
          value: "reception_date",
          align: "center",
          width: 140
        },
        {
          text: this.$t("subscribers.updated_at"),
          value: "updated_at",
          align: "center",
          width: 110
        },
        {
          text: this.$t("Payments.payment_method"),
          value: "payment_method",
          align: "center",
          width: 140
        },
        {
          text: this.$tc("amount", 1),
          value: "amount",
          align: "right",
          width: 190
        }
      ];
      const showActions = this.getActionHeader();

      headers.push({
        text: showActions ? this.$t("actions") : this.$t("action"),
        value: "action",
        sortable: false,
        filterable: false,
        align: 'center',
        width: 150
      });

      return headers;
    },
    computedSources() {
      return this.sources.map(source => {
        return {
          value: source.value,
          text: this.$t(source.text)
        };
      });
    },
    computedPaymentMethods() {
      return this.payment_methods.map(method => {
        return {
          value: method.value,
          text: this.$t(method.text)
        };
      });
    },
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      isEdit: false,
      Permissions: {
        edit: false,
        create: false,
        delete: false,
        pending: false,
        consolidated: false,
        processed: false,
        rejected: false,
        changeStatus: false
      },
      // Paged
      options: {
        page: 1,
        sortBy: ['reception_date'],
        sortDesc: [true]
      },
      perPage: 10,
      totalValues: 0,
      // Others
      searchBy: {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0 : Number(this.$store.getters.getFranchiseID),
        status: 1,
        source: null,
        payment_method: null,
        user_id: null,
        employee_id: null,
        start_date: null,
        end_date: null
      },
      moneyReceived: [],
      unwatch: '',
      step: 'tab-1',
      modeNode: process.env.NODE_ENV,
      // Queries
      statuses: [],
      sources: [],
      payment_methods: [],
      clear_secondary_filter: false
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after confirmation
        if (val) {
          this.List();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  watch: {
    options: {
      handler() {
        this.List();
      },
      deep: true
    },
    step() {
      this.triggerFilter();
    }
  },
  methods: {
    List() {
      this.loadingTable = true;
      if (this.step == "tab-1") {
        this.searchBy.status = "pending";
      } else if (this.step == "tab-2") {
        this.searchBy.status = "consolidated";
      } else if (this.step == "tab-3") {
        this.searchBy.status = "processed";
      } else if (this.step == "tab-4") {
        this.searchBy.status = "rejected";
      }
      axios
        .get(this.$store.getters.getMoneyReceived(), {
          params: {
            page: this.options.page,
            paginate: this.options.itemsPerPage,
            orderBy: this.options.sortBy[0] || null,
            dirDesc: this.options.sortDesc[0] || null,
            name: this.searchBy.by_name,
            franchise_id: this.searchBy.franchise_id,
            status: this.searchBy.status,
            legal_denomination: this.searchBy.legal_denomination,
            doc: this.searchBy.doc,
            source: this.searchBy.source,
            payment_method: this.searchBy.payment_method,
            user_id: this.searchBy.user_id,
            employee_id: this.searchBy.employee_id,
            start_date: this.searchBy.start_date,
            end_date: this.searchBy.end_date
          }
        })
        .then(response => {
          this.moneyReceived = response.data.money_received.data;
          this.totalValues = response.data.money_received.total;
        })
        .catch(error => {
          if (error.response && this.modeNode == "development") {
            // eslint-disable-next-line no-console
            console.log(error.response);
          }
        })
        .finally(() => {
          if (this.modeNode == "development") {
            setTimeout(() => {
              this.loadingTable = false;
            }, 400);
          } else {
            this.loadingTable = false;
          }
        });
    },
    newItem() {
      this.$refs.dialog_money.openDialog(false);
    },
    editItem(item, viewOnly = false) {
      this.$refs.dialog_money.openDialog(true, item, viewOnly);
    },
    changeStatusItem(item) {
      this.$refs.dialog_change_status.openDialog(item);
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        pre: "success",
        model: this.$t("money_received.money_received"),
        title: this.$t("base.delete"),
        action: this.$tc("deleted", 1),
        content: this.$t("base.confirm_delete", {
          action: this.$t("base.delete"),
          record: this.$t("money_received.money_received")
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.getMoneyReceived(item.id),
          method: "delete"
        },
        pos: 1
      });
    },
    getActionHeader() {
      const create = this.Permissions.create;
      const edit = this.Permissions.edit;
      const remove = this.Permissions.delete;
      if (
        (create && edit & remove) ||
        (edit && remove) ||
        (create && edit) ||
        (create && remove)
      ) {
        if (this.step == "tab-1" || this.step == "tab-2") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    GetPermissions() {
      if (this.$store.getters.isAdmin || this.$store.getters.isFranchiseAdmin) {
        this.Permissions = {
          edit: true,
          create: true,
          delete: true,
          pending: true,
          consolidated: true,
          processed: true,
          rejected: true,
          changeStatus: true
        };
      } else {
        this.Permissions.create = this.getPermissionGroup({
          permission: [
            "index_moneyReceived",
            "index_employee",
            "invoiceableContracts_clientDocument",
            "store_moneyReceived"
          ],
          mode: "all"
        });
        this.Permissions.edit = this.getPermissionGroup({
          permission: [
            "index_moneyReceived",
            "index_employee",
            "invoiceableContracts_clientDocument",
            "update_moneyReceived"
          ],
          mode: "all"
        });
        this.Permissions.delete = false;
        // this.Permissions.delete = this.getPermissionGroup({
        //   permission: ["index_moneyReceived", "destroy_moneyReceived"],
        //   mode: "all"
        // });
        this.Permissions.pending = this.getPermissionGroup({
          permission: ["index_moneyReceived", "pending_moneyReceived"],
          mode: "all"
        });
        this.Permissions.processed = this.getPermissionGroup({
          permission: ["index_moneyReceived", "processed_moneyReceived"],
          mode: "all"
        });
        this.Permissions.consolidated = this.getPermissionGroup({
          permission: ["index_moneyReceived", "consolidated_moneyReceived"],
          mode: "all"
        });
        this.Permissions.rejected = this.getPermissionGroup({
          permission: ["index_moneyReceived", "rejected_moneyReceived"],
          mode: "all"
        });
        this.Permissions.changeStatus = this.getPermissionGroup({
          permission: ["index_moneyReceived", "changeStatus_moneyReceived"],
          mode: "all"
        });
      }
    },
    getLowerCase(text) {
      return text.toLowerCase();
    },
    triggerFilter () {
      if (this.options.page != 1) {
        this.options.page = 1
      } else {
        this.List()
      }
    },
    filterBy(item, type) {
      this.searchBy[type] = item
      this.triggerFilter()
    },
    eraseFilter(){
      this.searchBy = {
        franchise_id: this.$store.getters.getFranchiseID == null ? 0 : Number(this.$store.getters.getFranchiseID),
        dni: '',
        legal_denomination: '',
        source: null,
        payment_method: null,
        user_id: null,
        employee_id: null,
        start_date: null,
        end_date: null
      }
      this.clear_secondary_filter = true
      this.triggerFilter()
      // Reset the cleaner
      setTimeout(() => {
        this.clear_secondary_filter = false
      }, 140);
    },
    fillSources (val) {
      if (val.length > 0) {
        this.sources = val
      }
    },
    fillPaymentMethods (val) {
      if (val.length > 0) {
        this.payment_methods = val
      }
    },
    fillStatuses (val) {
      if (val.length > 0) {
        this.statuses = val
      }
    },
    filterByDates (start, end) {
      this.searchBy.start_date = start
      this.searchBy.end_date = end
      this.triggerFilter()
    }
  },
  mounted() {
    this.GetPermissions();
  }
}
