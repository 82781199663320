import dialservice from './DialogService.vue'
import dialparam from './DialogParameters.vue'
import dialconfig from './DialogConfigs.vue'

export default {
  name: 'servicespecs',
  components: {
    dialservice,
    dialparam,
    dialconfig
  },
  data(){
    return {
      dialog: false,
      service: null,
      servicespecs: [],
      search: '',
      perPage: 10,
      page: 1,
      totalValues: 0,
      last_page: 0,
      list: false,
      loading: false,
      unwatch: '',
      sortBy:'',
      sortDesc:false,
    }
  },
  computed:{
    orders(){
      return [
        {
          text: this.$t('code'),
          value: 'code',
        },
        {
          text: this.$t('name'),
          value: 'name',
        },
        { 
          text: this.$t('description'), 
          value: 'description'
        },
        { 
          text: this.$tc('active',0), 
          value: 'active',
        },
      ]
    }
  },
  watch:{
    perPage(){
      this.getSpecs()
    },
    page(){
      this.getSpecs()
    },
    sortDesc(){
      this.getSpecs()
    },
    sortBy(){
      this.getSpecs()
    }
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(typeof(val) == 'boolean' && val && this.service != null){
        this.getSpecs()
      }
    })
  },
  methods:{
    searchValue(value){
      this.search = value
      this.page = 1
      this.getSpecs()
    },
    open(service){
      this.service = service
      this.dialog = true
      this.getSpecs()
    },
    close(){
      this.dialog = false
      this.service = null
      this.search = ''
    },
    getSpecs(){
      this.loading = true
      this.servicespecs = []
      let param = {
        root:this.service.id,
        page:this.page,
        paginate:this.perPage
      }
      if(this.search != '' && this.search != undefined){
        param['search'] = this.search
      }
      if(this.sortBy != ''){
        param['sortBy'] = this.sortBy
        param['sortDesc'] = this.sortDesc
      }
      axios.get(this.$store.getters.getService(),{
        params:param
      })
      .then(response => {
        this.last_page = response.data.service.last_page
        this.servicespecs = response.data.service.data
        this.totalValues = response.data.service.total
      })
      .finally(() => {
        this.loading = false
      })
    },
    removed(specs){
      this.$store.dispatch('confirmationValue',{
        show: true,
        title: this.$t('base.delete'),
        model:this.$tc('service.attributes',2),
        action: this.$tc('deleted',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.delete'),
          record:specs.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getService(specs.id),
          method: 'delete'
        },
        pos: 2
      })
    },
    viewMode(val){
      this.list = val
    }
  },
  beforeDestroy () {
    this.unwatch()
  }
}
