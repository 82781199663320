// Import Dialog concept
import ConceptDialog from '../../components/Concept/ConceptDialog.vue';
export default {
  components:{
    ConceptDialog: ConceptDialog
  },
  data() {
    return {
      concept: {},
      dialog: false,
      fullscreen: false,
      loadingTable:false,
      loading: false,
      edit:false,
      start_date_modal: false,
      end_date_modal: false,
      isCountry: false,
      isState: false,
      isCity: false,
      isMunicipality: false,
      panel_add:true,
      isParish: false,
      is_filter:false,
      country: null,
      state: null,
      city: null,
      municipal: null,
      parish: null,
      sector: null,
      perPage: 10,
      totalValues:0,
      current_id: 0,
      concept_motive: '',
      options: {
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      concepts: [],
      panel: [0,1],
      concept_motives: [],
      concept_types: [],
      concept_taxes: [],
      person_types: [],
      client_types: [],
      services: [],
      items_country: [],
      items_state: [],
      items_city: [],
      items_municipal: [],
      items_parish: [],
      items_sector: [],
      searches:{},
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      user_permissions:{},
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val){
        this.getConcepts();
      }
    });
  },
  methods: {
    create(item){
      // eslint-disable-next-line no-console
      // console.log('pre-create', this.concept);
      this.concept = item;
      axios.post(this.$store.getters.getConcepts(), this.concept)
      .then(response =>{
        if(response.status == 200){
          // eslint-disable-next-line no-console
          // console.log('create', response.data.success);
          this.$store.dispatch('pushNotification', {
            snackbar:true,
            style:'success',
            timeout:6000,
            notifText: 'success',
            notifPos: 1,
            extra: {
              model: this.$tc('concept.name',1),
              action: this.$tc('created',1) 
            }
          });
          this.getConcepts();
          this.dialog = false;
          this.close();
        }
      })
      .catch((err)=>{
        if (err.response) {
          // eslint-disable-next-line no-console
          // console.log('error concepto: ', err.response);
        }
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style:'error',
          timeout:6000,
          notifText:`${this.$t('concept.error_saving')}`
        });
      }).finally(()=>{
        this.loading = false;
        this.edit = false;
      });
    },
    /**
    * Save a Concept and show a message to the user.
    *
    * @public
    */
    save(item){
      this.$validator.validateAll().then(() =>{
        if (this.errors.all()==''){
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    /**
    * Close the dialog.
    *
    * @public
    */
    close(){
      this.dialog = false;
      this.edit = false;
      this.concept = {};
      this.concept_motive = '';
      this.current_id = 0;
      this.$validator.reset();
    },
    /**
    * Fill the dialog fields and open it to edit a concept.
    *
    * @param {object} item
    * @public
    */
    newItem(){
      this.dialog = true;
      this.edit = false;
      this.current_id = 0;
      this.$validator.reset();
      this.totalValues++;
      let motive = this.motives_data[Math.floor(Math.random()*this.motives_data.length)];
      this.concept_motive = motive.name;
      this.concept = {
        name: 'Concepto '+motive.name+' '+this.totalValues,
        concept_motive: motive.name,
        concept_motive_id: motive.id,
        amount: (this.totalValues * Math.random() *(491)).toFixed(2),
        quantity:  Math.floor(Math.random() * 101),
        start_date:  new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
        end_date: '',
        type: this.concept_types[Math.floor(Math.random()*this.concept_types.length)].value,
        tax: '',
        its_percentage: !Math.round(Math.random()),
        person_type: '',
        client_type: '',
        service: '',
        sector: ''
      };
    },
    editItem(item){
      // this.concept.name = item.name;
      // this.concept.concept_motive = item.concept_motive
      // this.concept.amount = item.amount
      this.concept = item;
      this.concept_motive = item.concept_motive;
      this.current_id = item.id;
      this.edit = true;
      // eslint-disable-next-line no-console
      // console.log('concepto base', this.concept);
      // eslint-disable-next-line no-console
      // console.log('motivos',this.concept_motives);
      // this.dialog = true;
      this.$refs.concept_dialog.open({concept: item, edit:true});
    },
    /**
    * Update a Concept and show a message to the user.
    *
    * @public
    */
    update(item){
      this.concept = item;
      // eslint-disable-next-line no-console
      // console.log('update',this.concept);
      axios.put(this.$store.getters.getConcepts(this.current_id),this.concept)
      .then(response =>{
        if(response.status == 200){
          this.$store.dispatch('pushNotification', {
            snackbar:true,
            style:'success',
            timeout:6000,
            notifText: 'success',
            notifPos: 1,
            extra: {
              model: this.$tc('concept.name',1),
              action: this.$tc('updated',1) 
            }
          });
          this.edit = false;
          this.loading = false;
          this.getConcepts();
          this.close();
        }
      })
      .catch(err=>{
        this.loading = false;
        this.dialog = false;
        if (err.response.data) {
          // eslint-disable-next-line no-console
          // console.log('Error: ', err.response.data);
        }
        this.$store.dispatch('pushNotification', {
          snackbar:true,
          style:'error',
          timeout:6000,
          notifText:`${this.$t('concept.error_saving')}`
        }).finally(()=>{
          this.loading = false;
          this.edit = false;
        });
      });
    },
    /**
    * Open a confirmation modal to delete the selected concept.
    *
    * @param {object} item
    * @public
    */
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        show: true,
        pre: 'success',
        model: this.$tc('concept.name',1),
        title: this.$t('base.delete'),
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getConcepts(item.id),
          method: 'delete'
        },
        pos:1
      });
    },
    /**
    * List of concepts registered in system
    *
    * @public
    */
    getConcepts(){
      this.getMotives();
      this.loadingTable = true;
      if ((this.searches.name != '' || this.searches.concept_motive != '' || this.searches.type != '') && this.is_filter) {
        this.options.page = 1;
      }
      axios.get(this.$store.getters.getConcepts(),{
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          name:this.searches.name,
          concept_motive:this.searches.concept_motive,
          type:this.searches.type
        }
      })
      .then(response => {
        this.concepts = response.data.concepts.data;
        this.concepts.map((concept, index) => {
          concept.concept_motive = this.motives_data.find(motive => motive.id == concept.concept_motive_id).name;
        });
        // eslint-disable-next-line no-console
        // console.log('concepts', response.data.concepts);
        this.totalValues = response.data.concepts.total;
      })
      .catch((error) => {
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      }).finally(()=>{
        this.loadingTable = false;
      });
    },
    /**
    * List of concept motives registered in system
    *
    * @public
    */
    getMotives(){
      axios.get(this.$store.getters.getConceptMotives())
      .then(response => {
        this.concept_motives = response.data.motives.map(value => value.name);
        this.motives_data = response.data.motives;
      })
      .catch(err=>{
        this.concept_motives = [];
      });
    },
    /**
    * Set the correspondent concept motive if its selected
    *
    * @param {object} concept_motive
    * @public
    */
    setConceptMotive(concept_motive) {
      if (this.concept_motives.includes(concept_motive)){
        this.motives_data.forEach((motive) => {
          if (motive.name == concept_motive) {
            this.concept.concept_motive = motive.name;
          }
        });
      } else {
        this.concept.concept_motive = this.concept_motive;
      }
    },
    /**
    * List of sectors registered in system
    *
    * @public
    */
   getSectors(){
    axios.get(this.$store.getters.getSectors())
    .then(response => {
      this.sectors = response.data.sectors.map(value => value.name);
      this.sectors_data = response.data.sectors;
    })
    .catch(err=>{
      this.sectors = [];
    });
   },
   /**
    * Set the correspondent sector if its selected
    *
    * @param {object} sector
    * @public
    */
   setSectors(sector) {
    if (this.sectors.includes(sector)){
      this.sectors_data.forEach((sector) => {
        if (sector.name == sector) {
          this.sectors.sectors = sector.name;
        }
      });
    } else {
      this.sectors.sectors = this.sectors;
    }
  },
    /**
    * List of availables concept types
    *
    * @public
    */
    getQueries(){
      axios.get(this.$store.getters.getQueries)
      .then(response =>{
        let conceptTypes=[];
        response.data.invoices_elements.concept_types.forEach(element => {
          conceptTypes.push({
            value: element,
            text: 'concept.types.'+element
          });
        });
        this.concept_types = conceptTypes;
      })
      .catch(error =>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    SearchFilter(){
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.is_filter = true;
      this.getConcepts();
    },
    EraseFilter(){
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.searches={serial:'',name:'',concept_motive:'',type:''};
      this.getConcepts();
    },
    OpenDialog(){
      this.$refs.concept_dialog.open();
    },
    permission(){
      this.$store.dispatch('MasterCRUD', 'concept').then(response=>{
        this.user_permissions = response;
      });
    },
  },
  computed:{
    /**
    * Return the concept datatable header
    *
    * @public
    */
    headers(){
      let headers = [
        {
          text: this.$t('name'),
          align: 'left',
          value: 'name',
        },
        { 
          text: this.$t('concept.motive'), 
          value: 'concept_motive_id',
          align: 'center'
        },
        { 
          text: this.$t('concept.amount'), 
          value: 'amount', 
          align: 'right' 
        },
      ];
      if (this.user_permissions.update || this.user_permissions.destroy) {
        headers.push(
          {
            text: this.user_permissions.update && this.user_permissions.destroy? this.$t('actions'):this.$t('action'),
            value: 'action',
            sortable: false,
            align: 'center'
          }
        ); 
      }
      return headers;
    },
    selectedConceptTypes(){
      return this.concept_types.map(types => {
        return {
          'value': types.value,
          'text': this.$t(types.text)
        };
      });
    },
    selectMotive(){
      return this.motives_data.map(motive => {
        return {
          'value': motive.id,
          'text:':motive.name
        };
      });
    },
    computedDateFormattedStartDate () {
      return this.formatDate(this.concept.start_date);
    },
    computedDateFormattedEndDate () {
      return this.formatDate(this.concept.finish_date);
    },
  },
  watch: {
    options: {
      immediate:true,
      handler () {
        this.getConcepts();
      },
      deep: true,
    },
  },
  /**
  * Initial call of necesary methods to get the concepts data and related
  *
  * @public
  */
  mounted(){
    // this.getConcepts();
    this.getQueries();
    this.permission();
  },
  beforeDestroy () {
    this.unwatch();
  }
};
