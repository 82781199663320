import simpleservice from './ServiceInfo.vue'
import contactsandaddress from './ContactsInfo.vue'

export default {
  components:{
    simpleservice: simpleservice,
    contactsandaddress:contactsandaddress
  },
  data: () => ({
    request_info: {},
    request_status: 'info_alt',
    subscriber_info: {},
    overlay: false,
    expand_status: false,
    tab: null,
    openCard: null,
    cardContent: 'TEXTO',
    timelines: [
      {
        id: 0,
        color: '',
        status: [],
        tag: 'service_requested',
        body: ''
      },
      {
        id: 1,
        color: '',
        status: ['service_request'],
        tag: 'feasibility_report',
        body: ''
      },
      {
        id: 2,
        color: '',
        status: ['feasible_service', 'infeasible_service'],
        tag: 'service_sale',
        body: ''
      },
      {
        id: 3,
        color: '',
        status: ['awaiting_acceptance', 'generate_charge_note', 'payment_pending'],
        tag: 'billing',
        body: ''
      },
      {
        id: 4,
        color: '',
        status: ['awaiting_installation', 'installed'],
        tag: 'installation',
        body: ''
      }
    ]
  }),
  computed:{
    request_id () {
      if (this.request_info.id) {
        let val = this.request_info.id.toString().length < 5 ?
          ("00000" + this.request_info.id).slice(-5) :
          this.request_info.id
          return '#'+val
      }
    },
    subscriber () {
      return '('+this.subscriber_info.doc+') '+this.subscriber_info.legal_denomination
    },
    disks(){
      return [
        {
          color: 'grey',
          text: 'pending',
        },
        {
          color: 'success',
          text: 'completed',
        },
        {
          color: 'info_alt',
          text: 'in_progress',
        },
        {
          color: 'warning',
          text: 'warning',
        },
        {
          color: 'error',
          text: 'error',
        }
      ]
    }
  },
  methods:{
    showInfo(i){
      this.openCard = i
      this.timelines.filter((timeline) => {
        timeline.id != this.openCard ? timeline.body = '' : ''
      })
      this.timelines[this.openCard].body == '' ?
        this.timelines[this.openCard].body = this.cardContent :
        this.timelines[this.openCard].body = ''
    },
    findRequestID () {
      this.overlay = true
      axios
        .get(this.$store.getters.getRequestDetails, {
          params: {
            'token': this.$router.currentRoute.params.token
          }
        })
        .then((response) => {
          if (response.data) {
            if (response.data.request.status == 'active') {
              this.timelines.filter((timeline) => {
                timeline.color = 'success'
              })
              this.request_status = 'success'
            } else if (response.data.request.status == 'inactive' || response.data.request.status == 'infeasible_service') {
              this.timelines.filter((timeline) => {
                timeline.color = 'error'
              })
              this.request_status = 'error'
            } else {
              let current = this.timelines.filter((timeline) => {
                return timeline.status.includes(response.data.request.status)
              })
              current[0].color = 'info_alt'
              this.timelines.filter((timeline) => {
                timeline.id < current[0].id ? timeline.color = 'success':''
                timeline.id > current[0].id ? timeline.color = 'grey':''
              })
              this.request_status = 'info_alt'
            }
            this.request_info = response.data.request
            this.subscriber_info = response.data.subscriber
          }
        }).catch((error) => {
          this.$router.go(-1)
          this.$router.push({ name: 'NotFound404' })
        })
        .finally(() => {
          setTimeout(() => { this.overlay = false }, 400)
        })
    }
  },
  mounted(){
    this.findRequestID()
  }
}
