<template>
  <v-dialog
    v-model="dialog1"
    max-width="500px"
    scrollable
    @click:outside="close"
  >
    <v-card tile>
      <toolbarDialog
        :editMode="edit"
        :numNew="0"
        :title="$tc('banks.banks',1)"
      />
      <v-card-text>
        <v-container fluid style="padding-top: 0 !important">
          <ValidationObserver ref="form_bank" v-slot="{ handleSubmit }">
            <v-form
              class="banks-form"
              @submit.prevent="handleSubmit(submit)"
            >
              <v-row no-gutters class="d-flex justify-center">
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="name"
                    :name="$t('banks.name')"
                    rules="required|max:190"
                  >
                    <v-text-field
                      v-model="bank.name"
                      name="name"
                      :label="$t('banks.name')"
                      :disabled="loading"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="currency_code"
                    :name="$t('system.currency_code')"
                    rules="required"
                  >
                    <v-select
                      v-model="bank.description.currency_code"
                      :items="currencies"
                      name="currency_code"
                      item-text="name"
                      item-value="description.currency_code"
                      :disabled="loading"
                      :error-messages="errors"
                      :label="$t('system.currency_code')"
                    >
                      <template slot="selection" slot-scope="data" >
                        <v-list-item-content class="ma-0 pa-0">
                          <v-list-item-title
                            v-html="data.item.description.currency_code">
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              $t('system.'+data.item.name) +
                              ' (' + data.item.description.currency_code + ')'
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="country"
                    :name="$t('country')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="bank.description.country_id"
                      name="country"
                      :items="countries"
                      item-text="name"
                      item-value="id"
                      :disabled="loading"
                      :error-messages="errors"
                      :label="$t('country')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn v-if="!fullscreen" text cancel @click="close" :disabled="loading">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.cancel')}}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save
          :loading="loading"
          @click="save"
        >
          <template v-if="!edit">
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.save')}}</span>
            <v-icon small>fas fa-save</v-icon>
          </template>
          <template v-else>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{$t('base.edit')}}</span>
            <v-icon small>fas fa-edit</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BankDialog",
  data() {
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      fullscreen:false,
      currencies:[],
      countries:[],
      bank:{
        name:'',
        category:"bank",
        description:{},
      },
      modeNode:process.env.NODE_ENV,
    }
  },
  computed: {},
  methods:{
    open(item){
      this.edit = item.edit;
      this.currencies = item.currencies;
      this.countries = item.countries;
      if(!this.edit) {
        this.bank = {
          name:'',
          category:"bank",
          description:{},
        };
      } else {
        this.bank = {
          id: item.bank.id,
          name: item.bank.name,
          category:item.bank.category,
          description:item.bank.description,
        };
      }
      this.dialog1 = true;
    },
    close(){
      this.$refs.form_bank.reset();
      this.bank = {
        name:'',
        category:"bank",
        description:{},
      };
      this.edit = false;
      this.dialog1 = false;
    },
    save(){
      this.$refs.form_bank.validate()
      .then((success)=>{
        if (success) {
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
        }
      });
    },
    create(){
      axios.post(this.$store.getters.catalogList(), this.bank)
      .then((response)=>{
        // eslint-disable-next-line no-console
        if (response.status == 200) {
          const text = this.$tc('banks.banks',1)+' '+response.data.success.name;
          this.pushNoty(text, "created", 1);
          this.$emit('updateTable');
          this.close();
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loadingData();
      });
    },
    update(){
      let nameBank = this.bank.name;
      axios.put(this.$store.getters.catalogList(this.bank.id), this.bank)
      .then((response)=>{
        if (response.status == 200) {
          const text = this.$tc('banks.banks',1)+' '+nameBank;
          this.pushNoty(text, "updated", 1);
          this.$emit('updateTable');
          this.close();
        }
        if (response.status == 204) {
          this.close();
        }
      })
      .catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
        }
      })
      .finally(()=>{
        this.loadingData();
      });
    },
    loadingData(){
      if (this.modeNode == 'development') {
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      } else {
        this.loading = false;
      }
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  watch: {},
  mounted(){},
}
</script>
