export const es_veeValidate = {
  code: "es",
  attributes: {
    filter: "",
    base: {
      cellphone: "celular"
    },
    company: {
      company: "empresa",
      name_company: "nombre",
      identification: "ID o RIF",
      object_company: "objeto de la empresa",
      country: "país",
      state: "estado",
      name_institution_registration: "nombre de la institución de registro",
      register_number: "número de registro",
      volume: "tomo",
      folio: "folio",
      date_register: "Fecha de registro",
      legal_representative: "representante legal",
      firm: "firma manuscrita",
      fiscal_address: "Dirección fiscal",
      latitude: "latitud",
      longitude: "longitud",
      telephone: "teléfono",
      email: "correo electrónico",
      seal: "Sello",
      name: "nombre de usuario del administrador",
      dbname: "nombre de la base de datos",
      email_admin: "correo electrónico del administrador",
      password: "contraseña del administrador",
      logo: "logo",
      head_logo: "logo",
      branch_office: "sucursal"
    },
    login: {
      email: "correo",
      password: "constraseña"
    },
    register: {
      name: "nombre",
      email: "correo",
      password: "constraseña",
      password_confirmation: "" /* se deja vacio para evitar error duplicado */
    },
    recovery: {
      email: "correo"
    },
    user_data: {
      name: "nombre",
      email: "correo"
    },
    user_pass: {
      current_password: "contraseña actual",
      password: "contraseña",
      password_confirmation: "" /* it is left empty to avoid duplicate error */
    },
    system: {
      name: "nombre",
      symbol: "símbolo",
      icon: "icono",
      exchange_rate: "tasa de cambio",
      url: "URL",
      decimal_places: "lugares decimales"
    },
    subscribers: {
      client_type: "tipo abonado",
      prefix: "prefijo",
      dni: "",
      first_name: "nombre",
      last_name: "apellido",
      firm_name: "firma",
      legal_denomination: "razón social",
      gender: "género",
      birthdate: "fecha de nacimiento",
      agent_ret: "agente",
      tax_rate: "impuesto",
      observations: "observaciones",
      position: "cargo",
      code: "código"
    },
    contracts: {
      sector: "sector",
      tower: "torre",
      address: "dirección",
      ref_point: "punto ref.",
      city: "ciudad",
      municipality: "municipio",
      parish: "parroquia"
    },
    typeClient: {
      name: "nombre",
      description: "descripción"
    }
  },
  names: {
    payment_date: 'Fecha del pago',
  },
  messages: {
    dontallownegative: () => {
      return "El campo debe ser un número positivo.";
    },
    dontallowzero: () => {
      return "El campo debe ser mayor a 0.";
    },
    ratenotzero: () => {
      return `La tasa debe ser mayor que cero (0)`;
    },
    fieldnotzero: (field) => {
      return `El ${field} debe ser mayor que cero (0)`;
    },
    maxmoney: (field, args) => {
      return `El campo ${field} debe ser menor o igual a ${args[0]}`;
    },
    percentValue: field => {
      return `El campo ${field} es un porcentaje su valor debe estar entre 0 y 100`;
    },
    passConfirm: field => {
      return `El campo ${field} no coincide`;
    }
  }
};
