<template>
  <v-dialog
    v-model="dialog1"
    scrollable
    max-width="500px"
    @click:outside="close"
  >
    <v-card tile>
      <toolbarDialog
        :editMode="edit"
        :numNew="2"
        :title="$t('corporate_identity')"
        @close="close"
      />
      <v-card-text>
        <v-container fluid style="padding-top: 0 !important">
          <ValidationObserver ref="form_identity" v-slot="{ handleSubmit }">
            <v-form
              class="identity-form"
              @submit.prevent="handleSubmit(submit)"
            >
              <v-row no-gutters class="d-flex justify-center">
                <!-- Type -->
                <v-col cols="12" sm="12" v-if="!edit">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="typeCatalog"
                    :name="$t('concept.type')"
                    :rules="{required: !edit}"
                  >
                    <v-autocomplete
                      v-model="catalog.description.type"
                      name="typeCatalog"
                      :items="identityType"
                      :error-messages="errors"
                      :label="$t('concept.type')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <!-- Name -->
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="name"
                    :name="$t('banks.name')"
                    rules="required|max:190"
                  >
                    <v-text-field
                      v-model="catalog.name"
                      name="name"
                      :label="$t('banks.name')"
                      :disabled="loading"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="!fullscreen"
          text
          cancel
          @click="close"
          :disabled="loading"
        >
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
            $t("base.cancel")
          }}</span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn save :loading="loading" @click="save">
          <template v-if="!edit">
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
              $t("base.save")
            }}</span>
            <v-icon small>fas fa-save</v-icon>
          </template>
          <template v-else>
            <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-5">{{
              $t("base.edit")
            }}</span>
            <v-icon small>fas fa-edit</v-icon>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IdentityDialog",
  components: {},
  props:{
    NewTypes:{
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data() {
    return {
      dialog1: false,
      edit: false,
      expand: false,
      loading: false,
      fullscreen: false,
      catalog: {
        name: '',
        category: 'corporate_identity',
        description: {}
      },
      selectTypes:[],
    };
  },
  computed: {
    identityType() {
      return this.selectTypes.map(type => {
        return {
          'value': type.value,
          'text': this.$t(type.text)
        };
      });
    }
  },
  watch: {},
  methods: {
    open(item) {
      this.edit = item.edit;
      // eslint-disable-next-line no-console
      // console.log('selectTypes', item.selectTypes)
      this.selectTypes = item.selectTypes;
      if (!this.edit) {
        this.serviceType = {
          name: '',
          category: 'corporate_identity',
          description: {}
        };
      } else {
        this.catalog = {
          id: item.catalog.id,
          name: item.catalog.name,
          category: item.catalog.category,
          description: item.catalog.description
        };
      }
      this.dialog1 = true;
    },
    close() {
      this.$refs.form_identity.reset();
      this.catalog = {
        name: '',
        category: 'corporate_identity',
        description: {}
      };
      this.selectTypes = [];
      this.edit = false;
      this.dialog1 = false;
    },
    save() {
      this.$refs.form_identity.validate().then(success => {
        if (success) {
          this.loading = true;
          if (this.edit == false) {
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
        }
      });
    },
    create() {
      axios
        .post(this.$store.getters.catalogList(), this.catalog)
        .then(response => {
          // eslint-disable-next-line no-console
          if (response.status == 200) {
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 1,
              extra: {
                model:
                  this.$t('corporate_identity') +
                  " " +
                  response.data.success.name,
                action: this.$tc("created", 1)
              }
            });
            this.$emit("updateTable");
            this.close();
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      let nameCatalog = this.catalog.name;
      axios
        .put(this.$store.getters.catalogList(this.catalog.id), this.catalog)
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch("pushNotification", {
              snackbar: true,
              style: "success",
              timeout: 6000,
              notifText: "success",
              notifPos: 1,
              extra: {
                model: this.$t('corporate_identity') + " " + nameCatalog,
                action: this.$tc("updated", 1)
              }
            });
            this.$emit("updateTable");
            this.close();
          }
          if (response.status == 204) {
            this.close();
          }
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {}
};
</script>
