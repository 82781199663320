<template>
  <v-file-input small-chips counter show-size
    v-if="computedAccept && computedUrl"
    v-model="file_array"
    ref="loaded_file"
    :accept="accept"
    :label="computedLabel"
    :multiple="multiple"
    :prepend-icon="icon"
    @change="validateFile"
    @click:clear="dropFiles"
  >
    <template v-slot:append-outer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn small
            v-on="on"
            color="primary"
            :loading="loading"
            :disabled="file_array.length == 0"
            @click="sendFile"
          >
            <v-icon size="16">fas fa-upload</v-icon>
          </v-btn>
        </template>
        <span>{{$tc('load',1)}}</span>
      </v-tooltip>
    </template>
  </v-file-input>
</template>

<script>
  export default {
    props: {
      subscriber_id: Number,
      contract_code: {
        type:[String, Number],
        default: ''
      },
      accept: {
        type:[String],
        default: ''
      },
      label: {
        type:[String],
        default: ''
      },
      multiple: {
        type:[Boolean],
        default: false
      },
      icon: {
        type:[String],
        default: 'fas fa-paperclip'
      },
      url: {
        type:[String],
        default: ''
      },
    },
    data(){
      return {
        file_array: [],
        loading: false,
      }
    },
    mounted () {
      //
    },
    computed:{
      computedAccept: {
        get () {
          if (this.accept) {
            return this.accept.split(',')
          } else {
            return false
          }
        },
        set (val) {
          //
        }
      },
      computedLabel: {
        get () {
          if (this.label) {
            return this.label
          } else {
            return this.multiple ? this.$tc('file_library.file', 2) : this.$tc('file_library.file', 1)
          }
        },
        set (val) {
          //
        }
      },
      computedUrl: {
        get () {
          if (this.url) {
            try {
              new URL(this.url)
              return this.url
            } catch (e) {
              return false;
            }
          } else {
            return false
          }
        },
        set (val) {
          //
        }
      }
    },
    methods:{
      sendFile(){
        this.loading = true
        let form_data = new FormData()
        this.file_array.forEach((file, index) => {
          let i = Number(index) + 1
          form_data.append('file'+i, file)
        })

        axios.post(this.computedUrl, form_data, {
          headers: { 'content-type': 'multipart/form-data' }
        })
          .then(response =>{
            // if (response && response.data && response.data.success) {
              this.$emit('changeMode', 'table')
            // }
          })
          .catch((err)=>{
            // eslint-disable-next-line no-console
            console.log(err)
          }).finally(()=>{
            this.loading = false
          })
      },
      validateFile (e) {
        if (e.length > 0) {
          let pos = e.length == 1 ? 1 : 2
          e.every(file => {
            if (!this.computedAccept.includes(file.type)) {
              this.dropFiles('type', pos)
              return false
            } else if (file.size > 4194304) {
              this.dropFiles('size', pos)
              return false
            }/* else {
              const fr = new FileReader ()
              fr.readAsDataURL(file)
              fr.addEventListener('load', () => {
                this.file_array.push(fr.result)
              })
            }*/
          })
        }
      },
      dropFiles (scope = '', pos = 0) {
        if (scope) {
          if (scope == 'type') {
            this.$awn.alert(
              this.$tc('error422.file_must_type', pos, []), {durations:{warning: 6000}}
            )
          }
          if (scope == 'size') {
            this.$awn.alert(
              this.$tc('error422.file_max_4096_kb', pos, []), {durations:{warning: 6000}}
            )
          }
        }
        this.file_array = []
        this.$refs['loaded_file'].reset()
        this.$refs['loaded_file'].blur()
      }
    },
    watch: {
      //
    }
  }
</script>
