<template>
<div>
<v-row>
  <v-col cols="12" sm="4">
    <v-text-field
      v-model="search"
      name="search"
      @keyup.enter="searchValue()"
      @click:append-outer="searchValue()"
      :label="$t('base.search')"
      hide-details
      append-outer-icon='fas fa-search'
    ></v-text-field>
  </v-col>
  <v-col cols="12" sm="4">
    <v-row>
      <v-col cols="10" sm="8" class="pt-0">
        <v-select
          v-show="!noOrder && items.length > 0"
          :items="items"
          v-model="order"
          :label="$t('orderBy')"
        ></v-select>
      </v-col>
      <v-col cols="2" sm="4">
        <v-btn color="primary" text @click="orderDirection = !orderDirection">
          <v-icon>
            {{
              orderDirection ?
              "fas fa-sort-alpha-down" :
              "fas fa-sort-alpha-up"
            }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  <v-col sm="2">
    <v-select
      v-show="!noPagination"
      :items="[5,10,15,20]"
      v-model="perPage"
      :label="$t('paymentNote.rowsPerPageText')"
    ></v-select>
  </v-col>
  <v-col sm="2" align="right" v-show="!noList" class="pt-7 ">
    <v-btn icon :color="!list ? 'primary' : 'silver'" @click="viewMode(false)">
      <v-icon>fas fa-th-large</v-icon>
    </v-btn>
    <v-btn icon :color="list ? 'primary' : 'silver'" @click="viewMode(true)">
      <v-icon>fas fa-bars</v-icon>
    </v-btn>
  </v-col>
</v-row>
<v-divider></v-divider>
</div>
</template>
<script>
export default {
  props:{
    'orderItems': Array,
    'dOrder': {
      type:String,
      default:''
    },
    'pagination': Array,
    'noPagination': {
      type: Boolean,
      default: false
    },
    'noList': {
      type: Boolean,
      default: false
    },
    'noOrder': {
      type: Boolean,
      default: false
    },
    'listMode': {
      type: Boolean,
      default: false
    },
    'dPerPage': {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      list:this.listMode,
      search: '',
      perPage: this.dPerPage,
      orderDirection:false,
      order: this.dOrder,
      items: this.orderItems != undefined ? [{
        text:'',
        value:''
      }].concat(this.orderItems) : []
    }
  },
  methods:{
    viewMode(mode){
      this.list = mode
    },
    searchValue(){
      this.$emit('search',this.search)
    }
  },
  watch:{
    perPage(){
      this.$emit('perPage',this.perPage)
    },
    list(){
      this.$emit('list',this.list)
    },
    orderDirection(){
      this.$emit('orderDir',this.orderDirection)
    },
    order(){
      this.$emit('order',this.order)
    }
  }
}
</script>
