var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form_exchange_rate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"exchange-rate-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"small-label ma-0 pa-0 ml-auto",staticStyle:{"width":"110px"},attrs:{"dense":"","hide-details":"","label":_vm.$t('Taxes.default'),"ripple":false,"disabled":_vm.edit && _vm.ex_rate.default,"color":"primary"},model:{value:(_vm.is_default),callback:function ($$v) {_vm.is_default=$$v},expression:"is_default"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"vid":"date","name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('date'),"name":"date","readonly":"","persistent-hint":"","error-messages":errors,"hint":_vm.$store.getters.getDateFormat[0]},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1920-01-01","max":_vm.today},on:{"input":function($event){_vm.date_menu = false}},model:{value:(_vm.ex_rate.date),callback:function ($$v) {_vm.$set(_vm.ex_rate, "date", $$v)},expression:"ex_rate.date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('banks.name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","counter":"","maxlength":"40","error-messages":errors,"label":_vm.$t('name'),"readonly":_vm.edit},model:{value:(_vm.ex_rate.name),callback:function ($$v) {_vm.$set(_vm.ex_rate, "name", $$v)},expression:"ex_rate.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"exchange_rate","name":_vm.$t('system.exchange_rate'),"rules":"required|dontallownegative|ratenotzero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('moneyfield',{attrs:{"showToggleDecimalBtn":"","name":"exchange_rate","labelfield":"system.exchange_rate","nosymbol":"","hintfield":_vm.$store.getters.getBaseCurrency !== null
                  ? _vm.$t('system.equal_base') +
                    _vm.$t(
                      'system.' +
                        Object.keys(_vm.$store.getters.getBaseCurrency)[0]
                    )
                  : '',"scopefield":"exrate","error-messages":errors,"decimalprecision":_vm.rate_decimal_precision},on:{"setDecimalPlaces":_vm.setDecimalPlaces},model:{value:(_vm.computedRate),callback:function ($$v) {_vm.computedRate=$$v},expression:"computedRate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-4",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"hide-details":"","name":"currency_code","items":_vm.currencies,"item-text":"name","item-value":"description.currency_code","label":_vm.$t('system.currency_code'),"loading":_vm.loadingCurrencies,"disabled":_vm.loadingCurrencies},on:{"change":_vm.fillPreset2},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.description.currency_code)}})],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("system." + data.item.name) + " (" + data.item.description.currency_code + ")")+" ")])],1)]}}],null,true),model:{value:(_vm.currency_code),callback:function ($$v) {_vm.currency_code=$$v},expression:"currency_code"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"vid":"symbol","name":_vm.$t('system.symbol'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"cursor-disabled",attrs:{"name":"symbol","counter":"","maxlength":"6","error-messages":errors,"label":_vm.$t('system.symbol'),"readonly":_vm.currency_code != null,"disabled":_vm.currency_code == null},model:{value:(_vm.ex_rate.symbol),callback:function ($$v) {_vm.$set(_vm.ex_rate, "symbol", $$v)},expression:"ex_rate.symbol"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"vid":"icon","name":_vm.$t('system.icon'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"cursor-disabled",attrs:{"name":"icon","items":_vm.currencies,"item-value":"description.icon","error-messages":errors,"label":_vm.$t('system.icon'),"readonly":_vm.currency_code != null,"disabled":_vm.currency_code == null},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-avatar',{staticClass:"ma-0 pa-0",staticStyle:{"height":"16px","min-width":"16px","width":"16px"}},[(data.item.description.currency_code == 'BTC')?_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v(" fab fa-"+_vm._s(data.item.description.icon)+" ")]):_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v(" fas fa-"+_vm._s(data.item.description.icon)+" ")])],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',{staticClass:"ma-0 pa-0"},[(data.item.description.currency_code == 'BTC')?_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v(" fab fa-"+_vm._s(data.item.description.icon)+" ")]):_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v(" fas fa-"+_vm._s(data.item.description.icon)+" ")])],1)]}}],null,true),model:{value:(_vm.ex_rate.icon),callback:function ($$v) {_vm.$set(_vm.ex_rate, "icon", $$v)},expression:"ex_rate.icon"}})]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }