<template>
  <v-text-field
    type="tel"
    :value="formattedValue"
    :name="name"
    @input="changeValue"
    :readonly="readonlyfield"
    :error-messages="errorMessages"
    :label="$tc(labelfield,level)"
    :hint="hintfield"
    :append-icon="appendicon"
    :prepend-icon="nosymbol ? '' : iconfield != '' ? iconfield.includes('fa-btc') ? 'fab fa-btc' : iconfield : 'fas fa-'+Object.values(this.$store.getters.getBaseCurrency)[0].icon"
    :prepend-inner-icon="nosymbol ? '' : innericonfield != '' ? innericonfield : Object.values(this.$store.getters.getBaseCurrency)[0].icon == 'coins' ? Object.values(this.$store.getters.getBaseCurrency)[0].symbol:''"
    :disabled="disabledfield"
    :ref="reference === ''? name : reference"
    :data-vv-scope="scopefield"
    :hide-details="hidedetails"
    class="input-money"
    @keyup="formatNumber"
    @keydown="onlyNumbers"
    @focus="focusVar"
    @click="focusVar"
  >
    <template v-slot:append v-if="showToggleDecimalBtn">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="success"
            class="mt-05"
            @click="toggleDecimalPrecision('+')"
          >
            fas fa-plus-circle
          </v-icon>
        </template>
        <span>{{ $t("system.add_decimal_place") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="error"
            class="mt-05 mx-1"
            @click="toggleDecimalPrecision('-')"
          >
            fas fa-minus-circle
          </v-icon>
        </template>
        <span>{{ $t("system.remove_decimal_place") }}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>
<script>
var numeral = require('numeral')

const between = (min, n, max) => Math.max(min, Math.min(n, max))
const fixed = (precision) => between(0, precision, 20)
const toStr = (value) => value ? value.toString() : ''
const onlyNumbers = (input) => toStr(input).replace(/\D+/g, '') || '0'
const numbersToCurrency = (numbers, precision) => {
    const exp = Math.pow(10, precision)
    const float = parseFloat(numbers) / exp
    return float.toFixed(fixed(precision))
}
const addThousandSeparator = (integer, separator) => {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}
const currencyToIntegerAndDecimal = (float) => {
  return toStr(float).split('.')
}
const joinIntegerAndDecimal = (integer, decimal, separator) => {
  return decimal ? integer + separator + decimal : integer
}
const setCursor = (el, position) => {
  var setSelectionRange = function () {
    // el.setSelectionRange(position, position)
    // AJ
    el.selectionStart = el.selectionEnd = el.value.length;
  }
  if (el === document.activeElement) {
    setSelectionRange()
    setTimeout(setSelectionRange, 1) // Android Fix
  }
}

const event = (name) => {
  var evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  return evt
}

const format = (input, opt) => {
  if (typeof(input) === 'number') {
    input = input.toFixed(fixed(opt.precision))
  }
  var negative = input.indexOf('-') >= 0 ? '-' : ''
  var numbers = onlyNumbers(input)
  var currency = numbersToCurrency(numbers, opt.precision)
  var parts = toStr(currency).split('.')
  var integer = parts[0]
  var decimal = parts[1]
  integer = addThousandSeparator(integer, opt.thousands)
  let union = joinIntegerAndDecimal(integer, decimal, opt.decimal)

  return opt.prefix + negative + union + opt.suffix
}

const unformat = (value, precision) => {
  if(typeof(value) === 'number'){
    value = Number(value).toFixed(precision)
  }
  const negative = value.indexOf('-') >= 0 ? -1 : 1
  const numbers = onlyNumbers(value)
  const currency = numbersToCurrency(numbers, precision)
  return parseFloat(currency) * negative
}

// Custom rule for validate numbers
import { Validator } from 'vee-validate'

Validator.extend('dontallownegative', {
  validate: value => unformat(Number(value).toFixed(2),2) >= 0,
});
Validator.extend('dontallowzero', {
  validate: value => unformat(value,2) > 0,
});
Validator.extend('maxmoney', {
  validate: (value,max) => unformat(Number(max[0]).toFixed(2),2) >= unformat(value,2)
});
Validator.extend('ratenotzero', {
  validate: value => parseFloat(value) > 0,
});
Validator.extend('fieldnotzero', {
  validate: value => parseFloat(value) > 0,
});
Validator.extend('percentValue', {
  validate: value => unformat(value,2) > 0 && unformat(value,2) <= 100,
});
Validator.extend('passConfirm', {
  validate: (value, pass) => {
    return value == pass[0]
  },
});

export default {
  props:{
    readonlyfield:{
      default:false,
      type:Boolean
    },
    disabledfield:{
      default:false,
      type:Boolean
    },
    hidedetails:{
      default:false,
      type:Boolean
    },
    nosymbol:{
      default:false,
      type:Boolean
    },
    hintfield:{
      type:String,
      default:''
    },
    name:{
      type:String,
      default:''
    },
    reference:{
      type:String,
      default:''
    },
    labelfield:{
      type:String,
      default:''
    },
    level:{
      type:[Number,String],
      default:1
    },
    value:{
      type:[Number,String],
      default:0
    },
    appendicon:{
      type:String,
      default:''
    },
    iconfield:{
      type:String,
      default:''
    },
    innericonfield:{
      type:String,
      default:''
    },
    scopefield:{
      type:String,
      default:''
    },
    validation:{
      type:[Object,String],
      default:null
    },
    allownegative:{
      default:false,
      type:Boolean
    },
    allowzero:{
      default:false,
      type:Boolean
    },
    decimalprecision:{
      type:[Number],
      default:2
    },
    errorMessages: {
      default:''
    },
    showToggleDecimalBtn: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      validationDefault:{
        'dontallownegative':!this.allownegative,
        'dontallowzero':!this.allownegative
      },
      formattedValue:''
    }
  },
  methods:{
    restrictInputs (event) {
      var key = event.which;
      if(!(key==8 && this.formattedValue != format(0,this.money)) && !((key==189 && this.formattedValue.indexOf('-') == -1) && (key==189 && this.formattedValue != format(0,this.money)))
        && key!=9 && key!=0 && key!=110 && key!=96 && key!=105 && key!=104 && key!=103 && key!=102 && key!=101 && key!=100 && key!=99 && key!=98 && key!=97 && key!=48 && key!=49 && key!=50 && key!=51 && key!=52 && key!=53 && key!=54 && key!=55 && key!=56 && key!=57
        && ([109, 173, 189].includes(key))){
        event.preventDefault();
      }
    },
    changeValue(val){
      let ref = this.reference === '' ? this.name : this.reference
      var el = this.$refs[ref].$refs['input']
      var opt = this.money
      this.$emit('input',unformat(val,this.money.precision))
      this.$nextTick(() => {
        setCursor(el,el.value.length - this.money.suffix.length)
      })
    },
    focusVar(){
      let ref = this.reference === '' ? this.name : this.reference
      var el = this.$refs[ref].$refs['input']
      setCursor(el,el.value.length - this.money.suffix.length)
    },
    noNegative (e) {
      if (this.allownegative === false && ([109, 173, 189].includes(e.keyCode))) {
        e.preventDefault()
      }
    },
    onlyNumbers(e){
      // 'left arrow', 'up arrow', 'right arrow', 'down arrow',
      const arrowsKeyCodes = [37, 38, 39, 40];
      // 'delete', 'Tab','supr', 'enter', 'end', 'home'
      const SpecialKeyCodes = [8, 9, 46, 13, 35, 36];
      const NumberCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const keyBoardCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
      var keyCode = e.keyCode ? e.keyCode : e.which;
      // var key = e.key;
      // var bad = /^[0-9]*$/i;
      // eslint-disable-next-line no-console
      let arr =[...arrowsKeyCodes,...SpecialKeyCodes,...NumberCodes,...keyBoardCodes];
      if (arr.indexOf(keyCode) < 0) {
        e.preventDefault();
      }
    },
    formatNumber(e){
      var input = e.target.value.replace(/[!#$@^%&*()_+|~=`:"/'?]/g,'');
      e.target.value = input;
    },
    toggleDecimalPrecision(operator) {
      switch (operator) {
        case "+":
          this.money.precision = Number(this.money.precision) + Number(1);
          break;
        case "-":
          if (Number(this.money.precision) - Number(1) <= 2) {
            this.money.precision = 2;
          } else {
            this.money.precision = Number(this.money.precision) - Number(1);
          }
          break;
      }
      this.formattedValue = format(this.value, this.money)
      this.$emit('setDecimalPlaces', this.money.precision)
    }
  },
  computed:{
    msgError(){
      let err = this.errors.items.find(m => m.field == this.name)
      return err != undefined ? err.msg : ''
    },
    money(){
      return {
        decimal: numeral.locales[this.$i18n.locale].delimiters.decimal,
        thousands: numeral.locales[this.$i18n.locale].delimiters.thousands,
        prefix: '',
        suffix: '',
        precision: this.decimalprecision,
        masked: true /* doesn't work with directive */
      }
    },
    valueRules(){
      return [
        v => {
          if(!this.allownegative)
            return v > /0[.,]/ || this.$t('validation.positive_number')
          return true
        },
        v =>{
          if(!this.allowzero)
            return unformat(v,this.money.precision) > 0 || this.$t('validation.zero')
          return true
        }
      ];
    },
  },
  watch: {
    value:{
      immediate:true,
      handler(newValue,oldValue){
        var formatted = format(newValue,this.money)
        if(formatted !== this.formattedValue){
          this.formattedValue = formatted
        }
      }
    },
    decimalprecision:{
      immediate:true,
      handler(){
        this.formattedValue = format(this.value,this.money)
      }
    }
  }
}
</script>
