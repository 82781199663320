var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"620","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.service.service_root_id == null ? _vm.$t(_vm.service.id != "" ? "service.edit" : "service.new") : _vm.$t( _vm.service.id != "" ? "service.edit_attribute" : "service.new_attribute" ))+" ")])]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-container',{staticStyle:{"padding-top":"0 !important"},attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"form_service",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"service-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',{staticClass:"d-flex justify-center"},[(_vm.service.id != '')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":_vm.service.id != '' && !_vm.service.service_root_id ? 2 : 12}},[_c('v-switch',{staticClass:"small-label",attrs:{"dense":"","label":_vm.$tc('active', 0),"disabled":!_vm.service.configurations,"color":"primary"},model:{value:(_vm.service.active),callback:function ($$v) {_vm.$set(_vm.service, "active", $$v)},expression:"service.active"}})],1):_vm._e(),(!_vm.service.service_root_id)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"10"}},[_c('ValidationProvider',{attrs:{"vid":"service_type","name":_vm.$t('service.service_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.service_types,"item-text":"name","item-value":"id","name":"role","prepend-icon":"fas fa-layer-group","label":_vm.$t('service.service_type'),"loading":_vm.loading_types,"error-messages":errors},model:{value:(_vm.service.service_type),callback:function ($$v) {_vm.$set(_vm.service, "service_type", $$v)},expression:"service.service_type"}})]}}],null,true)})],1):_vm._e(),(!_vm.service.configurations && _vm.service.id != '')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"body-2",attrs:{"dense":"","outlined":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("service.no_configs"))+" ")])],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"vid":"code","name":_vm.$t('code'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"code","error-messages":errors,"label":_vm.$t('code')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},model:{value:(_vm.service.code),callback:function ($$v) {_vm.$set(_vm.service, "code", $$v)},expression:"service.code"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","error-messages":errors,"label":_vm.$t('name')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"pt-1",attrs:{"hide-details":"","name":"description","rows":"3","label":_vm.$t('description')},model:{value:(_vm.service.description),callback:function ($$v) {_vm.$set(_vm.service, "description", $$v)},expression:"service.description"}})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"info","text":""},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t("base.save"))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }