export const error422_en = {
  // Notifications
  user_id_must_exist: 'User doesn\'t exist',
  policies_must_exist: 'Policies must exist',
  // General
  email_must_exist: 'The email address is not registered',
  // System
  category_already_registered: 'Base currency has already been set',
  category_name_combination_already_registered: 'The name already exists within the category',
  name_max_40: 'The name may not be greater than 40 characters',
  rate_must_decimal: 'The rate must be a decimal number',
  rate_greater_than_0: 'The rate must be greater than zero',
  rate_required: 'The rate must be greater than zero',
  symbol_max_6: 'The symbol must not exceed 6 characters',
  category_name_combination_required: 'The name field is required.',
  // User
  name_required: 'The name field is required',
  name_4_14: 'The name must be between 4 and 14 characters',
  email_required: 'The email field is required',
  email_must_valid: 'The email must be a valid email address',
  email_max_140: 'The email may not be greater than 140 characters',
  email_already_registered: 'Email already registered',
  password_required: 'The password field is required',
  password_min_8: 'The password must be at least 8 characters',
  password_without_spaces: 'The password can\'t contain spaces',
  confirmation_must_match: 'The password confirmation does not match',
  current_password_mismatch: 'The current password does not match',
  // Subscriber
  prefix_required: 'Choose a prefix',
  dni_required: 'The DNI is required',
  dni_digits_3_9: 'The DNI must have between 3 and 9',
  dni_digits_6_9: 'The DNI must have between 6 and 9',
  doc_already_registered: 'The DNI is already registered.',
  legal_denomination_required: 'The legal denomination is required',
  legal_denomination_4_140: 'The legal denomination must be between 4 and 140 characters',
  legal_denomination_invalid_format: 'The legal denomination can contain letters only',
  legal_denomination_must_json: 'Invalid format for legal denomination',
  birthdate_invalid_date: 'The birthdate must be a valid date',
  birthdate_required_person_type_natural: 'The birthdate is required for Natural Person',
  gender_required_person_type_natural: 'The gender is required for Natural Person',
  tax_rate_required_agent_ret_1: 'The tax rate is required for Legal Entity',
  is_firm_required_person_type_natural: 'Indicate if Sole Proprietorships',
  tax_rate_invalid_format: 'Invalid format for the tax rate',
  tax_rate_greater_than_0: 'The tax rate must be greater than zero',
  avatar_must_valid_image: '{0} must be JPG, JPEG or PNG',
  avatar_max_2048_kb: '{0} can\'t be larger than 2MB',
  type_client_id_required: 'The Subscriber type is required',
  type_client_id_must_number: 'The Subscriber type is required',
  agent_ret_must_boolean: 'Invalid Ret. Agent',
  contact_medium_required: 'The contact medium is required',
  observations_must_string: 'The observations must be a string',
  subscriber_already_active: 'The Subscriber is already active',
  no_valid_contracts: 'The Subscriber\'s condition has n\'t changed, because he has service requests in progress',
  old_subscriber_num_already_registered: 'The Subscriber\'s code already exists',
  code_required_migrating_true: 'The Subscriber\'s code is required',
  // Library
  contract_id_must_integer: 'Invalid contract ID',
  subscriber_id_must_exist: 'Invalid subscriber ID',
  subscriber_id_required: 'The subscriber ID is required',
  file_name_required: 'The file name is required',
  file_path_required: 'The file is required',
  file_path_must_type: 'The file must be JPG, JPEG, PNG or PDF',
  failed_to_upload: 'File upload failed',
  file_path_max_4096_kb: 'The file can\'t be larger than 4MB',
  file_must_type: 'The selected file type is invalid|The type of one of the selected files is invalid',
  file_max_4096_kb: 'The selected file is too large (Max: 4MB)|Some of the selected files is too large (Max: 4MB)',
  // Groups
  name_already_registered: 'The name already exists',
  // Contracts
  contract_id_required: 'The Contract ID hasn\'t been specified',
  contract_id_must_exist: 'The Contract ID doesn\'t exist',
  type_required: 'The Type hasn\'t been specified',
  type_is_invalid: 'The Type is invalid',
  type_tech_already_registered: 'Tech {1} already registered',
  type_admin_already_registered: 'Admin {1} already registered',
  type_legal_already_registered: 'Legal {1} already registered',
  parish_id_required: 'The parish is required',
  parish_id_must_number: 'The parish code is invalid',
  parish_id_must_exist: 'The parish doesn\'t exist',
  parish_name_must_string: 'The parish name must be a string',
  parish_name_min_3: 'The parish name must be at least 3 characters',
  sector_id_required: 'The sector is required',
  sector_id_must_number: 'The sector code is invalid',
  sector_id_must_exist: 'The sector doesn\'t exist',
  tower_max_190: 'The tower must not have more than 190 characters',
  tower_must_string: 'The tower must be a string',
  address_required: 'The address is required',
  fiscal_address_max_190: 'The fiscal address must not have more than 190 characters',
  ref_point_max_190: 'The reference point must not have more than 190 characters',
  ref_point_must_string: 'The ref. point must a string',
  lat_required: 'The latitude is required',
  lat_required_lon_present: 'The latitude is required along with the longitude',
  lat_invalid_format: 'Invalid format for latitude',
  lon_required: 'The longitude is required',
  lon_required_lat_present: 'The longitude is required along with the latitude',
  lon_invalid_format: 'Invalid format for longitude',
  first_name_required: 'The name is required',
  first_name_3_40: 'The name must be between 3 and 40 characters',
  first_name_invalid_format: 'Invalid format for name',
  last_name_required: 'The lastname is required',
  last_name_3_40: 'The lastname must be between 3 and 40 characters',
  last_name_invalid_format: 'Invalid format for lastname',
  doc_must_10: 'The ID must have 10 characters',
  position_required: 'The position is required',
  cellphone_required: 'The cellphone is required',
  cellphone_already_registered: 'The cellphone is already registered',
  cellphone_must_11_digits: 'The cellphone must have 11 digits',
  local_phone_must_11_digits: 'The phone must have 11 digits',
  phone_min_11: 'The phone must have 11 digits',
  price_required: 'The price is required',
  employee_id_required_type_tech: 'The employee is required',
  employee_id_must_number: 'The selected employee is invalid',
  employee_id_must_exist: 'The employee doesn\'t exist',
  // Actor
  dni_already_registered: 'Dni already registered',
  personal_mail_already_registered: 'Personal mail already registered',
  // Type Client
  has_franchise_active: 'Has active Business Unit',
  description_required: 'The description is required',
  id_cannot_exist: 'There are {1} associated with this registry|There are {1} associated with this registry',
  // Services
  status_is_invalid: 'Invalid status',
  price_must_decimal: 'The price must be a decimal number',
  price_greater_than_0: 'The price must be greater than zero',
  qty_invalid_format: 'Invalid format for quantity',
  qty_required: 'The quantity is required',
  code_already_registered: 'Service code already registered',
  package_with_contract: 'The package can only be deactivated',
  service_assigned_to_subscriber: 'Service assigned to Subscriber',
  measure_required: 'The Measure unit field is required',
  service_has_specifications: 'The Service has Specifications',
  // Acceptance Letter
  letter_does_not_exist: 'Acceptance letter does not exists',
  // Company
  name_company_already_registered: 'Company name already registered',
  logo_required: 'The logo is required',
  logo_must_valid_image: 'The logo must be JPG, JPEG or PNG',
  firm_must_valid_image: 'The firm must be JPG, JPEG or PNG',
  seal_must_valid_image: 'The seal must be JPG, JPEG or PNG',
  name_company_required: ' The company name is required',
  object_company_max_190: 'The object field must not be more than 190 characters',
  identification_required: 'The DNI is required',
  country_must_number: 'The country code is invalid',
  state_must_number: 'The state code is invalid',
  name_institution_registration_required: 'The registration institution\'s name is required',
  name_institution_registration_max_190: 'The registration institution\'s name  field must not be more than 190 characters',
  register_number_required: 'The register number is required',
  date_register_required: 'The register date is required',
  legal_representative_required: 'The legal representative\'s name is required',
  fiscal_address_required: 'The legal address is required',
  latitude_required_longitude_present: 'The latitude is required along with the longitude',
  latitude_invalid_format: 'Invalid format for latitudet',
  longitude_required_latitude_present: 'The longitude is required along with the latitude',
  longitude_invalid_format: 'Invalid format for longitude',
  telephone_required: 'The telephone is required',
  telephone_must_11_digits: 'The telephone must have 11 digits',
  name_4_20: 'The name must be between 4 and 20 characters',
  email_admin_required: 'The Administrator\'s email is required',
  email_admin_must_valid: 'The Administrator\'s email must be a valid email address',
  email_admin_already_registered: 'The Administrator\'s email is already registered',
  // Franchise
  name_franchise_required: 'The Business Unit\'s name is required',
  branch_office_must_string: 'The branch office must be a string',
  // Concept
  start_date_invalid_date: 'You must check the Start Date',
  end_date_invalid_date: 'You must check the End Date',
  amount_must_number: 'The concept amount must be numeric',
  quantity_must_number: 'The concept quantity must be numeric',
  is_percentage_boolean: 'The value of "is percentage" isn\'t correct',
  concept_motive_id_required: 'You must instroduce a concept motive',
  concept_does_not_exist: 'The concept does not exist',
  concept_used: 'The concept is being used',
  concept_amount_invalid: 'Check that concepts amount are valid',
  concept_motive_invalid: 'Concept Motive invalid',
  document_adjustment_motives_not_found: 'No concept adjustment reasons found',
  // Policies
  name_min_3: 'The name must be at least 3 characters',
  name_invalid_format: 'Invalid format for name',
  // Document Control
  initial_document_number_greater_than_0: 'Initial document number must be greater than zero',
  initial_control_number_greater_than_0: 'Initial control number must be greater than zero',
  final_document_number_greater_than_0: 'Final document number must be greater than initial document number',
  final_control_number_greater_than_0: 'Final control number must be greater than initial control number',
  final_must_greater_initial: 'Final document and control numbers must be greater than initial numbers',
  set_numbers_voided: 'Part of set of numbers to register are voided',
  invalid_document_number_set: 'The start or end numbers were used on another invoice or receipt, change them and try again',
  document_client_not_editable: 'The document can not be edited',
  document_has_childs: 'Can\'t be voided because other documents associated',
  document_is_not_acceptable: 'Contract is not in right stage to accept this document',
  document_control_already_exists: 'The control register to this type of document already exists',
  serial_document_must_string: 'The serial document must be a string',
  initial_document_number_must_number: 'The initial document number must be a number',
  final_document_number_must_number: 'The final document number must be a number',
  serial_control_must_string: 'The serial control must be string',
  initial_control_number_must_number: 'The initial control number must be a number',
  final_control_number_must_number: 'The final control number must be a number',
  payment_note_already_paid: 'Payment note has already been paid',
  client_document_already_exist: 'Client document already exist',
  payment_note_already_exist: 'Payment note already exist',
  client_document_control_voided: 'Document control number was voided',
  numbers_already_voided: 'Some of numbers already voided',
  no_numbers_void: 'No control numbers to void. Check that control numbers exist and aren\'t used first',
  control_number_not_found: 'Control number not found',
  need_more_specific_values: 'The given range encompasses various Document Control records. Limit it to just one.',
  // Material and Equipment
  no_equipmentmaterials_found: 'No materials or equipment found',
  no_change_found: 'No changes to save',
  equipmentmaterials_does_not_exist: 'This material or equipment does not exist',
  serial_required_type_equipment: 'Equipment serial is required',
  // Taxes
  category_required: 'You need to select a category',
  // Client Document
  employee_contract_not_found: 'This contract must be assigned to an employee first',
  control_number_finished: 'Assigned control numbers have ended',
  forbidden: 'You don\'t have permissions',
  price_quotation_not_found: 'No quote found in accepted status',
  register_does_not_exist: 'Register does not exist',
  client_document_types_not_found: 'Client document types not found',
  client_document_status_not_found: 'Client document status not found',
  concept_types_not_found: 'Concept types not found',
  client_document_names_not_found: 'Client document names are not found',
  base_currency_not_found: 'Base currency not found',
  tax_not_found: 'Tax are not registered',
  constant_ut_not_found: 'Constant UT not found',
  constant_islr_not_found: 'Constant ISLR not found',
  bank_not_found: 'Banks are not registered',
  exchange_rate_not_found: 'Exchange rate not found',
  document_control_number_not_found: 'Document control number not found',
  type_not_found: 'Document type not found',
  only_price_quotation: 'You can only work with Price Quotations to this contract at the moment',
  contract_status_invalid: 'Contract status aren\'t valid to that document',
  contract_not_found: 'Contract not found',
  services_not_found: 'Services not found',
  concepts_not_found: 'Concepts not found',
  client_document_not_found: 'Client document not found',
  voided_number_fail: 'Fail to void the document, please try again',
  actor_not_found: 'User not found',
  currency_not_found: 'Currency not found',
  document_type_invalid: 'Document type invalid',
  contract_status_is_invalid: 'Contract status is invalid',
  contract_status_required: 'Contract status is required',
  document_status_is_invalid: 'Document status is invalid',
  document_status_required: 'Document status is required',
  document_type_is_invalid: 'Document type is invalid',
  document_type_required: 'Document type is required',
  was_send_must_boolean: 'Was send must be boolean',
  client_document_invalid: 'The client document selected isn\'t valid',
  description_must_json: 'Invalid value',
  // Feasibility Report
  version_required: 'The version is required',
  elaboration_by_required: 'Elaboration by is required',
  elaboration_date_required: 'The elaboration date is required',
  inspection_staff_required: 'Inspection staff is required',
  inspection_date_required: 'The inspection date is required',
  objective_required: 'The objective is required',
  observation_required: 'The observation is required',
  image_must_valid_image: 'The image must be JPG, JPEG or PNG',
  image_max_2048_kb: 'The image can\'t be larger than 2MB',
  // Employee
  employee_types_not_found: 'Employee types not found',
  employee_type_already_registered: 'There is already a registered Collection Analyst',
  franchise_not_found: 'Business Unit not found',
  employee_not_found: 'Employee not found',
  some_contracts_unassigned: 'Some contracts are unassigned',
  some_contracts_not_found: 'Some contracts wasn\'t found',
  employee_id_required: 'You must select the collector',
  employee_contracts_not_found: 'Employee contracts not found',
  multiple_employee_contracts_found: 'Multiple employee contracts assigned to same employee type',
  employee_contract_address_not_found: 'Employee contract address not found',
  // ProofPayment
  months_advance_greater_than_0: 'Payment to advance must be greater than 0',
  exceed_max_payment_advanced: 'Months to pay exceed the max payment allowed',
  amount_paid_invalid: 'The amount is invalid',
  amounts_different: 'Total payment amount is invalid',
  exchange_rate_required: 'Exchange rate is required',
  version_max_190: 'The version field must not be more than 190 characters',
  elaboration_by_max_190:`The field 'elaborated by' must not have more than 190 characters`,
  inspection_staff_max_190: 'The Inspection Personnel field must be no more than 190 characters',
  invalid_billing_type: 'Invalid billing type',
  tax_bill_rate_required: 'The TaxBill Rate is required',
  // Roles
  description_max_190: 'The description field must be no more than 190 characters',
  policies_required: 'A role must have at least one policie',
  // Sectors
  sector_name_already_registered: 'There is already a sector with the same name in the same parish',
  sector_has_addresses: 'The sector is associated with a subscriber',
  sector_name_max_190: 'A sector must not have a name with more than 190 characters',
  sector_has_zones: 'The sector is associated with a zone',
  // Zones
  zone_name_max_190: 'A zone must not have a name with more than 190 characters',
  // Old Billing Bulk Load
  error_on_row: 'Error on row {0}',
  invoice_number_required: 'The Invoice Number is required',
  invoice_number_already_registered: 'Invoice Number already registered',
  invoice_date_required: 'The Invoice Date is required',
  invoice_date_invalid_date: 'The Invoice Date is invalid',
  invoice_amount_required: 'The Invoice Amount is required',
  invoice_amount_must_number: 'The Invoice Amount is invalid',
  invoice_amount_greater_than_0: 'The Invoice Amount must be greater than zero',
  invoice_description_required: 'The Description is required',
  // Banks (Catalogs)
  bank_has_accounts: 'The Bank has associated accounts',
  service_type_has_services: 'The Service Type has associated Services',
  // Money Received
  reception_date_required: 'The date of receipt of the money is required',
  status_required: 'The status of money received is required',
  source_required: 'The means of reception is mandatory',
  currency_code_required: 'You must select a currency',
  user_id_required: 'You must select a subscriber',
}
