//
import HeaderDoc from './headerDoc.vue';
import Payments  from './payments.vue';
import Replicas from './replicas.vue';
import SendInvoice from './sendInvoice.vue';

const round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
};
const minValue = (value, min, decimals)=>{
  if(Math.abs(value) < min){
    return value.toFixed(decimals);
  } else {
    return value;
  }
};

const ReturnObject = (value)=>{
  if(typeof(value)=='object'){
    return value;
  } else if(typeof(value)=='string' && value != 'null'){
    return JSON.parse(value);
  } else {
    return null;
  }
};

export default {
  name: "PreView",
  components:{
    HeaderDoc,
    Payments,
    Replicas,
    SendInvoice,
  },
  data (){
    return {
      dialog1: false,
      dialog2: false,
      fullscreen: false,
      loading: false,
      loadinfFile: false,
      loadinfFile2: false,
      loadingTable: false,
      loadingSendable: false,
      loadingSendable2: false,
      editPay: false,
      its_preview: null,
      ist_total: false,
      show_send: false,
      show_send_invoice: false,
      there_are_payments: false,
      itemCash:0,
      itemCard:0,
      itemtransfer:0,
      ItemPago:[],
      concepts:[],
      ItemsConcept:[],
      totalRecibe:0,
      totalRest:0,
      totalPay:0,
      employee_id:'',
      contracts:[],
      ItemsPayments:[],
      BillsDocument:[],
      Banks:[],
      subscriber:{},
      baseCurrency:{},
      exchange_rate:{},
      file:{},
      file2:{},
      howMany:false,
      resto:0,
      tax_rate:0,
      tax_sale:0,
      Amount:0,
      number_days:0,
      panel: [0,0],
      UT:'',
      IVA:'',
      ISRL:'',
      IGTF:'',
      symbol:'',
      decimal_places3: 2,
      rate:1,
      editedIndex:'',
      min_day1:'',
      min_day2:'',
      max_day1:'',
      max_day2:'',
      num_month_1:[],
      num_month_2:[],
      ExchangeRates1:[],
      NewExchangeRateDoc:{},
      additionalLines: {},
      taxBillRateDoc: {},
      AllTotals:{},
      modeNode:process.env.NODE_ENV,
      taxBillRate3: {},
      unwatch: ''
    };
  },
  created () {
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if(val && this.$store.getters.getConfirmationTemplate == 'paymentPreview'){
        this.close()
      }
    })
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('paymentNote.quantity'),
          value:'quantity',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paymentNote.description'),
          value:'name',
          sortable: false,
        },
        {
          text: this.$t('paymentNote.priceUnit'),
          value:'price',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$t('paymentNote.priceTotal'),
          value:'total',
          align: 'right',
          sortable: false,
        },
      ];
    },
    computeBillsName(){
      return this.$t('document_control.types.'+ this.BillsDocument.type);
    },
    computeBillStatus(){
      return this.$t('document_control.status.'+ this.BillsDocument.status);
    },
    computeBtnPayment(){
      if (this.BillsDocument.type == 'payment_note' &&
          !this.its_preview && this.howMany && !this.there_are_payments &&
          this.formatNumber(this.totalRest) < this.formatNumber(this.totalPay) &&
          this.formatNumber(this.totalRest) >= -0.01) {
        return true;
      } else {
        return false;
      }
    },
    computeBtnvoided(){
      if (this.its_preview) {
        const canVoid = this.getPermissionGroup({
          permission:['store_voidedNumber'],
          mode:'all'
        })
        if (
          canVoid && (
          // (this.BillsDocument.status == 'accepted' && this.BillsDocument.type == 'price_quotation') ||
          (this.BillsDocument.status == 'pending' && this.BillsDocument.type == 'payment_note') ||
          (this.BillsDocument.status == 'paid' && this.BillsDocument.type == 'payment_note') ||
          (this.BillsDocument.status == 'pending' && this.BillsDocument.type == 'credit_note') ||
          (this.BillsDocument.status != 'voided' && this.BillsDocument.type == 'invoice') &&
          (this.BillsDocument.status != 'replicated'))
        ) {
          return true;
        } else {
          return false;
        }
      } else{
        return false;
      }
    },
    computeSendDocument(){
      let amount = Number(this.BillsDocument.amount);
      if (this.loadinfFile) {
        if (amount) {
          if (
            (this.BillsDocument.type =='price_quotation' && this.BillsDocument.status=='pending') ||
            (this.BillsDocument.type =='payment_note' && this.BillsDocument.status=='pending') ||
            (this.BillsDocument.type =='credit_note'  && this.BillsDocument.status=='pending')
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods:{
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    formatNumber(number){
      let num = Number(number);
      let decimal = typeof(this.decimal_places3) == 'number' ? this.decimal_places3 : 2;
      return Number(num.toFixed(decimal));
    },
    open(item,its_preview){
      this.loadinfFile = false;
      this.loadinfFile2 = false;
      this.BillsDocument = {id:''};
      this.$store.dispatch('DocQuerys')
      .then(response =>{
        const client_document = Object.assign({},item.client_document);
        let show_igtf = false
        if (!its_preview) {
          show_igtf = true
        } else if (
          Object.prototype.hasOwnProperty.call(client_document, 'extra') &&
          Object.prototype.hasOwnProperty.call(client_document.extra, 'igtf_amount')
        ) {
          show_igtf = true
        }
        // eslint-disable-next-line no-console
        // console.log('its_preview', its_preview);
        this.IGTF = show_igtf ? response.IGTF : null;
        this.ISRL = response.ISRL;
        this.UT = response.UT;
        this.IVA = response.IVA;
        this.baseCurrency = ReturnObject(response.baseCurrency);
        this.ExchangeRates1 = response.ExchangeRates;
        // eslint-disable-next-line no-console
        // console.log(client_document);
        // Exchage Rate
        let exchange_rate = ReturnObject(client_document.exchange_rate);
        // eslint-disable-next-line no-console
        // console.log('exchange', exchange_rate);
        let currency_code= '';
        if (exchange_rate != 'null' && exchange_rate != null) {
          if (exchange_rate.rate !=1) {
            exchange_rate.id = exchange_rate.id+'xx';
            exchange_rate.name = exchange_rate.name +' '+this.$moment(exchange_rate.updated_at).format(this.$store.getters.getDateFormat[0]);
            this.ExchangeRates1.push(exchange_rate);
          }
          this.exchange_rate = exchange_rate;
          currency_code = this.exchange_rate.currency_code;
          this.rate = Number(this.exchange_rate.rate);
          this.symbol = this.exchange_rate.symbol;
          if (this.exchange_rate.decimal_places  != null && this.exchange_rate.decimal_places  != undefined) {
              this.decimal_places3 = Number(this.exchange_rate.decimal_places);
          } else {
            this.decimal_places3 = 2;
          }
        } else {
          this.exchange_rate = this.baseCurrency;
          currency_code = this.baseCurrency.currency_code;
          this.rate = Number(this.baseCurrency.rate);
          this.symbol = this.baseCurrency.symbol;
          this.decimal_places3 = this.baseCurrency.decimal_places != undefined ? Number(this.baseCurrency.decimal_places) : 2;
        }
        this.taxBillRate3 = response.TaxBillRate || {}
        // eslint-disable-next-line no-console
        // console.log('Exchange Rate',this.exchange_rate, this.rate);
        // eslint-disable-next-line no-console
        // console.log('Code: ',currency_code);
        // Bancos
        if (client_document.type !='price_quotation') {
          this.Banks =response.Banks;
          // this.Banks =response.Banks.filter(bank =>{
          //   return bank.currency_code == currency_code;
          // });
        }
        // Render
        // eslint-disable-next-line no-console
        // console.log('preview',item);
        if (item.status_contract != null) {
          let amount = Number(client_document.amount);
          if ( (item.status_contract=='generate_charge_note'  && client_document.type =='payment_note' && amount !=0) ) {
            this.show_send = true;
            this.show_send_invoice = false;
          }
          else if (item.status_contract=='awaiting_installation' && client_document.type =='invoice') {
            this.show_send = false;
            this.show_send_invoice = true
          }  else {
            this.show_send = false;
            this.show_send_invoice = false;
          }
        } else if (item.status_contract == null && client_document.type =='invoice') {
          this.show_send = false;
          this.show_send_invoice = true;
        } else {
          this.show_send = false;
          this.show_send_invoice = false;
        }
        this.totalPay = 0;
        this.its_preview = its_preview;
        this.dialog1 = true;
        this.BillsDocument = client_document;
        this.Amount = client_document.amount;
        this.concepts = client_document.concepts;
        let howMany = 0, lenConcepts = this.concepts.length;
        for (var i = 0; i < this.concepts.length; i++) {
          if (this.concepts[i].amount == 0) {
            howMany++;
            this.emitNotification('warning', 6000, 'error422.concept_amount_invalid');
          }
        }
        let diff = lenConcepts - howMany;
        this.howMany = diff == lenConcepts ? true : false;
        // eslint-disable-next-line no-console
        // console.log('haw many',howMany);
        this.subscriber = item.subscriber;
        // eslint-disable-next-line no-console
        // console.log(this.subscriber);
        let ItemPayments = ReturnObject(item.payment);
        // eslint-disable-next-line no-console
        // console.log(ItemPayments);
        if (ItemPayments!=undefined) {
          this.there_are_payments = true;
          this.employee_id = ItemPayments.employee_id;
          let payments = ReturnObject(ItemPayments.payments);
          // eslint-disable-next-line no-console
          // console.log('payments',payments);
          for(var key in payments){
            payments[key].map(pay=>{
              if (pay.exchange_rate !=undefined) {
                // eslint-disable-next-line no-console
                // console.log('exchange_rate',Number(pay.amount), Number(pay.exchange_rate.rate));
                pay.amount = Number(pay.amount)/Number(pay.exchange_rate.rate);
              } else {
                // eslint-disable-next-line no-console
                // console.log('other rate',Number(pay.amount), Number(this.rate));
                pay.amount = Number(pay.amount)/Number(this.rate);
              }
              // eslint-disable-next-line no-console
              // console.log('amount',pay.amount);
              this.ItemsPayments.push({
                parent_id:pay.parent_id,
                bank_id:pay.parent_id != 'cash' ? pay.bank_id : '',
                bank: pay.parent_id != 'cash' ? pay.bank : '',
                amount:pay.amount,
                ref:pay.parent_id != 'cash' ? pay.ref :'',
                exchange_rate:pay.exchange_rate,
                payment_date: pay.payment_date,
                profit_code: pay.profit_code,
              });
            });
          }
          payments = [];
        } else {
          this.there_are_payments = false;
          this.ItemsPayments = [];
        }
        this.createPDF();
        this.getNumberDays(this.BillsDocument.start_date, this.BillsDocument.end_date);
      })
      .catch(error =>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log(error.response);
        }
      });
    },
    closeReplicate(){
      this.close();
      this.$emit('updateList');
    },
    close(){
      if (this.loadinfFile) {
        this.dialog1 = false;
        this.dialog2 = false;
        this.howMany = false;
        this.its_cash = false;
        this.its_card = false;
        this.loadinfFile = false;
        this.loadinfFile2 = false;
        this.there_are_payments=false;
        this.concepts = [];
        this.subscriber = [];
        this.f=[];
        this.BillsDocument = {id:''};
        this.payments={ cash:[], transfer:[], card:[],};
        this.totalRecibe=0;
        this.totalRest=0;
        this.totalPay=0;
        this.file={};
        this.file2={};
        this.panel= [0,0];
        this.editedIndex = '';
        // en prueba
        this.employee_id = '';
        // Reset List all Payments at closing Dialog
        this.ItemsPayments = [];
        this.rate = 1;
        this.decimal_places3 = 2;
        this.AllTotals = {};
        this.ExchangeRates1 = [];
        this.NewExchangeRateDoc = {};
        this.taxBillRateDoc = {};
        this.ItemsPayments = [];
        this.Banks = [];
        this.additionalLines = {};
      }
    },
    getNumberDays(start, end){
      let start_day = '';
      let end_day = '';
      this.$store.dispatch('NumberBillableDays',{
        start:start,
        end:end,
      }).then(response=>{
        // eslint-disable-next-line no-console
        // console.log(response);
        if (Object.prototype.hasOwnProperty.call(response, 'num_month_1') && Object.prototype.hasOwnProperty.call(response, 'num_month_2')) {
          start_day = response.start_day;
          end_day = response.end_day;
          this.num_month_1 = response.num_month_1;
          this.num_month_2 = response.num_month_2;
          this.number_days = response.number_days;
          this.calculations();
        } else {
          start_day = this.$moment(start, "YYYY-MM-DD");
          end_day = this.$moment(end, "YYYY-MM-DD");
          this.number_days =end_day.diff(start_day, 'days')+1;
        }
      });
    },
    calculations(exchangeRateChanged = 0){
      let rate = this.exchange_rate != null ? Number(this.exchange_rate.rate) : 1;
      const cloneDoc = JSON.parse(JSON.stringify(this.BillsDocument))
      if (exchangeRateChanged) {
        rate = this.NewExchangeRateDoc.rate
        cloneDoc.exchange_rate = JSON.stringify(this.NewExchangeRateDoc)
      }
      this.$store.dispatch('ClientDocCalculations', {
        doc: cloneDoc,
        concepts: this.concepts,
        rate: rate,
        tax_rate: this.subscriber.tax_rate, // RetIVA
        tax_sale: this.subscriber.tax_sale, // RetISRL
        num_month_1: this.num_month_1,
        num_month_2: this.num_month_2,
        IVA: this.IVA,
        UT:this.UT,
        IGTF: this.IGTF,
        ISRL:this.ISRL,
      }).then(response=>{
        // eslint-disable-next-line no-console
        // console.log('Response', response);
        if (response !=null) {
          // eslint-disable-next-line no-console
          // console.log('concepts',response.concepts.length);
          if (response.concepts.length !=0) {
            this.ItemsConcept = response.ItemsConcept;
            this.ItemPago = response.ItemPago;
            this.totalPay = Number(response.amount);
            this.dialog2 = true;
            this.AllTotals = response.AllTotals;
          }
        } else {
          this.dialog2 = false;
          this.ItemsConcept = [];
          this.ItemPago = [];
          this.totalPay = 0;
          this.AllTotals = [];
        }
      });
    },
    createPDF(){
      // eslint-disable-next-line no-console
      // console.log('antes',this.loadinfFile);
      if (!this.loadinfFile) {
        // this.loadinfFile = true;
        axios.get(this.$store.getters.getDocumentPDF(this.BillsDocument.id)).
        then((response)=>{
          this.file = response.data.success;
          this.file2 = response.data.tax_bill;
          // this.loadinfFile = true;
          // eslint-disable-next-line no-console
          // console.log('hola',response.data.success.contract_id,response.data.success.file_name);
        }).catch((error)=>{
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.error(error.response);
          }
        }).finally(()=>{
          this.loadinfFile = true;
          this.loadinfFile2 = true;
        });
      }
    },
    send(){
      this.dialog1 = false;
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.computeBillsName,
        title: this.$t('base.send'),
        action: this.$tc('sent',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.send'),
          record: this.computeBillsName
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.sendClientDocumentEmail(this.BillsDocument.id),
          method: 'post',
          data: {
            file_id: this.file.id,
          },
        },
        pos:2
      });
    },
    canceled(){
      let document = this.BillsDocument;
      document.status = "canceled";
      let data = {
        model: this.$t('document_control.types.'+document.type),
        title: this.$t('base.cancel'),
        action: this.$tc('canceled',2),
        contentActions:this.$t('base.cancel'),
        contenRecord:document.remark != null ? document.remark : this.$t('document_control.types.'+document.type)+' - '+document.control_number,
        data: document,
        url:this.$store.getters.getClientDocument(document.id),
      };
      this.changeState(data);
    },
    voided(){
      let document = this.BillsDocument;
      document.status = "voided";
      let data = {
        model: this.$t('document_control.types.'+document.type),
        title: this.$t('base.override'),
        action: this.$tc('overrided',1),
        contentActions:this.$t('base.override'),
        contenRecord:document.remark != null ? document.remark : this.$t('document_control.types.'+document.type)+' - '+document.control_number,
        data: document,
      };
      // eslint-disable-next-line no-console
      // console.log('data',document);
      this.changeState(data);
    },
    accept(){
      let document = this.BillsDocument;
      document.status = "accepted";
      let data = {
        model: this.$t('document_control.types.'+document.type),
        title: this.$t('base.accept'),
        action: this.$tc('accepted',2),
        contentActions:this.$t('base.accept'),
        contenRecord:document.remark != null ? document.remark : this.$t('document_control.types.'+document.type)+' - '+document.control_number,
        data: document,
      };
      this.changeState(data);
    },
    generate(){
      this.dialog1 = false;
      let data1 = {
        contract_id: this.BillsDocument.contract_id,
        price_quotation_id:this.BillsDocument.id,
        type: 'payment_note',
      };
      // eslint-disable-next-line no-console
      // console.log(data1,this.$store.getters.getDocumentBuild);
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('paymentNote.name'),
        title: this.$t('generate'),
        action: this.$tc('generated',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('generate'),
          record: this.$t('paymentNote.name')
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getDocumentBuild,
          method: 'post',
          data: data1,
        },
        pos:2
      });
      this.close();
    },
    changeState(items){
      this.dialog1 = false;
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: items.model,
        title: items.title,
        action: items.action,
        content: this.$t('base.confirm_delete',{
          action: items.contentActions,
          record: items.contenRecord
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getClientDocument(items.data.id),
          method: 'put',
          data: items.data,
        },
        pos:2
      });
    },
    getPayments(item){
      // eslint-disable-next-line no-console
      // console.log(item);
      this.payments = item.payments;
      this.totalRecibe = item.totalRecibe;
      this.totalRest = Number(item.totalRest);
      this.totalPay = Number(item.totalPay);
      this.employee_id = item.employee_id;
      this.NewExchangeRateDoc = item.NewExchangeRate;
      this.additionalLines = item.additionalLines;
      this.calculations(1)
    },
    beforPay(){
      this.$refs.payments_module.employeeValidate();
    },
    pendingPay(item){
      this.employee_id = item;
      this.payment();
    },
    setTaxBillRate3 (taxBillRate) {
      this.taxBillRateDoc = taxBillRate
    },
    payment(){
      let payment ={
        description:  this.$tc('Payments.name',1) + ' - '+
                      this.subscriber.doc+ ' - ' +
                      this.BillsDocument.control_number,
        payments:JSON.stringify(this.payments),
        client_document_id:this.BillsDocument.id,
        amount:this.totalRecibe,
        symbol:this.exchange_rate != null ? this.exchange_rate.symbol : this.symbol,
        decimal_places:this.exchange_rate != null ? this.exchange_rate.decimal_places : this.decimal_places3,
        employee_id:this.employee_id,
        new_exchange_rate:JSON.stringify(this.NewExchangeRateDoc),
        additional_lines: JSON.stringify(this.additionalLines),
        tax_bill_rate: JSON.stringify(this.taxBillRateDoc)
      };
      if (this.NewExchangeRateDoc.currency_code !== 'VES') {
        payment['igtf_amount'] = this.totalRecibe * (this.IGTF.value / 100)
      }
      // eslint-disable-next-line no-console
      // console.log('pagos',JSON.parse(JSON.stringify(this.payments)));
      // eslint-disable-next-line no-console
      // console.log('type', payment);
      // let typeDoc = 'document_control.types.'+this.BillsDocument.type;
      // let control_number = this.BillsDocument.control_number;
      // this.loading = true;

      this.$store.dispatch('confirmationValue',{
        template: 'paymentPreview',
        show: true,
        model: this.$t('paymentNote.name'),
        title: this.$t('base.pay'),
        action: this.$tc('paid',2),
        content: this.$t('base.confirm_delete',{
          action:this.$t('base.pay'),
          record: payment.symbol+' '+ this.$formatAmount(payment.amount, this.decimal_places3),
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getPayments(),
          method: 'post',
          data: payment,
        },
        pos:2
      });
    },
    update(){
      this.BillsDocument.status ='canceled';
      axios.put(this.$store.getters.getClientDocument(this.BillsDocument.id), this.BillsDocument)
      .then((response)=>{
        if (response.status == 200) {
          this.$store.dispatch('pushNotification', {
            snackbar:true,
            style: 'success',
            timeout: 6000,
            notifText: 'success',
            notifPos: 2,
            extra: {
              model: this.BillsDocument.remark,
              action: this.$tc('updated',2)
            }
          });
        }
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      });
    },
    emitNotification (style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      });
    },
    replica(){
      this.$refs.replica_doc.open({
        id:this.BillsDocument.id,
        document_number: this.BillsDocument.document_number,
        control_number: this.BillsDocument.control_number,
        type: this.BillsDocument.type,
        status: this.BillsDocument.status,
        legal_denomination: this.subscriber.legal_denomination,
        dni: this.subscriber.doc,
        person_type: this.subscriber.person_type,
      });
    },
    sendInvoice(isTaxBill){
      if (isTaxBill) {
        this.loadingSendable2 = true
      } else {
        this.loadingSendable = true
      }
      this.$refs.send_invoice.open(this.BillsDocument.id, this.BillsDocument.document_number, isTaxBill);
    },
    sendInvoiceLoaded (isTaxBill) {
      if (isTaxBill) {
        this.loadingSendable2 = false
      } else {
        this.loadingSendable = false
      }
    }
  },
  watch:{},
  mounted(){},
  beforeDestroy () {
    this.unwatch()
  }
};
