<template>
  <v-container fill-height welcome-platgo >
    <v-row class="d-flex align-stretch">
      <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center justify-center">
        <h1 class="display-2 mb-3">
          {{ $t("base.welcome_to") }} <br>
          <span>
            <span style="color: #eb7721">{{ proyect_title | slice(0,4) }}</span><span>{{ proyect_title | slice(4,6) }}</span><br>
            <!-- <span>{{ new Date() | moment(this.$store.getters.getDateFormat[1]) }}</span> -->
           </span>
        </h1>
      </v-col>
    </v-row>
   <!--  <v-layout
      text-center
      align-center
      wrap
    >
      <v-flex xs12>
        <h1 class="display-2 font-weight-bold mb-3">
          {{ $t("base.welcome_to") }}
          <span
            class="black_ops"
            style="font-size:44px;"
          >
            <span style="color: #bd561b">{{ proyect_title | slice(0,4) }}</span><span>{{ proyect_title | slice(4,6) }}</span><br> -->
            <!-- <span>{{ new Date() | moment(this.$store.getters.getDateFormat[1]) }}</span> -->
        <!--   </span>
        </h1>
      </v-flex>
    </v-layout> -->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    proyect_title: process.env.VUE_APP_CLIENT
  }),
  filters: {
    slice (value, start, end) {
      return value.slice(start, end)
    }
  }
}
</script>
