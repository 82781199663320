//
export default {
  name: "DialogEmployees",
  components:{},
  data (){
    return {
      edit: false,
      is_firm:false,
      expand: false,
      dialog1: false,
      loading: false,
      is_filter:false,
      fullscreen:false,
      Valitate_names:false,
      loadingSelPrefix:false,
      dialog_birthdate: false,
      Valitate_legal_denomination:false,
      showFullName: 'other',
      genders: ['F', 'M'],
      ListFranchises:[],
      employee_types:[],
      prefixes: [],
      employee:{
        prefix:'',
        is_firm:0,
      },
      dni_type: 3,
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      years_18_ago: new Date(Date.now() - 567648000000).toISOString().substr(0, 10),
      node_type: process.env.NODE_ENV,
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
    };
  },
  computed: {
    computedDateFormattedBirthdate(){
      return this.formatDate(this.employee.birthdate);
    },
    selectEmplyeesTypes(){
      let pos = 1
      if (this.employee.gender && this.employee.gender == 'F') {
        pos = 2
      }
      return this.employee_types.map(type => {
        return {
          'value': type.value,
          'text': this.$tc(type.text,pos)
        };
      });
    },
  },
  watch:{
    dialog_birthdate (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods:{
    open(item){
      this.dialog1 = true;
      this.edit = item.edit;
      this.ListFranchises = item.Franchises != null ? item.Franchises : [];
      this.employee = item.employee;
      this.employee_types = item.EmployeesTypes;
      // Franchise
      if (this.franchise_id != 0) {
        this.employee.franchise_id = this.franchise_id;
      }
      // Prefix
      var prefix = item.employee.doc != null && item.employee.doc != undefined ? item.employee.doc : '';
      if (typeof(prefix.charAt(0))=='string' && prefix!='') {
        this.employee.prefix = prefix.charAt(0);
        this.employee.dni = prefix.substring(1);
        this.ChangePrefix(prefix.charAt(0));
        this.DataProcessor(prefix.charAt(0), item.employee.legal_denomination);
      } else {
        this.employee.prefix = '';
        this.employee.dni = prefix;
      }
    },
    create(){
      axios.post(this.$store.getters.getEmployees(), this.employee)
      // axios.post(this.$store.getters.getMaterialsEquipment(), this.materialEquipment)
      .then((response)=>{
        if(response.status == 200){
          // eslint-disable-next-line no-console
          // console.log('create', response.data.success.equipment_materials);
          // this.materialsAndEquipment.push(response.data.success.equipment_materials);
          const text = this.$tc('employees.employee', 1);
          this.pushNoty(text, "created", 1);
          this.close();
          this.$emit('updateList');
        }
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    update(){
      axios.put(this.$store.getters.getEmployees(this.employee.id), this.employee)
      .then((response)=>{
        if(response.status == 200){
          // eslint-disable-next-line no-console
          // console.log('update', response.data);
          const text = this.$tc('employees.employee', 1);
          this.pushNoty(text, "updated", 1);
          this.close();
          this.$emit('updateList');
        }
      }).catch((error)=>{
        // eslint-disable-next-line no-console
        // console.log('Error',error);
      }).finally(()=>{
        this.loading = false;
      });
    },
    save(){
      this.$refs.form_employee.validate()
      .then((success)=>{
        if (success){
          this.loading = true;
          if(this.edit==false){
            this.create();
          } else if (this.edit==true){
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    close(){
      this.$refs.form_employee.reset();
      this.edit = false;
      this.loading = false;
      this.dialog1 = false;
      this.is_firm = false;
      this.fullscreen = false;
      this.Valitate_names=false;
      this.Valitate_legal_denomination=false;
      this.employee = {
        prefix:'',
        is_firm:0,
      };
      this.dni_type = 3;
      this.showFullName = 'other';
    },
    formatDate (date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    loadPrefixes() {
      this.loadingSelPrefix = true;
      axios.get(this.$store.getters.getPrefixes)
        .then(response => {
          this.prefixes = response.data.prefixes;
        })
        .catch(err => {
          this.prefixes = [];
        })
        .finally(() => {
          this.loadingSelPrefix = false;
        })
    },
    onlyDigits (e) {
      var keypressed = e.keyCode ? e.keyCode : e.which;
      var platform = navigator.platform.includes("Mac");
      if (!platform) {
        var shiftKeyPlus = e.shiftKey && keypressed > 47 && keypressed < 58; // shift + key
        var interval1 = keypressed < 37;
        var interval2 = keypressed > 40 && keypressed < 48;
        var interval3 = keypressed > 57 && keypressed < 95;
        var interval4 = keypressed > 105;
        // eslint-disable-next-line no-console
        // console.log('key',keypressed , e.metaKey);
        if (keypressed != 8  && keypressed != 16 &&
          keypressed != 46 && keypressed != 0  &&
          (interval1 || interval2 || interval3 || interval4 || shiftKeyPlus))
        {
          e.preventDefault();
          // eslint-disable-next-line no-console
          // console.log('e',keypressed);
        }
      }
    },
    onlyLetters (e) {
      var keyCode = (e.keyCode ? e.keyCode : e.which);
      if (keyCode > 47 && keyCode < 58) {
        e.preventDefault();
      }
    },
    ChangePrefix(item, reset = false){
      if (item=='V'|| item=='E') {
        this.showFullName = 'yes';
        this.Valitate_legal_denomination=false;
        this.Valitate_names=true;
        this.dni_type = 0;
      } else if (item=='J'|| item=='G') {
        this.showFullName = 'no';
        this.Valitate_legal_denomination=true;
        this.Valitate_names=false;
        this.dni_type = 1;
      } else {
        this.showFullName = 'other';
        this.Valitate_names=false;
        this.Valitate_legal_denomination=false;
        this.is_firm = false;
        this.dni_type = 3;
      }
      if (reset) {
        this.$refs.form_employee.reset();
      }
    },
    ChangeIs_Firm(item, reset = false){
      if (item) {
        this.employee.is_firm = 1;
        this.dni_type = 1;
      } else {
        this.employee.is_firm = 0;
        this.dni_type = 0;
      }
      if (reset) {
        this.$refs.form_employee.reset();
      }
    },
    DataProcessor(prefix, item){
      let legal_denomination = item[0][0];
      let obj = JSON.parse(item[1][0]);

      if (this.node_type === 'development') {
        // eslint-disable-next-line no-console
        // console.clear();
        // eslint-disable-next-line no-console
        // console.warn(`Modo: ${this.node_type} Prefijo: ${prefix} Edit ${this.edit}`);
        // eslint-disable-next-line no-console
        // console.group();
        // eslint-disable-next-line no-console
        // console.log(legal_denomination);
        // eslint-disable-next-line no-console
        // console.log(obj);
        // eslint-disable-next-line no-console
        // console.groupEnd();
      }

      if (prefix=='V'|| prefix=='E') {
        this.employee.first_name = obj.first_name;
        this.employee.last_name = obj.last_name;
        if (obj.firm_name != null && obj.firm_name != undefined) {
          this.employee.legal_denomination = obj.firm_name;
          this.is_firm = true;
          this.ChangeIs_Firm(true);
        } else {
          this.employee.legal_denomination = '';
          this.is_firm = false;
          this.ChangeIs_Firm(false);
        }
      } else if (prefix=='J'|| prefix=='G') {
        this.employee.legal_denomination = legal_denomination;
      }
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  mounted() {
    this.loadPrefixes();
  },
};
