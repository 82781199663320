var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","show-arrows":"","color":"grey","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('franchise.active',2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-check")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc('franchise.inactive',2))}}):_vm._e(),_c('v-icon',[_vm._v("fas fa-ban")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.zones,"items-per-page":_vm.perPage,"footer-props":{
                    'items-per-page-options': [5, 10, 50]
                  },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"cshowSearch":"","cshowArrowBack":"","showNew":_vm.Permissions.create && _vm.step == 'tab-1',"disableNew":_vm.loadingTable,"title":_vm.$tc('zones.zones',0),"nameButton":_vm.$tc('base.new',2)},on:{"newItem":_vm.newItem}}),_c('sectorFilter',{attrs:{"showBtnFilter":"","showSearch":"","showFranchise":_vm.franchise_id == 0,"disableFields":_vm.loadingTable,"emitSearch":'by_name'},on:{"sector_id":_vm.filterBy,"franchise_id":_vm.filterBy,"by_name":_vm.filterBy,"eraseFilter":_vm.eraseFilter}})]},proxy:true},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-name-zone"},[_vm._v(_vm._s(item.name))])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.Permissions.edit),expression:"Permissions.edit"}],attrs:{"edit":"","disabled":_vm.loadingTable,"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.is_active==true && _vm.Permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.loadingTable,"color":"success"},on:{"click":function($event){return _vm.deactivateItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")])],1):_vm._e(),(item.is_active==false && _vm.Permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","delete":"","disabled":_vm.loadingTable},on:{"click":function($event){return _vm.activateItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ban")])],1):_vm._e()]}}],null,true)},[(item.is_active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('deactivate'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('activate'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.Permissions.delete),expression:"Permissions.delete"}],attrs:{"delete":"","disabled":_vm.loadingTable,"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('base.delete')))])])]}}],null,true)})],1)],1)],1)],1)]],2)],1),_c('DialogZone',{ref:"dialog_zone",on:{"createZone":_vm.List}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }