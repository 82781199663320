//
export default {
  name: "DocumentControl",
  components:{
  },
  data (){
    return {
      dialog1: false,
      loading: false,
      loadingTable:false,
      edit: false,
      fullscreen:false,
      document:{
        employee_id:'',
      },
      TypeValidation: {
        'required': false,
      },
      SerialValidation: {
        'required': false,
      },
      ControlValidation:{
        'required': true,
        'min_value': 0,
        'numeric': true,
      },
      documents:[],
      TypeDocument:[],
      ListFranchises:[],
      franchise_id: this.$store.getters.getFranchiseID == null ? 0:this.$store.getters.getFranchiseID,
    };
  },
  computed: {
    selectedDocumentTypes(){
      return this.TypeDocument.map(types => {
        return {
          'value': types.value,
          'text': this.$t(types.text)
        };
      });
    },
  },
  methods:{
    open(item){
      this.edit = item.edit;
      this.TypeDocument = item.TypeDocument;
      this.ListFranchises = item.ListFranchises;
      if(!this.edit) {
        this.newDocumentControl();
      } else {
        this.editItem(item.docControl);
      }
      this.dialog1 = true;
    },
    close(){
      this.dialog1 = false;
      this.edit = false;
      this.loading = false;
      this.fullscreen = false;
      this.document = { employee_id: ''};
      this.$refs.form_doc_control.reset();
      this.$refs.employee_search.clear2();
    },
    newDocumentControl(){
      this.document = {
        serial_document: '',
        initial_document_number:'',
        final_document_number:'',
        serial_control:'',
        initial_control_number:'',
        final_control_number:'',
        type: this.franchise_id == 0 ? '': 'invoice',
        franchise_id: this.franchise_id == 0 ? '': this.franchise_id,
        franchise_name:'',
        employee_id:'',
      };
    },
    editItem(item){
      // eslint-disable-next-line no-console
      // console.log('edit',item);
      this.document = Object.assign({},item);
      this.document.franchise = item.franchise_id;
    },
    save(){
      this.$refs.form_doc_control.validate()
      .then((success) =>{
        if (success){
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        }
      });
    },
    create(){
      axios.post(this.$store.getters.getDocumentControl(), this.document
      ).then((response)=>{
        if(response.status == 200){
          this.$emit('updateList');
          this.close();
          const text = this.$t('document_control.document');
          this.pushNoty(text, "created", 1);
        }
      }).catch((error)=>{
        if (error.response) {
          // console.log(error.response.status,' Error create ', error.response.data);
          if (error.response.data.error) {
            let err = 'error422.'+error.response.data.error;
          }
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    update(){
      // eslint-disable-next-line no-console
      // console.log('franchise', this.franchise_id, this.document.franchise_id,this.document.actor_id);
      // eslint-disable-next-line no-console
      // console.log('doc', this.document);
      axios.put(this.$store.getters.getDocumentControl(this.document.id), this.document)
      .then((response)=>{
        if(response.status == 200){
          this.$emit('updateList');
          this.close();
          const text = this.$t('document_control.document');
          this.pushNoty(text, "updated", 1);
        }
      }).catch((error)=>{
        if (error.response) {
          // console.log('Error update', error.response.data);
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    ChangeType(){
      this.$refs.form_doc_control.reset();
    },
    ChangeFranchise(item){
      if (item != undefined) {
        if (Object.prototype.hasOwnProperty.call(item, 'id')) {
          this.document.franchise_id = item.id;
          this.document.franchise_name = item.name;
        } else {
          this.document.franchise_id = '';
          this.document.franchise_name = '';
        }
      } else {
        this.document.franchise_id = '';
        this.document.franchise_name = '';
      }
      // eslint-disable-next-line no-console
      // console.log('franchise',this.document);
    },
    noNegative (e) {
      if ([109, 173, 189].includes(e.keyCode)) {
        e.preventDefault();
      }
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  watch: {
    "document.type":{
      handler(val){
        if(val != "" && typeof(val) == 'string'){
          if (val =='invoice') {
            this.TypeValidation = {
              'required': true,
              'min_value': 0,
            };
            this.SerialValidation = {
              'required': true,
            };
          } else {
            this.TypeValidation = {
              'required': false,
            };
            this.SerialValidation = {
              'required': false,
            };
          }
        }
      },
    },
  },
};
