// import ConceptAlertDialog from './ConceptAlertDialog.vue';
export default {
  name: "ConceptDialog",
  components: {
    // ConceptAlertDialog
  },
  props: {
    checkAmountCounter: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      fullscreen: false,
      date_modal_start: false,
      date_modal_end: false,
      its_percentage: false,
      isCountry: false,
      isState: false,
      isCity: false,
      isMunicipality: false,
      isParish: false,
      country: null,
      state: null,
      city: null,
      municipal: null,
      parish: null,
      sector: null,
      symbolMoney: '',
      iconMoney: '',
      rate: 1,
      concept: {},
      options: {},
      concept_motives: [],
      concept_types: [],
      concept_taxes: [],
      person_types: [],
      client_types: [],
      services: [],
      items_city: [],
      items_country: [],
      items_municipal: [],
      items_parish: [],
      items_state: [],
      items_sector: [],
      document_adjustment_motives: [],
      concept_motive: '',
      concept_amount: 0.0,
      QtyValidation: {
        'required': false,
      },
      panel: [0, 1],
      today: new Date().toLocaleString('fr-CA', {
        timeZone: 'America/Caracas'
      }).substr(0, 10),
      decimal_places: 2,
      modeNode: process.env.NODE_ENV,
      auxAmount: 0,
      money_key: 0
    };
  },
  computed: {
    selectedConceptTypes() {
      return this.concept_types.map(types => {
        return {
          "value": types.value,
          "text": this.$t(types.text)
        };
      });
    },
    computedDateFormattedStartDate() {
      return this.formatDate(this.concept.start_date);
    },
    computedDateFormattedEndDate() {
      return this.formatDate(this.concept.finish_date);
    },
  },
  methods: {
    getQueries() {
      this.$store.dispatch('DocQuerys')
        .then(response => {
          this.document_adjustment_motives = response.document_adjustment_motives;
          this.concept_taxes = response.ListIVA;
          this.concept_types = response.ConceptTypes;
          this.client_types = response.ClientType;
        })
        .catch(error => {
          if (error.response) {
            // eslint-disable-next-line no-console
            // console.error(error.response);
          }
        });
    },
    /**
     * List of concept motives registered in system
     */
    getMotives() {
      axios.get(this.$store.getters.getConceptMotives())
        .then(response => {
          this.concept_motives = response.data.motives.map(value => value.name);
          this.motives_data = response.data.motives;
        })
        .catch((err) => {
          this.concept_motives = [];
        });
    },
    /**
     * Set the correspondent concept motive if its selected
     * @param {object} concept_motive
     */
    setConceptMotive(concept_motive) {
      if (this.concept_motives.includes(concept_motive)) {
        let motive = this.concept_motives.filter(mo => {
          return mo.name == concept_motive;
        });
      } else {
        this.concept.concept_motive = this.concept_motive;
        this.concept.concept_motive_id = '';
      }
    },
    /**
     * List of person types registered in system
     */
    getPersonTypes() {
      this.person_types = [
        'Natural',
        'Juridic'
      ];
    },
    /**
     * List of services registered in system
     */
    getServices() {
      axios.get(this.$store.getters.getService())
        .then(response => {
          this.services = response.data.service.data.map(value => value.name);
        })
        .catch(error => {
          this.services = [];
        });
    },
    /**
     * List of countries registered in system
     */
    getCountries() {
      axios.get(this.$store.getters.getAllCountries)
        .then(response => {
          this.items_country = response.data.countries;
        })
        .catch(error => {
          this.items_country = [];
        });
    },
    /**
     * List of states registered in system that corresponds to selected country
     */
    countrySelected() {
      if (this.country) {
        axios.get(this.$store.getters.getStates(this.country))
          .then(response => {
            this.isCountry = true;
            this.items_state = response.data.states;
          })
          .catch(error => {
            this.isCountry = false;
            this.items_state = [];
          });
      } else {
        this.isCountry = false;
      }
    },
    /**
     * List of municipalities registered in system that corresponds to selected state
     */
    stateSelected: function () {
      if (this.state) {
        axios.all([
            axios.get(this.$store.getters.getCities(this.state)),
            axios.get(this.$store.getters.getMunicipalities(this.state))
          ])
          .then(responseArr => {
            this.isState = true;
            this.items_city = responseArr[0].data.cities;
            this.items_municipal = responseArr[1].data.municipalities;
          })
          .catch(error => {
            this.isState = false;
            this.items_city = [];
            this.items_municipal = [];
          });
      } else {
        this.isState = false;
      }
    },
    /**
     * List of sectors registered in system that corresponds to selected city
     */
    citySelected: function () {
      /* Commented while Sectors data is provided
      if (this.city) {
        axios.get(this.$store.getters.getSectors(this.city))
        .then(response => {
          this.isCity = true
          this.items_sector = response.data.sectors
        })
        .catch(error => {
          this.isCity = false
          this.items_sector = []
        })
      } else {
        this.isCity = false
      }
      */
      // Begin of only for test
      this.isCity = true;
      this.items_sector = ['Sector_1', 'Sector_2', 'Sector_3'];
      // End of only for test
    },
    /**
     * List of parishes registered in system that corresponds to selected municipality
     */
    municipalSelected: function () {
      if (this.municipal) {
        axios
          .get(this.$store.getters.getParishes(this.municipal))
          .then(response => {
            this.isMunicipality = true;
            this.items_parish = response.data.parishes;
          })
          .catch(error => {
            this.isMunicipality = false;
            this.items_parish = [];
          });
      } else {
        this.isMunicipality = false;
      }
    },
    /**
     * List of sectors registered in system that corresponds to selected parish
     */
    parishSelected() {
      /* Commented while Sectors data is provided
      if (this.parish) {
        axios.get(this.$store.getters.getSectors(this.parish))
        .then(response => {
          this.isParish = true
          this.items_sector = response.data.sectors
        })
        .catch(error => {
          this.isParish = false
          this.items_sector = []
        })
      } else {
        this.isParish = false
      }
      */
      // Begin of only for test
      this.isParish = true;
      this.items_sector = ['Sector_1', 'Sector_2', 'Sector_3'];
      // End of only for test
    },
    /**
     * Date Format
     */
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    },
    /**
     * Functions Template
     */
    open(item) {
      this.loading = false;
      // eslint-disable-next-line no-console
      // console.log('concepto', item);
      if (item != undefined) {
        this.edit = item.edit ? item.edit : false;
      }
      if (item != undefined && Object.prototype.hasOwnProperty.call(item, 'exchange_rate')) {
        this.symbolMoney = item.exchange_rate.symbol;
        this.iconMoney = 'fas fa-' + item.exchange_rate.icon;
        this.rate = Number(item.exchange_rate.rate);
        this.decimal_places = item.exchange_rate.decimal_places || 2;
      } else {
        this.symbolMoney = '';
        this.iconMoney = '';
        this.rate = 1;
      }
      if (item != undefined && Object.prototype.hasOwnProperty.call(item, 'concept')) {
        this.concept = item.concept;
        if (item.concept.is_percentage) {
          this.its_percentage = item.concept.is_percentage;
          this.concept.is_percentage = item.concept.is_percentage;
          this.concept_amount = Number(item.concept.amount);
        } else {
          this.concept_amount = Number(item.concept.amount) * this.rate;
        }
        // Count amount decimal places
        let cnt = this.$countDecimals(this.concept_amount)
        if (cnt > this.decimal_places) {
          this.decimal_places = cnt
        }
        if (item.concept.concept_motive) {
          this.concept_motive = item.concept.concept_motive;
        } else {
          if (item.concept.concept_motive_id) {
            this.concept_motive = this.motives_data.find(motive => {
              return motive.id == item.concept.concept_motive_id;
            }).name;
          }
        }
        this.edit = item.edit;
        this.dialog1 = true;
      } else {
        this.newItem();
      }
    },
    newItem() {
      let motive = this.motives_data[Math.floor(Math.random() * this.motives_data.length)];
      let type = this.concept_types[Math.floor(Math.random() * this.concept_types.length)].value;
      this.dialog1 = true;
      this.edit = false;
      this.concept_motive = '';
      // this.concept_motive = motive.name;
      this.concept_amount = 0.0;
      this.concept = {
        name: '',
        concept_motive: '',
        concept_motive_id: '',
        quantity: 1,
        amount: this.concept_amount,
        type: '',
        is_percentage: false,
        is_one_time: true,
        // name: 'Concepto '+motive.name,
        // concept_motive: motive.name,
        // quantity: Math.floor(Math.random() * 11),
        // quantity:1,
        // amount: (Math.random() *(91)).toFixed(2),
        // start_date:  new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
        // type: 'debit',
        // type:type,
        // is_percentage: !Math.round(Math.random()),
        // is_one_time:!Math.round(Math.random()),
      };
    },
    close() {
      this.concept_amount = 0.0;
      this.dialog1 = false;
      this.edit = false;
      this.concept_motive = '';
      this.concept = {
        amount: 0.0,
        concept_motive: '',
        is_percentage: false,
        is_one_time: false,
      };
      this.$refs.form_concept.reset();
    },
    save() {
      this.$refs.form_concept.validate()
        .then((success) => {
          if (success) {
            this.loading = true;
            this.afterVerifyAmount(this.edit, true);
          } else {
            this.loading = false;
          }
        });
    },
    /*
    beforeSave() {
      const newAmount = Number(this.auxAmount);
      const oldAmount = Number(this.concept.amount);
      const documents_count = Number(this.concept.documents_count);
      if (documents_count > 1 && oldAmount != newAmount && this.checkAmountCounter) {
        const newConcept = {
          id: this.concept.id,
          value: newAmount,
          name: this.concept.name,
          is_percentage: this.concept.is_percentage,
          decimal_places: this.concept.is_percentage ? 2 : this.decimal_places,
          count: documents_count,
          amount: this.concept.amount,
          symbol: this.symbolMoney
        }
        this.$refs.concept_alert.open(newConcept);
      } else {
        this.afterVerifyAmount(this.edit, false);
      }
    },
    */
    afterVerifyAmount(edit, deleteAndCreate) {
      this.preProcesorData();
      setTimeout(() => {
        if (edit == true) {
          if (deleteAndCreate == true) {
            this.$emit('deleteAndCreate', this.concept);
          } else {
            this.$emit('updated1', this.concept);
          }
        } else {
          this.$emit('created1', this.concept);
        }
        this.close();
      }, 50);
    },
    preProcesorData() {
      if (!this.its_percentage) {
        this.concept.amount = this.concept_amount / this.rate;
      } else {
        this.concept.amount = this.concept_amount;
      }
    },
    changeConcepAmount() {
      this.afterVerifyAmount(this.edit, false);
    },
    createNewConcept() {
      this.afterVerifyAmount(false, true);
    },
    closeAlert() {
      this.loading = false;
    }
  },
  watch: {
    "concept.is_percentage": {
      handler(val) {
        if (val) {
          this.concept_amount = 0.0
          this.money_key = Number(val)
        }
      }
    },
    "concept.type": {
      handler(val) {
        if (val != "" && typeof (val) == 'string') {
          if (val == 'debit') {
            this.QtyValidation = {
              'required': true,
              'min_value': 1,
            };
            this.concept.tax_id = this.concept_taxes[0].value;
          } else {
            this.QtyValidation = {
              'required': false,
            };
            this.concept.tax_id = '';
          }
        }
      }
    },
    "concept_amount": {
      handler(val) {
        if (typeof (val) == 'number' && val != undefined) {
          if (!this.its_percentage) {
            this.auxAmount = val / this.rate;
          } else {
            this.auxAmount = val;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    concept_motive(val) {
      if (val != undefined && val.length > 2) {
        let motive = this.motives_data.find(motive => {
          return motive.name == val;
        });
        if (typeof (motive) == 'object' && motive.name != undefined) {
          this.concept.concept_motive = motive.name;
          this.concept.concept_motive_id = motive.id;
        } else {
          this.concept.concept_motive = val;
          this.concept.concept_motive_id = '';
        }
      }
    }
  },
  mounted() {
    this.getQueries();
    this.getMotives();
    this.getPersonTypes();
  }
};
