//
export default {
  name: "MaterialsAndEquipment",
  components:{},
  data (){
    return {
      dialog1: false,
      loading: false,
      loadingTable:false,
      edit: false,
      fullscreen:false,
      expand: false,
      is_filter:false,
      typesME:[
        {value:'material', text:'material'},
        {value:'equipment', text:'equipment'}
      ],
      materialsAndEquipment:[],
      materialEquipment:{
        serial:'',
        price: 0,
      },
      options:{
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      EquipValidation: {
        'required': true,
      },
      searches:{
        serial:'',
        name:'',
        type:'',
      },
      count:0,
      perPage: 10,
      relationIndex:-1,
      totalValues:0,
      index: '',
      editedIndex:'',
      unwatch: '',
      search:'',
      today: new Date().toLocaleString('fr-CA', {timeZone: 'America/Caracas'}).substr(0, 10),
      modeNode:process.env.NODE_ENV,
    };
  },
  mounted() {
    // this.List();
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.List();
      }
    });
  },
  beforeDestroy () {
    this.unwatch();
  },
  watch: {
    options: {
      handler () {
        this.List();
      },
      deep: true,
    },
    "materialEquipment.type":{
      handler(val){
        if(val != "" && typeof(val) == 'string'){
          if (val =='equipment') {
            this.EquipValidation = {
              'required': true,
            };
          } else {
            this.EquipValidation = {
              'required': false,
            };
          }
        }
      }
    },
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t('MaterialEquipment.type.type'),
          value:'type',
          align: 'left',
        },
        {
          text: this.$t('MaterialEquipment.name'),
          value:'name',
          align: 'center',
        },
        {
          text: this.$t('MaterialEquipment.brand'),
          value:'brand',
          align: 'center',
        },
        {
          text: this.$t('MaterialEquipment.model'),
          value:'model',
          align: 'center',
        },
        {
          text: this.$t('MaterialEquipment.serial'),
          value:'serial',
          align: 'center',
        },
        {
          text: this.$t('MaterialEquipment.price'),
          value:'price',
          align: 'right',
        },
        {
          text: this.$t('actions'),
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'right'
        }
      ];
    },
    SelectTypesME(){
      return this.typesME.map(types => {
        return {
          'value': types.value,
          'text': this.$t('MaterialEquipment.type.'+types.text)
        };
      });
    },
  },
  methods:{
    List(){
      this.loadingTable = true;
      if ((this.searches.name != '' || this.searches.serial != '' || this.searches.type != '') && this.is_filter) {
        this.options.page = 1;
      }
      axios.get(this.$store.getters.getMaterialsEquipment(),
      {
        params:{
          page:this.options.page,
          paginate:this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
          name:this.searches.name,
          serial:this.searches.serial,
          type:this.searches.type
        }
      }
      ).then((response)=>{
        response.data.equipment_materials.data.map(me=>{
          me.price = Number(me.price);
        })
        this.materialsAndEquipment = response.data.equipment_materials.data;
        this.totalValues = response.data.equipment_materials.total;
        this.loadingTable = false;
        this.is_filter = false;
      }).catch((error)=>{
        this.loadingTable = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      });
    },
    newItem(){
      let name  = ['Modem', 'Conector RJ45', 'Conector RJ11', 'Cable UTP', 'Antena','Router','Martillo','Tornillos','Llave inglesa','Pinzas','BenQ'];
      let model = ['Mjolnir','APT','Alcatel','Belkin','Benq','THW','THHH'];
      this.dialog1 = true;
      this.totalValues ++;
      this.materialEquipment = {
        price: 0,
        // name:name[Math.floor(Math.random()*name.length)]+ ' '+ this.totalValues,
        // model: model[Math.floor(Math.random()*model.length)],
        // serial:this.totalValues,
        // brand:'',
        // measurement:'',
        // type: this.SelectTypesME[Math.floor(Math.random()*this.SelectTypesME.length)].value,
        // price: (this.totalValues * Math.random() *(491)).toFixed(2),
        // description:'Cupidatat nisi in eu sunt ex anim officia pariatur adipisicing adipisicing mollit quis.'
      };
    },
    editItem(item){
      // eslint-disable-next-line no-console
      // console.log('Item', item.price);
      this.dialog1 = true;
      this.edit = true;
      this.editedIndex = this.materialsAndEquipment.indexOf(item);
      this.materialEquipment = Object.assign({}, item);
    },
    deleteItem(item){
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('MaterialEquipment.type.'+item.type),
        title: this.$t('base.delete'),
        pre: 'success',
        action: this.$tc('deleted',1),
        content: this.$t('base.confirm_delete',{
          action: this.$t('base.delete'),
          record: item.name
        }),
        type: 'error',
        confirm:{
          url: this.$store.getters.getMaterialsEquipment(item.id),
          method: 'delete'
        },
        pos: 1
      });
    },
    create(){
      // eslint-disable-next-line no-console
      // console.log('Material', this.materialEquipment);
      // this.searches={serial:'',name:'',type:this.materialEquipment.type};
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      axios.post(this.$store.getters.getMaterialsEquipment(), this.materialEquipment)
      // axios.post(this.$store.getters.getMaterialsEquipment(), this.materialEquipment)
      .then((response)=>{
        if(response.status == 200){
          // eslint-disable-next-line no-console
          // console.log('create', response.data.success.equipment_materials);
          // this.materialsAndEquipment.push(response.data.success.equipment_materials);
          const text = this.$t('MaterialEquipment.type.'+response.data.success.equipment_materials.type);
          this.pushNoty(text, "created", 1);
          this.close();
          this.List();
        }
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    update(){
      // this.searches={serial:'',name:'',type:this.materialEquipment.type};
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      axios.put(this.$store.getters.getMaterialsEquipment(this.materialEquipment.id), this.materialEquipment)
      .then((response)=>{
        if(response.status == 200){
          // eslint-disable-next-line no-console
          // console.log('update', response.data);
          const text = this.$t('MaterialEquipment.type.'+response.data.type);
          this.pushNoty(text, "updated", 1);
          this.close();
          this.List();
        }
      }).catch((error)=>{
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.log('Error',error.response);
          if (error.response.status == 422) {
            this.dialog1 = false;
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log('Error',error);
        }
      }).finally(()=>{
        this.loading = false;
        this.edit = false;
      });
    },
    close(){
      this.$refs.form_material_equipment.reset();
      this.dialog1 = false;
      this.edit = false;
      this.loading = false;
      this.fullscreen = false;
      this.materialEquipment = {
        price: 0,
      };
    },
    save(){
      this.$refs.form_material_equipment.validate()
      .then((success)=>{
        if (success){
          this.loading = true;
          if(this.edit == false){
            this.create();
          } else {
            this.update();
          }
        } else {
          // eslint-disable-next-line no-console
          // console.log(this.errors.all());
        }
      });
    },
    SearchFilter(){
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.is_filter = true;
      this.List();
    },
    EraseFilter(){
      this.options={sortBy:['updated_at'],sortDesc:[true]};
      this.searches={serial:'',name:'',type:'',};
      this.List();
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
};
