var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1 roles-table table-ellipsis",attrs:{"headers":_vm.headers,"items":_vm.roles,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"server-items-length":_vm.totalValues,"options":_vm.options,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":"","disableNew":_vm.loadingTable,"title":_vm.$tc('roles.roles', 2),"nameButton":_vm.$tc('base.new', 0)},on:{"newItem":_vm.newItem}})]},proxy:true},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"item-text-ellipsis-2"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"item-text-ellipsis-2"},[_vm._v(" "+_vm._s(item.description))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("policy.view_policy")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"edit":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"delete":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.delete")))])])]}}],null,true)}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(this.$vuetify.breakpoint.xsOnly),expression:"this.$vuetify.breakpoint.xsOnly"}],attrs:{"color":"primary","fab":"","dark":"","fixed":"","bottom":"","right":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)]],2)],1),_c('v-dialog',{attrs:{"max-width":"920","persistent":!_vm.view,"scrollable":"","fullscreen":_vm.fullscreen},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-0",attrs:{"dense":"","tile":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"subtitle-1 font-weight-bold py-1"},[(_vm.view)?[_vm._v(" "+_vm._s(_vm.$t("policy.view_policy"))+" ")]:(!_vm.edit)?[_vm._v(" "+_vm._s(((_vm.$tc("base.new", 0)) + " " + (_vm.$tc("roles.roles", 1))))+" ")]:[_vm._v(" "+_vm._s(((_vm.$t("base.edit")) + " " + (_vm.$tc("roles.roles", 1))))+" ")]],2)]),_c('v-spacer'),(!_vm.view)?_c('v-toolbar-items',{staticClass:"requests-modal-toolbar"},[_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[(!_vm.view)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationObserver',{ref:"form_roles",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"group-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","counter":"","maxlength":"190","error-messages":errors,"label":_vm.$t('name')},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":"description","name":_vm.$t('description'),"rules":{ required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"description","counter":"","maxlength":"190","error-messages":errors,"label":_vm.$t('description')},model:{value:(_vm.role.description),callback:function ($$v) {_vm.$set(_vm.role, "description", $$v)},expression:"role.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"vid":"groups","name":_vm.$tc('roles.groups', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"groups","items":_vm.groups,"item-text":"name","item-value":"id","error-messages":errors,"label":_vm.$tc('roles.groups', 1)},model:{value:(_vm.role.groups),callback:function ($$v) {_vm.$set(_vm.role, "groups", $$v)},expression:"role.groups"}})]}}],null,true)})],1)],1)],1)]}}],null,false,1572367331)})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.dialog)?_c('panelsroles',{ref:"panel_roles",attrs:{"RolePolicies":_vm.role.policies},on:{"SendPolices":_vm.receivedPolicies}}):_vm._e()],1)],1):_c('v-row',[_c('v-col',{staticClass:"view-policies"},_vm._l((_vm.role.policies),function(policie_chip,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(_vm.$te("policy." + policie_chip.name) ? _vm.$tc("policy." + policie_chip.name, 1) : _vm.$t("policy.translation_not_found"))+" ")])}),0)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(_vm.view)?_c('v-btn',{attrs:{"text":"","cancel":""},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("close")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):[_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.save")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t("base.edit")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }