import { permission_es } from './es/permission_es.js';
import { es_veeValidate } from "./es/es_veeValidate.js";
import { error422_es } from './es/error422';

export const es = {
  line: 'Línea',
  additional_info: 'Información Adicional',
  no_flow: 'El flujo no ha sido definido. Estatus: {0}',
  code: 'Código',
  close: 'Cerrar',
  password_conditions: {
    cap: 'Min. una letra Mayúscula',
    low: 'Min. una letra Minúscula',
    num: 'Min. un número',
    char: 'Min. un caracter especial !@#$%^&*()--__+.',
    long: 'Min. longitud 8'
  },
  configs: 'Configuraciones',
  download: 'Descargar',
  view: 'Ver',
  generate: 'Generar',
  minimize: 'Minimizar',
  active: 'Activo|Activos|(Activo)',
  back: 'Atrás',
  is_required: 'Obligatorio',
  row: 'fila|filas',
  writeSearch: 'Escribe para buscar {model}',
  usermanagement: 'Gestión de usuarios',
  refprice: 'Precio referencial',
  inactive: 'Inactivo|Inactivos|(Inactivo)',
  no_data: 'Ningún dato disponible',
  measure: 'Unidad de medida',
  measure_info: 'Para la unidad de medida puede utilizar una palabra en singular o plural. También es posible agregar ambas, separadas mediante una barra diagonal (/), siempre y cuando la primera palabra sea singular y la segunda plural. Ej.: canal/canales',
  employee_search_info: 'Para iniciar la búsqueda del Empleado, debe escribir las primeras tres (3) letras del nombre.',
  lower_limit: 'Límite inferior',
  upper_limit: 'Límite superior',
  unauthorized: 'No autorizado',
  logged_out: '¡Usuario desconectado exitosamente!',
  safety_log_out: 'Algo salió mal. ¡Usuario desconectado!',
  conected_to: 'Conectado a: {0}',
  connection_refused: 'Conexión Rechazada',
  name_only_letters: 'El nombre solo puede contener letras',
  telephone_must_11_digits: 'El teléfono debe tener 11 dígitos',
  logo_must_valid_image: 'El logo debe ser JPG, JPEG o PNG',
  name_alpha_dash: 'El nombre solo puede contener letras, números, (-) y (_)',
  ced_digits_3_8: 'El DNI debe tener entre 3 y 8 dígitos',
  doc_alpha_num: 'El DNI solo puede contener números',
  doc_must_10: 'El DNI debe constar de 10 caracteres',
  too_many_attempts: 'Demasiados intentos',
  name: 'Nombre',
  description: 'Descripción',
  perPage: 'Filas por página',
  orderBy: 'Ordenar por',
  action: 'Acción',
  actions: 'Acciones',
  success: '¡El {model} fue {action} con éxito!|¡La {model} fue {action} con éxito!',
  success2: '¡Los {model} fueron {action} con éxito!|¡Las {model} fueron {action} con éxito!',
  saved: 'guardados|guardadas',
  created: 'creado|creada',
  updated: 'actualizado|actualizada',
  deleted: 'eliminado|eliminada',
  canceled: 'canceledo|canceleda',
  overrided: 'anulado|anulada|anulados|anuladas',
  accepted: 'aceptado|aceptada',
  generated: 'generado|generada',
  installed: 'Instalado|Instalada|Instalados|Instaladas',
  configured: 'Configurado|Configurada|Configurados|Configuradas',
  paid: 'pagado|pagada',
  restored_updated: 'restaurado y actualizado|restaurada y actualizada',
  completed: 'completado|completada',
  stablished: 'establecido|establecida',
  approved: 'aprobado|aprobada',
  changed: 'cambiado|cambiada',
  denied: 'negado|negada',
  denied2: 'Denegado|Denegados',
  Restore: 'restaurado|restaurada',
  sent: 'enviado|enviada',
  obj_not_found: 'No se encontró {0}|No se encontraron {0}',
  dni: 'Cédula',
  first_name: 'Nombre',
  last_name: 'Apellido',
  assign: 'Asignar',
  disallow: 'Desasignar',
  assigned: 'asignado|asignados',
  assigned2: 'Asignado|Asignados',
  deallocated: 'desasignado|desasignados',
  deallocated2: 'Desasignado|Desasignados',
  unassigned: 'no asignado|no asignados',
  replicated: 'replicado|replicada',
  not_assigned: '¡Sin asignar...!',
  not_found: 'No encontrado|no encontrado|No encontrada|no encontrada',
  preview: 'Vista previa',
  birthday: 'Fecha de Nacimiento',
  address: 'Dirección|Direcciones',
  phone: 'Teléfono',
  cellphone: 'Celular',
  image: 'imagen',
  yes: 'Sí|SI|sí',
  no: 'No|NO|no',
  attach: 'Adjuntar',
  personal_info: 'Información Personal',
  business_info: 'Información Empresarial',
  work_info: 'Información Laboral',
  social_net_info: 'Información de Redes Sociales',
  of: 'De',
  of2: 'del',
  and: 'y',
  or: 'o',
  to: 'a|al',
  by: 'Por|por|por',
  in: 'En|en',
  both: 'Ambos',
  request_field: 'Debe introducir los datos solicitados',
  example: 'p.ej.',
  activate: 'Activar',
  activated: 'Activado|activado|activada',
  deactivate: 'Desactivar',
  reactivate: 'Reactivar',
  reset: 'Reestablecer',
  process: 'Procesar',
  deactivated: 'Desactivado|desactivado|desactivada',
  enabled: 'Habilitado|habilitado|habilitada',
  disabled: 'Deshabilitado|deshabilitado|deshabilitada',
  network_error: 'Error de Red',
  try_again: '¡Error! Intente nuevamente',
  avatar: 'Avatar',
  the: 'La|El|la|el',
  the_2: 'Los|Las|los|las',
  service_does_not_exist: 'El servicio no existe',
  quantity: 'Cantidad',
  amount: 'Monto|Montos',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  municipality: 'Municipio',
  parish: 'Parroquia',
  sector: 'Sector',
  content: 'Contenido',
  type: 'Tipo',
  show_more: 'Ver Más',
  date: 'Fecha',
  notice: 'Aviso',
  attached: 'Agregados|Agregadas|agregados|agregadas',
  were: 'Fueron|fueron',
  load: 'carga|Cargar|cargar',
  loaded: 'cargado|cargada',
  replace: 'Reemplazar|reemplazar',
  replaced: 'Reemplazado|Reemplazada|reemplazado|reemplazada',
  clean: 'Limpiar',
  filter: 'Filtro',
  welcome: 'Bienvenido|Bienvenidos',
  update: 'Actualizar',
  expand: 'Expandir',
  from_to: 'Desde {start} hasta {end}',
  please_wait: 'Espere un momento por favor mientras carga la página',
  lack_of_settings: 'No ha configurado credenciales para el envío de correo',
  incomplete_message: 'Falta contenido del correo electrónico',
  email_auto_verification: 'Auto verificación de correos',
  email_auto_verification_msg: 'Al habilitar esta opción, todos los registros de correo electrónico que requieran envío de mensaje de verificación (p.ej.: Usuarios), se insertarán en BD con el campo <i>\'email_verified_at\'</i> relleno con la misma fecha de registro. Los emails de verificación <b>NO</b> serán enviados.',
  prorated_auto_month: 'Fracción de mensualidad automática',
  prorated_auto_month_msg: '¿Generar fracción automática de mensualidad?|Al habilitar esta opción, una vez registrada la Instalación de un Servicio, se generará una Nota de Cobro con el monto fraccionado del costo de la mensualidad, según el número de días restantes en el mes.',
  dear: 'Estimado|Estimada|Estimados|Estimadas',
  clients: 'cliente|clientes',
  wallet: 'Cartera',
  replicate: 'Replicar',
  control_numbers: 'números de control',
  betw: 'los números de control entre {start} y {end}',
  printable: 'Imprimible',
  readonly: 'Sólo lectura',
  all: 'todo|toda|todos|todas',
  locations: 'Ubicaciones',
  start_date: "Fecha Inicio",
  end_date: "Fecha Fin",
  instalation:"Instalación|Instalaciones",
  corporate_identity:'Identidad Corporativa',
  force_state:'Forzar estado',
  debt: 'Deuda|Deudas',
  validation: {
    locale: es_veeValidate.code,
    name: es_veeValidate.code,
    code: es_veeValidate.code,
    attributes: es_veeValidate.attributes,
    names: es_veeValidate.names,
    messages: es_veeValidate.messages
  },
  error422: error422_es,
  base: {
    none: 'Ninguno|Ninguna',
    configuration: 'Configuración',
    welcome_to: 'Bienvenido a',
    condensate_menu: 'Condensar Menú',
    code: 'Código',
    restore_menu: 'Restaurar Menú',
    hide_menu: 'Ocultar Menú',
    show_menu: 'Mostrar Menú',
    send: 'Enviar',
    save: 'Guardar',
    complete: 'Completar',
    approve: 'Aprobar',
    deny: 'Negar',
    sign_in: 'Iniciar sesión',
    change: 'Cambiar',
    changes: 'Cambios',
    cancel: 'Cancelar',
    login: 'Ingresar',
    register: 'Registro|Registrar',
    name: 'Nombre',
    email: 'Correo electrónico',
    features: 'Características',
    forgot: 'Recuperar',
    confirm: 'Confirmar',
    password: 'Contraseña',
    pwd: '{0} contraseña',
    remember: 'Recuérdame',
    goto: 'Ir a',
    success_auth: '¡Usuario autenticado exitosamente!',
    logout: 'Cerrar Sesión',
    confirm_logout: '¿Está seguro de Cerrar Sesión?',
    logged_out: '* ¡Usuario desconectado exitosamente!',
    something_wrong: '¡Algo salió mal! Intente nuevamente',
    leastOneConcept: 'Un documento debe tener al menos un concepto tipo débito',
    success_reg: '¡Registro exitoso. Bienvenido!',
    lng_changed_to: 'Idioma cambiado a: {0}',
    this_lang: '<b>Español</b>',
    about_page: 'Acerca de...',
    error_401: 'No autorizado',
    error_403: 'No tienes permisos',
    error_404: 'Página No Encontrada',
    error_500: 'Error del Servidor',
    error_503: 'Modo Mantenimiento',
    password_recovery: 'Recuperar Contraseña',
    password_reset: 'Reestablecer Contraseña',
    wait: 'Espere',
    loading: 'Cargando',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    select: 'Seleccione',
    selected: 'Seleccionado|Seleccionados',
    a: 'una',
    edit: 'Editar',
    add: 'Agregar|agregar',
    delete: 'Eliminar',
    // Inicio - Confirmaciones
    confirm_delete: '¿Está seguro de {action}: {record}?',
    confirm_reset: `<span> ¿Está seguro de <b>{action}</b> los números de control de la <b style="text-transform: uppercase;">{record}</b> seleccionada? </span>`,
    confirm_completed: 'Al presionar el botón "Confirmar", no podrá editar la información ingresada en las pestañas.<br><br>¿Desea completar la Solicitud?',
    confirm_discard: 'Al presionar el botón "Confirmar", el proceso de Solicitud se detendrá y no podrá ser reanudado.<br><br>¿Desea descartar la Solicitud?',
    confirm_trial: 'Al presionar el botón "Confirmar" iniciará el período de prueba desde el {start} hasta el {end}.<br><br>¿Desea confirmar el inicio del período de prueba?',
    confirm_default: '¿Está seguro de establecer la empresa <b>"{company}"</b> como acceso predeterminado al Usuario: <b>{user}</b>?<br><br><i class="fas fa-exclamation-triangle"></i> Esta acción no se puede deshacer',
    confirm_feasible: 'Al presionar el botón "Confirmar", no podrá continuar editando la información ingresada del Informe de Factibilidad.<br><br>¿Desea aprobar la factibilidad de la Solicitud de Servicio?',
    confirm_infeasible: 'Al presionar el botón "Confirmar", se marcará la Solicitud de Servicio como <b>No Factible</b>, y no podrá continuar editando la información ingresada en el Informe de Factibilidad.<br><br>¿Desea negar la factibilidad de la Solicitud de Servicio?',
    confirm_send_acceptance_letter: 'Antes de presionar el botón "Confirmar", debe estar seguro de que el Prospecto de Abonado <b>aceptó</b> la Cotización y la Propuesta Comercial de Servicio.<br><br>¿Desea <b>enviar</b> la Carta de Aceptación?',
    confirm_config_serv: 'Al presionar el botón "Confirmar", no podrá continuar editando la información ingresada.<br><br>¿Desea aprobar las Configuraciones?',
    confirm_installation : '¿Desea confirmar la instalación del servicio?',
    confirm_send_install: 'Al presionar el botón "Confirmar" finalizará el período de prueba y se enviará al abonado un correo con la carta de aceptación.<br><br>¿Desea <b>enviar</b> la Carta de Aceptación?',
    confirm_approved_installation: 'Al presionar el botón "Confirmar" culminará el proceso de la Solicitud.<br><br>¿Desea <b>iniciar</b> el periodo de Facturación Regular?',
    confirm_subscriber_migration: 'Al presionar el botón "Confirmar" culminará el Registro del Abonado.<br><br>¿Desea <b>completar</b> el registro?',
    // Fin - Confirmaciones
    new: 'Nuevo|Nuevos|Nueva|Nuevas',
    continue: 'Continuar',
    next: 'Siguiente',
    prev: 'Anterior',
    search: 'Buscar',
    accept: 'Aceptar',
    view_details: 'Ver Detalles',
    hide_details: 'Ocultar Detalles',
    discard: 'Descartar',
    notification: 'Notificaciones|Notificación',
    // Tipos de Notificación
    info: 'Información',
    success: 'Éxito',
    warning: 'Advertencia',
    error: 'Error',
    alert: 'Error',
    tip: 'Normal',
    access: 'Acceso|Accesos',
    default_access: 'Acceso Predeterminado|Acceso Predeterminado: {0}',
    pay: 'Pagar',
    close: 'Cerrar',
    override: 'Anular',
    update: 'Actualizar',
    upgrade: 'Mejora',
    message: 'Mensaje',
    help: 'Ayuda',
    broadcasted: 'Notificación enviada a: <b>{0}</b> usuario|Notificación enviada a: <b>{0}</b> usuarios'
  },
  emailverify: {
    unverified: 'Para activar su cuenta, su dirección de correo electrónico debe ser verificada.<br><br>Se ha enviado un correo a su dirección de correo registrada. Haga clic en el botón <b>Verificar Correo</b>.<br><br>Si no puede encontrar el mensaje en su Bandeja de Entrada, verifique su carpeta de Spam, o haga clic aquí para reenviar: ',
    success: 'Su correo ha sido verificado exitosamente',
    expired: 'La URL de verificación ha caducado.<br>{0}|Su dirección de correo electrónico no pudo ser verificada.<br>{0}',
    request_resend: 'Ingresa para solicitar un nuevo mensaje de verificación',
    request_resend_logged: 'Haga clic aquí para solicitar un nuevo mensaje de verificación: ',
    resend: 'Reenviar',
    already_verified_email: 'La dirección de correo electrónico ya ha sido verificada',
    notification_resubmitted: 'El mensaje de verificación de correo ha sido reenviado',
    not_verified: 'Debe verificar su correo electrónico'
  },
  feasibility:{
    identification: 'Identificación',
    contracts: 'Contratos',
    active_reports: 'Informes Activos',
    closed_reports: 'Informes Cerrados',
    version: 'Versión',
    elaboration_by: 'Elaborado por',
    elaboration_date: 'Fecha de elaboración',
    inspection_staff: 'Personal de inspección',
    inspection_date: 'Fecha de inspección',
    objective: 'Objetivo',
    feasible: 'Factible',
    infeasible: 'Infactible',
    observation: 'Observación'
  },
  password_reset: {
    reset_link_sent: 'Enlace de restablecimiento de contraseña enviado',
    invalid_token: 'El token de restablecimiento de contraseña es inválido',
    token_expired: 'El token de restablecimiento de contraseña ha caducado',
    email_not_found: 'El correo electrónico no está registrado',
    re_request: 'Puede volver a solicitar la recuperación de su contraseña, haciendo clic aquí: ',
    succeeded: 'Contraseña restablecida! Ahora puede iniciar sesión'
  },
  DSB: {
    welcome: 'Bienvenido al DSB',
    settings: 'Ajustes',
    logout: 'Salir',
    subheader: {
      administrative: 'Administrativo',
      operative: 'Operativo',
      gral_config: 'Configuración General',
      taxes: 'Impuestos',
      bank_account:'Cuentas Bancarias',
    },
    menu: {
      dashboard: 'Tablero',
      account: 'Cuenta',
      about: 'Acerca de',
      night_mode: 'Modo nocturno',
      turn_on: 'Activar',
      turn_off: 'Desactivar',
      test: 'Prueba',
      company: 'Empresa',
      type_client: 'Tipo de Cliente',
      subscribers: 'Abonados',
      all_subscribers: 'Todos los Abonados',
      filter_subscribers: 'Contratos x Abonado',//Filtrar Abonados
      migrate_subscriber: 'Migrar Abonado',
      requests: 'Solicitudes',
      requestsHistory: 'Historial de Solicitudes',
      feasibilityreport: 'Informe de Factibilidad',
      billing: 'Facturación',
      management: 'Gestión',
      contracts_per_service: 'Contratos x Servicio',
      amounts_per_service: 'Montos x Servicio',
      installs_per_date: 'Instalaciones x Fecha|Instalaciones por Fecha',
      debt_per_subscriber: 'Deuda x Abonado',
      adjustment_motives: 'Motivos de Ajuste',
    },
    profile: {
      profile: 'Perfil',
      security: 'Seguridad',
      current_password: 'Contraseña actual',
      new: 'Nueva',
      confirm_new: 'Confirmar nueva',
      current_equal_new: 'La contraseña nueva debe ser diferente de la actual',
      nothing_to_change: 'No hay nada que cambiar'
    }
  },
  company: {
    active: 'Activa',
    inactive: 'Inactiva',
    basic_data: 'Datos Básicos',
    legal_data: 'Datos Legales',
    geographical_information: 'Información Geográfica',
    system_administrator: 'Administrador del Sistema',
    social_networking: 'Redes Sociales',
    register: 'Registrar Empresa',
    update: 'Actualizar Empresa',
    confirm_delete: 'Está seguro de eliminar esta Empresa?',
    is_active: 'Activar',
    company: 'Empresas|Empresa',
    new: 'Nueva Empresa',
    name_company: 'Nombre de la Empresa',
    identification: 'ID o RIF',
    object_company: 'Objeto de la Empresa',
    country: 'País',
    state: 'Estado',
    name_institution_registration: 'Nombre de la Institución de Registro',
    register_number: 'Número de Registro',
    volume: 'Tomo',
    folio: 'Folio(s)',
    date_register: 'Fecha de Registro',
    legal_representative: 'Representante Legal',
    firm: 'Firma Manuscrita',
    fiscal_address: 'Dirección Fiscal',
    latitude: 'Latitud (Coordenadas Decimales)',
    longitude: 'Longitud (Coordenadas Decimales)',
    head_logo: 'Logo de Sistema',
    printable_logo: 'Logo Imprimible',
    telephone: 'Teléfono',
    email: 'Correo Electrónico',
    seal: 'Sello',
    name: 'Nombre de Usuario del Administrador',
    dbname: 'Nombre de la Base de Datos',
    email_admin: 'Correo Electrónico del Administrador',
    password: 'Contraseña del Administrador',
    primary_color: 'Color Principal',
    secondary_color: 'Color Secundario',
    default_access_company: 'Empresa de acceso predeterminado',
    default_company_set_to: 'Acceso predeterminado establecido a: {0}',
    no_default_selected: 'No ha seleccionado ninguna Empresa'
  },
  service_sale:{
    service_sale: 'Venta de Servicio',
    acceptance_letter: 'Carta de Aceptación',
    considerations: 'Consideraciones',
    quotation: 'Cotización',
    proposal: 'Propuesta|Propuesta de Servicio',
    awaiting: 'Solicitudes pendientes',
    approve: 'Solicitudes esperando por Aceptación',
    sent: 'Carta de Aceptación enviada',
    send_to_client: 'Enviar al Abonado'
  },
  service_install:{
    service_installation: 'Instalación de Servicio',
    pending: 'Servicios para Instalar',
    activation_pending: 'Servicios para Activar',
    test: 'En Periodo de Prueba',
    letter_sent: 'Carta Enviada',
    installed: 'Por Iniciar Pruebas',
    materialConfig: 'Config. de Material|Configuración del Material {name}',
    serviceConfig: 'Config. de Servicio|Configuración del Servicio {name}|Configuraciones del Servicio {name}',
    lanConfig: 'Config. LAN|Configuración LAN para {name}',
    new: 'Nueva {service}',
    installation_done: 'Instalación realizada',
    config_done: 'Configuración finalizada',
    init_date: 'Inicio del período de prueba',
    expasion_date: 'Ampliar el período de pruebas',
    end_date: 'Fin del período de prueba',
    start_trial: 'Iniciar el período de prueba',
    sent: 'Enviar Carta de Aceptación',
    approve: 'Aprobado por el cliente',
    installation_order: 'Orden de Instalación',
    missing_config: 'Configuración de Servicio incompleta',
    awaiting_config: 'En espera de configuración por parte de la Empresa',
    installation_date: 'Fecha de instalación',
    billing_starts_at: 'Comienza a facturar el',
    billing_start: 'fecha'
  },
  franchise: {
    contractor: 'Unidades de Negocio|Unidad de Negocio',
    active: 'Activa|Activas',
    customers: 'Clientes',
    inactive: 'Inactiva|Inactivas',
    basic_data: 'Datos Básicos',
    legal_data: 'Datos Legales',
    geographical_information: 'Información Geográfica',
    system_administrator: 'Administrador de Sistema',
    social_networking: 'Redes Sociales',
    register: 'Registrar Unidad de Negocio',
    update: 'Actualizar Unidad de Negocio',
    confirm_delete: 'Está seguro de eliminar esta Unidad de Negocio?',
    is_active: 'Activar',
    company: 'Unidades de Negocio|Unidad de Negocio',
    new: 'Nueva Unidad de Negocio',
    name_company: 'Nombre de la Unidad de Negocio',
    identification: 'ID o RIF',
    object_company: 'Objeto de la Unidad de Negocio',
    country: 'País',
    state: 'Estado',
    name_institution_registration: 'Nombre de la Institución de Registro',
    register_number: 'Número de Registro',
    volume: 'Tomo',
    folio: 'Folio',
    date_register: 'Fecha de Registro',
    legal_representative: 'Representante Legal',
    firm: 'Firma Manuscrita',
    fiscal_address: 'Dirección Fiscal',
    latitude: 'Latitud (Coordenadas Decimales)',
    longitude: 'Longitud (Coordenadas Decimales)',
    telephone: 'Teléfono',
    email: 'Correo Electrónico',
    name: 'Nombre de Usuario del Administrador',
    email_admin: 'Correo Electrónico del Administrador',
    password: 'Contraseña del Administrador',
    default_access_company: 'Unidad de Negocio de acceso predeterminado',
    default_company_set_to: 'Acceso predeterminado establecido a: {0}',
    no_default_selected: 'No ha seleccionado ninguna Unidad de Negocio',
    total: 'Total|Totales',
    company_owned: 'Pertenece a la Empresa|Propia',
    branch_office: 'Sucursal'
  },
  concept: {
    concepts: 'Conceptos',
    name: 'Concepto|Conceptos',
    motive: 'Motivo',
    amount: 'Monto',
    quantity: 'Cantidad',
    start_date: 'Fecha de Inicio',
    finish_date: 'Fecha de Finalización',
    type: 'Tipo',
    types:{
      debit: 'Débito',
      credit: 'Crédito',
      discount: 'Descuento',
      annulment: 'Anulación',
      exoneration: 'Exoneración',
      retention: 'Retención',
    },
    tax: 'Impuesto',
    its_percentage: 'Es porcentaje',
    its_one_time: 'Es solo una vez',
    person_type: 'Tipo de Persona',
    client_type: 'Tipo de Cliente',
    service: 'Servicio',
    sector: 'Sector',
    additional_data: 'Datos Adicionales',
    applied_to: 'Aplicado A',
    error_saving: 'Error al guardar el concepto',
    amount_changing_alert: 'El concepto <b>{name}</b> esta vinculado a <b>{count}</b> documentos.<br>Al cambiar el monto <b>{symbol}{value}</b>, el monto total de Todos los documentos asociados a este concepto, será modificado.<br>Puede <b>{btn1}</b> concepto o <b>{btn2}</b> el monto y alterar todos los documentos relacionados a este Concepto.'
  },
  paymentNote:{
    address: 'Dirección',
    bill: 'Factura',
    billingPeriod: 'Período de facturación',
    concept: 'Concepto',
    count: 'Cuenta',
    dateIssue: 'Fecha de emisión',
    daysCredit: 'Días de crédito',
    description: 'Descripción',
    name: 'Nota de Cobro',
    numberDays: 'Número de días facturados',
    numberId: 'Número de cédula',
    numberSerial: 'Número de serie',
    payment: 'Abonos recibidos',
    priceUnit: 'Precio unitario',
    priceTotal: 'Precio Total',
    quantity: 'Cantidad',
    rowsPerPageText: 'Filas por página',
    subscriber: 'Abonado',
    taxbase: 'Base imponible',
    totalPay: 'Total a pagar',
    totalWithOutDiscount: 'Total sin descuentos',
    totalWithOutCredits: 'Total sin creditos',
    totalWithOutCP: 'Total sin abonos/pagos',
    totalWithOutRet: 'Total sin Retenciones',
    withholdingIVA: 'Retención de I.V.A.',
    withholdingISRL: 'Retención de I.S.R.L.',
    withholdingSocial: 'Retención aporte social',
    withholdingStamp: 'Retención timbre fiscal',
    year: 'Año',
  },
  typeClient: {
    action: 'Acción',
    create: 'Crear',
    cancel: 'Cancelar',
    company: 'Empresa',
    description: 'Descripción',
    client: 'Cliente',
    editTypeClient: 'Editar tipo de cliente',
    editSubTypeClient: 'Editar sub tipo de cliente',
    editFeature: 'Editar característica',
    estate: 'Estado',
    feature: 'Característica',
    is_active: 'Activo',
    is_franchise: 'Unidad de Negocio',
    features: 'Características',
    newType: 'Nuevo Tipo',
    newSubType: 'Nuevo Sub Tipo',
    newFeature: 'Nueva Característica',
    name: 'Nombre',
    save: 'Guardar',
    searchFeature: 'Buscar Característica',
    subTypeClient: 'Sub Tipo de Cliente',
    title: 'Tipos de Clientes',
    typeClient: 'Tipo de Cliente',
    no_types_assigned: 'Sin Tipos de Cliente Asignados'
  },
  feasibilityReport: {
    report: 'Informe',
    feasibility : 'Factibilidad',
    network: 'Informe de redes',
    fibra: 'Informe de fibra',
    title : 'Informe de Factibilidad',
    description: 'Descripción',
    action: 'Acción',
    save: 'Guardar',
    cancel: 'Cancelar',
    new: 'Nuevo Informe',
    version : 'Versión',
    image: 'Imagen',
    elaboration_by: 'Elaborado por',
    elaboration_date: 'Fecha de Elaboración',
    inspection_date: 'Fecha de inspección',
    inspection_staff: 'Personal de inspección',
    objective: 'Objetivo',
    observation: 'Observación',
    descriptive_memory: 'Memoria descriptiva',
    work_to_realize: 'Trabajo a realizar',
    title_report: 'Título',
    equipmentmaterials: 'Materiales o Equipos',
    equip:{
      'new': 'Nuevo Material/Equipo',
      'edit': 'Editar Material/Equipo',
      'delete': 'Eliminar Material/Equipo'
    },
    provider: 'Proveedor',
    quantity: 'Cantidad'
  },
  roles: {
    groups: 'Grupo|Grupos',
    roles: 'Rol|Roles',
    permissions: 'Permiso|Permisos',
    no_data: 'No hay registros en base de datos',
    individual_permissions: 'Permisos Individuales',
  },
  service: {
    service : 'Servicio|Servicios',
    serviceto: 'Servicio para el {model}: {value}|Servicios para el {model}: {value}',
    add: 'Añadir servicio',
    new: 'Nuevo servicio',
    current:'Servicio actual',
    edit: 'Editar servicio',
    delete: 'Eliminar servicio',
    select_service: 'Seleccionar servicio',
    attributes: 'Especificaciones del servicio|Especificación de servicio',
    new_attribute: 'Nueva especificación',
    edit_attribute: 'Editar especificación',
    delete_attribute: 'Eliminar especificación',
    params: 'Parámetros|Parámetro',
    paramto: 'Parámetros para {serv}',
    new_param: 'Nuevo parámetro',
    edit_param: 'Editar parámetro',
    delete_param: 'Eliminar parámetro',
    select: 'Selección',
    config: 'Configuración del Servicio {service}',
    no_configs: 'Para activar este Servicio, primero debe añadirle configuraciones',
    no_configs_preloaded: 'El Servicio no tiene configuraciones precargadas',
    // Estatuses
    draft: 'Borrador',
    service_request: 'Servicio Solicitado',
    feasible_service: 'Factible',
    infeasible_service: 'Infactible',
    awaiting_acceptance: 'Espera de Aceptación',
    generate_charge_note: 'Por Generar Nota de Cobro',
    payment_pending: 'Pendiente de Pago',
    awaiting_installation: 'Espera de Instalación',
    installed: 'Instalado',
    active: 'Activo',
    inactive: 'Inactivo',
    migrating: 'Borrador (M)',
    // Otro
    service_details: 'Detalles del Servicio',
    no_service_selected: 'No se han seleccionado Servicios',
    // Línea de Tiempo de Solicitud
    service_requested: 'Solicitud de Servicio',
    feasibility_report: 'Verificación de Factibilidad',
    service_sale: 'Venta de Servicio',
    billing: 'Facturación',
    installation: 'Instalación',
    // Status Disks
    pending: 'Pendiente',
    completed: 'Completado',
    in_progress: 'En Curso',
    warning: 'Advertencia',
    error: 'Error',
    configs: {
      // Groups
      general: 'Generales',
      eq_config: 'Config. de Equipo',
      eq_data: 'Datos del Equipo',
      extra: 'Adicionales',
      // Types
      free: 'Libre',
      alpha: 'Alfa Numérico',
      string: 'Cadena de Texto',
      integer: 'Número entero',
      float: 'Número Decimal',
      url: 'URL',
      ipv4: 'IPv4',
      ipv6: 'IPv6',
      block_ipv4: 'Bloque IPv4',
      block_ipv6: 'Bloque IPv6',
      segment_ipv4: 'Segmento IPv4',
      segment_ipv6: 'Segmento IPv6',
    },
    contract_statuses: 'Estados del Contrato',
    service_type:'Tipo de servicio',
    service_types:'Tipos de servicio',
    statuses: {
      draft: 'Borrador',
      requested: 'Solicitado',
      activation_in_progress: 'Activación en Progreso',
      trial_period: 'Periodo de Prueba',
      active: 'Activo',
      voluntary_suspension_in_progress: 'Suspensión Voluntaria en Progreso',
      suspension_by_cut_in_progress: 'Suspensión por Corte en Progreso',
      retired: 'Retirado',
      voluntarily_suspended: 'Suspendido Voluntariamente',
      suspended_by_cut: 'Suspendido por Corte'
    }
  },
  package:{
    package: 'Paquetes|Paquete',
    new: 'Nuevo paquete',
    edit: 'Editar paquete',
    delete: 'Eliminar paquete',
    no_service: 'Para activar este paquete, primero debe añadirle servicios',
    package_does_not_exist: 'El paquete no existe',
    package_config_does_not_exist: 'La config. del paquete no existe',
    package_added_for_contract: '¡El paquete fue añadido con éxito!',
    service_added_for_contract: '¡El servicio fue añadido con éxito!',
    package_already_exists: 'El paquete ya ha sido agregado',
    service_already_exists: 'El servicio ya ha sido agregado',
    no_packages_assigned: 'Sin servicios asignados',
    current:'Paquete actual',
  },
  actor: {
    actor: 'Usuario|Usuarios',
    position: 'Cargo|Cargos',
    sign: 'Firma',
    seal: 'Sello',
    personal_mail: 'Correo Personal',
    no_actors_found: 'No se encontraron Usuarios',
    avatar: 'La imagen del Usuario',
  },
  system: {
    currency: 'Moneda',
    preset_currency: 'Moneda Preestablecida',
    currency_code: 'Código de Moneda',
    base_currency: 'Moneda Base del Sistema',
    set_currency: 'Establecer',
    symbol: 'Símbolo',
    icon: 'Icono',
    currency_stablished: 'Moneda Base Establecida',
    exchange_rate: 'Tasa de Cambio|Tasas de Cambio',
    new_rate: 'Nueva Tasa',
    edit_rate: 'Editar Tasa',
    // url_optional: 'La URL es opcional',
    must_set_base_currency: 'No se ha establecido la Moneda Base',
    equal_base: 'Equivalente a 1 ',
    base_currency_notice: 'Una vez que haya establecido la <b>Moneda Base</b> del Sistema, no podrá cambiarla ni modificarla.<br><br> ¿Está seguro de que desea establecer <b>{0} ({1})</b> como la Moneda base?',
    decimal_places: 'Lugares Decimales',
    add_decimal_place: 'Agregar Lugar Decimal',
    remove_decimal_place: 'Quitar Lugar Decimal',
    global_billing_mode: 'Modo de Facturación del Sistema',
    global_billing_mode_msg: 'Cambiar modo de Facturación del Sistema|Al seleccionar la opción de facturación <b>Interna</b> (Int.), el sistema ejecutará automáticamente el proceso de facturación cada mes.<br><br>Al seleccionar la opción de facturación <b>Externa</b> (Ext.), el sistema <u>NO</u> generará automáticamente las Notas de Cobro de mensualidad. Tendrá que depender de un sistema externo para esta tarea.',
    global_subscriber_wallet_mode_msg: 'Al habilitar esta opción, se activa una <b>Billetera</b> para cada Abonado, y su saldo respectivo se mostrará en la ficha del Abonado, donde también se podrán visualizar los movimientos de la misma.<br><br>Con esta opción habilitada, el proceso de <i>Facturación Mensual</i> verificará el saldo de cada Abonado y de ser suficiente para cubrir el monto de la <u>mensualidad</u> de sus Servicios, este será <b>descontado del Saldo</b> y se generará la <u>Factura</u> correspondiente.',
    // Nombres de Monedas
    pab: 'Balboa',
    btc: 'Bitcoin',
    ves: 'Bolívar Soberano',
    bob: 'Boliviano',
    usd: 'Dólar Estadounidense',
    cad: 'Dólar Canadiense',
    eur: 'Euro',
    uah: 'Grivna Ucraniano',
    pyg: 'Guaraní',
    gbp: 'Libra Esterlina',
    itl: 'Lira Italiana',
    ils: 'Nuevo Séquel Israelí',
    ars: 'Peso Argentino',
    clp: 'Peso Chileno',
    cop: 'Peso Colombiano',
    cup: 'Peso Cubano',
    dop: 'Peso Dominicano',
    mxn: 'Peso Mexicano',
    uyu: 'Peso Uruguayo',
    ptr: 'Petro',
    brl: 'Real Brasileño',
    inr: 'Rupia India',
    rub: 'Rublo Ruso',
    pen: 'Sol Peruano',
    kzt: 'Tenge Kazajo',
    krw: 'Won Surcoreano',
    cny: 'Yuan Chino',
    jpy: 'Yen Japonés',
  },
  history_log: {
    date: 'Fecha',
    rate: 'Tasa',
    exchange_rate_created: 'Tasa de cambio creada',
    exchange_rate_deleted: 'Tasa de cambio eliminada',
    exchange_rate_restored: 'Tasa de cambio restaurada',
    exchange_rate_updated: 'Tasa de cambio actualizada',
    unauthorized_update: 'Intento de act. no autorizado',
    wan_config: 'Configuraciones de servicio',
    // old: 'Antes',
    // attributes : 'Despues',
    before: 'Antes',
    after: 'Después',
    price: 'Precio',
    contract_id: 'Contrato id',
    service_id: 'Servicio id',
    package_id: 'Paquete id',
    qty: 'Cantidad',
    contract_service_updated: 'Contrato de servicio actualizado',
    contract_service_created: 'Contrato de servicio creado',
    // status
    status: 'Estado del Servicio',
    old_status: 'Estado Anterior del Servicio',
    draft: 'Borrador',
    requested: 'Solicitado',
    activation_in_progress: 'Activación en Progreso',
    trial_period: 'Periodo de Prueba',
    active: 'Activo',
    voluntary_suspension_in_progress: 'Suspensión Voluntaria en Progreso',
    suspension_by_cut_in_progress: 'Suspensión por Corte en Progreso',
    retired: 'Retirado',
    voluntarily_suspended: 'Suspendido Voluntariamente',
    suspended_by_cut: 'Suspendido por Corte',
    motive: 'Motivo',
    motive_details: 'Detalles de Motivo'
  },
  file_library: {
    file: 'Archivo|Archivos',
    service_proposal: 'Propuesta de Servicio',
    feasibility_order: 'Orden de Verificación de Factibilidad de Servicio',
    inspection_order: 'Orden de Inspección',
    installation_order: 'Orden de Instalación',
    price_quotation: 'Cotización',
    invoice: 'Factura',
    invoice_sendable: 'Factura',
    tax_bill: 'Factura Fiscal',
    bill: 'Recibo',
    payment_note: 'Nota de Cobro',
    credit_note: 'Nota de Crédito',
    acceptance_letter: 'Carta de Aceptación',
    signed_acceptance_letter: 'Carta de Aceptación Firmada',
    service_equipment_delivery_certificate: 'Acta de Entrega de Servicios y Equipos',
    service_equipment_acceptance_letter: 'Carta de Aceptación de Servicios y Equipos',
    signed_service_equipment_acceptance_letter: 'Carta de Aceptación de Servicios y Equipos Firmada',
    dni: 'Documento de Identidad',
    rif: 'Registro de Información Fiscal',
    legal_representative_dni: 'C.I. del Representante Legal',
    legal_representative_rif: 'RIF del Representante Legal',
    legal_person_rif: 'RIF de la Persona Jurídica',
    legal_person_registration_document: 'Documento de Registro de la Persona Jurídica',
    last_registration_modification: 'Última modificación del Registro',
    assembly_minute: 'Última Acta de Asamblea',
    documentation_warning: 'Tenga en cuenta que hasta que no realice la Carga de la Documentación correspondiente, el estado del Abonado no cambiará de Abonado Prospecto a Abonado Activo.',
  },
  subscribers: {
    admin: 'Admin.',
    tech: 'Técnica|Técnico',
    address: 'Dirección',
    payment: 'de Pago',
    contact: 'Contacto|Contactos',
    info: 'Información',
    type_client: 'Tipo de Abonado',
    subscribers: 'Abonado|Abonados',
    prospect: 'Prospecto|Prospectos',
    retired: 'Retirado|Retirados|Retirados Definitivamente',
    retire_permanently: 'Retirar Definitivamente',
    reverted: 'Revertido|Revertidos|Revertido a Inactivo',
    revert_to_inactive: 'Revertir a Inactivo',
    subscriber_prospects: 'Prospectos de Abonado',// Respuesta 404 desde backend
    active_subscribers: 'Abonados Activos',// Respuesta 404 desde backend
    inactive_subscribers: 'Abonados Inactivos',// Respuesta 404 desde backend
    prefix: 'Prefijo|Prefijos',
    dni: 'Cédula|RIF|ID o RIF',
    is_firm: 'Firma Personal',
    firm_name: 'Nombre de la Firma Personal',
    first_name: 'Nombre',
    last_name: 'Apellido',
    legal_denomination: 'Razón Social',
    gender: 'Género',
    birthdate: 'Fecha de Nacimiento|Fecha Nac.',
    agent_ret: 'Agente de Ret.',
    tax_rate: 'Tasa I.V.A.',
    tax_sale: 'Tasa I.S.R.L.',
    contact_medium: 'Medio de Contacto',
    contact_mediums: {
      whatsapp: 'WHATSAPP',
      call: 'LLAMADA',
      email: 'EMAIL',
      others: 'OTROS'
    },
    observations: 'Observaciones',
    status: 'Condición',
    subscriber_details: 'Detalles del Abonado',
    person_type: 'Tipo de Persona',
    natural: 'Natural|Natural|Natural',
    legal: 'Jurídica|Jurídico|Legal',
    is_prospect: 'Es Prospecto?',
    created_at: 'Registrado',
    updated_at: 'Última act.',
    avatar: 'La imagen de Abonado',
    incomplete_request: '¡El registro no se ha completado!',
    subscriber_already_exists: 'Abonado Existente|Ya existe un Abonado registrado con el número de identificación ingresado: <b>{0}</b>.<br><br>¿Desea crearle una nueva Solicitud de Servicio en una nueva Ubicación a este Abonado?',
    subscriber_code_already_exists: 'Número de Abonado Existente|El número de Abonado ingresado ya existe: <b>{0}</b>.<br><br>¿Desea registrale un nuevo Servicio en una nueva Ubicación a este Abonado?',
    verify_subscriber_information: 'Verifique la información antes de continuar',
    name: 'Nombre de abonado',
    credit_days: 'Días de Crédito',
    documentation: 'Documentación',
    condition_changed: 'La condición del Abonado ha cambiado de Prospecto a Activo',
    code: 'Número de Abonado|# Abonado',
    dues:'Cuotas|cuotas',
    subscriber_debt:'Deuda del abonado|Deuda de los abonados',
    overdue_debt:'Deuda Vencida',
    court_candidates:'Candidatos a corte',
    corporate:'Corporativo',
    total_debt_contract:'Deuda total del contrato',
    groups: 'Grupos de Abonados',
    wallet: 'Billetera',
    balance: 'Saldo'
  },
  contracts: {
    id: 'Número de Contrato',
    contracts: 'Contrato|Contratos',
    city: 'Ciudad',
    municipality: 'Municipio',
    parish: 'Parroquia',
    tower: 'TORRE|Torre',
    ref_point: 'Punto Ref.',
    coordinates: 'Coordenada|Coordenadas',
    no_contracts: 'El Abonado no posee Contratos registrados',
    tech_address_error: 'Dirección Técnica no encontrada',
    same_as_previous: 'Igual al Anterior|Igual a la Anterior',
    assigned: 'Contrato asignado|Contratos asignados',
    unassigned: 'Contrato no asignado|Contratos no asignados',
    deny_contracts: 'Denegar contratos',
    deny: 'Denegar',
  },
  requests: {
    requests: 'Solicitud|Solicitudes',
    expansion: 'Ampliación|Ampliaciones',
    reduction: 'Reducción|Reducciones',
    repair: 'Reparación|Reparaciones',
    voluntary_suspension: 'Suspensión Voluntaria|Suspensiones Voluntarias',
    completed: 'Completada|Completadas',
    no_requests_found: 'No se encontraron Solicitudes',
    discarded: 'Descartada|Descartadas',
    status: 'Estado de la Solicitud',
    history: 'Historial de la Solicitud',
    unrepeatable_info: 'Los datos de <i>Número de Celular</i> y <i>Correo Electrónico</i> no deben ser iguales para los Contactos <i>Técnico</i>, <i>Administrativo</i> y <i>Legal</i>',
    requires_inspection: '¿Esta Solicitud requiere Inspección?'
  },
  inspection: {
    inspection: 'Inspecciones|Inspección',
    awaiting: 'Pendiente|Pendientes',
    closed: 'Cerrada|Cerradas',
    confirm_feasible: 'Al presionar el botón "Confirmar", no podrá continuar editando la información ingresada para la Inspección.<br><br>¿Desea aprobar la factibilidad de la Solicitud de Servicio?',
    confirm_infeasible: 'Al presionar el botón "Confirmar", se marcará la Solicitud de Servicio como <b>No Factible</b>, y no podrá continuar editando la información ingresada para la Inspección.<br><br>¿Desea negar la factibilidad de la Solicitud de Servicio?',
  },
  about_paragraph_1: 'La Plataforma de Gestión de Operaciones (PLATGO) es un software de aplicación perteneciente a VIGINET, C.A. que permite gestionar las operaciones frecuentes de la empresa con sus clientes corporativos y masivos.',
  about_paragraph_2: 'PLATGO es un software cuya propiedad exclusiva le corresponde a VIGINET, C.A. y ha sido desarrollado en su totalidad por la empresa Apushi Technology C.A.',
  about_paragraph_3: '©2021. Propiedad Intelectual de VIGINET, C.A. El logotipo, los contenidos y la información alojada en el sistema son propiedad exclusiva de la empresa VIGINET, C.A. y usted no podrá hacer uso de los mismos sin previa autorización de la propietaria.',
  document_control: {
    document: 'Documento',
    documents: 'Documentos',
    document_control: 'Control de Documentos',
    document_number: 'N° del documento',
    control_number: 'N° de control',
    serial_document: 'Serie del documento',
    initial_document_number: 'Inicio N° del documento',
    final_document_number: 'Final N° del documento',
    serial_control: 'Serie de control',
    initial_control_number: 'Inicio N° de control',
    final_control_number: 'Final N° de control',
    type: 'Tipo',
    franchise: 'Unidad de Negocio',
    actor: 'Usuario',
    types:{
      price_quotation: 'Cotización',
      invoice: 'Factura',
      bill: 'Recibo',
      payment_note: 'Nota de cobro',
      credit_note: 'Nota de crédito',
    },
    status:{
      pending: 'Pendiente',
      paid: 'Pagado',
      canceled: 'Cancelado',
      voided: 'Anulado',
      accepted: 'Aceptado',
      replicated: 'Replicada',
    },
    errors:{
      not_found_control_number: 'Número de control no encontrado',
      not_found_dni: 'Número de identificación del cliente no encontrado',
      not_found_concepts: 'Conceptos no encontrados, necesita agregar conceptos',
      not_found_contract: 'Contrato no encontrado, necesita seleccionar un contrato',
    },
    change_header: '¿Desea cambiar el número de control del documento?',
    change_dates: '¿Desea actualizar las fechas asociadas al documento?',
    alert: `Si selecciona 'Todos', no podrá crear ningun documento de este tipo`,
    alert2: 'A continuacion debe indicar los números de control que desea anular de la {type} seleccionada.',
    in_a_range: 'En un rango',
    only_one: 'Sólo uno',
  },
  billing:{
    create: 'Crear documento',
    invoice_number: 'Número de Factura',
    control_number: 'Número de control',
    control_series: 'Serie de control',
    description: 'Descripción',
    documents: 'Documentos de facturación',
    elaboration_date: 'Fecha de elaboración',
    history: 'Historial',
    name: 'Facturación',
    management: 'Gestión de facturación',
    state: 'Estado',
    status:{
      draft: 'Borrador',
      assigned: 'Asignada',
      accepted: 'Aceptada',
      pending: 'Pendiente',
      sended: 'Enviar',
      paid: 'Pagada',
      canceled: 'Cancelada',
      voided: 'Anulado',
      replicated: 'Replicada'
    },
    invoice_date: 'Fecha de la Factura',
    date_billind: 'Período de facturación',
    date_issue: 'Fecha de emisión',
    date_due: 'Fecha de vencimiento',
    date_cutoff: 'Fecha de corte',
    amount: 'Monto',
    tax_bill_rate_missing: 'Tasa fiscal no encontrada'
  },
  notification: {
    no_mail_settings: 'No se ha configurado el envío de correo',
    new_service_requested: 'Nuevo Servicio Solicitado',
    new_installation_order: 'Nueva Orden de Instalación',
    activation_request_from_franchise: 'Nueva Solicitud de Activación desde Unidad de Negocio',
    franchise_request_activated: 'Solicitud de Servicio Aprobada para Activación'
  },
  policy: Object.assign({
    impropely_named_policy: '* Permiso Nombrado Incorrectamente *',
    translation_not_found: '* Traducción no Encontrada *',
    add_actor: 'Agregar Actor',
    edit_actor: 'Editar Actor',
    add_subscriber: 'Agregar Abonado',
    view_policy: 'Ver Permisos',
    role_policy: 'Permisos del Rol',
    // actions
    add_equipment_materials: 'Añade el equipo y/o los materiales',
    acceptance_letter: 'Carta de aceptación',
    awaiting_request: 'Esperando solicitud',
    acceptance_letter_send: 'Envío de la carta de aceptación',
    billing: 'Facturación',
    billing_request: 'Solicitud de facturación',
    change_contracts_billing_type: 'Cambiar tipo de facturación de contratos',
    create : 'Crear',
    concepts: 'Conceptos',
    crud: 'Crear, Leer, Actualizar y Borrar',
    condition_changed: 'La condición del Abonado ha cambiado de Prospecto a Activo',
    commons_for_create_edit: 'Los puntos comunes para crear o editar',
    create_payment: 'Crear pago',
    dashboard: 'Tablero',
    delete: 'Borrar',
    documents: 'Documentos',
    document_search_by_id_or_legal_name: 'Búsqueda de documento por DNI o denominación legal',
    edit_Only_by_franchise_subscriber: 'Editar (Sólo por el suscriptor de la franquicia)',
    fiber_feasibility_reports: 'Informes de factibilidad de fibra',
    generate_payment_note: 'Generar nota de cobro',
    list: 'Listar',
    list_pendign_request: 'Listar: Solicitud pendiente',
    list_reports_active_closed: 'Listar: Informes activos / cerrados',
    network_feasibility_reports: 'Informes de factibilidad de red',
    new_control_number_to_create_document: 'Nuevo número de control para crear documento',
    management: 'Gestión',
    only_corporate_subscriber: 'Sólo suscriptor corporativo',
    only_franchise_subscriber: 'Sólo suscriptor de la franquicia',
    only_corporate_user_or_admin: 'Solo usuario corporativo o administrador',
    payment_reconciliation: 'Conciliación de pagos',
    pdf: 'PDF',
    request: 'Solicitud',
    report_approve: 'Informe: Aprobar',
    report_deny: 'Informe: Negar',
    requests: 'Solicitudes',
    request_approve: 'Solicitud de aprobación',
    replicated: 'Replicar',
    services_sale: 'Venta de servicios',
    service_proposal: 'Propuesta de servicio',
    service_instalation: 'Instalación de servicio',
    send_mail: 'Enviar correo',
    search_employee: 'Buscar Empleado',
    show: 'Mostrar',
    subscriber: 'Abonado',
    update: 'Actualizar',
    update_document: 'Actualizar documento',
    update_approve_service_installation_approve_start_of_the_trial_period: 'Actualización/Aprobar la instalación del servicio/Aprobar el inicio del período de prueba',
    voided: 'Anulado',
    restore: 'Restaurar',
  },permission_es),
  month: 'Mes',
  months:{
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre'
  },
  MaterialEquipment:{
    brand: 'Marca',
    description: 'Descripción',
    equipment: 'Equipo|Equipos',
    materials: 'Material|Materiales',
    measurement: 'Medición',
    measurement_unit: 'Unidad de Medida',
    model: 'Modelo',
    name: 'Nombre',
    names: 'Materiales y Equipos',
    serial: 'Serial',
    price: 'Precio',
    type:{
      equipment: 'Equipo',
      material: 'Material',
      type: 'Tipo',
    },
  },
  Taxes:{
    tax: 'Impuesto|Impuestos',
    name: 'Nombre',
    category: 'Categoría',
    value: 'Valor',
    unit: 'Unidad',
    default: 'Por defecto',
    lastUpdate: 'Última actualización',
    other_taxes: 'Otros Impuestos'
  },
  Payments:{
    reconciliation: 'Conciliación de pago',
    name: 'Pago|Pagos',
    invoice: 'Factura|Facturas',
    mode: 'Modo de pago',
    received: 'Recibido|Recibidos',
    payment_recieved: 'Pagos recibidos',
    type:{
      type: 'Tipo de pago',
      partial: 'Pago parcial',
      total: 'Pago Total',
      cash: 'Efectivo',
      transfer: 'Transferencia',
      card: 'Tarjeta',
      external: 'Externo'
    },
    cash_payment: 'Pago en efectivo',
    transfer_payment: 'Pago por transferencia',
    card_payment: 'Pago por tarjeta',
    amount: 'Monto',
    ref: 'N° Referencia',
    balance: 'Saldo',
    bank: 'Banco',
    payingTo: 'Pago a',
    payOf: 'Pago de',
    payment_method: 'Método de Pago',
    not_banks: 'No tienes bancos registrados para la Tasa de cambio seleccionada',
    pay_all: 'Al pagar completamente la nota de Cobro, se creara una FACTURA con los siguientes números de Control'
  },
  requestBills:{
    generate: 'Generar nota de cobro',
    waitingPayment: 'Esperando pago',
  },
  employees:{
    employee: 'Empleado|Empleada',
    employees: 'Empleados',
    first_name: 'Nombre',
    last_name: 'Apellido',
    firm_name: 'Nombre de la Firma Personal',
    legal_denomination: 'Razón social',
    person_type: 'Tipo de Persona',
    name: 'Nombre del Empleado',
    dni: 'Cédula',
    birthdate: 'Fecha de nacimiento',
    gender: 'Genero',
    type: 'Tipo',
    types:{
      collector: 'Cobrador|Cobradora',
      collection_analyst: 'Analista de Cobranza',
      seller: 'Vendedor|Vendedora',
      technician: 'Técnico'
    },
    email: 'Correo electrónico',
    local_phone: 'Teléfono',
    cellphone: 'Celular',
    status: 'Estado',
    active: 'Activo',
    franchise: 'Unidad de Negocio',
    set_employee: 'Asignar a Cobrador',
    payment_collector: 'Receptor del Pago',
  },
  humanResources:{
    humanResources: 'Recursos Humanos'
  },
  reports:{
    reports: 'Reportes',
    generate: 'Generar Reportes',
    report_type: 'Tipo de Reporte',
    ledger: 'Libro de Ventas',
    void_list: 'Listado de Anulaciones',
    billing_detail_by_concept: 'Detalle de Facturación por Concepto',
    general_income_by_date: 'Ingreso General por Fecha',
    general_income_by_collector: 'Ingreso General por Cobrador',
    general_income_by_concept: 'Resumen de Ingresos por Concepto',
    daily_income: 'Ingreso Diario',
    income_by_sector: 'Ingresos por Sector',
    daily_cash_register: 'Cuadre diario de Caja (Interno)',
    daily_cash_register_external: 'Cuadre diario de Caja (Externo)',
    income_by_concept_group_by: 'Ingresos agrupados por Servicio',
    collection_list: 'Listado de Cobranza',
    no_results: 'Sin resultados para: <b>{0}</b>, que coincidan con la búsqueda'
  },
  banks:{
    bank: 'Bancos',
    banks: 'Banco|Bancos',
    name: 'Nombre',
    currency_code: 'Código de Moneda',
    bank_account: 'Cuenta Bancaria|Cuentas Bancarias',
    national: 'Nacional|Nacionales',
    international: 'Internacional|Internacionales',
    account: 'Cuenta',
    mobile: 'Pago Móvil',
    account_holder_dni: 'C.I. o RIF del Titular',
    account_holder: 'Titular de la Cuenta',
    account_number: 'Número de Cuenta',
    account_type: 'Tipo de Cuenta',
    account_types: {
      savings: 'Ahorro',
      current: 'Corriente'
    },
    to_print_in_payment_note: 'Para imprimir en la Nota de Cobro',
  },
  paymentForm:{
    paymentForm: 'Forma de pago|forma de pago',
    types:{
      prepaid: 'Prepago',
      postpaid: 'Postpago',
    },
  },
  ErrorPage:{
    description_403: 'No tienes los permisos necesarios para continuar, comunícate con el administrador para mayor información.',
    description_404: '',
    description_503: 'Temporalmente el servicio no esta disponible, disculpe las molestias.',
  },
  sectors:{
    sectors: 'sector|sectores|Sectores',
    create_new_sector:'Crea tu nuevo sector',
    points_available:'Puntos disponibles',
    points_occupied:'Puntos ocupados',
    points_remaining:'Puntos restantes',
    minimum_sales_goal:'Objetivo de ventas mínimo',
    maximum_capacity_alert:'Alerta de capacidad máxima'
  },
  zones:{
    zone: 'zona|Zona',
    zones: 'zonas|Zonas',
  },
  table: {
    totals: 'Totales',
    contracts: 'Contratos Totales',
    massives: 'Masivos',
    corporate: 'Corporativos',
    seven_days: '7 Días',
    this_month: 'Este Mes',
    last_month: 'Mes Anterior',
    prev_last_month: 'Mes Previo al Anterior',
    sixty_days: '60 Días',
  },
  dashboard:{
    cards:{
      subscribers_active: 'Abonados activos',
      requests_in_progress: 'Solicitudes en curso',
      installations_completed: 'Instalaciones completadas',
      installations_pending: 'Instalaciones pendientes'
    }
  },
  identity_labels:{
    installations_completed:'Instalaciones completadas',
    installations_pending: 'Instalaciones pendientes',
    points_available:'Puntos disponibles',
    points_occupied:'Puntos ocupados',
    points_remaining:'Puntos restantes',
    subscribers_active: 'Abonados activos',
    requests_in_progress:'Solicitudes en curso',
  },
  money_received:{
    money_received: 'Dinero recibido',
    money_receive: 'Recibir dinero',
    reception_date: 'Fecha Recep.',
    reception_medium: 'Medio de recepción',
    profit_code: 'Código Profit',
    profit_number: 'Referencia Factura',
    details: 'Observaciones|Detalles',
    status: {
      pending: 'Pendiente',
      consolidated: 'Consolidado',
      processed: 'Procesado',
      rejected: 'Rechazado'
    },
    payment_methods: {
      cash: 'Efectivo',
      digital: 'Digital',
      mobile: 'Móvil',
      tdc: 'TDC',
      tdd: 'TDD',
      transfer: 'Transferencia'
    },
    sources: {
      call: 'Llamada',
      cds: 'CDS',
      email: 'Correo electrónico',
      office: 'Oficina',
      sms: 'SMS',
      telegram: 'Telegram',
      web_page: 'Página web',
      whatsapp: 'Whatsapp'
    },
    rejection_concept: 'Concepto de Rechazo',
    payment_support: 'Soporte de Pago'
  }
};
