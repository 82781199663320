export default {
  name:'dialconfig',
  data(){
    return {
      loading: false,
      unwatch: '',
      dialog: false,
      loadingSel: false,
      service: 0,
      rows: {
        inputs: [{
          'group': '',
          'name': '',
          'required': false,
          'type': '',
          'print': false,
          'is_readonly': false
        }]
      },
      groups: [
        {'value': 'general'},
        {'value': 'eq_config'},
        {'value': 'eq_data'},
        {'value': 'extra'},
      ],
      data_types: []
    }
  },
  computed:{
    //
  },
  watch:{
    //
  },
  methods: {
    open(serv){
      if (serv.configurations) {
        // Old way
        // this.rows.inputs = serv.configurations.slice();
        // ES6 way
        this.rows.inputs = [...serv.configurations]
      } else {
        this.rows.inputs = []
      }
      this.service = serv
      if (this.data_types.length == 0) {
        this.getConfigTypes()
      }
      if (this.rows.inputs.length == 0) {
        this.addRow()
      }
      this.dialog = true
    },
    close(){
      this.dialog = false
      this.service = 0
      this.rows.inputs = []
      this.$validator.reset();
    },
    addRow() {
      this.rows.inputs.push({ group: '', name: '', required: false, type: '', print: false, is_readonly: false })
    },
    removeRow(index) {
      this.rows.inputs.splice(index, 1)
    },
    getConfigTypes() {
      this.loadingSel = true
      axios.get(this.$store.getters.getServiceConfigTypes)
        .then(response => {
          this.data_types = response.data.types
        })
        .catch((err) => {
          this.data_types = []
        })
        .finally(() => {
          this.loadingSel = false
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid){
          this.loading = true
          this.service.configurations = this.rows.inputs
          this.service.is_configs = true
          axios.patch(this.$store.getters.getService(this.service.id), this.service)
            .then(response => {
              if (response.status == 200) {
                this.$emit('configSaved')
                this.$awn.success(
                  this.$tc('success2', 2,
                    {
                      model: this.$t('configs'),
                      action: this.$tc('saved',2)
                    }
                  )
                , {durations:{success: 4000}})
                this.close()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  },
  created(){
    /*this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after of confirmation
      if(val){
        //
      }
    })*/
  },
  beforeDestroy () {
    // this.unwatch()
  }
}
