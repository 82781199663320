var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11","lg":"8"}},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.taxes,"items-per-page":_vm.perPage,"footer-props":{
            'items-per-page-options': [5, 10, 50]
          },"loading":_vm.loadingTable,"sort-by":['updated_at'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('toolbarTable',{attrs:{"showNew":_vm.permissions.create,"disableNew":_vm.loadingTable,"title":_vm.$tc('Taxes.tax', 2),"nameButton":("" + (_vm.$tc('base.new', 0)))},on:{"newItem":_vm.newItem}})]},proxy:true},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format(_vm.$store.getters.getDateFormat[2]))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.permissions.edit)?_c('v-btn',_vm._g({attrs:{"icon":"","edit":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("base.edit")))])])]}}],null,true)})]],2)],1),(_vm.permissions.create || _vm.permissions.edit)?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","fullscreen":_vm.fullscreen,"max-width":"780px"},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"tile":""}},[_c('toolbarDialog',{attrs:{"editMode":_vm.edit,"numNew":3,"title":_vm.$tc('Taxes.tax', 1)}}),_c('v-card-text',[_c('v-container',{staticStyle:{"padding-top":"0 !important"},attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"form_taxes",attrs:{"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"taxes-form",attrs:{"data-vv-scope":"taxesForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('Taxes.name'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","error-messages":errors,"label":_vm.$t('Taxes.name')},model:{value:(_vm.tax.name),callback:function ($$v) {_vm.$set(_vm.tax, "name", $$v)},expression:"tax.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"value","name":_vm.$t('Taxes.value'),"rules":{
                      dontallowzero: _vm.dialog1
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('moneyfield',{ref:"moneyfield",attrs:{"name":"value","labelfield":"Taxes.value","nosymbol":"","error-messages":errors},model:{value:(_vm.tax.description.value),callback:function ($$v) {_vm.$set(_vm.tax.description, "value", $$v)},expression:"tax.description.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"name":"unit","label":_vm.$t('Taxes.unit')},model:{value:(_vm.tax.description.unit),callback:function ($$v) {_vm.$set(_vm.tax.description, "unit", $$v)},expression:"tax.description.unit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"category","name":_vm.$t('Taxes.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"category","items":_vm.computedTaxCategories,"label":_vm.$t('Taxes.category'),"error-messages":errors,"disabled":_vm.edit},model:{value:(_vm.tax.category),callback:function ($$v) {_vm.$set(_vm.tax, "category", $$v)},expression:"tax.category"}})]}}],null,true)})],1)],1)],1)]}}],null,false,1518611385)})],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),(!_vm.fullscreen)?_c('v-btn',{attrs:{"text":"","cancel":"","disabled":_vm.loading},on:{"click":_vm.close}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times")])],1):_vm._e(),_c('v-btn',{attrs:{"save":"","loading":_vm.loading},on:{"click":_vm.save}},[(!_vm.edit)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t("base.save"))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-save")])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$t("base.edit"))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])]],2)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }