export default {
  components: {},
  data() {
    return {
      dialog: false,
      editing: false,
      feature: {
        index: "",
        name: "",
        description: ""
      },
      type_client: {
        id: "",
        name: "",
        description: "",
        is_active: true
        // parent_id: null
      },
      perPage: 10,
      page: 1,
      totalValues: 0,
      loading: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    setFields() {
      this.feature.index = "";
      this.feature.name = "";
      this.feature.description = "";
      this.$emit("setFilter");
    },
    open(type_client) {
      this.setFields();
      this.type_client = type_client;
      this.editing = false;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.setFields();
    },
    saveFeature() {
      // eslint-disable-next-line no-console
      // console.log('Funcion saveFeature, feature:  ', this.feature );
      this.type_client.features =
        this.type_client.features == null
          ? []
          : JSON.parse(JSON.stringify(this.type_client.features));
      this.$refs.form_type_features.validate()
      .then(success => {
        if (success) {
          this.loading = true;
          if (this.feature.index === "" || this.feature.index === undefined) {
            this.feature.index = this.type_client.features.length;
            this.type_client.features.push(
              JSON.parse(JSON.stringify(this.feature))
            );
          } else {
            //Es de Vue, ayuda a no perder la cabeza con los arrays
            this.$set(
              this.type_client.features,
              this.feature.index,
              JSON.parse(JSON.stringify(this.feature))
            );
          }
          axios
            .put(
              this.$store.getters.getTypeClient(this.type_client.id),
              this.type_client
            )
            .then(response => {
              const text = this.$t("typeClient.feature");
              const action = this.editing ? "updated" : "created";
              this.pushNoty(text, action, 2);
              if (this.type_client.id) {
                this.$emit("updateTypeClient", {
                  edit: this.editing,
                  feature: this.feature
                });
              }
              this.setFields();
              this.$refs.form_type_features.reset();
              this.editing = false;
              this.dialog = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    editItem(value, type_client) {
      this.feature = Object.assign({}, value);
      this.type_client = Object.assign({}, type_client);
      this.editing = true;
      this.dialog = true;
    },
    pushNoty(model, action, pos = 2, time = 4000) {
      this.$awn.success(
        this.$tc("success", pos, {
          model: model,
          action: this.$tc(action, pos)
        }),
        { durations: { success: time } }
      );
    },
  },
  mounted() {}
};
