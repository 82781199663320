//
import PreView from '../../components/HistoryBills/preview.vue';
export default {
  name: "RequestBills",
  components:{
    PreView: PreView,
  },
  data (){
    return {
      edit: false,
      expand: false,
      dialog1: false,
      loading: false,
      is_filter: false,
      fullscreen:false,
      loadingTable:false,
      prospects_only:false,
      loadingSubscriber:false,
      its_filter_subscriber:false,
      policies:{
        show:false,
      },
      status_contract:null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy:['updated_at'],
        sortDesc:[true]
      },
      totalValues:0,
      contracts:[],
      filter: '',
      unwatch: '',
      step : 'tab-1',
    };
  },
  computed: {
    headers(){
      let headers = [
        {
          text: this.$tc('base.code',3),
          align: 'left',
          value: 'id',
          width: 100
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'doc',
          width: 140,
          sortable: false ,
        },
        { 
          text: this.$t('subscribers.legal_denomination'), 
          value: 'legal_denomination',
          sortable: false ,
        },
        {
          text: this.$t('Taxes.lastUpdate'),
          value: 'updated_at',
          align: 'center'
        },
      ];
      if (this.policies.show) {
        headers.push(
          {
            text: this.$t('action'),
            value: 'action',
            sortable: false,
            filterable: false,
            align: 'right'
          }
        );
      }
      return headers;
    } 
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      // Watch for update table after confirmation
      if(val){
        this.getContracts();
      }
    });
  },
  beforeDestroy() {
    this.unwatch()
  },
  watch: {
    options: {
      handler () {
        this.getContracts(this.expand);
      },
      deep: true,
    },
    step(){
      this.getContracts();
      this.PoliciesDocument();
    },
  },
  methods:{
    getContracts(){
      let ops = {
        params:{
          page: this.options.page,
          paginate: this.options.itemsPerPage,
          sort_by: this.options.sortBy[0] || null,
          sort_desc: this.options.sortDesc[0] || null,
        }
      };
      if (this.step =='tab-1') {
        // Cotizacion - Aceptada - Enviada // Contrado - En espera de generacion de nota de cobro
        ops.params.contract_status = 'generate_charge_note';
        ops.params.document_type   = 'price_quotation';
        ops.params.document_status = 'accepted';
        // ops.params.was_send = 1;
      } else if (this.step =='tab-2') {
        // Nota de Cobro - Pendiente - No enviada // Contrado - pendiente por pago
        ops.params.contract_status = 'generate_charge_note';
        ops.params.document_type   = 'payment_note';
        ops.params.document_status = 'pending';
        ops.params.was_send = 0;
      } else if (this.step =='tab-3') {
        // Nota de Cobro - Pendiente - Enviada // Contrado - pendiente por pago
        ops.params.contract_status = 'payment_pending';
        ops.params.document_type   = 'payment_note';
        ops.params.document_status = 'pending';
        ops.params.was_send = 1;
      } else if (this.step =='tab-4') {
        // Nota de Cobro - Pagada - No enviada // Contrado - Esperando instalacion
        ops.params.contract_status = 'awaiting_installation';
        ops.params.document_type   = 'payment_note';
        ops.params.document_status = 'paid';
        // ops.params.was_send = 1;
      }
      // eslint-disable-next-line no-console
      // console.log('ops',typeof(ops.params.was_send));
      this.contracts = [];
      this.loadingTable = true;
      axios.get(this.$store.getters.getContractsByParameters,ops)
      .then(response => {
        // eslint-disable-next-line no-console
        // console.log('Contratos',response.data.contracts.data);
        response.data.contracts.data.map(contract=>{
          // eslint-disable-next-line no-console
          // console.log(contract);
          contract.doc = contract.subscriber.doc;
          contract.legal_denomination = contract.subscriber.legal_denomination;
        });
        this.contracts = response.data.contracts.data;
        this.totalValues = response.data.contracts.total;
      })
      .catch((error)=>{
        // eslint-disable-next-line no-console
        // console.error('Error: ',error.response);
      })
      .finally(() => {
        this.loadingTable = false;
      });
    },
    getClientDocument(item){
      // eslint-disable-next-line no-console
      // console.log('Contract',item);
      let status_doc = null;
      this.status_contract = item.status;
      let url = null;
      let data1 = {
        contract_id: item.id,
        type:'',
        status:'',
      };
      if (this.step =='tab-1') {
        data1.type='price_quotation';
        data1.status='accepted';
        status_doc = true;
        this.DocumentContract(data1, status_doc);
      } else if (this.step =='tab-2') {
        data1.type='payment_note';
        data1.status='pending';
        status_doc = true;
        this.DocumentContract(data1, status_doc);
      } else if (this.step =='tab-3') {
        data1.type='payment_note';
        data1.status='pending';
        status_doc = false;
        this.DocumentContract(data1, status_doc);
      } else if (this.step =='tab-4') {
        data1.type='invoice';
        data1.status='paid';
        status_doc = true;
        this.DocumentContract(data1, status_doc);
      }
    },
    DocumentBuild(data1, status_doc){
      axios.post(this.$store.getters.getDocumentBuild,data1)
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('build:',response.data);
          // Le pasamos el simbolo al modal para evitar una consulta
          response.data.symbol = this.symbol;
          response.data.status_contract = this.status_contract;
          this.$refs.pre_view.open(response.data, status_doc);
      })
      .catch((error)=>{
        this.dialog1 = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        } else {
          // eslint-disable-next-line no-console
          // console.error('Error',error.message);
        }
      });
    },
    DocumentContract(data1, status_doc){
      axios.post(this.$store.getters.getClientDocumentContract,data1)
      .then((response)=>{
        // eslint-disable-next-line no-console
        // console.log('doc:',response.data);
          // Le pasamos el simbolo al modal para evitar una consulta
          response.data.symbol = this.symbol;
          response.data.status_contract = this.status_contract;
          this.$refs.pre_view.open(response.data, status_doc);
      })
      .catch((error)=>{
        this.dialog1 = false;
        if (error.response) {
          // eslint-disable-next-line no-console
          // console.error('Error',error.response);
        } else {
          // eslint-disable-next-line no-console
          // console.error('Error',error.message);
        }
      });
    },
    accept(item){
      // eslint-disable-next-line no-console
      // console.log('Contract',item);
      let data1 = {
        contract_id: item.id,
        type: 'payment_note'
      };
      // eslint-disable-next-line no-console
      // console.log('Data', data1);
      // axios.post(this.$store.getters.getDocumentBuild, build)
      this.$store.dispatch('confirmationValue',{
        show: true,
        model: this.$t('paymentNote.name'),
        title: this.$t('generate'),
        action: this.$t('generate'),
        content: this.$t('base.confirm_delete',{
          action: this.$t('generate'),
          record: this.$t('paymentNote.name')
        }),
        type: 'info',
        confirm:{
          url: this.$store.getters.getDocumentBuild,
          method: 'post',
          data: data1,
        }
      });
    },
    PoliciesDocument(){
      let policies = [
        'getContractsByParameters_clientDocument',
        'getClientDocumentContract_clientDocument',
        'invoiceQueries_clientDocument',
        'makeClientDocumentPDF_clientDocument',
        'makePDF_clientDocument',
        'sendClientDocumentEmail_clientDocument',
        'getClientDocument_clientDocument',
        'update_clientDocument',
      ];
      if (this.step=='tab-3') {
        policies.push('index_employee');
        policies.push('store_proofPayment');
      }
      this.policies.show = this.getPermissionGroup({
        permission:policies,
        mode:'all'
      });
    },
  },
  mounted() {
    // this.getContracts();
    this.PoliciesDocument();
  },
};
