<template>
  <v-card outlined class="card-outlined" style="margin-bottom: 0px">
    <v-card-title>
      <div class="title primary--text">
        <span class="px-1 white">
          Development
        </span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6" class="mx-auto"> Its preview {{ its_preview }} </v-col>
        <v-col cols="6" class="mx-auto">
          There are payments {{ there_are_payments }}
        </v-col>
        <v-col cols="6" class="mx-auto">
          Total Pay {{ totals[0].totalPay | formatAmount(decimal_places) }} -
          {{ totals[0].rest > 0.01 }}
        </v-col>
        <v-col cols="6" class="mx-auto">
          Rest {{ totals[0].rest | formatAmount(decimal_places) }} -
          {{ totals[0].rest > 0.01 }}
        </v-col>
        <v-col cols="6" class="mx-auto"> Rate Base: {{ rate_base }} </v-col>
        <v-col cols="6" class="mx-auto"> Rate NEW: {{ rate_new }} </v-col>
        <v-col cols="6" class="mx-auto">
          Total Rest > 0 {{ formatRound }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card--outlined.card-outlined {
  border-color: red !important;
}
</style>

<script>
export default {
  name: "payment-development",
  props: {
    its_preview: {
      type: Boolean,
      default: false,
    },
    there_are_payments: {
      type: Boolean,
      default: false,
    },
    decimal_places: {
      type: [String, Number],
      default: 2,
    },
    rate_base: {
      type: [String, Number],
      default: 2,
    },
    rate_new: {
      type: [String, Number],
      default: "",
    },
    formatRound: {
      type: Boolean,
      default: false,
    },
    totals: {
      type: Array,
      default: function () {
        return [{ totalPay: 0, totalRecibe: 0, rest: 0 }];
      },
    },
  },
};
</script>
