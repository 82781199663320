<template>
  <!-- v-show="showP"  -->
  <!-- v-if="errors.first('pdw.password')" -->

  <v-alert outlined
    class="verify-password"
  >
    <span>
      <v-icon
      v-text="`fas fa-${/[A-Z]/.test(pdw) ? 'check' : 'times'}`
      "></v-icon>
      {{$t('password_conditions.cap')}}
    </span>
    <span>
      <v-icon
      v-text="`fas fa-${/[a-z]/.test(pdw) ? 'check' : 'times'}`
      "></v-icon>
      {{$t('password_conditions.low')}}
    </span>
    <span>
      <v-icon
      v-text="`fas fa-${/[0-9]/.test(pdw) ? 'check' : 'times'}`
      "></v-icon>
      {{$t('password_conditions.num')}}
    </span>
    <span>
      <v-icon
      v-text="`fas fa-${/[!@#$%^&*()--__+.]/.test(pdw) ? 'check' : 'times'}`
      "></v-icon>
      {{$t('password_conditions.char')}}<br>
    </span>
    <span>
      <v-icon
      v-text="`fas fa-${/.{8,}/.test(pdw) ? 'check' : 'times'}`
      "></v-icon>
      {{$t('password_conditions.long')}}
    </span>
  </v-alert>
</template>

<script>
  export default {
    data(){
      return {
        pdw:''
      }
    },
    methods:{
      changePass(evt){
        this.pdw = evt
      }
    },
    computed:{
      //
    },
    watch: {
      //
    }
  }
</script>