import CompanyPreview from "./CompanyPreview.vue";
import DialogCompany from "./DialogCompany.vue";
import DialogPreview from "./DialogPreview.vue";
export default {
  components: {
    DialogPreview,
    CompanyPreview,
    DialogCompany
  },
  data: () => ({
    perPage: 10,
    page: 1,
    totalValues: 0,
    companies: [],
    companiesDeactives: [],
    item: "",
    defaultItem: {
      // is_active: true,
      name: "",
      email_admin: "",
      password: "",
      name_company: "",
      identification: "",
      object_company: "",
      country: null,
      state: null,
      name_institution_registration: "",
      register_number: "",
      volume: "",
      folio: "",
      legal_representative: "",
      fiscal_address: "",
      latitude: "",
      longitude: "",
      telephone: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      firm: null,
      seal: null,
      color1: "#7D7D7D",
      color2: "#353434",
      logo: null,
      head_logo: null,
      email: ""
    },
    loading: false,
    loadingTable: false,
    unwatch: "",
    tabActive: 1
  }),
  computed: {
    headers() {
      return [
        // { text: 'N° ', value: 'id' },
        { text: this.$t("company.name_company"), value: "name_company" },
        { text: this.$t("company.identification"), value: "identification" },
        {
          text: this.tabActive == 1 ? this.$t("actions") : this.$t("action"),
          value: "action",
          sortable: false,
          align: "right"
        }
      ];
    }
  },

  watch: {
    perPage(val) {
      this.initialize();
    },
    page(val) {
      this.initialize();
    }
  },

  mounted() {
    this.initialize();
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters.getConfirmationFinished,
      val => {
        // Watch for update table after of confirmation
        if (typeof val == "boolean" && val) {
          this.initialize();
        }
      }
    );
  },

  methods: {
    initialize() {
      this.loadingTable = true;
      axios
        .get(this.$store.getters.getCompanies(), {
          params: {
            page: this.page,
            paginate: this.perPage
          }
        })
        .then(response => {
          this.companies = response.data.companies.data;
          this.companiesDeactives = response.data.deactiveCompany.data;
          if (this.tabActive == 1) {
            this.totalValues = response.data.companies.total;
          }
          if (this.tabActive == 2) {
            this.totalValues = response.data.deactiveCompany.total;
          }
          this.logo = null;
          this.head_logo = null;
        })
        .catch(err => {
          // console.log(err)
          let error = err.response;
          if (err.response.data) {
            error = err.response.data.error;
          }
          this.$store.dispatch("pushNotification", {
            snackbar: true,
            style: "error",
            timeout: 6000,
            notifText: error
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    getTab(id) {
      this.tabActive = id;
      this.initialize();
    },
    initDialogCompany() {
      const company = Object.assign({}, this.defaultItem);
      // this.getCountries();
      this.$refs.dialog_company.open(company, false);
    },
    editItem(item) {
      this.logo = null;
      this.head_logo = null;
      let company = Object.assign({}, item);
      company.country = item.country;
      company.state = item.state;
      company.name = item.admin.name;
      company.email_admin = item.admin.email;
      company.latitude = item.geo_location
        ? item.geo_location.coordinates[1]
        : "";
      company.longitude = item.geo_location
        ? item.geo_location.coordinates[0]
        : "";
      this.editedIndex = this.companies.indexOf(item);
      let filter = this.companies.filter(comp => comp.id == company.id);
      setTimeout(() => {
        this.$refs.dialog_company.open(company, true, this.editedIndex, filter);
      }, 5);
    },
    showPreview(item) {
      this.logo = null;
      this.head_logo = null;
      this.company = Object.assign({}, item);
      this.company.country = item.country;
      this.company.state = item.state;
      this.company.name = item.admin.name;
      this.company.email_admin = item.admin.email;
      this.company.latitude = item.geo_location
        ? item.geo_location.coordinates[1]
        : "";
      this.company.longitude = item.geo_location
        ? item.geo_location.coordinates[0]
        : "";
      this.editedIndex = this.companies.indexOf(item);
      this.$refs.dialog_preview.open(this.company);
    },
    deleteItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        model: this.$tc("company.company", 2),
        title: this.$t("deactivate"),
        action: this.$tc("deactivated", 2),
        content: this.$t("base.confirm_delete", {
          action: this.$tc("deactivate", 0),
          record: item.name_company
        }),
        type: "error",
        confirm: {
          url: this.$store.getters.getCompanies(item.id),
          method: "delete"
        },
        pos: 2
      });
    },

    activeItem(item) {
      this.$store.dispatch("confirmationValue", {
        show: true,
        model: this.$tc("company.company", 2),
        title: this.$t("activate"),
        action: this.$tc("activated", 2),
        content: this.$t("base.confirm_delete", {
          action: this.$tc("activate", 0),
          record: item.name_company
        }),
        type: "info",
        confirm: {
          url: this.$store.getters.activateCompany(item.id),
          method: "patch"
        },
        pos: 2
      });
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format(this.$store.getters.getDateFormat[0]);
    }
  },
  beforeDestroy() {
    this.unwatch();
  }
};
