var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{class:_vm.classInput,style:(!_vm.showAppendOuterBtn || !_vm.taxBillRate.currency_code || !_vm.hasExchangeRatePermission?
      'margin-bottom: 10px !important' : ''),attrs:{"value":_vm.ExchangeRate,"items":_vm.rates,"name":_vm.nameTag,"item-text":"name","item-value":"id","disabled":_vm.disableField || (!_vm.taxBillRate.currency_code && !_vm.dontDisable),"loading":_vm.loadingField,"label":_vm.labelTag,"prepend-icon":_vm.showIcon
      ? _vm.prependIcon == ''
        ? _vm.$vuetify.icons.values.currencyexchange
        : _vm.prependIcon
      : '',"dense":_vm.dense,"dark":_vm.darkRate,"hide-details":_vm.hideDetails,"error-messages":_vm.errorMessages,"hint":_vm.taxBillRateHint,"persistent-hint":_vm.taxBillRateHint !== ''},on:{"input":function($event){return _vm.inputRate($event)},"change":function($event){return _vm.changeRate($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.selectedName(data.item))+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{class:{
        'success--text': data.item.default,
        'border-bottom': data.item.id === _vm.base.id,
      }},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.itemName(data.item))+" ")])],1)]}},{key:"append-outer",fn:function(){return [(_vm.showAppendOuterBtn && _vm.taxBillRate.currency_code && _vm.hasExchangeRatePermission)?_c('TaxBillRateMenu',{attrs:{"taxBillRate0":_vm.taxBillRate,"rates":_vm.rates},on:{"taxBillRateChanged":_vm.newTaxBillRate}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }